import { isMobile } from "react-device-detect";
import styled from "styled-components";

export const Wrapper = styled.div`
  background: linear-gradient(180deg, #00143d, #000);
  width: 100vw;
  min-height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
`;

export const Top = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const TopLeft = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  img {
    background: ${(props) => props.theme.colors.red100};
    border-radius: 50%;
    height: 4rem;
    margin-right: 10px;
    width: 4rem;
  }
`;

export const TopLeftData = styled.div`
  p {
    color: #fff;
    font-size: 2rem;
    font-weight: 600;
  }
  div {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
    p {
      background: linear-gradient(
        91.15deg,
        hsla(0, 0%, 78%, 0.451) 0.38%,
        hsla(0, 0%, 78%, 0) 105.06%
      );
      border-radius: 5px;
      color: #fff;
      font-weight: 500;
      margin: 0 5px;
      font-size: unset;
    }
  }
`;

export const TopRight = styled.div`
  margin-top: 1rem;
  width: 200px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  img {
    cursor: pointer;
    height: 35px;
    padding: 5px;
    width: 35px;
  }
`;

export const Main = styled.div`
  color: ${(props) => props.theme.colors.white100};
  margin-top: 50px;
`;

export const SectionHeading = styled.div`
  text-align: center !important;
  position: relative;
  z-index: 1;
  margin-bottom: 50px;
  padding: 0 30px;
`;

export const DreamFapArea = styled.div`
  button:first-child {
    border-top: 1px solid hsla(0, 0%, 100%, 0.1);
  }
  button {
    border: unset;
    cursor: pointer;
    padding: 15px;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
    margin-bottom: 0;
    color: ${(props) => props.theme.colors.white100};
    font-weight: 500;
    background-color: unset;
    width: 100%;
    text-align: left;
  }
  .card {
    border: unset;
  }
  .card-body {
    background: #00143d;
    border: unset;
    opacity: 0.8;
  }
`;

export const IcoCounter = styled.div`
  background-image: linear-gradient(90deg, #21d397 0, #7450fe);
  padding: ${isMobile ? "25px" : "20px 40px"};
  border-radius: 20px;
  text-align: center;
  position: relative;
`;

export const CounterHeader = styled.div`
  h3 {
    font-weight: 600;
    font-size: ${isMobile ? "22px" : "24px"};
    color: ${(props) => props.theme.colors.white100};
    margin-bottom: 30px;
  }
`;
export const CounterdownContent = styled.div`
  .countdown-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 18px;
    .countdown-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
      .countdown-item-value {
        background: #290571;
        font-family: "Poppins", Sans-serif;
        font-size: 25px;
        line-height: 30px;
        border-radius: 12px 12px 12px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        color: ${(props) => props.theme.colors.white100};
        width: 65px;
        height: 75px;
        margin-bottom: 5px;
      }
      .countdown-item-label {
        color: ${(props) => props.theme.colors.white100};
        font-family: "Poppins", Sans-serif;
        font-size: 12px;
        line-height: 21px;
        letter-spacing: 0.01px;
        text-align: center;
        margin-bottom: 10px;
        text-transform: lowercase;
      }
    }
  }
`;
export const ProgressWrapper = styled.div`
  width: 300px;
  margin: auto;
  .progress {
    .progress-bar {
      background-image: linear-gradient(90deg, #2238b3 0, #7450fe);
    }
  }
  margin-bottom: 20px;
  span {
    font-size: 12px;
  }
`;
export const BuyMore = styled.div`
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);

  a {
    position: relative;
    z-index: 0;
    overflow: hidden;
    display: inline-block;
    padding: 14px 40px;
    font-size: 16px;
    z-index: 2;
    line-height: 1.25;
    color: #25cbd3 !important;
    background: #fff;
    border: 0 solid;
    border-radius: 40px;
    font-weight: 600;
    letter-spacing: 0.02em;
    text-transform: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    cursor: pointer;
    vertical-align: middle;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all 0.25s ease-in-out;
    text-decoration: none !important;
    &:hover {
      background: #25cbd3;
      color: #fff !important;
    }
  }
`;

export const TableWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  p {
    margin: 0;
  }
`;

export const TableContainer = styled.div`
  h1 {
    color: ${(props) => props.theme.colors.white100};
    margin-top: 3rem;
    padding-bottom: 1rem;
  }
`;

export const Table = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(
    98.83deg,
    #130161 -0.03%,
    rgba(122, 199, 254, 0.4) 108.65%
  );
  border: 2px solid ${(props) => props.theme.colors.white100};
  border-radius: 20px;
  flex-flow: column;
  height: 22rem;
  padding: 10px;
`;

export const TableRow = styled.div`
  background: linear-gradient(
    91.15deg,
    hsla(0, 0%, 78%, 0.36) 0.38%,
    hsla(0, 0%, 78%, 0) 105.06%
  );
  border-radius: 10px;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  color: ${(props) => props.theme.colors.white100};
  padding: 12px;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;

  p:nth-child(2) {
    text-align: center;
    width: 40%;
  }
`;

export const Details = styled.div`
  color: ${(props) => props.theme.colors.white100};
  margin-bottom: 1.5rem;
`;

export const Banner = styled.img`
  height: 20rem;
  margin-bottom: 2rem;
  width: 100%;
`;

export const ICODoc = styled.div``;

export const DocElement = styled.div`
  background-color: #1d025c;
  border-radius: 4px;
  border-bottom: 2px solid #25cbd3;
  position: relative;
  transition: 0.5s;
  padding: 20px;
  margin-top: 20px;
  flex: 1 1;
  display: flex;
  align-items: center;
  &:before {
    height: 34px;
    background: url("https://ico3.netlify.app/static/media/pdf.d135e361.svg")
      50% no-repeat;
    background-size: contain;
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.3s;
    right: 15px;
    width: 27px;
  }
  &:hover {
    background-color: #25cbd3;
    &::after {
      opacity: 1;
      content: "";
    }
    &::before {
      opacity: 0;
    }
  }
  &:after {
    height: 30px;
    background: url("https://ico3.netlify.app/static/media/view.81045200.svg")
      50% no-repeat;
    background-size: contain;
    opacity: 0;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.3s;
    right: 15px;
    width: 27px;
  }
  a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    span {
      font-size: 14px;
      color: #fff;
      line-height: 1.35;
    }
  }
`;
