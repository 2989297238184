/* eslint-disable react-hooks/exhaustive-deps */
import APIs from "api";
import VButton from "components/button";
import Table from "components/table";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ContainerTable } from "./styles";
import { formatNumber } from "utils/common";

const Revenues = () => {
  const columns = [
    {
      key: "campaign",
      title: "Campaign",
      render: (item: any) => <span>{item.campaign.info?.name}</span>,
    },
    {
      key: "type",
      title: "Type",
      render: (item: any) => <span>{item.type}</span>,
    },
    {
      key: "amount",
      title: "Amount",
      render: (item: any) => (
        <span>
          {formatNumber(item.amount)} (
          {String(item.campaign?.stock).toUpperCase()})
        </span>
      ),
    },
    {
      key: "claimed",
      title: "Claimed",
      render: (item: any) => (
        <span>
          {formatNumber(item.claimed)} (
          {String(item.campaign?.stock).toUpperCase()})
        </span>
      ),
    },
    {
      key: "Remain",
      title: "Remain",
      render: (item: any) => <span>{+item.amount - item.claimed}</span>,
    },
    {
      key: "claim",
      title: "Claim",
      render: (item: any) => (
        <VButton
          loading={loading}
          onClick={() => handleClaim(item)}
          disabled={+item.claimed >= +item.amount || loading}
        >
          Claim
        </VButton>
      ),
    },
  ];

  const { campaignId } = useParams();

  const [revenues, setRevenues] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClaim = async (revenue) => {
    try {
      setLoading(true);
      const { type } = revenue;
      await APIs.TASK.claimRevenue({
        body: {
          campaignId,
          type,
        },
      });
      setLoading(false);
      getRevenues();
      toast.success("Claim success!");
    } catch (error) {
      toast.error(error?.errors);
      setLoading(false);
    }
  };

  const getRevenues = async () => {
    try {
      const res: any = await APIs.TASK.getRevenues({
        params: {
          campaignId: campaignId,
        },
      });
      if (res.revenues) {
        setRevenues(res.revenues);
      }
    } catch (error) {
      // console.log();
    }
  };

  useEffect(() => {
    if (campaignId) {
      getRevenues();
    }
  }, []);

  return (
    <>
      <ContainerTable>
        <Table bgTable={"black100"} columns={columns} dataSource={revenues} />
      </ContainerTable>
    </>
  );
};

export default Revenues;
