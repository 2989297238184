import React, { Suspense } from "react";
import { Container } from "reactstrap";

import { WhitePaper, Title } from "./styles";

import Button from "components/button";

const FileViewer = React.lazy(() => import("react-file-viewer"));

const View = (props: any) => {
  const { symbol, title, urlPdf } = props;

  return (
    <Container>
      <WhitePaper>
        <Title>
          <h5>{String(`${symbol} - ${title}`).toUpperCase()}</h5>
          <a href={urlPdf} target={"_blank"} rel="noopener noreferrer">
            <Button transparent={true}>Full screen</Button>
          </a>
        </Title>

        {urlPdf && (
          <Suspense fallback={<div>Loading...</div>}>
            <FileViewer fileType={"pdf"} filePath={urlPdf} />
          </Suspense>
        )}
      </WhitePaper>
    </Container>
  );
};

export default View;
