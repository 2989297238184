import { Container } from "./styles"
import Button from "../../components/button"
import Input from "../../components/input"
import Dropdown from "../../components/dropdown"
import Modal from "../../components/modal"
import { useState } from "react"

const View = () => {
  const [isModal, setIsModal] = useState<boolean>(false)
  const onChangeValue = (value: any) => {
    console.log(value);
  }
  return <>
    <Container>
      <div>
        <Button color={"orange100"}>Sign In</Button>
        <Button transparent={true} color={"orange100"}>Sign Up</Button>
    
        <Button color={"green100"} height="36px">Sign In</Button>
        <Button transparent={true} color={"green100"}>Sign Up</Button>
      </div>
      <br />
      
      <div>
        <Input color={"orange100"} placeholder="Email" />
        <Input transparent={true} placeholder="Password"/>
        <Input transparent={true} color={"green100"} placeholder="Confirm" />
      </div>

      <div>
        <Dropdown 
          width={'100%'} 
          transparent={true} 
          options={[
            {
              label: 'value1',
              value: 'val1'
            },
            {
              label: 'value2',
              value: 'val2'
            },
            {
              label: 'value3',
              value: 'val3'
            }
          ]} 
          defaultValue={"Choose the bank name"} 
          onChange={onChangeValue} />
      </div>
      <br />
      <div>
        <Button onClick={() => setIsModal(true)}>Open Modal</Button>
        <Modal
          isOpen={isModal} 
          title='Modal'
          onConfirm={() => setIsModal(false)}
          onCancel={() => setIsModal(false)}
        >
          Content Modal
        </Modal>
      </div>
    </Container>
  </>;
};

export default View;
