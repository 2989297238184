import { createAsyncThunk } from "@reduxjs/toolkit";
import { IRequestData } from "api/request";
import UserAPI from "api/users";

export const securityAsync = createAsyncThunk("user/security", async (data) => {
  try {
    const response = await UserAPI.getSecurity();
    return response;
  } catch (error) {
    return error;
  }
});

export const enable2faAsync = createAsyncThunk(
  "user/enable2fa",
  async (req: IRequestData) => {
    try {
      const response = await UserAPI.enable2FA(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const disable2faAsync = createAsyncThunk(
  "user/disable2fa",
  async (data: IRequestData) => {
    try {
      const response = await UserAPI.disable2FA(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);
