/*eslint max-len: ["error", { "code": 500 }]*/
/* eslint-disable react-hooks/exhaustive-deps */
import { useHistory, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import {
  Form,
  FormDescription,
  FormItem,
  InfoUserForm,
  Submit,
} from "../styles";

import Button from "components/button";
import Input from "components/input";

import APIs from "api"
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import Dropdown from "components/dropdown";

import Step3 from "../step3"

import FromProfit from "pages/my/my-project/components/profit-ibo"
import { filterAddress } from "utils/getAddress";
import { useAppSelector } from "redux/reducers/hook";
import { getCities } from "redux/reducers/settings/settings.selector";
import { getUserProfile } from "redux/reducers/users/users.selector";
interface IProps {
  [key: string]: any;
  role: string;
}

const defaultState = {
  name: '',
  symbol: '',
  supply: 0,
  logo: '',
  amountToSale: 0,
  amountSold: 0,
  tabs: [],
  social: [],
  ido: {},
  keep_time: {},
  content: '',
  description: '',
  banner: '',
  contract: '',
  contract_ido: '',
  url: '',
  type: 'no_hold',
  status: 'init',
  sale_time: 0,
  stage: '',
  stock_price: 0,
  stock_to_buy: '',
  user_id: '',
  share_profit: '',
  is_holding_balance: true,
  projectCategory: ''
};

export default function View(props: IProps) {
  const history = useHistory()
  const { projectId } = useParams();
  const cities = useAppSelector(getCities);
  const userLoggedIn = useAppSelector(getUserProfile);

  const email = userLoggedIn?.email

  const { role } = props

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState<boolean>(false)
  const [totalSupply, setTotalSupply] = useState(0)
  const [provices, setProvices] = useState<any>([])
  const [districts, setDistricts] = useState<any>([])
  const [wards, setWards] = useState<any>([])

  const [address, setAddress] = useState<{
    provices: string,
    districts: string,
    wards: string
  }>({
    provices: '',
    districts: '',
    wards: ''
  })

  const [dataDetail, setDataDetail] = useState<any>(null)
  const [errorField, setErrorField] = useState<any>({})
  const [fieldTokenomics, setFieldTokenomics] = useState<any>([
    {
      name: '',
      percent: ''
    },
  ])
  const [dataICO, setDataICO] = useState<any>(defaultState)

  const [step, setStep] = useState<number>(2)
  
  const onSubmit = async (value) => {
    try {
      if (step === 2) {
        setStep(3)
      } else {
        if( !dataDetail.projectCategory ){
          return setErrorField(prev => ({
            ...prev,
            projectCategory: true
          }))
        } else {
          setErrorField(prev => ({
            ...prev,
            projectCategory: false
          }))
        }
        let totalTokenomics: number = 0;
        fieldTokenomics.length > 0 && fieldTokenomics.forEach((item) => {
          if (item?.percent < 0) {
            setErrorField(prev => ({
              ...prev,
              totalTokenomics: false
            }))
          } else {
            totalTokenomics = totalTokenomics + Number(item?.percent)
            return totalTokenomics
          }
        })
        if (dataICO?.stock_to_buy || dataDetail.stock_to_buy) {
          setErrorField(prev => ({
            ...prev,
            stock_to_buy: false
          }))
          if (totalTokenomics >= 99 && totalTokenomics <= 100) {
            setErrorField(prev => ({
              ...prev,
              totalTokenomics: false
            }))

            const data = {
              ...dataICO,
              ...value,
              name: value.name,
              symbol: value.symbol.toLowerCase(),
              supply: totalSupply,
              stock_price: dataDetail.stock_price,
              amountToSale: dataDetail.amountToSale,
              min: dataDetail.min,
              max: dataDetail.max,
              tokenomics: fieldTokenomics,
              projectCategory: value.projectCategory || dataDetail.projectCategory,
              address: address
            }
            // const project: any = await APIs.PROJECT.createProject({ body: data })
            // if (project) {
            //   toast.success("Created successfull!")
            // } else {
            //   toast.error("Error!")
            // }
            const yourStock: any = await APIs.YOURESTOCK.createYourEstock({
              eStockData: {
                infoUser: {
                  fullname: value.fullname,
                  email: email ? email : value.email,
                  phone: value.phone,
                  address: `${value.address_line ? `${value.address_line}, ` : ''}${address.wards}, ${address.districts}, ${address.provices}`,
                  provices: address.provices,
                  districts: address.districts,
                  wards: address.wards,
                  role: role,
                  businessData: {
                    email: value.email,
                    business: value?.business,
                    business_owner: value?.business_owner,
                    businessCode: value?.businessCode
                  },
                  organizationsData: {
                    representative: value?.representative,
                    organization: value?.organization
                  },
                  studentData: {
                    schoolName: value?.schoolName,
                    schoolCode: value?.schoolCode,
                    specialized: value?.specialized
                  }
                },
                infoEstock: {
                  // name: value.name,
                  // symbol: value.symbol.toLowerCase(),
                  // supply: totalSupply,
                  // projectCategory: projectCategory.value || value.projectCategory,
                }
              },
              projectData: data
            })
            if (yourStock) {
              setLoading(false)
              setStep(4)

              if (yourStock.newAccount) {
                history.push(`/create-your-estock/${role}/profit/${yourStock?.data?.projectId}/${yourStock.token}?newAccount=${yourStock.newAccount}`)
              } else {
                history.push(`/create-your-estock/${role}/profit/${yourStock?.data?.projectId}/${yourStock.token}`)
              }
            } else {
              setLoading(false)
            }
          } else {
            setErrorField(prev => ({
              ...prev,
              totalTokenomics: true
            }))
          }
        } else {
          setStep(2)
          setErrorField(prev => ({
            ...prev,
            stock_to_buy: true
          }))
        }
        setLoading(false)
      }

    } catch (error) {
      setStep(2)
      setLoading(false)
      toast.error(error?.errors);
    }
  };

  const onChangeAddress = async (value, key) => {
    if (key === 'provices'){
      filterAddress(key, cities, value.value, setDistricts)
    } else if (key === 'districts') {
      filterAddress(key, districts, value.value, setWards)
    }
    setAddress(prev => ({
      ...prev,
      [key]: value.value
    }))
  }

  useEffect(() => {
    setProvices(cities)
  }, [cities])

  useEffect(() => {
    if (projectId) {
      setStep(4)
    }
  }, [])

  return (
    <InfoUserForm>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {
          step === 2 && <>
            <FormDescription>{role.charAt(0).toUpperCase() + role.slice(1)} information</FormDescription>
            {
              (role === "personal" || role === "student") && <FormItem>
                <Controller
                  name="fullname"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      transparent={true}
                      hasError={Boolean(errors.fullname)}
                      label="Full name"
                      placeholder="Full name"
                      {...field}
                    />
                  )}
                />
              </FormItem>
            }
            {
              (role === "student") && <> 
                <FormItem>
                  <Controller
                    name="schoolName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        transparent={true}
                        hasError={Boolean(errors.schoolName)}
                        label="School's name"
                        placeholder="School's name"
                        {...field}
                      />
                    )}
                  />
                </FormItem>
                <FormItem>
                  <Controller
                    name="schoolCode"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <Input
                        transparent={true}
                        label="School code"
                        placeholder="School code"
                        {...field}
                      />
                    )}
                  />
                </FormItem>
                <FormItem>
                  <Controller
                    name="specialized"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <Input
                        transparent={true}
                        label="Specialized"
                        placeholder="Specialized"
                        {...field}
                      />
                    )}
                  />
                </FormItem>
              </>
            }
            {
              role === "organization" && <>
                <FormItem>
                  <Controller
                    name="organization"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        transparent={true}
                        hasError={Boolean(errors.organization)}
                        label="Organization name"
                        placeholder="Organization name"
                        {...field}
                      />
                    )}
                  />
                </FormItem>
                <FormItem>
                  <Controller
                    name="representative"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        transparent={true}
                        hasError={Boolean(errors.representative)}
                        label="Representative"
                        placeholder="Representative"
                        {...field}
                      />
                    )}
                  />
                </FormItem>
              </>
            }
            {
              role === "business" &&
              <FormItem>
                <Controller
                  name="business"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      transparent={true}
                      hasError={Boolean(errors.business)}
                      label="Business name"
                      placeholder="Business name"
                      {...field}
                    />
                  )}
                />
              </FormItem>
            }
            {
              (role !== "personal" && role !== "student") && <>
                <FormItem>
                  <Controller
                    name="business_owner"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        transparent={true}
                        hasError={Boolean(errors.business_owner)}
                        label="Business owner"
                        placeholder="Business owner"
                        {...field}
                      />
                    )}
                  />
                </FormItem>
                <FormItem>
                  <Controller
                    name="businessCode"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        transparent={true}
                        hasError={Boolean(errors.businessCode)}
                        label="Business Code"
                        placeholder="Business code"
                        {...field}
                      />
                    )}
                  />
                </FormItem>
              </>

            }
            <FormItem>
              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    transparent={true}
                    type='email'
                    hasError={Boolean(errors.email)}
                    label="Email"
                    placeholder="Email"
                    {...field}
                  />
                )}
              />
            </FormItem>
            <FormItem>
              <Controller
                name="phone"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    transparent={true}
                    hasError={Boolean(errors.phone)}
                    label="Phone"
                    placeholder="Phone"
                    {...field}
                  />
                )}
              />
            </FormItem>
            <FormItem>
              <Dropdown
                transparent={true}
                color={'white100'}
                width={"100%"}
                label="Choose the Province / City"
                options={provices?.map((el: any) => {
                  return {
                    label: el?.name,
                    value: el?.code
                  }
                })}
                defaultValue={address.provices || "Province / City"}
                onChange={(e) => onChangeAddress(e, "provices")}
              />
            </FormItem>
            <FormItem>
              <Dropdown
                transparent={true}
                color={'white100'}
                width={"100%"}
                label="Districts"
                options={districts?.map((el: any) => {
                  return {
                    label: el?.name,
                    value: el?.code
                  }
                })}
                defaultValue={address.districts || "Choose the Districts"}
                onChange={(e) => onChangeAddress(e, "districts")}
              />
            </FormItem>
            <FormItem>
              <Dropdown
                transparent={true}
                color={'white100'}
                width={"100%"}
                label="Wards"
                options={wards?.map((el: any) => {
                  return {
                    label: el?.name,
                    value: el?.code
                  }
                })}
                defaultValue={address.wards || "Choose the Wards"}
                onChange={(e) => onChangeAddress(e, "wards")}
              />
            </FormItem>
            <FormItem>
              <Controller
                name="address_line"
                control={control}
                render={({ field }) => (
                  <Input
                    transparent={true}
                    hasError={Boolean(errors.address_line)}
                    label="Address line"
                    placeholder="Enter your address line"
                    {...field}
                  />
                )}
              />
            </FormItem>
          </>
        }
        <br />
        {
          step === 3 && <Step3
            control={control}
            errors={errors}
            errorField={errorField}
            fieldTokenomics={fieldTokenomics}
            dataDetail={dataDetail}
            totalSupply={totalSupply}
            setTotalSupply={setTotalSupply}
            dataICO={dataICO}
            setDataICO={setDataICO}
            setDataDetail={setDataDetail}
            setFieldTokenomics={setFieldTokenomics}
          />
        }
        {
          step === 4 ? <>
            <FormDescription>{'Project profit distribution'}</FormDescription>
            <FromProfit isCreate={true} />
          </> :
            <FormItem>
              <br />
              <Submit>
                <Button loading={loading} type="submit">
                  Next
                </Button>
              </Submit>
            </FormItem>
        }
      </Form>
    </InfoUserForm>
  );
}
