import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "api/projects";
import { IRequestData } from "api/request";

export const getProjectsAsync = createAsyncThunk(
  "projects/list",
  async (req) => {
    try {
      const response = await API.getProjects();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getProjectsOwnerAsync = createAsyncThunk(
  "projects/list/owner",
  async (req) => {
    try {
      const response = await API.getProjectsOwner();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getProjectById = createAsyncThunk(
  "projects/get-by-id",
  async (req: IRequestData) => {
    try {
      const response = await API.getProjectById(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

