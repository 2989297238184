/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
/* eslint-disable array-callback-return */
/* eslint-disable max-len */
import React, { useRef } from "react";
import {
  Wrapper,
  Section,
  BgColor,
  AddLink,
  UploadPdf,
  AddSlideBanner,
  Upload,
  SlideUpload,
  AddOrRemoveChil,
  MovePosition,
} from "pages/home/landing-page/styles";

import { v4 as uuidv4 } from "uuid";

import { Route, Switch } from "react-router-dom";
import { ROUTES } from "config/routes";

import { PaddingHeader } from "pages/home/styles";

import queryString from "query-string";
import { useParams, useHistory, useLocation } from "react-router-dom";

import { Row, Col } from "reactstrap";
import Header from "layouts/components/header";
import { useEffect, useState } from "react";
import { convertIdVideo } from "utils/convertIdVideo";
import APIs from "api";
import UploadFile from "components/uploadFile";
import Modal from "components/modal";
import Input from "components/input";
import Button from "components/button";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";
import withEstock from "HOCs/verifyToken";

import CertificateLandingPage from "pages/certificate/certificate-landing-page";
import WhitePaper from "./components/Whitepaper";
import PreviewAndDownload from "components/view-download";
import SettingColor from "components/setting-color-page";
import FormCreateContributeIdeas from "components/form-create-field";

import { exportComponentAsJPEG } from "react-component-export-image";

import Main from "./components/Main";
import decode from "utils/decodeToken";
import { Submit } from "pages/events/styles";

const optionSetting = [
  {
    title: "Background header",
    key: "bgColorHeader",
  },
  {
    title: "Background section 1",
    key: "bgColor2",
  },
  {
    title: "Background section 2",
    key: "bgColor1",
  },
  {
    title: "Text color",
    key: "textColor",
  },
  {
    title: "Hover color",
    key: "hoverColor",
  },
];

const defaultData = {
  name: "",
  symbol: "",
  logo: "",
  color: {
    bgColorHeader: "",
    bgColor1: "",
    bgColor2: "",
    textColor: "",
    hoverColor: "",
  },
  banner: {
    link: "",
    slide: [],
    imageBottom: {
      src: "",
    },
  },
  advertisement_banner: {
    left: [],
    right: [],
  },
  slide: [],
  linkIntro: "Aq6W-nmRO94",
  logoToken: "",
  fundamentalsImg: "",
  fundamentals_desc:
    "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
  fundamentals_tt1: "Title",
  fundamentals_de1:
    "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  fundamentals_tt2: "Title",
  fundamentals_de2:
    "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  fundamentals_tt3: "Title",
  fundamentals_de3:
    "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  fundamentals_tt4: "Title",
  fundamentals_de4:
    "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  fundamentals_tt5: "Title",
  fundamentals_de5:
    "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  fundamentals_tt6: "Title",
  fundamentals_de6:
    "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  roadmap_title:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  roadmap_desc:
    "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. ",
  roadmapImg: "",
  tokenomicImg: "",
  certificates: [],
  doc: [
    {
      id: uuidv4(),
      title: "Doc 1",
      decs: "Doc 1",
      link: "",
    },
    {
      id: uuidv4(),
      title: "Doc 2",
      decs: "Doc 2",
      link: "",
    },
    {
      id: uuidv4(),
      title: "Doc 3",
      decs: "Doc 3",
      link: "",
    },
  ],
  partner: [
    {
      id: uuidv4(),
      src: "",
    },
    {
      id: uuidv4(),
      src: "",
    },
    {
      id: uuidv4(),
      src: "",
    },
    {
      id: uuidv4(),
      src: "",
    },
  ],
  dataTeam: [
    {
      title: "Title",
      team_desc: "Descriptions",
      ourTeam: [
        {
          id: uuidv4(),
          src: "",
          name: "Mr. A",
          role: "Responsibility",
          desc: "Responsibility description",
          link: {
            vdiarybook: "",
            facebook: "",
            youtube: "",
            twitter: "",
            telegram: "",
          },
        },
        {
          id: uuidv4(),
          src: "",
          name: "Mr. A",
          role: "Responsibility",
          desc: "Responsibility description",
          link: {
            vdiarybook: "",
            facebook: "",
            youtube: "",
            twitter: "",
            telegram: "",
          },
        },
        {
          id: uuidv4(),
          src: "",
          name: "Mr. A",
          role: "Responsibility",
          desc: "Responsibility description",
          link: {
            vdiarybook: "",
            facebook: "",
            youtube: "",
            twitter: "",
            telegram: "",
          },
        },
      ],
    },
  ],

  team_desc: " MORE THAN 30+ EMPLOYEES ALL OVER THE WORLD",
  footer_desc:
    " Website is in the process of Experimental Research, Copyright belongs to Blockchain Technology and Education Joint Stock Company.",
  footer_address: "No. 111 Street, Abc Ward, Cde District, City, Vietnam.",
  footer_businessCode: "99999999",
  footer_agentName: "Nguyen van a",
  video: [],
  commentsField: [],
};

const linkDefault = {
  linkIntro: "",
  facebook: "",
  youtube: "",
  twitter: "",
  telegram: "",
  vdiarybook: "",
  video: "",
};

const optionManagersSection = {
  profit: {
    title: "Profit",
    isShow: true,
  },
  fundamentals: {
    title: "Fundamentals",
    isShow: true,
  },
  roadmap: {
    title: "Roadmap",
    isShow: true,
  },
  tokenomics: {
    title: "Project Tokenomics",
    isShow: true,
  },
  doc: {
    title: "Documents",
    isShow: true,
  },
  certificate: {
    title: "Project Certificate",
    isShow: true,
  },
  partner: {
    title: "Our Partners",
    isShow: true,
  },
  dataTeam: {
    title: "Our Team",
    isShow: true,
  },
  video: {
    title: "Videos",
    isShow: true,
  },
  review: {
    title: "Review",
    isShow: true,
  },
};

const getToken = (token?: any) => {
  if (token) {
    return token;
  }
  return localStorage.getItem("auth");
};

const View = (props: any) => {
  const prams: any = useParams();
  const location: any = useLocation();
  const history: any = useHistory();
  const imgCerRef = useRef();

  const user: any = decode(getToken(props.token));

  const [token, setToken] = useState<any>(null);

  const [dataTeam, setDataTeam] = useState<any>(defaultData.dataTeam);

  const [isModal, setIsModal] = useState<boolean>(false);
  const [isModalImage, setIsModalImage] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [dataEstock, setDataEstock] = useState<any>(defaultData);
  const [dataUser, setDataUser] = useState<any>();
  const [dataProject, setDataProject] = useState<any>();
  const [listFiles, setListFiles] = useState<any>({});

  const [link, setLink] = useState<any>(linkDefault);

  // setting
  const [optionSection, setOptionSection] = useState(optionManagersSection);
  const [openSettingColor, setOpenSettingColor] = useState<boolean>(false);
  const [color, setColor] = useState<any>({
    bgColorHeader: "",
    bgColor1: "",
    bgColor2: "",
    textColor: "",
  });
  const [timeSetting, setTimeSetting] = useState({
    start: "",
    end: "",
  });

  //state section
  const [advertisementBanner, setAdvertisementBanner] = useState<any>(
    defaultData.advertisement_banner
  );
  const [docState, setDocState] = useState<any>(defaultData.doc);
  const [partnerState, setPartnerState] = useState<any>(defaultData.partner);
  const [certifications, setCertifications] = useState(
    defaultData.certificates
  );
  const [videoState, setVideoState] = useState<any>(defaultData.video);
  const [commentsFieldState, setCommentsFieldState] = useState<any>(
    defaultData.commentsField
  );

  //list slide banner
  const [listSlide, setListSlide] = useState<any>(defaultData.slide);

  const [isDownloadCer, setIsDownloadCer] = useState<boolean>(false);

  const [typeModal, setTypeModal] = useState<string>("");
  const [srcImgPopup, setSrcImgPopup] = useState("");

  const [loading, setIsLoading] = useState<boolean>(false);
  const [required, setRequired] = useState({
    certifications: false,
    partnerState: false,
    ourTeamState: false,
  });

  const [indexStateChange, setIndexStateChange] = useState<number>(0);
  const [indexStateTeamChange, setIndexStateTeamChange] = useState<number>(0);

  //managersSection
  const [managersSections, setManagersSections] = useState<any>(["all"]);

  const [isOwnerEventOrAdmin, setIsOwnerEventOrAdmin] =
    useState<boolean>(false);

  const checkManagersSection = (section: string) => {
    if (!isEdit) {
      return false;
    }

    if (managersSections.includes("all")) {
      return false;
    }

    if (managersSections.includes(section)) {
      return false;
    }

    if (isOwnerEventOrAdmin) {
      return false;
    }

    return true;

    // if(!(managersSections.includes('all') || managersSections.includes(section)) && isEdit && !isOwnerEventOrAdmin){
    //   return true
    // } else {
    //   return false
    // }
  };

  const handleAddSectionTeam = () => {
    setDataTeam(
      dataTeam.concat({
        title: "Title Team",
        team_desc: "Descriptions",
        ourTeam: [
          {
            id: uuidv4(),
            src: "",
            name: "Mr. A",
            role: "Responsibility",
            desc: "Responsibility description",
            link: {
              vdiarybook: "",
              facebook: "",
              youtube: "",
              twitter: "",
              telegram: "",
            },
          },
          {
            id: uuidv4(),
            src: "",
            name: "Mr. A",
            role: "Responsibility",
            desc: "Responsibility description",
            link: {
              vdiarybook: "",
              facebook: "",
              youtube: "",
              twitter: "",
              telegram: "",
            },
          },
          {
            id: uuidv4(),
            src: "",
            name: "Mr. A",
            role: "Responsibility",
            desc: "Responsibility description",
            link: {
              vdiarybook: "",
              facebook: "",
              youtube: "",
              twitter: "",
              telegram: "",
            },
          },
        ],
      })
    );
  };

  const handleRemoveSectionTeam = () => {
    const newArr = dataTeam.slice(0, dataTeam.length - 1);
    setDataTeam(newArr);
  };

  const onChangeColor = (e: any, key: string) => {
    setColor((prev) => ({
      ...prev,
      [key]: e.target.value,
    }));
  };

  const addChil = (chil: string, index?: number) => {
    if (chil === "doc") {
      setDocState(
        docState.concat({
          file: "",
          id: uuidv4(),
          title: "Doc title",
          decs: "Doc description",
          link: "",
        })
      );
    } else if (chil === "partner") {
      setPartnerState(
        partnerState.concat({
          file: "",
          src: "",
          id: uuidv4(),
          isNew: true,
        })
      );
    } else if (chil === "ourTeam") {
      setDataTeam((prev) =>
        prev.map((el: any, idx: number) =>
          index === idx
            ? {
                ...el,
                ourTeam: el.ourTeam.concat({
                  file: "",
                  src: "",
                  name: "Mr. A",
                  role: "Responsibility",
                  desc: "Responsibility description",
                  link: {},
                  id: uuidv4(),
                  isNew: true,
                }),
              }
            : el
        )
      );
    } else if (chil === "video") {
      setVideoState(
        videoState.concat({
          link: "Aq6W-nmRO94",
        })
      );
    } else {
      setCertifications(
        certifications.concat({
          file: "",
          src: "",
          id: uuidv4(),
          isNew: true,
        })
      );
    }
  };

  const removeChil = (chil: string, uuid: string, index?: number) => {
    if (chil === "slide") {
      const newArr = listSlide.filter((el: any) => el.id !== uuid);
      setListSlide(newArr);
    } else if (chil === "doc") {
      const newArr = docState.filter((el: any) => el.id !== uuid);
      setDocState(newArr);
    } else if (chil === "partner") {
      const newArr = partnerState.filter((el: any) => el.id !== uuid);
      setPartnerState(newArr);
    } else if (chil === "ourTeam") {
      const newArr = dataTeam[index].ourTeam.filter(
        (el: any) => el.id !== uuid
      );
      setDataTeam((prev) =>
        prev.map((el: any, idx: number) =>
          index === idx
            ? {
                ...el,
                ourTeam: newArr,
              }
            : el
        )
      );
    } else if (chil === "certificates") {
      const newArr = certifications.filter((el: any) => el.id !== uuid);
      setCertifications(newArr);
    } else if (chil === "video") {
      const newArr = videoState.filter((el: any, idx: any) => idx !== uuid);
      setVideoState(newArr);
    } else {
      console.log("WRONG", chil);
    }
  };

  const removeLastChil = (chil: string, index?: number) => {
    if (chil === "doc") {
      const newArr = docState.slice(0, docState.length - 1);
      setDocState(newArr);
    } else if (chil === "partner") {
      const newArr = partnerState.slice(0, partnerState.length - 1);
      setPartnerState(newArr);
    } else if (chil === "ourTeam") {
      const newArr = dataTeam[index].ourTeam.slice(
        0,
        dataTeam[index].ourTeam.length - 1
      );
      setDataTeam((prev) =>
        prev.map((el: any, idx: number) =>
          index === idx
            ? {
                ...el,
                ourTeam: newArr,
              }
            : el
        )
      );
    } else if (chil === "video") {
      const newArr = videoState.slice(0, videoState.length - 1);
      setVideoState(newArr);
    } else {
      const newArr = certifications.slice(0, certifications.length - 1);
      setCertifications(newArr);
    }
  };

  const handleMoveChil = (
    index: number,
    type: string,
    key: string,
    indexGlobal?: number
  ) => {
    const moveElement = (state, setState) => {
      const newArr =
        type === "ourTeam" ? [...state[indexGlobal]?.ourTeam] : [...state];
      const el = newArr[index];

      if (key === "upward") {
        newArr[index] = newArr[index - 1];
        newArr[index - 1] = el;
      } else {
        newArr[index] = newArr[index + 1];
        newArr[index + 1] = el;
      }

      if (type === "ourTeam") {
        setDataTeam((prev) =>
          prev.map((el: any, idx: number) =>
            indexGlobal === idx
              ? {
                  ...el,
                  ourTeam: newArr,
                }
              : el
          )
        );
      } else {
        setState(newArr);
      }
    };

    if (type === "slide") {
      moveElement(listSlide, setListSlide);
    } else if (type === "doc") {
      moveElement(docState, setDocState);
    } else if (type === "certificates") {
      moveElement(certifications, setCertifications);
    } else if (type === "partner") {
      moveElement(partnerState, setPartnerState);
    } else if (type === "ourTeam") {
      moveElement(dataTeam, setDataTeam);
    } else if (type === "video") {
      moveElement(videoState, setVideoState);
    } else {
      // console.log();
    }
  };

  const handleViewImage = (src: string, type?: string) => {
    if (type === "certification") {
      setIsDownloadCer(true);
      if (imgCerRef.current === null) {
        return;
      }
    } else {
      setIsDownloadCer(false);
      setSrcImgPopup(src);
    }
    setIsModalImage(isEdit ? false : true);
  };

  const onClickLogoCer = (event: any, link: string) => {
    if (!isEdit) {
      window.open(link);
      event.stopPropagation();
    }
  };

  const downloadImage = () => {
    if (isDownloadCer) {
      exportComponentAsJPEG(imgCerRef, {
        fileName:
          `${dataEstock.symbol}_vzonex_${new Date().getTime()}` || "vzonex",
      });
    } else {
      saveAs(srcImgPopup, `${dataEstock.symbol}.jpg`);
    }
  };

  const handleClickVideo = (link: string, type?: string, index?: number) => {
    if (!isEdit) {
      window.open(link);
    } else {
      handleOpenPopup(type, index);
    }
  };

  const handleOpenPopup = (
    type: string,
    index?: number,
    indexGlobal?: number
  ) => {
    setIsModal(isEdit ? true : false);
    setTypeModal(type);
    setLink(linkDefault);

    if (type === "linkouteam" || type === "video") {
      setIndexStateChange(index);
    }
    if (type === "linkouteam") {
      setIndexStateTeamChange(indexGlobal);
    }
  };

  const onChangLink = (e: any, name: string) => {
    setLink((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));
  };

  const setFile = (f: any, id: string, key?: string, index?: number) => {
    if (!key) {
      setListFiles((pre) => ({
        ...pre,
        [id]: f,
      }));
    } else if (key === "slide") {
      setListSlide((prev) =>
        prev.map((el: any) =>
          el.id === id
            ? {
                ...el,
                file: f,
                isNew: true,
              }
            : el
        )
      );
    } else if (key === "doc") {
      setDocState((prev) =>
        prev.map((el: any) =>
          el.id === id
            ? {
                ...el,
                file: f,
                isNew: true,
              }
            : el
        )
      );
    } else if (key === "certificates") {
      setCertifications((prev) =>
        prev.map((el: any) =>
          el.id === id
            ? {
                ...el,
                file: f,
                isNew: true,
              }
            : el
        )
      );
    } else if (key === "partner") {
      setPartnerState((prev) =>
        prev.map((el: any) =>
          el.id === id
            ? {
                ...el,
                file: f,
                isNew: true,
              }
            : el
        )
      );
    } else if (key === "ourTeam") {
      setDataTeam((prev) =>
        prev.map((el: any, idx: number) =>
          index === idx
            ? {
                ...el,
                ourTeam: dataTeam[index].ourTeam.map((item: any) =>
                  item.id === id
                    ? {
                        ...item,
                        file: f,
                        isNew: true,
                      }
                    : item
                ),
              }
            : el
        )
      );
    } else {
      // console.log();
    }
  };

  const handleAccept = async () => {
    let newdDataTeam = dataTeam;
    if (typeModal !== "slide") {
      Object.keys(link).map((el) => {
        if (link[el]) {
          if (el === "banner") {
            setDataEstock((prev) => ({
              ...prev,
              linkBanner: convertIdVideo(link.banner),
            }));
          } else if (el === "linkIntro") {
            setDataEstock((prev) => ({
              ...prev,
              linkIntro: convertIdVideo(link.linkIntro),
            }));
          } else if (el === "video") {
            setVideoState((prev) =>
              prev.map((item, index: number) =>
                index === indexStateChange
                  ? { link: convertIdVideo(link.video) }
                  : item
              )
            );
          } else {
            if (typeModal === "linkouteam") {
              newdDataTeam = newdDataTeam.map((team: any, index: number) =>
                index === indexStateTeamChange
                  ? {
                      ...team,
                      ourTeam: newdDataTeam[index].ourTeam.map(
                        (item: any, idx: number) =>
                          idx === indexStateChange
                            ? {
                                ...item,
                                link: {
                                  ...item.link,
                                  [el]: link[el],
                                },
                              }
                            : item
                      ),
                    }
                  : team
              );
            } else {
              setDataEstock((prev) => ({
                ...prev,
                [el]: link[el],
              }));
            }
          }
        }
      });
    } else {
      if (
        listSlide?.length > 0 &&
        listSlide?.filter((it) => it.file)?.length > 0
      ) {
        const formData = new FormData();
        formData.append("folderPath", `landing-page/${prams.symbol}`);

        const statesNew = listSlide
          .filter((st) => st.isNew)
          .map((stNew) => {
            formData.append("files", stNew.file);
            return stNew;
          });

        const listImg: any = await APIs.UPLOAD.uploadMultiFile({
          body: formData,
        });

        listImg.forEach((el) => {
          statesNew[el.id] = {
            ...statesNew[el.id],
            src: el?.filePath,
          };
        });

        const slideUpload = listSlide.map((st) => {
          const newStFound = statesNew.find((c) => c.id === st.id);
          if (newStFound) {
            return {
              ...st,
              ...newStFound,
            };
          }
          return st;
        });
        setDataEstock((prev) => ({
          ...prev,
          linkBanner: "",
          slide: slideUpload,
        }));
      } else {
        setDataEstock((prev) => ({
          ...prev,
          linkBanner: "",
          slide: listSlide,
        }));
      }
    }
    setDataTeam(newdDataTeam);
    setIsModal(false);
  };

  const checkRequiredState = (state: any, key: string) => {
    if (state?.length > 0 && state.some((el) => !el.file && el.isNew)) {
      setIsLoading(false);
      toast.error("Please enter all required fields");
      setRequired((prev) => ({
        ...prev,
        [key]: true,
      }));
      return true;
    } else {
      return false;
    }
  };

  const handleCreateEstock = async () => {
    try {
      setIsLoading(true);

      // REQUIRED
      if (checkRequiredState(certifications, "certifications")) {
        return;
      }
      if (checkRequiredState(partnerState, "partnerState")) {
        return;
      }
      if (timeSetting && (timeSetting.start || timeSetting.end)) {
        await APIs.PROJECT.editProject({
          params: {
            id: dataProject._id,
          },
          body: {
            ...dataProject,
            keep_time: {
              ido_open: timeSetting.start,
              ido_close: timeSetting.end,
            },
          },
        });
      }

      // HANDLE UPLOAD CERTS

      let docUploaded = docState;
      let certificationsUploaded = certifications;
      let partnersUploaded = partnerState;
      let ourTeamsUploaded = dataTeam;
      let advertisementBannerUploadLeft = advertisementBanner?.left;
      let advertisementBannerUploadRight = advertisementBanner?.right;

      const uploadSectionImg = async (state: any, uploadedState: any) => {
        if (uploadedState !== "ourTeamsUploaded") {
          if (state?.length > 0 && state?.filter((it) => it.file)?.length > 0) {
            const formData = new FormData();
            formData.append("folderPath", `landing-page/${prams.symbol}`);

            const statesNew = state
              .filter((st) => st.isNew)
              .map((stNew) => {
                formData.append("files", stNew.file);
                return stNew;
              });

            const listImg: any = await APIs.UPLOAD.uploadMultiFile({
              body: formData,
            });

            listImg.forEach((el) => {
              statesNew[el.id] = {
                ...statesNew[el.id],
                src: el?.filePath,
              };
            });
            if (uploadedState === "bannerAdsLeft") {
              advertisementBannerUploadLeft = state.map((st) => {
                const newStFound = statesNew.find((c) => c.id === st.id);
                if (newStFound) {
                  return {
                    ...st,
                    ...newStFound,
                  };
                }
                return st;
              });
            } else if (uploadedState === "bannerAdsRight") {
              advertisementBannerUploadRight = state.map((st) => {
                const newStFound = statesNew.find((c) => c.id === st.id);
                if (newStFound) {
                  return {
                    ...st,
                    ...newStFound,
                  };
                }
                return st;
              });
            } else if (uploadedState === "certificationsUploaded") {
              certificationsUploaded = state.map((st) => {
                const newStFound = statesNew.find((c) => c.id === st.id);
                if (newStFound) {
                  return {
                    ...st,
                    ...newStFound,
                  };
                }
                return st;
              });
            } else if (uploadedState === "partnersUploaded") {
              partnersUploaded = state.map((st) => {
                const newStFound = statesNew.find((c) => c.id === st.id);
                if (newStFound) {
                  return {
                    ...st,
                    ...newStFound,
                  };
                }
                return st;
              });
            } else if (uploadedState === "docUploaded") {
              docUploaded = state.map((st) => {
                const newStFound = statesNew.find((c) => c.id === st.id);
                if (newStFound) {
                  return {
                    ...st,
                    ...newStFound,
                  };
                }
                return st;
              });
            } else {
              // console.log();
            }
          }
        } else {
          if (
            state?.length > 0 &&
            state.map(
              (item) =>
                item.ourTeam.filter((st) => st.isNew && st.file)?.length > 0
            )
          ) {
            const formData = new FormData();
            formData.append("folderPath", `landing-page/${prams.symbol}`);

            const statesNew = state.map((item) =>
              item.ourTeam
                .filter((st) => st.isNew && st.file)
                .map((stNew) => {
                  formData.append("files", stNew.file);
                  return stNew;
                })
            );
            let newData = [];
            statesNew.forEach((item) => {
              item.map((el) => {
                newData = [...newData, el];
              });
            });

            if (newData.length > 0) {
              const listImg: any = await APIs.UPLOAD.uploadMultiFile({
                body: formData,
              });

              listImg.forEach((el) => {
                newData[el.id] = {
                  ...newData[el.id],
                  src: el?.filePath,
                };
              });

              ourTeamsUploaded = state.map((st) => {
                const newStFound = st.ourTeam.map((item) => {
                  const newStF = newData.find((c) => c.id === item.id);
                  if (newStF) {
                    return {
                      ...item,
                      ...newStF,
                    };
                  }
                  return item;
                });
                return {
                  ...st,
                  ourTeam: newStFound,
                };
              });
            } else {
              // console.log()
            }
          }
        }
      };

      await uploadSectionImg(docState, "docUploaded");
      await uploadSectionImg(certifications, "certificationsUploaded");
      await uploadSectionImg(partnerState, "partnersUploaded");
      await uploadSectionImg(dataTeam, "ourTeamsUploaded");
      await uploadSectionImg(advertisementBanner?.left, "bannerAdsLeft");
      await uploadSectionImg(advertisementBanner?.right, "bannerAdsRight");

      let data = {
        ...dataEstock,
        advertisement_banner: {
          left: advertisementBannerUploadLeft,
          right: advertisementBannerUploadRight,
        },
        doc: docUploaded,
        certificates: certificationsUploaded,
        partner: partnersUploaded,
        dataTeam: ourTeamsUploaded,
        video: videoState,
        color: color,
        commentsField: commentsFieldState,
        optionSection: optionSection,
        account: queryString.parse(location.search).newAccount || "",
      };

      if (Object.keys(listFiles).length > 0) {
        const formData = new FormData();
        formData.append("folderPath", `landing-page/${prams.symbol}`);

        Object.keys(listFiles).map((el: any) => {
          formData.append("files", listFiles[el]);
        });
        const listImg: any = await APIs.UPLOAD.uploadMultiFile({
          body: formData,
        });
        if (listImg?.length > 0) {
          listImg?.map((el: any) => {
            Object.keys(listFiles).map((item: any, indexKey: number) => {
              if (el.id === indexKey) {
                data = {
                  ...data,
                  [item]: el?.filePath,
                };
              }
            });
          });
          const eStock: any = await APIs.YOURESTOCK.updateEstock({
            symbol: prams.symbol,
            token: token,
            body: data,
          });
          if (eStock) {
            if (eStock.newAccount) {
              history.push(
                `/auth/reset-password?token=${eStock.newAccount.token}`
              );
            }
            setIsLoading(false);
            setIsEdit(false);
            toast.success("Update successfull!");
            getYourEstock();
          }
        }
      } else {
        const eStock: any = await APIs.YOURESTOCK.updateEstock({
          symbol: prams.symbol,
          token: token,
          body: data,
        });
        if (eStock) {
          if (eStock.newAccount) {
            history.push(
              `/auth/reset-password?token=${eStock.newAccount.token}`
            );
          }
          setIsLoading(false);
          setIsEdit(false);
          toast.success("Update successfull!");
          getYourEstock();
        }
      }
    } catch (error) {
      toast.error("Something went wrong!");
      setIsLoading(false);
    }
  };

  const getYourEstock = async () => {
    try {
      const yourEstock: any = await APIs.YOURESTOCK.getYourEstock(prams.symbol);

      if (yourEstock) {
        setListFiles({});
        setDataEstock({
          ...defaultData,
          ...yourEstock.eStockInfo,
        });
        setDataUser({
          ...yourEstock.userInfo,
        });
        if (yourEstock.projectId)
          setDataProject({
            ...yourEstock.projectId,
            ido: yourEstock.projectId?.ido || {},
            keep_time: yourEstock.projectId?.keep_time || {},
            doc: yourEstock.projectId?.doc || [],
          });
        if (user) {
          const IsOwnerEventOrAdmin =
            String(yourEstock.userId) === String(user.userId) ||
            user.role === "ADMIN";

          if (IsOwnerEventOrAdmin) {
            setManagersSections(["all"]);
          } else {
            const dataManagersSection =
              yourEstock.eStockManagersWithSections?.[user.userId] || [];

            setManagersSections(dataManagersSection);
          }

          setIsOwnerEventOrAdmin(IsOwnerEventOrAdmin);
        }
        if (
          yourEstock.eStockInfo?.advertisement_banner &&
          Array.isArray(yourEstock.eStockInfo?.advertisement_banner?.left) &&
          Array.isArray(yourEstock.eStockInfo?.advertisement_banner?.right)
        ) {
          setAdvertisementBanner(yourEstock.eStockInfo?.advertisement_banner);
        }
        if (yourEstock.eStockInfo?.slide) {
          setListSlide(
            yourEstock.eStockInfo?.slide.map((sli) => ({
              ...sli,
              id: uuidv4(),
              isNew: false,
            }))
          );
        }
        if (yourEstock.eStockInfo?.doc) {
          setDocState(
            yourEstock.eStockInfo?.doc.map((doc) => ({
              ...doc,
              id: uuidv4(),
              isNew: false,
            }))
          );
        }
        if (yourEstock.eStockInfo?.partner) {
          setPartnerState(
            yourEstock.eStockInfo?.partner.map((part) => ({
              ...part,
              id: uuidv4(),
              isNew: false,
            }))
          );
        }
        if (yourEstock.eStockInfo?.dataTeam) {
          setDataTeam(
            yourEstock.eStockInfo?.dataTeam.map((team) => ({
              ...team,
              ourTeam: team.ourTeam.map((our) => ({
                ...our,
                id: uuidv4(),
                isNew: false,
              })),
            }))
          );
        }
        if (yourEstock.eStockInfo?.certificates) {
          setCertifications(
            yourEstock.eStockInfo?.certificates.map((cert) => ({
              ...cert,
              id: uuidv4(),
              isNew: false,
            }))
          );
        }
        if (yourEstock.eStockInfo?.video) {
          setVideoState(yourEstock.eStockInfo?.video);
        }
        if (yourEstock?.eStockInfo?.color) {
          setColor(yourEstock?.eStockInfo?.color);
        }
        if (yourEstock?.eStockInfo?.commentsField) {
          setCommentsFieldState(yourEstock?.eStockInfo?.commentsField);
        }
        if (yourEstock.eStockInfo?.optionSection) {
          setOptionSection({
            ...optionSection,
            ...yourEstock.eStockInfo.optionSection,
          });
        }
      } else {
        console.log("vzonex-bug landing page not found");
        history.push("/");
      }
    } catch (error) {
      console.log("vzonex-bug landing page", error);
      history.push("/");
    }
  };

  const onBlurText = (e: any) => {
    setDataEstock((prev) => ({
      ...prev,
      [e.target.getAttribute("data-key")]: e.target.innerText,
    }));
  };

  const onChangeLinkCer = (e: any, key: string) => {
    setDataEstock((prev) => ({
      ...prev,
      [key]: e.target.value,
    }));
  };
  const onBlurDocument = (
    e: any,
    index: number,
    type: string,
    chil: string,
    uuid?: string
  ) => {
    if (chil === "doc") {
      const newArr: any = docState;
      newArr[index] = {
        ...docState[index],
        [type]: e.target.innerText,
      };
      setDocState(newArr);
    } else if (chil === "partner") {
      const newArr: any = partnerState;
      newArr[index] = {
        ...partnerState[index],
        [type]: e.target.value,
      };
      setPartnerState(newArr);
    } else if (chil === "ourteam") {
      if (type === "title" || type === "team_desc") {
        const newArr: any = dataTeam;
        newArr[index] = {
          ...dataTeam[index],
          [type]: e.target.innerText,
        };
        setDataTeam(newArr);
      } else {
        setDataTeam((prev) =>
          prev.map((el: any, idx: number) =>
            index === idx
              ? {
                  ...el,
                  ourTeam: dataTeam[index].ourTeam.map((item: any) =>
                    item.id === uuid
                      ? { ...item, [type]: e.target.innerText }
                      : item
                  ),
                }
              : el
          )
        );
      }
    } else {
      // console.log();
    }
  };

  useEffect(() => {
    const { token, isEdit } = queryString.parse(location.search);
    if (token && isEdit === "true") {
      setIsEdit(true);
      setToken(token);
    } else {
      setIsEdit(false);
    }
    getYourEstock();
  }, [location]);

  return (
    <>
      <Modal isOpen={isModalImage} fullscreen={true} showImage={true}>
        <PreviewAndDownload
          srcImg={srcImgPopup}
          download={downloadImage}
          closeModal={setIsModalImage}
        >
          {isDownloadCer ? (
            <CertificateLandingPage
              data={{
                dataUser: dataUser,
                dataEstock: dataEstock,
                dataProject: dataProject,
              }}
              onClickLogoCer={onClickLogoCer}
            />
          ) : (
            <img src={srcImgPopup} alt="img" />
          )}
        </PreviewAndDownload>
      </Modal>
      <Modal
        isOpen={isModal}
        title={
          typeModal === "slide"
            ? "Slide"
            : typeModal === "banner"
            ? "Link banner"
            : typeModal === "intro"
            ? "Link youtube intro"
            : typeModal === "video"
            ? "Link video"
            : typeModal === "linkouteam"
            ? "Link Out Team"
            : "Footer link"
        }
        centered={true}
        onCancel={() => setIsModal(false)}
        onConfirm={() => handleAccept()}
      >
        <AddLink>
          {typeModal === "banner" && (
            <Input
              transparent={true}
              label="Link"
              onChange={(e) => onChangLink(e, "banner")}
              placeholder="link..."
            />
          )}
          {typeModal === "intro" && (
            <Input
              transparent={true}
              label="Link"
              onChange={(e) => onChangLink(e, "linkIntro")}
              placeholder="link..."
            />
          )}
          {typeModal === "video" && (
            <Input
              transparent={true}
              label="Link"
              onChange={(e) => onChangLink(e, "video")}
              placeholder="link..."
            />
          )}
          {(typeModal === "footer" || typeModal === "linkouteam") && (
            <>
              <Input
                transparent={true}
                label="Link vdiarybook"
                onChange={(e) => onChangLink(e, "vdiarybook")}
                placeholder="link..."
              />
              <Input
                transparent={true}
                label="Link facebook"
                onChange={(e) => onChangLink(e, "facebook")}
                placeholder="link..."
              />
              <Input
                transparent={true}
                label="Link youtube"
                onChange={(e) => onChangLink(e, "youtube")}
                placeholder="link..."
              />
              <Input
                transparent={true}
                label="Link twitter"
                onChange={(e) => onChangLink(e, "twitter")}
                placeholder="link..."
              />
              <Input
                transparent={true}
                label="Link telegram"
                onChange={(e) => onChangLink(e, "telegram")}
                placeholder="link..."
              />
            </>
          )}
          {typeModal === "slide" && (
            <AddSlideBanner>
              {listSlide.map((sl: any, index: number) => (
                <SlideUpload>
                  <AddOrRemoveChil>
                    <div onClick={() => removeChil("slide", sl.id)}>-</div>
                  </AddOrRemoveChil>
                  <MovePosition>
                    {index !== 0 && (
                      <Button
                        onClick={() => handleMoveChil(index, "slide", "upward")}
                      >
                        {"<"}
                      </Button>
                    )}
                    {index !== listSlide.length - 1 && (
                      <Button
                        onClick={() =>
                          handleMoveChil(index, "slide", "downward")
                        }
                      >
                        {">"}
                      </Button>
                    )}
                  </MovePosition>
                  <Upload>
                    <UploadFile
                      id={sl.id}
                      ratio="1920x700"
                      sectionName="slide"
                      file={sl.file}
                      src={sl.src}
                      setFile={setFile}
                    />
                  </Upload>
                </SlideUpload>
              ))}
              <div
                className="add_image"
                onClick={() =>
                  setListSlide(
                    listSlide.concat({
                      id: uuidv4(),
                      src: "",
                    })
                  )
                }
              >
                +
              </div>
            </AddSlideBanner>
          )}
        </AddLink>
      </Modal>
      <Header
        bgColorHeader={color.bgColorHeader}
        isEdit={isEdit}
        role={dataUser?.role}
        urlLogo={dataEstock?.logo}
        listFiles={listFiles["logo"]}
        setFile={setFile}
      ></Header>
      <PaddingHeader></PaddingHeader>
      <Wrapper id="home">
        <Switch>
          <Route
            path={ROUTES.LANGDINGPAGE_PROJECT_WHITEPAPER}
            component={WhitePaper}
          />
          <Route
            path={ROUTES.LANGDINGPAGE_PROJECT_TERMS}
            component={WhitePaper}
          />
          <Route
            path={ROUTES.LANGDINGPAGE_PROJECT}
            render={(props) => (
              <Main
                user={user}
                color={color}
                isEdit={isEdit}
                dataProject={dataProject}
                dataUser={dataUser}
                dataEstock={dataEstock}
                listFiles={listFiles}
                docState={docState}
                imgCerRef={imgCerRef}
                certifications={certifications}
                partnerState={partnerState}
                // ourTeamState={ourTeamState}
                dataTeam={dataTeam}
                videoState={videoState}
                listSlide={listSlide}
                required={required}
                optionSection={optionSection}
                advertisementBanner={advertisementBanner}
                setAdvertisementBanner={setAdvertisementBanner}
                setDataEstock={setDataEstock}
                setDocState={setDocState}
                setFile={setFile}
                onChangeLinkCer={onChangeLinkCer}
                onBlurText={onBlurText}
                onBlurDocument={onBlurDocument}
                handleOpenPopup={handleOpenPopup}
                handleViewImage={handleViewImage}
                addChil={addChil}
                removeChil={removeChil}
                removeLastChil={removeLastChil}
                onClickLogoCer={onClickLogoCer}
                handleAddSectionTeam={handleAddSectionTeam}
                handleRemoveSectionTeam={handleRemoveSectionTeam}
                handleClickVideo={handleClickVideo}
                handleMoveChil={handleMoveChil}
                checkManagersSection={checkManagersSection}
              />
            )}
          />
        </Switch>
        {isEdit && (
          <SettingColor
            managersSections={managersSections}
            openSettingColor={openSettingColor}
            optionSetting={optionSetting}
            color={color}
            optionSection={optionSection}
            timeSetting={timeSetting}
            dataProject={dataProject}
            setOptionSection={setOptionSection}
            setOpenSettingColor={setOpenSettingColor}
            onChangeColor={onChangeColor}
            setTimeSetting={setTimeSetting}
          />
        )}
        {isEdit && (
          <>
            <Section
              bgColorDefault={"bgColorLayer1"}
              isDisable={checkManagersSection("review")}
            >
              <FormCreateContributeIdeas
                state={commentsFieldState}
                setState={setCommentsFieldState}
              />
              <UploadPdf>
                <Row>
                  <Col sm={12} md={6}>
                    <BgColor>
                      <p>Upload Whitepaper:</p> &nbsp;
                      <UploadFile
                        typeUpload="application/pdf"
                        id={`whitepaper`}
                        ratio="150x150"
                        file={listFiles[`whitepaper`]}
                        setFile={setFile}
                      />
                    </BgColor>
                  </Col>
                  <Col sm={12} md={6}>
                    <BgColor>
                      <p>Upload Policy:</p> &nbsp;
                      <UploadFile
                        typeUpload="application/pdf"
                        id={`terms`}
                        ratio="150x150"
                        file={listFiles[`terms`]}
                        setFile={setFile}
                      />
                    </BgColor>
                  </Col>
                </Row>
              </UploadPdf>
            </Section>
            <Submit>
              <Button loading={loading} onClick={handleCreateEstock}>
                Save
              </Button>
            </Submit>
          </>
        )}
      </Wrapper>
    </>
  );
};

export default withEstock(View);
