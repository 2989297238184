import { useEffect, useState } from "react";
import APIs from "api";
import Button from "components/button";
import Table from "components/table";
import { TextColor } from "pages/my/my-project/styles";
import ModalViewRequest from "components/modal-view-request";

import {
  HistoryTb,
} from "./styles";

const View = () => {
  const columnsMyProjects = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      // render: (data: any) => <span>{data?.projectId?.name}</span>,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data: any) => (
        <span>{new Date(data.createdAt).toLocaleString("en-GB")}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (data: any) => (
        <TextColor
          color={
            data?.status === "replied"
              ? "green100"
              : data?.status === "pending"
                ? "yellow100"
                : "white100"
          }
        >
          {data?.status}
        </TextColor>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (data: any) => (
        <Button
          onClick={() => {
            handleAction(data);
            setOpenModal(true);
          }}
        >
          Detail
        </Button>
      ),
    },
  ];

  const [dataTable, setDataTable] = useState([]);
  const [selectData, setSelectData] = useState(null);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleAction = (data: any) => {
    setSelectData(data);
  };

  const getSupports = async () => {
    try {
      const mySupports: any = await APIs.SUPPORT.getSupportsByUser();
      setDataTable(mySupports.support);
    } catch (error) {
      // console.log(error);
    }
  };
  useEffect(() => {
    getSupports();
  }, []);

  return (
    <>
      <HistoryTb>
        <Table
          bgTable="blue300"
          columns={columnsMyProjects}
          dataSource={dataTable}
        />
      </HistoryTb>
      <ModalViewRequest 
        type ={"support"}
        openModal={openModal}
        selectData={selectData}
        setOpenModal={setOpenModal}
      />
    </>
  );
};

export default View;
