import { Title, PartnerContainer, ImagePartners, ItemPartner } from "./styles";

import { Row, Col, Container } from "reactstrap";
import { useEffect, useState } from "react";
import APIs from "api";
import { ItemContent, Partners } from "../styles";

const OurPartners = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const didMount = async () => {
      const res: any = await APIs.SETTING.getSettingByName("partners");
      if (res && res.setting && res.setting.value) {
        setData(res.setting.value);
      }
    };
    didMount();
  }, []);

  return (
    <Container>
      <Title>
        <h3>Our Partners</h3>
        <hr />
      </Title>
      <PartnerContainer>
        <Row>
          {data.length > 0 &&
            data.map((item: any, index: number) => (
              <Col xs={6} md={3} key={index}>
                <ItemContent  data-aos="flip-left" data-aos-duration="800">
                  <Partners>
                    <ItemPartner className="item-partners">
                      <ImagePartners>
                        <img src={item.logo} alt="" />
                      </ImagePartners>
                    </ItemPartner>
                  </Partners>
                </ItemContent>
              </Col>
            ))}
        </Row>
      </PartnerContainer>
    </Container>
  );
};

export default OurPartners;
