import { useState } from "react";
import { useForm, Controller } from "react-hook-form";

import {
  ContentRightView,
  Continue,
  Confirm,
  QrCodeWrapper,
} from "../../../styles";
import Input from "components/input";
import Button from "components/button";

import { copyText, formatCurrency, randString } from "utils/common";
import { toast } from "react-toastify";
import { CopyBtn } from "./styles";
import QRCode from "react-qr-code";
import { Col, Container, Row } from "reactstrap";

const Step3 = ({ nextStep, dataPayment, setDataByKey, backStep }) => {
  const [confirmAmount] = useState(formatCurrency(dataPayment.amount));
  const [confirmString] = useState(randString(false, 10));
  // const [globalError, setGlobalError] = useState<string | null>(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const confirmSubmit = (data) => {
    if (data.cfAmount !== confirmAmount) {
      return setError("cfAmount", new Error("Invalid amount!"));
    }

    if (dataPayment.currency === "vnd" && data.cfString !== confirmString) {
      return setError("cfString", new Error("Invalid amount!"));
    }

    setDataByKey("amount", dataPayment.amount);
    setDataByKey("content", confirmString);

    nextStep();
  };

  const handleCopy = (tx: string) => () => {
    copyText(tx);
    toast.success("Copied to clipboard!");
  };

  return (
    <ContentRightView>
      <Container>
        <Row>
          <Col xs={12} md={dataPayment.currency === "vnd" ? 12 : 8}>
            {" "}
            <Input
              transparent={true}
              label="Network"
              value={String(dataPayment.network).toUpperCase()}
              disabled={true}
            />
            <Input
              transparent={true}
              label={`${dataPayment.bank && dataPayment.bank.bank_name} -  ${
                dataPayment.bank && dataPayment.bank.account_name
              }`}
              value={dataPayment.bank && dataPayment.bank.account_number}
              suffixIcon={
                <CopyBtn>
                  <img
                    src="/assets/images/icons/copy.svg"
                    alt="copy.svg"
                    onClick={handleCopy(
                      dataPayment.bank && dataPayment.bank.account_number
                    )}
                  />
                </CopyBtn>
              }
              disabled={true}
            />
            <Input
              transparent={true}
              label="Amount to be paid"
              value={
                confirmAmount + " " + String(dataPayment.currency).toUpperCase()
              }
              suffixIcon={
                <CopyBtn>
                  <img
                    src="/assets/images/icons/copy.svg"
                    alt="copy.svg"
                    onClick={handleCopy(confirmAmount)}
                  />
                </CopyBtn>
              }
              disabled={true}
            />
            {dataPayment.currency === "vnd" && (
              <Input
                transparent={true}
                disabled={true}
                label="Bank transfer content"
                placeholder="LDC9DI038K"
                value={confirmString}
                suffixIcon={
                  <img
                    src="/assets/images/icons/copy.svg"
                    alt="copy.svg"
                    onClick={handleCopy(confirmString)}
                  />
                }
              />
            )}
          </Col>
          {dataPayment.currency !== "vnd" && (
            <Col xs={12} md={4}>
              <QrCodeWrapper>
                <QRCode value={dataPayment.bank.account_number} size={150} />
              </QrCodeWrapper>
              <br />
              <small>
                {" "}
                <b>Network:</b>{" "}
                {String(dataPayment.network || "").toUpperCase()}{" "}
              </small>
              <br />
              <small>
                <b>Note:</b> Please direct to the selected network. We are not
                responsible for this issue.
              </small>
            </Col>
          )}
        </Row>
        <Confirm>
          <p>Please confirm again.</p>
          <Controller
            name="cfAmount"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                transparent={true}
                label={`The ${String(dataPayment.currency).toUpperCase()} amount you will transfer`}
                hasError={Boolean(errors.cfAmount)}
                placeholder={`10,000 ${String(
                  dataPayment.currency
                ).toUpperCase()}`}
                {...field}
              />
            )}
          />

          {dataPayment.currency === "vnd" && (
            <Controller
              name="cfString"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  transparent={true}
                  hasError={Boolean(errors.cfString)}
                  label="The bank transfer content you will use"
                  placeholder="LDC9DI038K"
                  {...field}
                />
              )}
            />
          )}
          <Continue>
            <Button onClick={handleSubmit(confirmSubmit)}>
              I’m ready to transfer money
            </Button>
            <Button transparent={true} onClick={() => backStep()}>
              Back
            </Button>
          </Continue>
        </Confirm>
      </Container>
    </ContentRightView>
  );
};

export default Step3;
