/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Container, Name, Title } from "./styles";
import Table from "components/table";
import { formatNumber } from "utils/common";

const View = (props) => {
  const { titleTable, data, type } = props;

  const all = ["name", "price", "stage", "start", "end", "goal"];
  const upcoming = ["name", "stage", "start", "goal"];
  const opening = ["name", "stage", "end", "goal"];

  const handelClickProject = (item: any) => {
    if(item.symbol === "VDC"){
      window.open(`personal/${item.symbol}`)
    } else {
      window.open(`${item.roleUser ? `${item.roleUser}/${item.symbol}` : item.pathLandingPage}`)
    }
  };
  const renderName = (item: any) => {
    return (
      <Name>
        <img src={item.logo} />
        <span> {item.name}</span>
      </Name>
    );
  };

  const _columns = [
    {
      key: "name",
      title: "Project",
      render: renderName,
    },
    {
      key: "price",
      title: "Price",
      render: (item: any) => `${item.stock_price} ${item.stock_to_buy}`,
    },
    {
      key: "stage",
      title: "Stage",
      dataIndex: "stage",
    },
    {
      key: "start",
      title: "Start Date",
      render: (item: any) =>
        `${
          item.keep_time
            ? new Date(item.keep_time.ido_open).toDateString()
            : "N/A"
        } `,
    },
    {
      key: "end",
      title: "End Date",
      render: (item: any) =>
        `${
          item.keep_time
            ? new Date(item.keep_time.ido_close).toDateString()
            : "N/A"
        } `,
    },
    {
      key: "goal",
      title: "Goal",
      render: (item: any) =>
        `${
          item.amountToSale
            ? formatNumber(+item.amountToSale / +item.stock_price)
            : "N/A"
        } (${String(item.stock_to_buy).toUpperCase()})`,
    },
  ];

  const columns = {
    all: _columns.filter((c) => all.includes(c.key)),
    upcomming: _columns.filter((c) => upcoming.includes(c.key)),
    opening: _columns.filter((c) => opening.includes(c.key)),
  };

  return (
    <Container>
      <Title>{titleTable}</Title>
      <Table
        columns={columns[type]}
        dataSource={data}
        onChange={handelClickProject}
      />
    </Container>
  );
};

export default View;
