import { useState } from "react";
import { Container } from "./styles";
import Tabs from "components/tabs";

import { BsListTask, BsClockHistory } from "react-icons/bs";
import Statis from "./Statis";
import Revenues from "./Revenues";

const View = () => {
  const [tab, setTab] = useState<string>("statis");

  return (
    <Container>
      <Tabs
        active={tab}
        tabs={[
          {
            name: "statis",
            render: (
              <>
                <BsListTask size={17} /> <span>Statistical</span>
              </>
            ),
          },
          // {
          //   name: "history",
          //   render: (
          //     <>
          //       <BsClockHistory size={17} /> <span>Histories</span>
          //     </>
          //   ),
          // },
          {
            name: "revenues",
            render: (
              <>
                <BsClockHistory size={17} /> <span>Revenues</span>
              </>
            ),
          },

        ]}
        handleTab={setTab}
      />

      {tab === "statis" && <Statis />}
      {tab === "revenues" && <Revenues />}
    </Container>
  );
};

export default View;
