/* eslint-disable react-hooks/exhaustive-deps */
import APIs from "api";
import draftToHtml from "draftjs-to-html";
import { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { Wrapper } from "./styles";

import PdfPreview from "components/pdf-preview";

const View = (props: any) => {
  const { type } = props;
  const [urlFilePdf, setUrlFilePdf] = useState<string>("");
  useEffect(() => {
    const didMount = async () => {
      try {
        const res: any = await APIs.SETTING.getSettingByName("landing_page");
        if (res && res.setting && res.setting.value) {
          if (res.setting.value[type].pdf) {
            setUrlFilePdf(res.setting.value[type].pdf);
          }
          const whitepaper = res.setting.value[type] || {};
          const html = draftToHtml(whitepaper);
          const element = document.getElementById("whitepaper-content");
          if (element) element.innerHTML = html;
        }
      } catch (error) {}
    };
    didMount();
  }, []);

  return (
    <Wrapper>
      {urlFilePdf ? (
        <PdfPreview
          symbol={"vzx"}
          title={
            type === "whitepaper"
              ? "whitepaper"
              : "User Agreement and Privacy Policy"
          }
          urlPdf={`${process.env.REACT_APP_API_URL}/pdf-preview?url=${urlFilePdf}`}
        />
      ) : (
        <Container>
          <div id="whitepaper-content"></div>
        </Container>
      )}
    </Wrapper>
  );
};

export default View;
