/* eslint-disable indent */
/* eslint-disable no-empty-pattern */
import { createSlice } from "@reduxjs/toolkit";
import { getPurchasesAsync } from "./purchases.actions";

export interface IPruchasesState {
  projects: [];
  products: [];
  packages: [];
}

const initialState: IPruchasesState = {
  products: [],
  packages: [],
  projects: [],
};

export const PurchaseSlice = createSlice({
  name: "order",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPurchasesAsync.pending, (state) => {})
      .addCase(getPurchasesAsync.fulfilled, (state, action) => {
        const { type, purchases, errors } = action.payload;
        if (errors) {
          return;
        }
        switch (type) {
          case "project":
            state.projects = purchases;
            break;
          case "product":
            state.products = purchases;
            break;
          case "package":
            state.packages = purchases;
            break;
        }
      });
  },
});

export const {} = PurchaseSlice.actions;

export default PurchaseSlice.reducer;
