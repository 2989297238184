/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container } from "./styles";
import Tabs from "components/tabs";
import {
  BsClockHistory,
  BsCloudDownloadFill,
  BsListTask,
} from "react-icons/bs";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { getProjectById } from "redux/reducers/projects/projects.action";
import { getProjectSelected } from "redux/reducers/projects/projects.selector";
import Stages from "./components/Stages";
import Rounds from "./components/Rounds";
import Pixels from "./components/Pixel";
import Histories from "./components/Histories";
import { useHistory } from "react-router-dom";
import useQuery from "hooks/useQuery";

const View = (props) => {
  const { projectId } = useParams();
  const [tab, setTab] = useState<string>("stages");
  const dispatch = useAppDispatch();
  const project = useAppSelector(getProjectSelected);
  const history = useHistory();
  const query = useQuery();

  useEffect(() => {
    dispatch(
      getProjectById({
        params: {
          id: projectId,
        },
      })
    );
  }, [projectId]);

  useEffect(() => {
    if (query && query.tab) {
      setTab(query.tab);
    } else if (project) {
      if (project.type_sell === "round") setTab("stages");
      else setTab("pixels");
    }
  }, [project, query]);

  const handleChangeTab = (value) => {
    setTab(value);
    history.push(`?tab=${value}`);
  };

  const tab1 = [
    {
      name: "stages",
      render: (
        <>
          <BsListTask size={17} /> <span>Stages</span>
        </>
      ),
    },
  ];
  const tab2 = [
    {
      name: "pixels",
      render: (
        <>
          <BsListTask size={17} /> <span>Pixels</span>
        </>
      ),
    },
    {
      name: "rounds",
      render: (
        <>
          <BsCloudDownloadFill size={17} /> <span>Rounds</span>
        </>
      ),
    },
  ];

  return (
    <Container>
      <Tabs
        active={tab}
        tabs={[
          ...(project?.type_sell !== "round" ? tab2 : tab1),
          {
            name: "histories",
            render: (
              <>
                <BsClockHistory size={17} /> <span>History</span>
              </>
            ),
          },
        ]}
        handleTab={handleChangeTab}
      />

      {tab === "stages" && <Stages />}
      {tab === "rounds" && <Rounds />}
      {tab === "pixels" && <Pixels />}
      {tab === "histories" && <Histories />}
    </Container>
  );
};

export default View;
