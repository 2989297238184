/* eslint-disable react-hooks/exhaustive-deps */
import APIs from "api";
import AuthLayout from "layouts/AuthLayout";

// import Button from "components/button";
// import { ROUTES } from "config/routes";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import DateTimeEvent from "../components/date-event";
import Banner from "components/business-landing-page/components/Banner";

// import { FaShare } from "react-icons/fa";

import Wapprer, {
  Container,
  EventDetail,
  BannerEvent,
  Content,
  // Action,
  NameEvent,
  FilterHeader,
  ItemStatus,
} from "./styles";
import { compareDateWithCur } from "utils/times";
import {
  getEventTypeFromRoute,
  eventType,
  eventTypeMeta,
  getEventRoute,
} from "../components/constants";

const View = () => {
  const history = useHistory();

  const [listEvents, setListEvents] = useState<any>([]);
  const [listFilter, setListFilter] = useState<any>([]);

  const [active, setActive] = useState<string>("all");

  const eventTypeFromRoute = useMemo(() => {
    const selectedEventType = getEventTypeFromRoute(window.location.pathname);
    return selectedEventType;
  }, [window.location.pathname]);

  const handleFilter = (type: string) => {
    if (Object.keys(eventType).includes(type)) {
      const route = eventTypeMeta[type].routes.root;
      console.log("route router event", route);
      history.push(route);
    } else {
      setActive(type);
    }
  };

  const handleClickEvent = (event: any) => {
    const route = getEventRoute(event, "detail");
    history.push(route.replace(":id", event._id));
  };

  const getEvents = async () => {
    try {
      const events: any = await APIs.EVENT.getEvents();
      setListEvents(events);
      setListFilter(events);
    } catch (error) {}
  };

  useEffect(() => {
    getEvents();
  }, []);

  useEffect(() => {
    if (eventTypeFromRoute && listEvents && active) {
      console.log("activelistEvents", active, listEvents);

      setListFilter(
        listEvents.filter((el) => {
          let secondCondition = true;
          if (active === "all") {
            secondCondition = true;
          }

          if (active === "upcoming") {
            secondCondition = compareDateWithCur(el.event.start_day) > 0;
          }

          if (active === "happening") {
            secondCondition =
              compareDateWithCur(el.event.start_day) < 0 &&
              compareDateWithCur(el.event.end_day) > 0;
          }

          if (active === "ended") {
            secondCondition = compareDateWithCur(el.event.end_day) < 0;
          }

          if (
            (el.event.eventType || eventType.smart_event) ===
              eventTypeFromRoute &&
            secondCondition
          ) {
            return true;
          }

          return false;
        })
      );
    }
  }, [eventTypeFromRoute, listEvents, active]);

  return (
    <AuthLayout page="event">
      <Wapprer>
        <div className="filter-header">
          <FilterHeader>
            <ItemStatus
              active={active === "all"}
              onClick={() => handleFilter("all")}
            >
              Tất cả
            </ItemStatus>
            <ItemStatus
              active={active === "upcoming"}
              onClick={() => handleFilter("upcoming")}
            >
              Sắp diễn ra
            </ItemStatus>
            <ItemStatus
              active={active === "happening"}
              onClick={() => handleFilter("happening")}
            >
              Đang diễn ra
            </ItemStatus>
            <ItemStatus
              active={active === "ended"}
              onClick={() => handleFilter("ended")}
            >
              Đã kết thúc
            </ItemStatus>
          </FilterHeader>

          <div className={`event-filter-smart`}>
            {Object.keys(eventType).map((key) => {
              return (
                <div
                  key={key}
                  // active={eventTypeFromRoute === key}
                  className={`${eventTypeFromRoute === key ? "active" : ""}`}
                  onClick={() => handleFilter(key)}
                >
                  {/* {key.split("_").join(" ")} */}
                  <img alt="" src={`/assets/images/events/${key}/${key}.png`} />
                </div>
              );
            })}
          </div>
        </div>

        <div className="sca">
          <Container>
            {listFilter &&
              listFilter.length > 0 &&
              listFilter.map((item) => (
                <EventDetail
                  key={item._id}
                  onClick={() => handleClickEvent(item)}
                >
                  <div className="_event">
                    <BannerEvent>
                      <Banner
                        isEdit={false}
                        isSlide={
                          item.event.banner?.slide &&
                          item.event.banner?.slide?.length > 0
                        }
                        minHeight={"100%"}
                        linkVideo={item.event.banner?.link}
                        listSlideBanner={item.event.banner?.slide}
                      />
                    </BannerEvent>
                    <Content>
                      <NameEvent>{item.event.name.toUpperCase()}</NameEvent>
                      <DateTimeEvent data={item.event} />
                    </Content>
                  </div>
                  {/* <Action>
                  <Button disabled>Like</Button>
                  <Button disabled>
                    <FaShare size={20} />
                  </Button>
                </Action> */}
                </EventDetail>
              ))}
            {listFilter.length === 0 && <div>No events</div>}
          </Container>
        </div>
      </Wapprer>
    </AuthLayout>
  );
};

export default View;
