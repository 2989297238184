import {
  Container,
  TopMain,
  ItemBalance,
  LeftView,
  RightView,
  Title,
  Content,
  Detail,
  Action,
  His,
  PColor,
  TextHold,
  ShowLeftView,
} from "./styles";
import { Route, Switch, Link, useLocation } from "react-router-dom";
import { Row, Col, Label, FormGroup, Input as InputB } from "reactstrap";
import Button from "components/button";
import queryString from "query-string";

import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { getCryptos } from "redux/reducers/cryptos/cryptos.selector";
import {
  getBalances,
  getLockedBalances,
} from "redux/reducers/users/users.selector";
import { getCryptosAsync } from "redux/reducers/cryptos/cryptos.actions";

import { formatNumber } from "utils/common";
import { ROUTES } from "config/routes";
import WithdrawVND from "./components/withdraw-vnd";
import DepositVnd from "./components/deposit-vnd";
import DepositVnp from "./components/deposit-vnpay";
import DepositStock from "./components/deposit-stock";
import WithdrawStock from "./components/withdraw-stock";
import HistoryStock from "./components/history-stock";
import SellStock from "./components/sell-stock";
import { getProjects } from "redux/reducers/projects/projects.selector";
import { getProjectsAsync } from "redux/reducers/projects/projects.action";
import DepositP2P from "./components/p2p/deposit-p2p";
import depositSpot from "./components/spot/deposit-spot";
import WithdrawP2P from "./components/p2p/withdraw-p2p";
import WithdrawSpot from "./components/spot/withdraw-spot";
import { IoMdContract } from "react-icons/io";
import { TbWorld } from "react-icons/tb";
import WithdrawCV from "./components/withdraw-cv";
import WithdrawQrMart from "./components/withdraw-qrmart";
import { RiExchangeLine } from "react-icons/ri";
import Transfer from "./components/transfer";
import WithdrawVdiarybook from "./components/withdraw-vdiarybook";

const View = (props) => {
  const location: any = useLocation();
  const address = queryString.parse(location.search)?.address;
  const amountStock = queryString.parse(location.search)?.amountStock;
  const [isQrScanWithdraw, setIsQrScanWithdraw] = useState<boolean>(
    Boolean(amountStock && address)
  );

  const dispatch = useAppDispatch();
  const cryptos = useAppSelector(getCryptos);
  const balances = useAppSelector(getBalances);
  const locked_balances = useAppSelector(getLockedBalances);
  const projects = useAppSelector(getProjects);

  const [subWallets, setSubWallets] = useState([]);
  const [wallets, setListWallets] = useState([]);
  const [isShowAll, setIsShowAll] = useState(false);
  const [isShowAllSub, setIsShowAllSub] = useState(false);

  useEffect(() => {
    if (cryptos && cryptos.length > 0 && balances) {
      const _wallets = cryptos.map((token) => ({
        ...token,
        balance: balances[token.symbol] ? Number(balances[token.symbol]) : 0,
        locked_balances: locked_balances[token.symbol]
          ? Number(locked_balances[token.symbol])
          : 0,
      }));

      const _walletVnex = _wallets.find((w) => w.symbol === "vnex");
      const _walletsNormal = _wallets.filter(
        (w) => w.symbol !== "vnex" && (isShowAll ? true : +w.balance > 0)
      );
      setListWallets([_walletVnex, ..._walletsNormal]);
    }
  }, [cryptos, balances, locked_balances, isShowAll]);

  useEffect(() => {
    if (projects.length > 0 && balances) {
      const _wallets = projects
        .filter(
          (p) =>
            ["closed", "opening"].includes(p.status) &&
            !cryptos.some((c) => c.symbol === p.symbol)
        )
        .map((token) => ({
          ...token,
          balance: balances[token.symbol] ? Number(balances[token.symbol]) : 0,
          locked_balances: locked_balances[token.symbol]
            ? Number(locked_balances[token.symbol])
            : 0,
        }));
      const _walletsNormal = _wallets.filter((w) =>
        isShowAllSub ? true : +w.balance > 0
      );
      setSubWallets(_walletsNormal);
    }
  }, [projects, cryptos, balances, locked_balances, isShowAllSub]);

  useEffect(() => {
    dispatch(getCryptosAsync());
    dispatch(getProjectsAsync());
  }, [dispatch]);

  const scroll = () => {
    document.getElementById("right").scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setTimeout(() => {
      scroll();
    }, 100);
  }, []);

  return (
    <Container color={"black100"}>
      {address && (
        <ShowLeftView
          isShow={!isQrScanWithdraw}
          onClick={() => setIsQrScanWithdraw(!isQrScanWithdraw)}
        >
          {isQrScanWithdraw ? "Show" : "Hide"} my assets
        </ShowLeftView>
      )}
      {!isQrScanWithdraw && (
        <TopMain>
          <ItemBalance>
            <p>Estimated assets value:</p>
            <h2>
              <span>{formatNumber(balances.total_vnex || 0)}</span> VNEX
            </h2>
          </ItemBalance>
          <ItemBalance isLocked={true}>
            <p>Hold balances:</p>
            <h2>
              <span>{formatNumber(locked_balances.total_vnex || 0)}</span> VNEX
            </h2>
          </ItemBalance>
        </TopMain>
      )}
      <Row>
        {!isQrScanWithdraw && (
          <Col col lg={6} xl={5}>
            <LeftView>
              <Title bgColor={"grey200"} just="space-between">
                <p>e.NFT Stocks</p>
                <FormGroup check>
                  <Label check>
                    <InputB
                      type="checkbox"
                      checked={isShowAll}
                      onChange={(e) => setIsShowAll(e.target.checked)}
                    />{" "}
                    Show all stock
                  </Label>
                </FormGroup>
              </Title>
              {wallets.map((item: any, index: number) => (
                <Content key={index}>
                  <Row lg={12} md={12}>
                    <Col xs={8} lg={6}>
                      <Detail>
                        <h6>
                          {" "}
                          <img
                            src={item.logo || "/assets/images/coins/btc.svg"}
                            alt={item.symbol}
                          />{" "}
                          {item.name}{" "}
                          {item.symbol !== "vnex" && (
                            <span>{item.symbol.toUpperCase()}</span>
                          )}
                        </h6>
                        <h6>
                          <span>{formatNumber(+item.balance)} </span>{" "}
                          {item.symbol.toUpperCase()}
                        </h6>
                        {+item.locked_balances > 0 && (
                          <TextHold>
                            Hold:{" "}
                            <span>{formatNumber(+item.locked_balances)} </span>{" "}
                            {item.symbol.toUpperCase()}
                          </TextHold>
                        )}
                      </Detail>
                    </Col>
                    <Col xs={4} lg={6}>
                      <Action>
                        <Link
                          to={
                            item.is_deposit &&
                            `/my/wallets/${item.symbol}/deposit`
                          }
                        >
                          <Button
                            height="40px"
                            onClick={scroll}
                            disabled={!item.is_deposit}
                          >
                            Deposit
                          </Button>
                        </Link>
                        <Link
                          to={
                            item.is_withdraw &&
                            `/my/wallets/${item.symbol}/withdraw`
                          }
                        >
                          <Button
                            height="40px"
                            color="green100"
                            onClick={scroll}
                            disabled={!item.is_withdraw}
                          >
                            Withdraw
                          </Button>
                        </Link>
                      </Action>
                    </Col>
                  </Row>
                  <div>
                    <His>
                      <Link to={`/my/wallets/${item.symbol}/history`}>
                        <PColor
                          color={
                            props?.location?.pathname?.includes(
                              `${item.symbol}/history`
                            ) && "orange100"
                          }
                          onClick={scroll}
                        >
                          <img
                            src={`/assets/images/icons/${
                              props?.location?.pathname?.includes(
                                `${item.symbol}/history`
                              )
                                ? "historyActive.svg"
                                : "history.svg"
                            }`}
                            alt="BTC"
                          />{" "}
                          History
                        </PColor>
                      </Link>

                      <Link to={`/my/wallets/${item.symbol}/sell`}>
                        <PColor
                          color={
                            props?.location?.pathname?.includes(
                              `${item.symbol}/sell`
                            ) && "orange100"
                          }
                          onClick={scroll}
                        >
                          <img
                            src="/assets/images/icons/sellCard.svg"
                            alt="BTC"
                          />{" "}
                          Sell to cash
                        </PColor>
                      </Link>
                      <PColor onClick={scroll}>
                        <IoMdContract />
                        <a
                          href={item.contract}
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          Contract
                        </a>
                      </PColor>
                      <PColor onClick={scroll}>
                        <TbWorld />
                        <a href={item.web} target={"_blank"} rel="noreferrer">
                          Website
                        </a>
                      </PColor>
                      {item?.is_transfer_to_trade && (
                        <Link to={`/my/wallets/${item.symbol}/transfer`}>
                          <PColor
                            color={
                              props?.location?.pathname?.includes(
                                `${item.symbol}/transfer`
                              ) && "orange100"
                            }
                            onClick={scroll}
                          >
                            <RiExchangeLine />
                            Transfer
                          </PColor>
                        </Link>
                      )}
                    </His>
                  </div>
                </Content>
              ))}

              <Title bgColor={"grey200"} just="space-between">
                <p>Projects is Launched</p>
                <FormGroup check>
                  <Label check>
                    <InputB
                      type="checkbox"
                      checked={isShowAllSub}
                      onChange={(e) => setIsShowAllSub(e.target.checked)}
                    />{" "}
                    Show all stock
                  </Label>
                </FormGroup>
              </Title>
              {subWallets.map((item: any, index: number) => (
                <Content key={index}>
                  <Row lg={12} md={12}>
                    <Col xs={8} lg={6}>
                      <Detail>
                        <h6>
                          {" "}
                          <img
                            src={item.logo || "/assets/images/coins/btc.svg"}
                            alt={item.symbol}
                          />{" "}
                          {item.name}{" "}
                          {item.symbol !== "vnex" && (
                            <span>{item.symbol.toUpperCase()}</span>
                          )}
                        </h6>
                        <h6>
                          <span>{formatNumber(+item.balance)} </span>{" "}
                          {item.symbol.toUpperCase()}
                        </h6>
                        {+item.locked_balances > 0 && (
                          <TextHold>
                            Hold:{" "}
                            <span>{formatNumber(+item.locked_balances)} </span>{" "}
                            {item.symbol.toUpperCase()}
                          </TextHold>
                        )}
                      </Detail>
                    </Col>
                    <Col xs={4} lg={6}>
                      <Action>
                        <Link>
                          <Button height="40px" onClick={scroll} disabled>
                            Deposit
                          </Button>
                        </Link>
                        <Link>
                          <Button
                            height="40px"
                            color="green100"
                            onClick={scroll}
                            disabled
                          >
                            Withdraw
                          </Button>
                        </Link>
                      </Action>
                    </Col>
                  </Row>
                  <div>
                    <His>
                      <Link to={`/my/wallets/${item.symbol}/history`}>
                        <PColor
                          color={
                            props?.location?.pathname?.includes(
                              `${item.symbol}/history`
                            ) && "orange100"
                          }
                          onClick={scroll}
                        >
                          <img
                            src={`/assets/images/icons/${
                              props?.location?.pathname?.includes(
                                `${item.symbol}/history`
                              )
                                ? "historyActive.svg"
                                : "history.svg"
                            }`}
                            alt="BTC"
                          />{" "}
                          History
                        </PColor>
                      </Link>
                      <Link to={`/certificate/${item._id}`} target={"_blank"}>
                        <PColor onClick={scroll}>
                          <img
                            src="/assets/images/icons/sellCard.svg"
                            alt="BTC"
                          />{" "}
                          Certificate
                        </PColor>
                      </Link>
                      <Link to={`/my/wallets/${item.symbol}/sell-to-cash`}>
                        <PColor onClick={scroll}>
                          <img
                            src="/assets/images/icons/sellCard.svg"
                            alt="BTC"
                          />{" "}
                          Sell to cash
                        </PColor>
                      </Link>
                    </His>
                  </div>
                </Content>
              ))}
            </LeftView>
          </Col>
        )}
        <Col col lg={6} xl={7}>
          <RightView id="right">
            <Switch>
              <Route path={ROUTES.DEPOSIT_VND} component={DepositVnd} />
              <Route path={ROUTES.DEPOSIT_VNP} component={DepositVnp} />
              <Route path={ROUTES.WITHDRAW_VND} component={WithdrawVND} />
              <Route path={ROUTES.DEPOSIT_P2P} component={DepositP2P} />
              <Route path={ROUTES.WITHDRAW_P2P} component={WithdrawP2P} />
              <Route path={ROUTES.DEPOSIT_SPOT} component={depositSpot} />
              <Route path={ROUTES.WITHDRAW_SPOT} component={WithdrawSpot} />
              <Route path={ROUTES.WITHDRAW_CV} component={WithdrawCV} />
              <Route path={ROUTES.WITHDRAW_QRMART} component={WithdrawQrMart} />
              <Route
                path={ROUTES.WITHDRAW_VDIARYBOOK}
                component={WithdrawVdiarybook}
              />
              <Route path={ROUTES.DEPOSIT_STOCK} component={DepositStock} />
              <Route path={ROUTES.WITHDRAW_STOCK} component={WithdrawStock} />
              <Route path={ROUTES.HISTORY_STOCK} component={HistoryStock} />
              <Route path={ROUTES.SELL_STOCK} component={SellStock} />
              <Route path={ROUTES.TRANSFER} component={Transfer} />
            </Switch>
          </RightView>
        </Col>
      </Row>
    </Container>
  );
};

export default View;
