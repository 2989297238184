/* eslint-disable no-empty-pattern */
import { createSlice } from "@reduxjs/toolkit";
import { getProductsAsync } from "./products.actions";

export interface IProductsState {
  products: any;
}

const initialState: IProductsState = {
  products: [],
};

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProductsAsync.pending, (state) => {})
      .addCase(getProductsAsync.fulfilled, (state, action) => {
        const { products, errors } = action.payload;

        if (errors) {
          return;
        }
        state.products = products;
      });
  },
});

export const {} = productsSlice.actions;

export default productsSlice.reducer;
