import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const View = ({data}: any) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };

  
  const openWindown = (link: string) => {
    window.open(link)
  }

  return <>
    <Slider {...settings}>
      {
        data?.map(item => 
          <img src={item?.src} alt="" onClick={() => item.link && openWindown(item.link)} />
        )
      }
		 </Slider>
  </>
}

export default View