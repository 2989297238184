import styled from "styled-components";

export const Container = styled.div`
  margin-top: 40px;
  background: ${(props) => props.theme.colors["black100"]};
  border-radius: 8px;
  width: 100%;
  padding: 30px;
  overflow: hidden;
  button {
    font-weight: 600;
    margin-bottom: 20px;
  }
`;

export const WrapperLeft = styled.div<{ props: any }>`
  position: fixed;
  z-index: 100000;
  bottom: 13px;
  left: ${(props) => (!props.props.isHome ? "250px" : "10px")};
  text-align: center;
  .close {
    position: absolute;
    right: 0;
    color: red;
    top: 0;
    cursor: pointer;
  }
`;

export const WrapperRight = styled.div`
  position: fixed;
  z-index: 100000;
  bottom: 13px;
  right: 10px;
  text-align: center;
  .close {
    position: absolute;
    right: 0;
    color: red;
    top: 0;
  }
`;

export const Wrapper = styled.div`
  img {
    width: 100px;
  }
  p {
    max-width: 200px;
    font-size: 17px;
    line-height: 22px;
    filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.25));
    color: rgb(255, 255, 255);
    text-shadow: rgb(180 0 14) 1px 0 0, rgb(180 0 14) 0 1px 0,
      rgb(180 0 14) -1px 0 0, rgb(180 0 14) 0 -1px 0;
    margin-top: -30px !important;
  }
  a {
    height: 30px;
    width: 150px;
    display: flex;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 4px;
    margin: 2px auto 0;
    font-size: 12px;
    padding: 0 16px;
    border: 1px solid transparent;
    z-index: 1;
    position: relative;
    color: #ffffff !important;
    background: linear-gradient(
      90deg,
      rgb(243, 177, 51) 0%,
      rgb(243, 140, 49) 100%
    );
    border-color: rgb(255, 255, 255);
    text-decoration: none;
  }
`;

export const CenterWrapper = styled.div`
  max-width: 500px;
  overflow: hidden;
  text-align: center;
  a {
    text-decoration: none;
  }
`;

export const DiscoverWrapper = styled.div`
  /* width: 100%;
  text-align: center;
  margin-top: 20px;
  height: 50px; */

  a {
    /* width: 250px; */
    text-decoration: none;
    transform: translate(-50%, 30px);
    /* box-shadow: 0 4px 4px rgb(0 0 0 / 15%); */
    /* border-radius: 10px; */
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    transition: transform 0.1s linear;
    color: #ffffff !important;
    /* background: linear-gradient(
      90deg,
      rgb(243, 177, 51) 0%,
      rgb(243, 140, 49) 100%
    ); */
    /* border-color: rgb(255, 255, 255); */
    /* padding: 10px 15px; */
  }
`;

export const CountdownWrapper = styled.div`
  .countdown-section {
    justify-content: left !important;
    padding-bottom: 0px !important;
    .countdown-item-value {
      background-image: linear-gradient(90deg, #21d397 0, #7450fe) !important;
      width: 40px !important;
      height: 40px !important;
    }
  }
`;

export const TextWrapper = styled.div`
  p {
    font-size: 18px;
    font-weight: 600;
    color: ${(props) => props.theme.colors["green100"]};
  }
`;

export const Image = styled.img`
  /* max-height: 250px; */
  margin-bottom: 15px;
`;
