// import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { AddOrRemoveChil } from "pages/home/landing-page/styles";
import APIs from "api";

import { Section, Title } from "pages/events/styles";
import UploadFile from "components/uploadFile";
import Input from "components/input";

import { UploadCalendarImg } from "./styles";
import { useState } from "react";

const openTagWindow = (url: string) => {
  window.open(url);
};

export default function View(props: any) {
  const { isEdit, hasWallpaper, background2Color, dataEvent, setDataEvent } =
    props;

  const [loading, setLoading] = useState(false);

  const setValueForContent = (itemId, key, value) => {
    setDataEvent((prevEvent) => {
      return {
        ...prevEvent,
        event_calendars: {
          ...prevEvent.event_calendars,
          content: prevEvent.event_calendars.content.map((itemCalenar) => {
            if (itemCalenar.id === itemId) {
              return {
                ...itemCalenar,
                [key]: value,
              };
            }
            return itemCalenar;
          }),
        },
      };
    });
  };

  const onBlurText = (item, e) => {
    setValueForContent(item.id, "title", e.target.innerText);
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("folderPath", `event`);

    formData.append("files", file);

    const listImg: any = await APIs.UPLOAD.uploadMultiFile({
      body: formData,
    });

    const img = listImg[0];
    const src = img.filePath;

    return src;
  };

  const onSetFile = async (item, fileUpload) => {
    setLoading(true);
    const srcFile = await uploadFile(fileUpload);

    setValueForContent(item.id, "src", srcFile);
    setLoading(false);
  };

  const onAddNewSection = () => {
    setDataEvent((prevEvent) => {
      return {
        ...prevEvent,
        event_calendars: {
          ...prevEvent.event_calendars,
          content: prevEvent.event_calendars.content.concat({
            id: uuidv4(),
            title: "Lịch làm việc",
            src: "",
            link: "",
          }),
        },
      };
    });
  };

  const onRemoveSection = () => {
    setDataEvent((prevEvent) => {
      return {
        ...prevEvent,
        event_calendars: {
          ...prevEvent.event_calendars,
          content: prevEvent.event_calendars.content.slice(
            0,
            prevEvent.event_calendars.content.length === 1
              ? 1
              : prevEvent.event_calendars.content.length - 1
          ),
        },
      };
    });
  };

  return (
    <Section
      hasWallpaper={hasWallpaper}
      bgColor={background2Color}
      // isDisable={checkManagersSection("organization")}
    >
      <div
        style={{
          position: "relative",
        }}
      >
        {isEdit && (
          <AddOrRemoveChil mt="20px">
            <div onClick={onAddNewSection}>+</div>
            <div onClick={onRemoveSection}>-</div>
          </AddOrRemoveChil>
        )}

        {dataEvent?.event_calendars?.content?.map((item: any) => (
          <div
            key={item.id}
            style={{
              position: "relative",
              marginBottom: "35px",
            }}
          >
            <Title mb={"30px"} type={""} color={dataEvent.color.title}>
              <h4 contentEditable={isEdit} onBlur={onBlurText.bind(null, item)}>
                {item.title}
              </h4>
            </Title>
            <UploadCalendarImg
              onClick={() =>
                !isEdit ? openTagWindow(item?.link) : ""
              }
            >
              {isEdit ? (
                <UploadFile
                  width='100%'
                  className='event-calendar-uploads'
                  id={`upload_${item.id}`}
                  sectionName="doc"
                  ratio="500x500"
                  file={item.file}
                  src={item.src}
                  setFile={onSetFile.bind(null, item)}
                  loading={loading}
                />
              ) : (
                <img src={item.src || "/assets/images/vzx-64.png"} alt="" />
              )}

              <div hidden={!isEdit} style={{width: "100%"}}>
                <Input
                  transparent={true}
                  color={"orange"}
                  defaultValue={item?.link}
                  onBlur={(e) =>
                    setValueForContent(item.id, "link", e.target.value)
                  }
                  placeholder="Link"
                />
              </div>
            </UploadCalendarImg>
          </div>
        ))}
      </div>
    </Section>
  );
}
