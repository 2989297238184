import { IRootState } from "..";

export const getCryptosByObject = (state: IRootState) => {
  const result = state.cryptos.cryptos.reduce(
    (obj, cur) => ({ ...obj, [cur.symbol]: cur }),
    {}
  );
  return result;
};
export const getCryptos = (state: IRootState) => state.cryptos.cryptos;
export const getStocks = (state: IRootState) => state.cryptos.cryptos;
export const getStocksSwap = (state: IRootState) => state.cryptos.stocksSwap;
export const getStocksProjectSwap = (state: IRootState) => state.cryptos.stocksProjectSwap;
export const getStocksPrice = (state: IRootState) => state.cryptos.stocksPrice;
export const getHistorySwap = (state: IRootState) => state.cryptos.historySwap;
