import Table from "components/table";
import { TextColor } from "pages/my/profile/styles";
import { FlexColumn, HeadProfit } from "../styles";
import { formatCurrency } from "utils/common";

const View = (props: any) => {
  const { total, dataSource } = props
  const columnsDetailShare = [
    {
      title: 'To',
      dataIndex: 'to',
      key: 'to',
      render: (data: any) => <FlexColumn fisrtBold={true}>
        <span>{data?.userId === "ADMIN" ? "ADMIN" : data?.userId?.fullName}</span>
        <span>{data?.userId !== "ADMIN" && `(${data?.userId?.refer_code})`}</span>
      </FlexColumn>
    },
    {
      title: 'Amount Received',
      dataIndex: 'amount',
      key: 'amount',
      render: (data: any) => <span>{`${formatCurrency(data?.amountReceived)} (${data?.symbol.toUpperCase()})`}</span>
    },
    {
      title: 'Amount VNEX',
      dataIndex: 'amountVnex',
      key: 'amountVnex',
      render: (data: any) => <span>{formatCurrency(data?.amountVnex)}</span>
    },
    {
      title: 'Transaction Type',
      dataIndex: 'key',
      key: 'key',
      render: (data: any) => <FlexColumn>
        <span>{`${data?.key === "admin" ? "commissions" : data?.key} (${data?.percent}%)`}</span>
        {data?.share && <span>{data?.share}</span>}
      </FlexColumn> 
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (data: any) => 
        <TextColor color={data?.status === "success" ? "green100" : "yellow100"}>
          {data?.status || "pending"}
        </TextColor>
    }
  ];

  return <>
    <HeadProfit>Total profit sharing: {formatCurrency(total)} VNEX</HeadProfit>
    <Table bgTable="blue300" columns={columnsDetailShare} dataSource={dataSource}></Table>
  </>
}

export default View;