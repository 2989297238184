import styled from "styled-components";

export const Content = styled.div`
  padding: 10px;
  max-width: 900px;
  width: 100%;
  max-height: calc(var(--vh, 1vh) * 100 - 300px);
  overflow: auto;
`;

export const Item = styled.div`
  margin-bottom: 40px;
  padding-left: 20px;
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
`;

export const Detail = styled.div`
  padding-left: 15px;
`;

export const AttachmentsContainer = styled.div`
  margin-top: 30px;
`;

export const ImageAt = styled.div`
  display: flex;
  flex-wrap: wrap;

  img {
    width: auto;
    height: 100%;
    max-height: 150px;
    margin: 5px;
  }
`;

export const CententReply = styled.div`
  p{
    white-space: pre-line;
  }
`;
