import React from "react";
import QRCode from "react-qr-code";

import { QrCodeWrapper } from "pages/home/landing-page/styles";
import {
  Section,
  Title,
  Content,
  ItemFlex,
  Statistical,
  Action,
} from "pages/events/styles";

import DateTimeEvent from "../date-event";

import { formatNumber } from "utils/common";
import { TiTick } from "react-icons/ti";
import { eventType, getEventRoute, getEventTypeFromObj } from "../constants";
import CharityDonateForm from "../charity/FormDonate";

export default function View(props: any) {
  const {
    id,
    isEdit,
    dataEvent,
    hasWallpaper,
    background2Color,
    onBlurText,
    handleInteractiveOrSurvey,
    user,
    domain,
    setDataEvent,
  } = props;

  return (
    <Section hasWallpaper={hasWallpaper} bgColor={background2Color}>
      <Title color={dataEvent.color.title}>
        <h2 className="event-title" data-key={"name"} contentEditable={isEdit} onBlur={onBlurText}>
          {dataEvent.name.toUpperCase()}
        </h2>
      </Title>
      <Title color={dataEvent.color.title}>
        <h3
          data-key={"description"}
          contentEditable={isEdit}
          onBlur={onBlurText}
        >
          {dataEvent.description.toUpperCase()}
        </h3>
      </Title>
      <Content>
        <ItemFlex just_content="space-between" isColumn={true}>
          <Statistical>
            <ItemFlex isNoWrap={true} just_content="center">
              <Action
                disabled={isEdit}
                bgColor="orange100"
                onClick={() =>
                  handleInteractiveOrSurvey("care_abouts", "interactive")
                }
              >
                {dataEvent.interactive?.care_abouts?.includes(user?._id) && (
                  <TiTick size={18} />
                )}
                Quan tâm (
                {formatNumber(
                  Number(dataEvent.interactive?.care_abouts?.length || 0) +
                    Number(dataEvent.ca || 0),
                  0
                )}
                )
              </Action>
              <Action
                disabled={isEdit}
                bgColor="red100"
                onClick={() =>
                  handleInteractiveOrSurvey("participations", "interactive")
                }
              >
                {dataEvent.interactive?.participations?.includes(user?._id) && (
                  <TiTick size={18} />
                )}
                Tham gia (
                {formatNumber(
                  Number(dataEvent.interactive?.participations?.length || 0) +
                    Number(dataEvent.pa || 0),
                  0
                )}
                )
              </Action>
              <Action
                disabled={isEdit}
                bgColor="blue300"
                onClick={() =>
                  handleInteractiveOrSurvey("to_share", "interactive")
                }
              >
                Chia sẻ (
                {formatNumber(
                  Number(dataEvent.interactive?.to_share?.length || 0) +
                    Number(dataEvent.sh || 0),
                  0
                )}
                )
              </Action>
            </ItemFlex>
            <br />
            <QrCodeWrapper>
              <QRCode
                value={`${domain}${getEventRoute({event: dataEvent}, "root")}/${id}`}
                size={150}
              />
            </QrCodeWrapper>
          </Statistical>
          <div>
            <DateTimeEvent
              isEdit={isEdit}
              data={dataEvent}
              textColor={dataEvent.color.text}
              type={"event_detail"}
              setDataEvent={setDataEvent}
            />

            {getEventTypeFromObj({event: dataEvent}) === eventType.smart_charity && (
              <CharityDonateForm
                isEdit={isEdit}
                dataEvent={dataEvent}
                setDataEvent={setDataEvent}
              />
            )}
          </div>
        </ItemFlex>
      </Content>
    </Section>
  );
}
