import styled from "styled-components";

export const Amount = styled.div`
  margin-top: 20px;
`;

export const ContainButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    font-size: 20px;
    font-weight: 700;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 15px;
  }
`;

export const ContainCard = styled.div`
  margin: 20px 0;
`;

export const ContainOutput = styled.div``;

export const SeletedCon = styled.div`
  div {
    color: ${(props) => props.theme.colors.black100};
  }
`;

export const Wrapper = styled.div`
  .middle {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .output {
    border: 1px solid gray;
    border-radius: 8px;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
`;
