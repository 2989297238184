import Countdown from "components/countdown";
import Modal from "components/modal";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import {
  CenterWrapper,
  CountdownWrapper,
  DiscoverWrapper,
  // DiscoverWrapper,
  Image,
  TextWrapper,
} from "./styles";

const Center = ({ data }) => {
  const [openModal, setModal] = useState(false);

  useEffect(() => {
    if (data) {
      setModal(true);
    }
  }, [data]);

  return (
    <Modal
      centered={true}
      isOpen={openModal}
      onCancel={() => setModal(false)}
      confirmLabel={
        <DiscoverWrapper>
          <a href={data.url} target={"_blank"} rel="noreferrer">
            {" "}
            DISCOVER NOW
          </a>
        </DiscoverWrapper>
      }
      onConfirm={() => {}}
    >
      <CenterWrapper>
        <a href={data.url} target={"_blank"} rel="noreferrer">
          <Container>
            <Row>
              {" "}
              <Image src={data.banner} alt="" />
            </Row>
            <Row>
              <Col xs={6}>
                <CountdownWrapper>
                  <Countdown date={new Date(data.end_date).getTime()} />
                </CountdownWrapper>
              </Col>
              <Col xs={6}>
                <TextWrapper>
                  <p>{data.name.toUpperCase()}</p>
                </TextWrapper>
              </Col>
            </Row>
          </Container>
        </a>
      </CenterWrapper>
    </Modal>
  );
};

export default Center;
