import { useState } from "react";
import { Container } from "./styles";
// import Button from "components/button";
import Products from "./components/products";
import History from "./components/history";
import Download from "./components/download";
import Tabs from "components/tabs";
import {
  BsListTask,
  BsClockHistory,
  BsCloudDownloadFill,
} from "react-icons/bs";
const View = () => {
  const [tab, setTab] = useState<string>("products");

  return (
    <Container>
      <Tabs
        active={tab}
        tabs={[
          {
            name: "products",
            render: (
              <>
                <BsListTask size={17} /> <span>Products</span>
              </>
            ),
          },
          {
            name: "history",
            render: (
              <>
                <BsClockHistory size={17} /> <span>History</span>
              </>
            ),
          },
          {
            name: "code",
            render: (
              <>
                <BsCloudDownloadFill size={17} /> <span>Code</span>
              </>
            ),
          },
        ]}
        handleTab={setTab}
      />

      {tab === "products" && <Products />}
      {tab === "history" && <History />}
      {tab === "code" && <Download />}
    </Container>
  );
};

export default View;
