import styled from "styled-components";

export const Container = styled.div`
  margin-top: 40px;
  background: ${(props) => props.theme.colors["black100"]};
  border-radius: 8px;
  width: 100%;
  padding: 30px 0;
  overflow: hidden;
  text-align: center;
  h4 {
    text-align: center;
    font-weight: 600;
    font-size: 25px;
  }
  img {
    max-width: 1200px;
    width: 100%;
  }
`;

export const RedirectWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 16px
`;
