import Button from "components/button";
import Input from "components/input";
import UploadFile from "components/uploadFile";
import { AddOrRemoveChil, Upload } from "pages/home/landing-page/styles";
import { useState } from "react";
import { AddOthersLinkIcon, IconLinkSurvey } from "../styles";

const View = (props) => {
  const { index, id, type, listLinkIcon, onBlurText, handleAddOrRemoveChil, setFile } = props;

  const [isShow, setIsShow] = useState<boolean>(false);

  return <>
    {
      type === "detail" && <div className="_show_hide">
        <Button onClick={() => setIsShow(!isShow)}>{isShow ? "Hide" : "Icons"}</Button>
      </div>
    }
    {
      (type === "content" || isShow) &&
			<div className="_add_icon">
			  <div>
			    <Input
			      transparent={true}
			      color={"orange"}
			      label="Link"
			      defaultValue={listLinkIcon?.link || ''}
			      onBlur={(e) =>
			        onBlurText(e, "list_survey", index, "link", id, type)
			      }
			      placeholder="Nhập link..."
			    />
			    <Input
			      key={index}
			      transparent={true}
			      color={"orange"}
			      label="Link Vdiarybook"
			      defaultValue={listLinkIcon?.link_vdiarybook || ''}
			      onBlur={(e) =>
			        onBlurText(e, "list_survey", index, "link_vdiarybook", id, type)
			      }
			      placeholder="Nhập link..."
			    />
			    <Input
			      key={index}
			      transparent={true}
			      color={"orange"}
			      label="Link Zalo"
			      defaultValue={listLinkIcon?.link_zalo || ''}
			      onBlur={(e) =>
			        onBlurText(e, "list_survey", index, "link_zalo", id, type)
			      }
			      placeholder="Nhập link..."
			    />
			    <Input
			      key={index}
			      transparent={true}
			      color={"orange"}
			      label="Link Facebook"
			      defaultValue={listLinkIcon?.link_facebook || ''}
			      onBlur={(e) =>
			        onBlurText(e, "list_survey", index, "link_facebook", id, type)
			      }
			      placeholder="Nhập link..."
			    />
			    <Input
			      key={index}
			      transparent={true}
			      color={"orange"}
			      label="Link document"
			      defaultValue={listLinkIcon?.link_document || ''}
			      onBlur={(e) =>
			        onBlurText(e, "list_survey", index, "link_document", id, type)
			      }
			      placeholder="Nhập link..."
			    />
			    <AddOthersLinkIcon>Others:
			      <AddOrRemoveChil mt="0px">
			        <div
			          onClick={() => handleAddOrRemoveChil("icon_survey_others", "add", index, type, id)}
			        >
									+
			        </div>
			        <div
			          onClick={() =>
			            handleAddOrRemoveChil("icon_survey_others", "remove", index, type, id)
			          }
			        >
									-
			        </div>
			      </AddOrRemoveChil>
			    </AddOthersLinkIcon>
			    {
			      listLinkIcon?.others?.length > 0 &&
						listLinkIcon?.others?.map(item => <>
						  <IconLinkSurvey>
						    <Upload>
						      <UploadFile
						        id={item.id}
						        idGlobal={id}
						        index={index}
						        ratio="50x50"
						        sectionName={type}
						        file={item.file}
						        src={item.src}
						        setFile={setFile}
						      />
						    </Upload>
						  </IconLinkSurvey>
						  <Input
						    key={index}
						    transparent={true}
						    color={"orange"}
						    label="Link"
						    defaultValue={item?.link || ''}
						    onBlur={(e) =>
						      onBlurText(e, "list_survey_others", index, type, item.id, id)
						    }
						    placeholder="Nhập link..."
						  />
						</>
						)
			    }
			  </div>
			</div>
    }
  </>
}

export default View;