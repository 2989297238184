import styled from "styled-components";

export const SettingColor = styled.div<{ isActive: boolean }>`
  position: fixed;
  top: calc(50% - 35vh);
  right: 0;
  width: ${(props) => (props.isActive ? "300px" : "0px")};
  height: 70vh;
  z-index: 9999999999;
  background-color: #ffffff;
  opacity: 0.9;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

  ._setting_color {
    border-radius: 10px;
    padding: 15px;
    background-color: white;
    width: fit-content;
    position: absolute;
    left: ${(props) => (props.isActive ? "-20px" : "-50px")};
    top: calc(50% - 30px);
    cursor: pointer;
  }
`;

export const Detail = styled.div`
  padding-left: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  overflow-y: auto;
`;

export const ItemColor = styled.div`
  display: flex;
  align-items: center;
`;

export const BoxColor = styled.div<{ color: string }>`
  position: relative;
  margin-right: 20px;
  width: auto;
  max-width: 70px;
  height: 20px;
  border-radius: 2px;
  background-color: ${(props) => props.color};
`;

export const OptionDelSection = styled.div``;
