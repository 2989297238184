import useQuery from "hooks/useQuery";
import AuthAPI from "api/auth";
import { ROUTES } from "config/routes";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useState } from "react";

const useVdiarybook = (props, setLoading, type = "login") => {
  const query = useQuery();
  const [isVdiaryBook, setIsVdiaryBook] = useState(false);
  const [providerToken, setProviderToken] = useState(false);
  const history = useHistory();

  const checkLogin = async () => {
    try {
      if (
        props.match.params["callback_type"] === "token-callback" &&
        query.token
      ) {
        setLoading(true);

        const showError = () => {
          toast.error(
            "Your account  have not completed KYC yet. Please complete your KYC to receive VDB!"
          );
        };

        if (type === "login") {
          const res: any = await AuthAPI.login(
            {},
            {
              provider: "vdiarybook",
              providerToken: query.token,
            }
          );
          if (!res.isKyc) showError();
          localStorage.setItem("auth", res.token);
          history.push(ROUTES.DASHBOARD);
        }

        if (type === "register") {
          // const res: any = await AuthAPI.register(
          //   {
          //     type: "vdiarybook",
          //   },
          //   {
          //     providerToken: query.token,
          //   }
          // );
          setProviderToken(query.token);
          setIsVdiaryBook(true);
          // if (!res.isKyc) showError();
          // localStorage.setItem("auth", res.token);
        }
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.errors || "Register failed!");
    }
  };

  return {
    checkLogin,
    isVdiaryBook,
    providerToken
  };
};

export default useVdiarybook;
