import { createSlice } from "@reduxjs/toolkit";
import { IWallet } from "models";
import {
  createWalletAsync,
  getWalletByOptionAsync,
  getWalletsAsync,
} from "./wallets.actions";

export interface IWalletState {
  wallets: IWallet[];
  walletSelected: IWallet;
}

const initialState: IWalletState = {
  wallets: [],
  walletSelected: null,
};

export const walletsSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setWallets: (state, action) => {
      state.wallets = action.payload;
    },
    setWalletSelected: (state, action) => {
      state.walletSelected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWalletsAsync.pending, (state) => {})
      .addCase(getWalletsAsync.fulfilled, (state, action) => {
        const { wallets, errors } = action.payload;
        if (errors) {
          //handle error
          return;
        }
        state.wallets = wallets;
      })
      .addCase(getWalletByOptionAsync.pending, (state) => {})
      .addCase(getWalletByOptionAsync.fulfilled, (state, action) => {
        const { wallet, errors } = action.payload;
        if (errors) {
          //handle error
          return;
        }
        state.walletSelected = wallet;
      })
      .addCase(createWalletAsync.pending, (state) => {})
      .addCase(createWalletAsync.fulfilled, (state, action) => {
        const { wallet, errors } = action.payload;
        if (errors) {
          //handle error
          return;
        }
        //show message
        state.wallets = [...state.wallets, wallet];
      });
  },
});

export const { setWallets, setWalletSelected } = walletsSlice.actions;

export default walletsSlice.reducer;
