/* eslint-disable react-hooks/exhaustive-deps */
import { Title, Project, ItemDoc, Document, ItemContentDoc } from "./styles";

import { Row, Col, Container } from "reactstrap";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { getDocuments } from "redux/reducers/settings/settings.selector";
import { useEffect } from "react";
import { getDocumentsAsync } from "redux/reducers/settings/settings.action";

const Documents = () => {
  const listDocument = useAppSelector(getDocuments);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getDocumentsAsync());
  }, []);
  return (
    <Container>
      <Title>
        <h3>DOCUMENTS</h3>
        <hr />
      </Title>
      <br />
      <Document>
        <Row>
          {listDocument.map((item) => (
            <Col md={4} key={item._id}>
              <ItemContentDoc href={item.url} target={"_blank"}>
                <Project data-aos="flip-left" data-aos-duration="800">
                  <ItemDoc>
                    <img
                      src={item.image || "/assets/images/icons/exchange.svg"}
                      alt=""
                    />
                    <h5>{item.name}</h5>
                    <p>{item.description}</p>
                  </ItemDoc>
                </Project>
              </ItemContentDoc>
            </Col>
          ))}
        </Row>
      </Document>
    </Container>
  );
};

export default Documents;
