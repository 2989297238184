/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable indent */
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { getSupportsAsync } from "redux/reducers/settings/settings.action";
import { getSupports } from "redux/reducers/settings/settings.selector";
import { getUserProfile } from "redux/reducers/users/users.selector";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Card, CardBody, UncontrolledCollapse } from "reactstrap";
import draftToHtml from "draftjs-to-html";
import { BiSupport, BiHistory } from "react-icons/bi";

import ContainerContent, {
  WrapperSupport,
  FileUpLoad,
  Submit,
  Attachments,
  AttachmentsFile,
  AddAttachment,
  RemoveChil,
} from "./styles";
import { Wrapper, Element, Contain, DreamFapArea } from "../q&a/styles";

import Input from "components/input";
import Dropdown from "components/dropdown";
import UploadFile from "components/uploadFile";
import { Upload } from "pages/home/landing-page/styles";
import Button from "components/button";
import Tabs from "components/tabs";
import History from "./history";

import APIs from "api";

const View = () => {
  const dispatch = useAppDispatch();
  const supports: any = useSelector(getSupports);

  const profile: any = useAppSelector(getUserProfile);
  const [loading, setLoading] = useState<boolean>(false);
  const [active, setActive] = useState<string>("supports");

  const [state, setState] = useState([]);
  const [dataQuestion, setDataQuestion] = useState([]);

  const handelTabs = (tab: string) => {
    setActive(tab);
  };

  const handleSubmitForm = async (id: string) => {
    try {
      setLoading(true);
      const send = state.find((st) => st.id === id);
      const data = dataQuestion.find((el) => el.id === id);

      if (data) {
        delete data.id;
      }

      const newData = send.listFiles.filter((list) => list.file);

      if (newData.length > 0) {
        const formData = new FormData();

        newData.map((stNew) => {
          formData.append("files", stNew.file);
          return stNew;
        });

        const res: any = await APIs.UPLOAD.uploadMultiFile({
          body: formData,
        });

        if (res) {
          const listAttachments = res.map((el) => el.filePath);
          await APIs.SUPPORT.sendSupport({
            title: send.title,
            value: { ...data, attachments: listAttachments },
          });
        }
      } else {
        await APIs.SUPPORT.sendSupport({
          title: send.title,
          value: data,
        });
      }
      toast.success(`Send support success!`);
      setLoading(false);
    } catch (error) {
      toast.error(`Error!`);
      setLoading(false);
    }
  };

  const handleAddAttachment = (id: string) => {
    setState((prev) =>
      prev.map((st) =>
        st.id === id
          ? {
              ...st,
              listFiles: [
                ...st.listFiles,
                {
                  file: null,
                },
              ],
            }
          : st
      )
    );
  };

  const removeChil = (id: string, index: number) => {
    setState((prev) =>
      prev.map((st) =>
        st.id === id
          ? {
              ...st,
              listFiles: st.listFiles.filter(
                (el, idx: number) => index !== idx
              ),
            }
          : st
      )
    );
  };

  const handleChangeFields = (
    e: any,
    id: string,
    title: string,
    key: string,
    typeFields: string
  ) => {
    const qs = dataQuestion.find((el) => el.id === id);
    if (qs) {
      setDataQuestion((prev) =>
        prev.map((el) =>
          el.id === id
            ? {
                ...el,
                [key]: typeFields === "input" ? e.target.value : e.value,
              }
            : el
        )
      );
    } else {
      setDataQuestion(
        dataQuestion.concat({
          id: id,
          [key]: typeFields === "input" ? e.target.value : e.value,
        })
      );
    }
  };

  const setFile = (f: any, id: string, key: string, index: number) => {
    setState((prev) =>
      prev.map((st) =>
        st.id === key
          ? {
              ...st,
              listFiles: st.listFiles.map((el, idx: number) =>
                index === idx
                  ? {
                      file: f,
                    }
                  : el
              ),
            }
          : st
      )
    );
  };

  useEffect(() => {
    state.map((item, index) => {
      const element = document.getElementById(`guild_${item.id}`);
      if (element) element.innerHTML = draftToHtml(item.attachments.guide);
    });
  }, [state, active]);

  useEffect(() => {
    dispatch(getSupportsAsync());
  }, []);

  useEffect(() => {
    if (supports)
      setState(
        (supports || []).map((q) => ({
          ...q,
          isOpen: false,
          listFiles: [
            {
              file: null,
            },
          ],
        }))
      );
  }, [supports]);

  return (
    <WrapperSupport>
      <Tabs
        active={active}
        tabs={[
          {
            name: "supports",
            render: (
              <>
                <BiSupport size={17} /> <span>Supports</span>
              </>
            ),
          },
          {
            name: "history",
            render: (
              <>
                <BiHistory size={17} /> <span>History</span>
              </>
            ),
          },
        ]}
        handleTab={handelTabs}
      />
      {active === "history" && <History />}
      {active === "supports" && (
        <Wrapper>
          <DreamFapArea>
            {state.map((item, index) => (
              <Element className={item.isOpen}>
                <Contain>
                  <button
                    id={`toggler_${item.id}`}
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    {item.title}
                  </button>
                  <UncontrolledCollapse toggler={`toggler_${item.id}`}>
                    <Card>
                      <CardBody>
                        <ContainerContent>
                          <Input
                            transparent={true}
                            label={"Name"}
                            disabled={true}
                            defaultValue={
                              profile.fullName || profile.userName || ""
                            }
                          />
                          <Input
                            transparent={true}
                            label={"Email"}
                            defaultValue={profile.email || ""}
                            disabled={true}
                          />
                          {item?.fields.length > 0 &&
                            item.fields.map((el: any) => (
                              <>
                                {el.typeField === "input" && (
                                  <Input
                                    transparent={true}
                                    placeholder={el.placeholder}
                                    label={el.label}
                                    onChange={(e) =>
                                      handleChangeFields(
                                        e,
                                        item.id,
                                        item.title,
                                        el.label,
                                        "input"
                                      )
                                    }
                                  />
                                )}
                                {el.typeField === "dropdown" && (
                                  <Dropdown
                                    transparent={true}
                                    // color={'white100'}
                                    width={"100%"}
                                    label={el.label}
                                    options={el?.options?.map((el: any) => {
                                      return {
                                        label: el?.name,
                                        value: el?.name,
                                      };
                                    })}
                                    defaultValue={el.placeholder}
                                    onChange={(e) =>
                                      handleChangeFields(
                                        e,
                                        item.id,
                                        item.title,
                                        el.label,
                                        "dropdown"
                                      )
                                    }
                                  />
                                )}
                                {el.typeField === "textarea" && (
                                  <Input
                                    transparent={true}
                                    height="100px"
                                    type="textarea"
                                    placeholder={el.placeholder}
                                    label={el.label}
                                    onChange={(e) =>
                                      handleChangeFields(
                                        e,
                                        item.id,
                                        item.title,
                                        el.label,
                                        "input"
                                      )
                                    }
                                  />
                                )}
                              </>
                            ))}
                          {item.attachments.enable && (
                            <Attachments>
                              <h4>Follow the requirements for screens:</h4>
                              <span id={`guild_${item.id}`}></span>
                              <br />
                              <AttachmentsFile>
                                {item?.listFiles?.length > 0 &&
                                  item?.listFiles?.map(
                                    (at: any, index: number) => (
                                      <FileUpLoad>
                                        <RemoveChil>
                                          <Button
                                            onClick={() =>
                                              removeChil(item.id, index)
                                            }
                                          >
                                            -
                                          </Button>
                                        </RemoveChil>
                                        <Upload>
                                          <UploadFile
                                            id={`${item.id}_${index}`}
                                            index={index}
                                            sectionName={item.id}
                                            ratio="340x340"
                                            file={at.file}
                                            setFile={setFile}
                                          />
                                        </Upload>
                                      </FileUpLoad>
                                    )
                                  )}
                                <AddAttachment
                                  onClick={() => handleAddAttachment(item.id)}
                                >
                                  +
                                </AddAttachment>
                              </AttachmentsFile>
                            </Attachments>
                          )}

                          <Submit>
                            <Button
                              loading={loading}
                              onClick={() => handleSubmitForm(item.id)}
                            >
                              Send
                            </Button>
                          </Submit>
                        </ContainerContent>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </Contain>
              </Element>
            ))}
          </DreamFapArea>
        </Wrapper>
      )}
    </WrapperSupport>
  );
};

export default View;
