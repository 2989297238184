import Button from "components/button";
import { MarginTop, NetWork } from "../../styles";
const View = ({ network, setNetwork }) => {
  return (
    <>
      <span>Network</span>
      <NetWork>
        <Button
          height="40px"
          className={network === "vzonex" && "active"}
          onClick={() => setNetwork("vzonex")}
        >
          VZONEX
        </Button>
        <Button
          height="40px"
          className={network === "bsc" && "active"}
          onClick={() => setNetwork("bsc")}
        >
          BSC
        </Button>
        <Button
          disabled={true}
          height="40px"
          className={network === "ethereum" && "active"}
          onClick={() => setNetwork("ethereum")}
        >
          ERC20
        </Button>
        <Button
          disabled={true}
          height="40px"
          className={network === "tron" && "active"}
          onClick={() => setNetwork("tron")}
        >
          TRC20
        </Button>
      </NetWork>
      <MarginTop mt="10px" />
    </>
  );
};

export default View;
