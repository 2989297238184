import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: #061a38;
  min-height: calc(calc(var(--vh, 1vh) * 100) - 108px);
  color: white !important;
  padding: 30px 0;
  p,
  h1,
  h2,
  h3,
  h4,
  span {
    color: white !important;
  }

  @media screen and (max-width: 1100px) {
    min-height: calc(calc(var(--vh, 1vh) * 100) - 86px);
  }
`;
