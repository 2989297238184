import React, { useState } from "react";
import Tabs from "components/tabs";
import Wrapper from "./styles";
import { FiPackage } from "react-icons/fi";
import { AiOutlineHistory } from "react-icons/ai";
import Packages from "./packages";
import History from "./history";
import ExpectValue from "./expect-value";
import { SiFuturelearn } from "react-icons/si";

interface IProps {
  [key: string]: any;
}

export default function View(props: IProps) {
  const [tab, setTab] = useState<string>("packages");

  return (
    <Wrapper>
      <Tabs
        active={tab}
        tabs={[
          {
            name: "packages",
            render: (
              <>
                <FiPackage size={17} /> <span>Packages</span>
              </>
            ),
          },
          {
            name: "history",
            render: (
              <>
                <AiOutlineHistory size={17} /> <span>Histories</span>
              </>
            ),
          },
          {
            name: "expect_value",
            render: (
              <>
                <SiFuturelearn size={15} /> <span>Expect Value</span>
              </>
            ),
          },
        ]}
        handleTab={setTab}
      />
      {tab === "packages" && <Packages />}
      {tab === "history" && <History />}
      {tab === "expect_value" && <ExpectValue />}
    </Wrapper>
  );
}
