/* eslint-disable import/no-anonymous-default-export */
import { request, parseErrorResponse } from "./request";

export const getProjects = () =>
  new Promise((resolve, reject) => {
    request()
      .get("/projects")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const getProjectsOwner = () =>
  new Promise((resolve, reject) => {
    request()
      .get("/projects/owner")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const getProjectById = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get("/projects/" + req.params.id)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const buyICO = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("/ico/buy", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const getHistoryBuyICO = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get("/ico/user/")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const createProject = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("/projects/", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const getParticipantById = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get("/ico/participants/" + req.params.id)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      });
  });

export const editProject = (req) =>
  new Promise((resolve, reject) => {
    request()
      .put(`/projects/${req.params.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const editProjectFBPageID = (req) =>
  new Promise((resolve, reject) => {
    request()
      .put(`/projects/${req.params.id}/fb-page-id`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const getCertificateByUser = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get("/projects/certificate/" + req.params.id)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const updateShareProfit = (req) =>
  new Promise((resolve, reject) => {
    request()
      .put(`/projects/${req.params.id}/share-profit`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const checkShareProfitByType = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/projects/${req.params.id}/${req.params.type}/check-share-profit`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const getProjectOwnerFriends = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/projects/${req.params.id}/list-friends`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const transferShareProfit = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/projects/${req.params.id}/process-transactions`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const requestMainet = (req) =>
  new Promise((resolve, reject) => {
    request()
      .put(`/projects/${req.params.id}/request-mainet`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const getPixelsByProjectId = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/projects/pixels-by-project/${req.params.projectId}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const getPixelById = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/projects/pixels/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const createPixels = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("/projects/pixels", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const updatePixel = (req) =>
  new Promise((resolve, reject) => {
    request()
      .put(`/projects/pixels/${req.params.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const deletePixel = (req) =>
  new Promise((resolve, reject) => {
    request()
      .delete(`/projects/pixels/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const changeStatusProject = (req) =>
  new Promise((resolve, reject) => {
    request()
      .put(`/projects/change-status/${req.params.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export default {
  deletePixel,
  getProjects,
  getProjectById,
  buyICO,
  getHistoryBuyICO,
  createProject,
  editProject,
  getCertificateByUser,
  updateShareProfit,
  checkShareProfitByType,
  transferShareProfit,
  getProjectOwnerFriends,
  requestMainet,
  getProjectsOwner,
  editProjectFBPageID,
  updatePixel,
  createPixels,
  getPixelById,
  getPixelsByProjectId,
  changeStatusProject
};
