import Button from "./styles";
import { Spinner } from "reactstrap";

type IProps = {
  children?: any;
  transparent?: any;
  color?: string;
  loading?: boolean;
  disabled?: boolean;
  type?: string;
  width?: any;
  height?: any;
  onClick?: (e?: any) => any;
  className?: any;
  isPadding?: any;
};

const View = ({
  children,
  transparent = false,
  color = "orange100",
  loading = false,
  ...props
}: IProps) => {
  return (
    <Button
      transparent={transparent}
      color={color}
      disabled={loading}
      {...props}
    >
      {loading && <Spinner size="sm">...</Spinner>}
      {children}
    </Button>
  );
};

export default View;
