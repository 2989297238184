import { ContainIcon } from "./styles";
import Drawer from "react-modern-drawer";
import React, { useEffect, useState } from "react";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import { isMobile } from "react-device-detect";

const Social = ({ openMenu }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  const [clientHeight, setClientHeight] = useState(500);
  useEffect(() => {
    const doc = document.getElementById("root");
    setClientHeight(doc.clientHeight);
  }, []);

  return (
    <>
      <ContainIcon isOpen={openMenu}>
        <img src="/assets/images/icons/facebook.svg" alt="" />
        <img src="/assets/images/icons/youtube.svg" alt="" />
        <img
          src="/assets/images/icons/twitter.svg"
          alt=""
          onClick={toggleDrawer}
        />
        <img src="/assets/images/icons/telegram.svg" alt="" />
      </ContainIcon>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        className=""
        size={isMobile ? 250 : 300}
      >
        {isOpen && (
          <TwitterTimelineEmbed
            sourceType="profile"
            screenName="vzonex"
            theme="dark"
            options={{ height: clientHeight }}
          />
        )}
      </Drawer>
    </>
  );
};

export default Social;
