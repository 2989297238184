import { TbWorld } from "react-icons/tb";
import { IoMdDocument } from "react-icons/io";

const View = ({item}: any) => {
  const openTagWindow = (url: string) => {
    window.open(url)
  }

  return <>
		 <div className="_list_icon">
      {item?.link && <div className="_icon" onClick={() => openTagWindow(item?.link)}><TbWorld size={20} /></div>}
      {item?.link_vdiarybook &&
						<div className="_icon" onClick={() => openTagWindow(item?.link_vdiarybook)}>
						  <img src="/assets/images/VDB-64.png" alt="" />
						</div>}
      {item?.link_zalo && <div className="_icon" onClick={() => openTagWindow(item?.link_zalo)}>
        <img src="/assets/images/icons/zalo.svg" alt="" />
      </div>}
      {item?.link_facebook && <div className="_icon" onClick={() => openTagWindow(item?.link_facebook)}>
        <img src="/assets/images/facebook.png" alt="" />
      </div>}
      {item?.link_document && <div className="_icon" onClick={() => openTagWindow(item?.link_document)}>
        <IoMdDocument size={20} />
      </div>}
      {
        item?.others?.length > 0 &&
        item?.others?.map(el => 
          <div key={el.id} className="_icon" onClick={() => openTagWindow(el?.link)}>
            <img src={el.src} alt="" />
          </div>
        )
      }
    </div>
  </>
}

export default View;