/* eslint-disable react-hooks/exhaustive-deps */
import APIs from "api";
import Button from "components/button";
import Input from "components/input";
import Modal from "components/modal";
import Reward from "components/v-reward";
import ToolTip from "components/tooltip/View";
import { useEffect, useState } from "react";
import { BsFillGiftFill } from "react-icons/bs";
import Select from "react-select";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import { getCryptosAsync } from "redux/reducers/cryptos/cryptos.actions";
import { getCryptos } from "redux/reducers/cryptos/cryptos.selector";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { getProductsAsync } from "redux/reducers/products/products.actions";
import { getProducts } from "redux/reducers/products/products.selector";
import {
  Action,
  Amount,
  CodeWrapper,
  ContainImage,
  ContainModal,
  Cost,
  Detail,
  Element,
  Header,
  ImageList,
  ImageListItem,
  Item,
  ItemWrapper,
  LabelAmount,
  Left,
  Price,
  PriceCart,
  ProductWrapper,
  RewardWrapper,
  Right,
  SeletedCon,
  SubTitle,
  TitleLabel,
  TotalArea,
  WraperImg,
  WrapperProduct,
} from "../../styles";
import VAffilate from "components/v-affiliate";

const View = () => {
  const listProduct = useAppSelector(getProducts);
  const cryptos = useAppSelector(getCryptos);
  const dispatch = useAppDispatch();

  const options = [
    { value: "sticker", label: "Sticker" },
    { value: "voucher", label: "Voucher" },
    { value: "nft", label: "NFT" },
    { value: "discount", label: "Discount" },
    { value: "code", label: "Code" },
  ];

  const [code, setCode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [productSelected, setProductSelected] = useState(null);
  const [state, setState] = useState({
    isOpen: false,
    isOpenImages: false,
  });
  const [dataBuy, setDataBuy] = useState({
    amount: 0,
    total: 0,
  });
  const defaultFilter = {
    token: cryptos.map((a: any) => a.symbol),
    type: options.map((a) => a.value),
  };
  const [filter, setFilter] = useState(defaultFilter);

  useEffect(() => {
    dispatch(getCryptosAsync());
  }, []);

  useEffect(() => {
    if (filter) {
      const token =
        filter.token.length < 1 ? defaultFilter.token : filter.token;
      const type = filter.type.length < 1 ? defaultFilter.type : filter.type;
      dispatch(
        getProductsAsync({
          query: {
            token,
            type,
            search: "",
            sortField: "createdAt",
            sortOrder: -1,
          },
        })
      );
    }
  }, [filter]);

  const onChangeFilter = (e, name) => {
    setFilter((prev) => ({
      ...prev,
      [name]: e.map((a) => a.value),
    }));
  };

  const handleOpen = (type, product) => {
    setProductSelected(product);
    if (type === "buy") {
      setState({
        ...state,
        isOpen: true,
      });
    } else
      setState({
        ...state,
        isOpenImages: true,
      });
  };

  const handleClose = (type) => {
    if (type === "buy")
      setState({
        ...state,
        isOpen: !state.isOpen,
      });
    else
      setState({
        ...state,
        isOpenImages: false,
      });
    setDataBuy({
      amount: 0,
      total: 0,
    });
    setCode(null);
  };

  const handleSub = () => {
    if (dataBuy.amount > 0)
      setDataBuy({
        ...dataBuy,
        amount: Number(dataBuy.amount) - 1,
        total: dataBuy.total - productSelected.price_token,
      });
  };

  const handleAdd = () => {
    setDataBuy({
      ...dataBuy,
      amount: Number(dataBuy.amount) + 1,
      total: +dataBuy.total + +productSelected.price_token,
    });
  };

  const handleChange = (event) => {
    if (event.target.value >= 0)
      setDataBuy({
        ...dataBuy,
        [event.target.name]: event.target.value,
        total: +productSelected.price_token * +event.target.value,
      });
  };

  const handleBuyProduct = async () => {
    try {
      if (+dataBuy.amount <= 0) {
        return toast.error("Please input amount!");
      }
      setIsLoading(true);
      const res: any = await APIs.PURCHASE.buyProduct({
        body: {
          quantity: dataBuy.amount,
          productId: productSelected._id,
        },
      });
      setCode(res.code);
      setIsLoading(false);
    } catch (error) {
      toast.error(error.errors || "Something went error!");
      setIsLoading(false);
    }
  };

  const renderModal = (productSelected, code) => {
    return code ? (
      <ContainModal>
        <CodeWrapper>
          <h3 className="sell-success">Congratulations successful purchase</h3>
          <h3>Your code:</h3>
          <div className="code">
            {code.map((c, key) => (
              <p key={key}>{c}</p>
            ))}
          </div>
        </CodeWrapper>
      </ContainModal>
    ) : (
      productSelected && (
        <ContainModal>
          <Left>
            <ContainImage>
              <img src={productSelected.thumbnail} alt="" />
            </ContainImage>
            <TitleLabel>
              <h4>{productSelected.name}</h4>
            </TitleLabel>
          </Left>
          <Right>
            <PriceCart>
              <h4>Unit price: </h4>
              <span>
                {" "}
                {productSelected.price_token}{" "}
                {String(productSelected.token).toUpperCase()}
              </span>
            </PriceCart>
            <Amount>
              <LabelAmount>
                <h4>Amount</h4>
              </LabelAmount>
              <Action>
                <Button onClick={handleSub}>-</Button>
                <Input
                  name="amount"
                  type="number"
                  variant="outlined"
                  value={dataBuy.amount}
                  onChange={handleChange}
                />
                <Button onClick={handleAdd}>+</Button>
              </Action>
            </Amount>
            <TotalArea>
              <h4>Total: </h4>
              <span>
                {dataBuy.total.toFixed(4)}{" "}
                {String(productSelected.token).toUpperCase()}
              </span>
            </TotalArea>
            {/* <Buy>
              <Button onClick={handleBuyProduct} loading={isLoading}>
                Buy
              </Button>
            </Buy> */}
          </Right>
        </ContainModal>
      )
    );
  };

  const renderModalImages = (productSelected) => {
    return (
      productSelected &&
      productSelected.images && (
        <ImageList>
          <Row>
            {productSelected.images.map((item) => (
              <Col>
                <ImageListItem key={item}>
                  <img src={item} alt={"vzonex"} />
                </ImageListItem>
              </Col>
            ))}
          </Row>
        </ImageList>
      )
    );
  };

  return (
    <WrapperProduct>
      <Header>
        <Row>
          <Col sm={4} xs={12}>
            <SeletedCon>
              <Select
                options={cryptos.map((c) => ({
                  label: `${c.name} (${String(c.symbol).toUpperCase()})`,
                  value: c.symbol,
                }))}
                defaultValue={[]}
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder={"e.NFT Stocks"}
                onChange={(e) => onChangeFilter(e, "token")}
              />
            </SeletedCon>
          </Col>
          <Col sm={4} xs={12}>
            <SeletedCon>
              <Select
                options={options}
                placeholder={"Type"}
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(e) => onChangeFilter(e, "type")}
              />
            </SeletedCon>
          </Col>
        </Row>
      </Header>
      <ProductWrapper>
        {listProduct?.length > 0 &&
          listProduct?.map((item, key) => (
            <ItemWrapper key={key}>
              <RewardWrapper>
                <ToolTip
                  id={item._id}
                  content={<Reward reward={item.reward} />}
                >
                  <span id={`tooltip-` + item._id} className="gift">
                    <BsFillGiftFill size={22} />
                  </span>
                </ToolTip>
              </RewardWrapper>

              <Item>
                <WraperImg onClick={() => handleOpen("images", item)}>
                  <img src={item.thumbnail} alt="" />
                </WraperImg>
                <Detail>
                  <Element>
                    <ToolTip
                      id={item._id}
                      content={<VAffilate affiliates={item.affiliates} />}
                    >
                      <p id={`tooltip-` + item._id}>{item.name}</p>
                    </ToolTip>
                    <div>
                      <img
                        src="/assets/images/icons/cart.svg"
                        alt=""
                        onClick={() => handleOpen("buy", item)}
                      />
                    </div>
                  </Element>
                  <SubTitle>{item.description}</SubTitle>
                  <Cost>
                    <Price>
                      {item.price_token}{" "}
                      <span>{String(item.token).toUpperCase()}</span>
                    </Price>
                    <p>
                      Sold: <span>{item.amount_sold || 0}</span>
                    </p>
                  </Cost>
                </Detail>
              </Item>
            </ItemWrapper>
          ))}
      </ProductWrapper>

      <Modal
        isOpen={state.isOpen}
        children={renderModal(productSelected, code)}
        onCancel={() => handleClose("buy")}
        centered={true}
        confirmLabel={"Buy"}
        onConfirm={handleBuyProduct}
        loading={isLoading}
        disableConfirm={code}
      />
      <Modal
        isOpen={state.isOpenImages}
        children={renderModalImages(productSelected)}
        onCancel={() => handleClose("images")}
        centered={true}
      />
    </WrapperProduct>
  );
};

export default View;
