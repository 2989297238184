import React from "react";

import Wrapper, { Tab, Tabs } from "./styles";

interface IProps {
  [key: string]: any;
}

export default function View(props: IProps) {
  const { handleTab, tabs, active } = props;

  return (
    <Wrapper>
      <Tabs>
        {tabs.map((tab) => (
          <Tab active={active === tab.name} onClick={() => handleTab(tab.name)}>
            {tab.render}
          </Tab>
        ))}
      </Tabs>
    </Wrapper>
  );
}
