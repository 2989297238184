/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import {
  UploadKYC,
  ContainerVisit,
  SubmitKYC,
  NoteUploadInvoice,
  ContaineIdKYC,
} from "../styles";
import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { toast } from "react-toastify";
import { getKycAsync } from "redux/reducers/users/users.actions";
import APIs from "api";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import Dropdown from "components/dropdown";
import UploadFile from "components/uploadFile";
import { Upload } from "pages/home/landing-page/styles";
import Button from "components/button";
import { getVerify } from "redux/reducers/users/users.selector";
import Input from "components/input";
import { getObjects } from "redux/reducers/settings/settings.selector";
import { getObjectsAsync } from "redux/reducers/settings/settings.action";

const kycType = [
  { label: "ID card", value: "id_card" },
  { label: "Passport", value: "passport" },
  { label: "Driving license", value: "driving_license" },
];

const KYC = ({ setStepCurrent }) => {
  const dispatch = useAppDispatch();

  const objects = useAppSelector(getObjects);
  const kycData = useAppSelector(getVerify);
  const [KYC, setKYC] = useState<any>({
    type: "id_card",
    object: null,
  });

  const [isSavingKYC, setIsSavingKYC] = useState(false);
  const [listFiles, setListFiles] = useState<any>({});
  const [idKYC, setIdKYC] = useState();
  const [labelId, setLabelId] = useState(null);

  useEffect(() => {
    dispatch(getObjectsAsync());
  }, []);

  useEffect(() => {
    if (kycData) setKYC(kycData);
  }, [kycData]);

  const setFile = (f: any, id: string) => {
    setListFiles((prev) => ({
      ...prev,
      [id]: f,
    }));
  };

  const onChangeTypeKYC = (type) => {
    setKYC((prev) => ({
      ...prev,
      type: type.value,
    }));
    setLabelId(kycType.find((kyc) => kyc.value === type.value).label);
  };

  const onChangeObject = (object) => {
    setKYC((prev) => ({
      ...prev,
      object: object.value,
    }));
  };

  const ChangeHandle = (event) => {
    setIdKYC(event.target.value);
  };

  const handleSaveKYC = async () => {
    setIsSavingKYC(true);

    try {
      if (!KYC.object) {
        toast.error("Please choose object!");
        setIsSavingKYC(false);
        return;
      }
      const formData = new FormData();
      Object.keys(listFiles).map((el: any) => {
        formData.append("files", listFiles[el]);
      });
      const listImg: any = await APIs.UPLOAD.uploadMultiFile({
        body: formData,
      });
      if (listImg && listImg?.length > 0) {
        let data = {
          type: KYC?.type || "id_card",
          object: KYC?.object,
        };
        listImg?.map((el: any) => {
          Object.keys(listFiles).map((item: any, indexKey: number) => {
            if (el.id === indexKey) {
              data = {
                ...data,
                [item]: el?.filePath,
              };
            }
          });
        });
        try {
          const kyc: any = await APIs.KYC.createKyc({
            body: {
              ...data,
              method: "kyc",
              idKyc: idKYC,
            },
          });
          if (kyc) {
            toast.success("successfull!");
            setListFiles({});
            dispatch(getKycAsync());
            setStepCurrent(null);
          }
        } catch (err) {
          toast.error("ID KYC Is Exists");
        }
      }
    } catch (err) {
      toast.error("Something went wrong!");
    }
    setIsSavingKYC(false);
  };

  return (
    <UploadKYC>
      {KYC?.status === "pending" ? (
        <h5 style={{ color: "orange", textAlign: "center" }}>
          We are verifying your KYC submition. Please stay tuned!
        </h5>
      ) : KYC?.status === "approved" ? (
        <h5 style={{ color: "#00ad00", textAlign: "center" }}>
          Your account was verified!
        </h5>
      ) : (
        <>
          <div className="type-object">
            <div className="type">
              <Dropdown
                width={"100%"}
                transparent={true}
                label="Type"
                options={kycType?.map((el: any) => {
                  return {
                    label: el?.label,
                    value: el?.value,
                  };
                })}
                defaultValue={"ID card"}
                onChange={onChangeTypeKYC}
              />
            </div>

            <div className="object">
              <Dropdown
                width={"100%"}
                transparent={true}
                label="Object"
                options={(objects || []).map((el: any) => {
                  return {
                    label: el?.name,
                    value: el?.id,
                  };
                })}
                onChange={onChangeObject}
              />
            </div>
          </div>
          <Row>
            <Col md={4}></Col>
            <Col md={4}>
              <ContaineIdKYC>
                <span>{labelId || kycType[0].label}</span>
                <Input
                  name="haha"
                  value={idKYC}
                  onChange={(e) => ChangeHandle(e)}
                />
              </ContaineIdKYC>
            </Col>
            <Col md={4}></Col>
            <Col md={2}></Col>
            <Col md={4}>
              <span>Front</span>
              <ContainerVisit>
                <Upload>
                  <UploadFile
                    id="front"
                    ratio="220x350"
                    typeUpload="image"
                    file={listFiles?.["front"]}
                    setFile={setFile}
                  />
                </Upload>
              </ContainerVisit>
            </Col>
            <Col md={4}>
              <span>Back</span>
              <ContainerVisit>
                <Upload>
                  <UploadFile
                    id="back"
                    ratio="220x350"
                    typeUpload="image"
                    file={listFiles?.["back"]}
                    setFile={setFile}
                  />
                </Upload>
              </ContainerVisit>
            </Col>
            <Col md={2}></Col>
          </Row>
          <br />
          {/* <Row>
            <Col md={6}>
              <span>Vdiarybook profile</span>
              <ContainerVisit>
                <Upload>
                  <UploadFile
                    id="profile_VDB"
                    ratio="220x350"
                    typeUpload="image"
                    file={listFiles?.["profile_VDB"]}
                    setFile={setFile}
                  />
                </Upload>
              </ContainerVisit>
            </Col>
            <Col md={6}>
              <span>Review Vdiarybook on CH-PLAY/APPSTORE</span>
              <ContainerVisit>
                <Upload>
                  <UploadFile
                    id="appStore_CHPlay"
                    ratio="220x350"
                    typeUpload="image"
                    file={listFiles?.["appStore_CHPlay"]}
                    setFile={setFile}
                  />
                </Upload>
              </ContainerVisit>
            </Col>
          </Row> */}
          <SubmitKYC>
            <Button onClick={handleSaveKYC} loading={isSavingKYC}>
              Save
            </Button>
          </SubmitKYC>
          <NoteUploadInvoice>
            <h6>Note : </h6>
            <p>
              {" "}
              - FRONT : Please upload the front of your ID card or driver's
              license or passport or student card
            </p>
            <p>
              {" "}
              - BACK : Please upload the back of your ID card or driver's
              license or passport or student card
            </p>
            <p>
              {" "}
              - VDIARYBOOK PROFILE : Please upload your account screen of social
              network Vdiarybook
            </p>
            <p>
              {" "}
              - REVIEWS ON THE APP : Access the appstore Apple Store, CH Play
              evaluate and feel about the social network vdiarybook. Upload
              review screen
            </p>
          </NoteUploadInvoice>
        </>
      )}
    </UploadKYC>
  );
};

export default KYC;
