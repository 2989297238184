/* eslint-disable jsx-a11y/alt-text */
import Button from "components/button";
import { Continue } from "../../styles";
import { Step0Wrapper } from "./styles";

const Step0 = ({ dataPayment, nextStep, setDataByKey, setDataPayment }) => {
  const currencies = [
    {
      name: "vnd",
      icon: "/assets/images/coins/vnd.png",
    },
    // {
    //   name: "usdt",
    //   icon: "/assets/images/coins/usdt.png",
    // },
  ];

  return (
    <Step0Wrapper>
      {currencies.map((c) => (
        <Button
          className={`btn-currency ${
            dataPayment.currency === c.name ? "active" : ""
          }`}
          onClick={() => {
            setDataPayment((dataPayment) => {
              return {
                ...dataPayment,
                network: c.name === "vnd" ? "bank" : "stock",
                currency: c.name,
              };
            });
          }}
        >
          <img src={c.icon} />
          {c.name.toUpperCase()}
        </Button>
      ))}
      <Continue>
        <Button onClick={() => nextStep()} disabled={!dataPayment.currency}>
          Continue
        </Button>
      </Continue>
    </Step0Wrapper>
  );
};

export default Step0;
