import Button from "components/button";
import Table from "components/table";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { getPurchasesAsync } from "redux/reducers/purchases/purchases.actions";
import { getPurchaseProjects } from "redux/reducers/purchases/purchases.selector";
import { formatNumber } from "utils/common";
import { HistoriesWrapper } from "./styles";


const Histories = (props) => {
  const dispatch = useAppDispatch();
  const histories = useAppSelector(getPurchaseProjects);

  const { setCongratulationInverst, setOpenModalCongratulation } = props

  const columns = [
    {
      key: "Period",
      title: "Period",
      dataIndex: "_id",
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
    },
    {
      key: "invoice",
      title: "Invoice",
      render: (item: any) => (
        <a
          href={`/invoice/ico/${item.project_id["symbol"]}/${item._id}`}
          target={"_blank"}
          rel="noreferrer"
        >
          <Button>View</Button>
        </a>
      ),
    },
    {
      key: "congratulation",
      title: "Congratulation",
      render: (item: any) => (
        <Button onClick={() => handleViewCongratulation(item)}>View</Button>
      ),
    },
    {
      key: "Project",
      title: "Project",
      render: (item: any) =>
        `${item.project_id ? item.project_id["name"] : "N/A"}`,
    },
  
    {
      key: "Amount Sent",
      title: "Amount Sent",
      render: (item: any) =>
        `${formatNumber(item.project.amount)} (${
          item.project_id ? item.project_id["stock_to_buy"] : "N/A"
        })`,
    },
    {
      key: "amountReceived",
      title: "AmountReceived",
      render: (item: any) =>
        `${formatNumber(item.project.amountReceived)} (${
          item.project_id ? item.project_id["symbol"] : "N/A"
        })`,
    },
    {
      key: "Hash",
      title: "Hash",
      render: (item: any) => (
        <a
          href={`${
            process.env.REACT_APP_VZX_SCAN || "https://block.vzonex.com"
          }/tx/${item.project.hash}`}
          target={"_blank"}
          rel="noreferrer"
        >
          {item.project.hash?.slice(0, 20)}...
        </a>
      ),
    },
  ];
  
  const handleViewCongratulation = (data: any) => {
    setCongratulationInverst(data)
    setOpenModalCongratulation(true)
  }

  useEffect(() => {
    dispatch(
      getPurchasesAsync({
        params: {
          type: "project",
        },
      })
    );
  }, [dispatch]);

  return (
    <HistoriesWrapper>
      <Table columns={columns} dataSource={histories}></Table>
    </HistoriesWrapper>
  );
};

export default Histories;
