import React, { useEffect } from "react";

import { useAppDispatch } from "redux/reducers/hook";
import { setConnectSocket } from "redux/reducers/users/users.reducer";
import { Route, Switch } from "react-router-dom";
import Profile from "./profile";
import BuyAndSell from "./buy-and-sell";
import Swap from "./swap";
import Wallets from "./wallets";
// import Referral from "./profile/referral";
import Shop from "./shop";
import IDO from "./ibo-project";
import IBO from "./my-project";
import TASK from "./task";
import TASK_DETAIL from "./task/detail";
import withAuth from "HOCs/withAuth";
import UserLayout from "../../layouts/UserLayout";
import { ROUTES } from "config/routes";
import VzoneBank from "./vzone-bank/";
import PetaVerse from "./petaverse";
import { getBalancesAsync, getKycAsync } from "redux/reducers/users/users.actions";
import Invests from "./invests";
import Reward from "./rewards";
import Channel from "./channel";
import Statis from "./statis";
import QA from "./q&a";
import Campaign from "./campaign";
import CampaignDetail from "./campaign/Details";
import Supports from "./supports";
import MyEvent from "../events/my_events";
import Dashboard from "./dashboard";
import Rounds from "./ibo-project/Rounds";
import PreviewChannel from "./profile/channel/preview-channel";

function MyRoute() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const auth = localStorage.getItem("auth");
    if (auth) {
      dispatch(setConnectSocket(true));
      dispatch(getKycAsync());
      dispatch(getBalancesAsync());
    }
  }, [dispatch]);

  return (
    <UserLayout>
      <Switch>
        <Route path={ROUTES.MY_PROFILE} component={Profile} />
        <Route path={ROUTES.MY_BY_SELL} component={BuyAndSell} />
        <Route path={ROUTES.MY_SWAP} component={Swap} />
        <Route path={ROUTES.MY_WALLETS} component={Wallets} />
        {/* <Route path={ROUTES.MY_REFERRALS} component={Referral} /> */}
        <Route path={ROUTES.SALESTATISTICS} component={Statis} />
        <Route path={ROUTES.MY_SHOP} component={Shop} />
        <Route path={ROUTES.MY_IBO_ROUND} component={Rounds} />
        <Route path={ROUTES.MY_IDO} component={IDO} />
        <Route path={ROUTES.MY_IBO} component={IBO} />
        <Route path={ROUTES.MY_TASK_DETAIL} component={TASK_DETAIL} />
        <Route path={ROUTES.MY_TASK} component={TASK} />
        <Route path={ROUTES.VZONE_BANK} component={VzoneBank} />
        <Route path={ROUTES.BORROW_LEND} component={VzoneBank} />
        <Route path={ROUTES.BLOCKCHAIN} component={VzoneBank} />
        <Route path={ROUTES.PETAVERSE} component={PetaVerse} />
        <Route path={ROUTES.INVEST} component={Invests} />
        <Route path={ROUTES.REWARD} component={Reward} />
        <Route path={ROUTES.CHANNEL} component={Channel} />
        <Route path={ROUTES.QA} component={QA} />
        <Route path={ROUTES.CAMPAIGNDETAIL} component={CampaignDetail} />
        <Route path={ROUTES.CAMPAIGN} component={Campaign} />
        <Route path={ROUTES.SUPPORTS} component={Supports} />
        <Route
          path={[
            ROUTES.SMART_EVENTS_MY_LIST,
            ROUTES.SMART_SCHOOLS_MY_LIST,
            ROUTES.SMART_HOSPITALS_MY_LIST,
            ROUTES.SMART_POOLS_MY_LIST,
            ROUTES.SMART_CHARITY_MY_LIST,
          ]}
          component={MyEvent}
        />
        <Route path={ROUTES.DASHBOARD} component={Dashboard} />
        <Route path={ROUTES.PREVIEW_CHANNEL} component={PreviewChannel} />
      </Switch>
    </UserLayout>
  );
}

export default withAuth(MyRoute);
