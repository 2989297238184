import React from "react";

import { HeaderRunIcon } from "pages/events/styles";
import Marquee from "components/marquee";
import { isMobile } from "react-device-detect";

export default function View(props: any) {
  const { logosAds, backgroundLogos } = props;

  return (
    <HeaderRunIcon bgColor={backgroundLogos}>
      <div
        style={{
          width: logosAds.length * 100 + "px",
          minWidth: "100vw",
        }}
      >
        <Marquee size={6}>
          <div className="_listIcon">
            {logosAds?.map((el, index) => (
              <a
                key={index}
                href={el.link || "#"}
                target={"_blank"}
                rel="noreferrer"
                className="logo-banner-ads-link"
                style={{
                  width: isMobile ? "100px" : "197px",
                  textAlign: "center",
                }}
              >
                <img src={el.src} alt="" />
              </a>
            ))}
          </div>
        </Marquee>
      </div>
    </HeaderRunIcon>
  );
}
