import styled from "styled-components";

export const Wrapper = styled.div`
    padding: 3px;
    margin-top: 15px;

    .form-group {
        display: flex;
        justify-content: space-between;
        align-items: center;

        label {
            display: block !important;
        }

        input {
            display: block;
            width: 100%;
        }

        .input-amount {
            width:70%;
            margin-right: 5px;
        }

        .input-currency {
            width: 30%;
        }

        .btn-donate {
            padding-top: 13px;
            button {
                padding: 8px;
                margin-left: 4px;
            }
        }
    }
`


export const WrapperModalDonate = styled.div`
  padding: 20px;
  h3 {
    text-align: center;
  }
  input {
    width: 100%;
    border: 1px solid white;
  }
`;


export const Thanks = styled.div`
  img {
    width: 100%;
  }
`;

export const Histories = styled.div`
  max-height: 250px;
  overflow: auto;
`;
