import { Container, Fly, Transform, Congratulations } from "./styles";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setReward } from "redux/reducers/settings/settings.reducer";
import { useAppSelector } from "redux/reducers/hook";
import { getReward } from "redux/reducers/settings/settings.selector";
const View = () => {
  const reward = useAppSelector(getReward);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(setReward(null));
    }, 5000);
  }, [dispatch]);

  return (
    <Container>
      <Transform>
        <Fly>
          <img src="/assets/images/fly.png" alt="" />
        </Fly>
        <Congratulations>
          <h3>Congratulations!</h3>
          <h3>You received</h3>
          {reward["stocks"].length > 0 && (
            <>
              <label>Stocks :</label>
              {reward["stocks"].map((s) => (
                <div>
                  <span>
                    +{s.amount} ({s.symbol})
                  </span>
                </div>
              ))}
              <hr />
            </>
          )}

          {reward["vouchers"].length > 0 && (
            <>
              {" "}
              <label>Vouchers :</label>
              {reward["vouchers"].map((s) => (
                <div>
                  <span>
                    +{s.amount} ({s.id})
                  </span>
                </div>
              ))}
              <hr />
            </>
          )}

          {reward["tickets"].length > 0 && (
            <>
              <label>Tickets :</label>
              {reward["tickets"].map((s) => (
                <div>
                  <span>
                    +{s.amount} ({s.id})
                  </span>
                </div>
              ))}
              <hr />
            </>
          )}

          {reward["codes"].length > 0 && (
            <>
              <label>Codes :</label>
              {reward["codes"].map((s) => (
                <div>
                  <span>
                    +{s.codes} ({s.symbol})
                  </span>
                </div>
              ))}
            </>
          )}
        </Congratulations>
      </Transform>
    </Container>
  );
};

export default View;
