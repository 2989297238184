import ToolTip from "components/tooltip/View";
import { Progress } from "reactstrap";
import { formatNumber } from "utils/common";
import { ContentStage, ContentToolTip } from "./styles";

const colors = ["primary", "success", "warning", "danger", "info"];

const Stage = ({ stages, name, stock, type }) => {
  const content = (stage) => {
    return (
      <ContentStage>
        <span>
          Price : 1 {String(name).toUpperCase()} = {stage.price}{" "}
          {String(stock).toUpperCase()}
        </span>
        <hr />
        <span>
          Amount To Sale :{" "}
          {type === "pixel"
            ? stage.pixels.length
            : formatNumber(stage.amountToSale || 0)}{" "}
        </span>
        <hr />
        <span>Amount Sold : {formatNumber(stage.amountSold || 0)} </span>
        <hr />
      </ContentStage>
    );
  };
  return (
    <Progress multi>
      {stages.map((s, i: number) => (
        <Progress
          bar
          value={+s.percentToSale || 100 / stages.length}
          color={colors[i]}
          animated={s.on_sale}
        >
          <ToolTip id={i + name} content={content(s)}>
            <ContentToolTip>
              {s.name} <span id={`tooltip-` + i + name}>!</span>
            </ContentToolTip>
          </ToolTip>
        </Progress>
      ))}
    </Progress>
  );
};

export default Stage;
