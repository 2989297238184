import styled from "styled-components";
import { Button } from "reactstrap";

const CustomButton = styled(Button)`
  background: ${(prop) =>
    prop.transparent ? "transparent" : prop.theme.colors[prop.color]};
  border: 1px solid ${(prop) => prop.theme.colors[prop.color]};
  color: ${(prop) =>
    prop.transparent ? prop.theme.colors[prop.color] : "#FFFFFF"};
  padding: 5px 32px;
  border-radius: 8px;
  margin: 0;
  font-size: 14px;
  cursor: pointer;
  height: ${(props) => props.height || "46px"};


  &:disabled,
  &:hover,
  &:focus {
    background: ${(prop) =>
    prop.transparent ? "transparent" : prop.theme.hoverColors[prop.color]};
    border-color: ${(prop) => prop.theme.hoverColors[prop.color]};
    color: ${(prop) =>
    prop.transparent ? prop.theme.hoverColors[prop.color] : "#FFFFFF"};
    box-shadow: none;
  }

  .spinner-border {
    margin-right: 8px;
  }
`;

export default CustomButton;
