import styled from "styled-components";

const ContainerNav = styled.div`
  .navbar {
    background-color: ${props => props.theme.colors.blue300};
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    font-size: 12px;

    .nav-link {
      padding: 7px 8px !important;
      color: white;
      .row {
        text-align: center;
        height: 42px !important;
        justify-content: space-between !important;
      }
    }
    .active {
      color: ${props => props.theme.colors.orange100};
    }
  }
`;

export default ContainerNav