import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
`;

export const NotificationWrapper = styled.div`
  margin: 0 5px;

  .dropdown-header {
    color: white;
    font-size: 17px;
    font-weight: 600;
    border-bottom: 1px solid white;
    display: flex;
    align-items: center;
    padding: 20px 10px;
    .title {
      flex: 1;
    }
    p {
      margin: 0;
    }
  }

  .dropdown-menu {
    max-height: 400px;
    overflow: auto;
    background-color: #1b3152;
    .dropdown-item {
      border-bottom: 1px solid gray;
      padding: 10px 10px;
      :hover {
        background-color: #143a73;
        opacity: 0.8;
      }
    }
    .read {
      opacity: 0.4;
    }
    .unread {
      opacity: 1;
    }
  }
`;

export const NoticeContent = styled.div`
  width: 360px;
  position: relative;
  height: 60px;
  overflow: hidden;
  display: flex;
  img {
    width: 30px;
  }
`;

export const ImgWrapper = styled.div`
  width: 10%;
  margin-right: 5px !important;
`;

export const Content = styled.div`
  width: 75%;
  margin-right: 5px;
  color: ${(props) => props.theme.colors.white100};
  h6 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 2px;
  }
  p {
    white-space: normal;
    font-size: 13px;
    margin-bottom: 1px;
  }
`;

export const Date = styled.div`
  font-size: 10px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.white100};
`;

export const IconWrapper = styled.div`
  position: relative;
  cursor: pointer;

`;

export const Count = styled.div`
  position: absolute;
  top: -7px;
  right: 0px;
  span {
    color: #ec0d1e;
    font-weight: bold;
    font-size: 10px;
  }
`;

export const ItemNoti = styled.div`
  position: relative;
`;

export const View = styled.div`
  position: absolute;
  cursor: pointer;
  z-index: 100;
  right: 10px;
  bottom: 10px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.orange100};
`;


