/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Profit from "pages/my/my-project/components/profit-ibo";
import { useLocation } from "react-router-dom";
import APIs from "api";
import { getCryptosAsync } from "redux/reducers/cryptos/cryptos.actions";

const View = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [isRender, setIsRender] = useState<boolean>(true);

  const setToken = async () => {
    if (location.search) {
      const token = location.search.split("token=")[1];

      if (token) {
        try {
          const isToken: any = await APIs.YOURESTOCK.verifyTokenEdit({
            token: token,
          });
          if (isToken && isToken.role === "ADMIN") {
            setIsRender(true);
            dispatch(getCryptosAsync());
          }
        } catch (error) {
          setIsRender(false);
          // console.log(error);
        }
      }
    }
  };
  useEffect(() => {
    setToken();
  }, []);

  return (
    <div style={{ padding: "0 10px" }}>
      {isRender && <Profit isWebview={true} />}
    </div>
  );
};

export default View;
