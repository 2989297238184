import React from "react";
import { formatNumber } from "utils/common";

import Wrapper from "./styles";

export default function View({ affiliates }) {
  return (
    <Wrapper>
      {Object.keys(affiliates || {}).map((key) => {
        const reward = affiliates[key];
        return (
          <>
            <div>Partner : {String(key).split("f")[1]}</div>
            <hr />
            {(reward["stocks"] || []).length > 0 && (
              <>
                <label>Stocks :</label>
                {reward["stocks"].map(
                  (s) =>
                    +s.amount > 0 && (
                      <div>
                        <span>
                          +{formatNumber(s.amount, 0)}
                          {s.is_percent ? "%" : ""} (
                          {String(s.symbol).toUpperCase()})
                        </span>
                      </div>
                    )
                )}
                <hr />
              </>
            )}
            {(reward["vouchers"] || []).length > 0 && (
              <>
                <label>Vouchers :</label>
                {reward["vouchers"].map(
                  (s) =>
                    +s.amount > 0 && (
                      <div>
                        <span>
                          +{s.amount} ({s.id})
                        </span>
                      </div>
                    )
                )}
                <hr />
              </>
            )}
            {(reward["tickets"] || []).length > 0 && (
              <>
                <label>Tickets :</label>
                {reward["tickets"].map(
                  (s) =>
                    s.amount > 0 && (
                      <div>
                        <span>
                          +{s.amount} ({s.id})
                        </span>
                      </div>
                    )
                )}
                <hr />
              </>
            )}
            {(reward["codes"] || []).length > 0 && (
              <>
                <label>Codes :</label>
                {reward["codes"].map((s) => (
                  <div>
                    <span>
                      +{s.codes} ({s.symbol})
                    </span>
                  </div>
                ))}
                <hr />
              </>
            )}
            <br />
          </>
        );
      })}
    </Wrapper>
  );
}
