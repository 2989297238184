/* eslint-disable max-len */

import { convertS3Link } from "api/s3-request";
import { isMobile } from "react-device-detect";

/* eslint-disable no-useless-escape */
function isAmount(data) {
  const re = /^[0-9]*\.?[0-9]*$/;
  let istrue = false;

  if (data === "" || re.test(data)) {
    istrue = true;
    return istrue;
  }
  return istrue;
}

function valueIsNumber(data) {
  const re = /^[0-9]*$/;
  let istrue = false;

  if (data === "" || re.test(data)) {
    istrue = true;
    return istrue;
  }
  return istrue;
}

function skipSpace(data) {
  const value = data.replace(/\s/g, "");
  return value;
}

function isValueText(data) {
  const re = /^[a-zA-Z ]*$/;
  let istrue = false;
  if (data === "" || re.test(data)) {
    istrue = true;
    return istrue;
  }
  return istrue;
}

function validateEmail(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const getUrlImage = (img) => {
  return `${
    process.env.REACT_APP_IMG || "http://localhost:3001/uploads"
  }/${img}`;
};

const type = {
  id_card: "Identity Card",
  passport: "Passport",
  driving_license: "Driving License",
};

const getType = (typeId) => type[typeId];

const formatNumber = (number, minimumFractionDigits = 2) => {
  return new Intl.NumberFormat([], {
    minimumFractionDigits: minimumFractionDigits,
  }).format(number);
};

const fortmatShortDate = (d: Date) => {
  return ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" +
  d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);;
};

const formatCurrency = (number) => {
  return new Intl.NumberFormat([], {
    minimumFractionDigits: 0,
  }).format(number);
};

const formatCurrencyByType = (
  number,
  type = "en-US",
  currency = "USD",
  minimumFractionDigits = 2
) => {
  return new Intl.NumberFormat(type || [], {
    minimumFractionDigits,
    style: "currency",
    currency,
  }).format(number);
};

function randString(isToken = false, len = 10) {
  var text = "";
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var n = len;
  if (isToken) {
    n = 40;
    possible = "abcdefghijklmnopqrstuvwxyz0123456789-";
  }
  for (var i = 0; i < n; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

function randCodeDeposit(isToken = false, len = 10) {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  var n = len;
  if (isToken) {
    n = 40;
    possible = "abcdefghijklmnopqrstuvwxyz0123456789-";
  }
  for (var i = 0; i < n; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

function copyText(text: string) {
  return navigator.clipboard.writeText(text);
}

const getBase64Image = async (url) => convertS3Link(url);

/**
 * 
 * @param string 
  new Promise((rs, rj) => {
    try {
      const img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.crossOrigin = "Anonymous";
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL("image/png");
        return rs(dataURL);
      };
      img.src = url + "?not-from-cache-please";
    } catch (error) {
      console.log(error);

      rs(null);
    }
  });
 * @returns 
 */

function uppercasefirst(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const getLinkYoutubeIframe = (idVideo: string, isPreview?: boolean) => {
  return `https://www.youtube.com/embed/${idVideo}?autoplay=${isMobile ? "0" : "1"}&mute=${
    isPreview ? "0" : "1"
  }&loop=1&playlist=${idVideo}&rel=0&modestbranding=1&autohide=1&showinfo=0&controls=${
    isPreview ? "1" : "0"
  }&disablekb=1&egm=1&fs=0&version=3&wmode=opaque&enablejsapi=1`;
};

function percentToHex(p: number) {
  return `0${Math.round((255 / 100) * p).toString(16)}`.slice(-2).toUpperCase();
}

export {
  isAmount,
  valueIsNumber,
  skipSpace,
  isValueText,
  validateEmail,
  getUrlImage,
  getType,
  formatNumber,
  formatCurrency,
  randString,
  randCodeDeposit,
  copyText,
  getBase64Image,
  formatCurrencyByType,
  uppercasefirst,
  getLinkYoutubeIframe,
  percentToHex,
  fortmatShortDate
};
