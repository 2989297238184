import React from "react";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import Wrapper, { Header, ContentAuth } from "./styles";

import NavBarBottom from "../components/navbar-bottom";
import VParticles from "components/particles";

interface IProps {
  [key: string]: any;
}

export default function View(props: IProps) {
  const history = useHistory();
  return (
    <Wrapper>
      <Header page={props.page}>
        <img onClick={() => history.push("/")}  src="/assets/images/logo.png" alt="Vzonex.com" />
      </Header>
      <ContentAuth page={props.page}>
        <VParticles />
        {props.children}
      </ContentAuth>
      {isMobile && <NavBarBottom />}
    </Wrapper>
  );
}
