/* eslint-disable react-hooks/exhaustive-deps */
import PurchaseService from "api/purchases";
import VButton from "components/button";
import VModal from "components/modal";
import Table from "components/table";
import { useState } from "react";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { getSalesStatisticsAsync } from "redux/reducers/users/users.actions";
import {
  getSalesStatistics,
  getUserProfile,
} from "redux/reducers/users/users.selector";
import { formatNumber } from "utils/common";
import { ModalHistoryWrapper } from "../invests/styles";
import { StatisWrapper, TableWrapper, TableContent } from "./styles";

const Statis = () => {
  const dispatch = useAppDispatch();
  const statis = useAppSelector(getSalesStatistics);
  const auth = useAppSelector(getUserProfile);

  const [openModal, setOpenModal] = useState(false);
  const [branchSelected, setBranchSelected] = useState(null);

  useEffect(() => {
    dispatch(getSalesStatisticsAsync({}));
  }, []);

  const columns = [
    {
      key: "User",
      title: "ID",
      render: (item) => {
        return `${item.refer_code}`;
      },
    },
    {
      key: "Username",
      title: "Username",
      render: (item) => {
        return `${item?.fullName || item?.username}`;
      },
    },
    {
      key: "affiliate marketing",
      title: "Affiliate marketing",
      render: (item) => {
        return `${item.level}`;
      },
    },
    {
      key: "info",
      title: "Info",
      render: (item: any) => (
        <VButton onClick={() => handleOpenModal(item)}>View</VButton>
      ),
    },
  ];

  const columnsHistoriesProfit = [
    {
      key: "name",
      title: "Package Name",
      render: (item) => {
        return item.package_id?.name;
      },
    },
    {
      key: "amount",
      title: "Amount",
      render: (item) => {
        return `${formatNumber(item.investment?.amount || 0)} (${String(
          item.investment?.principal?.stock
        ).toUpperCase()})`;
      },
    },
    {
      key: "date",
      title: "Date",
      render: (item) => {
        return new Date(item.createdAt).toLocaleString();
      },
    },
  ];

  const handleOpenModal = async (item) => {
    const orders: any = await PurchaseService.getPurchaseByUserId({
      params: {
        user_id: item._id,
      },
    });
    if (orders) {
      setBranchSelected(orders.purchases || []);
      setOpenModal(true);
    }
  };

  const renderModal = (investSelected: any) => {
    if (investSelected) {
      return (
        <ModalHistoryWrapper>
          {" "}
          <Table
            columns={columnsHistoriesProfit}
            dataSource={investSelected}
            bgTable={"black100"}
          ></Table>
        </ModalHistoryWrapper>
      );
    }
  };

  const handleClosed = () => {
    setOpenModal(false);
    setBranchSelected(null);
  };

  const _statis = (statis || []).map((user) => {
    const parent = String(user.parents || "")
      .split("_")
      .reverse();
    const level = parent.findIndex((a) => a === auth._id);
    return {
      ...user,
      level: level + 1,
    };
  });

  return (
    <StatisWrapper>
      {" "}
      <TableContent>
        <TableWrapper>
          <Table
            columns={columns}
            dataSource={_statis}
            bgTable="black100"
          ></Table>
        </TableWrapper>
      </TableContent>
      <VModal
        isOpen={openModal}
        children={renderModal(branchSelected)}
        onCancel={handleClosed}
        centered={true}
      />
    </StatisWrapper>
  );
};

export default Statis;
