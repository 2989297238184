import {useState} from 'react'
import Button from "components/button";
import Input from "components/input";
import Dropdown from "components/dropdown";
import { Wrapper } from "./style";
import { useAppSelector } from "redux/reducers/hook";
import { getCryptos } from "redux/reducers/cryptos/cryptos.selector";

import ModalDonate from "./ModalDonate";

export default function View(props: any) {
  const { isEdit, dataEvent, setDataEvent } = props;

  const charityValue = dataEvent?.charity;

  const [openModal, setOpenModal] = useState(false);

  const cryptos = useAppSelector(getCryptos);

  const onChangeAmount = (value) => {
    setDataEvent({
      ...dataEvent,
      charity: {
        ...dataEvent.charity,
        totalAmount: value,
      },
    });
  };

  const onChangeStock = (opt) => {
    setDataEvent({
      ...dataEvent,
      charity: {
        ...dataEvent.charity,
        stockSymbol: opt.value,
      },
    });
  };

  return (
    <Wrapper>
      <div className="form-group">
        <div className="input-amount">
          <Input
            transparent={true}
            color={"orange"}
            label="Tổng số e.stock gây quỹ:"
            defaultValue={+charityValue?.totalAmount}
            value={+charityValue?.totalAmount}
            onValueChange={onChangeAmount}
            placeholder="100.000.000"
            type="text"
            isCurrency={true}
            disabled={!isEdit}
          />
        </div>

        <div className="input-currency">
          <Dropdown
            transparent={true}
            color={"orange100"}
            width={"100%"}
            label={"Loại e.stock"}
            options={cryptos
              ?.filter((el: any) => String(el.symbol).toUpperCase() === "VGC")
              .map((el: any) => {
                return {
                  label: el?.name,
                  value: el?.symbol,
                };
              })}
            defaultValue={charityValue?.stockSymbol}
            disabled={!isEdit}
            onChange={(e) => onChangeStock(e)}
          />
        </div>

        {
          !isEdit && <div className='btn-donate'>
            <Button transparent={true} onClick={setOpenModal.bind(null, true)}>Donate</Button >
          </div>
        }
      </div>
      {
        !isEdit && <ModalDonate dataEvent={dataEvent} openModal={openModal} setOpenModal={setOpenModal}/>
      }

      
    </Wrapper>
  );
}
