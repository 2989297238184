import { formatNumber } from "./common";

const renderId = (_id: String) => {
  return `${_id.slice(0, 15)}...`;
};

const renderTextInDay = () => {
  const hour = new Date().getHours();
  if (0 <= hour && hour <= 12) return "Good Morning";
  if (13 <= hour && hour <= 18) return "Good Afternoon";
  return "Good Evening";
};

const renderAmount = (amount: number) => {
  if (amount >= 0)
    return <span style={{ color: "#00AC11" }}> +{formatNumber(+amount)} </span>;
  else
    return (
      <span style={{ color: "#dc3545" }}>
        {" "}
        -{formatNumber(Math.abs(+amount))}{" "}
      </span>
    );
};

export { renderId, renderTextInDay, renderAmount };
