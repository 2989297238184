import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "api";
import { IRequestData } from "api/request";

export const getCryptosAsync = createAsyncThunk("cryptos/list", async () => {
  try {
    const response = await API.CRYPTO.getCryptos();
    return response;
  } catch (error) {
    return error;
  }
});

export const getStocksPriceAsync = createAsyncThunk(
  "cryptos/stocks-price",
  async () => {
    try {
      const response = await API.CRYPTO.getStocksPrice();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getStocksSwapAsync = createAsyncThunk(
  "cryptos/stocks-swap",
  async () => {
    try {
      const response = await API.CRYPTO.getStocksSwap();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getStockProjetsSwapAsync = createAsyncThunk(
  "cryptos/stock-pre-mainet",
  async () => {
    try {
      const response = await API.CRYPTO.getStockProjetsSwap();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const swapsAsync = createAsyncThunk(
  "cryptos/swap",
  async (req: IRequestData) => {
    try {
      const response = await API.CRYPTO.swap(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const swapStocksProjectAsync = createAsyncThunk(
  "cryptos/swap-stocks-pre-mainet",
  async (req: IRequestData) => {
    try {
      const response = await API.CRYPTO.swapStocksProjectAsync(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getHistorySwapAsync = createAsyncThunk("cryptos", async (req) => {
  try {
    const response = await API.CRYPTO.getHistorySwap(req);
    return response;
  } catch (error) {
    return error;
  }
});
