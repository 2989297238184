import styled from "styled-components";
// import {isMobile} from 'react-device-detect';

const UserHeaderWapper = styled.div<{ isOpen?: boolean }>`
  width: 100%;
  padding: 2px 0;
  display: flex;
  background-color: #3d639c;
  position: fixed;
  height: 30px;
  top: 0;
  z-index: 9999;
  right: 0;
  align-items: center;
`;

export const Top = styled.div``;

export const Coins = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Coin = styled.div`
  margin-right: 40px;

  img {
    margin-right: 6px;
    width: 20px;
  }
  span {
    font-size: 16px;
    vertical-align: middle;
  }
`;

export const BalancesWrapper = styled.div`
  display: flex;
  justify-content: end;
  padding: 0 5px;
  align-items: center;
  span {
    font-weight: 700;
    color: ${(props) => props.theme.colors.green100};
  }
  .balances,
  .exchanges {
    font-size: 15px;
    margin-right: 15px;
  }
  white-space: nowrap;

  @media screen and (max-width: 500px) {
    font-size: 15px;
  }
`;

export default UserHeaderWapper;
