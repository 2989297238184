/* eslint-disable react-hooks/exhaustive-deps */
import { Tokenomic } from "./styles";

import { Container } from "reactstrap";
import { useEffect, useState } from "react";
import APIs from "api";
import { VictoryPie } from "victory-pie";

const Tokenomics = (props) => {
  const [data, setData] = useState([]);
  const { tokenomics } = props;

  useEffect(() => {
    if (tokenomics) {
      setData(tokenomics);
    } else {
      const didMount = async () => {
        const res: any = await APIs.SETTING.getSettingByName("tokenomics");
        if (res && res.setting && res.setting.value) {
          setData(res.setting.value);
        }
      };
      didMount();
    }
  }, []);

  return (
    <Container>
      <Tokenomic data-aos="flip-left" data-aos-duration="800">
        <VictoryPie
          style={{ labels: { fontSize: 11 } }}
          data={data.map((item) => ({
            x: `${item.percent}% ${item.name}`,
            y: +item.percent,
          }))}
          labels={({ datum }) => {
            return datum.xName || datum.x;
          }}
          colorScale={["blue", "yellow", "red", "white", "orange", "purple"]}
          radius={100}
          innerRadius={60}
        />
      </Tokenomic>
    </Container>
  );
};

export default Tokenomics;
