import {
  ContainerVisit,
  SubmitKYC,
  NoteUploadInvoice,
  VerifyWrapper,
} from "../styles";
import { useState } from "react";
import { toast } from "react-toastify";
import APIs from "api";
import UploadFile from "components/uploadFile";
import Button from "components/button";
import { getKycAsync } from "redux/reducers/users/users.actions";
import { useAppDispatch } from "redux/reducers/hook";
import { Col, Row } from "reactstrap";

const Verify = ({ setStepCurrent }) => {
  const dispatch = useAppDispatch();
  const [isSavingKYC, setIsSavingKYC] = useState(false);
  const [file, setFile] = useState<any>(null);
  const [fileVideo, setFileVideo] = useState<any>(null);

  const handleSaveKYC = async () => {
    setIsSavingKYC(true);
    try {
      const formData = new FormData();
      formData.append("files", file);
      const img: any = await APIs.UPLOAD.uploadFile({
        body: formData,
      });

      const formDataVideo = new FormData();
      formDataVideo.append("files", fileVideo);

      const video: any = await APIs.UPLOAD.uploadFile({
        body: formDataVideo,
      });

      const kyc: any = await APIs.KYC.createKyc({
        body: {
          img_verifed: img.filePath,
          video_intro: video.filePath,
          method: "verify",
        },
      });
      if (kyc) {
        toast.success("successfull!");
        dispatch(getKycAsync());
        setStepCurrent(null);
      }
    } catch (err) {
      toast.error("Something went wrong!");
    }
    setIsSavingKYC(false);
  };

  

  return (
    <VerifyWrapper>
      <Row>
        <Col md={2}></Col>
        <Col md={4}>
          <span>Video</span>
          <ContainerVisit>
            <UploadFile
              id="fileVideo"
              ratio="220x350"
              typeUpload="video"
              file={fileVideo}
              setFile={setFileVideo}
            />
          </ContainerVisit>
        </Col>
        <Col md={4}>
          <span>Image verified</span>
          <ContainerVisit>
            <UploadFile
              id="file"
              ratio="220x350"
              typeUpload="image"
              file={file}
              setFile={setFile}
            />
          </ContainerVisit>
        </Col>
        <Col md={2}></Col>
      </Row>
      <SubmitKYC>
        <Button onClick={handleSaveKYC} loading={isSavingKYC}>
          Save
        </Button>
      </SubmitKYC>
      <NoteUploadInvoice>
        <h6>Note : </h6>
        <p> - Please upload electricity, water, internet bills</p>
        <p>- Invoices must have your stamp and address information</p>
        <p>
          - VIDEO : Please upload a short video (with the front of the hand in
          hand ID card or driver's license or passport and show the face
          clearly){" "}
        </p>
      </NoteUploadInvoice>
    </VerifyWrapper>
  );
};

export default Verify;
