import axios from "axios";
import { request, parseErrorResponse } from "./request";

const getSettingByName = (name: string) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/settings/${name}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getCities = () =>
  new Promise((resolve, reject) => {
    axios
      // .get("https://api.blockcheck.vn/api/cities/province")
      .get("/api/cities/province.json")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getLocations = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/locations/getAll`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const APIs = {
  getSettingByName,
  getCities,
  getLocations,
};

export default APIs;
