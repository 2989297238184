import { ROUTES } from "config/routes";
import { v4 as uuidv4 } from "uuid";

export const eventType = {
  smart_event: "smart_event",
  smart_school: "smart_school",
  smart_poll: "smart_poll",
  smart_hospital: "smart_hospital",
  smart_charity: "smart_charity",
};

export const eventPermissions = {
  smart_event: "events",
  smart_school: "events--school",
  smart_poll: "events--pool",
  smart_hospital: "events--hospital",
  smart_charity: "events--charity",
};

export const eventTypeMeta = {
  smart_event: {
    name: "Smart Events",
    icon: "",
    routes: {
      root: ROUTES.SMART_EVENTS,
      create: ROUTES.SMART_EVENTS_CREATE,
      detail: ROUTES.SMART_EVENTS_DETAIL,
      my: ROUTES.SMART_EVENTS_MY_LIST,
    },
  },
  smart_school: {
    name: "Smart School",
    routes: {
      root: ROUTES.SMART_SCHOOLS,
      create: ROUTES.SMART_SCHOOLS_CREATE,
      detail: ROUTES.SMART_SCHOOLS_DETAIL,
      my: ROUTES.SMART_SCHOOLS_MY_LIST,
    },
  },
  smart_poll: {
    name: "Smart Polls",
    routes: {
      root: ROUTES.SMART_POOLS,
      create: ROUTES.SMART_POOLS_CREATE,
      detail: ROUTES.SMART_POOLS_DETAIL,
      my: ROUTES.SMART_POOLS_MY_LIST,
    },
  },
  smart_hospital: {
    name: "Smart Hospital",
    routes: {
      root: ROUTES.SMART_HOSPITALS,
      create: ROUTES.SMART_HOSPITALS_CREATE,
      detail: ROUTES.SMART_HOSPITALS_DETAIL,
      my: ROUTES.SMART_HOSPITALS_MY_LIST,
    },
  },
  smart_charity: {
    name: "Smart Charity",
    routes: {
      root: ROUTES.SMART_CHARITY,
      create: ROUTES.SMART_CHARITY_CREATE,
      detail: ROUTES.SMART_CHARITY_DETAIL,
      my: ROUTES.SMART_CHARITY_MY_LIST,
    },
  },
};

export const getEventTypeFromRoute = (pathname) => {
  const key = Object.keys(eventType).find(key => {
    return String(pathname).toLowerCase().indexOf(key.split("smart_")[1]) > -1
  })

  return eventType[key] || eventType.smart_event;
}

export const getEventRoute = (event, type) => {
  console.log('getEventRoute', event);
  
  
  if (!event) {
    return eventTypeMeta[eventType.smart_event].routes.root
  }
  const meta = eventTypeMeta[event?.event?.eventType] || eventTypeMeta.smart_event;
  return meta.routes[type || "root"];
};


export const getEventTypeFromObj = (event) => {
  return event?.event?.eventType || eventType.smart_event
};


export const getDefaultEventData = (eventTypeDf?: string) => ({
  name: "Lễ công bố trên nền tảng hệ sinh thái Vzonex Global",
  description: "Mô tả",
  eventType: eventTypeDf || eventType.smart_event, // smart_event smart_school smart_poll smart_hospital smart_charity
  banner: {
    link: "",
    slide: [],
    imageBottom: {
      src: "",
    },
  },
  advertisement_banner: {
    left: [],
    right: [],
  },
  organizational_units: {
    title: "Đơn vị tổ chức",
    left_icon: [
      {
        id: uuidv4(),
        src: "",
        link: "",
      },
      {
        id: uuidv4(),
        src: "",
        link: "",
      },
      {
        id: uuidv4(),
        src: "",
        link: "",
      },
    ],
    right_icon: [
      {
        id: uuidv4(),
        src: "",
        link: "",
      },
      {
        id: uuidv4(),
        src: "",
        link: "",
      },
      {
        id: uuidv4(),
        src: "",
        link: "",
      },
    ],
  },
  event_calendars: {
    title: "",
    content: [
      {
        id: uuidv4(),
        title: "Lịch làm việc",
        src: "",
        link: "",
      },
    ],
  },
  guest: {
    title: "Đại biểu khách mời - Diễn giả",
    content: [
      {
        id: uuidv4(),
        src: "",
        position: "Tổng giám đốc",
        company: "Vzone Global",
        link: "",
      },
      {
        id: uuidv4(),
        src: "",
        position: "Tổng giám đốc",
        company: "Vzone Global",
        link: "",
      },
      {
        id: uuidv4(),
        src: "",
        position: "Tổng giám đốc",
        company: "Vzone Global",
        link: "",
      },
    ],
  },
  sponsors: {
    title: "Đơn vị tài trợ",
    content: [
      {
        id: uuidv4(),
        src: "",
        link: "",
      },
      {
        id: uuidv4(),
        src: "",
        link: "",
      },
      {
        id: uuidv4(),
        src: "",
        link: "",
      },
    ],
  },
  companions: {
    title: "Đơn vị đồng hành - truyền thông",
    content: [
      {
        id: uuidv4(),
        src: "",
        link: "",
      },
      {
        id: uuidv4(),
        src: "",
        link: "",
      },
      {
        id: uuidv4(),
        src: "",
        link: "",
      },
    ],
  },
  doc: {
    title: "Tài liệu",
    content: [
      {
        id: uuidv4(),
        title: "Doc 1",
        decs: "Doc 1",
        src: "",
        link: "",
      },
      {
        id: uuidv4(),
        title: "Doc 2",
        decs: "Doc 2",
        src: "",
        link: "",
      },
      {
        id: uuidv4(),
        title: "Doc 3",
        decs: "Doc 3",
        src: "",
        link: "",
      },
    ],
  },
  video: {
    title: "Videos",
    content: [],
  },
  surveys: [
    {
      id: uuidv4(),
      title: "Khảo sát",
      content: [
        {
          id: uuidv4(),
          src: "",
          urlVideo: "https://www.youtube.com/watch?v=Aq6W-nmRO94",
          name: "",
          slide: [],
          listIcon: {},
          checked: [],
        },
      ],
      detail: [],
      type: "image_video",
      limit: 1,
    },
  ],
  color: {
    backgroundLogos: "",
    background1: "",
    background2: "",
    text: "",
    title: "#FF9900",
    backgroundOpacity: 1,
  },
  listIconConnect: [],
  interactive: {
    care_abouts: [],
    participations: [],
    to_share: [],
  },
  commentsField: [],
  wallpaper: "",
  wallpaperVideo: null,
  wallpaperLinkClick: "",
  // charity
  charity: {
    totalAmount: 0,
    stockSymbol: "vgc",
    rounds: []
  },
  start_day: "2022-12-22T16:20",
  end_day: "2022-12-22T16:20",
});

export const optionSetting = [
  {
    title: "Nền thanh QC Logo",
    key: "backgroundLogos",
  },
  {
    title: "Nền lớp dưới",
    key: "background1",
  },
  {
    title: "Nền mỗi section",
    key: "background2",
  },
  {
    title: "Màu text",
    key: "text",
  },
  {
    title: "Màu tiêu đề",
    key: "title",
  },
];

export const optionSectionDefault = {
  title: {
    title: "Thanh tiêu đề",
    isShow: true,
  },
  organization: {
    title: "Đơn vị tổ chức",
    isShow: true,
  },
  event_calendars: {
    title: "Lịch làm việc",
    isShow: true,
  },
  guest: {
    title: "Đại biểu khách mời - Diễn giả",
    isShow: true,
  },
  sponsor_companion: {
    title: "Đơn vị tài trợ / đồng hành - truyền thông",
    isShow: true,
  },
  doc: {
    title: "Tài liệu",
    isShow: true,
  },
  video: {
    title: "Video",
    isShow: true,
  },
  survey: {
    title: "Khảo sát",
    isShow: true,
  },
  review: {
    title: "Bảng đánh giá",
    isShow: true,
  },
};
