import Input from "components/input";
import UploadFile from "components/uploadFile";
import { Upload } from "pages/home/landing-page/styles";
import {
  Wrapper,
  Container,
  ItemIcon,
  AddIcon
} from "./styles";
const View = (props) => {
  const { isEdit, listIconConnect, handleAddOrRemoveChil, onBlurDocument, setFile } = props;
  const handleClickIcon = (link: string) => {
    window.open(link)
  }
  
  return <Wrapper>
    <Container>
      {
        listIconConnect?.length > 0 &&
        listIconConnect.map(el => <>
          <ItemIcon>
            {
              isEdit ? <Upload>
                <UploadFile
                  id={el.id}
                  ratio="60x60"
                  sectionName="icon_connect"
                  file={el.file}
                  src={el.src}
                  setFile={setFile}
                />
              </Upload> : <img style={{cursor: "pointer"}} src={el.src} alt="" onClick={() => handleClickIcon(el.link)} />
            }
          </ItemIcon>
          {isEdit && (
            <Input
              transparent={true}
              color={"orange"}
              defaultValue={el?.link}
              onBlur={(e) =>
                onBlurDocument(e, "icon_connect", el.id)
              }
              placeholder="Link"
            />
          )}
        </>
        )
      }
      {isEdit && (
        <AddIcon>
          <div
            onClick={() => handleAddOrRemoveChil("icon_connect", "add")}
          >
                    +
          </div>
          <div
            onClick={() =>
              handleAddOrRemoveChil("icon_connect", "remove")
            }
          >
                    -
          </div>
        </AddIcon>
      )}
    </Container>
  </Wrapper>
}

export default View;