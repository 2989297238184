/* eslint-disable no-empty-pattern */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IRegisterState {
  noticePopup: {
    type: string,
    amount: number
  };
}

const initialState: IRegisterState = {
  noticePopup: {
    type: "",
    amount: 0
  }
};

export const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    setNoticePopup: (state: IRegisterState, action: PayloadAction<{
      type: string,
      amount: number
    }>) => {
      state.noticePopup = action.payload;
    },
  },
  extraReducers: {}
});

export const { setNoticePopup } = registerSlice.actions;

export default registerSlice.reducer;
