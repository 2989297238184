/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import { useParams } from "react-router-dom";
import Input from "components/input";
import { Content, DetailRightView, Title } from "../../styles";
import { useEffect, useMemo, useState } from "react";
import {
  Available,
  HistoryTitle,
  HistoryTransfer,
  SpanColor,
  Stock,
  StockWrapper,
  Submit,
  TransferWrapper,
} from "./styles";
import { useAppSelector } from "redux/reducers/hook";
import { getStocks } from "redux/reducers/cryptos/cryptos.selector";
import {
  getBalances,
  getUserProfile,
} from "redux/reducers/users/users.selector";
import { formatNumber } from "utils/common";
import Button from "components/button";
import APIs from "api";
import { toast } from "react-toastify";
import Table from "components/table";
import { renderAmount } from "utils/render";

const View = () => {
  const user = useAppSelector(getUserProfile);
  const stocks = useAppSelector(getStocks);
  const balances = useAppSelector(getBalances);

  const params = useParams();
  const { stock } = params;

  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);

  const stockImg = useMemo(() => {
    return stocks?.find((item) => stock === item.symbol);
  }, [stock, stocks]);

  const columns = [
    {
      title: "From",
      dataIndex: "from",
      key: "from",
      render: () => <span>Balances</span>,
    },
    {
      title: "To",
      dataIndex: "To",
      key: "to",
      render: () => <span>Trade Balances</span>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (data: any) => (
        <SpanColor color="orange100">{renderAmount(-data.amount)}</SpanColor>
      ),
    },
    {
      title: "Amount Locked",
      dataIndex: "amount_locked",
      key: "amount_locked",
      render: (data: any) => (
        <SpanColor color="orange100">
          {renderAmount(data.amountLocked)}
        </SpanColor>
      ),
    },
    {
      title: "Amount Received",
      dataIndex: "amount_received",
      key: "amount_locked",
      render: (data: any) => (
        <SpanColor color="orange100">
          {renderAmount(data.amountReceived)}
        </SpanColor>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (data: any) => (
        <SpanColor color={"green100"}>{data.status}</SpanColor>
      ),
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data: any) => (
        <span>{new Date(data.createdAt).toLocaleString()}</span>
      ),
    },
  ];

  const handleTransferBalances = () => {
    setLoading(true);
    APIs.USER.swapToTradeBalances({
      user_id: user._id,
      amount,
      token: stock,
    })
      .then((res) => {
        if (res) {
          toast.success("Transfer success!");
          setAmount(0);
          getHistory();
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => setLoading(false));
  };

  const getHistory = async () => {
    try {
      const res: any = await APIs.TRANSACTION.getTransactions({
        query: {
          stock,
          type: "transfer_to_trade",
        },
      });
      setHistory(res?.transactions);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setAmount(0);
  }, [stock]);

  useEffect(() => {
    getHistory();
  }, []);

  return (
    <TransferWrapper>
      <DetailRightView>
        <Title bgColor={"grey200"}>
          Transfer {stock.toUpperCase()} to Trade Balance
        </Title>
        <Content>
          <StockWrapper>
            <Stock>
              <img
                src={stockImg?.logo || "/assets/images/coins/dollar.png"}
                alt=""
              />
              <p>{stock.toUpperCase()}</p>
            </Stock>
            <Available>
              <p>Available:</p>
              <p>
                {formatNumber(balances[stock] || 0)} {stock.toUpperCase()}
              </p>
            </Available>
          </StockWrapper>
          <Input
            transparent={true}
            label="Amount"
            type="number"
            placeholder="0"
            value={amount || ""}
            onChange={(e) => setAmount(+e.target.value)}
            suffixIcon={stock.toUpperCase()}
          />

          <Submit>
            <Button
              loading={loading}
              onClick={handleTransferBalances}
              disabled={!amount}
            >
              Submit
            </Button>
          </Submit>
        </Content>
      </DetailRightView>
      {history.length > 0 && (
        <DetailRightView>
          <HistoryTitle bgColor={"grey200"}>History</HistoryTitle>
          <HistoryTransfer>
            <Table
              columns={columns.filter((a) => a)}
              dataSource={history}
              bgTable={"blue300"}
            ></Table>
          </HistoryTransfer>
        </DetailRightView>
      )}
    </TransferWrapper>
  );
};

export default View;
