import { Warning, ContentRightView, Continue } from "../../../styles";
import Input from "components/input";
import Button from "components/button";
import { toast } from "react-toastify";
import Network from "../../networks";

const Step1 = ({ dataPayment, nextStep, setDataByKey, backStep }) => {
  const handleChange = (e) => {
    setDataByKey("amount", e);
  };

  const handleClick = () => {
    if (+dataPayment.amount <= 0)
      return toast.error("Amount must be greater 0!");
    nextStep();
  };

  return (
    <ContentRightView>
      <Network
        network={dataPayment.network}
        setNetwork={(data) => {
          setDataByKey("network", data);
        }}
      />
      <Input
        isCurrency={true}
        transparent={true}
        label={`Amount (${String(dataPayment.stock || "").toUpperCase()}) *`}
        value={dataPayment.amount}
        onValueChange={(e) => handleChange(e)}
        placeholder="10,000"
      />
      <Warning>
        <img src="/assets/images/icons/warning.svg" alt="warning" />
        <p>
          {dataPayment.stock.toUpperCase()} deposit is free. Vzonex does not
          collect {dataPayment.stock.toUpperCase()} deposit fee, however, in
          some instances, you may have to bear a small transaction fee charged
          directly by the processing bank or network.
        </p>
      </Warning>
      <Continue>
        <Button onClick={handleClick}>Continue</Button>
        <Button transparent={true} onClick={() => backStep()}>
          Back
        </Button>
      </Continue>
    </ContentRightView>
  );
};

export default Step1;
