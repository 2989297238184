import styled from "styled-components"

export const Container = styled.div`
    background-color: #172D4D;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    padding: 30px;

    button {
        margin-right: 10px;
    }
`