/* eslint-disable indent */
import { setAdvertisement } from "redux/reducers/advertisments/advertisments.reducers";
import {
  setNotificationClient,
  setNotificationSystem,
} from "redux/reducers/notifications/notifications.reducers";
import { setReward } from "redux/reducers/settings/settings.reducer";
import { getBalancesAsync, getKycAsync } from "redux/reducers/users/users.actions";
import io from "socket.io-client";

const socketMiddleware = (store: any) => (next: any) => {
  const connectSocket = () => {
    const token = localStorage.getItem("auth");
    if (token) {
      const socket = io(`${process.env.REACT_APP_SOCKET_URL}/users`, {
        query: {
          token,
        },
        transports: ["websocket"],
      });
      socket.on("balances", () => {
        store.dispatch(getBalancesAsync());
      });
      socket.on("rewards", (data) => {
        store.dispatch(setReward(data));
        store.dispatch(getKycAsync());
      });
      socket.on("notifications", (data) => {
        store.dispatch(setNotificationClient(data));
      });
      socket.on("notifications-system", (data) => {
        store.dispatch(setNotificationSystem(data));
      });
    }
  };

  const connectSocketAd = () => {
    const socket = io(`${process.env.REACT_APP_SOCKET_URL}/advertisements`, {
      transports: ["websocket"],
    });
    socket.on("advertisement", (data) => {
      store.dispatch(setAdvertisement(data));
    });
  };

  return (action: any) => {
    switch (action.type) {
      case "users/setConnectSocket": {
        if (action.payload) {
          connectSocket();
        }
        return next(action);
      }
      case "settings/setConnectSocket": {
        if (action.payload) {
          connectSocketAd();
        }
        return next(action);
      }
      default:
        return next(action);
    }
  };
};

export default socketMiddleware;
