import Countdown from "components/countdown";
import { Container, CountdownContainer } from "./style";

const View = () => {
  return (
    <Container>
      <h4>Function will work later : </h4>
      <CountdownContainer>
        <Countdown date={new Date("2023/12/23").getTime()} />
      </CountdownContainer>
      <img src="/assets/images/vzx/comming-soon.png" alt="warning" />
    </Container>
  );
};

export default View;
