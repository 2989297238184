/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { MdOutlineEdit } from "react-icons/md";
import { DateBox, DateEvent, DateItem, EditDateTime, Status } from "../styles";

import { compareDateWithCur } from "utils/times";
import Countdown from "react-countdown";
import { CounterdownContent } from "pages/home/projects/project-detail/styles";

const View = (props: any) => {
  const { type, isEdit, data, textColor, setDataEvent } = props;
  const [statusEvent, setStatusEvent] = useState<string>("Sắp diễn ra");

  const [dateTime, setDateTime] = useState<any>({
    start_day: {
      day: null,
      month: null,
      year: null,
      hours: null,
      minutes: null,
    },
    end_day: {
      day: null,
      month: null,
      year: null,
      hours: null,
      minutes: null,
    },
  });

  const handleChangeDateTime = (e: any, type: string) => {
    const value = e.target.value;
    convertDate(value, type);
    setDataEvent((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const convertDate = (value, key) => {
    const data = {
      day: new Date(value).getDate(),
      month: new Date(value).getMonth() + 1,
      year: new Date(value).getFullYear(),
      hours: new Date(value).getHours(),
      minutes: new Date(value).getMinutes(),
    };
    setDateTime((prev) => ({
      ...prev,
      [key]: data,
    }));
  };

  useEffect(() => {
    let stt: string = statusEvent;

    if (data.start_day) {
      convertDate(data.start_day, "start_day");
    }

    if (data.end_day) {
      convertDate(data.end_day, "end_day");
    }

    if (compareDateWithCur(data.start_day) > 0) {
      stt = "Sắp diễn ra";
    } else {
      if (
        compareDateWithCur(data.start_day) < 0 &&
        compareDateWithCur(data.end_day) > 0
      ) {
        stt = "Đang diễn ra";
      } else {
        stt = "Đã kết thúc";
      }
    }

    if (stt !== statusEvent) {
      setStatusEvent(stt);
    }
  }, [data]);

  const CountDownElement = (props) => {
    return (
      <div className="countdown-section">
        <div className="countdown-item">
          <span className="countdown-item-value">{props.days}</span>
          <span className="countdown-item-label">Ngày</span>
        </div>
        <div className="countdown-item">
          <span className="countdown-item-value">{props.hours}</span>
          <span className="countdown-item-label">Giờ</span>
        </div>
        <div className="countdown-item">
          <span className="countdown-item-value">{props.minutes}</span>
          <span className="countdown-item-label">Phút</span>
        </div>
        <div className="countdown-item">
          <span className="countdown-item-value">{props.seconds}</span>
          <span className="countdown-item-label">Giây</span>
        </div>
      </div>
    );
  };

  const countdow_render = ({ days, hours, minutes, seconds }) => {
    return (
      <CountDownElement
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  };

  return (
    <>
      <DateEvent>
        {!isEdit && (
          <Status
            color={
              statusEvent === "Sắp diễn ra"
                ? "yellow100"
                : statusEvent === "Đang diễn ra"
                ? "green100"
                : "grey100"
            }
          >
            {statusEvent === "Sắp diễn ra"
              ? "Sự kiện sẽ bắt đầu sau:"
              : statusEvent}
          </Status>
        )}
        {statusEvent === "Sắp diễn ra" && !isEdit && (
          <CounterdownContent>
            <Countdown
              date={new Date(data?.start_day).getTime()}
              renderer={countdow_render}
            />
          </CounterdownContent>
        )}
        <DateItem textColor={textColor}>
          {type === "event_detail" && <label>Bắt đầu:</label>}
          <div>
            <DateBox>{dateTime.start_day.day || "22"}</DateBox>
            <DateBox>{dateTime.start_day.month || "12"}</DateBox>
            <DateBox>{dateTime.start_day.year || "2022"}</DateBox>

            <DateBox ml={"12px"}>{dateTime.start_day.hours || "08"}</DateBox>
            <DateBox>{dateTime.start_day.minutes || "00"}</DateBox>
            <DateBox>00</DateBox>
            {isEdit && (
              <EditDateTime>
                <MdOutlineEdit size={20} />
                <input
                  type="datetime-local"
                  onChange={(e) => handleChangeDateTime(e, "start_day")}
                ></input>
              </EditDateTime>
            )}
          </div>
        </DateItem>
        <DateItem textColor={textColor}>
          {type === "event_detail" && <label></label>}
          <div>
            <DateBox noBg={true} textColor={textColor}>
              Ngày
            </DateBox>
            <DateBox noBg={true} textColor={textColor}>
              Tháng
            </DateBox>
            <DateBox noBg={true} textColor={textColor}>
              Năm
            </DateBox>

            <DateBox noBg={true} ml={"12px"} textColor={textColor}>
              Giờ
            </DateBox>
            <DateBox noBg={true} textColor={textColor}>
              Phút
            </DateBox>
            <DateBox noBg={true} textColor={textColor}>
              Giây
            </DateBox>
          </div>
        </DateItem>
        {(isEdit ||
          statusEvent !== "Sắp diễn ra" ||
          type === "event_detail") && (
          <DateItem
            textColor={
              compareDateWithCur(data.end_day) < 0 ? "#dc3545" : textColor
            }
          >
            {type === "event_detail" && <label>Kết thúc:</label>}
            <div>
              <DateBox
                textColor={
                  compareDateWithCur(data.end_day) < 0 ? "#dc3545" : ""
                }
              >
                {dateTime.end_day.day || "22"}
              </DateBox>
              <DateBox
                textColor={
                  compareDateWithCur(data.end_day) < 0 ? "#dc3545" : ""
                }
              >
                {dateTime.end_day.month || "12"}
              </DateBox>
              <DateBox
                textColor={
                  compareDateWithCur(data.end_day) < 0 ? "#dc3545" : ""
                }
              >
                {dateTime.end_day.year || "2022"}
              </DateBox>

              <DateBox
                ml={"12px"}
                textColor={
                  compareDateWithCur(data.end_day) < 0 ? "#dc3545" : ""
                }
              >
                {dateTime.end_day.hours || "08"}
              </DateBox>
              <DateBox
                textColor={
                  compareDateWithCur(data.end_day) < 0 ? "#dc3545" : ""
                }
              >
                {dateTime.end_day.minutes || "00"}
              </DateBox>
              <DateBox
                textColor={
                  compareDateWithCur(data.end_day) < 0 ? "#dc3545" : ""
                }
              >
                00
              </DateBox>
              {isEdit && (
                <EditDateTime>
                  <MdOutlineEdit size={20} />
                  <input
                    type="datetime-local"
                    onChange={(e) => handleChangeDateTime(e, "end_day")}
                  ></input>
                </EditDateTime>
              )}
            </div>
          </DateItem>
        )}
        <DateItem textColor={textColor}></DateItem>
      </DateEvent>
    </>
  );
};

export default View;
