/* eslint-disable react-hooks/exhaustive-deps */
import { 
  Certificate, 
  ConfimationProject, 
  ImageCer, 
  InfoProject, 
  LogoCer, 
  ContainerImageCerBottom, 
  ContainerCerIcon } from "pages/home/landing-page/styles";
import React, { useEffect, useRef, useState } from "react";
import { getBase64Image } from "utils/common";

const CertificateLandingPage = (props) => {
  const { data, onClickLogoCer } = props
  const imgRef = useRef(null);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const getImages = async () => {
      if (data && data.dataEstock) {
        const {
          certificateLogo,
          certificateImg1,
          certificateImg2,
          certificateImg3,
        } = data.dataEstock
        const res = await Promise.all(
          [
            certificateLogo,
            certificateImg1,
            certificateImg2,
            certificateImg3,
          ].map(async (i, index) => await getBase64Image(i))
        );
        setImages(res);
      }
    };
    getImages();
  }, [data]);

  return (
    <Certificate ref={imgRef}>
      <img src="/assets/images/certificate.png" alt="" />
      <ContainerCerIcon>
        <LogoCer onClick={(e) => onClickLogoCer(e, data.dataEstock?.linkCertificateLogo || "")}>
          <img src={images[0]} alt="" />
        </LogoCer>
      </ContainerCerIcon>
      <InfoProject>
        <div>{data?.dataEstock?.nameProject || data?.dataProject?.name}</div>
        <div>
          {data?.dataEstock?.ownerProject
            || data?.dataUser?.organizationsData?.representative
            || data?.dataUser?.businessData?.business_owner
            || data?.dataUser?.fullname}
        </div>
        <div>{data?.dataEstock?.addressProject || data?.dataUser?.address}</div>
      </InfoProject>
      <ConfimationProject>
        <ContainerImageCerBottom>
          <ImageCer onClick={(e) => onClickLogoCer(e, data.dataEstock?.linkCertificateImg1 || "")}>
            <img src={images[1]} alt="" />
          </ImageCer>
        </ContainerImageCerBottom>
        <ContainerImageCerBottom>
          <ImageCer onClick={(e) => onClickLogoCer(e, data.dataEstock?.linkCertificateImg2 || "")}>
            <img src={images[2]} alt="" />
          </ImageCer>
        </ContainerImageCerBottom>
        <ContainerImageCerBottom>
          <ImageCer onClick={(e) => onClickLogoCer(e, data.dataEstock?.linkCertificateImg3 || "")}>
            <img src={images[3]} alt="" />
          </ImageCer>
        </ContainerImageCerBottom>
      </ConfimationProject>
    </Certificate>
  );
};

export default CertificateLandingPage;
