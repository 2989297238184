import { Controller } from "react-hook-form";

import {
  FormDescription,
  FormItem,
} from "../styles";

import Input from "components/input";

import FormProjectICO from "pages/my/my-project/components/form-project-ICO"


const View = (props) => {
  const {
    control,
    errors,
    errorField,
    fieldTokenomics,
    dataDetail,
    totalSupply,
    setTotalSupply,
    dataICO,
    setDataICO,
    setDataDetail,
    setFieldTokenomics,
  } = props
  return <>
    <FormDescription>Project information</FormDescription>
    <FormItem>
      <Controller
        name="name"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <Input
            transparent={true}
            hasError={Boolean(errors.name)}
            label="Name e.Stock"
            placeholder="name"
            {...field}
          />
        )}
      />
    </FormItem>
    <FormItem>
      <Controller
        name="symbol"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <Input
            transparent={true}
            hasError={Boolean(errors.symbol)}
            label="Symbol"
            placeholder="symbol"
            {...field}
          />
        )}
      />
    </FormItem>
    <FormItem>
      <Input
        type="text"
        transparent={true}
        placeholder="total supply"
        label="Total Supply"
        isCurrency={true}
        allowNegativeValue={false}
        defaultValue={totalSupply}
        disabled={false}
        // hasError={errors.stock_price}
        onValueChange={(e) => setTotalSupply(e)}
      />
    </FormItem>
    <FormProjectICO
      active={'create'}
      isDisable={false}
      isShowDetail={false}
      dataDetail={dataDetail}
      control={control}
      errors={errors}
      errorField={errorField}
      fieldTokenomics={fieldTokenomics}
      setFieldTokenomics={setFieldTokenomics}
      dataICO={dataICO}
      setDataICO={setDataICO}
      setDataDetail={setDataDetail}
    />
  </>
}

export default View