/* eslint-disable react-hooks/exhaustive-deps */
import {
  ItemContent,
  Title,
  Description,
  Project,
  QrCodeWrapper,
  TextWallet,
} from "./styles";
import QRCode from "react-qr-code";
import { Row, Col, Container } from "reactstrap";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { useEffect } from "react";
import { getProjectsAsync } from "redux/reducers/settings/settings.action";
import { getProjects } from "redux/reducers/settings/settings.selector";

const Projects = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(getProjects);
  useEffect(() => {
    dispatch(getProjectsAsync());
  }, []);
  // const data = [
  //   {
  //     name: "Vzonex",
  //     desc: "Bonus user Join Plan Digital Affiliate Marketing",
  //     totalSupply: "21,000,000,000",
  //     allotted: 0,
  //     symbol: "VZX",
  //     contract: "0xb74D58f0ff784Fd0Ea4cdE1ff3B4E4981bb1B8fB",
  //   },
  //   {
  //     name: "Vzone Global Technology Central",
  //     desc: "Bonus user Join Plan Digital Affiliate Marketing",
  //     totalSupply: "210,000,000",
  //     allotted: 0,
  //     symbol: "VTC",
  //     contract: "0x4278546FCE2F94f105d44D14d9C65d0a99e44755",
  //   },
  //   {
  //     name: "Vzone Global Charity",
  //     desc: "Bonus user Join Plan Digital Affiliate Marketing",
  //     totalSupply: "21,000,000,000",
  //     allotted: 0,
  //     symbol: "VGC",
  //     contract: "0xFbbb8359d0668B8182dF9c1382F3Ab029668012F",
  //   },
  //   {
  //     name: "Vzone Global Adversting",
  //     desc: "Bonus user Join Plan Digital Affiliate Marketing",
  //     totalSupply: "21,000,000",
  //     allotted: 0,
  //     symbol: "VGA",
  //     contract: "0x68a25E5eBb264209251299DAAe64561E3b2A3EEa",
  //   },
  //   {
  //     name: "Vdiarybook",
  //     desc: "Bonus user Join Plan Digital Affiliate Marketing",
  //     totalSupply: "2,100,000,000",
  //     allotted: 0,
  //     symbol: "VDB",
  //     contract: "0x091A26649a0cd8aB66A0B7965e032E28713D74b3",
  //   },
  // ];

  return (
    <Container>
      <Title>
        <h3>VZONE GLOBAL ECOSYSTEM BLOCKCHAIN PROJECT (BY VZONEX)</h3>
        <hr />
        <Description>
          We are developing projects that unleash our potential and make our
          ambitions come true.
        </Description>
      </Title>
      <Row>
        {data.map((item: any, index: number) => (
          <Col md={4} key={index}>
            <ItemContent data-aos="flip-left" data-aos-duration="800">
              <Project>
                <QrCodeWrapper>
                  <QRCode value={item.contract} size={80} />
                </QrCodeWrapper>
                <TextWallet>{item.contract}</TextWallet>
                <h4>{item.name}</h4>
                <span>{item.desc}</span>
                <p>
                  Total Supply: {item.totalSupply} {item.symbol}
                </p>
                <p>
                  Allotted: {item.allotted} {item.symbol}
                </p>
              </Project>
            </ItemContent>
          </Col>
        ))}
      </Row>
    </Container>
  );
};
export default Projects;
