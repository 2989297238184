/* eslint-disable indent */
import styled from "styled-components";

const Wrapper = styled.div<{
  width;
  height;
  transparent: any;
  hasError?: boolean;
  color: any;
}>`
  margin-bottom: 15px;

  .dropdown {
    span {
      background-color: transparent;
      white-space: nowrap;
      padding-right: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .btn {
      background-color: ${(props) =>
        props.transparent ? "transparent" : "white"};
      border: 1px solid
        ${(props) =>
          props.hasError
            ? props.theme.colors.red100
            : props.color
            ? props.theme.colors[props.color]
            : "#ced4da"};
      border-radius: 8px;
      width: ${(props) => (props.width ? props.width : "fit-content")};
      text-align: left;
      display: flex;
      justify-content: space-between;
      height: ${(props) => props.height || "46px"};
      align-items: center;
      color: ${(props) => (props.transparent ? "white" : "black")};
      font-size: 14px;
      outline: none;
      box-shadow: none;
    }
  }

  .dropdown-menu {
    margin-top: 4px;
    width: ${(props) => (props.width ? props.width : "fit-content")};
    background-color: ${(props) => props.theme.colors.bgColorLayer2};
    border: 1px solid
      ${(props) => (props.color ? props.theme.colors[props.color] : "#ced4da")};
    overflow: auto;
    max-height: 400px;

    .dropdown-item {
      &:hover {
        background-color: #ced4da;
        color: ${(props) => props.theme.colors[props.color] || "#172D4D"};
      }
    }

    .dropdown-item,
    .dropdown-item-text {
      color: ${(props) =>
        props.transparent
          ? "white"
          : `${props.theme.colors[props.color] || "black"}`};
    }
  }
`;

export const Scroll = styled.div``;

export const DropdownCarret = styled.div``;

export const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
`;

export const CloseIcon = styled.div`
  position: absolute;
  right: 32px;
  z-index: 50;
`;

export default Wrapper;
