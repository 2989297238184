import Marquee from "react-fast-marquee";
import Market from "layouts/Markets/index";
import { Coins } from "pages/home/styles";
import { getCryptos } from "redux/reducers/cryptos/cryptos.selector";
import { useAppSelector } from "redux/reducers/hook";
import { isMobile } from "react-device-detect";

export default function View() {
  const token = useAppSelector(getCryptos);

  const data = (token || []).filter((item) => item.is_advertisement);

  return (
    <Marquee speed={isMobile ? 25 : 50} gradient={false}>
      <Coins>
        <>
          {/* <Market token="btc" />
          <Market token="eth" />
          <Market token="bnb" /> */}
          {data.map((item) => (
            <Market token={item.symbol} />
          ))}
        </>
      </Coins>
    </Marquee>
  );
}
