import styled from "styled-components";

export const Container = styled.div`
  margin-top: 40px;
  background: ${(props) => props.theme.colors["black100"]};
  padding: 20px;
  border-radius: 8px;
  img {
    cursor: pointer;
  }

  @media screen and (max-width: 512px) {
    padding: 5px;
  }
`;

export const WrapperProduct = styled.div`
  padding: 0 10px;
`;

export const Header = styled.div`
  margin-bottom: 20px;
`;

export const Item = styled.div`
  /* margin: 5px 0; */
`;

export const WraperImg = styled.div`
  /* height: 160px; */
  height: 200px;
  overflow: hidden;
  background: ${(props) => props.theme.colors.white100};
  width: 100%;
  @media screen and (max-width: 1440px) {
    height: 150px;
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    &:hover {
      transform: scale(1.1);
      transition-duration: 0.5s;
    }
  }
`;

export const Detail = styled.div`
  padding: 5px;
  background: ${(props) => props.theme.colors["grey200"]};
  border-radius: 0 0 8px 8px;
`;

export const Element = styled.div`
  display: flex;
  align-items: center;
  p {
    width: 80%;
    height: 16px;
    overflow: hidden;
    font-size: 15px;
    line-height: 19px;
    font-weight: 700;
    margin: 0;
  }
  div {
    width: 20%;
    text-align: right;
    img {
      width: 25px;
    }
  }
`;

export const Title = styled.div``;

export const SubTitle = styled.div`
  font-weight: 300;
  font-size: 11px;
  height: 12px;
  width: 100%;
  margin-top: 3px;
  overflow: hidden;
`;

export const Cost = styled.div`
  margin-top: 10px;
  padding-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  span:first-child {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #00ac11;
  }
  p {
    font-size: 14px;
    line-height: 16px;
    margin: 0;
  }
  span:last-child {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: ${(props) => props.theme.colors["orange100"]};
  }
`;

export const Price = styled.p`
  font-weight: 600;
`;

export const Download = styled.div`
  width: 50%;
  margin: auto;
`;

export const Check = styled.div`
  text-align: center;
`;

export const SeletedCon = styled.div`
  margin-bottom: 10px;
  div {
    color: black;
  }
`;

export const HistoryShop = styled.div`
  padding: 0 10px;
  overflow-x: auto;
`;

export const Left = styled.div`
  /* width: 40%; */
  margin-right: 10px;
  text-align: center;
  img {
    width: 150px;
    margin-bottom: 10px;
  }
`;

export const Right = styled.div`
  /* width: 58%; */
  margin-left: 30px;
  h4 {
    font-size: 15px;
    display: inline-block;
  }
`;

export const PriceCart = styled.div`
  span {
    font-weight: bold;
  }
`;

export const TitleLabel = styled.div`
  font-size: 18px;
`;

export const ContainImage = styled.div`
  img {
    width: 100px;
  }
`;

export const Amount = styled.div``;

export const LabelAmount = styled.div``;

export const TotalArea = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
  h4 {
    display: inline;
    font-size: 18px;
  }
`;

export const Buy = styled.div`
  /* position: absolute; */
  /* right: 50px; */
  text-align: end;
`;

export const ContainModal = styled.div`
  display: flex;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;
  min-height: 250px;
  overflow: auto;
  button {
    padding: 5px 20px;
  }
`;

export const Action = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  div {
    width: 102px;
    margin: 0 2px;
  }
`;

export const CodeWrapper = styled.div`
  text-align: center;
  padding: 10px;
  background-image: url("/assets/images/gif/crypto.gif");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  .code {
    p {
      color: ${(props) => props.theme.colors.yellow100};
      margin: 3px 0;
      font-weight: 700;
      font-size: 20px;
    }
  }
`;

export const ImageList = styled.div`
  max-width: 500px;
  padding: 20px;
`;

export const ImageListItem = styled.div`
  display: flex;
  margin: 5px;
  img {
    width: 70px;
  }
`;

export const ProductWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ItemWrapper = styled.div`
  width: 170px;
  margin: 5px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors["grey200"]};
  @media screen and (max-width: 425px) {
    width: 150px;
  } 
  @media screen and (max-width: 382px) {
    width: 140px;
  }
  @media screen and (max-width: 362px) {
    width: 121px;
  }
`;

export const RewardWrapper = styled.div`
  cursor: pointer;
  svg {
    color: deeppink;
  }
  display: flex;
  justify-content: end;
  background: ${(props) => props.theme.colors.white100};
  padding-right: 4px;
`;
