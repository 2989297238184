import styled from "styled-components";
import { isMobile } from "react-device-detect";

const Wrapper = styled.div`
  position: relative;
  margin-top: 108px;
  width: 100vw;
  height: 100%;
  min-height: calc(calc(var(--vh, 1vh) * 100) - 150px);
  overflow: ${ isMobile ? "auto"  : ""};
  background-color: ${props => props.theme.colors.bgColorLayer1};
  color: ${props => props.theme.colors.orange100};

  @media screen and ( max-width: 1100px ) {
    margin-top: 86px;
    height: calc(calc(var(--vh, 1vh) * 100) - ${isMobile ? "142px" : "56px"});
  }
`;

export const Container = styled.div`
  position: relative;
  padding: 30px 15px;
  min-height: 750px;
  display: flex;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 900px;
  margin: auto;
  opacity: 0.8;
  padding: 30px 20px;
  background-color: #0c0d0d;
  border-radius: 10px;
  position: relative;
`;


export const InfoUserForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  input:last-child {
    width: 100%;
    padding: 0 10px;
    display: block;
  }
`;

export const Form = styled.form`
  max-width: 600px;
  width: 100%;

  @media screen and (max-width: 456px) {
    width: 100%;
    padding: 0px;
  }

  .form-check {
    display: flex;
    justify-content: space-evenly;
  }
`;

export const FormTitle = styled.h1`
  font-size: ${isMobile ? "30px" : "42px"};
  margin-bottom: 30px;
  text-align: center;
`;

export const FormDescription = styled.p`
  font-size: ${isMobile ? "14px" : "16px"};
  text-align: center;
`;

export const FormItem = styled.div`
  margin: 12px 0;

  > button {
    width: 100%;
    max-width: 300px;
    font-size: 16px;
    font-weight: 700;
  }
`;

export const Document = styled.div`
`;

export const Detail = styled.div`
  padding-left: 30px;
`;

export const OptionRole = styled.div`
  position: relative  ;
  display: flex;
  margin-top: 60px;
  justify-content: center;
  flex-wrap: wrap;
`;

export const WithRole = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4px;
  box-shadow: 0 1px 1px 1px none;
  cursor: pointer;
  width: 30%;
  max-width: ${isMobile ? "75px" : "100px"};
  height: auto;
  margin: 0 30px;

  img {
    height: 100%;
    max-height: ${isMobile ? "75px" : "90px"};
    width: auto;
  }

  span {
    color: ${props => props.theme.colors.orange100};
    text-align: center;
    font-weight: bold;
    padding: 10px 0;
    font-size: ${isMobile ? "12px" : "16px"};
  }

  img {
    border-radius: 4px;
  }

  &:hover {
    transform: scale(1.1);
  }

  @media screen and (max-width: 540px ) {
    margin-bottom: 40px;
  }
`;
export const Submit = styled.div`
  text-align: center;
  button {
    width: 100%;
    max-width: 300px;
    font-size: 16px;
    font-weight: 700;
  }
`;

export const Upload = styled.div`
  margin-left: 100px;
`;

export const CreateSuccess = styled.div`
  padding: 40px 20px;
  h3 {
    margin-bottom: 30px;
  }
`;

export const Back = styled.div`
  position: absolute;
  top: 10px;
  left: 13px;

  &:hover {
    cursor: pointer;
  }
`;

export const TitleProjectCategory = styled.div`
  span {
    cursor: pointer;
    border: 0.5px solid grey;
    border-radius: 4px;
    padding: 3px 5px;

    &:hover {
      background-color: ${props => props.theme.colors.orange100};
      opacity: 0.8;
    }
  }
`;

export const BackgroundVideo = styled.div`
  position: fixed;
  top: 0; 
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;

  .disable-event {
    pointer-events: none;
    transform: scale(1.15);
  }
`;

export const BgFooter = styled.div`
  position: relative;
  background-color: ${props => props.theme.colors.bgColorLayer1};
  color: white;
  padding-bottom: 30px;
`;

export default Wrapper;