import styled, { css } from "styled-components";

export const Container = styled.div<{
  page?: string;
  isEdit?;
  isBannerAds?: boolean;
  hasWallpaper?: boolean;
  hasWallpaperLinkClick?: boolean;
}>`
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;

  ${(props) =>
    props.hasWallpaperLinkClick
      ? css`
          cursor: pointer;
        `
      : ""}

  ${(props) =>
    props.hasWallpaper
      ? css`
          height: 80vh;
          align-items: center;
        `
      : ""}

  img {
    width: 100%;
    height: auto;
  }

  ._advertisement {
    width: ${(props) =>
    props.page === "landing_page" && !props.isBannerAds && !props.isEdit
      ? "0px"
      : "22%"};
    position: relative;
    max-height: 35vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    ._item_ads {
      width: 100%;
      height: 50%;
      position: relative;

      :nth-child(1) {
        padding-bottom: 5px;
      }

      :nth-child(2) {
        border-top: 3px solid orange;
        padding-top: 5px;
      }
      ._banner_container {
        min-height: 100% !important;
        max-width: 100% !important;
      }

      ._item_ {
        height: calc(100% - 30px);
      }

      input {
        height: 30px;
        border-radius: 4px;
      }
    }

    ._add_remove_banner_ads {
      margin-top: 20px;
      text-align: center;
      button {
        height: 30px;
        padding: 3px 20px;
        border-radius: 4px;
      }
    }

    @media screen and (max-width: 875px) {
      display: none;
    }
  }

  ._banner_container {
    overflow: hidden;
    background-color: unset !important;

    @media screen and (min-width: 876px) {
      max-width: ${(props) =>
    props.page === "landing_page" && !props.isBannerAds && !props.isEdit
      ? "100%"
      : "66%"};
      min-height: ${(props) =>
    props.page === "landing_page" && !props.isBannerAds && !props.isEdit
      ? ""
      : "35vw !important"}
        ${(props) => props.hasWallpaper
    ? css`
                width: 100% !important;
                max-width: 650px !important;
                height: 50vh !important;
                max-height: 400px !important;
                min-height: unset !important;
                margin-top: 25px !important;
              `
    : ""}
    }
  }
`;
