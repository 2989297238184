/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { formatCurrencyByType } from "utils/common";
import { Coin } from "layouts/UserHeader/styles";
import { useAppSelector } from "redux/reducers/hook";
import {
  getCryptos,
  getStocksPrice,
} from "redux/reducers/cryptos/cryptos.selector";
import { getRateUSDToVND } from "redux/reducers/settings/settings.selector";

type IMarket = {
  token: string;
};

const Matket = ({ token }: IMarket) => {
  const [market, setMarket] = React.useState({
    logo: null,
    isStock: false,
  });
  const stocks = useAppSelector(getCryptos);
  const prices = useAppSelector(getStocksPrice);
  const rate = useAppSelector(getRateUSDToVND);

  useEffect(() => {
    const wss = new WebSocket(
      `wss://stream.binance.com:9443/ws/${token}usdt@trade`
    );
    wss.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setMarket(data); 
    };
    return () => {
      wss.close();
    };
  }, []);

  useEffect(() => {
    if (stocks && prices) {
      const stock = stocks.find((a) => a.symbol === token);
      if (stock)
        setMarket({
          ...stock,
          p: prices[token] ? prices[token]["vnd"] : `N/A`,
          isStock: true,
        });
    }
  }, [stocks, token, prices]);

  return (
    <Coin>
      <img
        src={market.logo || `/assets/images/coins/${token}.png`}
        alt={token}
      />
      <span>
        {token.toUpperCase()}:{" "}
        {market["p"] ? (
          !market.isStock ? (
            formatCurrencyByType(+market["p"])
          ) : (
            <span>
              {formatCurrencyByType(+market["p"], "vi-VN", "VND")}{" "}
              <span>({formatCurrencyByType(+market["p"] / +rate)})</span>
            </span>
          )
        ) : (
          "N/A"
        )}
      </span>
    </Coin>
  );
};

export default Matket;
