/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { toast } from "react-toastify";

import { useAppSelector } from "redux/reducers/hook";
import { BankList, BankItem } from "./styles";

import Button from "components/button";
import {
  Amount,
  Content,
  Continue,
  Detail,
  DetailRightView,
  Title,
  Warning,
} from "../../styles";
import Input from "components/input";
import History from "../history-vnd";
import { formatNumber, randString } from "utils/common";

import APIs from "api";
import BankAccountForm from "./components/BankAccountForm";
import { useSelector } from "react-redux";
import { getMyBanks } from "redux/reducers/banks/banks.selector";
import {
  getBalances,
  getUserProfile,
} from "redux/reducers/users/users.selector";
import Verify from "../verify";
import { Step0Wrapper } from "../deposit-vnd/styles";
import { getRateUSDTToVND } from "utils/currency";
import Network from "../networks";
import { getCryptos } from "redux/reducers/cryptos/cryptos.selector";

const View = (props) => {
  const balances = useAppSelector(getBalances);
  const user = useAppSelector(getUserProfile);
  const cryptos = useAppSelector(getCryptos);

  const stockVnex = cryptos.find((el) => el.symbol === "vnex");

  const [isOpenBankAccountModal, setOpenBankAccountModal] = useState(false);
  const [step, setStep] = useState(0); // 1 : choose bank ,2 : amount,3 : verify
  const [selectedBank, setSelectBank] = useState(null);
  const [amount, setAmount] = useState<number>(null);
  const [hisDeposit, setHisDeposit] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errMessage, setErrMessage] = useState<string>("");
  const [currency, setCurrency] = useState(null);
  const [address, setAddress] = useState(null);
  const [network, setNetwork] = useState("bsc");
  const [verify, setVerify] = useState({
    email_code: "",
    google_code: "",
  });
  const [rate, setRate] = useState();

  const myBanks = useSelector(getMyBanks);

  const handleChange = (e) => {
    if (+e < 0) {
      setAmount(0);
    } else if (+e === 0) {
      setAmount(null);
    } else {
      setAmount(+e);
    }
  };

  const handleClickButtonTop = async () => {
    setErrMessage("");
    if (step !== 3) {
      if (step === 0 && currency !== "vnd") {
        setStep(step + 2);
      } else setStep(step + 1);
    } else {
      try {
        if (amount > 0) {
          setLoading(true);
          await APIs.PAYMENT.withdraw({
            body: {
              verify,
              amount: amount,
              bank: selectedBank,
              content: randString(false, 10),
              currency,
              address,
              network,
              stock: "vnex",
            },
          });
          getPayment();
          toast.success("Withdraw successful!");
          setStep(0);
          setLoading(false);
        } else {
          setErrMessage("Please enter amount");
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        toast.error(error?.errors);
      }
    }
  };

  const addBank = () => {
    setOpenBankAccountModal(true);
  };

  const backStep = () => {
    if (step === 2 && currency !== "vnd") setStep(step - 2);
    else setStep(step - 1);
  };

  const getPayment = async () => {
    try {
      const res: any = await APIs.PAYMENT.getPaymentByUserId({
        userId: user._id,
        type: "withdraw",
        stock: "vnex",
      });
      if (res.payments?.length > 0) {
        setHisDeposit(res.payments);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getRate = async () => {
    const rate = await getRateUSDTToVND();
    setRate(rate);
  };

  useEffect(() => {
    props.getMyBanks();
    getPayment();
    getRate();
  }, []);

  const currencies = [
    {
      name: "vnd",
      icon: "/assets/images/coins/vnd.png",
    },
    // {
    //   name: "usdt",
    //   icon: "/assets/images/coins/usdt.png",
    // },
  ];

  const handleCurrency = (name) => {
    setCurrency(name);
  };

  

  return (
    <>
      <DetailRightView>
        <Title bgColor={"grey200"} just="space-between">
          <p>Withdraw VNEX</p>
        </Title>
        {step === 0 && (
          <Step0Wrapper>
            {currencies.map((c) => (
              <Button
                onClick={() => {
                  handleCurrency(c.name);
                  setNetwork(c.name === "vnd" ? "bank" : "bsc");
                }}
                className={`btn-currency ${currency === c.name && "active"}`}
              >
                <img src={c.icon} />
                {c.name.toUpperCase()}
              </Button>
            ))}
          </Step0Wrapper>
        )}
        {step === 1 && (
          <Content>
            <BankList>
              {myBanks.map((b) => {
                return (
                  <BankItem
                    key={b._id}
                    selected={String(b._id) === String(selectedBank?._id)}
                    onClick={setSelectBank.bind(null, b)}
                  >
                    <Row lg={12} key={b._id}>
                      <Col lg={6}>
                        <Detail>
                          <h5>{b.bank_name}</h5>
                          <p>{b.account_name}</p>
                          <p>{b.account_number}</p>
                        </Detail>
                      </Col>
                      <Col lg={6}>
                        <Amount>
                          <img src="/assets/images/icons/del.svg" alt="del" />
                        </Amount>
                      </Col>
                    </Row>
                  </BankItem>
                );
              })}
            </BankList>
          </Content>
        )}
        {step === 2 && (
          <Content>
            {currency !== "vnd" && (
              <>
                <Network network={network} setNetwork={setNetwork} />
                <Input
                  transparent={true}
                  label="Address"
                  placeholder="0x9f0F7601b9143ACc36aE838800153805698e8f08"
                  onChange={(e) => setAddress(e.target.value)}
                />
              </>
            )}
            <Input
              isCurrency={true}
              transparent={true}
              label={`Amount (${currency.toUpperCase()})*`}
              placeholder="10,000"
              value={amount}
              hasError={errMessage ? true : false}
              errMessage={errMessage}
              suffixIcon={
                <Button
                  className={"action"}
                  onClick={() =>
                    setAmount(
                      (+balances["vnex"] * 1000) /
                        (currency === "usdt" ? rate : 1)
                    )
                  }
                >
                  MAX
                </Button>
              }
              onValueChange={(e) => handleChange(e)}
            />
            {currency === "vnd" && (
              <>
                <Warning>
                  <img src="/assets/images/icons/warning.svg" alt="warning" />
                  <p>
                    VAT:{" "}
                    <span>
                      {formatNumber((amount * (stockVnex?.VAT || 0)) / 100)}{" "}
                      {String(currency).toUpperCase()}
                    </span>
                  </p>
                </Warning>
                <Warning>
                  <img src="/assets/images/icons/warning.svg" alt="warning" />
                  <p>
                    Amount Received:{" "}
                    <span>
                      {formatNumber(
                        amount - (amount * (stockVnex?.VAT || 0)) / 100
                      )}{" "}
                      {String(currency).toUpperCase()}
                    </span>
                  </p>
                </Warning>
              </>
            )}
            <Warning>
              <img src="/assets/images/icons/warning.svg" alt="warning" />
              <p>
                Maximum {String(currency).toUpperCase()} Withdrawal:{" "}
                <span>
                  {formatNumber(
                    (+balances["vnex"] * 1000) /
                      (currency === "usdt" ? rate : 1)
                  )}{" "}
                  {String(currency).toUpperCase()}
                </span>
              </p>
            </Warning>
            <Warning>
              <img src="/assets/images/icons/warning.svg" alt="warning" />
              <p>
                {String(currency).toUpperCase()} deposit is free. Vzonex does
                not collect {String(currency).toUpperCase()} deposit fee,
                however, in some instances, you may have to bear a small
                transaction fee charged directly by the processing bank or
                network.
              </p>
            </Warning>
          </Content>
        )}
        {step === 3 && <Verify verify={verify} setVerify={setVerify} />}
      </DetailRightView>
      <Continue>
        {step === 1 && (
          <Button transparent={true} height="40px" onClick={() => addBank()}>
            + Add new account
          </Button>
        )}

        <Button
          loading={loading}
          height="40px"
          onClick={handleClickButtonTop}
          disabled={!currency || (step === 1 && !selectedBank)}
        >
          {step === 3 ? "Confirm" : "Continue"}
        </Button>
        {step !== 0 && (
          <Button transparent={true} height="40px" onClick={() => backStep()}>
            Back
          </Button>
        )}
      </Continue>
      {hisDeposit.length ? (
        <History lists={hisDeposit} type="withdraw" stock="vnex" />
      ) : (
        <></>
      )}
      <BankAccountForm
        isOpen={isOpenBankAccountModal}
        setIsOpen={setOpenBankAccountModal}
      />
    </>
  );
};

export default View;
