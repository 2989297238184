import Input from "components/input";
import { Controller } from "react-hook-form";

import { FormItem } from "../../styles";

const Step2 = (props) => {
  const { control, errors, query } = props;

  return (
    <>
      <FormItem>
        <Controller
          name="fullName"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Input
              hasError={Boolean(errors.fullName)}
              label="Full name"
              placeholder="Full name"
              {...field}
            />
          )}
        />
      </FormItem>
      <FormItem>
        <Controller
          name="refer_code"
          control={control}
          rules={{ required: false }}
          defaultValue={query.refer_code}
          render={({ field }) => (
            <Input
              hasError={Boolean(errors.refer_code)}
              label="Referral ID (Optional)"
              placeholder="Referral ID"
              {...field}
            />
          )}
        />
      </FormItem>
    </>
  );
};

export default Step2;
