import { Continue } from "../../styles";
import Button from "components/button";
import { Step0Wrapper } from "./styles";

const Step2 = ({
  setDataByKey,
  nextStep,
  depositBanks = [],
  dataPayment,
  backStep,
}) => {
  const onclickBank = (b) => {
    setDataByKey("bank", b);
  };

  return (
    <Step0Wrapper>
      <Continue>
        {depositBanks
          .filter((b) => dataPayment.network === b.network)
          .map((b) => {
            return (
              <Button
                className={`btn-currency ${
                  dataPayment.bank &&
                  dataPayment.bank.account_name === b.account_name
                    ? "active"
                    : ""
                }`}
                key={b.name}
                height="40px"
                color="green100"
                onClick={onclickBank.bind(null, b)}
              >
                Transfer to <b>{b.account_name}</b> ({b.bank_name})
              </Button>
            );
          })}
        <br />
        <Button onClick={() => nextStep()} disabled={!dataPayment.bank}>
          Continue
        </Button>
        <Button transparent={true} onClick={() => backStep()}>
          Back
        </Button>
      </Continue>
    </Step0Wrapper>
  );
};

export default Step2;
