import {
  Container,
  Flex,
  Logo,
  Menu,
  HeaderEnd,
  Item,
  Text,
  MenuIcon,
  TopMain,
  OptionMenu,
  Upload,
} from "./styles";
import Button from "components/button";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import TopHeader from "layouts/components/top-header";

import UploadFile from "components/uploadFile";
import {
  AiOutlineHome,
  AiOutlineTeam,
  AiOutlineFundProjectionScreen,
  AiOutlineCloseSquare,
} from "react-icons/ai";
import { RiRoadMapLine } from "react-icons/ri";
import { FaInfo, FaPeopleArrows, FaRegNewspaper } from "react-icons/fa";
import { MdOutlineGeneratingTokens } from "react-icons/md";
import { CloseSideBar } from "layouts/UserSidebar/styles";
import { useAppDispatch } from "redux/reducers/hook";
import { useSelector } from "react-redux";
import {
  getTopMenuAsync,
  getWebConfigAsync,
} from "redux/reducers/settings/settings.action";
import { getTopMenu } from "redux/reducers/settings/settings.selector";

const View = (props: any) => {
  const { bgColorHeader, role, isEdit, urlLogo, listFiles, setFile, main } =
    props;
  const prams: any = useParams();
  const dispatch = useAppDispatch();
  const menu: any = useSelector(getTopMenu);

  const listMenuDefault = [
    {
      icon: <AiOutlineHome />,
      title: "Home",
      path: "home",
    },
    {
      icon: <FaInfo size={13} />,
      title: "About",
      path: "about",
    },
    {
      icon: <AiOutlineFundProjectionScreen />,
      title: "Projects",
      path: "projects",
    },
    {
      icon: <RiRoadMapLine />,
      title: "Roadmap",
      path: "roadmap",
    },
    {
      icon: <MdOutlineGeneratingTokens />,
      title: main ? "NFTs" : "Tokenomic",
      path: main ? "nfts" : "tokenomic",
    },
    {
      icon: <FaPeopleArrows />,
      title: "Partner",
      path: "partner",
    },
    {
      icon: <AiOutlineTeam />,
      title: "Team",
      path: "team",
    },
    {
      icon: <FaRegNewspaper />,
      title: "Whitepaper",
      path: `whitepaper`,
    },
  ];

  const [listMenu, setListMenu] = useState<any>(listMenuDefault);

  const history = useHistory();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const goToApp = (path: string) => {
    try {
      if (main) {
        history.push(path);
      } else {
        if (path === "/auth/login" || path === "/auth/register") {
          history.push(path);
        } else if (path === "whitepaper") {
          history.push(`/${role}/${prams.symbol}/whitepaper`);
        } else {
          !isEdit ? history.push(`/${role}/${prams.symbol}`) : console.log("");
        }
      }
      setIsOpen(false);
      document.getElementById(path).scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    } catch (error) {
      setIsOpen(false);
    }
  };

  const handleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (main && menu.length > 0) {
      const menuEnable = menu.filter((el: any) => el.enable);
      if (menuEnable.length > 0) {
        setListMenu(menuEnable);
      } else {
        setListMenu(listMenuDefault);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu, main]);

  useEffect(() => {
    dispatch(getTopMenuAsync());
    dispatch(getWebConfigAsync());
    if (!main) {
      setListMenu((prev) => prev.filter((el: any) => el.title !== "Projects"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TopMain>
        <TopHeader />
      </TopMain>
      <Container bgColorHeader={bgColorHeader}>
        <Flex>
          <Logo onClick={() => goToApp("/")}>
            {isEdit && (
              <Upload exception={Boolean(!listFiles && urlLogo)}>
                <UploadFile
                  id="logo"
                  ratio="227x62"
                  file={listFiles}
                  setFile={setFile}
                />
              </Upload>
            )}
            {main ? (
              <img src={"/assets/images/logo.png"} alt="logo" />
            ) : (
              <img hidden={listFiles || !urlLogo} src={urlLogo} alt="logo" />
            )}
          </Logo>
          <Menu>
            {listMenu.map((item: any, index: number) => (
              <Item
                key={index}
                height={"20px"}
                onClick={() => goToApp(item.link || item.path)}
              >
                {item.id ? <img src={item.icon} alt="" /> : item.icon}
                <Text>{item.title}</Text>
              </Item>
            ))}
          </Menu>
          <HeaderEnd>
            <Button
              onClick={() => goToApp("/auth/login")}
              height="36px"
              transparent={true}
            >
              Login
            </Button>
            <Button
              onClick={() => goToApp("/auth/register")}
              height="36px"
              color="orange100"
            >
              Register
            </Button>
          </HeaderEnd>
        </Flex>
        <MenuIcon onClick={handleMenu}>
          <img src="/assets/images/menu/1.svg" alt="" />
        </MenuIcon>
      </Container>
      {isOpen && (
        <OptionMenu>
          <CloseSideBar onClick={() => setIsOpen(false)}>
            <AiOutlineCloseSquare size={30} />
          </CloseSideBar>
          <Item>
            <Button
              onClick={() => goToApp("/auth/login")}
              height="36px"
              transparent={true}
            >
              Login
            </Button>
            <Button
              onClick={() => goToApp("/auth/register")}
              height="36px"
              color="orange100"
            >
              Register
            </Button>
          </Item>
          {listMenu.map((item: any, index: number) => (
            <Item
              key={index}
              height={"40px"}
              onClick={() => goToApp(item.link || item.path)}
            >
              {item.id ? <img src={item.icon} alt="" /> : item.icon}
              &ensp;<Text>{item.title}</Text>
            </Item>
          ))}
        </OptionMenu>
      )}
    </>
  );
};

export default View;
