import { ROUTES } from "config/routes";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getUser } from "redux/reducers/users/users.reducer";
import Loading from "components/loading";
import {
  getCryptosAsync,
  getStocksPriceAsync,
} from "redux/reducers/cryptos/cryptos.actions";
import qs from "query-string";
import Mess from "components/messenger";

const withAuth = (Component: any) => {
  const AuthenticatedComponent: React.FC = (props) => {
    const location = useLocation();
    const query = qs.parse(location.search);
    const dispatch = useDispatch();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      async function getMe() {
        try {
          setIsLoading(true);
          const user: any = await dispatch(getUser());
          setIsLoading(false);
          if (user.payload) {
            dispatch(getCryptosAsync());
            dispatch(getStocksPriceAsync());
            if (query.callback)
              window.location.replace(
                `${query.callback}?token=${localStorage.getItem(
                  "auth"
                )}&&provider=vzonex`
              );
            return;
          } else {
            localStorage.removeItem("auth");
            history.push(ROUTES.LOGIN);
          }
          setIsLoading(false);
        } catch (error) {
          history.push(ROUTES.LOGIN);
          localStorage.removeItem("auth");
          setIsLoading(false);
        }
      }
      getMe();
    }, [dispatch, history]);

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <Component {...props} />

        <Mess />
      </div>
    );
  };

  return AuthenticatedComponent;
};

export const withoutAuth = (Component: any) => {
  return function HOC(props: any) {
    // const auth = useSelector(getAuth)
    // return auth === 'GUEST' ? <Component {...props} /> : <Redirect to="/" />
  };
};

export default withAuth;
