import Client from "./Client";
import { Wrapper } from "./styles";
import System from "./System";

const View = () => {
  return (
    <Wrapper>
      <System />
      <Client />
    </Wrapper>
  );
};

export default View;
