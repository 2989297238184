/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import Container, {
  Wrapper,
  TextColor,
  ActionTable,
  CreateProject,
} from "./styles";

import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Table from "components/table";
import APIs from "api";
import { useAppSelector } from "redux/reducers/hook";
import { getUserProfile } from "redux/reducers/users/users.selector";
import Button from "components/button";
import Modal from "components/modal";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { formatCurrency } from "utils/common";
import Notification from "./components/notifications";
import Tabs from "components/tabs";
import { AiFillNotification, AiFillProject } from "react-icons/ai";
import { RiAdvertisementLine } from "react-icons/ri";

import FormProjectICO from "./components/form-project-ICO";
import Input from "components/input";
import Advertisement from "./components/advertisement";
import RequestLandingPage from "./components/request-landing-page";

import FromAddIDManagers from "components/add-page-managers";
import { ContainerRegisterItem } from "pages/events/styles";
import Switch from "react-switch";
import Dropdown from "components/dropdown";

const defaultState = {
  name: "",
  symbol: "",
  supply: 0,
  logo: "",
  amountToSale: 0,
  amountSold: 0,
  tabs: [],
  social: [],
  ido: {},
  keep_time: {},
  content: "",
  description: "",
  banner: "",
  contract: "",
  contract_ido: "",
  url: "",
  type: "no_hold",
  status: "init",
  sale_time: 0,
  stage: "",
  stock_price: 0,
  stock_to_buy: "",
  user_id: "",
  share_profit: "",
  facebookPageId: "",
  is_holding_balance: true,
  type_sell: "",
  max_per_user: 1,
};

const optionManagersSection = {
  header: {
    title: "Header",
  },
  fundamentals: {
    title: "Fundamentals",
  },
  roadmap: {
    title: "Roadmap",
  },
  doc: {
    title: "Documents",
  },
  certificate: {
    title: "Project Certificate",
  },
  partner: {
    title: "Our Partners",
  },
  dataTeam: {
    title: "Our Team",
  },
  video: {
    title: "Videos",
  },
  review: {
    title: "Review",
  },
  footer: {
    title: "Footer",
  },
  project: {
    title: "Project",
  },
  round: {
    title: "Rounds",
  },
  profit: {
    title: "Profit",
  },
};

const statusProjects = [
  {
    label: "Init",
    value: "init",
  },
  {
    label: "Upcoming",
    value: "upcoming",
  },
  {
    label: "Opening",
    value: "opening",
  },
  {
    label: "Closed",
    value: "closed",
  },
];

const View = () => {
  const profile: any = useAppSelector(getUserProfile);
  const history = useHistory();

  // //managersSection
  // const [managersSections, setManagersSections] = useState<any>([])

  const checkManagersSection = (data: any, section: string) => {
    const managersSections = data.eStockManagersWithSections?.[profile._id];
    if (
      !(
        managersSections?.includes("all") || managersSections?.includes(section)
      ) &&
      !(String(data.userId) === String(profile._id) || profile.role === "ADMIN")
    ) {
      return true;
    } else {
      return false;
    }
  };

  const columnsMyProjects = [
    {
      title: "Logo",
      dataIndex: "logo",
      key: "logo",
      render: (data: any) => (
        <img src={data?.projectId?.logo} alt="logoToken" />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (data: any) => <span>{data?.projectId?.name}</span>,
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
      render: (data: any) => (
        <span>{data?.projectId?.symbol.toUpperCase()}</span>
      ),
    },
    {
      title: "Total supply",
      dataIndex: "supply",
      key: "supply",
      render: (data: any) => (
        <span>{formatCurrency(data?.projectId?.supply)}</span>
      ),
    },
    {
      title: "Status Project",
      dataIndex: "status_projects",
      key: "status_projects",
      render: (data: any) => (
        <TextColor
          color={
            data?.projectId?.status === "opening"
              ? "green100"
              : data?.projectId?.status === "upcoming"
              ? "yellow100"
              : data?.projectId?.status === "closed"
              ? "red100"
              : "white100"
          }
        >
          {data?.projectId?.status}
        </TextColor>
      ),
    },
    {
      key: "on_off",
      title: "On/Off Project",
      dataIndex: "on_off",
      render: (data: any) => (
        <Dropdown
          width={"100%"}
          color="red"
          transparent={true}
          options={statusProjects}
          defaultValue={data?.projectId?.status || "init"}
          onChange={(e) => onChangeStatusProject(e, data?.projectId?._id)}
        />
      ),
    },
    {
      title: "Status Landing Page",
      dataIndex: "status_page",
      key: "status_page",
      render: (data: any) => (
        <TextColor
          color={
            data?.status === "approved"
              ? "green100"
              : data?.status === "pending"
              ? "yellow100"
              : data?.status === "declined"
              ? "red100"
              : "white100"
          }
        >
          {data?.status}
        </TextColor>
      ),
    },
    {
      key: "on_off",
      title: "On/Off Landing Page",
      dataIndex: "on_off",
      render: (item: any) => (
        <Switch
          onChange={(e) => handleChangeStatusByTrustUser(e, item._id)}
          checked={item.status === "approved"}
        />
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data: any) => (
        <span>
          {new Date(data?.projectId?.createdAt).toLocaleString("en-GB")}
        </span>
      ),
    },
    {
      title: "Action Project",
      dataIndex: "action_project",
      key: "action_project",
      render: (data: any) => (
        <ActionTable>
          <Button
            height="30px"
            disabled={checkManagersSection(data, "profit")}
            onClick={() => handleAction(data?.projectId, "profit")}
          >
            Profit
          </Button>
          <Button
            height="30px"
            onClick={() => handleAction(data?.projectId, "view")}
          >
            View
          </Button>
          <Button
            height="30px"
            disabled={checkManagersSection(data, "round")}
            onClick={() => handleAction(data?.projectId, "round")}
          >
            Rounds
          </Button>
          <Button
            height="30px"
            disabled={
              (!profile.permissions?.["projects.trust"] &&
                data?.projectId?.status === "opening") ||
              checkManagersSection(data, "projects")
            }
            onClick={() => handleAction(data?.projectId, "edit")}
          >
            Edit
          </Button>
        </ActionTable>
      ),
    },
    {
      title: "Action LandingPage",
      dataIndex: "action_landingpage",
      key: "action_landingpage",
      render: (data: any) => (
        <ActionTable>
          <Button
            height="30px"
            onClick={() => handleAction(data, "view-landingpage")}
          >
            View
          </Button>
          <Button
            height="30px"
            disabled={
              !profile.permissions?.["projects.trust"] &&
              data?.status === "approved"
            }
            onClick={() => handleAction(data, "edit-landingpage", true)}
          >
            Edit
          </Button>
          <Button
            height="30px"
            onClick={() => handleAction(data?.projectId, "editFacebookPageId")}
          >
            FB Page ID
          </Button>
          <Button
            height="30px"
            onClick={() => handleAction(data, "view-request")}
          >
            View request
          </Button>
        </ActionTable>
      ),
    },
    {
      title: "Request Pre-mainet",
      dataIndex: "request_pre_mainet",
      key: "request_pre_mainet",
      render: (data: any) => (
        <>
          {data?.projectId?.requestMainet?.status !== "pre-mainet" ? (
            <Button
              height="30px"
              onClick={() => handleAction(data?.projectId, "pre-mainet")}
            >
              Request
            </Button>
          ) : (
            <>
              {data?.projectId?.requestMainet?.status} <br />
              {data?.projectId?.requestMainet?.enable && (
                <>
                  (
                  <TextColor
                    color={
                      data?.projectId?.requestMainet?.enable === "enable"
                        ? "green100"
                        : "white100"
                    }
                  >
                    {data?.projectId?.requestMainet?.enable}
                  </TextColor>
                  )
                </>
              )}
            </>
          )}
        </>
      ),
    },
    {
      title: "Add Managers",
      dataIndex: "ID_user",
      key: "ID_user",
      render: (data: any) => (
        <>
          <Button
            height="30px"
            onClick={() => handleAction(data, "add_id_user")}
          >
            Add Managers
          </Button>
        </>
      ),
    },
    {
      title: "Mini Game",
      dataIndex: "mini_game",
      key: "mini_game",
      render: (data: any) => (
        <>
          <Button
            disabled
            height="30px"
            onClick={() => handleAction(data?.projectId, "createMinigame")}
          >
            Create
          </Button>
        </>
      ),
    },
  ];

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    reset,
  } = useForm();

  const [loading, setLoading] = useState<boolean>(false);
  const [dataTables, setDataTables] = useState<any>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [isShowDetail, setIsShowDetail] = useState<boolean>(false);
  const [dataICO, setDataICO] = useState<any>(defaultState);
  const [fieldTokenomics, setFieldTokenomics] = useState<any>([
    {
      name: "",
      percent: "",
    },
  ]);
  const [dataDetail, setDataDetail] = useState<any>(null);
  const [active, setActive] = useState<string>("project");
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [errorField, setErrorField] = useState<any>({});
  const [type, setType] = useState<string>();
  const [openModalEStockManagers, setOpenModalEStockManagers] =
    useState<boolean>(false);

  const handelTabs = (tab: string) => {
    if (tab === "project") {
      setDataTables((prev) => prev.filter((el) => el?.projectId));
    } else {
      getListEstock();
    }
    setActive(tab);
    setFieldTokenomics([
      {
        name: "",
        percent: "",
      },
    ]);
  };

  const handleAction = async (
    data: any,
    action: string,
    isEditLandigPage?: boolean
  ) => {
    switch (action) {
      case "profit":
        setType("profit");
        history.push(`/my/ibo-project/${data?._id}/edit`);
        break;
      case "round":
        setType("round");
        history.push(`/my/ibo-project/${data?._id}/round`);
        break;
      case "add_id_user":
        setType("add_id_user");
        setDataDetail(data);
        setOpenModalEStockManagers(true);
        break;
      case "pre-mainet":
        try {
          await APIs.PROJECT.requestMainet({
            params: {
              id: data._id,
            },
            body: {
              enable: "disable",
            },
          });
          getListEstock();
        } catch (error) {
          toast.error(error.errors || "Error!");
        }
        break;
      case "view-landingpage":
      case "edit-landingpage":
        try {
          const token = await APIs.YOURESTOCK.createTokenIntime(data?._id, {
            symbol: data?.projectId?.symbol,
            role: data?.userInfo?.role,
          });
          if (token) {
            // eslint-disable-next-line max-len
            window.open(
              `/${data?.userInfo?.role.toLowerCase()}/${
                data?.projectId?.symbol
              }?token=${token}${isEditLandigPage ? "&isEdit=true" : ""}`
            );
          } else {
            toast.error("Something went error!");
          }
        } catch (error) {
          toast.error("Something went error!");
        }
        break;
      case "view-request":
        setActive("view-request");
        setDataDetail(data);
        break;
      case "createMinigame":
        setType("createMinigame");
        const token = localStorage.getItem("auth");
        window.open(
          `${process.env.REACT_APP_MINIGAME}/my/create-minigame?token=${token}&projectId=${data?._id}`
        );
        break;

      default:
        setIsModal(true);
        setDataDetail(data);
        setFieldTokenomics(data?.tokenomics);
        if (action === "view") {
          setIsDisable(true);
          setIsShowDetail(true);
          setType("view");
        } else if (action === "editFacebookPageId") {
          setIsDisable(false);
          setIsShowDetail(false);
          setType("editFacebookPageId");
        } else {
          setIsDisable(false);
          setIsShowDetail(false);
          setType("edit");
        }
    }
  };

  const onChangeStatusProject = async (status, id) => {
    try {
      const data = await APIs.PROJECT.changeStatusProject({
        params: {
          id: id,
        },
        body: {
          status: status.value,
        },
      });
      if (data) {
        toast.success("Successfull!");
        getListEstock();
      }
    } catch (error) {
      toast.error(error.errors || "Error!");
    }
  };

  const handleChangeStatusByTrustUser = async (
    checked: boolean,
    id: string
  ) => {
    try {
      const data = await APIs.YOURESTOCK.changeStatusEStock({
        params: {
          id: id,
        },
        body: {
          status: checked ? "approved" : "declined",
        },
      });
      if (data) {
        toast.success("Successfull!");
        getListEstock();
      }
    } catch (error) {
      toast.error(error.errors || "Error!");
    }
  };

  const handleAddEStockManagers = async (
    value: any,
    dataManagersSection: any,
    type: string
  ) => {
    try {
      setLoading(true);
      if (!["add", "delete", "edit"].includes(type))
        return toast.error("Error!");
      const res = await APIs.YOURESTOCK.addEStockManagers({
        params: {
          id: dataDetail._id,
        },
        body: {
          refer_code: value.refer_code,
          sectionManagers: dataManagersSection,
          type: type,
        },
      });
      if (res) {
        setLoading(false);
        toast.success("Successfull!");
        setValue("refer_code", "");
        getListEstock();
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.errors || "Error!");
    }
  };

  const handleEditICO = async (value) => {
    try {
      if (type === "editFacebookPageId") {
        const facebookPageId = value.facebookPageId; //

        await APIs.PROJECT.editProjectFBPageID({
          params: {
            id: dataDetail._id,
          },
          body: {
            facebookPageId,
          },
        });
        toast.success("Update successfull!");

        return;
      }

      let totalTokenomics: number = 0;
      fieldTokenomics.length > 0 &&
        fieldTokenomics.forEach((item) => {
          if (item?.percent < 0) {
            setErrorField((prev) => ({
              ...prev,
              totalTokenomics: false,
            }));
          } else {
            totalTokenomics = totalTokenomics + Number(item?.percent);
            return totalTokenomics;
          }
        });
      if (dataICO?.stock_to_buy || dataDetail.stock_to_buy) {
        setErrorField((prev) => ({
          ...prev,
          stock_to_buy: false,
        }));
        if (totalTokenomics >= 99 && totalTokenomics <= 100) {
          setErrorField((prev) => ({
            ...prev,
            totalTokenomics: false,
          }));
          if (type === "edit" || type === "editFacebookPageId") {
            const dataEdit = {
              is_holding_balance: dataDetail.is_holding_balance,
              contract: value.contract || dataDetail.contract,
              description: value.description || dataDetail.description,
              tabs: value.tabs || dataDetail.tabs,
              url: value.url || dataDetail.url,
              stock_price: dataDetail.stock_price,
              amountToSale: dataDetail.amountToSale,
              stock_to_buy: dataICO.stock_to_buy || dataDetail.stock_to_buy,
              min: dataDetail.min,
              max: dataDetail.max,
              tokenomics: fieldTokenomics,
              facebookPageId: value.facebookPageId || dataDetail.facebookPageId,
              projectCategory:
                value.projectCategory || dataDetail.projectCategory,
              max_per_user: dataDetail.max_per_user,
              type_sell: dataDetail.type_sell,
              logo_pixel: value.logo_pixel || dataDetail.logo_pixel,
            };
            const project: any = await APIs.PROJECT.editProject({
              params: {
                id: dataDetail._id,
              },
              body: {
                ...dataDetail,
                ...dataEdit,
              },
            });
            setIsModal(false);
            if (project) {
              toast.success("Update successfull!");
              getListEstock();
              reset();
            } else {
              toast.error("Error!");
            }
          } else {
            const data = {
              ...dataICO,
              ...value,
              stock_price: dataDetail.stock_price,
              amountToSale: dataDetail.amountToSale,
              min: dataDetail.min,
              max: dataDetail.max,
              tokenomics: fieldTokenomics,
              logo_pixel: dataDetail.logo_pixel,
            };
            const project: any = await APIs.PROJECT.createProject({
              body: data,
            });
            setIsModal(false);
            if (project) {
              toast.success("Created successfull!");
              getListEstock();
              reset();
            } else {
              toast.error("Error!");
            }
          }
        } else {
          setErrorField((prev) => ({
            ...prev,
            totalTokenomics: true,
          }));
        }
      } else {
        setErrorField((prev) => ({
          ...prev,
          stock_to_buy: true,
        }));
      }
    } catch (error) {
      toast.error("Error!");
      setIsModal(false);
    }
  };

  const getListEstock = async () => {
    try {
      const listEstock: any = await APIs.YOURESTOCK.getYourEstockByUserId(
        profile._id
      );
      if (listEstock) {
        const eStock = listEstock.find((el) => el._id === dataDetail?._id);
        setDataDetail(eStock);
        setDataTables(listEstock);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getListEstock();
  }, []);

  const renderEStockManagers = () => {
    return (
      <ContainerRegisterItem>
        <FromAddIDManagers
          page={"landing_page"}
          data={dataDetail?.eStockManagers}
          dataManagersWithSection={dataDetail?.eStockManagersWithSections || {}}
          optionSection={optionManagersSection}
          loading={loading}
          onSubmit={handleAddEStockManagers}
        />
      </ContainerRegisterItem>
    );
  };

  return (
    <Container>
      <Tabs
        active={active}
        tabs={[
          {
            name: "project",
            render: (
              <>
                <AiFillProject size={17} /> <span>My Projects</span>
              </>
            ),
          },
          {
            name: "notification",
            render: (
              <>
                <AiFillNotification size={17} /> <span>Notifications</span>
              </>
            ),
          },
          {
            name: "advertisement",
            render: (
              <>
                <RiAdvertisementLine size={17} /> <span>Advertisement</span>
              </>
            ),
          },
        ]}
        handleTab={handelTabs}
      />

      <br />
      {active === "project" && (
        <Table
          bgTable="blue300"
          columns={
            profile.permissions?.["projects.trust"]
              ? columnsMyProjects
              : columnsMyProjects.filter((el) => el.key !== "on_off")
          }
          dataSource={dataTables}
        ></Table>
      )}
      {active === "view-request" && (
        <RequestLandingPage data={dataDetail} setActive={setActive} />
      )}
      {active === "notification" && <Notification />}
      {active === "advertisement" && <Advertisement />}
      <CreateProject onClick={() => history.push("/create-your-estock")}>
        <img src="/assets/images/icons/createPages.svg" alt="" />
      </CreateProject>
      <Modal
        key={dataDetail?._id}
        isOpen={isModal}
        title="IBO project"
        centered={true}
        onCancel={() => setIsModal(false)}
        {...(isShowDetail ? {} : { onConfirm: handleSubmit(handleEditICO) })}
      >
        <Wrapper>
          <form id={dataDetail?._id}>
            {type === "editFacebookPageId" ? (
              <Controller
                name="facebookPageId"
                control={control}
                rules={{ required: dataDetail?.facebookPageId ? false : true }}
                render={({ field }) => (
                  <Input
                    transparent={true}
                    placeholder="id"
                    label="Facebook Page Id"
                    defaultValue={dataDetail?.facebookPageId}
                    hasError={errors.facebookPageId}
                    {...field}
                  />
                )}
              />
            ) : (
              <FormProjectICO
                isEdit={true}
                active={active}
                isDisable={isDisable}
                isShowDetail={isShowDetail}
                control={control}
                errors={errors}
                dataDetail={dataDetail}
                errorField={errorField}
                fieldTokenomics={fieldTokenomics}
                setFieldTokenomics={setFieldTokenomics}
                setDataICO={setDataICO}
                setDataDetail={setDataDetail}
              />
            )}
          </form>
        </Wrapper>
      </Modal>
      <Modal
        centered={true}
        isOpen={openModalEStockManagers}
        title="E.Stock Managers"
        children={renderEStockManagers()}
        onCancel={() => setOpenModalEStockManagers(false)}
      />
    </Container>
  );
};

export default View;
