/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Data, Wrapper, Certificate } from "./stype";
import { useParams, useHistory } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import APIs from "api";
import { formatNumber, getBase64Image } from "utils/common";
import DownloadImg from "components/download/View";

const CertificateHoldStocks = () => {
  const { projectId } = useParams();
  const history = useHistory();
  const [data, setData] = useState(null);
  const imgRef = useRef(null);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const didMount = async () => {
      try {
        const data = await APIs.PROJECT.getCertificateByUser({
          params: {
            id: projectId,
          },
        });
        if (data) setData(data);
      } catch (error) {
        history.push("/");
      }
    };
    if (projectId) {
      didMount();
    }
  }, [projectId]);

  useEffect(() => {
    const getImages = async () => {
      if (data && data.info) {
        if (data.info.eStockInfo) {
          const {
            certificateLogo,
            certificateImg1,
            certificateImg2,
            certificateImg3,
          } = data.info.eStockInfo;
          const res = await Promise.all(
            [
              certificateLogo,
              certificateImg1,
              certificateImg2,
              certificateImg3,
            ].map(async (i, index) => await getBase64Image(i))
          );
          setImages(res);
        }
      }
    };
    getImages();
  }, [data]);

  return (
    <Wrapper>
      <Container ref={imgRef}>
        {data && (
          <Data>
            <p>{data.name}</p>
            <p>{data.user_id.email}</p>
            <p>{data.investor}</p>
            <p>
              {formatNumber(data.balance || 0)} (
              {String(data.symbol).toUpperCase()})
            </p>
          </Data>
        )}
        {data && data.info && (
          <Certificate>
            <img src={images[0]} alt="" />
            <img src={images[1]} alt="" />
            <img src={images[2]} alt="" />
            <img src={images[3]} alt="" />
          </Certificate>
        )}
      </Container>
      {data && (
        <DownloadImg imgRef={imgRef} fileName={`certificate_${data.symbol}`} />
      )}
    </Wrapper>
  );
};

export default CertificateHoldStocks;
