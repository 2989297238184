/* eslint-disable indent */
import {
  Container,
  Header,
  Item,
  Content,
  Title,
  Icon,
  // Cost,
  // Element,
  Amount,
  User,
  Search,
  ContainDropdown,
  // Percent,
  TextGreen,
  ContainModal,
  ModalHeader,
  ModalContent,
  Coin,
  Transfer,
  Description,
  WapperContent,
  WapperItem,
  WrapperAmount,
  WrapperTitle,
  CounterdownWrapper,
  ImageBg,
  ImageWrapper,
  Image,
  Status,
  SeletedCon,
  StageWrapper,
  PercentProgress,
} from "./styles";
import { Col, Row, Progress } from "reactstrap";
import Select from "react-select";
import Modal from "components/modal";
import Input from "components/input";
import Button from "components/button";
import { useEffect, useState } from "react";
import useProjects from "hooks/userProjects";
import Countdown from "react-countdown";
import { SOCIAL_IMG } from "utils/constants";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { getCryptosByObject } from "redux/reducers/cryptos/cryptos.selector";
import { getCryptosAsync } from "redux/reducers/cryptos/cryptos.actions";
import APIs from "api";
import Histories from "./Histories";
import { getProjectsAsync } from "redux/reducers/projects/projects.action";
import { formatNumber, uppercasefirst } from "utils/common";
import Stage from "./Stage";
import ToolTip from "components/tooltip/View";
import { BsFillGiftFill } from "react-icons/bs";
import Reward from "components/v-reward";
import { getPurchasesAsync } from "redux/reducers/purchases/purchases.actions";
import CongratulationInvests from "components/congratulations";
import { useHistory } from "react-router-dom";

const filters = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Upcoming",
    value: "upcoming",
  },
  {
    label: "Live",
    value: "opening",
  },
  {
    label: "Closed",
    value: "closed",
  },
];

const CountDownElement = (props) => {
  return (
    <div className="countdown-section">
      <div className="countdown-item">
        <span className="countdown-item-value">{props.days}</span>
      </div>
      <div className="countdown-item">
        <span className="countdown-item-value">{props.hours}</span>
      </div>
      <div className="countdown-item">
        <span className="countdown-item-value">{props.minutes}</span>
      </div>
      <div className="countdown-item">
        <span className="countdown-item-value">{props.seconds}</span>
      </div>
    </div>
  );
};

const countdow_render = ({ days, hours, minutes, seconds }) => {
  return (
    <CountDownElement
      days={days}
      hours={hours}
      minutes={minutes}
      seconds={seconds}
    />
  );
};

const filterProject = (projects, filter) => {
  if (filter.value === "all") return projects;
  return projects.filter((p) => p.status === filter.value);
};

const View = () => {
  const dispatch = useAppDispatch();
  const stocks = useAppSelector(getCryptosByObject);
  const { projects, projectsByType } = useProjects();
  const [state, setState] = useState({
    isOpen: false,
    amountFrom: null,
    amountTo: null,
  });
  const [projectSeleted, setProjectSeleted] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    label: "Live",
    value: "opening",
  });
  const [openModalCongratulation, setOpenModalCongratulation] = useState(false);
  const [congratulationInverst, setCongratulationInverst] = useState<any>(null);
  const history = useHistory();

  const handleOpen = (project) => {
    if (project.status !== "opening")
      return toast.warning("Now is not the time to sell!");
    setProjectSeleted(project);
    setState({
      ...state,
      isOpen: true,
    });
  };

  const handleClosed = () => {
    setProjectSeleted(null);
    setState({
      ...state,
      isOpen: false,
      amountFrom: 0,
      amountTo: 0,
    });
  };

  const handleChange = (type, value, round) => {
    let amountFrom = 0;
    let amountTo = 0;
    if (type === "from") {
      amountFrom = +value;
      amountTo = +value / +round.price;
    } else {
      amountTo = +value;
      amountFrom = +value * +round.price;
    }
    setState({
      ...state,
      amountFrom,
      amountTo,
    });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const res: any = await APIs.PURCHASE.buyIBO({
        body: {
          project_id: projectSeleted._id,
          amount: +state.amountFrom,
        },
      });
      if (res) {
        setCongratulationInverst(res.purchase);
        setOpenModalCongratulation(true);
        toast.success("Congratulations on your successful purchase!");
        setProjectSeleted(null);
        setState({
          ...state,
          isOpen: false,
          amountFrom: 0,
          amountTo: 0,
        });
        dispatch(
          getPurchasesAsync({
            params: {
              type: "project",
            },
          })
        );
        dispatch(getProjectsAsync());
      }
      setLoading(false);
    } catch (error) {
      toast.error(error.errors);
      setLoading(false);
    }
  };

  const renderModalCongratulation = (investSelected: any) => {
    if (investSelected) {
      const stageBuyIdo = investSelected?.project_id?.stages?.filter(
        (el: any) => el.name === investSelected?.project?.round
      );
      return (
        <CongratulationInvests
          type="buyIdo"
          background={stageBuyIdo[0]?.backgroundSuccess}
          userName={investSelected?.user_id?.fullName}
          avataUser={investSelected?.user_id?.avatar}
          logoProject={investSelected?.project_id?.logo}
          logoLevel={stageBuyIdo[0]?.logoLevel}
          amount={investSelected?.project?.amountReceived}
          symbol={investSelected?.project_id?.symbol}
          createdAt={investSelected?.createdAt}
          openModalCongratulation={openModalCongratulation}
          setOpenModalCongratulation={setOpenModalCongratulation}
        />
      );
    }
  };

  const renderModal = (projectSeleted) => {
    if (projectSeleted) {
      const round = projectSeleted.stages.find((s) => s.on_sale);

      return (
        <ContainModal>
          <ModalHeader>
            <img src={projectSeleted.logo} alt="" />
            {/* <span>VZONEX</span> */}
            <span>{projectSeleted.name}</span>
          </ModalHeader>
          <ModalContent>
            <Coin>
              <img src={stocks[projectSeleted.stock_to_buy]["logo"]} alt="" />
              <span>{projectSeleted.stock_to_buy.toUpperCase()}</span>
              <Input
                name="amountFrom"
                // type="number"
                isCurrency={true}
                value={state.amountFrom}
                placeholder="0.00"
                onValueChange={(e) => handleChange("from", e, round)}
              />
            </Coin>
            <Transfer>
              <img src="/assets/images/icons/transfer.svg" alt="" />
            </Transfer>
            <Coin>
              <img src={projectSeleted.logo} alt="" />
              <span>{projectSeleted.symbol.toUpperCase()}</span>

              <Input
                isCurrency={true}
                name="amountTo"
                value={state.amountTo}
                placeholder="0.00"
                onValueChange={(e) => handleChange("to", e, round)}
              />
            </Coin>
            <Description>
              <span>Rate</span>
              <span>
                1 {String(projectSeleted.symbol || "").toUpperCase()} ={" "}
                {round.price}{" "}
                {String(projectSeleted.stock_to_buy || "").toUpperCase()}
              </span>
            </Description>
            {/* <Description>
                <span>Fee</span>
                <span>0.5%</span>
              </Description> */}
            <Button onClick={handleSubmit} loading={loading}>
              Buy
            </Button>
          </ModalContent>
        </ContainModal>
      );
    }
  };

  const projectByFilter = filterProject(projects, filter);

  const renderProgress = (project: any) => {
    if (project.stages) {
      const stage = project.stages.find((s) => s.on_sale);
      if (project.status === "upcoming") {
        return (
          <>
            <Amount>
              <label>Start</label>
              <User>
                {/* <span>516</span> */}
                {project.status === "opening" && <Button disabled>BUY</Button>}
              </User>
            </Amount>
            <Progress value={0} color="warning" />
          </>
        );
      }
      if (stage) {
        return (
          <>
            <Amount>
              <label>
                {formatNumber(+stage.amountSold || 0)} /{" "}
                {formatNumber(stage.amountToSale)}{" "}
                {project.symbol.toUpperCase()}
              </label>
              <User>
                {/* <span>516</span> */}
                {project.status === "opening" && (
                  <Button
                    onClick={() => handleOpen(project)}
                    disabled={!project.stages.some((s) => s.on_sale)}
                  >
                    BUY
                  </Button>
                )}
              </User>
            </Amount>
            <Progress
              value={(+stage.amountSold / +stage.amountToSale) * 100}
              color="warning"
            >
              <PercentProgress>
                {((+stage.amountSold / +stage.amountToSale) * 100).toFixed(1)}
                {"%"}
              </PercentProgress>
            </Progress>
          </>
        );
      } else {
        return (
          <>
            <Amount>
              <label className="end">END</label>
              <User>
                {/* <span>516</span> */}
                {project.status === "opening" && <Button disabled>BUY</Button>}
              </User>
            </Amount>
            <Progress value={100} color="warning" />
          </>
        );
      }
    }
  };

  const renderProgressPixel = (project: any) => {
    if (project.rounds) {
      const stage = project.rounds.find((s) => s.on_sale);
      if (project.status === "upcoming") {
        return (
          <>
            <Amount>
              <label>Start</label>
              <User>
                {/* <span>516</span> */}
                {project.status === "opening" && <Button disabled>View</Button>}
              </User>
            </Amount>
            <Progress value={0} color="warning" />
          </>
        );
      }
      if (stage) {
        return (
          <>
            <Amount>
              <label>
                {formatNumber(+stage.amountSold || 0)} /{" "}
                {formatNumber(stage.pixels.length)}{" "}
                {project.symbol.toUpperCase()}
              </label>
              <User>
                {/* <span>516</span> */}
                {project.status === "opening" && (
                  <Button
                    onClick={() => history.push(`/my/ico/${project._id}`)}
                    disabled={!project.rounds.some((s) => s.on_sale)}
                  >
                    View
                  </Button>
                )}
              </User>
            </Amount>
            <Progress
              value={(+stage.amountSold / +stage.pixels.length) * 100}
              color="warning"
            >
              <PercentProgress>
                {((+stage.amountSold / +stage.pixels.length) * 100).toFixed(1)}
                {"%"}
              </PercentProgress>
            </Progress>
          </>
        );
      } else {
        return (
          <>
            <Amount>
              <label className="end">END</label>
              <User>
                {/* <span>516</span> */}
                {project.status === "opening" && <Button disabled>View</Button>}
              </User>
            </Amount>
            <Progress value={100} color="warning" />
          </>
        );
      }
    }
  };

  useEffect(() => {
    dispatch(getCryptosAsync());
  }, [dispatch]);

  return (
    <Container>
      <Header>
        <Row>
          <Col xs={12} md={2}>
            <WrapperAmount>
              <strong>{projectsByType["opening"].length} opening</strong>
            </WrapperAmount>
          </Col>
          <Col xs={12} md={3}>
            <ContainDropdown>
              <SeletedCon>
                <Select
                  options={filters}
                  defaultValue={filter}
                  // isMulti
                  onChange={(e) => {
                    setFilter(e);
                  }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder={"Status"}
                />
              </SeletedCon>
            </ContainDropdown>
          </Col>
          <Col xs={12} md={3}>
            {" "}
            <Search>
              <Input placeholder={"Search..."} />
            </Search>
          </Col>
        </Row>
      </Header>
      <WapperContent>
        {projectByFilter.map((project, key) => (
          <WapperItem key={key}>
            <Item>
              <ImageWrapper>
                <ImageBg props={{ bg: project.banner }} />
                <Image>
                  <img src={project.logo} alt="" />
                </Image>
              </ImageWrapper>

              <Content>
                <WrapperTitle>
                  <Row>
                    <Col xs={12}>
                      <Title>
                        <a
                          href={
                            project.symbol === "VDC"
                              ? `/personal/${project.symbol}`
                              : project.roleUser
                              ? `/${project.roleUser}/${project.symbol}`
                              : project.pathLandingPage || "/"
                          }
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          {project.name.length > 31
                            ? `${project.name.slice(0, 28)}...`
                            : project.name}
                        </a>
                      </Title>
                    </Col>
                    <Col xs={12}>
                      {project.status !== "closed" && (
                        <CounterdownWrapper>
                          <Countdown
                            date={new Date(
                              project.status === "opening"
                                ? project.keep_time
                                  ? project.keep_time.ido_close
                                  : 0
                                : project.keep_time
                                ? project.keep_time.ido_open
                                : 0
                            ).getTime()}
                            renderer={countdow_render}
                          />
                        </CounterdownWrapper>
                      )}
                    </Col>
                  </Row>
                </WrapperTitle>

                <Icon>
                  {Object.keys(project.social || {}).map((key) => {
                    return (
                      <a
                        href={project.social[key]}
                        target="_blank"
                        rel="noreferrer"
                        key={key}
                      >
                        <img src={SOCIAL_IMG[key]} alt="" />
                      </a>
                    );
                  })}
                </Icon>
                <label>
                  Starts{" "}
                  {project.keep_time
                    ? new Date(project.keep_time.ido_open).toLocaleString()
                    : "N/A"}{" "}
                  in your time
                </label>
                <StageWrapper>
                  <Stage
                    stages={
                      project.type_sell === "pixel"
                        ? project.rounds
                        : project.stages
                    }
                    name={project.symbol}
                    stock={project.stock_to_buy}
                    type={project.type_sell}
                  />
                </StageWrapper>
                {/* <Cost>
                  <Element>
                    <img src="/assets/images/icons/lock.svg" alt="" />
                    <span>100%</span>
                  </Element>
                  <Percent>
                    <img src="/assets/images/icons/lock.svg" alt="" />
                    <span>0.2%</span>
                  </Percent>
                </Cost> */}
                <Row>
                  <Col xs={10}>
                    {" "}
                    <Status>
                      <label className={project.status}>
                        {uppercasefirst(project.status)}
                      </label>
                    </Status>
                    <TextGreen>
                      <label>Markets Initialized</label>
                    </TextGreen>
                  </Col>
                  <Col xs={2}>
                    {" "}
                    <ToolTip
                      id={project._id}
                      content={<Reward reward={project.reward} />}
                    >
                      <span id={`tooltip-` + project._id} className="gift">
                        <BsFillGiftFill size={18} />
                      </span>
                    </ToolTip>
                  </Col>
                </Row>
                {project.type_sell === "pixel"
                  ? renderProgressPixel(project)
                  : renderProgress(project)}
              </Content>
            </Item>
          </WapperItem>
        ))}
      </WapperContent>
      <br />
      <Histories
        setCongratulationInverst={setCongratulationInverst}
        setOpenModalCongratulation={setOpenModalCongratulation}
      />
      {projectSeleted && (
        <Modal
          isOpen={state.isOpen}
          children={renderModal(projectSeleted)}
          onCancel={handleClosed}
          centered={true}
        />
      )}

      {renderModalCongratulation(congratulationInverst)}
    </Container>
  );
};

export default View;
