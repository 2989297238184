import Input from "components/input";
import {
  ContainerInput,
  ItemShare,
  ListFriends,
  TableDetail
} from "../styles";

import Table from "components/table";
import { useState } from "react";

const columnList = [
  {
    title: 'FullName',
    dataIndex: 'fullName',
    key: 'fullName',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  // {
  //   title: 'ID',
  //   dataIndex: 'refer_code',
  //   key: 'refer_code',
  // },
]
const ReferCodeSection = (props: any) => {
  const {
    dataProfit,
    dataListFriends,
    field,
    type,
    isWebview,
    onChangeAffilate } = props
  const [isShowListFriends, setIsShowListFriends] = useState<boolean>(false)
  const [indexInputFocus, setIndexInputFocus] = useState<number>(null)

  const handleFocusInput = (index: number) => {
    setIsShowListFriends(true)
    setIndexInputFocus(index)
  }

  const hanleClickRow = (value: any) => {
    onChangeAffilate(
      indexInputFocus,
      "refer_code",
      {
        target: { value: value.refer_code }
      },
      field,
      type
    )
    setIsShowListFriends(false)
    setIndexInputFocus(null)
  }

  return <>
    {
      dataProfit?.map(
        (el: any, index: number) => (
          <ItemShare>
            <ContainerInput>
              <Input
                id={el?.refer_code}
                transparent={!isWebview}
                color={isWebview ? "black" : "white"}
                label="ID"
                placeholder="ID"
                value={el?.refer_code}
                // hasError={errors[`tokenomics${index}${index}${active}`]}
                onFocus={() => handleFocusInput(index)}
                onChange={(e) =>
                  onChangeAffilate(
                    index,
                    "refer_code",
                    e,
                    field,
                    type
                  )
                }
              />
            </ContainerInput>
            <ContainerInput>
              <Input
                transparent={!isWebview}
                color={isWebview ? "black" : "white"}
                type="number"
                label="% profit received"
                placeholder="0"
                suffixIcon="%"
                value={el?.percent}
                // hasError={errors[`tokenomics${index}${index}${active}`]}
                onChange={(e) =>
                  onChangeAffilate(
                    index,
                    "percent",
                    e,
                    field,
                    type
                  )
                }
              />
            </ContainerInput>
          </ItemShare>
        )
      )
    }
    {
      (isShowListFriends && dataListFriends.length > 0) &&
      <ListFriends>
        <p>List Friends</p>
        <TableDetail>
          <Table
            bgTable="blue300"
            columns={columnList}
            dataSource={dataListFriends}
            onChange={hanleClickRow}
          />
        </TableDetail>
      </ListFriends>
    }
  </>
}

export default ReferCodeSection;