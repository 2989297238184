import styled from "styled-components"
import { isMobile } from "react-device-detect";

export const ViewAndDown = styled.div`
  padding: 15px;
  margin-top: 30px;
  height: calc(calc(var(--vh, 1vh) * 100) - ${isMobile ? "100px" : "0"});
  width: 100%;

  img {
    width: 100%;
    height: auto;
    max-width: 900px;
  }

  .react-transform-wrapper {
    margin: auto;
  }
`;

export const ContainerViewImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(calc(var(--vh, 1vh) * 100) - 100px);
`;

export const ActionImage = styled.div`
  display: flex;
  padding: 0 20px;
  justify-content: space-between;

  div {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: orange;
    }
  }

  img {
    width: 20px;
  }
`;

export const ActionZoom = styled.div`
  text-align: center;
  margin-top: 10px;
  width: 100%;

  button {
    margin: 0 2px;
    padding: 0 19px;
    font-size: 19px;
  }
`;