import styled from "styled-components";

export const ItemContent = styled.div`
  height: 100%;

  img {
    width: 100%;
  }
`;

export const Flex = styled.div<{ main?: boolean }>`
  display: flex;

  &:nth-child(2) {
    pointer-events: none;
    margin-top: ${props => props.main ? "-20px" : '0px'};
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    &:nth-child(2) {
      margin-top: 0px;
    }
  }
`;

export const ItemFooter = styled.div`
  margin-top: 40px;
  width: 100%;
  max-width: 430px;

  a {
    color: #FFF;
    text-decoration : none;
  }
  
  h6 {
    width: fit-content;
    border-bottom: 1px solid;
    margin-bottom: 20px;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      color: #a0a0a0;
    }
  }

  p {
    cursor: pointer;
    &:hover {
      color: #a0a0a0;
    }
    font-size: 14px;
  }

  span {
    font-size: 14px;
    display: flex;
    align-items: center;
    line-height: 16px;
    margin-bottom: 20px;
    img {
      width: 25px;
      margin-right: 5px;
    }
  }
`;

export const LogoFooter = styled.div`
  margin: 30px 0;
  position: relative;
  /* max-width: 50%; */
  width: auto;
  height: 100%;
  min-height: 50px;
  img {
    width: auto !important;
    max-width: 100% !important;
    cursor: pointer;
    height: 50px !important;
  }
`;

export const ListIcons = styled.div`
  display: flex;
  margin: 40px 0;

  img {
    margin-right: 30px;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
`;

export const AddLinkFooter = styled.div<{mt?: string}>`
  margin-top: ${props => props.mt || '0px'};
  cursor: pointer;
`;

export const ItemContainer = styled.div`
  padding-right: 50px;
`;

export const Upload = styled.div`
  position: absolute;
  width: auto;
  max-width: 100%;
  height: 100%;
`;
