import styled from "styled-components";
// import { isMobile } from "react-device-detect";

export const UploadCalendarImg = styled.div`
  width: auto;
  height: 400px;
  max-width: 700px;
  margin: auto;
  position: relative;
  margin-bottom: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;

  img {
    width: auto;
    height: 100%;
  }
  
  input {
    width: 100%;
    height: 37px;
    margin-top: 15px;
  }
`;
