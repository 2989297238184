import { isMobile } from "react-device-detect";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-top: 40px;
  background: ${(props) => props.theme.colors["black100"]};
  padding: 20px;
  border-radius: 8px;
  hr {
    margin: 10px 0px;
  }
  .history {
    overflow: auto;
    .principal,
    .profit {
      button {
        padding: 3px 6px;
        height: auto;
        font-size: 12px;
      }
    }

    button {
      padding: 5px 12px;
      height: auto;
      font-size: 12px;
    }
  }
`;

export const PackagesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Item = styled.div<{ type }>`
  background-image: ${(props) => props.type};
  border-radius: 10px;
  border: 1px solid white;
  width: ${isMobile ? "350px" : "400px"};
  margin: 15px;
  padding: 5px;
  overflow: hidden;
  position: relative;
  text-align: center;

  .video-bg {
    height: 100%;
  }

  .video-bg .player-video-link {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 0;
    max-width: 100%;
    height: 100% !important;
    object-fit: fill;
  }

  .video-bg .player-video-link video {
    z-index: 0;
    max-width: 100%;
    height: 100% !important;
    object-fit: fill;
  }
`;

export const Background = styled.div<{ bg }>`
  background-image: url(${(props) => props.bg});
  padding: 10px 5px;
  border-radius: 10px;
`;

export const Title = styled.div`
  position: relative;
  z-index: 100;
  border: 1px solid gray;
  padding: 5px 20px;
  text-align: center;
  border-radius: 10px;
  font-weight: 700;
`;

export const Introduce = styled.div`
  p {
    font-size: 18px;
    font-weight: 700;
    margin: 0px;
    width: 100%;
    height: 27px;
    overflow: hidden;
    position: relative;
    z-index: 50;
  }
  span {
    font-size: 12px;
    position: relative;
    z-index: 50;
  }
`;

export const Profit = styled.div`
  font-size: 13px;
  min-height: 300px;
  position: relative;
  z-index: 50;
  .title {
    font-weight: 600;
    margin: 0;
    font-size: 18px;
  }
  .gift {
    margin-right: 15px;
    .reward {
      animation: wiggle 2s linear infinite;
    }
  }
  .active {
    background-image: linear-gradient(90deg, #292a2a 0, #b9be54);
    font-weight: 700;
  }
  .col-4 {
    cursor: pointer;
  }
  .intended-for {
    font-weight: 700;
  }
`;
export const Footer = styled.div`
  text-align: center;
  position: relative;
  z-index: 50;

  .btn-glow {
    background: #1877f2;
    border-color: #1877f2;
    font-weight: 600;
    border: none;
    outline: none;
    color: #fff;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
  }

  .btn-glow:before {
    content: "";
    background: linear-gradient(
      45deg,
      #ff0000,
      #ff7300,
      #fffb00,
      #48ff00,
      #00ffd5,
      #002bff,
      #7a00ff,
      #ff00c8,
      #ff0000
    );
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }

  .btn-glow:active {
    color: #000;
  }

  .btn-glow:active:after {
    background: transparent;
  }

  .btn-glow:hover:before {
    opacity: 1;
  }

  .btn-glow:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  @keyframes glowing {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
`;

export const CounterdownWrapper = styled.div`
  margin: 10px 0;
  text-align: center;
  position: relative;
  z-index: 50;
  .title {
    font-size: 17px;
    text-align: center;
    font-weight: 700;
  }
  .Upcoming {
    color: ${(props) => props.theme.colors["yellow100"]};
  }
  .Happening {
    color: ${(props) => props.theme.colors["white"]};
  }
  .countdown-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0;
    .countdown-item {
      margin-right: 3px;
      .countdown-item-label {
        margin-bottom: 2px;
        padding-top: 0px;
        font-size: 10px;
      }
      &:last-child {
        margin-right: 0;
      }
      .countdown-item-value {
        background: #284f8a;
        padding: 0;
        font-family: "Poppins", Sans-serif;
        font-size: 14px;
        border-radius: 12px 12px 12px 12px;
        width: 30px;
        height: 30px;
        margin-bottom: 0px;
      }
    }
  }
`;

export const ContainModal = styled.div`
  max-width: 600px;
  padding: 20px;
  h3 {
    font-weight: 600;
    text-align: center;
  }
  input {
    width: 100%;
  }
  .packages {
    button {
      margin: 10px;
    }
  }
  .img-info {
    width: 100%;
    max-width: 1040px;
    height: auto;
  }
  .middle {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .output {
    border: 1px solid gray;
    border-radius: 8px;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
  }
  .image {
    img {
      max-width: 600px;
    }
  }
  .editor {
    border: 1px solid;
    padding: 10px;
    max-height: 420px;
    overflow: auto;
    img {
      max-width: 100%;
    }
  }
  .accept_term {
    display: flex;
    input {
      width: 30px;
      height: 20px;
    }
    margin-bottom: 10px;
  }

  @media screen and (max-width: 500px) {
    .image {
      img {
        max-width: 300px;
      }
    }
  }

  @media screen and (max-width: 500px) {
    .img-info {
      max-width: 300px;
    }
  }
`;

export const IconWrapper = styled.div`
  text-align: center;
  margin: 10px 0;
  position: relative;
  z-index: 50;
  img {
    width: 100px;
    height: 100px;
  }
`;

export const ExpectValueWrapper = styled.div`
  select,
  select:hover {
    color: gray !important;
  }
`;

export const ModalHistoryWrapper = styled.div`
  max-height: 500px;
  overflow: auto;
`;

export const Coin = styled.div`
  input {
    width: 100%;
  }
  img {
    margin-bottom: 5px;
    margin-right: 5px;
  }
`;

export const Transfer = styled.div`
  text-align: center;
`;

export const Description = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  span {
    font-size: 15px;
  }
  span:not(:first-child) {
    color: rgb(31, 199, 212) !important;
    font-size: 16px !important;
    font-weight: 600 !important;
  }
`;

export const Available = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: -4px;
  span {
    font-size: 15px;
    margin-right: 4px;
  }
  span:not(:first-child) {
    color: rgb(31, 199, 212) !important;
    font-size: 16px !important;
    font-weight: 600 !important;
  }
`;

export const ModalHeader = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
    width: 40px;
  }
  span {
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 7px;
  }
`;

export const ContainButton = styled.div`
  button {
    font-size: 20px;
    font-weight: 700;
    background: #ff9900;
    display: flex;
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
  margin-bottom: 10px;
`;
export const ContainCard = styled.div``;

export const Promotion = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  .promotion-image {
    overflow: hidden;
    border-radius: 4px;
    img {
      position: relative;
      z-index: 50;
      width: 70px;
      height: auto;
    }
  }
`;

export const CounterdownPromotionWrapper = styled.div`
  position: relative;
  z-index: 50;
  .countdown-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0;
    .countdown-item {
      margin-right: 1px;
      .countdown-item-label {
        margin-bottom: 1px;
        padding-top: 0px;
        font-size: 8px;
      }
      &:last-child {
        margin-right: 0;
      }
      .countdown-item-value {
        background: #ff9900;
        padding: 0;
        font-family: "Poppins", Sans-serif;
        font-size: 12px;
        border-radius: 12px 12px 12px 12px;
        width: 25px;
        height: 25px;
        margin-bottom: 0px;
      }
    }
  }
`;

export default Wrapper;
