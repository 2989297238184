import Button from "components/button";
import Input from "components/input";

import VParticles from "components/particles";
// import ProjectAdvertisement from "layouts/ProjectAdvertisement";
import {
  AddBanner,
  AddLink,
  AddOrRemoveChil,
  AddSlideBanner,
  BackgroundBanner,
  Banner,
  MovePosition,
  SlideUpload,
  Upload,
} from "pages/home/landing-page/styles";

import SliderCustom from "components/slider";
import Modal from "components/modal";
import { useEffect, useState } from "react";
import UploadFile from "components/uploadFile";

import { v4 as uuidv4 } from "uuid";
import APIs from "api";

import { checkValidUrlFB } from "utils/convertIdVideo";
import Video from "components/video";

const View = (props) => {
  const {
    id,
    index,
    isEdit,
    page,
    type,
    minHeight,
    isSlide,
    linkVideo,
    listSlideBanner,
    listLogos,
    imageBottom,
    setDataState,
    onChangeData,
  } = props;
  const [isModal, setIsModal] = useState<boolean>(false);
  const [typeModal, setTypeModal] = useState<string>(null);
  const [listSlide, setListSlide] = useState<any>(listSlideBanner || []);
  const [link, setLink] = useState<string>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [logoLinks, setLogoLinks] = useState<any>({});

  useEffect(() => {
    if (listLogos?.length > 0) {
      setLogoLinks(listLogos.reduce((acc, item) => ({
        ...acc,
        [item.id]: item.link
      }), {}))
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listLogos?.length])

  const handleOpenPopup = (type: string) => {
    if (type === "slide") {
      setListSlide(listSlideBanner);
    }
    if (type === "logo") {
      setListSlide(listLogos);
    }
    setIsModal(true);
    setTypeModal(type);
  };

  const onChangLink = (e: any) => {
    setLink(e.target.value);
  };

  const setFile = (f: any, id: string, key?: string) => {
    if (key === "image_bottom_banner") {
      setDataState((prev) => ({
        ...prev,
        banner: {
          ...prev.banner,
          imageBottom: {
            ...prev.banner.imageBottom,
            file: f,
            isNew: true,
          },
        },
      }));
    } else {
      setListSlide((prev) =>
        prev.map((el: any) =>
          el.id === id
            ? {
              ...el,
              file: f,
              isNew: true,
            }
            : el
        )
      );
    }
  };

  const handleAccept = async () => {
    setLoading(true);
    if (typeModal === "banner") {
      if (type === "banner") {
        setDataState((prev) => ({
          ...prev,
          banner: {
            ...prev.banner,
            link: link,
            slide: [],
          },
        }));
      } else {
        onChangeData(
          {
            link: link,
            slide: [],
          },
          index,
          id
        );
      }
    } else {
      // event things
      if (
        listSlide?.length > 0 &&
        listSlide?.filter((it) => it.file)?.length > 0
      ) {
        const formData = new FormData();
        formData.append("folderPath", `event`);

        const statesNew = listSlide
          .filter((st) => st.isNew && st.file)
          .map((stNew) => {
            formData.append("files", stNew.file);
            return stNew;
          });

        const listImg: any = await APIs.UPLOAD.uploadMultiFile({
          body: formData,
        });

        listImg.forEach((el) => {
          statesNew[el.id] = {
            ...statesNew[el.id],
            src: el?.filePath,
          };
        });

        const slideUpload = listSlide.map((st) => {
          const newStFound = statesNew.find((c) => c.id === st.id);
          if (newStFound) {
            return {
              ...st,
              ...newStFound,
            };
          }
          return st;
        });

        if (type === "banner") {
          if (typeModal === "slide") {
            
            setDataState((prev) => ({
              ...prev,
              banner: {
                ...prev.banner,
                link: "",
                slide: slideUpload,
              },
            }));
          } else {
            setDataState((prev) => ({
              ...prev,
              banner: {
                ...prev.banner,
                listLogos: slideUpload.map(item => ({
                  ...item,
                  link: logoLinks[item.id]
                })),
              },
            }));
          }
        } else {
          onChangeData(
            {
              link: "",
              slide: slideUpload,
            },
            index,
            id
          );
        }
      } else {
        if (type === "banner") {
          setDataState((prev) => ({
            ...prev,
            banner: {
              link: "",
              slide: typeModal === "slide" ? listSlide : listSlideBanner,
              listLogos: (typeModal === "logo" ? listSlide : listLogos).map((item) => ({
                ...item,
                link: logoLinks[item.id]
              })),
            },
          }));
        } else {
          onChangeData(
            {
              link: "",
              slide: listSlide,
            },
            index,
            id
          );
        }
      }
    }
    setLoading(false);
    setIsModal(false);
  };

  const removeChil = (uuid: string) => {
    const newArr = listSlide.filter((el: any) => el.id !== uuid);
    setListSlide(newArr);
  };

  const handleMoveChil = (index: number, key: string) => {
    const newArr = listSlide;
    const el = newArr[index];

    if (key === "upward") {
      newArr[index] = newArr[index - 1];
      newArr[index - 1] = el;
    } else {
      newArr[index] = newArr[index + 1];
      newArr[index + 1] = el;
    }
    setListSlide([...newArr]);
  };

  useEffect(() => {
    setListSlide(listSlideBanner);
  }, [listSlideBanner]);

  return (
    <>
      <Modal
        isOpen={isModal}
        title={
          typeModal === "slide"
            ? "Slide"
            : typeModal === "logo"
              ? "Logo"
              : "Link Youtube/Facebook"
        }
        centered={true}
        loading={loading}
        onCancel={() => setIsModal(false)}
        onConfirm={() => handleAccept()}
      >
        <AddLink>
          {typeModal === "banner" && (
            <Input
              transparent={true}
              label="Link"
              onChange={(e) => onChangLink(e)}
              placeholder="link..."
            />
          )}
          {(typeModal === "slide" || typeModal === "logo") && (
            <AddSlideBanner>
              <div
                className="add_image"
                onClick={() =>
                  setListSlide(
                    [
                      {
                        id: uuidv4(),
                        src: "",
                      },
                    ].concat([...listSlide])
                  )
                }
              >
                +
              </div>
              {listSlide?.length > 0 &&
                listSlide.map((sl: any, index: number) => (
                  <div>
                    <SlideUpload key={sl.id}>
                      <AddOrRemoveChil>
                        <div onClick={() => removeChil(sl.id)}>-</div>
                      </AddOrRemoveChil>
                      <MovePosition>
                        {index !== 0 && (
                          <Button
                            onClick={() => handleMoveChil(index, "upward")}
                          >
                            {"<"}
                          </Button>
                        )}
                        {index !== listSlide.length - 1 && (
                          <Button
                            onClick={() => handleMoveChil(index, "downward")}
                          >
                            {">"}
                          </Button>
                        )}
                      </MovePosition>
                      <Upload>
                        <UploadFile
                          id={sl.id}
                          ratio="1920x700"
                          sectionName={typeModal}
                          file={sl.file}
                          src={sl.src}
                          setFile={setFile}
                        />
                      </Upload>
                    </SlideUpload>
                    <div style={{ width: "150px", height: "32px", margin: "4px" }}>
                      <Input
                        height={"32px"}
                        placeholder="link"
                        transparent={true}
                        color={"orange"}
                        value={logoLinks[sl.id]}
                        onChange={(e) => setLogoLinks((prev) => ({
                          ...prev,
                          [sl.id]: e.target.value
                        }))}
                      />
                    </div>
                  </div>
                ))}
            </AddSlideBanner>
          )}
        </AddLink>
      </Modal>
      <Banner
        bg={Boolean(!linkVideo && !isSlide)}
        zIndex={Boolean(!linkVideo && !isSlide) ? 0 : 2}
        minHeight={minHeight}
        className="_banner_container"
      >
        {isEdit && (
          <>
            {type === "banner" && page === "event" && (
              <AddBanner isLeft={true}>
                <Button onClick={() => handleOpenPopup("logo")}>Logos</Button>
              </AddBanner>
            )}
            <AddBanner>
              <Button onClick={() => handleOpenPopup("banner")}>
                Link video
              </Button>
              <Button onClick={() => handleOpenPopup("slide")}>Slide</Button>
            </AddBanner>
          </>
        )}
        {!linkVideo && !isSlide && false && <VParticles />}
        {
          <BackgroundBanner
            isVideo={Boolean(linkVideo)}
            isLinkPB={checkValidUrlFB(linkVideo)}
          >
            {linkVideo && (
              <Video
                type={type}
                page={page}
                isEdit={isEdit}
                link={linkVideo}
                imageBottom={imageBottom}
                setFile={setFile}
              />
            )}
            {isSlide && <SliderCustom data={listSlideBanner} />}
          </BackgroundBanner>
        }
      </Banner>
    </>
  );
};

export default View;
