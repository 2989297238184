/* eslint-disable array-callback-return */
/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  AddOrRemoveChil,
  AddSectionTeam,
  // QrCodeWrapper,
  Upload,
  AddLink,
} from "pages/home/landing-page/styles";
import { useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import queryString from "query-string";

// import { TiTick } from "react-icons/ti";

import APIs from "api";

import Button from "components/button";
import UploadFile from "components/uploadFile";

import Guest from "../guest";
// import DateTimeEvent from "./date-event";
import DocumentsSection from "components/business-landing-page/components/DocumentSection";
import VideoSection from "components/business-landing-page/components/VideoSection";
import FormAddLink from "./formAddLink";
import FormCreateContributeIdeas from "components/form-create-field";
import Video from "components/video";

import { copyText } from "utils/common";
import decode from "utils/decodeToken";
import { percentToHex } from "utils/common";

import Wrapper, {
  Section,
  Title,
  TitleOrg,
  Content,
  ItemFlex,
  // Statistical,
  // Action,
  Organization,
  OrganizationItem,
  Submit,
  ContainerItem,
  ItemSurveyDetail,
  // HeaderRunIcon,
} from "../styles";

import { toast } from "react-toastify";
// import { ROUTES } from "config/routes";
import Input from "components/input";
import SettingColor from "components/setting-color-page";
import IconPageConnect from "components/icon-page-connect";
// import QRCode from "react-qr-code";
import Modal from "components/modal";
import Footer from "layouts/components/footer";
import CollapseField from "components/business-landing-page/components/CollapseField";
import FormInfomation from "./formInfomation";
import Survey from "./survey";

// import { formatNumber } from "utils/common";

import BannerPage from "components/banner_banner-ads";

// import Marquee from "components/marquee";
// import { isMobile } from "react-device-detect";

import LogosAds from "./event-sections/LogosAds";
import TitleCheckinSection from "./event-sections/TitleCheckinSection";
import EventCalendars from "./event-sections/EventCalendars";

// import FirstStepForm from "./FirstStepForm";

import {
  getDefaultEventData,
  optionSetting,
  optionSectionDefault,
  getEventRoute,
  getEventTypeFromRoute,
} from "./constants";

const View = () => {
  const params = useParams();
  const location: any = useLocation();
  const history = useHistory();

  const domain = window.location.origin;

  const { id } = params;

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const defaultData = useMemo(
    () => getDefaultEventData(getEventTypeFromRoute(location.pathname)),
    []
  );

  const [isEdit, setEditMode] = useState(true);

  // const [showFirstStep, setShowFirstStep] = useState(true);
  const [dataEvent, setDataEvent] = useState<any>({
    ...defaultData,
    _id: id,
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [openModalInteractive, setOpenModalInteractive] = useState<any>({
    open: false,
    key: null,
  });

  console.log("dataEvent", dataEvent._id);

  const getToken = () => {
    const token = isEdit
      ? queryString.parse(location.search)?.token
      : localStorage.getItem("auth") ||
        localStorage.getItem("interactive-survey");
    return token;
  };

  const user: any = decode(getToken());

  const [optionSection, setOptionSection] = useState(optionSectionDefault);

  const [typeInteractive, setTypeInteractive] = useState<string>("");

  //managersSection
  const [managersSections, setManagersSections] = useState<any>([]);
  const [isOwnerEventOrAdmin, setIsOwnerEventOrAdmin] =
    useState<boolean>(false);

  //survey
  const [isModalLinkSurvey, setIsModalLinkSurvey] = useState<any>({
    open: false,
    type: null,
  });

  const [indexStateChange, setIndexStateChange] = useState<any>({
    idx: null,
    id: null,
  });

  const [listLinkIcon, setListLinkIcon] = useState<any>({
    link: "",
    link_vdiarybook: "",
    link_zalo: "",
    link_facebook: "",
    link_document: "",
    others: [],
  });

  //setting color
  const [openSettingColor, setOpenSettingColor] = useState<boolean>(false);

  //state upload
  const [advertisementBanner, setAdvertisementBanner] = useState<any>(
    defaultData.advertisement_banner
  );

  const [wallpaper, setWallpaper] = useState<any>(defaultData.wallpaper);
  const [wallpaperVideo, setWallpaperVideo] = useState<any>(
    defaultData.wallpaperVideo
  );
  const [wallpaperLinkClick, setWallpaperLinkClick] = useState<any>(
    defaultData.wallpaperLinkClick
  );

  useEffect(() => {
    if (dataEvent.wallpaperLinkClick) {
      setWallpaperLinkClick(dataEvent.wallpaperLinkClick);
    }
  }, [dataEvent.wallpaperLinkClick]);

  useEffect(() => {
    if (dataEvent.wallpaperVideo) {
      setWallpaperVideo(dataEvent.wallpaperVideo);
    }
  }, [dataEvent.wallpaperVideo]);

  const [organizationalLeft, setOrganizationalLeft] = useState<any>(
    defaultData.organizational_units.left_icon
  );
  const [organizationalRight, setOrganizationalRight] = useState<any>(
    defaultData.organizational_units.right_icon
  );
  const [guest, setGuest] = useState<any>(defaultData.guest.content);
  const [sponsors, setSponsors] = useState<any>(defaultData.sponsors.content);
  const [companions, setCompanions] = useState<any>(
    defaultData.companions.content
  );
  const [survey, setSurvey] = useState<any>(defaultData.surveys);
  const [docState, setDocState] = useState<any>(defaultData.doc.content);
  const [videoState, setVideoState] = useState<any>(defaultData.video.content);
  const [listIconConnect, setListIconConnect] = useState<any>(
    defaultData.listIconConnect
  );

  //review
  const [commentsFieldState, setCommentsFieldState] = useState<any>(
    defaultData.commentsField
  );

  const checkManagersSection = (section: string) => {
    if (
      !(
        managersSections.includes("all") || managersSections.includes(section)
      ) &&
      isEdit &&
      !isOwnerEventOrAdmin
    ) {
      return true;
    } else {
      return false;
    }
  };

  const setFile = (
    f: any,
    id: string,
    key?: string,
    index?: number,
    idGlobal?: string
  ) => {
    const updateState = async (setState) => {
      if (key === "survey") {
        setState((prev) =>
          prev.map((el: any, idx: number) =>
            index === idx
              ? {
                  ...el,
                  content: survey[index].content.map((item: any) =>
                    item.id === id
                      ? {
                          ...item,
                          file: f,
                          isNew: true,
                        }
                      : item
                  ),
                }
              : el
          )
        );
      } else if (key === "advertisement_banner") {
        setState((prev) => ({
          ...prev,
          [id]: {
            ...prev[id],
            file: f,
            isNew: true,
          },
        }));
      } else if (key === "content") {
        setState((prev) => ({
          ...prev,
          others: prev?.others?.map((el) =>
            el.id === id
              ? {
                  ...el,
                  file: f,
                  isNew: true,
                }
              : el
          ),
        }));
      } else if (key === "detail") {
        const formData = new FormData();
        formData.append("files", f);
        const res: any = await APIs.UPLOAD.uploadFile({
          body: formData,
        });
        setState((prev) =>
          prev.map((el: any, idx: number) =>
            index === idx
              ? {
                  ...el,
                  detail: el?.detail?.map((item: any) =>
                    item.id === idGlobal
                      ? {
                          ...item,
                          others: item.others.map((ot) =>
                            ot.id === id
                              ? {
                                  ...ot,
                                  src: res.filePath,
                                }
                              : ot
                          ),
                        }
                      : item
                  ),
                }
              : el
          )
        );
      } else {
        setState((prev) =>
          prev.map((el: any) =>
            el.id === id
              ? {
                  ...el,
                  file: f,
                  isNew: true,
                }
              : el
          )
        );
      }
    };

    if (key === "organizational_units_left") {
      updateState(setOrganizationalLeft);
    } else if (key === "organizational_units_right") {
      updateState(setOrganizationalRight);
    } else if (key === "guest") {
      updateState(setGuest);
    } else if (key === "sponsors") {
      updateState(setSponsors);
    } else if (key === "companions") {
      updateState(setCompanions);
    } else if (key === "survey") {
      updateState(setSurvey);
    } else if (key === "content") {
      updateState(setListLinkIcon);
    } else if (key === "detail") {
      updateState(setSurvey);
    } else if (key === "icon_connect") {
      updateState(setListIconConnect);
    } else if (key === "advertisement_banner") {
      updateState(setAdvertisementBanner);
    } else {
      // console.log();
    }
  };

  const openTagWindow = (url: string) => {
    if (!isEdit) {
      window.open(url);
    }
  };

  const handleSectionSurvey = (type: string) => {
    if (type === "add") {
      setSurvey(
        survey.concat({
          id: uuidv4(),
          title: "Khảo sát",
          content: [
            {
              id: uuidv4(),
              src: "",
              urlVideo: "https://www.youtube.com/watch?v=Aq6W-nmRO94",
              name: "",
              slide: [],
              listIcon: {},
              checked: [],
            },
          ],
          detail: [],
          type: "image_video",
          limit: 1,
        })
      );
    } else {
      const newArr = survey.slice(0, survey.length - 1);
      setSurvey(newArr);
    }
  };

  const handleAccept = async () => {
    let listIconUpload = listLinkIcon.others;
    if (
      listLinkIcon.others?.length > 0 &&
      listLinkIcon.others?.filter((it) => it.file)?.length > 0
    ) {
      const formData = new FormData();
      formData.append("folderPath", `event`);

      const statesNew = listLinkIcon.others
        .filter((st) => st.isNew)
        .map((stNew) => {
          formData.append("files", stNew.file);
          return stNew;
        });

      const listImg: any = await APIs.UPLOAD.uploadMultiFile({
        body: formData,
      });
      listImg.forEach((el) => {
        statesNew[el.id] = {
          ...statesNew[el.id],
          src: el?.filePath,
        };
      });

      listIconUpload = listLinkIcon.others.map((st) => {
        const newStFound = statesNew.find((c) => c.id === st.id);
        if (newStFound) {
          return {
            ...st,
            ...newStFound,
          };
        }
        return st;
      });
    }
    setSurvey((prev) =>
      prev.map((el: any, index: number) =>
        index === indexStateChange.idx
          ? {
              ...el,
              content: el.content.map((item) =>
                item.id === indexStateChange.id
                  ? {
                      ...item,
                      listIcon: {
                        ...listLinkIcon,
                        others: listIconUpload,
                      },
                    }
                  : item
              ),
            }
          : el
      )
    );
    setIsModalLinkSurvey(false);
  };

  const handleAddOrRemoveChil = (
    chil: string,
    type: string,
    index?: number,
    key?: string,
    uuid?: string
  ) => {
    const updateState = (state, setState) => {
      if (type === "add") {
        if (chil === "guest") {
          setState(
            state.concat({
              id: uuidv4(),
              src: "",
              position: "Tổng giám đốc",
              company: "Vzone Global",
              link: "",
            })
          );
        } else {
          if (chil === "list_survey") {
            setState((prev) =>
              prev.map((el: any, idx: number) =>
                index === idx
                  ? {
                      ...el,
                      [key]: el[key].concat(
                        key === "content"
                          ? {
                              id: uuidv4(),
                              src: "",
                              name: "",
                              urlVideo:
                                "https://www.youtube.com/watch?v=Aq6W-nmRO94",
                              slide: [],
                              listIcon: {},
                              checked: [],
                            }
                          : {
                              id: uuidv4(),
                              name: "",
                              checked: [],
                            }
                      ),
                    }
                  : el
              )
            );
          } else if (chil === "icon_survey_others") {
            if (key === "content") {
              setState((prev) => ({
                ...prev,
                others: prev.others.concat({
                  id: uuidv4(),
                  src: "",
                  link: "",
                }),
              }));
            } else {
              setState((prev) =>
                prev.map((el: any, idx: number) =>
                  index === idx
                    ? {
                        ...el,
                        detail: el?.detail?.map((q: any) =>
                          q.id === uuid
                            ? {
                                ...q,
                                others: (q?.others || []).concat({
                                  id: uuidv4(),
                                  src: "",
                                  link: "",
                                }),
                              }
                            : q
                        ),
                      }
                    : el
                )
              );
            }
          } else {
            setState(
              state.concat({
                id: uuidv4(),
                src: "",
                link: "",
              })
            );
          }
        }
      } else {
        if (chil === "list_survey") {
          const newArr = state[index][key].slice(
            0,
            state[index][key].length - 1
          );
          setState((prev) =>
            prev.map((el: any, idx: number) =>
              index === idx
                ? {
                    ...el,
                    [key]: newArr,
                  }
                : el
            )
          );
        } else if (chil === "icon_survey_others") {
          if (key === "content") {
            setState((prev) => ({
              ...prev,
              others: prev.others.slice(0, prev.others.length - 1),
            }));
          } else {
            setState((prev) =>
              prev.map((el: any, idx: number) =>
                index === idx
                  ? {
                      ...el,
                      detail: el?.detail?.map((q: any) =>
                        q.id === uuid
                          ? {
                              ...q,
                              others: (q?.others || []).slice(
                                0,
                                q?.others?.length - 1
                              ),
                            }
                          : q
                      ),
                    }
                  : el
              )
            );
          }
        } else {
          const newArr = state.slice(0, state.length - 1);
          setState(newArr);
        }
      }
    };

    if (chil === "organizational_units_left") {
      updateState(organizationalLeft, setOrganizationalLeft);
    } else if (chil === "organizational_units_right") {
      updateState(organizationalRight, setOrganizationalRight);
    } else if (chil === "guest") {
      updateState(guest, setGuest);
    } else if (chil === "sponsors") {
      updateState(sponsors, setSponsors);
    } else if (chil === "companions") {
      updateState(companions, setCompanions);
    } else if (chil === "icon_connect") {
      updateState(listIconConnect, setListIconConnect);
    } else if (chil === "list_survey") {
      updateState(survey, setSurvey);
    } else if (chil === "icon_survey_others") {
      if (key === "content") {
        updateState(listLinkIcon, setListLinkIcon);
      } else {
        updateState(survey, setSurvey);
      }
    }
  };

  const handleSubSectionSurvey = (id: string) => {
    setSurvey((prev) => prev.filter((el) => el.id !== id));
  };

  const removeChil = (
    chil: string,
    uuid: string,
    index?: number,
    key?: string
  ) => {
    if (chil === "bannerAds") {
      setAdvertisementBanner((prev) => ({
        ...prev,
        [key]: prev?.[key].filter((el: any) => el.id !== uuid),
      }));
    } else if (chil === "list_survey") {
      const newArr = survey[index].content.filter((el: any) => el.id !== uuid);
      setSurvey((prev) =>
        prev.map((el: any, idx: number) =>
          index === idx
            ? {
                ...el,
                content: newArr,
              }
            : el
        )
      );
    }
  };

  const handleMoveChil = (
    index: number,
    type: string,
    key: string,
    indexGlobal?: number
  ) => {
    const moveElement = (state, setState) => {
      const newArr =
        type === "list_survey" ? [...state[indexGlobal]?.content] : [...state];
      const el = newArr[index];

      if (key === "upward") {
        newArr[index] = newArr[index - 1];
        newArr[index - 1] = el;
      } else {
        newArr[index] = newArr[index + 1];
        newArr[index + 1] = el;
      }

      if (type === "list_survey") {
        setState((prev) =>
          prev.map((el: any, idx: number) =>
            indexGlobal === idx
              ? {
                  ...el,
                  content: newArr,
                }
              : el
          )
        );
      } else {
        setState(newArr);
      }
    };
    if (type === "list_survey") {
      moveElement(survey, setSurvey);
    } else {
      // console.log();
    }
  };

  const onBlurText = (
    e: any,
    key?: string,
    index?: number,
    type?: string,
    uuid?: string,
    name?: string
  ) => {
    if (!key) {
      setDataEvent((prev) => ({
        ...prev,
        [e.target.getAttribute("data-key")]: e.target.innerText,
      }));
    } else if (key === "list_survey") {
      if (type === "title" || type === "limit") {
        const newArr: any = [...survey];
        newArr[index] = {
          ...survey[index],
          [type]: type === "title" ? e.target.innerText : e.target.value,
        };
        setSurvey(newArr);
      } else {
        if (isModalLinkSurvey.type === "icon") {
          setListLinkIcon((prev) => ({
            ...prev,
            [type]: e.target.value,
          }));
        } else {
          setSurvey((prev) =>
            prev.map((el: any, idx: number) =>
              index === idx
                ? {
                    ...el,
                    [name]: el?.[name]?.map((item: any) =>
                      item.id === uuid
                        ? { ...item, [type]: e.target.value }
                        : item
                    ),
                  }
                : el
            )
          );
        }
      }
    } else if (key === "list_survey_others") {
      if (type === "content") {
        setListLinkIcon((prev) => ({
          ...prev,
          others: prev.others.map((el) =>
            el.id === uuid
              ? {
                  ...el,
                  link: e.target.value,
                }
              : el
          ),
        }));
      } else {
        setSurvey((prev) =>
          prev.map((el: any, idx: number) =>
            index === idx
              ? {
                  ...el,
                  detail: el?.detail?.map((item: any) =>
                    item.id === name
                      ? {
                          ...item,
                          others: item?.others.map((ot) =>
                            ot.id === uuid
                              ? {
                                  ...ot,
                                  link: e.target.value,
                                }
                              : ot
                          ),
                        }
                      : item
                  ),
                }
              : el
          )
        );
      }
    } else {
      setDataEvent((prev) => ({
        ...prev,
        [key]: {
          ...prev[key],
          title: e.target.innerText,
        },
      }));
    }
  };

  const onBlurChil = (e: any, index: number, type: string) => {
    const newArr: any = guest;
    newArr[index] = {
      ...guest[index],
      [type]: e.target.innerText,
    };
    setGuest(newArr);
  };

  const onBlurDocument = (
    e: any,
    type: string,
    uuid?: string,
    key?: string
  ) => {
    const updateState = (setState) => {
      setState((prev) =>
        prev.map((el) =>
          el.id === uuid
            ? {
                ...el,
                link: e.target.value,
              }
            : el
        )
      );
    };
    if (type === "organizational_units_left") {
      updateState(setOrganizationalLeft);
    } else if (type === "organizational_units_right") {
      updateState(setOrganizationalRight);
    } else if (type === "guest") {
      updateState(setGuest);
    } else if (type === "sponsors") {
      updateState(setSponsors);
    } else if (type === "companions") {
      updateState(setCompanions);
    } else if (type === "icon_connect") {
      updateState(setListIconConnect);
    } else if (type === "list_survey") {
      setDataEvent((prev) => ({
        ...prev,
        survey: {
          ...prev.survey,
          detail: prev.survey.detail.map((el) =>
            el.id === uuid
              ? {
                  ...el,
                  [key]: e.target.value,
                }
              : el
          ),
        },
      }));
    } else {
      // console.log('warning');
    }
  };

  const onChangeChecked = (
    checked: any,
    index: number,
    uuid: string,
    key: string
  ) => {
    if (checked) {
      setSurvey((prev) =>
        prev.map((el: any, idx: number) =>
          idx === index
            ? {
                ...el,
                [key]: el[key]?.map((item) =>
                  item.id === uuid
                    ? {
                        ...item,
                        checked: item?.checked.concat(user?._id),
                      }
                    : item
                ),
              }
            : el
        )
      );
    } else {
      setSurvey((prev) =>
        prev.map((el: any, idx: number) =>
          idx === index
            ? {
                ...el,
                [key]: el[key]?.map((item) =>
                  item.id === uuid
                    ? {
                        ...item,
                        checked: item?.checked.filter(
                          (check) => check !== user?._id
                        ),
                      }
                    : item
                ),
              }
            : el
        )
      );
    }
  };

  const handleChooseTypeSurvey = (checked: any, index: number, key: string) => {
    if (checked) {
      setSurvey((prev) =>
        prev.map((el: any, idx: number) =>
          idx === index
            ? {
                ...el,
                type: key,
              }
            : el
        )
      );
    } else {
      // console.log();
    }
  };

  const handleSurvey = async (type: string, data?: any) => {
    try {
      setLoading(true);
      const token = getToken();
      const key = type === "image_video" ? "content" : "detail";
      let value = [];
      await Promise.all(
        survey.map((el) => {
          el[key]?.map((item) => {
            if (item.checked.includes(user?._id)) {
              value = value.concat(item.id);
            }
          });
        })
      );
      const res: any = await APIs.EVENT.updateSurveyEvent({
        params: {
          id: id,
        },
        body: {
          survey: value,
          type: key,
          email: data?.email || null,
          fullname: data?.fullname || null,
          phone: data?.phone || null,
        },
        options: {
          headers: {
            Authorization: `Beare ${token}`,
          },
        },
      });
      if (res) {
        setLoading(false);
        toast.success("Successfull!");
        await localStorage.setItem("interactive-survey", res.token);
        setOpenModalInteractive({
          open: false,
          key: null,
        });
        getEventById();
      } else {
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.errors || "Something went wrong!");
      setLoading(false);
    }
  };

  const interactive = async (type: string, data?: any) => {
    try {
      setLoading(true);
      if (type === "to_share") {
        const route = getEventRoute({ event: dataEvent }, "root");
        copyText(`${domain}${route}/${id}`);
      }

      const token = getToken();

      const res: any = await APIs.EVENT.updateInteractiveEvent({
        params: {
          id: id,
        },
        body: {
          type: type,
          email: data?.email || null,
          fullname: data?.fullname || null,
          phone: data?.phone || null,
        },
        options: {
          headers: {
            Authorization: `Beare ${token}`,
          },
        },
      });
      if (res) {
        setLoading(false);
        toast.success("Successfull!");
        await localStorage.setItem("interactive-survey", res.token);
        setOpenModalInteractive({
          open: false,
          key: null,
        });
        getEventById();
      } else {
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.errors || "Something went wrong!");
      setLoading(false);
    }
  };

  const handleAddIconContent = (listLink: any, uuid: string, index: number) => {
    setIsModalLinkSurvey({
      open: true,
      type: "icon",
    });
    setIndexStateChange({
      idx: index,
      id: uuid,
    });
    setListLinkIcon({
      ...listLink,
      others: listLink.others || [],
    });
  };

  const onChangeDataSurveyBanner = (data, index: number, uuid: string) => {
    setSurvey((prev) =>
      prev.map((el: any, idx: number) =>
        idx === index
          ? {
              ...el,
              content: el.content.map((item) =>
                item.id === uuid
                  ? {
                      ...item,
                      slide: data.slide,
                      urlVideo: data.link,
                    }
                  : item
              ),
            }
          : el
      )
    );
  };

  const handleInteractiveOrSurvey = async (type: string, key: string) => {
    setTypeInteractive(type);
    const token = getToken();
    if (!token) {
      setOpenModalInteractive({
        open: true,
        key: key,
      });
    } else {
      if (key === "interactive") {
        interactive(type);
      } else {
        handleSurvey(type);
      }
    }
  };

  const confirmInterForm = async (data) => {
    if (openModalInteractive.key === "interactive") {
      interactive(typeInteractive, data);
    } else {
      handleSurvey(typeInteractive, data);
    }
  };

  const handleSubmitEvent = async () => {
    try {
      setLoading(true);

      delete dataEvent._id;

      let bannerUploaded = dataEvent.banner;
      let leftOrgUploaded = organizationalLeft;
      let rightOrgUploaded = organizationalRight;
      let guestUploaded = guest;
      let sponsorsUploaded = sponsors;
      let companionsUploaded = companions;
      let docsUploaded = docState;
      let surveyUploaded = survey;
      let iconsUploaded = listIconConnect;
      let advertisementBannerUploadLeft = advertisementBanner?.left;
      let advertisementBannerUploadRight = advertisementBanner?.right;
      let wallpaperUploaded = dataEvent.wallpaper;

      const uploadSectionImg = async (state: any, uploadedState: any) => {
        try {
          if (uploadedState === "banner") {
            if (state.imageBottom?.file) {
              const formData = new FormData();
              formData.append("folderPath", `event`);
              formData.append("files", state.imageBottom.file);

              const img: any = await APIs.UPLOAD.uploadFile({
                body: formData,
              });
              bannerUploaded = {
                ...dataEvent.banner,
                imageBottom: {
                  src: img?.filePath,
                },
              };
            }
          } else if (uploadedState !== "surveyUploaded") {
            if (
              state?.length > 0 &&
              state?.filter((it) => it.file)?.length > 0
            ) {
              const formData = new FormData();
              formData.append("folderPath", `event`);

              const statesNew = state
                .filter((st) => st.isNew && st.file)
                .map((stNew) => {
                  formData.append("files", stNew.file);
                  return stNew;
                });

              const listImg: any = await APIs.UPLOAD.uploadMultiFile({
                body: formData,
              });

              listImg.forEach((el) => {
                statesNew[el.id] = {
                  ...statesNew[el.id],
                  src: el?.filePath,
                };
              });
              if (uploadedState === "bannerAdsLeft") {
                advertisementBannerUploadLeft = state.map((st) => {
                  const newStFound = statesNew.find((c) => c.id === st.id);
                  if (newStFound) {
                    return {
                      ...st,
                      ...newStFound,
                    };
                  }
                  return st;
                });
              } else if (uploadedState === "bannerAdsRight") {
                advertisementBannerUploadRight = state.map((st) => {
                  const newStFound = statesNew.find((c) => c.id === st.id);
                  if (newStFound) {
                    return {
                      ...st,
                      ...newStFound,
                    };
                  }
                  return st;
                });
              } else if (uploadedState === "leftOrgUploaded") {
                leftOrgUploaded = state.map((st) => {
                  const newStFound = statesNew.find((c) => c.id === st.id);
                  if (newStFound) {
                    return {
                      ...st,
                      ...newStFound,
                    };
                  }
                  return st;
                });
              } else if (uploadedState === "rightOrgUploaded") {
                rightOrgUploaded = state.map((st) => {
                  const newStFound = statesNew.find((c) => c.id === st.id);
                  if (newStFound) {
                    return {
                      ...st,
                      ...newStFound,
                    };
                  }
                  return st;
                });
              } else if (uploadedState === "guestUploaded") {
                guestUploaded = state.map((st) => {
                  const newStFound = statesNew.find((c) => c.id === st.id);
                  if (newStFound) {
                    return {
                      ...st,
                      ...newStFound,
                    };
                  }
                  return st;
                });
              } else if (uploadedState === "sponsorsUploaded") {
                sponsorsUploaded = state.map((st) => {
                  const newStFound = statesNew.find((c) => c.id === st.id);
                  if (newStFound) {
                    return {
                      ...st,
                      ...newStFound,
                    };
                  }
                  return st;
                });
              } else if (uploadedState === "companionsUploaded") {
                companionsUploaded = state.map((st) => {
                  const newStFound = statesNew.find((c) => c.id === st.id);
                  if (newStFound) {
                    return {
                      ...st,
                      ...newStFound,
                    };
                  }
                  return st;
                });
              } else if (uploadedState === "docsUploaded") {
                docsUploaded = state.map((st) => {
                  const newStFound = statesNew.find((c) => c.id === st.id);
                  if (newStFound) {
                    return {
                      ...st,
                      ...newStFound,
                    };
                  }
                  return st;
                });
              } else if (uploadedState === "iconsUploaded") {
                iconsUploaded = state.map((st) => {
                  const newStFound = statesNew.find((c) => c.id === st.id);
                  if (newStFound) {
                    return {
                      ...st,
                      ...newStFound,
                    };
                  }
                  return st;
                });
              } else if (uploadedState === "surveyIconsDetailUploaded") {
                iconsUploaded = state.map((st) => {
                  const newStFound = statesNew.find((c) => c.id === st.id);
                  if (newStFound) {
                    return {
                      ...st,
                      ...newStFound,
                    };
                  }
                  return st;
                });
              } else {
              }
            }
          } else {
            if (
              state?.length > 0 &&
              state.map(
                (item) =>
                  item.content.filter((st) => st.isNew && st.file)?.length > 0
              )
            ) {
              const formData = new FormData();
              formData.append("folderPath", `event`);

              const statesNew = state.map((item) =>
                item.content
                  .filter((st) => st.isNew && st.file)
                  .map((stNew) => {
                    formData.append("files", stNew.file);
                    return stNew;
                  })
              );
              let newData = [];
              statesNew.forEach((item) => {
                item.map((el) => {
                  newData = [...newData, el];
                });
              });

              if (newData.length > 0) {
                const listImg: any = await APIs.UPLOAD.uploadMultiFile({
                  body: formData,
                });

                listImg.forEach((el) => {
                  newData[el.id] = {
                    ...newData[el.id],
                    src: el?.filePath,
                  };
                });

                surveyUploaded = state.map((st) => {
                  const newStFound = st.content.map((item) => {
                    const newStF = newData.find((c) => c.id === item.id);
                    if (newStF) {
                      return {
                        ...item,
                        ...newStF,
                      };
                    }
                    return item;
                  });
                  return {
                    ...st,
                    content: newStFound,
                  };
                });
              } else {
                // console.log()
              }
            }
          }
        } catch (err) {
          console.log("ERROR upload", err, {
            state,
            uploadedState,
          });
        }
      };

      const uploadWallpaper = async () => {
        if (wallpaper) {
          const formData = new FormData();
          formData.append("folderPath", `event`);
          formData.append("files", wallpaper);

          const img: any = await APIs.UPLOAD.uploadFile({
            body: formData,
          });

          wallpaperUploaded = img?.filePath;
        }
      };

      await uploadSectionImg(dataEvent.banner, "banner");
      await uploadSectionImg(organizationalLeft, "leftOrgUploaded");
      await uploadSectionImg(organizationalRight, "rightOrgUploaded");
      await uploadSectionImg(guest, "guestUploaded");
      await uploadSectionImg(sponsors, "sponsorsUploaded");
      await uploadSectionImg(companions, "companionsUploaded");
      await uploadSectionImg(docState, "docsUploaded");
      await uploadSectionImg(survey, "surveyUploaded");
      await uploadSectionImg(listIconConnect, "iconsUploaded");
      await uploadSectionImg(advertisementBanner?.left, "bannerAdsLeft");
      await uploadSectionImg(advertisementBanner?.right, "bannerAdsRight");
      await uploadWallpaper();

      let data = {
        ...dataEvent,
        banner: bannerUploaded,
        advertisement_banner: {
          left: advertisementBannerUploadLeft,
          right: advertisementBannerUploadRight,
        },
        organizational_units: {
          ...dataEvent.organizational_units,
          left_icon: leftOrgUploaded,
          right_icon: rightOrgUploaded,
        },
        guest: {
          ...dataEvent.guest,
          content: guestUploaded,
        },
        sponsors: {
          ...dataEvent.sponsors,
          content: sponsorsUploaded,
        },
        companions: {
          ...dataEvent.companions,
          content: companionsUploaded,
        },
        doc: {
          ...dataEvent.doc,
          content: docsUploaded,
        },
        surveys: surveyUploaded,
        video: {
          ...dataEvent.video,
          content: videoState,
        },
        listIconConnect: iconsUploaded,
        optionSection: optionSection,
        commentsField: commentsFieldState,
        wallpaper: wallpaperUploaded,
        wallpaperVideo: wallpaperVideo,
        wallpaperLinkClick: wallpaperLinkClick || dataEvent.wallpaperLinkClick,
      };

      const token = getToken();

      const smartEventDetailRoute = getEventRoute(
        { event: dataEvent },
        "detail"
      );

      if (!id) {
        const event: any = await APIs.EVENT.createEvent({
          body: data,
          options: {
            headers: {
              Authorization: `Beare ${token}`,
            },
          },
        });
        if (event) {
          setLoading(false);
          toast.success("Create successfull!");
          history.push(
            `${smartEventDetailRoute.replace(":id", event._id)}?token=${token}`
          );
        }
      } else {
        const event: any = await APIs.EVENT.updateEventById({
          params: {
            id: id,
          },
          body: data,
          options: {
            headers: {
              Authorization: `Beare ${token}`,
            },
          },
        });

        if (event) {
          setLoading(false);
          toast.success("Update successfull!");
          history.push(
            `${smartEventDetailRoute.replace(":id", event._id)}?token=${token}`
          );
        }
      }
    } catch (error) {
      toast.error(error.errors || "Something went wrong!");
      setLoading(false);
    }
  };

  const getEventById = async () => {
    try {
      const data: any = await APIs.EVENT.getEventById({
        params: {
          id: id,
        },
      });

      if (data) {
        if (user) {
          const dataManagersSection =
            data.eventManagersWithSections?.[user._id] || [];
          setManagersSections(dataManagersSection || []);
          setIsOwnerEventOrAdmin(
            String(data.user_id) === String(user._id) || user.role === "ADMIN"
          );
        }
        const event = data.event;
        setDataEvent({
          ...defaultData,
          ...event,
          _id: id,
        });
        if (
          event.advertisement_banner &&
          Array.isArray(event.advertisement_banner?.left) &&
          Array.isArray(event.advertisement_banner?.right)
        ) {
          setAdvertisementBanner(event.advertisement_banner);
        }
        if (event.organizational_units) {
          if (event.organizational_units.left_icon) {
            setOrganizationalLeft(
              event.organizational_units.left_icon.map((left) => ({
                ...left,
                id: uuidv4(),
                isNew: false,
              }))
            );
          }
          if (event.organizational_units.right_icon) {
            setOrganizationalRight(
              event.organizational_units.right_icon.map((right) => ({
                ...right,
                id: uuidv4(),
                isNew: false,
              }))
            );
          }
        }
        if (event.guest?.content) {
          setGuest(
            event.guest.content.map((item) => ({
              ...item,
              id: uuidv4(),
              isNew: false,
            }))
          );
        }
        if (event.sponsors?.content) {
          setSponsors(
            event.sponsors.content.map((item) => ({
              ...item,
              id: uuidv4(),
              isNew: false,
            }))
          );
        }
        if (event.companions?.content) {
          setCompanions(
            event.companions.content.map((item) => ({
              ...item,
              id: uuidv4(),
              isNew: false,
            }))
          );
        }
        if (event.doc?.content) {
          setDocState(
            event.doc.content.map((item) => ({
              ...item,
              id: uuidv4(),
              isNew: false,
            }))
          );
        }
        if (event.surveys) {
          setSurvey(
            event.surveys.map((sur) =>
              sur.id
                ? {
                    ...sur,
                    content: sur.content.map((el) => ({
                      ...el,
                      slide: el.slide
                        ? el.slide.map((item) => ({ ...item, isNew: false }))
                        : [
                            {
                              src: el.src,
                              id: uuidv4(),
                              isNew: false,
                            },
                          ],
                    })),
                    detail: sur.detail.sort(
                      (a: any, b: any) => b.checked?.length - a.checked?.length
                    ),
                  }
                : {
                    ...sur,
                    id: uuidv4(),
                    content: sur.content.map((el) => ({
                      ...el,
                      slide: el.slide
                        ? el.slide.map((item) => ({ ...item, isNew: false }))
                        : [
                            {
                              src: el.src,
                              id: uuidv4(),
                              isNew: false,
                            },
                          ],
                    })),
                  }
            )
          );
        }
        if (event.video) {
          setVideoState(event.video.content);
        }
        if (event.listIconConnect) {
          setListIconConnect(
            event.listIconConnect.map((item) => ({
              ...item,
              id: uuidv4(),
              isNew: false,
            }))
          );
        }
        if (event.optionSection) {
          setOptionSection({
            ...optionSectionDefault,
            ...event.optionSection,
          });
        }
        if (event.commentsField) {
          setCommentsFieldState(event.commentsField);
        }
      } else {
        // setManagersSections(['all'])
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const onChangeColor = (e: any, type: string) => {
    setDataEvent((prev) => ({
      ...prev,
      color: {
        ...prev.color,
        [type]: e.target.value,
      },
    }));
  };

  const renderModalFrom = () => {
    return (
      <FormInfomation control={control} errors={errors} setValue={setValue} />
    );
  };

  useEffect(() => {
    const { isEdit } = queryString.parse(location.search);
    if (isEdit === "true" || !id) {
      setEditMode(true);
      if (!id) {
        setManagersSections(["all"]);
      }
    } else {
      setEditMode(false);
      setManagersSections(["all"]);
    }
    if (id) {
      getEventById();
    }
  }, [id, location]);

  const hasWallpaper = Boolean(
    dataEvent.wallpaper || wallpaper || wallpaperVideo
  );

  const backgroundOpacity =
    Number(dataEvent.color.backgroundOpacity || "1") * 100;
  const opacityHex = percentToHex(backgroundOpacity);
  const backgroundLogos = dataEvent.color.backgroundLogos
    ? dataEvent.color.backgroundLogos + opacityHex
    : "";
  const background1Color = dataEvent?.color?.background1
    ? dataEvent?.color?.background1 + opacityHex
    : "";
  const background2Color = dataEvent?.color?.background2
    ? dataEvent?.color?.background2 + opacityHex
    : "";

  const logosAds = dataEvent?.banner?.listLogos;

  return (
    <Wrapper bgColor={background1Color} hasWallpaper={hasWallpaper}>
      {logosAds?.length > 0 && (
        <LogosAds logosAds={logosAds} backgroundLogos={backgroundLogos} />
      )}

      {/* <Modal isOpen={showFirstStep && isEdit} centered={true}>
        <FirstStepForm
          dataEvent={dataEvent}
          onSubmit={(data) => {
            setDataEvent({
              ...dataEvent,
              ...data,
            });
            setShowFirstStep(false);
          }}
        />
      </Modal> */}

      <BannerPage
        page={"event"}
        isEdit={isEdit}
        user={user}
        dataPage={dataEvent}
        advertisementBanner={advertisementBanner}
        setDataPage={setDataEvent}
        setAdvertisementBanner={setAdvertisementBanner}
        hasWallpaper={hasWallpaper}
        wallpaperLinkClick={wallpaperLinkClick}
      />

      {optionSection.title.isShow && (
        <TitleCheckinSection
          id={id}
          isEdit={isEdit}
          dataEvent={dataEvent}
          hasWallpaper={hasWallpaper}
          background2Color={background2Color}
          onBlurText={onBlurText}
          handleInteractiveOrSurvey={handleInteractiveOrSurvey}
          user={user}
          domain={domain}
          setDataEvent={setDataEvent}
        />
      )}

      {optionSection.organization.isShow && (
        <Section
          hasWallpaper={hasWallpaper}
          bgColor={background2Color}
          isDisable={checkManagersSection("organization")}
        >
          {
            <Title
              mb={"30px"}
              type={
                organizationalLeft.length > 0 && organizationalRight.length > 0
                  ? "organizational"
                  : ""
              }
              color={dataEvent.color.title}
            >
              <h4
                contentEditable={isEdit}
                onBlur={(e) => onBlurText(e, "organizational_units")}
              >
                {dataEvent.organizational_units.title.toUpperCase()}
              </h4>
            </Title>
          }
          <Content>
            <ItemFlex
              just_content={
                organizationalLeft.length > 0 && organizationalRight.length > 0
                  ? "space-between"
                  : "center"
              }
              alignItem="center"
            >
              <Organization
                width={
                  organizationalLeft.length > 0 &&
                  organizationalRight.length > 0
                    ? "38%"
                    : "100%"
                }
                type={
                  organizationalLeft.length > 0 &&
                  organizationalRight.length > 0
                    ? "organizational"
                    : ""
                }
              >
                {isEdit && (
                  <AddOrRemoveChil mt="-20px">
                    <div
                      onClick={() =>
                        handleAddOrRemoveChil(
                          "organizational_units_left",
                          "add"
                        )
                      }
                    >
                      +
                    </div>
                    <div
                      onClick={() =>
                        handleAddOrRemoveChil(
                          "organizational_units_left",
                          "remove"
                        )
                      }
                    >
                      -
                    </div>
                  </AddOrRemoveChil>
                )}
                <ItemFlex just_content="space-around" alignItem="center">
                  {organizationalLeft.length > 0 &&
                    organizationalLeft.map((item, key) => (
                      <ContainerItem>
                        <OrganizationItem
                          key={key}
                          onClick={() =>
                            !isEdit ? openTagWindow(item?.link) : ""
                          }
                        >
                          {isEdit ? (
                            <Upload>
                              <UploadFile
                                id={item.id}
                                ratio="150x150"
                                sectionName="organizational_units_left"
                                file={item.file}
                                src={item.src}
                                setFile={setFile}
                              />
                            </Upload>
                          ) : (
                            <img
                              src={item.src || "/assets/images/vzx-64.png"}
                              alt=""
                            />
                          )}
                        </OrganizationItem>
                        {isEdit && (
                          <Input
                            transparent={true}
                            color={"orange"}
                            defaultValue={item?.link}
                            onBlur={(e) =>
                              onBlurDocument(
                                e,
                                "organizational_units_left",
                                item.id
                              )
                            }
                            placeholder="Link"
                          />
                        )}
                      </ContainerItem>
                    ))}
                </ItemFlex>
              </Organization>
              {
                <TitleOrg
                  isShow={
                    organizationalLeft.length > 0 &&
                    organizationalRight.length > 0
                  }
                  color={dataEvent.color.title}
                >
                  <h4
                    contentEditable={isEdit}
                    onBlur={(e) => onBlurText(e, "organizational_units")}
                  >
                    {dataEvent.organizational_units.title.toUpperCase()}
                  </h4>
                </TitleOrg>
              }
              <Organization
                width={
                  organizationalLeft.length > 0 &&
                  organizationalRight.length > 0
                    ? "38%"
                    : "100%"
                }
                type={"organizational"}
              >
                {isEdit && (
                  <AddOrRemoveChil mt="-20px">
                    <div
                      onClick={() =>
                        handleAddOrRemoveChil(
                          "organizational_units_right",
                          "add"
                        )
                      }
                    >
                      +
                    </div>
                    <div
                      onClick={() =>
                        handleAddOrRemoveChil(
                          "organizational_units_right",
                          "remove"
                        )
                      }
                    >
                      -
                    </div>
                  </AddOrRemoveChil>
                )}
                <ItemFlex just_content="space-around" alignItem="center">
                  {organizationalRight.length > 0 &&
                    organizationalRight.map((item, key) => (
                      <ContainerItem>
                        <OrganizationItem
                          key={key}
                          onClick={() =>
                            !isEdit ? openTagWindow(item?.link) : ""
                          }
                        >
                          {isEdit ? (
                            <Upload>
                              <UploadFile
                                id={item.id}
                                ratio="150x150"
                                sectionName="organizational_units_right"
                                file={item.file}
                                src={item.src}
                                setFile={setFile}
                              />
                            </Upload>
                          ) : (
                            <img
                              src={item.src || "/assets/images/vzx-64.png"}
                              alt=""
                            />
                          )}
                        </OrganizationItem>
                        {isEdit && (
                          <Input
                            transparent={true}
                            color={"orange"}
                            defaultValue={item?.link}
                            onBlur={(e) =>
                              onBlurDocument(
                                e,
                                "organizational_units_right",
                                item.id
                              )
                            }
                            placeholder="Link"
                          />
                        )}
                      </ContainerItem>
                    ))}
                </ItemFlex>
              </Organization>
            </ItemFlex>
          </Content>
        </Section>
      )}

      {optionSection.event_calendars.isShow && (
        <EventCalendars
          isEdit={isEdit}
          hasWallpaper={hasWallpaper}
          background2Color={background2Color}
          dataEvent={dataEvent}
          setDataEvent={setDataEvent}
        />
      )}

      {optionSection.guest.isShow && (
        <Section
          hasWallpaper={hasWallpaper}
          bgColor={background2Color}
          isDisable={checkManagersSection("guest")}
        >
          <Title mb={"30px"} color={dataEvent.color.title}>
            <h4 contentEditable={isEdit} onBlur={(e) => onBlurText(e, "guest")}>
              {dataEvent.guest.title.toUpperCase()}
            </h4>
          </Title>
          <Content>
            <ItemFlex just_content={"center"} alignItem="start">
              {isEdit && (
                <AddOrRemoveChil mt="-20px">
                  <div onClick={() => handleAddOrRemoveChil("guest", "add")}>
                    +
                  </div>
                  <div onClick={() => handleAddOrRemoveChil("guest", "remove")}>
                    -
                  </div>
                </AddOrRemoveChil>
              )}
              {guest.length > 0 &&
                guest.map((item, index) => (
                  <ContainerItem
                    onClick={() => (!isEdit ? openTagWindow(item?.link) : "")}
                  >
                    <Guest
                      isEdit={isEdit}
                      index={index}
                      info={item}
                      textColor={dataEvent.color.text}
                      onBlurChil={onBlurChil}
                      setFile={setFile}
                    />
                    {isEdit && (
                      <Input
                        transparent={true}
                        color={"orange"}
                        defaultValue={item?.link}
                        onBlur={(e) => onBlurDocument(e, "guest", item.id)}
                        placeholder="Link"
                      />
                    )}
                  </ContainerItem>
                ))}
            </ItemFlex>
          </Content>
        </Section>
      )}
      {optionSection.sponsor_companion.isShow && (
        <ItemFlex just_content="space-between" isColumn={true}>
          <Section
            hasWallpaper={hasWallpaper}
            type="item-flex"
            bgColor={background2Color}
            isDisable={checkManagersSection("sponsor_companion")}
          >
            <Title mb="30px" color={dataEvent.color.title}>
              <h4
                contentEditable={isEdit}
                onBlur={(e) => onBlurText(e, "sponsors")}
              >
                {dataEvent.sponsors.title.toUpperCase()}
              </h4>
            </Title>

            <Content>
              <Organization>
                {isEdit && (
                  <AddOrRemoveChil mt="-20px">
                    <div
                      onClick={() => handleAddOrRemoveChil("sponsors", "add")}
                    >
                      +
                    </div>
                    <div
                      onClick={() =>
                        handleAddOrRemoveChil("sponsors", "remove")
                      }
                    >
                      -
                    </div>
                  </AddOrRemoveChil>
                )}
                <ItemFlex just_content="center" alignItem="center">
                  {sponsors.length > 0 &&
                    sponsors.map((item, key) => (
                      <ContainerItem>
                        <OrganizationItem
                          key={key}
                          size="80px"
                          onClick={() =>
                            !isEdit ? openTagWindow(item?.link) : ""
                          }
                        >
                          {isEdit ? (
                            <Upload>
                              <UploadFile
                                id={item.id}
                                ratio="100x100"
                                sectionName="sponsors"
                                file={item.file}
                                src={item.src}
                                setFile={setFile}
                              />
                            </Upload>
                          ) : (
                            <img
                              src={item.src || "/assets/images/vzx-64.png"}
                              alt=""
                            />
                          )}
                        </OrganizationItem>
                        {isEdit && (
                          <Input
                            transparent={true}
                            color={"orange"}
                            defaultValue={item?.link}
                            onBlur={(e) =>
                              onBlurDocument(e, "sponsors", item.id)
                            }
                            placeholder="Link"
                          />
                        )}
                      </ContainerItem>
                    ))}
                </ItemFlex>
              </Organization>
            </Content>
          </Section>
          <Section
            hasWallpaper={hasWallpaper}
            type="item-flex"
            bgColor={background2Color}
            isDisable={checkManagersSection("sponsor_companion")}
          >
            <Title mb="30px" color={dataEvent.color.title}>
              <h4
                contentEditable={isEdit}
                onBlur={(e) => onBlurText(e, "companions")}
              >
                {dataEvent.companions.title.toUpperCase()}
              </h4>
            </Title>

            <Content>
              <Organization>
                {isEdit && (
                  <AddOrRemoveChil mt="-20px">
                    <div
                      onClick={() => handleAddOrRemoveChil("companions", "add")}
                    >
                      +
                    </div>
                    <div
                      onClick={() =>
                        handleAddOrRemoveChil("companions", "remove")
                      }
                    >
                      -
                    </div>
                  </AddOrRemoveChil>
                )}
                <ItemFlex just_content="center" alignItem="center">
                  {companions.length > 0 &&
                    companions.map((item, key) => (
                      <ContainerItem>
                        <OrganizationItem
                          key={key}
                          size="80px"
                          onClick={() =>
                            !isEdit ? openTagWindow(item?.link) : ""
                          }
                        >
                          {isEdit ? (
                            <Upload>
                              <UploadFile
                                id={item.id}
                                ratio="100x100"
                                sectionName="companions"
                                file={item.file}
                                src={item.src}
                                setFile={setFile}
                              />
                            </Upload>
                          ) : (
                            <img
                              src={item.src || "/assets/images/vzx-64.png"}
                              alt=""
                            />
                          )}
                        </OrganizationItem>
                        {isEdit && (
                          <Input
                            transparent={true}
                            color={"orange"}
                            defaultValue={item?.link}
                            onBlur={(e) =>
                              onBlurDocument(e, "companions", item.id)
                            }
                            placeholder="Link"
                          />
                        )}
                      </ContainerItem>
                    ))}
                </ItemFlex>
              </Organization>
            </Content>
          </Section>
        </ItemFlex>
      )}

      {optionSection.doc.isShow && (
        <DocumentsSection
          docState={docState}
          data_key={"doc"}
          title={dataEvent.doc.title}
          isEdit={isEdit && !checkManagersSection("video")}
          isDisable={checkManagersSection("doc")}
          hasWallpaper={hasWallpaper}
          color={{
            textColor: dataEvent.color.text,
            titleColor: dataEvent.color.title,
            bgColor: dataEvent.color.background2,
            bgDefault: "bgColorLayer1",
          }}
          setDocState={setDocState}
          onBlurText={onBlurText}
        />
      )}

      <br />
      {optionSection.video.isShow && (
        <VideoSection
          videoState={videoState}
          data_key={"video"}
          title={dataEvent.video.title}
          isEdit={isEdit && !checkManagersSection("video")}
          isDisable={checkManagersSection("video")}
          hasWallpaper={hasWallpaper}
          color={{
            textColor: dataEvent.color.text,
            titleColor: dataEvent.color.title,
            bgColor: dataEvent.color.background2,
            bgDefault: "bgColorLayer1",
          }}
          setVideoState={setVideoState}
          onBlurText={onBlurText}
        />
      )}
      {optionSection.survey.isShow && (
        <Section
          hasWallpaper={hasWallpaper}
          mb={"0"}
          bgColor={background2Color}
          isDisable={checkManagersSection("survey")}
        >
          {survey.length > 0 &&
            survey.map((el: any, index: number) => (
              <ItemSurveyDetail>
                <Title mb="30px" color={dataEvent.color.title}>
                  <h4
                    contentEditable={isEdit}
                    onBlur={(e) => onBlurText(e, "list_survey", index, "title")}
                  >
                    {el.title.toUpperCase()}
                  </h4>
                </Title>
                <Survey
                  user={user}
                  isEdit={isEdit}
                  type={el.type}
                  index={index}
                  idSurvey={el.id}
                  content={el.content}
                  detail={el.detail}
                  color={dataEvent.color}
                  limit={el.limit}
                  setIsModalLinkSurvey={setIsModalLinkSurvey}
                  handleChooseTypeSurvey={handleChooseTypeSurvey}
                  handleAddOrRemoveChil={handleAddOrRemoveChil}
                  removeChil={removeChil}
                  handleMoveChil={handleMoveChil}
                  setFile={setFile}
                  onChangeChecked={onChangeChecked}
                  handleAddIconContent={handleAddIconContent}
                  onChangeDataSurveyBanner={onChangeDataSurveyBanner}
                  onBlurText={onBlurText}
                  handleInteractiveOrSurvey={handleInteractiveOrSurvey}
                  handleSubSectionSurvey={handleSubSectionSurvey}
                />
              </ItemSurveyDetail>
            ))}
          {isEdit && (
            <AddSectionTeam>
              <Button onClick={() => handleSectionSurvey("add")}>+ Add</Button>
              <Button onClick={() => handleSectionSurvey("remove")}>
                - Remove
              </Button>
            </AddSectionTeam>
          )}
        </Section>
      )}
      {isEdit && optionSection.review.isShow && (
        <Section
          hasWallpaper={hasWallpaper}
          mb={"0"}
          bgColor={background2Color}
          textColor={"white"}
          isDisable={checkManagersSection("review")}
        >
          <FormCreateContributeIdeas
            state={commentsFieldState}
            setState={setCommentsFieldState}
          />
        </Section>
      )}
      {!isEdit &&
        optionSection.review.isShow &&
        dataEvent?.commentsField?.length > 0 && (
          <Section
            hasWallpaper={hasWallpaper}
            id="support"
            mb={"0"}
            bgColor={background2Color}
            textColor={"white"}
          >
            <Title mb="30px" color={dataEvent.color.title}>
              <h4>{"Review"}</h4>
            </Title>
            <CollapseField
              supports={dataEvent?.commentsField}
              type={"event"}
              eventId={id}
              color={{
                bgColor1: background1Color,
                bgColor2: dataEvent.color.background2,
              }}
            />
          </Section>
        )}

      <Section
        hasWallpaper={hasWallpaper}
        mb={"0"}
        bgColor={background2Color}
        textColor={dataEvent.color.text || "white"}
      >
        <Footer isEdit={isEdit} main={true} />
      </Section>
      {isEdit && (
        <Submit>
          <Button loading={loading} onClick={handleSubmitEvent}>
            Save
          </Button>
        </Submit>
      )}

      {isEdit && (
        <SettingColor
          managersSections={managersSections.concat(
            isOwnerEventOrAdmin ? ["all"] : [""]
          )}
          openSettingColor={openSettingColor}
          optionSetting={optionSetting}
          color={dataEvent.color}
          optionSection={optionSection}
          setOptionSection={setOptionSection}
          setOpenSettingColor={setOpenSettingColor}
          onChangeColor={onChangeColor}
          dataEvent={dataEvent}
          setWallpaper={setWallpaper}
          setWallpaperVideo={setWallpaperVideo}
          setWallpaperLinkClick={setWallpaperLinkClick}
        />
      )}
      {
        <IconPageConnect
          isEdit={isEdit}
          listIconConnect={listIconConnect}
          handleAddOrRemoveChil={handleAddOrRemoveChil}
          setFile={setFile}
          onBlurDocument={onBlurDocument}
        />
      }

      <Modal
        centered={true}
        isOpen={openModalInteractive.open}
        title="Interactive"
        children={renderModalFrom()}
        onConfirm={handleSubmit(confirmInterForm)}
        onCancel={() =>
          setOpenModalInteractive({
            open: false,
            key: null,
          })
        }
      />
      <Modal
        isOpen={isModalLinkSurvey.open}
        title={isModalLinkSurvey.type === "icon" ? "Link icon" : "Link video"}
        centered={true}
        onCancel={() =>
          setIsModalLinkSurvey({
            open: false,
            type: null,
          })
        }
        onConfirm={() => handleAccept()}
      >
        <AddLink>
          {isModalLinkSurvey.type === "icon" && (
            <FormAddLink
              index={indexStateChange.idx}
              id={indexStateChange.uuid}
              type={"content"}
              listLinkIcon={listLinkIcon}
              onBlurText={onBlurText}
              handleAddOrRemoveChil={handleAddOrRemoveChil}
              setFile={setFile}
            />
          )}
        </AddLink>
      </Modal>

      {hasWallpaper && (
        <div
          className={`event-wallpaper ${
            wallpaperLinkClick ? "event-wallpaper-has-link" : ""
          }`}
        >
          <img
            src={
              wallpaper ? URL.createObjectURL(wallpaper) : dataEvent.wallpaper
            }
            alt=""
          />

          {wallpaperVideo && (
            <div className="event-wallpaper-video">
              <Video link={wallpaperVideo} />
            </div>
          )}
        </div>
      )}
    </Wrapper>
  );
};

export default View;
