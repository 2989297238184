import { request, parseErrorResponse } from './request';

const login = (body, params?: any) =>
  new Promise((resolve, reject) => {
    request()
      .post('/login', body, {
        params
      })
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error('Something went error!'));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const register = (body, params?: any) =>
  new Promise((resolve, reject) => {
    request()
      .post('/register', body, {
        params
      })
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error('Something went error!'));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const postVerifyEmail = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`verifyEmail/${req.params.token}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error('Something went error!'));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const resendVerifyEmail = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post('resend-verify-email', req.params.token)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error('Something went error!'));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const postForgotPassword = (req) =>
  request()
    .post('forgot-password', req.body)
    .then((res) => res.data);

const resetPassword = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post('/reset-password', req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error('Something went error!'));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const postVerify = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post('/verify-email', req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error('Something went error!'));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const postVerifyLogin = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post('/verify-login', req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error('Something went error!'));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const resendCode = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post('/resend-otp', req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error('Something went error!'));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const checkUserExists = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post('/users/check-user-exists', req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error('Something went error!'));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const APIs = {
  login,
  register,
  postVerifyEmail,
  resendVerifyEmail,
  postForgotPassword,
  resetPassword,
  postVerify,
  postVerifyLogin,
  resendCode,
  checkUserExists
};

export default APIs;
