import styled, { css } from "styled-components";

export const Wrapper = styled.div<{bgColor?: string}>`
  margin-top: 20px;
  background: ${(props) => props.bgColor || props.theme.colors[props.bgColor || "black100"]};
  border-radius: 8px;
  width: 100%;
  overflow: hidden;
  color: #000 !important;
  padding: 30px;

  @media screen and (max-width: 420px){
    padding: 10px;
  }
  h3 {
    color: white;
    margin-bottom: 10px;
  }
  .collapse {
    a,
    span,
    p {
      background-color: transparent !important;
    }
  }

  /* @media screen and (max-width: 1100px) {
    min-height: calc(calc(var(--vh, 1vh) * 100) - 86px);
  } */
`;

export const Element = styled.div<{color?, bgColor?: string, isBorder?: boolean}>`
  button {
    width: 100%;
    text-align: start;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${props => props.bgColor || "#3a3f51"} !important;
    color: #f2f2f2 !important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    span {
      font-weight: 600;
      font-size: 15px;
      text-transform: uppercase;
    }
  }

  ${({ isBorder, color }) => isBorder ? css`
    border: 1px solid ${color} !important;
    border-radius: 10px;
    overflow: hidden;
  `: ''}

  margin-bottom: 10px;
  label, input, textarea, h4 {
    ${({ color }) => color ? css`
    color: ${color};
  `: ''}
  }
`;

export const Contain = styled.div`
  /* padding: 0 30px; */
`;

export const DreamFapArea = styled.div<{bgColor?: string}>`
  button:first-child {
    border-top: 1px solid hsla(0, 0%, 100%, 0.1);
  }
  button {
    border: unset;
    cursor: pointer;
    padding: 15px;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
    margin-bottom: 0;
    color: ${(props) => props.theme.colors.white100};
    font-weight: 500;
    background-color: unset;
    width: 100%;
    text-align: left;
  }
  .card {
    border: unset;
  }
  .card-body {
    background: ${(props) => props.bgColor || "#00143d"};
    border: unset;
    color: white;
  }
`;
