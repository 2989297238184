import Button from "components/button";
import Input from "components/input";
import { useState } from "react";
import { toast } from "react-toastify";
import { ROUTES } from "config/routes";
import { useHistory } from "react-router-dom";
import AuthAPI from "api/auth";

import { FormItem } from "../../styles";

const StepVDB = ({ providerToken }) => {
  const history = useHistory();
  const [referredBy, setReferredBy] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const res: any = await AuthAPI.register(
        {
          type: "vdiarybook",
          referred_by: referredBy,
        },
        {
          providerToken,
        }
      );
      if (res) toast.success("You have successfully registered");
      setLoading(false);
      history.push(ROUTES.LOGIN);
    } catch (error) {
      toast.error(error.errors);
      setLoading(false);
    }
  };

  return (
    <>
      <FormItem>
        <Input
          onChange={(e) => setReferredBy(e.target.value)}
          label="Referral ID (Optional)"
          placeholder="Referral ID"
        />
        <Button type="button" onClick={handleSubmit} loading={loading}>
          Submit
        </Button>
      </FormItem>
    </>
  );
};

export default StepVDB;
