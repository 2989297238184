/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import ToolTip from "components/tooltip/View";
import { useEffect, useState } from "react";
import Stepper from "react-stepper-horizontal";
import { useAppSelector } from "redux/reducers/hook";
import { getUserProfile } from "redux/reducers/users/users.selector";
import { TooltipWrapper, AvatarActive } from "./styles";
import { formatNumber } from "utils/common";
import VReward from "components/v-reward";
const Timeline = ({ levels, myLevel, campaignSeleted }) => {
  const [activeStep, setActiveStep] = useState(-1);

  useEffect(() => {
    if (levels && myLevel) {
      levels.map((l, index) => {
        if (l.id === myLevel.level_id) {
          setActiveStep(index);
        }
        return l;
      });
    }
  }, [levels, myLevel]);

  const profile: any = useAppSelector(getUserProfile);

  const renderReward = (p: any) => {
    return (
      <TooltipWrapper>
        <h6>Require : </h6>
        {p.enable_total_f1 && (
          <>
            <label>Amount Groups :</label>
            <span> {p.total_f1}</span>
            <hr />
          </>
        )}
        {p.enable_total_user && (
          <>
            <label>Amount Users :</label>
            <span> {formatNumber(p.total_user)}</span>
            <hr />
          </>
        )}
        {p.enable_total_my_invest && (
          <>
            <label>Total My Invest :</label>
            <span>
              {" "}
              {formatNumber(p.total_my_invest)} (
              {String(campaignSeleted?.stock).toUpperCase()}){" "}
            </span>
            <hr />
          </>
        )}
        {p.enable_total_user_invest && (
          <>
            <label>Total User Invest :</label>
            <span>
              {" "}
              {formatNumber(p.total_user_invest)} (
              {String(campaignSeleted?.stock).toUpperCase()}){" "}
            </span>
            <hr />
          </>
        )}
        {p.enable_amount_image && (
          <>
            <label>Amount Image :</label>
            <span> {p.amount_image}</span>
          </>
        )}
        {p.enable_amount_video && (
          <>
            <label>Amount Video :</label>
            <span> {p.amount_video}</span>
          </>
        )}
        {p.enable_amount_attachment && (
          <>
            <label>Amount Attachment :</label>
            <span> {p.amount_attachment}</span>
          </>
        )}
        <br />
        <h6>Reward : </h6>
        <VReward reward={p.reward} />
      </TooltipWrapper>
    );
  };

  const steps = levels.map((level, index) => {
    return {
      title: (
        <>
          <AvatarActive isActive={activeStep === index}>
            <img src={profile.avatar} alt="_avt" />
          </AvatarActive>
          <ToolTip id={`${level.id}_level`} content={renderReward(level)}>
            <div id={`tooltip-` + `${level.id}_level`} className="level">
              <div
                className={`level-detail ${activeStep === index && "active"}`}
              >
                <img src={level.logoLevel}></img>
                <p>{level.name}</p>
              </div>
            </div>
          </ToolTip>
        </>
      ),
      icon: campaignSeleted?.info.img_reward,
    };
  });

  return (
    <div className="level-stepper">
      <Stepper
        steps={steps}
        activeStep={activeStep}
        activeColor="#FF9900"
        defaultBarColor="white"
        completeColor="white"
        completeBarColor="#FF9900"
        defaultBorderWidth={10}
      />
    </div>
  );
};

export default Timeline;
