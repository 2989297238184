import React, { useState } from "react";
import Input from "components/input";
import Dropdown from "components/dropdown";
import Modal from "components/modal";

import Wrapper from "./styles";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { getUserProfile } from "redux/reducers/users/users.selector";
import { toast } from "react-toastify";

interface IProps {
  [key: string]: any;
}

export default function View({ isOpen, setIsOpen, createMyBank }: IProps) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const profile : any = useSelector(getUserProfile);

  const [error, setError] = useState(null);
  const [bankName, setBank] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleAddBank = async (data) => {
    if (!bankName) {
      return setError(new Error("Please select bank name!"));
    }

    setError(null);
    setLoading(true);

    const body = {
      account_name: data.bankAccountHolderName,
      account_number: data.bankAccountNumber,
      bank_name: bankName,
      user_id: profile._id,
    };

    try {
      await createMyBank({body});
      toast.success("Added bank successful!")
      setIsOpen(false);
    } catch (err) {
      setError(err.message)
    }
    setLoading(false);
  };

  const onChangeBankName = (v) => {
    setBank(v.label);
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Add new bank account"
      centered={true}
      onConfirm={handleSubmit(handleAddBank)}
      onCancel={() => setIsOpen(false)}
      loading={loading}
    >
      <Wrapper>
        <form>
          <Dropdown
            width={"100%"}
            transparent={true}
            hasError={error}
            label="Bank name"
            options={[
              {
                label: "Vietcombank",
                value: "VCB",
              },
              {
                label: "Techcombank",
                value: "TCB",
              },
              {
                label: "TPBank",
                value: "TPB",
              },
              {
                label: "VietinBank",
                value: "VTB",
              },
              {
                label: "MB Bank",
                value: "MB",
              },
              {
                label: "ACB Bank",
                value: "ACB",
              },
              {
                label: "Agribank",
                value: "AGB",
              },
            ]}
            defaultValue={"Choose the bank name"}
            onChange={onChangeBankName}
          />

          <Controller
            name="bankAccountNumber"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                type="number"
                transparent={true}
                placeholder="Your bank account number"
                label="Bank account number"
                hasError={errors.bankAccountNumber}
                {...field}
              />
            )}
          />

          <Controller
            name="bankAccountHolderName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                transparent={true}
                placeholder="Your bank account holder name"
                label="Bank account holder name"
                hasError={errors.bankAccountHolderName}
                {...field}
              />
            )}
          />
        </form>
      </Wrapper>
    </Modal>
  );
}
