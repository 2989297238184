import { v4 as uuidv4 } from "uuid";
import Banner from "components/business-landing-page/components/Banner";
import Button from "components/button";
import SurveyItem from "pages/events/components/survey/components/survey-item";
import { AddOrRemoveChil } from "pages/home/landing-page/styles";
import {
  Container
} from "./styles"

const View = (props: any) => {
  const { 
    page,
    isEdit,
    user,
    dataPage,
    setDataPage,
    advertisementBanner,
    setAdvertisementBanner,
    hasWallpaper,
    wallpaperLinkClick
  } = props;

  const permissionBanner = user?.role === "ADMIN" || user?.permissions?.[page === "event" ? 'events.ads_trust' : 'projects.ads_trust']

  const linkBanner = dataPage?.banner?.link
  const isSlide = dataPage?.banner?.slide && dataPage?.banner?.slide.length > 0

  const openTagWindow = (url: string) => {
    if (!isEdit) {
      window.open(url)
    }
  }

  const handleAddBannerAds = (key: string) => {
    setAdvertisementBanner(prev => ({
      ...prev,
      [key]: prev[key]?.concat({
        id: uuidv4(),
        src: "",
        link: "",
        urlVideo: 'https://www.youtube.com/watch?v=Aq6W-nmRO94',
        slide: [],
      })
    }))
  }

  const removeChil = (uuid: string, key? :string) => {
    setAdvertisementBanner(prev => ({
      ...prev,
      [key]: prev?.[key].filter((el: any) => el.id !== uuid)
    }))
  }

  const setFileLogoAdsLeft = (f: any, id: string) =>{
    setAdvertisementBanner(prev => ({
      ...prev,
      left: prev?.left?.map(el => el.id === id ? {
        ...el,
        file: f,
        isNew: true
      } : el)
    }))
  }

  const setFileLogoAdsRight = (f: any, id: string) =>{
    setAdvertisementBanner(prev => ({
      ...prev,
      right: prev?.right?.map(el => el.id === id ? {
        ...el,
        file: f,
        isNew: true
      } : el)
    }))
  }

  const onChangeDataBannerAdsLeft = (data, index: number, uuid: string) => {
    setAdvertisementBanner(prev => ({
      ...prev,
      left: prev?.left?.map(item => item.id === uuid ? {
        ...item,
        slide: data.slide,
        urlVideo: data.link
      } : item)
    }))
  }

  const onBlurTextBannerAdsRight = (data, key, index, name, id) => {
    setAdvertisementBanner(prev => ({
      ...prev,
      right: prev?.right?.map((el: any) => el.id === id ? {
        ...el,
        link: data.target.valua
      } : el )
    }))
  }

  const onChangeDataBannerAdsRight = (data, index: number, uuid: string) => {
    setAdvertisementBanner(prev => ({
      ...prev,
      right: prev?.right?.map(item => item.id === uuid ? {
        ...item,
        slide: data.slide,
        urlVideo: data.link
      } : item)
    }))
  }

  const onBlurTextBannerAdsLeft = (data, key, index, name, id) => {
    setAdvertisementBanner(prev => ({
      ...prev,
      left: prev?.left?.map((el: any) => el.id === id ? {
        ...el,
        link: data.target.value
      } : el )
    }))
  }

  return <>
    <Container 
      page={page} 
      isEdit={isEdit}
      hasWallpaper={hasWallpaper}
      hasWallpaperLinkClick={Boolean(wallpaperLinkClick)}
      isBannerAds={
        advertisementBanner?.left?.length > 0 || 
        advertisementBanner?.right?.length > 0
      }
      onClick={(e: any) => {
        if (e.target.id === 'banner-container-wrapper' && wallpaperLinkClick) {
          window.open(wallpaperLinkClick, '_blank')
        }
      }}
      id='banner-container-wrapper'
    >
      <div className="_advertisement">
        {
          advertisementBanner?.left?.length > 0 &&
            advertisementBanner.left.map((item, index) =>
              <div key={index} className="_item_ads" onClick={() => openTagWindow(item.link)}>
                {
                  isEdit && permissionBanner &&
                  <AddOrRemoveChil>
                    <div onClick={() => removeChil(item.id, "left")}>-</div>
                  </AddOrRemoveChil>
                }
                <SurveyItem
                  isEdit={isEdit && (user?.role === "ADMIN" || user?.permissions?.['events.ads_trust'])}
                  type={"banner_ads"}
                  item={item}
                  index={index}
                  setFile={setFileLogoAdsLeft}
                  onChangeDataSurveyBanner={onChangeDataBannerAdsLeft}
                  onBlurText={onBlurTextBannerAdsLeft}
                />
              </div>
            )
        }
        {
          isEdit && permissionBanner &&
            advertisementBanner?.left?.length < 2 &&
            <div className="_add_remove_banner_ads">
              <Button 
                disabled={
                  page === "landing_page" && 
                  !linkBanner &&
                  !isSlide
                }
                onClick={() => handleAddBannerAds("left")}
              >
                  +
              </Button>
            </div>
        }
      </div>

      <Banner
        isEdit={isEdit}
        page={page}
        type={"banner"}
        isSlide={dataPage.banner.slide && dataPage.banner.slide.length > 0}
        // minHeight={page'100vh'}
        linkVideo={dataPage.banner.link}
        listSlideBanner={dataPage.banner.slide}
        listLogos={dataPage.banner.listLogos || []}
        imageBottom={dataPage.banner.imageBottom}
        setDataState={setDataPage}
      />

      <div className="_advertisement">
        {
          advertisementBanner?.right?.length > 0 &&
            advertisementBanner.right.map((item, index) =>
              <div key={index} className="_item_ads" onClick={() => openTagWindow(item.link)}>
                {
                  isEdit && permissionBanner &&
                  <AddOrRemoveChil>
                    <div onClick={() => removeChil(item.id, "right")}>-</div>
                  </AddOrRemoveChil>
                }
                <SurveyItem
                  isEdit={isEdit && permissionBanner}
                  type={"banner_ads"}
                  item={item}
                  index={index}
                  setFile={setFileLogoAdsRight}
                  onChangeDataSurveyBanner={onChangeDataBannerAdsRight}
                  onBlurText={onBlurTextBannerAdsRight}
                />
              </div>
            )
        }
        {
          isEdit && permissionBanner &&
            advertisementBanner?.right?.length < 2 &&
            <div className="_add_remove_banner_ads">
              <Button 
                disabled={
                  page === "landing_page" && 
                  !linkBanner &&
                  !isSlide
                }
                onClick={() => handleAddBannerAds("right")}
              >
                  +
              </Button>
            </div>
        }
      </div>
    </Container>
  </>
}

export default View;