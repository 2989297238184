/* eslint-disable no-useless-escape */
export const convertIdVideo = (link: any) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
  const match = link?.match(regExp)
  if (match && match[2].length === 11) {
    return match[2]
  } else {
    //
  }
}

export const checkValidUrlFB = (url: string) => {
  var pattern = /^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/i;
  
  if(pattern.test(url)) {
    return true
  }

  if(url && url.indexOf('fb.watch')) {
    return true
  }

  else {
    return false
  }
}
