import styled from "styled-components";

const Wapprer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  .sca {
    height: calc(100% - 70px);
    overflow-x: hidden;

    @media screen and (max-width: 1365px) {
      height: calc(100% - 95px);
    }

    @media screen and (max-width: 768px) {
      height: calc(100% - 75px);
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0px 20px 30px 20px;

  @media screen and (max-width: 1365px) {
    transform: scale(0.8) translate(-12.5%, -12.5%);
    width: 125%;
    height: inherit;
  }
  @media screen and (max-width: 1365px) {
    transform: scale(0.8) translate(-12.5%, -12.5%);
    width: 125%;
    height: inherit;
  }
  /* @media screen and (max-width: 460px) {
		transform: scale(0.4) translate(-75%, -75%);
		width: 250%;
		padding: 30px 5px;
	} */
`;

export const EventDetail = styled.div`
  position: relative;
  z-index: 1;
  cursor: pointer;
  max-width: 400px;
  width: 100%;
  height: 510px;
  border: 1px solid;
  border-radius: 10px;
  margin: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    transform: scale(1.03);
    box-shadow: 0 0 10px 3px ${(props) => props.theme.colors.orange100};
  }

  ._event {
    height: 100%;
  }

  @media screen and (max-width: 840px) {
    margin: 10px 6px;
  }
`;

export const BannerEvent = styled.div`
  width: 100%;
  height: 44%;
  display: flex;
  justify-content: center;
  overflow: hidden;

  img {
    width: auto;
    height: 100%;
  }

  ._banner_container {
    min-height: 100% !important;
    cursor: pointer;
  }
`;

export const Content = styled.div`
  padding: 10px 15px;

  @media screen and (max-width: 380px) {
    padding: 10px 5px;
  }
`;

export const NameEvent = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.orange100};
  padding: 7px 0px;
  margin-bottom: 10px;
  max-height: 60px;
  overflow: hidden;
  text-align: center;
  white-space: pre-line;
`;

export const Action = styled.div`
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;

  button {
    height: 36px;
    border-radius: 4px;
    padding: 0;
  }

  button:first-child {
    width: calc(100% - 70px);
  }

  button:nth-child(2) {
    width: 50px;
  }
`;

export const FilterHeader = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0;
`;

export const ItemStatus = styled.div<{ active: boolean }>`
  height: 30px;
  padding: 6px 14px;
  margin: 0px 15px;
  background: rgb(255, 255, 255);
  font-size: 12px;
  border-radius: 8px;
  color: ${(props) =>
    props.active ? props.theme.colors.orange100 : "rgb(58, 63, 81)"};
  opacity: ${(props) => (props.active ? 1 : 0.6)};
  cursor: pointer;

  @media screen and (max-width: 470px) {
    margin: 0px 4px;
    padding: 5px 7px;
    border-radius: 4px;
  }
`;

export default Wapprer;
