import styled from "styled-components";

export const Container = styled.div``;

export const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
`;

export const Name = styled.div`
  img {
    width: 30px;
    margin-left: 2px;
  }
  span {
    color: ${(props) => props.theme.colors.orange100};
    font-size: 15px;
    font-weight: 700;
  }
`;
