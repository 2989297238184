import styled from "styled-components";
import { isMobile } from "react-device-detect";

const Wrapper = styled.div`
  background-image: url("/assets/images/slide/slide-1.jpg");
  background-size: cover;
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  color: white;

  .filter-header {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
  }

  .event-filter-smart {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    img {
      width: 50px;
      height: auto;
      margin: 10px;
      transition: 0.3s;
      cursor: pointer;
      opacity: 0.6;

      &:hover {
        width: 80px;
        opacity: 1;
      }
    }

    .active {
      img {
        opacity: 1;
        width: 80px;
      }
    }
  }
`;

export const Header = styled.div<{ page?: string }>`
  padding: 24px;
  cursor: pointer;

  img {
    width: 250px;
  }

  @media screen and (max-width: 768px) {
    padding: 14px;
    img {
      width: ${(props) => (props.page === "event" ? "150px" : "250px")};
    }
  }
`;

export const ContentAuth = styled.div<{ page?: string }>`
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  height: calc(calc(var(--vh, 1vh) * 100) - ${isMobile ? "172px" : "116px"});

  @media screen and (max-width: 768px) {
    height: ${(props) =>
    `calc(calc(var(--vh, 1vh) * 100) - ${
      isMobile ? (props.page === "event" ? "125px" : "172px") : "116px"
    })`};
  }
`;

export default Wrapper;
