import APIs from "api";
import Button from "components/button";
import Input from "components/input";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { securityAsync } from "redux/reducers/securities/securities.action";
import { getSecurity } from "redux/reducers/securities/securities.selector";
import { getUserProfile } from "redux/reducers/users/users.selector";
import { Content } from "../../styles";

import Wrapper from "./styles";

interface IProps {
  [key: string]: any;
}

export default function View(props: IProps) {
  const { verify, setVerify } = props;
  const user = useAppSelector(getUserProfile);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { is_enable } = useAppSelector(getSecurity);
  const [isSend, setIsSend] = useState(false);

  const getCode = async () => {
    try {
      setLoading(true);
      await APIs.AUTH.resendCode({
        body: { email: user.email, title: "Verification Code - Withdraw" },
      });
      setIsSend(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.errors);
    }
  };

  useEffect(() => {
    dispatch(securityAsync());
  }, [dispatch]);

  return (
    <Wrapper>
      <Content>
        <Input
          transparent={true}
          label="Email verification code"
          onChange={(e) =>
            setVerify({
              ...verify,
              email_code: e.target.value,
            })
          }
          value={verify.email_code}
          suffixIcon={
            <Button
              loading={loading}
              className={"action"}
              onClick={getCode}
              disabled={isSend}
            >
              {" "}
              {!isSend ? "Get code" : "Code Send"}
            </Button>
          }
        />
        <span className="details">
          Enter the 6 digit code send to {user.email}
        </span>
        {is_enable && (
          <>
            {" "}
            <Input
              transparent={true}
              label=" Google verification code"
              onChange={(e) =>
                setVerify({
                  ...verify,
                  google_code: e.target.value,
                })
              }
              value={verify.google_code}
            />
            <span className="details">
              Enter the 6 digit code from Google Authenticator
            </span>
          </>
        )}

        {user.pin_secure && (
          <>
            {" "}
            <Input
              transparent={true}
              label="Pin Code"
              onChange={(e) =>
                setVerify({
                  ...verify,
                  pin_code: e.target.value,
                })
              }
              value={verify.pin_code}
            />
            <span className="details">Enter your pin code</span>
          </>
        )}
      </Content>
    </Wrapper>
  );
}
