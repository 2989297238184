import { Warning, ContentRightView, Continue } from "../../styles";
import Input from "components/input";
import Button from "components/button";
import { toast } from "react-toastify";
import APIs from "api";

const Step1 = ({ dataPayment, setDataByKey }) => {
  const handleChange = (e) => {
    setDataByKey("amount", e);
  };

  const handleClick = async () => {
    console.log(dataPayment);
    if (dataPayment.currency === "vnp" && +dataPayment.amount < 10000)
      return toast.error("Minimum deposit is 10000vnp!");
    if (dataPayment.currency === "usdt" && +dataPayment.amount <= 0)
      return toast.error("Amount must be greater 0!");

    console.log(dataPayment.stock);
    if (dataPayment.stock === 'vnp') {
      console.log(true);
      const paymentUrl: any = await APIs.PAYMENT.createPaymentUrlViaVNPay({
        body: {
          amount: dataPayment.amount,
          stock: "vnp",
        },
      });
      // console.log(paymentUrl?.vnpUrl);
      window.location.href = paymentUrl?.vnpUrl ?? window.location.href;
    };
  }

  return (
    <ContentRightView>
      <Input
        isCurrency={true}
        transparent={true}
        label={`Amount (${String(dataPayment.currency || "").toUpperCase()}) *`}
        value={dataPayment.amount}
        onValueChange={(e) => handleChange(e)}
        placeholder="10,000"
      />
      <Warning>
        <img src="/assets/images/icons/warning.svg" alt="warning" />
        <p>
          {dataPayment.currency.toUpperCase()} deposit is free. Vzonex does not
          collect {dataPayment.currency.toUpperCase()} deposit fee, however, in
          some instances, you may have to bear a small transaction fee charged
          directly by the processing bank or network.
        </p>
      </Warning>
      <Continue>
        <Button onClick={handleClick}>Continue</Button>
      </Continue>
    </ContentRightView>
  );
};

export default Step1;
