/* eslint-disable jsx-a11y/iframe-has-title */
import { useState } from "react";
import { Container, ChatSupportIframe } from "./styles";
import { useSelector } from "react-redux";
import { getWebConfig } from "redux/reducers/settings/settings.selector";
// import { useHistory } from "react-router-dom";

const View = () => {
  const [isOpenChat, setIsOpenChat] = useState(false);
  const [isHideChat, setIsHideChat] = useState(false);

  const webConfig = useSelector(getWebConfig);

  const webConfigChat = webConfig?.find(
    (ele) => ele.name === "vdb_chat_plugin-homepage"
  );

  if (!webConfigChat) {
    return <></>;
  }

  const regexChat = /https:\/\/vdiarybook.vn\/conversations\/(.+)\/invite/;
  const matches = webConfigChat.value.match(regexChat);

  const linkChat =
    matches && matches[1]
      ? `https://chat.vdiarybook.vn/conversations/${matches[1]}/invite?is_chat_plugin=1`
      : webConfigChat.value;

  return (
    <>
      {isOpenChat && (
        <ChatSupportIframe isHideChat={isHideChat}>
          <iframe
            src={linkChat}
            allow="camera; microphone; display-capture; fullscreen; clipboard-read; clipboard-write; autoplay"
          ></iframe>
        </ChatSupportIframe>
      )}
      <Container
        onClick={() => {
          // history.push("/my/supports")
          if (isOpenChat) {
            setIsHideChat(!isHideChat);
          } else {
            setIsOpenChat(true);
          }
        }}
      >
        <img src="/assets/images/support.gif" alt="" />
      </Container>
    </>
  );
};

export default View;
