import Marquee from "react-fast-marquee";
import {
  ContentWrapper,
  ImgWrapper,
  Item,
  Item2,
  Items,
  ProjectWrapper,
} from "./styles";
import useProjects from "hooks/userProjects";
import { useHistory } from "react-router-dom";

export default function View() {
  const { projectsByType } = useProjects();
  const history = useHistory();

  const handleClickProject = (p) => {
    if(p.symbol === "VDC"){
      history.push(`personal/${p.symbol}`)
    } else {
      history.push(`${p.roleUser ? `${p.roleUser}/${p.symbol}` : p.pathLandingPage}`)
    }
  }

  return (
    <>
      <ProjectWrapper>
        <Marquee
          speed={50}
          gradient={false}
          pauseOnHover={true}
          direction={"right"}
        >
          <Items>
            {projectsByType.opening.map((p) => (
              <Item onClick={() => handleClickProject(p)}>
                <ImgWrapper>
                  <img src={p.logo} alt="warning" />
                </ImgWrapper>
                <ContentWrapper>
                  <span>{p.name.slice(0, 30)}</span>
                  <p>{p.description.slice(0, 30)}</p>
                </ContentWrapper>
              </Item>
            ))}
          </Items>
        </Marquee>
      </ProjectWrapper>
      <ProjectWrapper>
        <Marquee
          speed={50}
          gradient={false}
          pauseOnHover={true}
          direction={"right"}
        >
          <Items>
            {projectsByType.upcoming.map((p) => (
              <Item2>
                <ImgWrapper>
                  <img src={p.logo} alt="warning" />
                </ImgWrapper>
                <ContentWrapper>
                  <span>{p.name.slice(0, 40)}</span>
                  <p>{p.description.slice(0, 40)}</p>
                </ContentWrapper>
              </Item2>
            ))}
          </Items>
        </Marquee>
      </ProjectWrapper>
    </>
  );
}
