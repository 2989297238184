import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "api";
import { IRequestData } from "api/request";

export const getTasksAsync = createAsyncThunk(
  "task",
  async (req: IRequestData) => {
    try {
      const response: any = await API.TASK.getTasks();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getCampaignsAsync = createAsyncThunk(
  "campaigns",
  async () => {
    try {
      const response: any = await API.TASK.getCampaigns();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getTaskByIdAsync = createAsyncThunk(
  "task/id",
  async (req: IRequestData) => {
    try {
      const response: any = await API.TASK.getTaskById(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);
