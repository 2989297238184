import Table from "components/table";
import { useEffect } from "react";
import { getHistorySwapAsync } from "redux/reducers/cryptos/cryptos.actions";
import { getHistorySwap } from "redux/reducers/cryptos/cryptos.selector";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { formatNumber } from "utils/common";
import { renderAmount } from "utils/render";
import { HistoryWrapper, Status } from "./styles";

const columns = [
  {
    key: "Period",
    title: "Period",
    dataIndex: "_id",
  },
  {
    key: "User",
    title: "User",
    render: (item: any) => `${item.user && item.user["email"]}`,
  },
  {
    key: "From",
    title: "From",
    render: (item) => String(item.from).toUpperCase(),
  },

  {
    key: "To",
    title: "To",
    render: (item) => String(item.to).toUpperCase(),
  },
  {
    key: "Amount Send",
    title: "Amount Send",
    render: (item) => renderAmount(-item.amountSend),
  },
  {
    key: "Amount Locked",
    title: "Amount Locked",
    render: (item) => renderAmount(item.amountLocked),
  },
  {
    key: "Amount Received",
    title: "Amount Received",
    render: (item) => renderAmount(item.amountReceived),
  },
  {
    key: "Fee",
    title: "Fee",
    render: (item) =>
      item.fee
        ? formatNumber(item.fee.amount) +
          `(${String(item.fee.stock || "").toUpperCase()})`
        : "N/A",
  },
  {
    key: "Status",
    title: "Status",
    dataIndex: "status",
    render: (item: any) => (
      <Status>
        <div className={item.status}>{item.status}</div>
      </Status>
    ),
  },
];

const History = () => {
  const dispatch = useAppDispatch();
  const history = useAppSelector(getHistorySwap);

  useEffect(() => {
    dispatch(getHistorySwapAsync());
  }, [dispatch]);

  return (
    <HistoryWrapper>
      {" "}
      <Table columns={columns} dataSource={history || []}></Table>
    </HistoryWrapper>
  );
};

export default History;
