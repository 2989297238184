import Input from "components/input";
import Button from "components/button";
import { Download, Check } from "../../styles";
import ProductApi from "api/products";
import { useState } from "react";
import { toast } from "react-toastify";
import { formatNumber } from "utils/common";
const View = () => {
  const [code, setCode] = useState("");
  const [state, setState] = useState(null);
  const [loading, setLoading] = useState(false);

  const checkCode = async () => {
    try {
      setLoading(true);
      const res = await ProductApi.getInfoCode({
        query: {
          code: code,
        },
      });
      setState(res);
      setLoading(false);
    } catch (error) {
      toast.error("Code not found!");
      setLoading(false);
    }
  };

  return (
    <Download>
      <span>Your code</span>
      <Input
        onChange={(e) => {
          setCode(e.target.value);
          setState(null);
        }}
      />
      <Check>
        <Button loading={loading} onClick={(_) => checkCode()}>
          Check
        </Button>
      </Check>
      {state && (
        <div className="container">
          <div>Code : {state.code}</div>
          <div>Start date : {new Date(state.start_date).toLocaleString()}</div>
          <div>End date :{new Date(state.end_date).toLocaleString()}</div>
          <div>Amount : {state.number}</div>
          <div>Amount used : {state.used}</div>
          <div>Price : {formatNumber(state.price)}</div>
        </div>
      )}
    </Download>
  );
};

export default View;
