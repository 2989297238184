import { Controller, useForm } from "react-hook-form";
import Button from "components/button";
import Input from "components/input";

import APIs from "api";
// import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import Dropdown from "components/dropdown";

import { filterAddress } from "utils/getAddress";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { getCities } from "redux/reducers/settings/settings.selector";
import {
  Form,
  FormDescription,
  FormItem,
  Submit,
} from "pages/create-landing-page/styles";
import { ButtonEdit, ObjectWrapper } from "./styles";
import { getUserProfile } from "redux/reducers/users/users.selector";
import { Info, ItemRow, RowInfo, TextColor } from "../styles";
import { toast } from "react-toastify";
import { getUser } from "redux/reducers/users/users.reducer";
import UploadFile from "components/uploadFile";
import { Col, Container, Row } from "reactstrap";

const View = () => {
  const cities = useAppSelector(getCities);
  const profile: any = useAppSelector(getUserProfile);
  const [loading, setLoading] = useState<boolean>(false);
  const [provices, setProvices] = useState<any>([]);
  const [districts, setDistricts] = useState<any>([]);
  const [wards, setWards] = useState<any>([]);
  const [role, setRole] = useState("personal");
  const [address, setAddress] = useState<any>({
    provices: "",
    districts: "",
    wards: "",
  });
  const [isEdit, setIsEdit] = useState(false);
  const [file, setFile] = useState(null);
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setProvices(cities);
  }, [cities]);

  const onChangeAddress = async (value, key) => {
    if (key === "provices") {
      filterAddress(key, cities, value.value, setDistricts);
    } else if (key === "districts") {
      filterAddress(key, districts, value.value, setWards);
    }
    setAddress((prev) => ({
      ...prev,
      [key]: value.label,
    }));
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const { info } = object_info;
      let logo = null;

      if (file) {
        const formData = new FormData();
        formData.append("files", file);
        const res: any = await APIs.UPLOAD.uploadFile({ body: formData });
        logo = res.filePath;
      }
      const _object_info = {
        ...object_info,
        info: {
          ...info,
          ...data,
          address,
          logo: logo || info.logo,
        },
        type: role,
      };
      const res = await APIs.USER.editUser({
        params: {
          id: profile._id,
        },
        body: {
          ...profile,
          object_info: _object_info,
        },
      });
      if (res) {
        await dispatch(getUser());
        setIsEdit(false);
      }
      setLoading(false);
    } catch (error) {
      toast.error(error.errors);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (profile && profile.object_info) {
      setRole(profile.object_info.type);
    }
  }, [profile]);

  const object_info = profile.object_info || {
    status: "pending",
    info: {},
    type: "personal",
  };

  const renderAddress = (address) => {
    return `${address.wards || ""}, ${address.districts || ""}, ${
      address.provices || ""
    } `;
  };

  return (
    <ObjectWrapper>
      <ButtonEdit>
        <Button
          height={"30px"}
          onClick={() => setIsEdit((a) => !a)}
          disabled={object_info.status === "confirmed"}
        >
          {!isEdit ? "Edit" : "Cancel"}
        </Button>
      </ButtonEdit>
      {!isEdit ? (
        <>
          <Container>
            <Row>
              <Col xs={12} md={3} className="logo-wrapper">
                {" "}
                <img src={object_info.info?.logo} width={200} alt="" />
              </Col>
              <Col xs={12} md={9}>
                {" "}
                <Info>
                  <RowInfo>
                    <ItemRow>Object Type</ItemRow>
                    <ItemRow>{object_info?.type}</ItemRow>
                  </RowInfo>
                  {(role === "personal" || role === "student") && (
                    <RowInfo>
                      <ItemRow>FullName</ItemRow>
                      <ItemRow>{object_info?.info?.fullname}</ItemRow>
                    </RowInfo>
                  )}

                  {role === "student" && (
                    <>
                      <RowInfo>
                        <ItemRow>School Name</ItemRow>
                        <ItemRow>{object_info?.info?.schoolName}</ItemRow>
                      </RowInfo>

                      <RowInfo>
                        <ItemRow>School Code</ItemRow>
                        <ItemRow>{object_info?.info?.schoolCode}</ItemRow>
                      </RowInfo>

                      <RowInfo>
                        <ItemRow>Specialized</ItemRow>
                        <ItemRow>{object_info?.info.specialized}</ItemRow>
                      </RowInfo>
                    </>
                  )}
                  {role === "organization" && (
                    <>
                      <RowInfo>
                        <ItemRow>Organization name</ItemRow>
                        <ItemRow>{object_info?.info?.organization}</ItemRow>
                      </RowInfo>

                      <RowInfo>
                        <ItemRow>Representative</ItemRow>
                        <ItemRow>{object_info?.info?.representative}</ItemRow>
                      </RowInfo>
                    </>
                  )}
                  {role === "business" && (
                    <RowInfo>
                      <ItemRow>Business name</ItemRow>
                      <ItemRow>{object_info?.info?.business}</ItemRow>
                    </RowInfo>
                  )}
                  {role !== "personal" && role !== "student" && (
                    <>
                      <RowInfo>
                        <ItemRow>Business owner</ItemRow>
                        <ItemRow>{object_info?.info?.business_owner}</ItemRow>
                      </RowInfo>

                      <RowInfo>
                        <ItemRow>Business code</ItemRow>
                        <ItemRow>{object_info?.info?.businessCode}</ItemRow>
                      </RowInfo>
                    </>
                  )}

                  <RowInfo>
                    <ItemRow>Email</ItemRow>
                    <ItemRow>{object_info.info?.email}</ItemRow>
                  </RowInfo>

                  <RowInfo>
                    <ItemRow>Phone</ItemRow>
                    <ItemRow>{object_info.info?.phone}</ItemRow>
                  </RowInfo>

                  <RowInfo>
                    <ItemRow>Address</ItemRow>
                    <ItemRow>
                      {renderAddress(object_info.info?.address || {})}
                    </ItemRow>
                  </RowInfo>
                  <RowInfo>
                    <ItemRow>Address line</ItemRow>
                    <ItemRow>{object_info.info?.address_line}</ItemRow>
                  </RowInfo>

                  <RowInfo>
                    <ItemRow>Status</ItemRow>
                    <ItemRow>
                      <TextColor
                        color={
                          object_info?.status === "confirmed"
                            ? "green100"
                            : "orange100"
                        }
                      >
                        {object_info?.status}
                      </TextColor>
                    </ItemRow>
                  </RowInfo>
                </Info>
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <div className="form-edit">
          <div className="upload">
            <UploadFile
              id="logo"
              title="Logo"
              ratio="100x100"
              typeUpload="image"
              file={file}
              src={object_info.info?.logo}
              setFile={(f) => setFile(f)}
            />
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormDescription>
              {role.charAt(0).toUpperCase() + role.slice(1)} information
            </FormDescription>

            <FormItem>
              <Dropdown
                transparent={true}
                color={"white100"}
                width={"100%"}
                label="Object Type"
                options={[
                  { label: "Personal", value: "personal" },
                  { label: "Business", value: "business" },
                  { label: "Organization", value: "organization" },
                  { label: "Student", value: "student" },
                ]}
                defaultValue={role}
                onChange={(e) => setRole(e.value)}
              />
            </FormItem>

            {(role === "personal" || role === "student") && (
              <FormItem>
                <Controller
                  name="fullname"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      transparent={true}
                      hasError={Boolean(errors.fullname)}
                      label="Full name"
                      placeholder="Full name"
                      {...field}
                    />
                  )}
                />
              </FormItem>
            )}
            {role === "student" && (
              <>
                <FormItem>
                  <Controller
                    name="schoolName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        transparent={true}
                        hasError={Boolean(errors.schoolName)}
                        label="School's name"
                        placeholder="School's name"
                        {...field}
                      />
                    )}
                  />
                </FormItem>
                <FormItem>
                  <Controller
                    name="schoolCode"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <Input
                        transparent={true}
                        label="School code"
                        placeholder="School code"
                        {...field}
                      />
                    )}
                  />
                </FormItem>
                <FormItem>
                  <Controller
                    name="specialized"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <Input
                        transparent={true}
                        label="Specialized"
                        placeholder="Specialized"
                        {...field}
                      />
                    )}
                  />
                </FormItem>
              </>
            )}
            {role === "organization" && (
              <>
                <FormItem>
                  <Controller
                    name="organization"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        transparent={true}
                        hasError={Boolean(errors.organization)}
                        label="Organization name"
                        placeholder="Organization name"
                        {...field}
                      />
                    )}
                  />
                </FormItem>
                <FormItem>
                  <Controller
                    name="representative"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        transparent={true}
                        hasError={Boolean(errors.representative)}
                        label="Representative"
                        placeholder="Representative"
                        {...field}
                      />
                    )}
                  />
                </FormItem>
              </>
            )}
            {role === "business" && (
              <FormItem>
                <Controller
                  name="business"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      transparent={true}
                      hasError={Boolean(errors.business)}
                      label="Business name"
                      placeholder="Business name"
                      {...field}
                    />
                  )}
                />
              </FormItem>
            )}
            {role !== "personal" && role !== "student" && (
              <>
                <FormItem>
                  <Controller
                    name="business_owner"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        transparent={true}
                        hasError={Boolean(errors.business_owner)}
                        label="Business owner"
                        placeholder="Business owner"
                        {...field}
                      />
                    )}
                  />
                </FormItem>
                <FormItem>
                  <Controller
                    name="businessCode"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        transparent={true}
                        hasError={Boolean(errors.businessCode)}
                        label="Business Code"
                        placeholder="Business code"
                        {...field}
                      />
                    )}
                  />
                </FormItem>
              </>
            )}
            <FormItem>
              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    transparent={true}
                    type="email"
                    hasError={Boolean(errors.email)}
                    label="Email"
                    placeholder="Email"
                    {...field}
                  />
                )}
              />
            </FormItem>
            <FormItem>
              <Controller
                name="phone"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    transparent={true}
                    hasError={Boolean(errors.phone)}
                    label="Phone"
                    placeholder="Phone"
                    {...field}
                  />
                )}
              />
            </FormItem>
            <FormItem>
              <Dropdown
                transparent={true}
                color={"white100"}
                width={"100%"}
                label="Choose the Province / City"
                options={provices?.map((el: any) => {
                  return {
                    label: el?.name,
                    value: el?.code,
                  };
                })}
                defaultValue={"Province / City"}
                onChange={(e) => onChangeAddress(e, "provices")}
              />
            </FormItem>
            <FormItem>
              <Dropdown
                transparent={true}
                color={"white100"}
                width={"100%"}
                label="Districts"
                options={districts?.map((el: any) => {
                  return {
                    label: el?.name,
                    value: el?.code,
                  };
                })}
                defaultValue={"Choose the Districts"}
                onChange={(e) => onChangeAddress(e, "districts")}
              />
            </FormItem>
            <FormItem>
              <Dropdown
                transparent={true}
                color={"white100"}
                width={"100%"}
                label="Wards"
                options={wards?.map((el: any) => {
                  return {
                    label: el?.name,
                    value: el?.code,
                  };
                })}
                defaultValue={"Choose the Wards"}
                onChange={(e) => onChangeAddress(e, "wards")}
              />
            </FormItem>
            <FormItem>
              <Controller
                name="address_line"
                control={control}
                render={({ field }) => (
                  <Input
                    transparent={true}
                    hasError={Boolean(errors.address_line)}
                    label="Address line"
                    placeholder="Enter your address line"
                    {...field}
                  />
                )}
              />
            </FormItem>
            <FormItem>
              <br />
              <Submit>
                <Button loading={loading} type="submit">
                  Submit
                </Button>
              </Submit>
            </FormItem>
          </Form>
        </div>
      )}
    </ObjectWrapper>
  );
};

export default View;
