import {
  Upload,
  OptionVideoImage,
} from "pages/home/landing-page/styles";

import Button from "components/button";
import UploadFile from "components/uploadFile";

import Banner from "components/business-landing-page/components/Banner";
import Input from "components/input";
import { ContainerItemSurvey, TitleItemSurvey } from "pages/events/styles";

const View = (props) => {
  const {
    isEdit,
    type,
    item,
    index,
    setFile,
    handleAddIconContent,
    onChangeDataSurveyBanner,
    onBlurText,
    openTagWindow,
  } = props
  return <ContainerItemSurvey>
    <OptionVideoImage>
      <div className="_logo_survey">
        {
          isEdit ? <Upload>
            <UploadFile
              id={item.id}
              index={index}
              ratio="50x50"
              sectionName="survey"
              file={item.file}
              src={item.src}
              setFile={setFile}
            />
          </Upload> : (type !== "banner_ads" || item.src ) &&
          <img src={item.src || "/assets/images/vzx-64.png"} alt="logo" onClick={() => openTagWindow(item?.listIcon?.link)}/>
        }
      </div>
    </OptionVideoImage>
    <div className="_item_" onClick={() => (!isEdit ? openTagWindow(item?.listIcon.link) : "")}>
      <Banner
        id={item.id}
        index={index}
        isEdit={isEdit}
        isSlide={true}
        linkVideo={item.urlVideo}
        listSlideBanner={item?.slide}
        onChangeData={onChangeDataSurveyBanner}
      />
    </div>
    {
      isEdit && (
        <Input
          transparent={true}
          color={"orange"}
          defaultValue={type === "banner_ads" ? item?.link : item?.name}
          suffixIcon={
            type !== "banner_ads" && 
            <Button className={'_suffixIcon'} onClick={() => handleAddIconContent(item.listIcon || {}, item.id, index)}>{'CHAT'}</Button>
          }
          onBlur={(e) =>
            onBlurText(e, "list_survey", index, "name", item.id, "content")
          }
          placeholder={`Enter ${type === "banner_ads" ? "link" : "title"}`}
        />
      )
    }
    {
      type === "single" && <TitleItemSurvey>{item?.name?.toUpperCase()}</TitleItemSurvey>
    }
  </ContainerItemSurvey>
}

export default View