import { AddOrRemoveChil } from "pages/home/landing-page/styles";
import {
  Wrapper,
  Container,
  Content,
  Detail,
  ItemField,
  FieldContainer,
  Attachment
} from "./styles";

import { v4 as uuidv4 } from "uuid";
import Input from "components/input";
import Dropdown from "components/dropdown";

import Switch from "react-switch";

const View = (props) => {
  const {
    state,
    setState,
  } = props

  const listType = [
    {
      label: 'Input',
      value: 'input'
    },
    {
      label: 'Textarea',
      value: 'textarea'
    },
    // {
    //   label: 'Dropdown',
    //   value: 'dropdown'
    // }
  ]

  const handleAddOrRemoveChil = (type: string, key: string, uuid?: string) => {
    if (type === "add") {
      if(key === "title"){
        setState(
          (state || []).concat({
            id: uuidv4(),
            title: '',
            fields: [],
            attachments: false,
          })
        );
      } else {
        setState(prev => prev.map(el => el.id === uuid ? {
          ...el,
          fields: el?.fields?.concat({
            id: uuidv4(),
            label: '',
            placeholder: '',
            typeField: '',
            options: []
          })
        } : el
        ));
      }
     
    } else {
      if(key === "title"){
        const newArr = state?.slice(0, state.length - 1) || [];
      	setState(newArr);
      } else {
        setState(prev => prev.map(el => el.id === uuid ? {
          ...el,
          fields: el?.fields?.slice(0, el?.fields?.length - 1) || []
        } : el
        ));
      }
      
    }
  };

  const onChangeField = (e: any, key: string, uuid: string, uuidChil?: string) => {
    if(key === "title"){
      setState(prev => prev.map(el => el.id === uuid ? {
        ...el,
        title: e.target.value
      } : el));
    } else {
      setState(prev => prev.map(el => el.id === uuid ? {
        ...el,
        fields: el?.fields?.map(field => field.id === uuidChil ? {
          ...field,
          [key]: e.target.value
        } : field)
      } : el
      ));
    }
  }

  const onChangTypeField = (data: any, uuid: string, uuidChil?: string) => {
    setState(prev => prev.map(el => el.id === uuid ? {
      ...el,
      fields: el?.fields?.map(field => field.id === uuidChil ? {
        ...field,
        typeField : data.value
      } : field)
    } : el
    ));
  }

  const handleChangeAttachment = async (checked, uuid: string) => {
    setState(prev => prev.map(el => el.id === uuid ? {
      ...el,
      attachments: checked
    } : el));
  };
	
  return <Wrapper>
    <Container>
      <h4>Create Review Form: 
        <AddOrRemoveChil mt={"0px"}>
          <div
            onClick={() =>
              handleAddOrRemoveChil("add" , "title")
            }
          >
            +
          </div>
          <div
            onClick={() =>
              handleAddOrRemoveChil("remove", "title")
            }
          >
            -
          </div>
        </AddOrRemoveChil>
      </h4> 
      <Content>
        {
          state?.length > 0 &&
					state.map(item => 
					  <Detail>
					    <ItemField>
					    	<Input 
									 transparent={true}
									 color={"orange"}
									 defaultValue={item?.title}
									 label="Title"
									 placeholder="Title"
					         onChange={(e) => onChangeField(e, "title", item.id)}
					      />
					    </ItemField>
					    <FieldContainer>
					      <h5>Field:
					        <AddOrRemoveChil mt={"0px"}>
					          <div
					            onClick={() =>
					              handleAddOrRemoveChil("add" , "field", item.id)
					            }
					          >
            						+
					          </div>
					          <div
					            onClick={() =>
					              handleAddOrRemoveChil("remove", "field", item.id)
					            }
					          >
            						-
					          </div>
					        </AddOrRemoveChil>
					      </h5>
					      {
					        item?.fields?.length > 0 &&
									item?.fields?.map(field => <div className="_item_field">
									  <Input 
									    transparent={true}
									    defaultValue={field?.label}
									    label="Label"
									    placeholder="Enter label"
										  onChange={(e) => onChangeField(e, "label", item.id, field.id)}
									  />
									  <Input 
									    transparent={true}
									    defaultValue={field?.placeholder}
									    label="Placeholder"
									    placeholder="Enter placeholder"
										  onChange={(e) => onChangeField(e, "placeholder", item.id, field.id)}
									  />
									  <Dropdown
									    transparent={true}
									    width={"100%"}
									    label={'Type field'}
									    options={listType?.map((el: any) => {
									      return {
									        label: el?.label,
									        value: el?.value
									      }
									    })}
									    defaultValue={field.typeField || "Choose the type"}
									    onChange={(e) => onChangTypeField(e, item.id, field.id)}
									  />
									</div>)
					      }
					    </FieldContainer>
					    <Attachment>
					      <label>Attachment:</label>
					      <Switch onChange={(e) => handleChangeAttachment(e, item.id)} checked={item?.attachments || false} />
					    </Attachment>
					  </Detail>	
					)
        }
      </Content>
    </Container>
  </Wrapper>
}

export default View;