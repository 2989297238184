import { useState } from "react";
import ReactPlayer from "react-player";
import { BiVolumeMute } from "react-icons/bi";
import { VscUnmute } from "react-icons/vsc";

import { Container, ImageBottom, ContainerImgBottom } from "./styles";
import { Upload } from "pages/home/landing-page/styles";
import UploadFile from "components/uploadFile";

const View = (props) => {
  const { type, page, isEdit, link, imageBottom, setFile  } = props;

  const [mute, setMute] = useState<boolean>(true)

  const handleMute = (e) => {
    e.stopPropagation();
    setMute(!mute)
  }

  return <Container>
    <div className="container_video">
      <ReactPlayer
        width={"100%"}
        height={"100%"}
        playing={true} 
        muted={mute}
        loop={true} 
        url={link}
      />
      <div className="_speaker" onClick={handleMute}>
        {
          mute ? <BiVolumeMute size={30} color="white" /> : <VscUnmute size={30} color="white" />
        }
      </div>
      {
        (type === "banner" && page === "event") && <ImageBottom>
          {
            isEdit ? (
              <Upload className="img_bottom_banner">
                <UploadFile
                  id={"img_bottom_banner"}
                  ratio="1500x150"
                  sectionName="image_bottom_banner"
                  file={imageBottom?.file}
                  src={imageBottom?.src}
                  setFile={setFile}
                />
              </Upload>
            ) : <ContainerImgBottom>
              <img src={imageBottom?.src} alt="" />
            </ContainerImgBottom>
          }
        </ImageBottom>
      }
    </div>
  </Container>
}

export default View;