import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "api";
import { IRequestData } from "api/request";

export const getProductsAsync = createAsyncThunk(
  "shop/products",
  async (req: IRequestData) => {
    try {
      const response: any = await API.PRODUCT.getProducts(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);
