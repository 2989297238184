import { AiFillCloseCircle } from "react-icons/ai";
import { setAdvertisement } from "redux/reducers/advertisments/advertisments.reducers";
import { useAppDispatch } from "redux/reducers/hook";
import { Wrapper, WrapperLeft } from "./styles";

const Left = ({ data, isHome }) => {
  const dispatch = useAppDispatch();

  return (
    <WrapperLeft className="ad-wrapper-left" props={{ isHome }}>
      <Wrapper>
        <div
          className="close"
          onClick={() => dispatch(setAdvertisement({ left: null }))}
        >
          <AiFillCloseCircle size={20} />
        </div>
        <img src={data.banner} alt="" />
        <p>{data.name && data.name.toUpperCase()}</p>
        <a href={data.url} target={"_blank"} rel="noreferrer">
          {" "}
          DISCOVER NOW
        </a>
      </Wrapper>
    </WrapperLeft>
  );
};

export default Left;
