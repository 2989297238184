/* eslint-disable react-hooks/exhaustive-deps */
import QRCode from "react-qr-code";
import {
  DetailRightView,
  Title,
  MarginTop,
  StockAddress,
  WalletInfo,
  Warning,
  QrCodeWrapper,
  CopyAndViewQR,
  TitleModal,
  ContainerModal,
  ContentModal,
  ActionModal,
  Description,
} from "../../../styles";
import Button from "components/button";
import { useSelector } from "react-redux";

import { getUserProfile } from "redux/reducers/users/users.selector";
import { getWalletSelected } from "redux/reducers/wallets/wallets.selector";

import { copyText, formatCurrency } from "utils/common";
import { toast } from "react-toastify";
import { useEffect, useRef, useState } from "react";
import Input from "components/input";
import Modal from "components/modal";

import Network from "../../networks";

import { exportComponentAsJPEG } from "react-component-export-image";

const View = (props) => {
  // const dispatch = useDispatch();
  const imgQrRef = useRef();
  const profile: any = useSelector(getUserProfile);
  const walletSelected: any = useSelector(getWalletSelected);

  const walletAddress = walletSelected.address || "N/A";
  const tokenName = String(walletSelected.token || "N/A").toUpperCase();

  const [amount, setAmount] = useState<number>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [network, setNetwork] = useState("vzonex");

  const handleChange = (e) => {
    setAmount(e);
  };
  const handleCopy = () => {
    copyText(walletAddress);
    toast.success("Copied to clipboard!");
  };

  const handlePreview = () => {
    setOpenModal(true);
  };

  const handleDownload = () => {
    exportComponentAsJPEG(imgQrRef, {
      fileName: `${"Qr-code"}_vzonex_${new Date().getTime()}` || "vzonex",
    });
    setOpenModal(false);
  };

  useEffect(() => {
    document.getElementById("right").scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  }, []);

  const renderQr = () => {
    return (
      <>
        <ContainerModal ref={imgQrRef}>
          <TitleModal>
            <img src="/assets/images/logo0.png" alt="" />
          </TitleModal>
          <ContentModal>
            <QrCodeWrapper>
              <QRCode
                value={`vzxwallet-${tokenName}-${walletAddress}${
                  amount >= 0 && `-${amount}`
                }`}
                size={280}
              />
            </QrCodeWrapper>
            <MarginTop mt="20px" />
            <p>{`${walletAddress.slice(0, 12)}...${walletAddress.slice(
              -12
            )}`}</p>

            {amount > 0 && <h3>{`${formatCurrency(amount)} ${tokenName}`}</h3>}
          </ContentModal>
          <Description>
            <p>
              Send only{" "}
              <span>
                {tokenName} ({"vzonex".toUpperCase()})
              </span>{" "}
              to this address.
              <br /> Sending and other coins may result in permanent loss.{" "}
            </p>
          </Description>
        </ContainerModal>
      </>
    );
  };

  const handleClosed = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    props.getWalletByOptionAsync({
      params: {
        userId: profile._id,
      },
      query: {
        network: network,
        token: props.match.params.stock,
      },
    });
  }, [props.match.params.stock, network]);

  return (
    <>
      <DetailRightView>
        <Title bgColor={"grey200"} just="space-between">
          <p>Deposit {tokenName}</p>
        </Title>
        <WalletInfo>
          <Network network={network} setNetwork={(e) => setNetwork(e)} />
          <MarginTop mt="10px" />
          <span>{tokenName} Address</span>
          <StockAddress>
            <MarginTop mt="20px" />
            <QrCodeWrapper>
              <QRCode
                value={`vzxwallet-${tokenName}-${walletAddress}${
                  amount >= 0 && `-${amount}`
                }`}
                size={150}
              />
            </QrCodeWrapper>
            <MarginTop mt="20px" />
            <p>{walletAddress}</p>
            <MarginTop mt="8px" />
            <CopyAndViewQR>
              <Button transparent={true} onClick={handleCopy}>
                Copy To Clipboard
              </Button>
              <Button transparent={true} onClick={handlePreview}>
                Preview
              </Button>
            </CopyAndViewQR>
          </StockAddress>
          <MarginTop mt="20px" />
          <Input
            isCurrency={true}
            transparent={true}
            label={`Amount (${tokenName})`}
            value={amount}
            onValueChange={(e) => handleChange(e)}
            placeholder="10,000"
          />
        </WalletInfo>
        <WalletInfo>
          {/* <p>
            Deposit fee: <SpanColor color="red100">0.00000000</SpanColor> VZX
          </p> */}
          <Warning>
            <img src="/assets/images/icons/warning.svg" alt="warning" />
            <p>
              You have to deposit at least 0.5 {tokenName} to be credited. Any
              deposit that is less than 0.5 {tokenName} will not be refunded
            </p>
          </Warning>
          <Warning>
            <img src="/assets/images/icons/warning.svg" alt="warning" />
            <p>
              This deposit address only accepts {tokenName}. Do not send other
              coins to it.
            </p>
          </Warning>
        </WalletInfo>
      </DetailRightView>
      <Modal
        isOpen={openModal}
        children={renderQr()}
        onCancel={handleClosed}
        onConfirm={handleDownload}
        confirmLabel={
          <ActionModal>
            <img src="/assets/images/icons/download.svg" alt="" />
          </ActionModal>
        }
        centered={true}
      />
    </>
  );
};

export default View;
