import React from "react";
import UploadFile from "components/uploadFile";
import { Upload } from "pages/home/landing-page/styles";

import { Wrapper, Avatar } from "./styles";

const View = (props: any) => {
  const { isEdit, info, index, textColor, onBlurChil, setFile } = props;

  return (
    <Wrapper textColor={textColor}>
      {
        <>
          <Avatar>
            {isEdit ? (
              <Upload>
                <UploadFile
                  id={info.id}
                  ratio="300x300"
                  sectionName="guest"
                  file={info.file}
                  setFile={setFile}
                  src={info.src}
                />
              </Upload>
            ) : (
              <img src={info.src || "/assets/images/vzx-64.png"} alt="guest" />
            )}
          </Avatar>
          <h4
            contentEditable={isEdit}
            onBlur={(e) => onBlurChil(e, index, "position")}
          >
            {info.position || "Tổng giám đốc"}
          </h4>
          <h5
            contentEditable={isEdit}
            onBlur={(e) => onBlurChil(e, index, "company")}
          >
            {info.company || "Vzone Global"}
          </h5>
        </>
      }
    </Wrapper>
  );
};

export default View;
