import Modal from "./styles";
import { ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Button from "components/button";
import { PaginateWrapper, TabsWrapper } from "pages/my/statis/styles";

type Props = {
  isOpen?: any;
  title?: any;
  onCancel?: any;
  onConfirm?: any;
  cancelLabel?: any;
  confirmLabel?: any;
  loading?: any;
  centered?: any;
  fullscreen?: any;
  showImage?: any;
  children?: any;
  disableConfirm?: boolean;
  element?: any;
  onDelete?: any;
  deleteLabel?: any;
  tabs?: any;
  onChangeTab?: any;
  isPaginate?: boolean;
  lazyParams?: any;
  onChangeParams?: any;
  totalPages?: number;
  activeTab?: string
};

const View = ({ children, ...props }: Props) => {
  const {
    isOpen,
    title,
    onCancel,
    disableConfirm,
    onConfirm,
    cancelLabel,
    confirmLabel,
    loading = false,
    centered = false,
    fullscreen = false,
    showImage = false,
    element,
    onDelete,
    deleteLabel,
    tabs,
    onChangeTab,
    isPaginate = false,
    lazyParams,
    onChangeParams,
    totalPages,
    activeTab
  } = props;

  return (
    <>
      <Modal
        centered={centered}
        isOpen={isOpen}
        fullscreen={fullscreen}
        showImage={showImage}
      >
        {tabs && tabs.length > 0 && (
          <TabsWrapper>
            {tabs.map((item, index) => (
              <div
                className={`tab-item ${activeTab === item?.type && "active-tab"}`}
                key={index}
                onClick={() => onChangeTab(item?.type)}
              >
                <span className="icon">{item?.icon}</span>
                <span>{item.name}</span>
              </div>
            ))}
          </TabsWrapper>
        )}
        {title && <ModalHeader>{title}</ModalHeader>}
        <ModalBody>{children}</ModalBody>
        {isPaginate && (
          <PaginateWrapper>
            <Button
              width={24}
              height={16}
              disabled={lazyParams?.page === 1}
              onClick={() =>
                onChangeParams({
                  ...lazyParams,
                  page: lazyParams?.page - 1,
                })
              }
            >
              Previous
            </Button>
            <Button
              width={24}
              height={16}
              disabled={lazyParams?.page >= totalPages}
              onClick={() =>
                onChangeParams({
                  ...lazyParams,
                  page: lazyParams?.page + 1,
                })
              }
            >
              Next
            </Button>
          </PaginateWrapper>
        )}
        <ModalFooter>
          {onCancel && (
            <Button
              transparent={true}
              disabled={loading}
              onClick={() => onCancel()}
            >
              {cancelLabel || "Close"}
            </Button>
          )}

          {onDelete && (
            <Button
              transparent={true}
              disabled={loading}
              onClick={() => onDelete()}
              color={"red100"}
            >
              {deleteLabel || "Delete"}
            </Button>
          )}

          {onConfirm && (
            <Button
              onClick={() => onConfirm()}
              loading={loading}
              disabled={disableConfirm}
            >
              {confirmLabel || "Confirm"}
            </Button>
          )}

          {element && element}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default View;
