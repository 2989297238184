/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom"
import Loading from "components/loading";
import NavBottom from "layouts/components/navbar-bottom";
import Bg from "./iframeVideo";

import Wrapper, {
  Back,
  Container,
  Content,
  FormTitle,
  BgFooter
} from "./styles";

import Step1 from './step1'
import Step2 from './step2'
// import Step3 from './Step3'

import APIs from "api"
import { BottomHome, HomeLayout } from "pages/home/styles";
import Header from "layouts/components/header";
import Footer from "layouts/components/footer";
interface IProps {
  [key: string]: any;
}

export default function View(props: IProps) {
  const prams: any = useParams();
  const { role, symbol } = prams

  const history = useHistory()

  const [isLoading, setIsLoading] = useState<boolean>(true)

  const getYourEstock = async () => {
    try {
      if (symbol) {
        const yourEstock: any = await APIs.YOURESTOCK.getYourEstock(symbol)
        if (yourEstock) {
          setIsLoading(false)
        } else {
          setIsLoading(false)
        }
      } else {
        setIsLoading(false)
      }
    } catch (error) {
      // console.log();
    }
  }

  useEffect(() => {
    getYourEstock()
  }, [])

  return (
    <HomeLayout>
      <Header main={true} />
      {
        isLoading && <Loading />
      }
      {
        <Wrapper>
          <Bg />
          <Container>
            <Content>
              <FormTitle>Create, share idea Project</FormTitle>
              {
                !role ? <Step1 /> : <>
                  <Back onClick={() => history.push('/create-your-estock')}>
                    <img src="/assets/images/icons/backspace.svg" alt="" />
                  </Back>
                  <Step2 role={role} />
                </>
              }
            </Content>
          </Container>
          <BgFooter>
            <Footer main={true} />
          </BgFooter>
        </Wrapper>
      }
      <BottomHome>
        <NavBottom />
      </BottomHome>
    </HomeLayout>
  );
}
