import APIs from "api";
import Button from "components/button";
import Input from "components/input";
import Modal from "components/modal";
import VTable from "components/table";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { BiDonateHeart } from "react-icons/bi";
import { FaDonate } from "react-icons/fa";
import { toast } from "react-toastify";
import { getCryptos } from "redux/reducers/cryptos/cryptos.selector";
import { useAppSelector } from "redux/reducers/hook";
import { DonateWrapper, Histories, Thanks, WrapperForm } from "./styles";

const columns = [
  {
    key: "Id",
    title: "Id",
    dataIndex: "_id",
  },
  {
    key: "User",
    title: "User",
    render: (item: any) => `${item.user_id && item.user_id["refer_code"]}`,
  },
  {
    key: "Amount",
    title: "Amount",
    render: (item) => item.amount + `(${String(item.token).toUpperCase()})`,
  },
];

const View = ({ setOpenMenu }) => {
  const stocks = useAppSelector(getCryptos);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    stock: "vnex",
    amount: 0,
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const [type, setType] = useState("dev");
  const [histories, setHistories] = useState([]);

  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await APIs.TRANSACTION.donate({
        body: { ...form, type },
      });
      getData();
      setIsSuccess(true);
      setLoading(false);
      setForm({
        stock: "vnex",
        amount: 0,
      });
    } catch (error) {
      setLoading(false);
      toast.error(error.errors);
    }
  };

  const renderHistories = (type) => {
    const data = histories.find((i) => i.key === type);
    const transactions = data ? data["transactions"] : [];
    return (
      <Histories>
        {" "}
        <VTable columns={columns} dataSource={transactions}></VTable>
      </Histories>
    );
  };

  const renderModal = () => {
    return (
      <Modal
        isOpen={openModal}
        centered={true}
        onCancel={() => {
          setOpenModal(false);
          setIsSuccess(false);
        }}
      >
        {!isSuccess ? (
          <WrapperForm>
            <h3>
              {type === "charity"
                ? "Vzone Charity Foundation".toUpperCase()
                : "DONATE FOR " + type.toUpperCase()}
            </h3>
            <Input
              transparent={true}
              placeholder="VNEX"
              label="Stock"
              name="stock"
              type="select"
              value={form.stock}
              onChange={handleChange}
            >
              {stocks?.length &&
                stocks.map((stock) => (
                  <option value={stock.symbol}>
                    {stock.name} ({String(stock.symbol).toUpperCase()})
                  </option>
                ))}
            </Input>

            <Input
              isCurrency={true}
              transparent={true}
              placeholder="10"
              label="Amount"
              name="amount"
              value={form.amount}
              allowNegativeValue={false}
              // hasError={errors.stock_price}
              onValueChange={(e) => setForm((form) => ({ ...form, amount: e }))}
            />

            <Button loading={loading} onClick={handleSubmit}>
              Submit
            </Button>
          </WrapperForm>
        ) : (
          <Thanks>
            <img src={"/assets/images/thanks.gif"} alt="" />
          </Thanks>
        )}

        {renderHistories(type)}
      </Modal>
    );
  };

  const getData = async () => {
    try {
      const res: any = await APIs.TRANSACTION.getTransactionsDonate({});
      setHistories(res);
    } catch (error) {}
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <DonateWrapper>
      {renderModal()}
      {/* <img
        className="bounce-2"
        onClick={() => setOpenModal(true)}
        src={"/assets/images/donate.png"}
        alt=""
      /> */}
      <div
        className="charity"
        onClick={() => {
          isMobile && setOpenMenu(false);
          setType("charity");
          setOpenModal(true);
        }}
      >
        <BiDonateHeart color="red" size={30} />
      </div>
      <div
        className="system"
        onClick={() => {
          isMobile && setOpenMenu(false);
          setType("dev");
          setOpenModal(true);
        }}
      >
        <FaDonate color="green" size={25} />
      </div>
    </DonateWrapper>
  );
};

export default View;
