import { combineReducers } from "redux";
import Crypto, { ICryptoState } from "./cryptos/cryptos.reducer";
import Banks, { IBanksState } from "./banks/banks.reducer";

import User, { IUsersState } from "./users/users.reducer";
import Wallet, { IWalletState } from "./wallets/wallets.reducer";
import Auth, { IRegisterState } from "./auth/auth.reducer";
import Security, { ISecurityState } from "./securities/securities.reduce";
import ProjectSlice, { IProjectState } from "./projects/projects.reducer";
import ProductsSlice, { IProductsState } from "./products/products.reducer";
import OrdersSlice, { IPruchasesState } from "./purchases/purchases.reducer";
import TasksSlice, { ITasksState } from "./tasks/tasks.reducer";
import advertismentsReducers, {
  IAdvertisementState,
} from "./advertisments/advertisments.reducers";
import settingsReducer, { ISettingState } from "./settings/settings.reducer";
import NotificationSlice, {
  INotificationState,
} from "./notifications/notifications.reducers";
import InvestsSlice, { IInvestState } from "./invests/invests.reducer";

export interface IRootState {
  users: IUsersState;
  wallets: IWalletState;
  cryptos: ICryptoState;
  banks: IBanksState;
  auth: IRegisterState;
  securities: ISecurityState;
  projects: IProjectState;
  products: IProductsState;
  purchases: IPruchasesState;
  tasks: ITasksState;
  advertisements: IAdvertisementState;
  settings: ISettingState;
  notifications: INotificationState;
  invests: IInvestState;
}

export default combineReducers<IRootState>({
  users: User,
  wallets: Wallet,
  cryptos: Crypto,
  banks: Banks,
  auth: Auth,
  securities: Security,
  projects: ProjectSlice,
  products: ProductsSlice,
  purchases: OrdersSlice,
  tasks: TasksSlice,
  advertisements: advertismentsReducers,
  settings: settingsReducer,
  notifications: NotificationSlice,
  invests: InvestsSlice,
});
