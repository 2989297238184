/* eslint-disable max-len */
import {
  ItemFooter,
  LogoFooter,
  ListIcons,
  ItemContainer,
  Flex,
  ItemContent,
  AddLinkFooter,
} from "./styles";

import { Link, useParams } from "react-router-dom";

import { Upload } from "../header/styles";

import UploadFile from "components/uploadFile";

import { Col, Container } from "reactstrap";

import GoogleMap from "components/google-map";

const View = (props: any) => {
  const {
    isEdit,
    main,
    dataUser,
    dataEstock,
    dataProject,
    listFiles,
    setFile,
    onBlurText,
    handleOpenPopup,
  } = props;
  
  const prams: any = useParams();

  return (
    <>
      <Container>
        <Flex>
          <Col md={5}>
            <ItemContent>
              <ItemFooter>
                <ItemContainer>
                  <LogoFooter>
                    {isEdit && (
                      <Upload
                        exception={Boolean(
                          !listFiles && dataEstock?.logoFooter
                        )}
                        width="50%"
                      >
                        <UploadFile
                          id="logoFooter"
                          ratio="213x40"
                          file={listFiles}
                          setFile={setFile}
                        />
                      </Upload>
                    )}
                    {main ? (
                      <img src={"/assets/images/logo.png"} alt="logoFooter" />
                    ) : (
                      <img
                        hidden={listFiles || !dataEstock?.logoFooter}
                        src={dataEstock?.logoFooter}
                        alt="logoFooter"
                      />
                    )}
                  </LogoFooter>
                  <span
                    data-key={"footer_desc"}
                    contentEditable={isEdit}
                    onBlur={onBlurText}
                  >
                    {dataEstock?.footer_desc ||
                      "Website is in the process of Experimental Research, Copyright belongs to Blockchain Technology and Education Joint Stock Company."}
                  </span>
                  <ListIcons>
                    <img
                      src="/assets/images/VDB-64.png"
                      alt=""
                      onClick={() =>
                        window.open(
                          main
                            ? "https://vdiarybook.vn/"
                            : dataEstock?.vdiarybook
                        )
                      }
                    />
                    <img
                      src="/assets/images/facebook.png"
                      alt=""
                      onClick={() =>
                        window.open(
                          main
                            ? "https://www.facebook.com/vzonexglobal/"
                            : dataEstock?.facebook
                        )
                      }
                    />
                    <img
                      src="/assets/images/youtube.png"
                      alt=""
                      onClick={() =>
                        window.open(
                          main
                            ? "https://www.youtube.com/channel/UC1t7nB4UyRgXytWtVW7CfdA"
                            : dataEstock?.youtube
                        )
                      }
                    />
                    <img
                      src="/assets/images/twitter.png"
                      alt=""
                      onClick={() =>
                        window.open(
                          main
                            ? "https://twitter.com/vzonex"
                            : dataEstock?.twitter
                        )
                      }
                    />
                    <img
                      src="/assets/images/telegram.png"
                      alt=""
                      onClick={() =>
                        window.open(
                          main
                            ? "https://t.me/vzonexglobal"
                            : dataEstock?.telegram
                        )
                      }
                    />
                  </ListIcons>
                  {isEdit && (
                    <AddLinkFooter
                      mt="-20px"
                      onClick={() => handleOpenPopup("footer")}
                    >
                      Click here to add link
                    </AddLinkFooter>
                  )}
                </ItemContainer>
              </ItemFooter>
            </ItemContent>
          </Col>
          <Col md={3}>
            <ItemContent>
              <ItemFooter>
                <h6>Important Link</h6>
                <p>Home</p>
                <p>Feature</p>
                <p>Integrations</p>
                <p>About</p>
                <Link
                  to={
                    main ? `/terms` : `/${dataUser?.role}/${prams.symbol}/terms`
                  }
                  target="_blank"
                >
                  <p>User Agreement and Privacy Policy.</p>
                </Link>
              </ItemFooter>
            </ItemContent>
          </Col>
          <Col md={4}>
            <ItemContent>
              <ItemFooter>
                <h6>Informations</h6>
                {
                  (dataUser?.role !== "personal" && dataUser?.role !== "student") && <span>
                    <img src="/assets/images/icons/company.svg" alt="" />
                    <div>
                    Name: &nbsp;
                      <b>{main ? "BLOCKCHAIN EDUCATION AND TECHNOLOGY CO .,JSC" : dataUser?.fullname}</b>
                    </div>
                  </span>
                }
                <span>
                  <img src="/assets/images/icons/map.svg" alt="" />
                  <div>
                    Address:&nbsp;
                    <b>
                      {dataUser?.address ||
                        "No. 6 Lot A12 Le Trong Tan KDT - Geleximco, An Khanh Ward, Hoai Duc District, Hanoi City, Vietnam"}
                    </b>
                  </div>
                </span>
                {!main && (
                  <>
                    <span>
                      <img src="/assets/images/icons/business.svg" alt="" />
                      <div>
                        Phone: &nbsp;<b>{dataUser?.phone}</b>
                      </div>
                    </span>
                    <span>
                      <img src="/assets/images/icons/business.svg" alt="" />
                      <div>
                        Email: &nbsp;<b>{dataUser?.email}</b>
                      </div>
                    </span>
                  </>
                )}
                {(dataUser?.role === "personal" || dataUser?.role === "student") ? (
                  <>
                    <span>
                      <img src="/assets/images/icons/user.svg" alt="" />
                      <div>
                        Full Name: &nbsp;<b>{dataUser?.fullname}</b>
                      </div>
                    </span>
                    {
                      dataUser?.role === "student" && <>
                        <span>
                          <img src="/assets/images/icons/company.svg" alt="" />
                          <div>
                            School: &nbsp;<b>{dataUser?.studentData?.schoolName}</b>
                          </div>
                        </span>
                        <span>
                          <img src="/assets/images/icons/business.svg" alt="" />
                          <div>
                            School code : &nbsp;<b>{dataUser?.studentData?.schoolCode}</b>
                          </div>
                        </span>
                        <span>
                          <img src="/assets/images/icons/world.svg" alt="" />
                          <div>
                            Specialized: &nbsp;<b>{dataUser?.studentData?.specialized}</b>
                          </div>
                        </span>
                      </>
                    }
                  </>
                ) : (
                  <>
                    {!main && (
                      <span>
                        <img src="/assets/images/icons/business.svg" alt="" />
                        <div>
                          {dataUser?.organizationsData
                            ? "Organization"
                            : "Business"}{" "}
                          Name: &nbsp;
                          <b>
                            {dataUser?.organizationsData?.organization ||
                              dataUser?.businessData?.business}
                          </b>
                        </div>
                      </span>
                    )}

                    <span>
                      <img src="/assets/images/icons/business.svg" alt="" />
                      <div>
                        Business Code: &nbsp;
                        <b>
                          {dataUser?.businessData?.businessCode || "0110394011"}
                        </b>
                      </div>
                    </span>
                    <span>
                      <img src="/assets/images/icons/user.svg" alt="" />
                      <div>
                        Agent Name: &nbsp;
                        <b>
                          {dataUser?.organizationsData?.representative ||
                            dataUser?.businessData?.business_owner ||
                            "LE TRUNG HIEU"}
                        </b>
                      </div>
                    </span>
                  </>
                )}
              </ItemFooter>
              {isEdit && <GoogleMap />}
            </ItemContent>
          </Col>
        </Flex>
        <Flex main={main}>
          <Col md={7}>
            <p>
              Start-up project Blockchain platform application in operation
              management of small and medium enterprises, Crowdfunding, Internal
              stock management, Digital transformation ....{" "}
            </p>
            <p> Founder & CEO : Le Trung Hieu </p>
            <p>
              {" "}
              Project owner: Blockchain technology and education joint stock
              company{" "}
            </p>
            <p> Project Director : Nguyen Tran Nhat Tri</p>
            <p> Chief Technology Officer : Phan Van Tinh</p>
          </Col>
          <Col md={5}></Col>
        </Flex>
        <br />
        <span>
          <img src="/assets/images/icons/copyright.svg" alt="" /> 2022 | All
          Right Reserved By {dataEstock?.name || dataProject?.name || "Vzonex"}
        </span>
      </Container>
    </>
  );
};

export default View;
