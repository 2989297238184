import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRequestData } from "api/request";
import UserAPIs from "api/users";
import WalletAPIs from "api/wallets";

import decode from "utils/decodeToken";
import {
  getBalancesAsync,
  getCommissionsAsync,
  getKycAsync,
  getSalesStatisticsAsync,
  getSharingsAsync,
} from "./users.actions";

type IUserProfile = {
  _id: string;
  refer_code: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  username: string;
  fullName: string;
  avatar: string;
  wallet?: string;
  email_secure?: boolean;
  pin_secure?: boolean;
  public_address?: string;
  login_by_address?: boolean;
  percent_sales?: number;
  sponsor: any;
  commission?: any;
  is_leader?: boolean;
  is_claim?: boolean;
  is_withdraw?: boolean;
  is_deposit?: boolean;
  percent_region?: number;
  percent_provice?: number;
  id_region?: boolean;
  id_province?: boolean;
  object_type?: string;
  address?: any;
  channels?: any;
};

export const getUser = createAsyncThunk("users/getUser", async () => {
  const user: any = await UserAPIs.getUserAuth();

  const vzxWallet: any = await WalletAPIs.getWalletsByUserAndOptions({
    params: {
      userId: user.user._id,
    },
    query: {
      network: "vzonex",
      token: "vzx",
    },
  });

  return {
    ...user,
    vzxWallet,
  };
});

export const editUser = createAsyncThunk(
  "users/editUser",
  async (req: IRequestData) => {
    const user = await UserAPIs.editUser(req);
    return user;
  }
);

export interface IUsersState {
  token: string | null;
  profile: IUserProfile;
  user: any;
  isLoggedIn: boolean;
  connectSocket: boolean;
  balances: any;
  locked_balances: any;
  commissions: any;
  sharings: any;
  vzxWallet?: any;
  verify: any;
  is_leader?: boolean;
  sales_statistics: any;
}

const defaultUser: any = decode(localStorage.getItem("auth"));

const initialState: IUsersState = {
  token: null,
  profile: defaultUser || {},
  isLoggedIn: false,
  user: {},
  connectSocket: false,
  balances: {},
  locked_balances: {},
  commissions: [],
  sharings: [],
  verify: null,
  sales_statistics: [],
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setToken: (state: IUsersState, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setProfile: (state: IUsersState, action: PayloadAction<IUserProfile>) => {
      state.profile = {
        ...(state.profile || {}),
        ...action.payload,
      };
    },
    setConnectSocket: (state: IUsersState, action: PayloadAction<boolean>) => {
      state.connectSocket = action.payload;
    },
    setBalances: (state, action) => {
      state.balances = action.payload.balances;
      state.locked_balances = action.payload.locked_balances;
    },
    setCommissions: (state, action) => {
      state.commissions = action.payload;
    },
  },
  extraReducers: {
    [`${getUser.pending}`]: (state: IUsersState, { payload }) => {},
    [`${getUser.fulfilled}`]: (state: IUsersState, { payload }) => {
      const { errors, user, vzxWallet } = payload;
      if (errors) {
        //handle error
        return;
      } else {
        state.user = user;
        state.profile = user;
        state.vzxWallet = vzxWallet;
      }
    },
    [`${getUser.rejected}`]: (state: IUsersState, { payload }) => {},

    [`${getCommissionsAsync.pending}`]: (state: IUsersState, { payload }) => {},
    [`${getCommissionsAsync.fulfilled}`]: (state: IUsersState, { payload }) => {
      const { errors, commissions } = payload;
      if (errors) {
        return;
      } else {
        state.commissions = commissions;
      }
    },
    [`${getCommissionsAsync.rejected}`]: (
      state: IUsersState,
      { payload }
    ) => {},
    [`${getSharingsAsync.pending}`]: (state: IUsersState, { payload }) => {},
    [`${getSharingsAsync.fulfilled}`]: (state: IUsersState, { payload }) => {
      const { errors, commissions } = payload;
      if (errors) {
        return;
      } else {
        state.sharings = commissions;
      }
    },
    [`${getKycAsync.pending}`]: (state: IUsersState, { payload }) => {},
    [`${getKycAsync.fulfilled}`]: (state: IUsersState, { payload }) => {
      const { errors, kyc } = payload;
      if (errors) {
        return;
      } else {
        state.verify = kyc;
      }
    },
    [`${getSalesStatisticsAsync.pending}`]: (
      state: IUsersState,
      { payload }
    ) => {},
    [`${getSalesStatisticsAsync.fulfilled}`]: (
      state: IUsersState,
      { payload }
    ) => {
      const { errors } = payload;
      if (errors) {
        return;
      } else {
        state.sales_statistics = payload;
      }
    },
    [`${getBalancesAsync.pending}`]: (state: IUsersState, { payload }) => {},
    [`${getBalancesAsync.fulfilled}`]: (state: IUsersState, { payload }) => {
      const { errors } = payload;
      if (errors) {
        return;
      } else {
        const { balances, locked_balances } = payload;
        state.balances = balances;
        state.locked_balances = locked_balances;
      }
    },
  },
});

export const {
  setToken,
  setProfile,
  setConnectSocket,
  setBalances,
  setCommissions,
} = usersSlice.actions;

export default usersSlice.reducer;
