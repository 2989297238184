import { request, parseErrorResponse } from "./request";

export const createNotification = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/notifications/`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const getNotificationsClient = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/notifications/client`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const getNotificationsOwner = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/notifications/owner`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const getNotificationsSystem = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/notifications/system`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const readNotification = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/notifications/read/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const APIs = {
  getNotificationsClient,
  getNotificationsOwner,
  createNotification,
  readNotification,
  getNotificationsSystem
};

export default APIs;
