import React, { useState } from "react";
import { Tooltip } from "reactstrap";

const ToolTip = (props: any) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => {
    setTooltipOpen(!tooltipOpen);
  };

  return (
    <>
      {props.children}
      <Tooltip
        isOpen={tooltipOpen}
        target={"tooltip-" + props.id}
        toggle={toggle}
      >
        {props.content}
      </Tooltip>
    </>
  );
};

export default ToolTip;
