/* eslint-disable no-empty-pattern */
import { createSlice } from "@reduxjs/toolkit";
import {
  getCryptosAsync,
  getHistorySwapAsync,
  getStocksPriceAsync,
  getStocksSwapAsync,
  getStockProjetsSwapAsync,
} from "./cryptos.actions";

export interface ICryptoState {
  cryptos: any;
  stocksPrice: any;
  stocksSwap: any;
  stocksProjectSwap: any;
  historySwap: any;
}

const initialState: ICryptoState = {
  cryptos: [],
  stocksPrice: {},
  stocksSwap: [],
  stocksProjectSwap: [],
  historySwap: {},
};

export const cryptoSlice = createSlice({
  name: "crypto",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCryptosAsync.pending, (state) => {})
      .addCase(getCryptosAsync.fulfilled, (state, action) => {
        const { cryptos, errors } = action.payload;
        if (errors) {
          return;
        }
        state.cryptos = cryptos;
      })
      .addCase(getStocksSwapAsync.pending, (state) => {})
      .addCase(getStocksSwapAsync.fulfilled, (state, action) => {
        const { stocks, errors } = action.payload;
        if (errors) {
          return;
        }
        state.stocksSwap = stocks;
      })
      .addCase(getStockProjetsSwapAsync.pending, (state) => {})
      .addCase(getStockProjetsSwapAsync.fulfilled, (state, action) => {
        const { stocks, errors } = action.payload;
        if (errors) {
          return;
        }
        state.stocksProjectSwap = stocks;
      })
      .addCase(getStocksPriceAsync.pending, (state) => {})
      .addCase(getStocksPriceAsync.fulfilled, (state, action) => {
        const { prices, errors } = action.payload;
        if (errors) {
          return;
        }
        state.stocksPrice = prices;
      })
      .addCase(getHistorySwapAsync.pending, (state) => {})
      .addCase(getHistorySwapAsync.fulfilled, (state, action) => {
        const { docs, errors } = action.payload;
        if (errors) {
          return;
        }
        state.historySwap = docs;
      });
  },
});

export const {} = cryptoSlice.actions;

export default cryptoSlice.reducer;
