/* eslint-disable react-hooks/exhaustive-deps */
import {
  ItemContent,
  VideoYT,
  Title,
  Description,
  Project,
  TextWallet,
} from "./styles";

import { Row, Col, Container } from "reactstrap";

import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { getNfts } from "redux/reducers/settings/settings.selector";
import { formatNumber, getLinkYoutubeIframe } from "utils/common";
import { useEffect } from "react";
import { getNftsAsync } from "redux/reducers/settings/settings.action";

const NFTs = ({ handleOpenVideo }) => {
  const listNfts = useAppSelector(getNfts);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getNftsAsync());
  }, []);

  return (
    <Container>
      <Title>
        <h3>NFTs</h3>
        <hr />
        <Description>
          The NFT subreddit is a gathering for those interested in Non-Fungible
          Tokens
        </Description>
      </Title>
      <Row>
        {listNfts.map((item: any, index: number) => (
          <Col md={4} key={index}>
            <ItemContent data-aos="flip-left" data-aos-duration="800">
              <Project isVideo={true}>
                <h6>{item.name}</h6>
                <VideoYT
                  onClick={() =>
                    handleOpenVideo(getLinkYoutubeIframe(item.link_video, true))
                  }
                >
                  <iframe
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen={true}
                    title="YouTube video player"
                    src={getLinkYoutubeIframe(item.link_video)}
                  ></iframe>
                </VideoYT>
                <TextWallet>{item.contract}</TextWallet>
                <span>Total Supply:{formatNumber(+item.total_supply, 0)} </span>
              </Project>
            </ItemContent>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default NFTs;
