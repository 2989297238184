import Modal from "components/modal";
import {
  Content,
  Item,
  Title,
  Detail,
  AttachmentsContainer,
  ImageAt,
  CententReply,
} from "./styles";

const View = (props) => {
  const { type, openModal, setOpenModal, selectData } = props;
  return <>
    <Modal
      isOpen={openModal}
      centered={true}
      title={selectData?.title}
      onCancel={() => setOpenModal(false)}
    >
      <Content>
        <h4>Question:</h4>
        <Item>
          {selectData?.value &&
              Object.keys(selectData?.value)?.map((value, i) => {
                if (value !== "attachments") {
                  return (
                    <>
                      <Title key={i}>- {value}:</Title>
                      <Detail>{selectData.value[value]}</Detail>
                    </>
                  );
                } else {
                  let imgs = selectData?.value[value];
                  if (!Array.isArray(imgs)) {
                    imgs = [imgs];
                  }

                  return (
                    <>
                      <AttachmentsContainer key={i}>
                        <Title> - Attachments:</Title>
                      </AttachmentsContainer>
                      <Detail>
                        <ImageAt>
                          {imgs.map((at, index) => (
                            <img key={index} src={at} alt="" />
                          ))}
                        </ImageAt>
                      </Detail>
                    </>
                  );
                }
              })}
        </Item>
        {
          type === "support" && <>
            <h4>Reply:</h4>
            {selectData?.reply && (
              <Item>
                <CententReply>
                  <p>{selectData?.reply}</p>
                </CententReply>
              </Item>
            )}
          </>
        }
      </Content>
    </Modal>
  </>
}

export default View;