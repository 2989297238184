import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  
	.container_video {
    position: relative;
    width: 100%;
    height: 100%;
    
    .fb-video {
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        height: 100% !important;
        width: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;


        iframe {
          width: 100% !important;
          height: auto;
        }
      }
    }
    ._speaker {
      position: absolute;
      right: 40px;
      bottom: 20px;
      z-index: 99;
      cursor: pointer;
    }
  }
`;

export const ImageBottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 98;
  width: 100%;
  height: auto;
  min-height: 30%;
  overflow: hidden;

  .img_bottom_banner {
    label {
      justify-content: flex-end !important;
    }
  }
`;

export const ContainerImgBottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  img {
    height: 100%;
    width: 100%;
  }
`;