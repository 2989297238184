/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import VInput from "components/input";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { UpdateWrapper } from "../styles";
import UploadFile from "components/uploadFile";
import APIs from "api";
import { toast } from "react-toastify";
import { EditWrapper } from "../styles";

const EditPixel = (props, ref) => {
  const { setPixels, pixel, setIsOpenModal, setLoading } = props;
  const [state, setState] = useState({
    name: "",
    description: "",
    image: "",
    video: "",
    attachments: [],
    file: "",
  });

  useImperativeHandle(ref, () => ({
    submit: () => {
      handleSubmit();
    },
    delete: () => {
      handleDelete();
    },
  }));

  useEffect(() => {
    setState({
      ...state,
      ...pixel,
    });
  }, [pixel]);

  const handleChangeText = (event) => {
    setState((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleAddAttachment = () => {
    setState((prev) => ({
      ...prev,
      attachments: [...prev.attachments, ""],
    }));
  };

  const handleSubAttachment = () => {
    setState((prev) => ({
      ...prev,
      attachments: prev.attachments.slice(0, prev.attachments.length - 1),
    }));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let _state: any = state;
      if (state.file) {
        const formData = new FormData();
        formData.append("files", state.file);
        const res: any = await APIs.UPLOAD.uploadFile({ body: formData });
        delete _state.file;
        _state = {
          ...state,
          image: res.filePath,
        };
      }
      await APIs.PROJECT.updatePixel({
        params: {
          id: pixel._id,
        },
        body: _state,
      });
      setPixels((prev) => prev.map((p) => (p._id === _state._id ? _state : p)));
      toast.success("Update success!");
      setLoading(false);
      setIsOpenModal(false);
    } catch (error) {
      toast.error(error?.errors);
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await APIs.PROJECT.deletePixel({
        params: {
          id: pixel._id,
        },
      });
      setPixels((prev) => prev.filter((p) => p._id !== pixel._id));
      toast.success("Delete success!");
      setLoading(false);
      setIsOpenModal(false);
    } catch (error) {
      toast.error(error?.errors);
      setLoading(false);
    }
  };

  const handleChangeFile = (file) => {
    setState((prev) => ({
      ...prev,
      file: file,
    }));
  };

  const handleChangeAttachment = (index) => (event) => {
    setState((prev) => ({
      ...prev,
      attachments: state.attachments.map((a, _index) =>
        _index === index ? event.target.value : a
      ),
    }));
  };

  return (
    <EditWrapper>
      <Container>
        <Row>
          <Col xs={12}>
            <UpdateWrapper className="upload-wrapper">
              <UploadFile
                id={`image`}
                typeUpload="image"
                file={state.file}
                src={state.image}
                setFile={handleChangeFile}
              />
            </UpdateWrapper>
          </Col>
          <Col xs={12} md={6}>
            <VInput
              transparent={true}
              value={state.name}
              name="name"
              label="Name"
              onChange={handleChangeText}
            />
          </Col>
          <Col xs={12} md={6}>
            <VInput
              transparent={true}
              value={state.description}
              name="description"
              label={`Description`}
              onChange={handleChangeText}
            />
          </Col>
          <Col xs={12} md={6}>
            <VInput
              transparent={true}
              value={state.video}
              name="video"
              label="Video"
              onChange={handleChangeText}
            />
          </Col>
          <Col xs={12}>
            <div>
              <label>Attachments</label>
              <Button color="primary" onClick={handleAddAttachment}>
                +
              </Button>
              <Button color="danger" onClick={handleSubAttachment}>
                -
              </Button>
              {state.attachments.map((attach, index) => (
                <VInput
                  transparent={true}
                  value={attach}
                  onChange={handleChangeAttachment(index)}
                />
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </EditWrapper>
  );
};

export default forwardRef(EditPixel);
