import styled from "styled-components";

export const ContentModal = styled.div`
  padding: 10px;
  max-width: 600px;
`;

export const ItemModal = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const Title =styled.div`
  width: 20%;
`;

export const Detail = styled.div`
  width: 80%;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    max-width: 150px;
  }
`;