import axios from "axios";

const getRateUSDTToVND = async () => {
  try {
    const res : any = await axios.get(
      "https://api.exchangerate-api.com/v4/latest/USD",
      {}
    );
    if (res.data) {
      return res.data["rates"]["VND"];
    }
    return 23500;
  } catch (error) {
    return 23500;
  }
};

export { getRateUSDTToVND };
