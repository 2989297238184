/* eslint-disable react-hooks/exhaustive-deps */
import APIs from "api";
import Table from "components/table";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ContainerTable } from "./styles";

const View = () => {
  const columns = [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      render: (item: any) => (
        <span>{item.user.fullName || item.user.username}</span>
      ),
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email",
      render: (item: any) => <span>{item.user.email}</span>,
    },
    {
      key: "levelId",
      title: "Level",
      dataIndex: "levelId",
      render: (item: any) => {
        const level = item?.campaign?.levels.find(
          (el) => el.id === item.level_id
        );
        return <span>{level.name}</span>;
      },
    },
    // {
    //   title: "Status upload",
    //   dataIndex: "status_upload",
    //   key: "status_upload",
    //   render: (data: any) => (
    //     <TextColor
    //       color={
    //         data?.status_upload === "approved"
    //           ? "green100"
    //           : data?.status_upload === "pending"
    //             ? "yellow100"
    //             : data?.status_upload === "declined"
    //               ? "red100"
    //               : "white100"
    //       }
    //     >
    //       {data?.status_upload}
    //     </TextColor>
    //   ),
    // },
    // {
    //   key: "createdAt",
    //   title: "Created At",
    //   dataIndex: "createdAt",
    //   render: (item: any) => (
    //     <span>
    //       {new Date(item?.createdAt).toLocaleString("en-GB")}
    //     </span>
    //   ),
    // },
  ];

  const { campaignId } = useParams();

  const [levels, setLevels] = useState([]);

  const getLevels = async () => {
    try {
      const res: any = await APIs.TASK.getLevels({
        params: {
          campaignId: campaignId,
        },
      });
      if (res) {
        setLevels(res);
      }
    } catch (error) {
      // console.log();
    }
  };

  useEffect(() => {
    if (campaignId) {
      getLevels();
    }
  }, []);

  return (
    <>
      <ContainerTable>
        <Table bgTable={"black100"} columns={columns} dataSource={levels} />
      </ContainerTable>
    </>
  );
};

export default View;
