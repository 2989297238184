import styled, { css } from "styled-components";
import {isMobile} from 'react-device-detect';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    color: white;
    overflow-y: hidden;
    ${isMobile
    ? css`
        height: calc(var(--vh, 1vh) * 100);
      `
    : css`
        height: 100vh;
      `}
`;

export const SideBarLayout = styled.div`
    position: ${isMobile ? "fixed" : ""};
    z-index: 100000;
    bottom: 0;

    ${isMobile
    ? css`
        height: calc(var(--vh, 1vh) * 100);
      `
    : css`
        height: 100vh;
      `}
`;

export const Content = styled.div`
    padding: 0px 16px;
    background-color: ${props => props.theme.colors.bgColorLayer1};
    width: 100%;
    color: white;
    ${isMobile
    ? css`
        height: calc(var(--vh, 1vh) * 100) - ${isMobile ? '56px' : '0px'};
      `
    : css`
        height: 100vh;
      `}

    overflow: auto;
    margin-top: ${isMobile ? '86px' : '0'};
    margin-bottom: ${isMobile ? '56px' : '0'};
`;

export const ContentScoll = styled.div`
    bottom: 0;
    width: 100%;
    
    ${isMobile
    ? css`
        height: calc(var(--vh, 1vh) * 100) - ${isMobile ? '142px' : '0px'};
      `
    : css`
        height: 100vh;
      `}

`;

export default Container;