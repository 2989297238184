/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";
import { BackgroundVideo } from "../styles";
import { getLinkYoutubeIframe } from "utils/common";

const linkVideo =
  "https://www.youtube.com/watch?v=Aq6W-nmRO94&ab_channel=Vzonex";

const BackgroundVideoComponent = () => {
  const [idBackground, setIdBackground] = useState<string>();

  const convertIdVideo = (data: any) => {
    let idVideo;
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;

    if (data) {
      const match = data.match(regExp);
      if (match && match[2].length === 11) {
        idVideo = match[2];
      } else {
        idVideo = "";
      }
    }
    setIdBackground(idVideo);
  };

  useEffect(() => {
    convertIdVideo(linkVideo);
  }, []);

  return idBackground ? (
    <BackgroundVideo>
      <iframe
        className="disable-event"
        style={{ width: "auto", height: "100%", minWidth: "100%" }}
        // eslint-disable-next-line max-len
        src={getLinkYoutubeIframe(idBackground)}
        frameBorder="0"
        allow="accelerometer"
        allowTransparency
        picture-in-picture
      ></iframe>
    </BackgroundVideo>
  ) : (
    <></>
  );
};

export default BackgroundVideoComponent;
