/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Col, Row } from "reactstrap";
import { isMobile } from "react-device-detect";

import Button from "components/button";
import Input from "components/input";

import APIs from "api";

import {
  QrCodeLogin,
  Content,
  Form,
  FormItem,
  FormTitle,
} from "pages/auth/styles";
import { toast } from "react-toastify";
import { ROUTES } from "config/routes";

const View = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const hanldeChangePassword = async (data) => {
    setLoading(true);
    try {
      const user = await APIs.AUTH.postForgotPassword({
        body: {
          email: data.email,
        },
      });
      setLoading(false);
      if (user) {
        history.push(`/auth/reset-password?token=${user.data?.token}`);
      } else {
        toast.error("Not found!");
      }
    } catch (error) {
      toast.error("Some thing went wrong!");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("auth")) {
      history.push(ROUTES.DASHBOARD);
    }
  }, []);

  return (
    <Content>
      <Row>
        {!isMobile && (
          <Col>
            <QrCodeLogin>
              <svg
                width="121"
                height="120"
                viewBox="0 0 121 120"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  // eslint-disable-next-line max-len
                  d="M38 7.5H8V37.5H38V7.5ZM45.5 0V45H0.5V0H45.5ZM15.5 15H30.5V30H15.5V15ZM113 7.5H83V37.5H113V7.5ZM120.5 0V45H75.5V0H120.5ZM90.5 15H105.5V30H90.5V15ZM38 82.5H8V112.5H38V82.5ZM45.5 75V120H0.5V75H45.5ZM15.5 90H30.5V105H15.5V90ZM53 0H60.5V7.5H53V0ZM60.5 7.5H68V15H60.5V7.5ZM53 15H60.5V22.5H53V15ZM60.5 22.5H68V30H60.5V22.5ZM53 30H60.5V37.5H53V30ZM60.5 37.5H68V45H60.5V37.5ZM53 45H60.5V52.5H53V45ZM53 60H60.5V67.5H53V60ZM60.5 67.5H68V75H60.5V67.5ZM53 75H60.5V82.5H53V75ZM60.5 82.5H68V90H60.5V82.5ZM53 90H60.5V97.5H53V90ZM60.5 97.5H68V105H60.5V97.5ZM53 105H60.5V112.5H53V105ZM60.5 112.5H68V120H60.5V112.5ZM113 60H120.5V67.5H113V60ZM8 60H15.5V67.5H8V60ZM15.5 52.5H23V60H15.5V52.5ZM0.5 52.5H8V60H0.5V52.5ZM30.5 52.5H38V60H30.5V52.5ZM38 60H45.5V67.5H38V60ZM45.5 52.5H53V60H45.5V52.5ZM68 60H75.5V67.5H68V60ZM75.5 52.5H83V60H75.5V52.5ZM83 60H90.5V67.5H83V60ZM90.5 52.5H98V60H90.5V52.5ZM98 60H105.5V67.5H98V60ZM105.5 52.5H113V60H105.5V52.5ZM113 75H120.5V82.5H113V75ZM68 75H75.5V82.5H68V75ZM75.5 67.5H83V75H75.5V67.5ZM83 75H90.5V82.5H83V75ZM98 75H105.5V82.5H98V75ZM105.5 67.5H113V75H105.5V67.5ZM113 90H120.5V97.5H113V90ZM68 90H75.5V97.5H68V90ZM75.5 82.5H83V90H75.5V82.5ZM90.5 82.5H98V90H90.5V82.5ZM98 90H105.5V97.5H98V90ZM105.5 82.5H113V90H105.5V82.5ZM113 105H120.5V112.5H113V105ZM75.5 97.5H83V105H75.5V97.5ZM83 105H90.5V112.5H83V105ZM90.5 97.5H98V105H90.5V97.5ZM98 105H105.5V112.5H98V105ZM75.5 112.5H83V120H75.5V112.5ZM90.5 112.5H98V120H90.5V112.5ZM105.5 112.5H113V120H105.5V112.5Z"
                  fill="white"
                />
              </svg>
              <h5>Sign in with QR Code</h5>
              <p>Scan this code with the mobile app to sign in instantly.</p>
            </QrCodeLogin>
          </Col>
        )}
        <Col>
          <Form onSubmit={handleSubmit(hanldeChangePassword)}>
            <FormTitle>Reset your password</FormTitle>
            <FormItem>
              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    hasError={Boolean(errors.email)}
                    label="Email"
                    placeholder="email"
                    {...field}
                  />
                )}
              />
            </FormItem>
            <FormItem>
              <Button loading={loading} type="submit" width="100%">
                Next
              </Button>
            </FormItem>
          </Form>
        </Col>
      </Row>
    </Content>
  );
};

export default View;
