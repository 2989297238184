import { ContentModal, ItemModal, Detail } from "./styles";
import Modal from "components/modal";

import { Link } from "react-router-dom";

const View = ({ dataModal, openModal, setOpenModal }: any) => {
  return (
    <Modal
      isOpen={openModal}
      centered={true}
      title="Preview notifications"
      onCancel={() => setOpenModal(false)}
    >
      <ContentModal>
        <ItemModal>
          <Detail>
            <h5>{dataModal?.title}</h5>
          </Detail>
        </ItemModal>
        <ItemModal>
          <Detail>
            <a href={dataModal?.link}>
              <img src={dataModal?.icon} alt="icon" />
            </a>
          </Detail>
        </ItemModal>
        <ItemModal>
          <Detail>
            <a href={dataModal?.link}>
              <img src={dataModal?.banner} alt="icon" />
            </a>
          </Detail>
        </ItemModal>
        <ItemModal>
          <Detail>
            <Link to={dataModal?.link} target="_blank">
              {dataModal?.link}
            </Link>
          </Detail>
        </ItemModal>
        <ItemModal>
          <Detail>
            <span>{dataModal?.content}</span>
          </Detail>
        </ItemModal>
      </ContentModal>
    </Modal>
  );
};

export default View;
