import styled from "styled-components";

const Wrapper = styled.div`
  min-width: 150px;
  text-align: left;
  z-index: 1000000;
  overflow: hidden;
  hr {
    margin: 0;
    width: 100%;
  }
`;

export default Wrapper;
