/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";

import { useAppDispatch } from "redux/reducers/hook";
import { useSelector } from "react-redux";

import { getBannerAsync } from "redux/reducers/settings/settings.action";
import { getBanner } from "redux/reducers/settings/settings.selector";

import Banner from "components/business-landing-page/components/Banner";
import { Wrapper } from "./styles";

const View = () => {
  const dispatch = useAppDispatch();
  const banner: any = useSelector(getBanner);

  useEffect(() => {
    dispatch(getBannerAsync());
  }, []);

  return (
    <Wrapper>
      {(banner?.is_facebook || banner?.is_youtube || banner?.is_slide) && (
        <Banner
          isEdit={false}
          isSlide={
            banner?.slide && banner?.slide?.length > 0 && banner?.is_slide
          }
          minHeight={"100%"}
          linkVideo={
            banner?.is_facebook
              ? banner?.link_facebook
              : banner?.is_youtube
              ? banner?.link_youtube
              : ""
          }
          listSlideBanner={banner?.slide}
        />
      )}
    </Wrapper>
  );
};

export default View;
