import styled, { css } from "styled-components";

export const Wrapper = styled.div<{textColor: string}>`
  padding: 15px;
  width: 140px;

  color: ${props => props.textColor || "white"};
  text-align: center;
  display: inline-block;

  h4 {
    font-size: 15px;
    margin-bottom: 4px;
    font-weight: normal;
  }

  h5 {
    font-size: 13px;
    font-weight: normal;
  }

  @media screen and (max-width: 730px) {
    width: 110px;
  }

  @media screen and (max-width: 600px) {
    width: 100px;
    padding: 10px 5px;
    h4 {
      font-size: 14px;
    }
    h5 {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 430px) {
    padding: 10px 2px;
    width: 80px;
    h4 {
      font-size: 12px;
    }
    h5 {
      font-size: 10px;
    }
  }

  @media screen and (max-width: 350px) {
    width: 70px;
  }
`;

export const Avatar = styled.div<{ isPreview?: boolean }>`
  position: relative;
  width: 100px;
  height: 100px;
  margin: auto;
  border-radius: 50%;
  border: 1px solid #707070;
  overflow: hidden;
  margin-bottom: 14px !important;

  ${({ isPreview }) =>
    isPreview
      ? css`
          img {
            width: 100%;
            height: auto;
          }
        `
      : css`
          img {
            width: 100%;
            height: auto;
          }
        `}

  @media screen and (max-width: 730px) {
    width: 90px;
    height: 90px;
  }
  
  @media screen and (max-width: 600px) {
    width: 80px;
    height: 80px;
  }

  @media screen and (max-width: 430px) {
    width: 67px;
    height: 67px;
  }
  @media screen and (max-width: 350px) {
    width: 60px;
    height: 60px;
  }
`;
