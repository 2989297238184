import Input, {
  Label,
  SuffixIcon,
  InputWrapper,
  CustomInputCurrency,
} from "./styles";
import { FormFeedback } from "reactstrap";

type IProps = {
  onChange?: any;
  [key: string]: any;
};

const View = ({
  transparent = false,
  color = "",
  isCurrency = false,
  label,
  hasError = false,
  suffixIcon = null,
  errMessage = "",
  type = "text",
  ...props
}: IProps) => {
  return (
    <InputWrapper>
      {label && <Label hasError={hasError}>{label}</Label>}
      {isCurrency ? (
        <CustomInputCurrency
          transparent={transparent}
          color={color}
          {...props}
        />
      ) : (
        <Input
          transparent={transparent}
          color={color}
          invalid={hasError}
          autocomplete="off"
          type={type}
          onWheel={(e) => e.target.blur()}
          {...props}
        />
      )}
      {hasError && <FormFeedback>{errMessage}</FormFeedback>}
      {suffixIcon && (
        <SuffixIcon height={props.height} hasError={hasError} color={color}>
          {suffixIcon}
        </SuffixIcon>
      )}
    </InputWrapper>
  );
};

export default View;
