/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Container,
  SwapType,
  Choose,
  TabSwap,
  InputText,
  Transfer,
  Bottom,
  Explain,
  Item,
  Select,
  Note,
  ListItem,
  Element,
  ModalContainer,
  Balance,
} from "./styles";
import Button from "components/button";
import { FormGroup, Label, Row, Col } from "reactstrap";
import Modal from "components/modal";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import {
  getHistorySwapAsync,
  getStocksPriceAsync,
  getStocksSwapAsync,
  getStockProjetsSwapAsync,
} from "redux/reducers/cryptos/cryptos.actions";
import {
  getStocksPrice,
  getStocksSwap,
  getStocksProjectSwap,
} from "redux/reducers/cryptos/cryptos.selector";
import { toast } from "react-toastify";
import APIs from "api";
import History from "./History";
import { getBalances } from "redux/reducers/users/users.selector";
import { formatNumber } from "utils/common";
import Progress from "components/progress";
import Input from "components/input";
import { BsFillArrowDownCircleFill } from "react-icons/bs";

const View = () => {
  const dispatch = useAppDispatch();

  const [state, setState] = useState({
    isOpenFrom: false,
    isOpenTo: false,
    from: null,
    to: null,
    listFrom: [],
    listTo: [],
    amountFrom: null,
    amountTo: null,
    fee: 0,
    oneWay: true,
  });

  const [active, setActive] = useState<string>("market");
  const [loading, setLoading] = useState(false);
  const [valueProgress, setValueProgress] = useState(0);

  const stocksProjectSwap = useAppSelector(getStocksProjectSwap);
  const stocksPrice = useAppSelector(getStocksPrice);
  const stocksSwap = useAppSelector(getStocksSwap);
  const balances = useAppSelector(getBalances);

  const init = () => {
    if (stocksSwap) {
      const fromSelected = stocksSwap.find((s) => s.symbol === "vnex");
      const listTo = stocksSwap.filter(
        (s) => (fromSelected.pairs || {})[s.symbol]
      );
      setState({
        ...state,
        from: fromSelected,
        to: listTo[0],
        listFrom: stocksSwap,
        listTo,
      });
    }
  };

  const handleTabs = (tab: string) => {
    if (tab === "projects") {
      setState({
        ...state,
        from: stocksProjectSwap[0],
        to: stocksProjectSwap[1],
      });
    } else {
      init();
    }
    setActive(tab);
  };

  const handleToggleModal = (type) => {
    if (type === "from")
      setState({
        ...state,
        isOpenFrom: !state.isOpenFrom,
      });
    else
      setState({
        ...state,
        isOpenTo: !state.isOpenTo,
      });
  };

  const handleChoose = (type, stock) => {
    if (active === "projects") {
      if (type === "from") {
        setState({
          ...state,
          from: stock,
          isOpenFrom: false,
        });
      } else
        setState({
          ...state,
          to: stock,
          isOpenTo: false,
        });
    } else {
      let from;
      let to;
      let oneWay = true;
      let listFrom = [];
      let listTo = [];
      if (type === "from") {
        listFrom = stocksSwap;
        listTo = stocksSwap.filter((s) => (stock.pairs || {})[s.symbol]);
        from = stock;
        to = listTo[0];
      } else {
        listFrom = stocksSwap.filter((s) => (s.pairs || {})[stock.symbol]);
        listTo = state.listTo;
        from = state.from;
        to = stock;
      }

      if (
        from &&
        to &&
        (from.pairs || {})[to.symbol] &&
        (to.pairs || {})[from.symbol]
      ) {
        oneWay = false;
      }
      setState({
        ...state,
        from,
        to,
        listFrom,
        listTo,
        isOpenFrom: false,
        oneWay,
      });
    }
  };

  const handleChange = (type, value) => {
    let amountFrom = 0;
    let amountTo = 0;
    if (type === "from") {
      amountFrom = +value;
      if (active === "market") {
        amountTo =
          +value *
          (+stocksPrice[state.from.symbol]["vnd"] /
            +stocksPrice[state.to.symbol]["vnd"]);
      } else {
        amountTo =
          +value *
          ((+stocksPrice[state.from.stock_to_buy]["vnd"] *
            state.from.stock_price) /
            (+stocksPrice[state.to.stock_to_buy]["vnd"] *
              state.to.stock_price));
      }
    } else {
      amountTo = +value;
      if (active === "market") {
        amountFrom =
          +value *
          (+stocksPrice[state.to.symbol]["vnd"] /
            +stocksPrice[state.from.symbol]["vnd"]);
      } else {
        amountFrom =
          +value *
          ((+stocksPrice[state.to.stock_to_buy]["vnd"] * state.to.stock_price) /
            (+stocksPrice[state.from.stock_to_buy]["vnd"] *
              state.from.stock_price));
      }
    }
    setState({
      ...state,
      amountFrom,
      amountTo,
    });
  };

  const handleSwap = async () => {
    try {
      setLoading(true);
      // await new Promise((res) => setTimeout(() => res(""), 3000));
      let res;
      if (active === "market") {
        res = await APIs.CRYPTO.swap({
          body: {
            from: state.from.symbol,
            to: state.to.symbol,
            amount: state.amountFrom,
          },
        });
      } else {
        res = await APIs.CRYPTO.swapStocksProjectAsync({
          body: {
            from: state.from.symbol,
            to: state.to.symbol,
            amount: state.amountFrom,
          },
        });
      }

      if (res) {
        setState({
          ...state,
          amountFrom: null,
          amountTo: null,
        });
        dispatch(getHistorySwapAsync());
        toast.success("Swap success!");
      }
      setLoading(false);
    } catch (error) {
      toast.error(error.errors);
      setLoading(false);
    }
  };

  const handleSwapStocks = () => {
    setState((state) => {
      return {
        ...state,
        from: state.to,
        to: state.from,
      };
    });
  };

  const handleChangeProgress = (value) => {
    setValueProgress(value);
  };

  const renderModalFrom = () => {
    return (
      <ModalContainer>
        <h5>Top stocks: </h5>
        <ListItem>
          {(active === "market" ? state.listFrom : stocksProjectSwap).map(
            (s, key) => (
              <Element
                key={key}
                className={s.symbol === state.from?.symbol ? "active" : ""}
                onClick={() => handleChoose("from", s)}
              >
                <img src={s.logo || "/assets/images/coins/dollar.png"} alt="" />
                <span>{s.symbol.toUpperCase()}</span>
              </Element>
            )
          )}
        </ListItem>
      </ModalContainer>
    );
  };

  const renderModalTo = () => {
    return (
      <ModalContainer>
        <h5>Top stocks: </h5>
        <ListItem>
          {(active === "market" ? state.listTo : stocksProjectSwap).map(
            (s, key) => (
              <Element
                key={key}
                className={s.symbol === state.to?.symbol ? "active" : ""}
                onClick={() => handleChoose("to", s)}
              >
                <img src={s.logo || "/assets/images/coins/dollar.png"} alt="" />
                <span>{s.symbol.toUpperCase()}</span>
              </Element>
            )
          )}
        </ListItem>
      </ModalContainer>
    );
  };

  useEffect(() => {
    if (balances && state.from)
      setState({
        ...state,
        amountFrom: ((balances[state.from.symbol] || 0) * valueProgress) / 100,
      });
  }, [valueProgress]);

  useEffect(() => {
    init();
  }, [stocksSwap]);

  useEffect(() => {
    if (state.from && state.to) {
      const amountFrom = state.amountFrom || 0;
      let amountTo;
      if (active === "market") {
        amountTo =
          amountFrom *
          (+stocksPrice[state.from.symbol]["vnd"] /
            +stocksPrice[state.to.symbol]["vnd"]);
      } else {
        amountTo =
          amountFrom *
          ((+stocksPrice[state.from.stock_to_buy]["vnd"] *
            state.from.stock_price) /
            (+stocksPrice[state.to.stock_to_buy]["vnd"] *
              state.to.stock_price));
      }
      const fee_swap = state.from.fee_swap || {
        stock: "vnex",
        percent: 1,
      };
      const fee = (amountFrom * +fee_swap.percent) / 100;
      setState({
        ...state,
        amountFrom,
        amountTo,
        fee,
      });
    }
  }, [state.amountFrom, state.amountTo]);

  useEffect(() => {
    if (state.from && state.to) {
      setValueProgress(0);
      setState({
        ...state,
        amountFrom: null,
        amountTo: null,
      });
    }
  }, [state.from, state.to]);

  useEffect(() => {
    dispatch(getStocksPriceAsync());
    dispatch(getStocksSwapAsync());
    dispatch(getStockProjetsSwapAsync());
  }, [dispatch]);

  return (
    <Container>
      <h4>Swap</h4>
      <SwapType>
        <span>Select swap type</span>
        <img alt="" src="/assets/images/icons/info.svg" />
      </SwapType>
      <Choose>
        <TabSwap
          active={active === "market"}
          onClick={() => handleTabs("market")}
        >
          Market place
        </TabSwap>
        <TabSwap active={active === "yourprice"}>Your price</TabSwap>
        <TabSwap
          active={active === "projects"}
          onClick={() => handleTabs("projects")}
        >
          IBO Stock Projects
        </TabSwap>
      </Choose>

      <Row>
        <Col md={12} lg={5}>
          {" "}
          <InputText>
            <Item>
              <FormGroup>
                <Label for="exampleEmail">
                  Availability
                  {/* Amount of {state.from ? state.from.symbol.toUpperCase() : "N/A"} */}
                  {state.from && (
                    <Balance>
                      {formatNumber(balances[state.from.symbol])}-
                      {state.from.symbol.toUpperCase()}
                    </Balance>
                  )}
                </Label>
                <Input 
                  isCurrency={true}
                  plaintext
                  name="amountFrom"
                  value={state.amountFrom}
                  placeholder="0.00"
                  onValueChange={(e) => handleChange("from", e)}
                  disabled={state.from && state.to ? false : true}
                />
              </FormGroup>
              <Progress
                value={valueProgress}
                handleChange={handleChangeProgress}
              />
              <Transfer>
                {state.oneWay ? (
                  <BsFillArrowDownCircleFill size={35} />
                ) : (
                  <img
                    onClick={handleSwapStocks}
                    src="/assets/images/icons/transfer.svg"
                    alt=""
                  />
                )}
              </Transfer>
            </Item>
            <Select
              onClick={() => handleToggleModal("from")}
              className="select"
            >
              <img
                src={
                  state.from
                    ? state.from.logo
                    : "/assets/images/coins/dollar.png"
                }
                alt=""
              />
              <p>{state.from ? state.from.symbol.toUpperCase() : "N/A"}</p>
              <img src="/assets/images/icons/dropdown.svg" alt="" />
            </Select>
          </InputText>
          <InputText>
            <Item>
              <FormGroup>
                <Label for="exampleEmail">
                  {" "}
                  Amount of {state.to ? state.to.symbol.toUpperCase() : "N/A"}
                </Label>
                <Input
                  name="amountTo"
                  isCurrency={true}
                  plaintext
                  value={state.amountTo}
                  placeholder="0.00"
                  onValueChange={(e) => handleChange("to", e)}
                  disabled={state.from && state.to ? false : true}
                />
              </FormGroup>
            </Item>
            <Select onClick={() => handleToggleModal("to")} className="select2">
              <img
                src={
                  state.to ? state.to.logo : "/assets/images/coins/dollar.png"
                }
                alt=""
              />
              <p>{state.to ? state.to.symbol.toUpperCase() : "N/A"}</p>
              <img src="/assets/images/icons/dropdown.svg" alt="" />
            </Select>
          </InputText>
          <Bottom>
            {state.from && state.to && (
              <p>
                <span>1 </span>
                {state.from.symbol.toUpperCase()} ={" "}
                <span>
                  {active === "market"
                    ? +stocksPrice[state.from.symbol]?.["vnd"] /
                      stocksPrice[state.to.symbol]?.["vnd"]
                    : (+stocksPrice[state.from.stock_to_buy]["vnd"] *
                        state.from.stock_price) /
                      (+stocksPrice[state.to.stock_to_buy]["vnd"] *
                        state.to.stock_price)}
                </span>{" "}
                {state.to.symbol.toUpperCase()}
              </p>
            )}
            <p>
              {" "}
              Fee : <span>{formatNumber(state.fee)}</span>{" "}
              {state.from && state.from.fee_swap
                ? state.from.fee_swap.stock &&
                  state.from.fee_swap.stock.toUpperCase()
                : "VNEX"}
            </p>
            <Explain>
              <Note>
                <span>!</span>
              </Note>
              <span>
                The final {state.to && state.to.symbol.toUpperCase()} amount you
                receive many by slightly different due to market volatility
              </span>
            </Explain>
            <Button
              onClick={handleSwap}
              loading={loading}
              disabled={!loading && state.from && state.to ? false : true}
            >
              Claim
            </Button>
          </Bottom>
        </Col>

        <Col md={12} lg={7}>
          <History />
        </Col>
      </Row>

      <Modal
        centered={true}
        isOpen={state.isOpenFrom}
        title="Swap from"
        children={renderModalFrom()}
        onCancel={() => handleToggleModal("from")}
      />
      <Modal
        centered={true}
        isOpen={state.isOpenTo}
        title="Swap to"
        children={renderModalTo()}
        onCancel={() => handleToggleModal("to")}
      />
    </Container>
  );
};

export default View;
