import styled from "styled-components";

export const Container = styled.div`
  margin-top: 40px;
  background: ${(props) => props.theme.colors["black100"]};
  padding: 30px;
  border-radius: 8px;
  overflow: hidden;
  img {
    cursor: pointer;
  }
`;

export const Wrapper = styled.div`
  margin-top: 40px;
  background: ${(props) => props.theme.colors["black100"]};
  padding: 30px;
  border-radius: 8px;
  overflow: hidden;
  img {
    cursor: pointer;
  }
`;

export const Header = styled.div`
  /* display: flex; */
  /* justify-content: space-between; */
  width: 100%;
  strong {
    font-size: 18px;
  }
`;

export const Item = styled.div`
  margin: 10px 0;
  border-radius: 8px;
  border: 1px solid gray;
  overflow: hidden;
  position: relative;
  height: 100%;
`;

export const Content = styled.div`
  padding: 15px;
  background: ${(props) => props.theme.colors["bgColorLayer1"]};
  height: 100%;
  label {
    font-size: 14px;
    display: block;
    color: #f1e3ab;
  }
`;
export const ImageWrapper = styled.div`
  width: 100%;
  height: 200px;
`;

export const ContentImage = styled.div``;

export const ImageBg = styled(ContentImage)<{ props }>`
  background-color: transparent;
  background-image: url(${(props: any) => props.props.bg});
  background-size: cover;
  opacity: 0.4;
  width: 100%;
  height: 100%;
`;

export const Image = styled.div`
  width: 100%;
  position: absolute;
  top: 50px;
  text-align: center;
  img {
    max-width: 100%;
    height: 100px;
    object-fit: cover;
  }
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  margin-bottom: 10px;
  img {
    margin-left: 5px;
  }
  &:hover {
    color: ${(props) => props.theme.colors.yellow100};
  }
  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.white100};
  }
`;

export const Icon = styled.div`
  margin: 3px 0 8px 0;
  min-height: 24px;
  img {
    width: 20px;
    margin: 0 2px;
  }
  img:not(:first-child) {
    margin-left: 10px;
  }
`;

export const Cost = styled.div`
  margin: 5px 0 8px 0;
  display: flex;
`;

export const Element = styled.div`
  font-size: 18px;
  margin-right: 10px;
`;

export const Percent = styled.div`
  background: #1fa5ff;
  border-radius: 8px;
  padding: 0 5px;
  span {
    font-size: 14px;
  }
`;

export const Amount = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  min-height: 31px;
  label {
    color: white;
  }
  .end {
    width: 100%;
    text-align: center;
    color: white;
    font-weight: 700;
    font-size: 20px;
  }
`;

export const User = styled.div`
  display: flex;
  button {
    border: unset;
    padding: 5px 10px;
    height: unset;
    background-color: ${(props) => props.theme.colors.green100};
    font-weight: 700;
    box-shadow: 0px 1px 5px 0px;
    border-radius: 6px;
  }
`;

export const Search = styled.div`
  width: 100%;
  input {
    height: 39px;
  }
  @media screen and (max-width: 768px) {
    margin-top: 10px;
  }
`;

export const TextGreen = styled.div`
  label {
    color: ${(props) => props.theme.colors.green100};
    font-size: 14px;
    opacity: 0.5;
  }
`;

export const ContainDropdown = styled.div`
  width: 100%;
  button {
    width: 100% !important;
  }
  .dropdown-menu {
    width: 100%;
    button {
      color: white !important;
    }
  }
`;

export const ContainModal = styled.div`
  padding: 10px 25px;
  img {
    cursor: pointer;
  }
`;

export const ModalHeader = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
    width: 40px;
  }
  span {
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 7px;
  }
`;

export const ModalContent = styled.div`
  button {
    margin-top: 20px;
    width: 100%;
  }
  img {
    width: 30px;
  }
`;

export const Coin = styled.div`
  input {
    width: 100%;
  }
  img {
    margin-bottom: 5px;
    margin-right: 5px;
  }
`;

export const Transfer = styled.div`
  text-align: center;
`;

export const Description = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  span {
    font-size: 15px;
  }
  span:not(:first-child) {
    color: rgb(31, 199, 212) !important;
    font-size: 16px !important;
    font-weight: 600 !important;
  }
`;

export const WapperContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 510px;
  .btn-view-pixel {
    margin-top: 10px;
    float: right;
    button {
      border: unset;
      padding: 5px 10px;
      height: unset;
      background-color: #00ac11;
      font-weight: 700;
      box-shadow: 0px 1px 5px 0px;
      border-radius: 6px;
    }
  }
`;

export const WapperItem = styled.div`
  width: 340px;
  margin: 10px;
`;

export const WrapperAmount = styled.div`
  display: flex;
  height: 100%;
  margin-left: 10px;
`;

export const WrapperTitle = styled.div``;

export const CounterdownWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .countdown-section {
    display: flex;
    align-items: center;
    justify-content: center;
    .countdown-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 3px;
      &:last-child {
        margin-right: 0;
      }
      .countdown-item-value {
        background: #284f8a;
        font-family: "Poppins", Sans-serif;
        font-size: 14px;
        line-height: 30px;
        border-radius: 12px 12px 12px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        color: ${(props) => props.theme.colors.white100};
        width: 30px;
        height: 30px;
      }
    }
  }
`;

export const Status = styled.div`
  font-weight: 700;
  .opening {
    color: ${(props) => props.theme.colors.green100};
  }

  .closed {
    color: ${(props) => props.theme.colors.red100};
  }

  .upcoming {
    color: ${(props) => props.theme.colors.yellow100};
  }
`;

export const SeletedCon = styled.div`
  div {
    color: ${(props) => props.theme.colors.black100};
  }
`;

export const StageWrapper = styled.div`
  margin: 10px 0;
  min-height: 16px;
`;

export const HistoriesWrapper = styled.div`
  overflow: auto;
  button {
    padding: 5px 12px;
    height: auto;
    font-size: 14px;
  }
`;

export const ContentStage = styled.div`
  text-align: left;
  hr {
    margin: 2px 0px;
  }
`;

export const ContentToolTip = styled.div`
  position: relative;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    position: absolute;
    right: 2px;
    border: 1px solid #ffe600;
    border-radius: 50%;
    font-size: 6px;
    padding: 0px 4px;
    top: 3px;
    background: #ffe600;
    color: black;
  }
`;

export const PercentProgress = styled.span`
  font-size: 10px;
  font-weight: 600;
  color: black;
`;

export const PixelWrapper = styled.div`
  .disable-pixel {
    opacity: 0.3;
  }
  .pixel-sold {
    border: 1px solid yellow;
    opacity: 0.6;
  }
  .react-transform-wrapper {
    width: 100%;
  }
  .action-zoom {
    margin-bottom: 20px;
    button {
      margin: 0 5px;
    }
  }
`;

export const RoundWrapper = styled.div`
  margin-top: 20px;
  .round-details {
    width: 100%;
    overflow: auto;
    white-space: nowrap;
    span {
      font-size: 18px;
      font-weight: 600;
      margin-right: 10px;
    }
    img {
      display: inline-block;
      margin: 0 5px;
      height: 50px;
    }
  }
`;

export const ProjectInfo = styled.div``;

export const PixelItemsWapper = styled.div`
  width: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PixelItems = styled.div`
  width: 1200px;
  height: 600px;
`;

export const PixelItem = styled.div`
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid;
  margin: 1px;
  text-align: center;
  line-height: 25px;
  img {
    width: 25px;
    height: 25px;
  }
  svg {
    width: 100%;
  }
`;

export const ModalWrapper = styled.div`
  max-width: 500px;
  max-height: 500px;
  overflow: auto;
`;

export const BuyWrapper = styled.div`
  img {
    max-width: 300px;
    margin: 20px 0;
  }
  .btn_close {
    margin-left: 10px;
  }
`;

export const EmptyText = styled.div`
  font-size: 20px;
  text-align: center;
`;
