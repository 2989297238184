import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "api";

export const getDepositBanks = createAsyncThunk("banks/list-deposit", async () => {
  try {
    const response : any = await API.SETTING.getSettingByName("banks");
    return response?.setting?.value;
  } catch (error) {
    return error;
  }
});

export const getMyBanks = createAsyncThunk("banks/list-my-banks", async () => {
  try {
    const response : any = await API.BANK.getBanks();
    return response?.banks;
  } catch (error) {
    return error;
  }
});


export const createMyBank = createAsyncThunk("banks/create-my-banks", async (req) => {
  try {
    const response : any = await API.BANK.createBank(req);
    return response?.bank;
  } catch (error) {
    return error;
  }
});
