import {
  Wrapper,
  Section,
  ContainerFirstHome,
  MainEstock,
  ItemContent,
  VideoYT,
  MainLogo,
  Title,
  Description,
  Fundamentals,
  OutTeam,
  Avta,
  Flex,
  Roadmap,
  ItemRoadmap,
  UpRoadmap,
  DownRoadmap,
  DetailRoadmap,
  ImageRoadmap,
  ColumnRoad,
  RowRoad,
  Timeline,
  FundamentalsImage,
  ContainerImage,
  RoadmapContainer,
  // BuySell,
} from "./styles";

import { TopMain } from "../styles";

import Footer from "layouts/components/footer";

import { Row, Col, Container } from "reactstrap";
// import BuyAndSell from "../../my/buy-and-sell";
import ProjectAdvertisement from "layouts/ProjectAdvertisement";
import Projects from "./Projects";
import NFTs from "./NFTs";
import Documents from "./Documents";
import OurPartners from "./Partners";
import Tokenomics from "./Tokenomics";
import VParticles from "components/particles";
import { useEffect, useState } from "react";
import { getLinkYoutubeIframe } from "utils/common";
const View = () => {
  const [bg, setBg] = useState("/assets/images/slide/slide-2.jpg");

  const handleOpenVideo = (link: string) => {
    window.open(link)
  }

  useEffect(() => {
    const id = setInterval(() => {
      setBg(
        `/assets/images/slide/slide-${Math.floor(Math.random() * 3) + 1}.jpg`
      );
    }, 5000);
    return () => clearInterval(id);
  }, []);

  return (
    <>
      <Wrapper bg={bg}>
        <Section bgColorDefault={"bgColorLayer1"} id="home">
          <VParticles />
          <Container>
            <ContainerFirstHome>
              <TopMain>
                <ProjectAdvertisement />
              </TopMain>
              <MainEstock isEdit={true} className="main-stock">
                <Row>
                  <Col md={7} xs={12}>
                    <ItemContent>
                      <VideoYT onClick={() => handleOpenVideo(getLinkYoutubeIframe('Aq6W-nmRO94', true))}>
                        <iframe
                          frameBorder="0"
                          allow="accelerometer"
                          allowFullScreen={true}
                          title="YouTube video player"
                          src={getLinkYoutubeIframe('Aq6W-nmRO94')}
                        />
                      </VideoYT>
                    </ItemContent>
                  </Col>
                  <Col md={5} xs={12}>
                    <ItemContent>
                      <MainLogo>
                        <img src="/assets/images/icons/image1.svg" alt="" />
                      </MainLogo>
                    </ItemContent>
                  </Col>
                </Row>
                {/* <BuySell>
                  <BuyAndSell isShowChart={false} />
                </BuySell> */}
              </MainEstock>
            </ContainerFirstHome>
          </Container>
        </Section>

        <Section bgColorDefault={"blue300"} id={"about"}>
          <Container max="860px">
            <Title>
              <h3>VZX fundamentals</h3>
              <hr />
              <Description>
                "VZX goal is to work with Cryptocurrency projects on the
                Blockchain platform, Smart Contract and digital transformation
                of companies, so it works on the global blockchain technology
                platform"
              </Description>
            </Title>
            <Row>
              <Col md={4}>
                <ItemContent>
                  <Fundamentals data-aos="flip-left" data-aos-duration="800">
                    <h6>
                      <img src="/assets/images/icons/mark.svg" alt="" /> Trusted
                      by Millions
                    </h6>
                    <p>
                      Bonus for 10 million Users joining Vzone Global Ecosystem
                      from 2019 to 2025
                    </p>
                  </Fundamentals>
                  <Fundamentals data-aos="flip-left" data-aos-duration="800">
                    <h6>
                      <img src="/assets/images/icons/mark.svg" alt="" /> Target
                      Audience
                    </h6>
                    <p>
                      Businesses and individuals Pay advertising and marketing
                      fees on the Vzone Global ecosystem with VZX.
                    </p>
                  </Fundamentals>
                  <Fundamentals data-aos="flip-left" data-aos-duration="800">
                    <h6>
                      <img src="/assets/images/icons/mark.svg" alt="" />{" "}
                      Technology Expansion
                    </h6>
                    <p>
                      The point of using Lorem Ipsum is that it has a
                      more-or-less normal distribution of letters, as opposed to
                      using Content here, content here, making it look like
                      readable English.Many desktop.
                    </p>
                  </Fundamentals>
                </ItemContent>
              </Col>
              <Col md={4}>
                <ItemContent>
                  <FundamentalsImage>
                    <img src="/assets/images/icons/image2.svg" alt="" />
                  </FundamentalsImage>
                </ItemContent>
              </Col>
              <Col md={4}>
                <ItemContent>
                  <Fundamentals data-aos="flip-left" data-aos-duration="800">
                    <h6>
                      <img src="/assets/images/icons/mark.svg" alt="" /> Stake
                      profit
                    </h6>
                    <p>Bonus for Stock holders of Vzone Global ecosystem.</p>
                  </Fundamentals>
                  <Fundamentals data-aos="flip-left" data-aos-duration="800">
                    <h6>
                      <img src="/assets/images/icons/mark.svg" alt="" />{" "}
                      Mobilized Capital
                    </h6>
                    <p>
                      Crowdfunding to develop Blockchain applications to support
                      small and medium enterprises.
                    </p>
                  </Fundamentals>
                  <Fundamentals data-aos="flip-left" data-aos-duration="800">
                    <h6>
                      <img src="/assets/images/icons/mark.svg" alt="" /> Eternal
                      Smart Contract Token
                    </h6>
                    <p>
                      The original token of the Vzonex Exchange Digital Stock
                      exchange development project.
                    </p>
                  </Fundamentals>
                </ItemContent>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section bgColorDefault={"bgColorLayer1"}>
          <Container>
            <Title>
              <h3>Blockchain Technology</h3>
              <hr />
            </Title>
            <Row>
              <Col>
                <ItemContent data-aos="flip-left" data-aos-duration="800">
                  <br />
                  <VideoYT height={"400px"}  onClick={() => handleOpenVideo(getLinkYoutubeIframe('rwxKoUgkw7A', true))}>
                    <iframe
                      frameBorder="0"
                      allow="accelerometer picture-in-picture"
                      allowFullScreen={true}
                      title="YouTube video player"
                      width="640"
                      height="360"
                      src={getLinkYoutubeIframe('rwxKoUgkw7A')}
                    />
                  </VideoYT>
                </ItemContent>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section bgColorDefault={"blue300"}>
          {/* Projects */}
          <Projects />
        </Section>
        <Section bgColorDefault={"bgColorLayer1"} id="roadmap">
          <Container>
            <Title>
              <h3>VZX Roadmap</h3>
              <hr />
              <h5>Four Years Infrastructure Project Management Roadmap</h5>
              <Description>
                The team at Vzonex believes in proper and long-term strategy to
                take things ahead as planned. Presenting you an overall roadmap
                of Vzonex to let all of you know how much the Vzonex team is
                committed to future plans and all the phases have been planned
                following all professional measures.
              </Description>
            </Title>
            <RoadmapContainer>
              <Roadmap data-aos="flip-left" data-aos-duration="800">
                <ItemRoadmap>
                  <UpRoadmap>
                    <Timeline>
                      <span>Deploy E-Commerce Ecosystem</span>
                      <p>2019</p>
                    </Timeline>
                    <ColumnRoad></ColumnRoad>
                  </UpRoadmap>
                  <DetailRoadmap>
                    <ImageRoadmap>
                      <img src="/assets/images/icons/shareNft.svg" alt="" />
                    </ImageRoadmap>
                  </DetailRoadmap>
                </ItemRoadmap>
                <ItemRoadmap>
                  <DetailRoadmap>
                    <ImageRoadmap>
                      <img src="/assets/images/icons/shareNft.svg" alt="" />
                    </ImageRoadmap>
                  </DetailRoadmap>
                  <DownRoadmap>
                    <ColumnRoad></ColumnRoad>
                    <Timeline>
                      <span>Deploy E-Commerce Ecosystem</span>
                      <p>2019</p>
                    </Timeline>
                  </DownRoadmap>
                </ItemRoadmap>
                <ItemRoadmap>
                  <UpRoadmap>
                    <Timeline>
                      <span>Deploy E-Commerce Ecosystem</span>
                      <p>2019</p>
                    </Timeline>
                    <ColumnRoad></ColumnRoad>
                  </UpRoadmap>
                  <DetailRoadmap>
                    <ImageRoadmap>
                      <img src="/assets/images/icons/shareNft.svg" alt="" />
                    </ImageRoadmap>
                  </DetailRoadmap>
                </ItemRoadmap>
                <ItemRoadmap>
                  <DetailRoadmap>
                    <ImageRoadmap>
                      <img src="/assets/images/icons/shareNft.svg" alt="" />
                    </ImageRoadmap>
                  </DetailRoadmap>
                  <DownRoadmap>
                    <ColumnRoad></ColumnRoad>
                    <Timeline>
                      <span>Deploy E-Commerce Ecosystem</span>
                      <p>2019</p>
                    </Timeline>
                  </DownRoadmap>
                </ItemRoadmap>
                <ItemRoadmap>
                  <UpRoadmap>
                    <Timeline>
                      <span>Deploy E-Commerce Ecosystem</span>
                      <p>2019</p>
                    </Timeline>
                    <ColumnRoad></ColumnRoad>
                  </UpRoadmap>
                  <DetailRoadmap>
                    <ImageRoadmap>
                      <img src="/assets/images/icons/shareNft.svg" alt="" />
                    </ImageRoadmap>
                  </DetailRoadmap>
                </ItemRoadmap>
                <RowRoad></RowRoad>
              </Roadmap>
            </RoadmapContainer>
          </Container>
        </Section>
        <Section bgColorDefault={"blue300"} id="tokenomic">
          <Title>
            <h3>Tokenomics</h3>
            <hr />
          </Title>
          <Tokenomics />
        </Section>
        <Section bgColorDefault={"bgColorLayer1"} id="nfts">
          <NFTs 
            handleOpenVideo={handleOpenVideo}
          />
        </Section>
        <Section bgColorDefault={"blue300"} id="documents">
          {/* Documents */}
          <Documents />
        </Section>
        <Section id="partner" bgColorDefault={"bgColorLayer1"}>
          <OurPartners />
        </Section>
        <Section id="team" bgColorDefault={"blue300"}>
          <Container max={"860px"}>
            <Title>
              <h3>Our team</h3>
              <hr />
              <h5>MORE THAN 30+ EMPLOYEES ALL OVER THE WORLD</h5>
            </Title>
            <br />
            <Flex>
              <Col md={4}>
                <ItemContent data-aos="flip-left" data-aos-duration="800">
                  <OutTeam>
                    <Avta>
                      <ContainerImage>
                        <img src="/assets/images/icons/avataHieu.svg" alt="" />
                      </ContainerImage>
                    </Avta>
                    <h3>Mr. Hieu</h3>
                    <h4>Chief Executive Officer and Founder</h4>
                    <p>
                      An experienced leader in IT business with more than 14
                      years of experience in the IT industry - successful
                    </p>
                  </OutTeam>
                </ItemContent>
              </Col>
              <Col md={4}>
                <ItemContent data-aos="flip-left" data-aos-duration="800">
                  <OutTeam>
                    <Avta>
                      <ContainerImage>
                        <img src="/assets/images/icons/avataTri.svg" alt="" />
                      </ContainerImage>
                    </Avta>
                    <h3>Mr. Tri</h3>
                    <h4>Blockchain Technology Engineer</h4>
                    <p>Experience in developing blockchain applications</p>
                  </OutTeam>
                </ItemContent>
              </Col>
              <Col md={4}>
                <ItemContent data-aos="flip-left" data-aos-duration="800">
                  <OutTeam>
                    <Avta>
                      <ContainerImage>
                        <img src="/assets/images/icons/avataTinh.svg" alt="" />
                      </ContainerImage>
                    </Avta>
                    <h3>Mr. Tinh</h3>
                    <h4>Web App Engineer</h4>
                    <p>
                      Extensive experience in mobile application programming and
                      website development
                    </p>
                  </OutTeam>
                </ItemContent>
              </Col>
            </Flex>
          </Container>
        </Section>
        <Section bgColorDefault={"bgColorLayer1"}>
          <Footer main={true} />
        </Section>
      </Wrapper>
    </>
  );
};

export default View;
