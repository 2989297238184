/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-concat */
/* eslint-disable max-len */
/* eslint-disable indent */
import APIs from "api";
import VButton from "components/button";

import Countdown from "components/countdown";
import Input from "components/input";
import Modal from "components/modal";
import ToolTip from "components/tooltip/View";
import { TooltipWrapper } from "pages/my/task/styles";
import { useEffect, useState } from "react";
import {
  BsCameraVideoFill,
  BsFillGiftFill,
  BsFillImageFill,
} from "react-icons/bs";
import { FcStatistics } from "react-icons/fc";
import { TbAffiliate, TbWorld } from "react-icons/tb";
import { RiDivideFill } from "react-icons/ri";
import { toast } from "react-toastify";
import { Button, Col, Row } from "reactstrap";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { getPackagesAsync } from "redux/reducers/invests/invests.actions";
import { getPackages } from "redux/reducers/invests/invests.selector";
import { getUserProfile } from "redux/reducers/users/users.selector";
import { formatNumber } from "utils/common";
import { compareDateWithCur } from "utils/times";
import { getStocksPrice } from "redux/reducers/cryptos/cryptos.selector";
import CongratulationInvests from "components/congratulations";
import {
  Background,
  ContainModal,
  CounterdownWrapper,
  Footer,
  IconWrapper,
  Introduce,
  Item,
  PackagesWrapper,
  Profit,
  Title,
  ContainButton,
  ContainCard,
  Promotion,
  CounterdownPromotionWrapper,
} from "../styles";
import { getObjects } from "redux/reducers/settings/settings.selector";
import { Markup } from "interweave";
import ReactPlayer from "react-player";

const colorPackage = {
  bronze: " linear-gradient(20deg, #363232, #a48d0d);",
  silver: " linear-gradient(20deg, #363232, #a6a3a3);",
  gold: " linear-gradient(20deg, #363232, #FFD700);",
  platinum: " linear-gradient(20deg, #363232, #eff3f6);",
  diamond: "#f1f7fb",
};

const Packages = () => {
  const user = useAppSelector(getUserProfile);
  const packages = useAppSelector(getPackages);
  const stockPrice = useAppSelector(getStocksPrice);
  const dispatch = useAppDispatch();
  const objects = useAppSelector(getObjects);

  const outputDefault = {
    stock: 0,
    vnex: 0,
    name_stock: "",
  };

  const [openModal, setOpenModal] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [amountInvest, setAmountInvest] = useState(0);
  const [term, setTerm] = useState(false);
  const [userReveiveReferCode, setUserReveiveReferCode] = useState(null);
  const [timeLock, setTimeLock] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [showCal, setShowCal] = useState(false);
  const [amount, setAmount] = useState(0);
  const [profit, setProfit] = useState([]);
  const [output, setOutput] = useState(outputDefault);

  const [openModalCongratulation, setOpenModalCongratulation] = useState(false);
  const [congratulationInverst, setCongratulationInverst] = useState<any>(null);

  const [imageSelected, setImageSelected] = useState(null);
  const [videoSelected, setVideoSelected] = useState(null);

  const { vnex } = stockPrice;

  const allowTrust = (packageItem: any) => {
    if (
      packageItem.apply_users &&
      !packageItem.apply_users.includes(user.refer_code)
    ) {
      return -1;
    }
    if (
      packageItem?.token_for_location &&
      packageItem?.token_for_location.length > 0
    ) {
      const tokenForLocation = packageItem?.token_for_location?.find(
        (item) => item?.address?.province === user?.address?.province
      );
      if (tokenForLocation) {
        return (
          (tokenForLocation.token || 0) - (tokenForLocation.token_trusted || 0)
        );
      }
      return 0;
    }
    return 1;
  };

  const handleChange = (event) => {
    let amountTemp = +event;
    let profitTam = null;
    if (amountTemp > 0 && profit.length > 0) {
      if (timeLock) {
        profitTam = profit.find(
          (item) => +item.time_lock === +timeLock.split("_")[1]
        );
      } else {
        profitTam = profit[0];
      }
      const stockTemp = (amountTemp * profitTam.percent) / 100;
      const vnexTemp =
        (stockTemp * stockPrice[profitTam.stock].vnd) / vnex?.vnd;
      setOutput({
        stock: stockTemp,
        vnex: vnexTemp,
        name_stock: profitTam.stock,
      });
      setAmount(amountTemp);
    } else {
      setOutput({
        ...output,
        stock: 0,
        vnex: 0,
      });
      setAmount(null);
    }
  };

  const renderReward = (p: any) => {
    return (
      <TooltipWrapper>
        {(p.reward["stocks"] || []).length > 0 && (
          <>
            <label>Stocks :</label>
            {p.reward["stocks"].map(
              (s) =>
                s.amount > 0 && (
                  <div>
                    <span>
                      +{s.amount}
                      {s.is_percent ? "%" : ""} (
                      {String(s.symbol).toUpperCase()})
                    </span>
                  </div>
                )
            )}
            <hr />
          </>
        )}
        {(p.reward["vouchers"] || []).length > 0 && (
          <>
            <label>Vouchers :</label>
            {p.reward["vouchers"].map(
              (s) =>
                s.amount > 0 && (
                  <div>
                    <span>
                      +{s.amount} ({s.id})
                    </span>
                  </div>
                )
            )}
            <hr />
          </>
        )}
        {(p.reward["tickets"] || []).length > 0 && (
          <>
            <label>Tickets :</label>
            {p.reward["tickets"].map(
              (s) =>
                s.amount > 0 && (
                  <div>
                    <span>
                      +{s.amount} ({s.id})
                    </span>
                  </div>
                )
            )}
            <hr />
          </>
        )}
        {(p.reward["codes"] || []).length > 0 && (
          <>
            <label>Codes :</label>
            {p.reward["codes"].map((s) => (
              <div>
                <span>
                  +{s.codes} ({s.symbol})
                </span>
              </div>
            ))}
            <hr />
          </>
        )}
      </TooltipWrapper>
    );
  };

  const renderAffilate = (p: any) => {
    return (
      <TooltipWrapper>
        {Object.keys(p.affiliates || {}).map((key) => {
          const reward = p.affiliates[key];
          return (
            <>
              <div>Level : {String(key).split("f")[1]}</div>
              <hr />
              {(reward["stocks"] || []).length > 0 && (
                <>
                  <label>Stocks :</label>
                  {reward["stocks"].map(
                    (s) =>
                      +s.amount > 0 && (
                        <div>
                          <span>
                            +{formatNumber(s.amount, 0)}
                            {s.is_percent ? "%" : ""} (
                            {String(s.symbol).toUpperCase()})
                          </span>
                        </div>
                      )
                  )}
                  <hr />
                </>
              )}
              {(reward["vouchers"] || []).length > 0 && (
                <>
                  <label>Vouchers :</label>
                  {reward["vouchers"].map(
                    (s) =>
                      +s.amount > 0 && (
                        <div>
                          <span>
                            +{s.amount} ({s.id})
                          </span>
                        </div>
                      )
                  )}
                  <hr />
                </>
              )}
              {(reward["tickets"] || []).length > 0 && (
                <>
                  <label>Tickets :</label>
                  {reward["tickets"].map(
                    (s) =>
                      s.amount > 0 && (
                        <div>
                          <span>
                            +{s.amount} ({s.id})
                          </span>
                        </div>
                      )
                  )}
                  <hr />
                </>
              )}
              {(reward["codes"] || []).length > 0 && (
                <>
                  <label>Codes :</label>
                  {reward["codes"].map((s) => (
                    <div>
                      <span>
                        +{s.codes} ({s.symbol})
                      </span>
                    </div>
                  ))}
                  <hr />
                </>
              )}
              <br />
            </>
          );
        })}
      </TooltipWrapper>
    );
  };

  const renderStatis = (p: any) => {
    const amountJoin = (p.investors || []).filter((u) => u === user._id).length;
    return (
      <TooltipWrapper>
        <div>
          <strong>You join project: </strong> {formatNumber(amountJoin, 0)}/
          {formatNumber(p.require.max_per_user, 0) || 0}
        </div>
        <hr />
        <div>
          <strong>Total package:</strong>{" "}
          {formatNumber(p.amount_invested, 0) || 0}/
          {formatNumber(p.require.total_package, 0)}
        </div>
        <hr />
      </TooltipWrapper>
    );
  };

  const renderImage = (image) => {
    return (
      <ContainModal>
        <div className="image">
          <img className="img-info" src={image} alt="" />
        </div>
      </ContainModal>
    );
  };

  const renderVideo = (video) => {
    return (
      <ContainModal>
        <div className="video-responsive">
          <iframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${video}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen={true}
          ></iframe>
        </div>
      </ContainModal>
    );
  };

  const renderCal = (selectedPackage) => {
    if (selectedPackage) {
      return (
        <ContainModal>
          <Row>
            <div className="packages">
              {(selectedPackage.profits || []).map((p) => (
                <Button
                  className={
                    timeLock === `${selectedPackage._id}_${p.time_lock}`
                      ? "active"
                      : ""
                  }
                  color="info"
                  outline
                  onClick={() => {
                    setAmount(0);
                    setTimeLock(`${selectedPackage._id}_${p.time_lock}`);
                  }}
                >
                  {p.time_lock} {selectedPackage.type_lock}s
                </Button>
              ))}
            </div>
            <Input
              label="Amount"
              name="amount"
              isCurrency={true}
              value={amount}
              onValueChange={(e) => handleChange(e)}
            />
            <ContainButton>
              <Button children="=>" />
            </ContainButton>

            <ContainCard>
              <div className="output">
                <span>Total profits: </span>
                <div>
                  ~ {formatNumber(output ? output?.stock : 0)}{" "}
                  {String(output.name_stock || "").toUpperCase()}
                </div>
                <div>~ {formatNumber(output?.vnex)} VNEX</div>
              </div>
            </ContainCard>
          </Row>
        </ContainModal>
      );
    }
  };

  const renderCountDown = (p) => {
    let title = "";
    let time = 0;
    if (p.time.start_date && compareDateWithCur(p.time.start_date) > 0) {
      title = "Upcoming";
      time = new Date().getTime() + compareDateWithCur(p.time.start_date);
    } else {
      title = "Happening";
      time = new Date().getTime() + compareDateWithCur(p.time.end_date);
    }

    return (
      <CounterdownWrapper>
        <span className={`title ${title}`}>{title}</span>
        <Countdown date={time} />
      </CounterdownWrapper>
    );
  };

  const renderCountDownPromotion = (date) => {
    const time = new Date().getTime() + compareDateWithCur(date);
    return (
      <CounterdownPromotionWrapper>
        <Countdown date={time} />
      </CounterdownPromotionWrapper>
    );
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const purchaseInvestPackage = await APIs.PURCHASE.investPackage({
        body: {
          package_id: selectedPackage._id,
          amount: amountInvest,
          timeLock: +String(timeLock).split("_")[1],
          is_term: term,
          userReveiveReferCode,
        },
      });
      setCongratulationInverst(purchaseInvestPackage);
      setOpenModal(false);
      setOpenModalCongratulation(true);
      dispatch(
        getPackagesAsync({
          query: {
            status: "opening",
          },
        })
      );
      toast.success("Congratulations on your successful investment!");
      setLoading(false);
      setAmountInvest(0);
      setSelectedPackage(null);
    } catch (error) {
      setLoading(false);
      toast.error(error.errors);
    }
  };

  const handleClosed = () => {
    setOpenModal(false);
    setSelectedPackage(null);
    setCongratulationInverst(null);
    setShowImage(false);
    setShowVideo(false);
    setShowCal(false);
    setLoading(false);
    setAmount(0);
    setProfit(null);
    setAmountInvest(0);
  };

  const PackageWrapper = ({ item, children }: { item: any; children: any }) => {
    return !!item?.video_background ? (
      <div className="video-bg">
        <ReactPlayer
          muted={true}
          loop={true}
          controls={false}
          key={item?._id}
          playing={true}
          className="player-video-link"
          url={item.video_background}
        />
        {children}
      </div>
    ) : (
      <Background bg={item.background}>{children}</Background>
    );
  };

  const renderModal = (selectedPackage: any) => {
    if (selectedPackage) {
      return (
        <ContainModal>
          <h3>{selectedPackage.name}</h3>
          {selectedPackage.is_term && (
            <div className="editor">
              <Markup content={selectedPackage.term} />{" "}
            </div>
          )}
          <hr />
          <div className="packages">
            {(selectedPackage.profits || []).map((p) => (
              <Button
                className={
                  timeLock === `${selectedPackage._id}_${p.time_lock}`
                    ? "active"
                    : ""
                }
                color="info"
                outline
                onClick={() =>
                  setTimeLock(`${selectedPackage._id}_${p.time_lock}`)
                }
              >
                {p.time_lock} {selectedPackage.type_lock}s
              </Button>
            ))}
          </div>
          <label>
            Amount {String(selectedPackage.invest.stock).toUpperCase()}
          </label>
          <Input
            name="amountFrom"
            isCurrency={true}
            value={amountInvest}
            placeholder="0.00"
            onValueChange={(e) => setAmountInvest(e)}
          />

          {selectedPackage.apply_users && (
            <>
              <label>Trust for user</label>
              <Input
                name="User Id"
                value={userReveiveReferCode}
                placeholder="VZX....."
                onChange={(e) => setUserReveiveReferCode(e.target.value)}
              />
            </>
          )}
          {selectedPackage.is_term && (
            <div className="accept_term">
              <input
                type="checkbox"
                checked={term}
                onChange={(e) => setTerm(e.target.checked)}
              />
              I have read and agree to the terms of the project
            </div>
          )}
          <VButton onClick={handleSubmit} loading={loading}>
            TRUST - DONATE PROJECT
          </VButton>
        </ContainModal>
      );
    }
  };

  useEffect(() => {
    dispatch(
      getPackagesAsync({
        query: {
          status: "opening",
        },
      })
    );
  }, []);

  return (
    <PackagesWrapper>
      {packages &&
        packages.map((p: any) => (
          <Item type={colorPackage[p.type]}>
            {p.promotionImage && (
              <Promotion>
                <div className="promotion-image">
                  <img
                    style={{
                      position: "relative",
                    }}
                    src={p.promotionImage}
                    alt=""
                  />
                </div>
                {p.time?.promotion_date &&
                  renderCountDownPromotion(p.time?.promotion_date)}
              </Promotion>
            )}
            <PackageWrapper item={p}>
              <IconWrapper>
                <img
                  src={p.icon || "/assets/images/icons/package.png"}
                  alt=""
                />
              </IconWrapper>
              <Title>
                {`${formatNumber(p.invest.from, 0)} - ${formatNumber(
                  p.invest.to,
                  0
                )} ${String(p.invest.stock).toUpperCase()}`}{" "}
              </Title>
              {renderCountDown(p)}
              <Introduce>
                <p>{p.name}</p>
                <span>{p.description}</span>
              </Introduce>
              <hr />

              <Profit>
                <div>
                  <p className="title">Trust time</p>
                  <Row>
                    {(p.profits || []).map((item, index) => (
                      <Col
                        key={index}
                        xs={
                          p.profits.length === 1
                            ? 12
                            : p.profits.length === 2
                            ? 6
                            : 4
                        }
                        className={
                          timeLock === `${p._id}_${item.time_lock}`
                            ? "active"
                            : ""
                        }
                        onClick={() =>
                          setTimeLock(`${p._id}_${item.time_lock}`)
                        }
                      >
                        <span>
                          {formatNumber(item?.time_lock, 0)} {p.type_lock}s
                        </span>
                        <br />
                        <span>
                          Min Claim Reward:{" "}
                          {formatNumber(p.require.min_withdraw_profit, 0)}{" "}
                          {String(item.stock).toUpperCase()}
                        </span>
                      </Col>
                    ))}
                  </Row>
                  <br />
                </div>
                <hr />
                <div>
                  <p className="title">Trust Reward</p>
                  <Row>
                    {(p.profits || []).map((item, key) => (
                      <Col
                        key={key}
                        xs={
                          p.profits.length === 1
                            ? 12
                            : p.profits.length === 2
                            ? 6
                            : 4
                        }
                        className={
                          timeLock === `${p._id}_${item.time_lock}`
                            ? "active"
                            : ""
                        }
                        onClick={() =>
                          setTimeLock(`${p._id}_${item.time_lock}`)
                        }
                      >
                        <span>
                          {formatNumber(item.time_lock, 0)} {p.type_lock}s (
                          {String(item.stock).toUpperCase()}){" "}
                        </span>
                        <br />
                        <span>
                          Total : <br />
                          {`${formatNumber(
                            (+item.percent * +p.invest.from) / 100
                          )} => ${formatNumber(
                            (+item.percent * +p.invest.to) / 100
                          )}`}
                        </span>
                        <br />
                        <span>
                          {p.type_lock === "month"
                            ? "Monthly"
                            : p.type_lock === "day"
                            ? "Bonus daily"
                            : "Weekly"}
                          : <br />
                          {`${formatNumber(
                            (item.percent * p.invest.from) /
                              +item.time_lock /
                              100,
                            3
                          )} => ${formatNumber(
                            (item.percent * p.invest.to) /
                              +item.time_lock /
                              100,
                            3
                          )} `}
                        </span>
                      </Col>
                    ))}
                  </Row>
                </div>
                <hr />
                <div>
                  <ToolTip id={`${p._id}_gift`} content={renderReward(p)}>
                    <span id={`tooltip-` + `${p._id}_gift`} className="gift">
                      <BsFillGiftFill size={20} className="reward" />
                    </span>
                  </ToolTip>

                  <ToolTip id={`${p._id}_aff`} content={renderAffilate(p)}>
                    <span id={`tooltip-` + `${p._id}_aff`} className="gift">
                      <TbAffiliate size={22} />
                    </span>
                  </ToolTip>

                  <ToolTip id={`${p._id}_statis`} content={renderStatis(p)}>
                    <span id={`tooltip-` + `${p._id}_statis`} className="gift">
                      <FcStatistics size={25} />
                    </span>
                  </ToolTip>
                  <ToolTip id={`${p._id}_image`} content={renderImage(p.image)}>
                    <span
                      id={`tooltip-` + `${p._id}_image`}
                      className="gift"
                      onClick={() => {
                        setImageSelected(p.image);
                        setShowImage(true);
                      }}
                    >
                      <BsFillImageFill size={20} />
                    </span>
                  </ToolTip>
                  <ToolTip id={`${p._id}_video`} content={"Click view video"}>
                    <span
                      id={`tooltip-` + `${p._id}_video`}
                      className="gift"
                      onClick={() => {
                        setVideoSelected(p.video);
                        setShowVideo(true);
                      }}
                    >
                      <BsCameraVideoFill size={25} />
                    </span>
                  </ToolTip>
                  <ToolTip
                    id={`${p._id}_cal`}
                    content={"Click view investment returns"}
                  >
                    <span
                      id={`tooltip-` + `${p._id}_cal`}
                      className="gift"
                      onClick={() => {
                        setShowCal(true);
                        setProfit(p.profits);
                        setSelectedPackage(p);
                      }}
                    >
                      <RiDivideFill size={25} />
                    </span>
                  </ToolTip>
                  <ToolTip id={`${p._id}_website`} content={p.website}>
                    <span
                      id={`tooltip-` + `${p._id}_website`}
                      className="gift"
                      onClick={() => {
                        window.open(p.website);
                      }}
                    >
                      <TbWorld size={18} />
                    </span>
                  </ToolTip>
                </div>
                <div className="intended-for">
                  Priority for :{" "}
                  {objects.map((o) =>
                    (p.objects || []).includes(o.id) ? `${o.name},` : ""
                  )}
                </div>
              </Profit>
              {allowTrust(p) > 1 && (
                <>
                  <hr />
                  <div>
                    Amount Remain : {formatNumber(allowTrust(p))}
                    {" " + String(p.invest.stock).toUpperCase()}
                  </div>
                </>
              )}
              <hr />

              <Footer>
                <Button
                  onClick={() => {
                    setSelectedPackage(p);
                    setOpenModal(true);
                  }}
                  className="btn-glow"
                  disabled={allowTrust(p) < 1}
                >
                  {allowTrust(p) === -1 ? "CONTACT FOR TRUST" : " TRUST - DONATE PROJECT"}
                </Button>
              </Footer>
            </PackageWrapper>
          </Item>
        ))}
      <Modal
        isOpen={openModal}
        children={renderModal(selectedPackage)}
        onCancel={handleClosed}
        centered={true}
      />
      <Modal
        isOpen={showImage}
        children={renderImage(imageSelected)}
        onCancel={handleClosed}
        centered={true}
      />
      <Modal
        isOpen={showVideo}
        children={renderVideo(videoSelected)}
        onCancel={handleClosed}
        centered={true}
      />
      <Modal
        isOpen={showCal}
        children={renderCal(selectedPackage)}
        onCancel={handleClosed}
        centered={true}
      />
      <CongratulationInvests
        type="investsSuccess"
        background={congratulationInverst?.package_id?.backgroundSuccess}
        userName={congratulationInverst?.user_id?.fullName}
        avataUser={congratulationInverst?.user_id?.avatar}
        logoProject={congratulationInverst?.package_id?.icon}
        logoLevel={congratulationInverst?.package_id?.logoLevel}
        amount={congratulationInverst?.investment?.amount}
        symbol={congratulationInverst?.package_id?.invest?.stock}
        createdAt={congratulationInverst?.createdAt}
        openModalCongratulation={openModalCongratulation}
        setOpenModalCongratulation={setOpenModalCongratulation}
      />
    </PackagesWrapper>
  );
};

export default Packages;
