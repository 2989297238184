import Input from "components/input";
import { Controller } from "react-hook-form";

import {
  FormItem,
} from "../../styles";

const Step1 = (props) => {
  const { control, errors, errMessage } = props;
  return <>
    <FormItem>
      <Controller
        name="email"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <Input
            type="email"
            hasError={Boolean(errors.email)}
            label="Email"
            errMessage={errMessage}
            placeholder="Email"
            {...field}
          />
        )}
      />
    </FormItem>
    <FormItem>
      <Controller
        name="password"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <Input
            type={"password"}
            hasError={Boolean(errors.password)}
            label="Password"
            placeholder="Password"
            {...field}
          />
        )}
      />
    </FormItem>
  </>
}

export default Step1;