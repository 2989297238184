import { connect} from 'react-redux'
import { getWalletByOptionAsync } from "redux/reducers/wallets/wallets.actions";


import View from "./View";


const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {
  getWalletByOptionAsync
}


export default connect(mapStateToProps, mapDispatchToProps)(View);
