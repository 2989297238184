/* eslint-disable indent */
import { Col, Row } from "reactstrap";
import {
  Amount,
  Content,
  Detail,
  DetailRightView,
  Status,
  Title,
} from "../../../styles";

const View = (props: any) => {
  const { stock, type } = props;

  return (
    <DetailRightView paddingTop={"20px"}>
      <Title bgColor={"grey200"} just="space-between">
        <p>
          {type === "deposit" ? "Deposit" : "Withdraw"}{" "}
          {String(stock || "").toUpperCase()} history
        </p>
      </Title>
      {props.lists.length &&
        props.lists.map((el: any, index: number) => (
          <Content key={index}>
            <Row lg={12}>
              <Col xs={8} sm={6}>
                <Detail>
                  <h6>Reference: {el.content}</h6>
                  <h6>{new Date(el.updatedAt).toLocaleString()}</h6>
                </Detail>
              </Col>
              <Col xs={4} sm={6}>
                <Amount>
                  {props.type === "withdraw" ? (
                    <h6>
                      - {el.amount.toFixed(0)}{" "}
                      {String(stock || "").toUpperCase()}
                    </h6>
                  ) : (
                    <h6>
                      + {el.amount_received.toFixed(0)}{" "}
                      {String(stock || "").toUpperCase()}
                    </h6>
                  )}
                  <Status
                    color={
                      el.status === "pending"
                        ? "yellow100"
                        : el.status === "success"
                        ? "green100"
                        : "red100"
                    }
                  >
                    {el.status}
                  </Status>
                </Amount>
              </Col>
            </Row>
          </Content>
        ))}
    </DetailRightView>
  );
};

export default View;
