/* eslint-disable indent */
/* eslint-disable no-useless-concat */
import Button from "components/button";
import ToolTip from "components/tooltip/View";
import UploadFile from "components/uploadFile";

import {
  Section,
  ItemContent,
  Title,
  Project,
  ItemDoc,
  Document,
  AddOrRemoveChil,
  BackGroundDoc,
  // IconDoc,
  LinkDoc,
  MovePosition,
  ContainerDoc,
} from "pages/home/landing-page/styles";

import { Row, Col, Container } from "reactstrap";

import { v4 as uuidv4 } from "uuid";

const View = (props) => {
  const {
    docState,
    data_key,
    title,
    isEdit,
    color,
    isDisable,
    setDocState,
    onBlurText,
    hasWallpaper,
  } = props;

  const openTagWindow = (url: string) => {
    if (!isEdit) {
      window.open(url);
    }
  };

  const handleAddOrRemoveChil = (type: string) => {
    if (type === "add") {
      setDocState(
        docState.concat({
          id: uuidv4(),
          title: "Doc 1",
          decs: "Doc 1",
          src: "",
          link: "",
        })
      );
    } else {
      const newArr = docState.slice(0, docState.length - 1);
      setDocState(newArr);
    }
  };

  const removeChil = (uuid: string) => {
    const newArr = docState.filter((el: any) => el.id !== uuid);
    setDocState(newArr);
  };

  const handleMoveChil = (index: number, key: string) => {
    const newArr = docState;
    const el = newArr[index];

    if (key === "upward") {
      newArr[index] = newArr[index - 1];
      newArr[index - 1] = el;
    } else {
      newArr[index] = newArr[index + 1];
      newArr[index + 1] = el;
    }
    setDocState([...newArr]);
  };

  const setFile = (f: any, id: string, key?: string) => {
    setDocState((prev) =>
      prev.map((el: any) =>
        el.id === id
          ? {
              ...el,
              file: f,
              isNew: true,
            }
          : el
      )
    );
  };

  const onBlurDocument = (e: any, type: string, uuid?: string) => {
    setDocState((prev) =>
      prev.map((el) =>
        el.id === uuid
          ? {
              ...el,
              [type]: e.target.innerText,
            }
          : el
      )
    );
  };

  return (
    <>
      <Section
        bgColor={color.bgColor}
        bgColorDefault={color.bgDefault}
        textColor={color.textColor}
        isDisable={isDisable}
        hasWallpaper={hasWallpaper}
      >
        <Container>
          {isEdit && (
            <AddOrRemoveChil>
              <div onClick={() => handleAddOrRemoveChil("add")}>+</div>
              <div onClick={() => handleAddOrRemoveChil("remove")}>-</div>
            </AddOrRemoveChil>
          )}
          <Title titleColor={color.titleColor}>
            <h3
              data-key={data_key}
              contentEditable={isEdit}
              onBlur={(e) => onBlurText(e, "doc")}
            >
              {title || "DOCUMENTS"}
            </h3>
            <hr />
          </Title>
          <br />
          <Document hoverColor={color.hoverColor}>
            <Row>
              {docState.length > 0 &&
                docState.map((el: any, index: any) => (
                  <Col md={4} key={el.id} className="doc_item">
                    <ItemContent>
                      {isEdit && (
                        <>
                          <AddOrRemoveChil>
                            <div onClick={() => removeChil(el.id)}>-</div>
                          </AddOrRemoveChil>
                          <MovePosition>
                            {index !== 0 && (
                              <Button
                                onClick={() => handleMoveChil(index, "upward")}
                              >
                                {"<"}
                              </Button>
                            )}
                            {index !== docState.length - 1 && (
                              <Button
                                onClick={() =>
                                  handleMoveChil(index, "downward")
                                }
                              >
                                {">"}
                              </Button>
                            )}
                          </MovePosition>
                        </>
                      )}
                      <ContainerDoc>
                        <Project pd="0px" mb="0px" isDoc={true}>
                          <ItemDoc
                            onClick={() =>
                              !isEdit ? openTagWindow(el?.link) : ""
                            }
                          >
                            {isEdit && (
                              <BackGroundDoc zIndex={"9"}>
                                <UploadFile
                                  id={el.id}
                                  sectionName="doc"
                                  ratio="420x270"
                                  file={el.file}
                                  setFile={setFile}
                                />
                              </BackGroundDoc>
                            )}
                            <BackGroundDoc zIndex={"0"}>
                              <img
                                hidden={Boolean(el.file)}
                                src={el.src}
                                alt=""
                              />
                            </BackGroundDoc>
                          </ItemDoc>
                        </Project>
                        <ToolTip id={`${el.id}`} content={el.link}>
                          <span id={`tooltip-` + `${el.id}`}>
                            {" "}
                            <div className="_info_doc">
                              <h5
                                contentEditable={isEdit}
                                onBlur={(e) =>
                                  onBlurDocument(e, "title", el.id)
                                }
                              >
                                {el?.title}
                              </h5>
                              <p
                                contentEditable={isEdit}
                                onBlur={(e) => onBlurDocument(e, "decs", el.id)}
                              >
                                {el?.decs}
                              </p>
                              {isEdit && (
                                <LinkDoc>
                                  Link:{" "}
                                  <span
                                    contentEditable={isEdit}
                                    onBlur={(e) =>
                                      onBlurDocument(e, "link", el.id)
                                    }
                                  >
                                    {el?.link || "Add link here"}
                                  </span>
                                </LinkDoc>
                              )}
                            </div>
                          </span>
                        </ToolTip>
                      </ContainerDoc>
                    </ItemContent>
                  </Col>
                ))}
            </Row>
          </Document>
        </Container>
      </Section>
    </>
  );
};

export default View;
