import styled from "styled-components";

export const Container = styled.div<{ bgColorHeader?: any }>`
  padding: 20px 30px;
  background: ${(props) => props.bgColorHeader || "#172D4D"};
  height: 78px;
  width: 100%;
  position: fixed;
  top: 30px;
  z-index: 1000;

  @media screen and (max-width: 1100px) {
    height: 56px;
  }
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;

  @media screen and (max-width: 1100px) {
    justify-content: center;
  }
`;

export const Logo = styled.div`
  position: relative;
  width: 227.75px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 62px;

  img {
    max-width: 100%;
    max-height: 62px;
  }

  @media screen and (max-width: 1100px) {
    width: 150px;
    height: 40px;
    justify-content: center;

    img {
      max-height: 40px;
    }
  }
`;

export const Menu = styled.div`
  width: 750px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media screen and (max-width: 1230px) {
    width: 600px;
  }

  @media screen and (max-width: 1100px) {
    display: none;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      margin-right: 3px;
    }
  }
`;

export const HeaderEnd = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button:last-child {
    margin-left: 10px;
  }
  @media screen and (max-width: 1100px) {
    display: none;
  }
`;

export const Item = styled.div<{ height?: string }>`
  display: flex;
  align-items: center;
  color: white;
  cursor: pointer;
  height: ${(props) => props.height || ""};
  overflow: hidden;

  img {
    width: auto;
    height: 100%;
  }

  @media screen and (max-width: 1100px) {
    padding: 10px;

    :first-child {
      border-bottom: 1px solid grey;
    }
  }
`;

export const Icon = styled.img`
  margin-right: 5px;
`;

export const Text = styled.div`
  font-size: 16px;
  margin: 0 3px;
`;

export const MenuIcon = styled.div`
  position: absolute;
  display: none;
  top: 20px;
  left: 15px;
  @media screen and (max-width: 1100px) {
    display: block;
    top: 10px;
    left: 10px;
  }
`;

export const TopMain = styled.div`
  padding: 2px 0;
  background-color: #3d639c;
  position: fixed;
  top: 0;
  width: 100%;
  height: 30px;
  z-index: 9999999;
`;

export const Coins = styled.div`
  display: flex;
  flex-direction: row;
  color: white;
`;

export const OptionMenu = styled.div`
  position: fixed;
  z-index: 9999999999;
  background-color: ${(props) => props.theme.colors.black100};
  color: white;
  left: 0;
  top: 0;
  height: calc(var(--vh, 1vh) * 100);
  padding: 20px;
  width: 100vw;
  max-width: 500px;

  button {
    :last-child {
      margin-left: 5px;
    }
  }
`;

export const Upload = styled.div<{ exception?; width?: any }>`
  position: absolute;
  width: ${(props) =>
    props.exception ? `${props.width || "inherit"}` : "auto"};
  max-width: 100%;
  height: 100%;
  opacity: ${(props) => (props.exception ? 0 : 1)};
`;
