/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Title } from "../../styles";
import { getDepositBanks } from "redux/reducers/banks/banks.actions";
import { getUserProfile } from "redux/reducers/users/users.selector";
import History from "../history-vnd";
import APIs from "api";
import Step1 from "./Step1";
import useQuery from "hooks/useQuery";
import { toast } from "react-toastify";

const View = () => {
  const defaultData = {
    bank: null,
    amount: null,
    content: "",
    proof: "",
    currency: "VNP",
    hash: null,
    stock: "vnp",
  };

  const query = useQuery();

  const getResult = () => {
    console.log(query);
    console.log(typeof query.vnp_ResponseCode);
    if (query?.vnp_ResponseCode === "00") {
      toast.success("Deposit successfully!")
    }
  };

  const user: any = useSelector(getUserProfile);

  const [dataPayment, setDataPayment] = useState(defaultData);
  const [hisDeposit, setHisDeposit] = useState<any>([]);

  const dispatch = useDispatch();

  const setDataByKey = (key, value) => {
    setDataPayment({
      ...dataPayment,
      [key]: value,
    });
  };

  const getPayment = async () => {
    try {
      const res: any = await APIs.PAYMENT.getPaymentByUserId({
        userId: user._id,
        type: "deposit",
        stock: "vnp",
      });
      if (res.payments?.length > 0) {
        setHisDeposit(res.payments);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(getDepositBanks());
    getPayment();
  }, [dispatch]);

  useEffect(() => {
    getResult();
  }, [query])

  return (
    <>
      <Title bgColor={"grey200"} just="center" direction="column">
        Select amount to confirm deposit
      </Title>

      <Step1
        dataPayment={dataPayment}
        setDataByKey={setDataByKey}
      />

      {hisDeposit.length ? (
        <History lists={hisDeposit} type="deposit" />
      ) : (
        <></>
      )}
    </>
  );
};

export default View;
