import styled, { css } from "styled-components";
import { isMobile } from "react-device-detect";

const Container = styled.div<{ width: any }>`
  width: ${(props) => props.width || "100%"};
  ${isMobile
    ? css`
        height: calc(var(--vh, 1vh) * 100);
      `
    : css`
        height: 100vh;
      `}

  max-width: 450px;
  overflow: auto;
  background-color: #071120;
  position: relative;
  transition: width 0.1s;
  overflow-y: hidden;
  /* min-height: ${!isMobile ? "100vh" : "unset"}; */

  p,
  h3 {
    margin-bottom: 0px;
  }

  @media screen and (max-width: 1100px) {
    position: fixed;
    z-index: 1000;
    bottom: 0;
    top: 0;
    height: auto;
  }
`;

export const Wrapper = styled.div<{ width: any }>`
  position: relative;
  width: ${(props) => props.width || "100vw"};
  max-width: 253px;
  z-index: 100000;

  @media screen and (max-width: 1100px) {
    max-width: 450px;
    height: calc(calc(var(--vh, 1vh) * 100));
  }

  @media screen and (max-width: 960px) {
    width: 0;
  }
`;

export const CerAvt = styled.div`
  position: relative;
`;

export const LogoAndMenuIcon = styled.div`
  margin: auto;
  position: fixed;
  z-index: 99999;
  background-color: ${(props) => props.theme.colors.blue300};
  width: 100%;
  top: 30px;
`;

export const Logo = styled.div<{ isOpen?: boolean }>`
  padding: ${(props) => (props.isOpen ? "24px" : "0px")};
  cursor: pointer;
  height: ${isMobile ? "56px" : "75px"};
  width: ${(props) => (isMobile ? "150px" : props.isOpen ? "240px" : "70px")};
  margin: auto;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  img {
    width: 100%;
  }
`;

export const UserMenu = styled.div`
  border-top: 1px solid #a0a0a0;
  margin-top: 70px;
  height: calc(100% - 75px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #3a3f51;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #3a3f51;
  }

  .sidebar {
    /* min-width: 250px; */
    /* max-width: 250px; */
    /* margin-left: -250px; */
    /* transition: all 0.5s; */
    .submenu-title {
      padding: 8px 23px;
      font-size: 14px;
      font-weight: 500;
      opacity: 0.8;
      cursor: pointer;
      :hover {
        background-color: #3a3f51;
      }
    }
    .dropdown-toggle {
      position: relative;
    }
    .dropdown-toggle::after {
      position: absolute;
      font-size: 19px;
      top: 30%;
      right: 0;
    }
  }

  .sidebar.is-open {
    margin-left: 0;
    transition: 0.5s;
  }

  .content {
    padding: 20px;
    margin-left: 0;
    height: 100vh;
  }
`;

export const Menu = styled.div`
  position: relative;
  /* padding: 16px 0; */
  height: 100%;
  max-height: calc(100% - ${isMobile ? "95px" : "175px"});
  overflow: auto;
  /* margin-top: 70px; */
`;

export const MenuIcon = styled.div`
  position: fixed;
  height: 56px;
  padding: 14.5px 10px;
  z-index: 99999;

  img {
    width: 26px;
  }
`;

export const MenuItem = styled.div<{ disabled?: boolean }>`
  padding: 8px 23px;
  cursor: pointer;
  background-color: ${(props: any) =>
    props.selected ? "#3A3F51" : "transperent"};
  background: ${(props: any) =>
    props.selected
      ? "linear-gradient(270deg,#f3e485,#b18145 106.25%)"
      : "transparent"};
  border-radius: 4px;
  display: flex;
  align-items: center;

  ${(props) =>
    props.disabled
      ? css`
          opacity: 0.6;
        `
      : ``}

  svg {
    color: ${(props: any) => (!props.selected ? "#f0e79d" : "#fff")};
  }
  .name {
    font-size: 14px;
    margin-left: 4px;
    font-weight: 500;
  }
  :hover {
    background-color: #3a3f51;
    /* color: #ffe600; */
  }
  img {
    margin-right: 8px;
    margin-top: 2px;
  }
`;

export const UserInfo = styled.div<{ selected; isOpen: any }>`
  display: flex;
  width: ${(props) => (isMobile && props.selected ? "320px" : "auto")};
  position: ${(props) =>
    props.isOpen ? "fixed" : isMobile ? "absolute" : "fixed"};
  align-items: center;
  top: ${isMobile ? "0" : "70px"};
  cursor: pointer;
  margin: 0px 8px 15px 8px;
  padding: 3px 10px;
  border-radius: 4px;
  background-color: ${(props) =>
    props.selected
      ? props.theme.colors.grey200
      : props.theme.colors.bgColorLayer2};
`;

export const VerifyIcon = styled.div`
  position: absolute;
  bottom: -5px;
  right: 0;
  z-index: 100;
  img {
    width: 16px;
  }
`;

export const Avatar = styled.div`
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  img {
    width: 50px;
    height: 50px;
  }
`;

export const Info = styled.div`
  margin-left: 10px;
  h3 {
    font-size: 18px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    max-width: 160px;
  }
  p {
    font-size: 12px;
  }

  span {
    font-size: 12px;
    font-weight: 600;
  }

  .animate-charcter {
    text-transform: uppercase;
    background-image: linear-gradient(
      -225deg,
      #231557 0%,
      #44107a 29%,
      #ff1361 67%,
      #fff800 100%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 3s linear infinite;
    display: inline-block;
  }

  @keyframes textclip {
    from {
      background-position: 200% center;
    }
  }

  @keyframes span {
    0% {
      text-shadow: 0 0 6px #13e685;
    }
    100% {
      text-shadow: 0 0 0px #ffffff;
    }
  }
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 10px;
  width: 100%;
`;

export const ContainIcon = styled.div<{ isOpen: boolean }>`
  img:first-child {
    margin-left: ${(props) => (props.isOpen ? "29px" : "30px")};
  }
  img {
    display: ${(props) => (props.isOpen ? "inline" : "block")};
    margin-left: ${(props) => (props.isOpen ? "20px" : "30px")};
    margin-bottom: ${(props) => (props.isOpen ? "0px" : "10px")};
    cursor: pointer;
  }
`;

export const SignOut = styled(MenuItem)<{ isOpen: boolean }>`
  margin-top: 0px;
  span {
    margin-left: 5px;
  }
`;

export const OpenMenuIcon = styled.div<{ isOpen: boolean }>`
  position: absolute;
  z-index: 999999;
  right: -13px;
  top: 20px;
  cursor: pointer;
  transform: ${(props) => (props.isOpen ? "rotate(0deg)" : "rotate(180deg)")};

  img {
    width: 40px;
  }
`;

export const Copy = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-right: 5px;
  }
`;

export const CloseSideBar = styled.div`
  position: absolute;
  z-index: 999999999;
  cursor: pointer;
  right: 15px;
  top: 10px;
  color: ${(props) => props.theme.colors.orange100};
`;

export const PigWrapper = styled.div`
  margin-left: 10px;
  .pig {
    width: 50px;
    animation: wiggle 2s linear infinite;
  }
`;

export default Container;
