import styled from "styled-components";

export const ProjectWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  @media screen and (max-width: 640px) {
    width: 100%;
  }
`;

export const Items = styled.div`
  display: flex;
  flex-direction: row;
  color: white;
  width: max-content;
`;

export const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 0 20px;
  padding: 1px 5px;
  margin-top: 10px;
  border-radius: 10px;
  &:hover {
    opacity: 0.5;
  }
`;

export const Item2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 0 20px;
  padding: 1px 5px;
  margin-top: 10px;
  border-radius: 10px;
  &:hover {
    opacity: 0.5;
  }
`;

export const ImgWrapper = styled.div`
  border-radius: 10px;
  overflow: hidden;
  background-image: linear-gradient(90deg, #21d397 0, #7450fe);
  width: 35px;
  height: 35px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const ContentWrapper = styled.div`
  margin-left: 5px;
  margin-top: -3px;
  span {
    font-size: 12px;
    opacity: 0.7;
    height: 12px;
  }
  p {
    font-weight: 700;
    font-size: 13px;
    margin: 0;
  }
`;
