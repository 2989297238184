/* eslint-disable max-len */
import {
  Section,
  ItemContent,
  MainEstock,
  VideoYT,
  MainLogo,
  ContainerFirstHome,
  Title,
  Description,
  Fundamentals,
  Project,
  // ItemDoc,
  PartnerContainer,
  Partners,
  ItemPartner,
  ImagePartners,
  OutTeam,
  Avta,
  Flex,
  Upload,
  ContentPreview,
  UpLinkVideoYoutube,
  FundamentalsImage,
  ContainerImage,
  Certificate,
  ContainerCerIcon,
  ContainerImageCerBottom,
  LogoCer,
  InfoProject,
  ConfimationProject,
  UploadCer,
  // Document,
  AddOrRemoveChil,
  OurTeamContainer,
  Certification,
  ImageCer,
  MyIDO,
  Video,
  ListIconsOutTeam,
  // BackGroundDoc,
  // IconDoc,
  // LinkDoc,
  AddSectionTeam,
  MovePosition,
  Banner,
  // BackgroundBanner,
  PlayProjectIntro,
  BackgroundBanner,
  // AddBanner
} from "pages/home/landing-page/styles";
import { TopMain } from "pages/home/styles";

import Countdown from "react-countdown";
import {
  Row,
  Col,
  Container,
  // Progress
} from "reactstrap";

import Footer from "layouts/components/footer";
import ProjectAdvertisement from "layouts/ProjectAdvertisement";
import UploadFile from "components/uploadFile";
import Table from "components/table";

import {
  BuyMore,
  CounterdownContent,
  CounterHeader,
  IcoCounter,
  // ProgressWrapper,
} from "pages/home/projects/project-detail/styles";
import Tokenomics from "pages/home/landing-page/Tokenomics";
import { useEffect, useState } from "react";
import {
  getBase64Image,
  formatCurrency,
  getLinkYoutubeIframe,
} from "utils/common";
import Input from "components/input";
import { AddLinkFooter } from "layouts/components/footer/styles";
import Button from "components/button";
import VParticles from "components/particles";
// import SliderCustom from "components/slider";
import CollapseField from "./CollapseField";
import DocumentsSection from "./DocumentSection";
import BannerPage from "components/banner_banner-ads";
import { isMobile } from "react-device-detect";

const CountDownElement = (props) => {
  return (
    <div className="countdown-section">
      <div className="countdown-item">
        <span className="countdown-item-value">{props.days}</span>
        <span className="countdown-item-label">Days</span>
      </div>
      <div className="countdown-item">
        <span className="countdown-item-value">{props.hours}</span>
        <span className="countdown-item-label">Hours</span>
      </div>
      <div className="countdown-item">
        <span className="countdown-item-value">{props.minutes}</span>
        <span className="countdown-item-label">Minutes</span>
      </div>
      <div className="countdown-item">
        <span className="countdown-item-value">{props.seconds}</span>
        <span className="countdown-item-label">Seconds</span>
      </div>
    </div>
  );
};

const Main = (props) => {
  const {
    user,
    color,
    isEdit,
    dataProject,
    dataUser,
    dataEstock,
    listFiles,
    docState,
    imgCerRef,
    certifications,
    partnerState,
    // ourTeamState,
    dataTeam,
    videoState,
    required,
    optionSection,
    advertisementBanner,
    setDataEstock,
    setAdvertisementBanner,
    setDocState,
    setFile,
    onChangeLinkCer,
    onBlurText,
    onBlurDocument,
    handleOpenPopup,
    handleViewImage,
    addChil,
    removeChil,
    removeLastChil,
    onClickLogoCer,
    handleAddSectionTeam,
    handleRemoveSectionTeam,
    handleClickVideo,
    handleMoveChil,
    checkManagersSection,
  } = props;

  const columnTableProfitTotal = [
    {
      title: "Stt",
      dataIndex: "stt",
      key: "stt",
      index: "index",
    },
    {
      title: "Standard level",
      dataIndex: "standardlevel",
      key: "standardlevel",
      render: (data: any) => (
        <span>
          {data?.key ||
            `${formatCurrency(data?.from)} -> ${formatCurrency(data?.to)} (${
              dataProject?.symbol
            })`}
        </span>
      ),
    },
    {
      title: "Profit",
      dataIndex: "profit",
      key: "profit",
      render: (data: any) => <span>{Number(data?.percent)}%</span>,
    },
    // {
    //   title: 'Note',
    //   dataIndex: 'note',
    //   key: 'note',
    //   render: (data: any) => <span>{data?.note}</span>
    // },
  ];

  const [dataTableProfit, setDataTableProfit] = useState<any>(null);
  const [imagesCer, setImagesCer] = useState<any>([]);

  const linkBanner = dataEstock?.banner?.link;
  const isSlide =
    dataEstock?.banner?.slide && dataEstock?.banner?.slide.length > 0;

  const openTagWindow = (url: string) => {
    if (!isEdit) {
      window.open(url);
    }
  };

  const countdow_render = ({ days, hours, minutes, seconds }) => {
    return (
      <CountDownElement
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  };

  useEffect(() => {
    const totalProfitShare =
      dataProject?.sharedProfit?.totalProfitSharingSetting;
    const arrDataProfit = totalProfitShare?.shareholder?.option;
    if (arrDataProfit) {
      setDataTableProfit(arrDataProfit);
    }
  }, [dataProject]);

  useEffect(() => {
    const getImages = async () => {
      if (dataEstock) {
        const {
          certificateLogo,
          certificateImg1,
          certificateImg2,
          certificateImg3,
        } = dataEstock;
        const res = await Promise.all(
          [
            certificateLogo,
            certificateImg1,
            certificateImg2,
            certificateImg3,
          ].map(async (i, index) => await getBase64Image(i))
        );
        setImagesCer(res);
      }
    };
    getImages();
  }, [dataEstock]);

  const renderSectionFirts = () => {
    return (
      <>
        <Section
          // bgColor={(linkBanner || isSlide) && color.bgColor1}
          // bgColorDefault={(linkBanner || isSlide) && "bgColorLayer1"}
          textColor={color.textColor}
          isDisable={checkManagersSection("header")}
        >
          <Container>
            <ContainerFirstHome pt={linkBanner || isSlide ? "30px" : "0px"}>
              <MainEstock
                isEdit={true}
                isPadding={
                  !Boolean(
                    !isEdit &&
                      (dataProject?.status === "opening" ||
                        dataProject?.status === "upcoming")
                  )
                }
              >
                <Row>
                  <Col md={7}>
                    <ItemContent>
                      <VideoYT
                        isEdit={isEdit}
                        onClick={(e) => {
                          handleClickVideo(
                            getLinkYoutubeIframe(dataEstock?.linkIntro, true),
                            "intro"
                          );
                          e.stopPropagation();
                        }}
                      >
                        {isEdit && (
                          <UpLinkVideoYoutube>
                            Click here to up your link video
                          </UpLinkVideoYoutube>
                        )}
                        {dataEstock?.linkIntro && (
                          <iframe
                            key={dataEstock?.linkIntro}
                            frameBorder="0"
                            allow="accelerometer"
                            allowFullScreen={true}
                            title="YouTube video player"
                            // eslint-disable-next-line max-len
                            src={getLinkYoutubeIframe(dataEstock?.linkIntro)}
                          ></iframe>
                        )}
                      </VideoYT>
                    </ItemContent>
                  </Col>
                  <Col md={5}>
                    <ItemContent>
                      <MainLogo
                        onClick={(e) => {
                          e.stopPropagation();
                          handleViewImage(dataEstock?.logoToken);
                        }}
                      >
                        {isEdit && (
                          <Upload
                            isHidden={
                              Boolean(!listFiles["logoToken"]) &&
                              dataEstock?.logoToken
                            }
                          >
                            <UploadFile
                              id="logoToken"
                              ratio="340x340"
                              file={listFiles["logoToken"]}
                              setFile={setFile}
                            />
                          </Upload>
                        )}
                        <img
                          hidden={listFiles["logoToken"]}
                          src={dataEstock?.logoToken}
                          alt=""
                        />
                      </MainLogo>
                    </ItemContent>
                  </Col>
                </Row>
              </MainEstock>
            </ContainerFirstHome>
          </Container>
        </Section>

        {!isEdit &&
        (dataProject?.status === "opening" ||
          dataProject?.status === "upcoming") ? (
          <Section
            // bgColor={(linkBanner || isSlide) && color.bgColor1}
            // bgColorDefault={(linkBanner || isSlide) && "bgColorLayer1"}
            textColor={color.textColor}
          >
            <MyIDO>
              <IcoCounter>
                <CounterHeader>
                  {dataProject?.status === "opening" && (
                    <>
                      <h3>STOCKS SALE ENDS IN</h3>
                      <CounterdownContent>
                        <Countdown
                          date={new Date(
                            dataProject?.keep_time.ido_close
                          ).getTime()}
                          renderer={countdow_render}
                        />
                      </CounterdownContent>
                    </>
                  )}
                  {dataProject?.status === "upcoming" && (
                    <>
                      <h3>STOCKS WILL BE SOLD LATE</h3>
                      <CounterdownContent>
                        <Countdown
                          date={new Date(
                            dataProject?.keep_time.ido_open
                          ).getTime()}
                          renderer={countdow_render}
                        />
                      </CounterdownContent>
                    </>
                  )}
                </CounterHeader>
                <BuyMore>
                  <a href="/my/ico">Buy More stocks</a>
                </BuyMore>
              </IcoCounter>
            </MyIDO>
          </Section>
        ) : null}
      </>
    );
  };

  return (
    <>
      <Banner
        bg={Boolean(!linkBanner && !isSlide)}
        zIndex={Boolean(!linkBanner && !isSlide) ? 0 : 2}
        minHeight={
          (advertisementBanner?.left?.length > 0 ||
            advertisementBanner?.right?.length > 0) &&
          "35vw"
        }
      >
        <PlayProjectIntro>
          <TopMain>
            <ProjectAdvertisement />
          </TopMain>
        </PlayProjectIntro>
        {!linkBanner && !isSlide && <VParticles />}
        {
          <BackgroundBanner isVideo={Boolean(linkBanner)}>
            <BannerPage
              page={"landing_page"}
              isEdit={isEdit}
              user={user}
              dataPage={dataEstock}
              advertisementBanner={advertisementBanner}
              setDataPage={setDataEstock}
              setAdvertisementBanner={setAdvertisementBanner}
            />
          </BackgroundBanner>
        }
        {!linkBanner && !isSlide && renderSectionFirts()}
      </Banner>
      {(linkBanner || isSlide) && (
        <Banner bg={Boolean(linkBanner || isSlide)} zIndex={1}>
          {renderSectionFirts()}
          <VParticles />
        </Banner>
      )}
      {optionSection.profit.isShow && (
        <Section
          id="shareProfit"
          bgColor={color.bgColor2}
          bgColorDefault={"blue300"}
          textColor={color.textColor}
          isDisable={checkManagersSection("profit")}
        >
          <Container>
            <Title>
              <h3
                data-key={"profitTitle"}
                contentEditable={isEdit}
                onBlur={onBlurText}
              >
                {dataEstock?.["profitTitle"] || "Project Profit Distribution"}
              </h3>
              <hr />
            </Title>
            <br />
            <Table
              bgTable={"bgColorLayer1"}
              columns={columnTableProfitTotal}
              dataSource={dataTableProfit}
              isEdit={isEdit}
              dataEdit={dataEstock}
              onBlurText={onBlurText}
            />
          </Container>
        </Section>
      )}
      {optionSection.fundamentals.isShow && (
        <Section
          bgColor={color.bgColor1}
          bgColorDefault={"bgColorLayer1"}
          textColor={color.textColor}
          isDisable={checkManagersSection("fundamentals")}
        >
          <Container>
            <Title>
              <h3
                data-key={"firstTitle"}
                contentEditable={isEdit}
                onBlur={onBlurText}
              >
                {dataEstock?.["firstTitle"] ||
                  `${(
                    dataEstock?.symbol ||
                    dataProject?.symbol ||
                    ""
                  ).toUpperCase()} fundamentals`}
              </h3>
              <hr />
              <Description
                data-key={"fundamentals_desc"}
                contentEditable={isEdit}
                onBlur={onBlurText}
              >
                {dataEstock?.fundamentals_desc}
              </Description>
            </Title>
            <Row>
              <Col md={4}>
                <ItemContent>
                  <Fundamentals>
                    <h6
                      data-key={"fundamentals_tt1"}
                      contentEditable={isEdit}
                      onBlur={onBlurText}
                    >
                      <img src="/assets/images/icons/mark.svg" alt="" />{" "}
                      {dataEstock?.fundamentals_tt1}
                    </h6>
                    <p
                      data-key={"fundamentals_de1"}
                      contentEditable={isEdit}
                      onBlur={onBlurText}
                    >
                      {dataEstock?.fundamentals_de1}
                    </p>
                  </Fundamentals>
                  <Fundamentals>
                    <h6
                      data-key={"fundamentals_tt2"}
                      contentEditable={isEdit}
                      onBlur={onBlurText}
                    >
                      <img src="/assets/images/icons/mark.svg" alt="" />{" "}
                      {dataEstock?.fundamentals_tt2}
                    </h6>
                    <p
                      data-key={"fundamentals_de2"}
                      contentEditable={isEdit}
                      onBlur={onBlurText}
                    >
                      {dataEstock?.fundamentals_de2}
                    </p>
                  </Fundamentals>
                  <Fundamentals>
                    <h6
                      data-key={"fundamentals_tt3"}
                      contentEditable={isEdit}
                      onBlur={onBlurText}
                    >
                      <img src="/assets/images/icons/mark.svg" alt="" />{" "}
                      {dataEstock?.fundamentals_tt3}
                    </h6>
                    <p
                      data-key={"fundamentals_de3"}
                      contentEditable={isEdit}
                      onBlur={onBlurText}
                    >
                      {dataEstock?.fundamentals_de3}
                    </p>
                  </Fundamentals>
                </ItemContent>
              </Col>
              <Col md={4}>
                <ItemContent>
                  <FundamentalsImage>
                    {isEdit && (
                      <Upload
                        isHidden={
                          Boolean(!listFiles["fundamentalsImg"]) &&
                          dataEstock?.fundamentalsImg
                        }
                      >
                        <UploadFile
                          id="fundamentalsImg"
                          ratio="300x300"
                          file={listFiles["fundamentalsImg"]}
                          setFile={setFile}
                        />
                      </Upload>
                    )}
                    <img
                      onClick={() =>
                        handleViewImage(dataEstock?.fundamentalsImg)
                      }
                      hidden={listFiles["fundamentalsImg"]}
                      src={dataEstock?.fundamentalsImg}
                      alt=""
                    />
                  </FundamentalsImage>
                </ItemContent>
              </Col>
              <Col md={4}>
                <ItemContent>
                  <Fundamentals>
                    <h6
                      data-key={"fundamentals_tt4"}
                      contentEditable={isEdit}
                      onBlur={onBlurText}
                    >
                      <img src="/assets/images/icons/mark.svg" alt="" />{" "}
                      {dataEstock?.fundamentals_tt4}
                    </h6>
                    <p
                      data-key={"fundamentals_de4"}
                      contentEditable={isEdit}
                      onBlur={onBlurText}
                    >
                      {dataEstock?.fundamentals_de4}
                    </p>
                  </Fundamentals>
                  <Fundamentals>
                    <h6
                      data-key={"fundamentals_tt5"}
                      contentEditable={isEdit}
                      onBlur={onBlurText}
                    >
                      <img src="/assets/images/icons/mark.svg" alt="" />{" "}
                      {dataEstock?.fundamentals_tt5}
                    </h6>
                    <p
                      data-key={"fundamentals_de5"}
                      contentEditable={isEdit}
                      onBlur={onBlurText}
                    >
                      {dataEstock?.fundamentals_de5}
                    </p>
                  </Fundamentals>
                  <Fundamentals>
                    <h6
                      data-key={"fundamentals_tt6"}
                      contentEditable={isEdit}
                      onBlur={onBlurText}
                    >
                      <img src="/assets/images/icons/mark.svg" alt="" />{" "}
                      {dataEstock?.fundamentals_tt6}
                    </h6>
                    <p
                      data-key={"fundamentals_de6"}
                      contentEditable={isEdit}
                      onBlur={onBlurText}
                    >
                      {dataEstock?.fundamentals_de6}
                    </p>
                  </Fundamentals>
                </ItemContent>
              </Col>
            </Row>
          </Container>
        </Section>
      )}
      {optionSection.roadmap.isShow && (
        <Section
          id="roadmap"
          bgColor={color.bgColor2}
          bgColorDefault={"blue300"}
          textColor={color.textColor}
          isDisable={checkManagersSection("roadmap")}
        >
          <Container>
            <Title>
              <h3
                data-key={"secondsTitle"}
                contentEditable={isEdit}
                onBlur={onBlurText}
              >
                {dataEstock?.["secondsTitle"] ||
                  `${(
                    dataEstock?.symbol ||
                    dataProject?.symbol ||
                    ""
                  ).toUpperCase()} Roadmap`}
              </h3>
              <hr />
              <h5
                data-key={"roadmap_title"}
                contentEditable={isEdit}
                onBlur={onBlurText}
              >
                {dataEstock?.roadmap_title}
              </h5>
              <Description
                data-key={"roadmap_desc"}
                contentEditable={isEdit}
                onBlur={onBlurText}
              >
                {dataEstock?.roadmap_desc}
              </Description>
            </Title>
            <ContentPreview>
              {isEdit && (
                <Upload
                  isHidden={
                    Boolean(!listFiles["roadmapImg"]) && dataEstock?.roadmapImg
                  }
                >
                  <UploadFile
                    id="roadmapImg"
                    ratio="1296x600"
                    file={listFiles["roadmapImg"]}
                    setFile={setFile}
                  />
                </Upload>
              )}
              <img
                hidden={listFiles["roadmapImg"]}
                src={dataEstock?.roadmapImg}
                alt=""
                onClick={() => handleViewImage(dataEstock?.roadmapImg)}
              />
            </ContentPreview>
          </Container>
        </Section>
      )}

      {dataProject?.tokenomics?.length > 0 &&
        optionSection.tokenomics.isShow && (
          <Section
            id="tokenomic"
            bgColor={color.bgColor1}
            bgColorDefault={"bgColorLayer1"}
            textColor={color.textColor}
            isDisable={checkManagersSection("project")}
          >
            <Title>
              <h3
                data-key={"tokenomics_title"}
                contentEditable={isEdit}
                onBlur={onBlurText}
              >
                {dataEstock?.tokenomics_title || "Tokenomics"}
              </h3>
              <hr />
            </Title>
            <Tokenomics tokenomics={dataProject?.tokenomics} />
          </Section>
        )}
      {optionSection.doc.isShow && (
        <DocumentsSection
          docState={docState}
          data_key={"fourTitle"}
          title={dataEstock?.["fourTitle"] || "DOCUMENTS"}
          isEdit={isEdit}
          isDisable={checkManagersSection("doc")}
          color={{
            textColor: color.textColor,
            bgColor: color.bgColor2,
            bgDefault: "blue300",
            hoverColor: color.hoverColor,
          }}
          setDocState={setDocState}
          onBlurText={onBlurText}
        />
      )}

      {/* <Section
        bgColor={color.bgColor2}
        bgColorDefault={"blue300"}
        textColor={color.textColor}
      >
        <Container>
          {isEdit && (
            <AddOrRemoveChil>
              <div onClick={() => addChil("doc")}>+</div>
              <div onClick={() => removeLastChil("doc")}>-</div>
            </AddOrRemoveChil>
          )}
          <Title>
            <h3
              data-key={"fourTitle"}
              contentEditable={isEdit}
              onBlur={onBlurText}
            >
              {dataEstock?.["fourTitle"] || "DOCUMENTS"}
            </h3>
            <hr />
          </Title>
          <br />
          <Document>
            <Row>
              {docState.length > 0 &&
                docState.map((el: any, index: any) => (
                  <Col md={4} key={el.id}>
                    <ItemContent>
                      {isEdit && <>
                        <AddOrRemoveChil>
                          <div onClick={() => removeChil("doc", el.id)}>-</div>
                        </AddOrRemoveChil>
                        <MovePosition>
                          {
                            index !== 0 && <Button onClick={() => handleMoveChil(index, 'doc', 'upward')}>{'<'}</Button>
                          }
                          {
                            index !== docState.length - 1 && <Button onClick={() => handleMoveChil(index, 'doc', 'downward')}>{'>'}</Button>
                          }
                        </MovePosition>
                      </>}

                      <Project pd="0px" mb="0px">
                        <ItemDoc onClick={() => (!isEdit ? openTagWindow(el?.link) : "")}>
                          {isEdit && (
                            <BackGroundDoc zIndex={'9'}>
                              <UploadFile
                                id={el.id}
                                sectionName="doc"
                                ratio="420x270"
                                file={el.file}
                                setFile={setFile}
                              />
                            </BackGroundDoc>
                          )}
                          <BackGroundDoc zIndex={'0'}>
                            <img
                              hidden={Boolean(el.file)}
                              src={el.src}
                              alt=""
                            />
                          </BackGroundDoc>
                          <IconDoc>
                            <img src="/assets/images/icons/exchange.svg" alt="" />
                          </IconDoc>
                          <h5
                            contentEditable={isEdit}
                            onBlur={(e) =>
                              onBlurDocument(e, index, "title", "doc")
                            }
                          >
                            {el?.title}
                          </h5>
                          <p
                            contentEditable={isEdit}
                            onBlur={(e) =>
                              onBlurDocument(e, index, "decs", "doc")
                            }
                          >
                            {el?.decs}
                          </p>
                          {isEdit && (
                            <LinkDoc>
                              Link:{" "}
                              <span
                                contentEditable={isEdit}
                                onBlur={(e) =>
                                  onBlurDocument(e, index, "link", "doc")
                                }
                              >
                                {el?.link || "Add link here"}
                              </span>
                            </LinkDoc>
                          )}
                        </ItemDoc>
                      </Project>
                    </ItemContent>
                  </Col>
                ))}
            </Row>
          </Document>
        </Container>
      </Section> */}
      {optionSection.certificate.isShow && (
        <Section
          id="certificate"
          bgColor={color.bgColor1}
          bgColorDefault={"bgColorLayer1"}
          textColor={color.textColor}
          isDisable={checkManagersSection("certificate")}
        >
          <Container>
            {isEdit && (
              <AddOrRemoveChil>
                <div onClick={() => addChil("certificates")}>+</div>
                <div onClick={() => removeLastChil("certificates")}>-</div>
              </AddOrRemoveChil>
            )}
            <Title>
              <h3
                data-key={"fiveTitle"}
                contentEditable={isEdit}
                onBlur={onBlurText}
              >
                {dataEstock?.["fiveTitle"] || "Project certificate"}
              </h3>
              <hr />
            </Title>
            <Certification>
              <Certificate
                ref={imgCerRef}
                onClick={() => handleViewImage("", "certification")}
              >
                <img src="/assets/images/certificate.png" alt="" />
                <ContainerCerIcon>
                  <LogoCer
                    onClick={(e) =>
                      onClickLogoCer(e, dataEstock?.linkCertificateLogo || "")
                    }
                  >
                    {isEdit && (
                      <UploadCer
                        isHidden={
                          Boolean(!listFiles["certificateLogo"]) &&
                          dataEstock?.certificateLogo
                        }
                      >
                        <UploadFile
                          id="certificateLogo"
                          file={listFiles["certificateLogo"]}
                          setFile={setFile}
                        />
                      </UploadCer>
                    )}
                    <img
                      hidden={listFiles["certificateLogo"]}
                      src={imagesCer[0] || dataEstock?.certificateLogo}
                      alt=""
                    />
                    {isEdit &&
                      !listFiles["certificateLogo"] &&
                      !dataEstock?.certificateLogo && <p>Logo (256*200)</p>}
                  </LogoCer>
                  {isEdit && (
                    <Input
                      transparent={true}
                      color={"orange"}
                      defaultValue={dataEstock?.linkCertificateLogo}
                      onChange={(e) =>
                        onChangeLinkCer(e, "linkCertificateLogo")
                      }
                      placeholder="Link"
                    />
                  )}
                </ContainerCerIcon>
                <InfoProject>
                  <div
                    data-key={"nameProject"}
                    contentEditable={isEdit}
                    onBlur={onBlurText}
                  >
                    {dataEstock?.nameProject ||
                      dataEstock?.name ||
                      dataProject?.name}
                  </div>
                  <div
                    data-key={"ownerProject"}
                    contentEditable={isEdit}
                    onBlur={onBlurText}
                  >
                    {dataEstock?.ownerProject ||
                      dataUser?.organizationsData?.representative ||
                      dataUser?.businessData?.business_owner ||
                      dataUser?.fullname}
                  </div>
                  <div
                    data-key={"addressProject"}
                    contentEditable={isEdit}
                    onBlur={onBlurText}
                  >
                    {dataEstock?.addressProject || dataUser?.address}
                  </div>
                </InfoProject>
                <ConfimationProject>
                  <ContainerImageCerBottom>
                    <ImageCer
                      onClick={(e) =>
                        onClickLogoCer(e, dataEstock?.linkCertificateImg1 || "")
                      }
                    >
                      {isEdit && (
                        <UploadCer
                          isHidden={
                            Boolean(!listFiles["certificateImg1"]) &&
                            dataEstock?.certificateImg1
                          }
                        >
                          <UploadFile
                            id="certificateImg1"
                            file={listFiles["certificateImg1"]}
                            setFile={setFile}
                          />
                        </UploadCer>
                      )}
                      <img
                        hidden={listFiles["certificateImg1"]}
                        src={imagesCer[1] || dataEstock?.certificateImg1}
                        alt=""
                      />
                      {isEdit &&
                        !listFiles["certificateImg1"] &&
                        !dataEstock?.certificateImg1 && (
                          <p>Project Legal Confirmation (437*437)</p>
                        )}
                    </ImageCer>
                    {isEdit && (
                      <Input
                        transparent={true}
                        color={"orange"}
                        defaultValue={dataEstock?.linkCertificateImg1}
                        onChange={(e) =>
                          onChangeLinkCer(e, "linkCertificateImg1")
                        }
                        placeholder="Link"
                      />
                    )}
                  </ContainerImageCerBottom>
                  <ContainerImageCerBottom>
                    <ImageCer
                      onClick={(e) =>
                        onClickLogoCer(e, dataEstock?.linkCertificateImg2 || "")
                      }
                    >
                      {isEdit && (
                        <UploadCer
                          isHidden={
                            Boolean(!listFiles["certificateImg2"]) &&
                            dataEstock?.certificateImg2
                          }
                        >
                          <UploadFile
                            id="certificateImg2"
                            file={listFiles["certificateImg2"]}
                            setFile={setFile}
                          />
                        </UploadCer>
                      )}
                      <img
                        hidden={listFiles["certificateImg2"]}
                        src={imagesCer[2] || dataEstock?.certificateImg2}
                        alt=""
                      />
                      {isEdit &&
                        !listFiles["certificateImg2"] &&
                        !dataEstock?.certificateImg2 && (
                          <p>Project Legal Confirmation (437*437)</p>
                        )}
                    </ImageCer>
                    {isEdit && (
                      <Input
                        transparent={true}
                        color={"orange"}
                        defaultValue={dataEstock?.linkCertificateImg2}
                        onChange={(e) =>
                          onChangeLinkCer(e, "linkCertificateImg2")
                        }
                        placeholder="Link"
                      />
                    )}
                  </ContainerImageCerBottom>
                  <ContainerImageCerBottom>
                    <ImageCer
                      onClick={(e) =>
                        onClickLogoCer(e, dataEstock?.linkCertificateImg3 || "")
                      }
                    >
                      {isEdit && (
                        <UploadCer
                          isHidden={
                            Boolean(!listFiles["certificateImg3"]) &&
                            dataEstock?.certificateImg3
                          }
                        >
                          <UploadFile
                            id="certificateImg3"
                            file={listFiles["certificateImg3"]}
                            setFile={setFile}
                          />
                        </UploadCer>
                      )}
                      <img
                        hidden={listFiles["certificateImg3"]}
                        src={imagesCer[3] || dataEstock?.certificateImg3}
                        alt=""
                      />
                      {isEdit &&
                        !listFiles["certificateImg3"] &&
                        !dataEstock?.certificateImg3 && (
                          <p>Project Legal Confirmation (437*437)</p>
                        )}
                    </ImageCer>
                    {isEdit && (
                      <Input
                        transparent={true}
                        color={"orange"}
                        defaultValue={dataEstock?.linkCertificateImg3}
                        onChange={(e) =>
                          onChangeLinkCer(e, "linkCertificateImg3")
                        }
                        placeholder="Link"
                      />
                    )}
                  </ContainerImageCerBottom>
                </ConfimationProject>
              </Certificate>
              {/* LIST ADDITIONAL CERTS */}
              {certifications.length > 0 &&
                certifications.map((el: any, index: number) => (
                  <>
                    <br />
                    <ContentPreview maxWidth="900px">
                      {isEdit && (
                        <>
                          <AddOrRemoveChil>
                            <div
                              onClick={() => removeChil("certificates", el.id)}
                            >
                              -
                            </div>
                          </AddOrRemoveChil>
                          <MovePosition>
                            {index !== 0 && (
                              <Button
                                onClick={() =>
                                  handleMoveChil(
                                    index,
                                    "certificates",
                                    "upward"
                                  )
                                }
                              >
                                {"<"}
                              </Button>
                            )}
                            {index !== certifications.length - 1 && (
                              <Button
                                onClick={() =>
                                  handleMoveChil(
                                    index,
                                    "certificates",
                                    "downward"
                                  )
                                }
                              >
                                {">"}
                              </Button>
                            )}
                          </MovePosition>
                        </>
                      )}
                      {isEdit && (
                        <Upload isHidden={!Boolean(el.file) && el.src}>
                          <UploadFile
                            id={el.id}
                            sectionName="certificates"
                            // index={index}
                            requeried={required.certifications && !el.file}
                            ratio="600x600"
                            file={el.file}
                            setFile={setFile}
                          />
                        </Upload>
                      )}
                      <img
                        hidden={Boolean(el.file)}
                        src={el.src}
                        alt=""
                        onClick={() => handleViewImage(el.src)}
                      />
                    </ContentPreview>
                  </>
                ))}
            </Certification>
          </Container>
        </Section>
      )}
      {optionSection.partner.isShow && (
        <Section
          id="partner"
          bgColor={color.bgColor2}
          bgColorDefault={"blue300"}
          textColor={color.textColor}
          isDisable={checkManagersSection("partner")}
        >
          <Container>
            {isEdit && (
              <AddOrRemoveChil>
                <div onClick={() => addChil("partner")}>+</div>
                <div onClick={() => removeLastChil("partner")}>-</div>
              </AddOrRemoveChil>
            )}
            <Title>
              <h3
                data-key={"sixTitle"}
                contentEditable={isEdit}
                onBlur={onBlurText}
              >
                {dataEstock?.["sixTitle"] || "Our Partners"}
              </h3>
              <hr />
            </Title>
            <br />
            <PartnerContainer hoverColor={color?.hoverColor}>
              <Row>
                {partnerState.length > 0 &&
                  partnerState.map((el: any, index: number) => (
                    <Col xs={6} md={3} key={index} className="partner_item">
                      <ItemContent>
                        {isEdit && (
                          <>
                            <AddOrRemoveChil>
                              <div onClick={() => removeChil("partner", el.id)}>
                                -
                              </div>
                            </AddOrRemoveChil>
                            <MovePosition>
                              {index !== 0 && (
                                <Button
                                  onClick={() =>
                                    handleMoveChil(index, "partner", "upward")
                                  }
                                >
                                  {"<"}
                                </Button>
                              )}
                              {index !== partnerState.length - 1 && (
                                <Button
                                  onClick={() =>
                                    handleMoveChil(index, "partner", "downward")
                                  }
                                >
                                  {">"}
                                </Button>
                              )}
                            </MovePosition>
                          </>
                        )}
                        <Partners>
                          <ItemPartner>
                            <ImagePartners
                              onClick={() => openTagWindow(el.link)}
                            >
                              {isEdit && (
                                <Upload
                                  padding="10px"
                                  isHidden={!Boolean(el.file) && el.src}
                                >
                                  <UploadFile
                                    id={el.id}
                                    ratio="150x150"
                                    sectionName="partner"
                                    requeried={
                                      required.partnerState && !el.file
                                    }
                                    file={el.file}
                                    setFile={setFile}
                                  />
                                </Upload>
                              )}
                              <img
                                hidden={Boolean(el.file)}
                                src={el.src}
                                alt=""
                              />
                            </ImagePartners>
                          </ItemPartner>
                          {isEdit && (
                            <Input
                              transparent={true}
                              color={"orange"}
                              defaultValue={el?.link}
                              onBlur={(e) =>
                                onBlurDocument(e, index, "link", "partner")
                              }
                              placeholder="Link"
                            />
                          )}
                        </Partners>
                      </ItemContent>
                    </Col>
                  ))}
              </Row>
            </PartnerContainer>
          </Container>
        </Section>
      )}
      {optionSection.dataTeam.isShow && (
        <Section
          id="team"
          bgColor={color.bgColor1}
          bgColorDefault={"bgColorLayer1"}
          textColor={color.textColor}
          isDisable={checkManagersSection("dataTeam")}
        >
          {dataTeam.map((el: any, indexGlobal: number) => (
            <Container>
              {isEdit && (
                <AddOrRemoveChil>
                  <div onClick={() => addChil("ourTeam", indexGlobal)}>+</div>
                  <div onClick={() => removeLastChil("ourTeam", indexGlobal)}>
                    -
                  </div>
                </AddOrRemoveChil>
              )}
              <Title>
                <h3
                  contentEditable={isEdit}
                  onBlur={(e) =>
                    onBlurDocument(e, indexGlobal, "title", "ourteam")
                  }
                >
                  {el?.title || "Our team"}
                </h3>
                <hr />
                <Description
                  contentEditable={isEdit}
                  onBlur={(e) =>
                    onBlurDocument(e, indexGlobal, "team_desc", "ourteam")
                  }
                >
                  {el?.team_desc}
                </Description>
              </Title>
              <br />
              <OurTeamContainer
                noneBorder={dataTeam.length - 1 === indexGlobal}
              >
                <Flex hoverColor={color.hoverColor}>
                  {el.ourTeam.length > 0 &&
                    el.ourTeam
                      .filter((item: any) => (isMobile ? item?.name : true))
                      .map((item: any, index: number) => (
                        <Col
                          xs={index === 0 ? 12 : 6}
                          md={4}
                          key={index}
                          className="our-team_item"
                        >
                          <ItemContent>
                            {isEdit && (
                              <>
                                <AddOrRemoveChil>
                                  <div
                                    onClick={() =>
                                      removeChil(
                                        "ourTeam",
                                        item.id,
                                        indexGlobal
                                      )
                                    }
                                  >
                                    -
                                  </div>
                                </AddOrRemoveChil>
                                <MovePosition>
                                  {index !== 0 && (
                                    <Button
                                      onClick={() =>
                                        handleMoveChil(
                                          index,
                                          "ourTeam",
                                          "upward",
                                          indexGlobal
                                        )
                                      }
                                    >
                                      {"<"}
                                    </Button>
                                  )}
                                  {index !== el.ourTeam.length - 1 && (
                                    <Button
                                      onClick={() =>
                                        handleMoveChil(
                                          index,
                                          "ourTeam",
                                          "downward",
                                          indexGlobal
                                        )
                                      }
                                    >
                                      {">"}
                                    </Button>
                                  )}
                                </MovePosition>
                              </>
                            )}
                            <OutTeam>
                              <Avta>
                                <ContainerImage>
                                  {isEdit && (
                                    <Upload
                                      isHidden={!Boolean(item.file) && item.src}
                                    >
                                      <UploadFile
                                        id={item.id}
                                        ratio="150x150"
                                        index={indexGlobal}
                                        sectionName="ourTeam"
                                        file={item.file}
                                        setFile={setFile}
                                      />
                                    </Upload>
                                  )}
                                  <img
                                    hidden={item.file}
                                    src={item.src}
                                    alt=""
                                    onClick={() => handleViewImage(item.src)}
                                  />
                                </ContainerImage>
                              </Avta>
                              <h3
                                contentEditable={isEdit}
                                onBlur={(e) =>
                                  onBlurDocument(
                                    e,
                                    indexGlobal,
                                    "name",
                                    "ourteam",
                                    item.id
                                  )
                                }
                              >
                                {item?.name}
                              </h3>
                              <h4
                                contentEditable={isEdit}
                                onBlur={(e) =>
                                  onBlurDocument(
                                    e,
                                    indexGlobal,
                                    "role",
                                    "ourteam",
                                    item.id
                                  )
                                }
                              >
                                {item?.role}
                              </h4>
                              <p
                                contentEditable={isEdit}
                                onBlur={(e) =>
                                  onBlurDocument(
                                    e,
                                    indexGlobal,
                                    "desc",
                                    "ourteam",
                                    item.id
                                  )
                                }
                              >
                                {item?.desc}
                              </p>
                              <ListIconsOutTeam>
                                <img
                                  hidden={!item?.link?.vdiarybook}
                                  src="/assets/images/VDB-64.png"
                                  alt=""
                                  onClick={() =>
                                    openTagWindow(item?.link?.vdiarybook)
                                  }
                                />
                                <img
                                  hidden={!item?.link?.facebook}
                                  src="/assets/images/facebook.png"
                                  alt=""
                                  onClick={() =>
                                    openTagWindow(item?.link?.facebook)
                                  }
                                />
                                <img
                                  hidden={!item?.link?.youtube}
                                  src="/assets/images/youtube.png"
                                  alt=""
                                  onClick={() =>
                                    openTagWindow(item?.link?.youtube)
                                  }
                                />
                                <img
                                  hidden={!item?.link?.twitter}
                                  src="/assets/images/twitter.png"
                                  alt=""
                                  onClick={() =>
                                    openTagWindow(item?.link?.twitter)
                                  }
                                />
                                <img
                                  hidden={!item?.link?.telegram}
                                  src="/assets/images/telegram.png"
                                  alt=""
                                  onClick={() =>
                                    openTagWindow(item?.link?.telegram)
                                  }
                                />
                              </ListIconsOutTeam>
                              {isEdit && (
                                <AddLinkFooter
                                  className="text-center"
                                  mt="10px"
                                  onClick={() =>
                                    handleOpenPopup(
                                      "linkouteam",
                                      index,
                                      indexGlobal
                                    )
                                  }
                                >
                                  Click here to add link
                                </AddLinkFooter>
                              )}
                            </OutTeam>
                          </ItemContent>
                        </Col>
                      ))}
                </Flex>
              </OurTeamContainer>
            </Container>
          ))}
          {isEdit && (
            <AddSectionTeam>
              <Button onClick={handleAddSectionTeam}>+ Add</Button>
              <Button onClick={handleRemoveSectionTeam}>- Remove</Button>
            </AddSectionTeam>
          )}
        </Section>
      )}
      {optionSection.video.isShow && (
        <Section
          id="video"
          bgColor={color.bgColor2}
          bgColorDefault={"blue300"}
          textColor={color.textColor}
          isDisable={checkManagersSection("video")}
        >
          <Container>
            {isEdit && (
              <AddOrRemoveChil>
                <div onClick={() => addChil("video")}>+</div>
                <div onClick={() => removeLastChil("video")}>-</div>
              </AddOrRemoveChil>
            )}
            <Title>
              <h3
                data-key={"eightTitle"}
                contentEditable={isEdit}
                onBlur={onBlurText}
              >
                {dataEstock?.["eightTitle"] || "Video"}
              </h3>
              <hr />
            </Title>
            <br />
            <Video>
              <Row>
                {videoState?.length > 0 ? (
                  videoState.map((item: any, index: number) => (
                    <Col md={4}>
                      <ItemContent data-aos="flip-left" data-aos-duration="800">
                        {isEdit && (
                          <>
                            <AddOrRemoveChil>
                              <div onClick={() => removeChil("video", index)}>
                                -
                              </div>
                            </AddOrRemoveChil>
                            <MovePosition>
                              {index !== 0 && (
                                <Button
                                  onClick={() =>
                                    handleMoveChil(index, "video", "upward")
                                  }
                                >
                                  {"<"}
                                </Button>
                              )}
                              {index !== videoState.length - 1 && (
                                <Button
                                  onClick={() =>
                                    handleMoveChil(index, "video", "downward")
                                  }
                                >
                                  {">"}
                                </Button>
                              )}
                            </MovePosition>
                          </>
                        )}
                        <Project isVideo={true}>
                          <VideoYT
                            isEdit={isEdit}
                            onClick={() =>
                              handleClickVideo(
                                getLinkYoutubeIframe(item.link, true),
                                "video",
                                index
                              )
                            }
                          >
                            {isEdit && (
                              <UpLinkVideoYoutube>
                                Click here to up your link video
                              </UpLinkVideoYoutube>
                            )}
                            {item.link && (
                              <iframe
                                key={item.link}
                                frameBorder="0"
                                allow="accelerometer"
                                allowFullScreen={true}
                                title="YouTube video player"
                                // eslint-disable-next-line max-len
                                src={getLinkYoutubeIframe(item.link)}
                              />
                            )}
                          </VideoYT>
                        </Project>
                      </ItemContent>
                    </Col>
                  ))
                ) : (
                  <p className="text-center">No video</p>
                )}
              </Row>
            </Video>
          </Container>
        </Section>
      )}
      {dataEstock?.commentsField?.length > 0 && optionSection.review.isShow && (
        <Section
          id="support"
          bgColor={color.bgColor1}
          bgColorDefault={"bgColorLayer1"}
          textColor={color.textColor}
        >
          <Container>
            <Title>
              <h3>{"Review"}</h3>
              <hr />
            </Title>
            <CollapseField
              type="estock"
              supports={dataEstock?.commentsField}
              symbol={dataProject?.symbol}
              color={color}
            />
          </Container>
        </Section>
      )}
      <Section
        bgColor={
          dataEstock?.commentsField?.length > 0
            ? color.bgColor2
            : color.bgColor1
        }
        bgColorDefault={
          dataEstock?.commentsField?.length > 0 ? "blue300" : "bgColorLayer1"
        }
        textColor={color.textColor}
        isDisable={checkManagersSection("footer")}
      >
        <Footer
          isEdit={isEdit}
          dataUser={dataUser}
          dataProject={dataProject}
          dataEstock={dataEstock}
          listFiles={listFiles["logoFooter"]}
          setFile={setFile}
          onBlurText={onBlurText}
          handleOpenPopup={handleOpenPopup}
        />
      </Section>
    </>
  );
};

export default Main;
