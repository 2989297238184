import Button from "components/button";
import {
  HistotyProfit,
  PaginationHistoty,
} from "../styles";

import TableProfit from "../tableShareProfit";

const View = (props: any) => {
  const {
    dataHistory,
    indexHistory,
    showHistotyTable,
  } = props
  return <>
    {
      dataHistory && (
        <HistotyProfit>
          History profit sharing ({dataHistory.length})
          <PaginationHistoty>
            {dataHistory?.map((el: any, index: number) => (
              <Button
                color={indexHistory === index ? "green100" : "orange100"}
                onClick={() => showHistotyTable(index)}
                height={"30px"}
              >
                {new Date(
                  el?.transactions[0]?.transactionId?.createdAt
                ).toLocaleString("en-GB")}
              </Button>
            ))}
          </PaginationHistoty>
          <TableProfit
            total={
              dataHistory[indexHistory]?.totalProfitSharingSetting?.total
            }
            dataSource={dataHistory[indexHistory]?.transactions}
          />
        </HistotyProfit>
      )
    }
  </>
}

export default View;