/* eslint-disable react-hooks/exhaustive-deps */
import {
  Flex,
  FieldTokenomics,
  Tokenomics,
  AddField,
  ErrorTk,
  HoldingSwitch,
} from "../../styles";

import { Controller } from "react-hook-form";
import Switch from "react-switch";

import { getCryptos } from "redux/reducers/cryptos/cryptos.selector";
import { useAppSelector } from "redux/reducers/hook";

import Input from "components/input";
import Dropdown from "components/dropdown";
import { TitleProjectCategory } from "pages/create-landing-page/styles";
import { useEffect, useState } from "react";

import { listProjectCategory } from "config/constant";

const FormProjectICO = (props: any) => {
  const cryptos = useAppSelector(getCryptos);

  const {
    active,
    isDisable,
    isShowDetail,
    control,
    errors,
    dataDetail,
    errorField,
    fieldTokenomics,
    setFieldTokenomics,
    dataICO,
    setDataICO,
    setDataDetail,
  } = props;

  const [typeProjectCategory, setTypeProjectCategory] =
    useState<string>("dropdown");

  const hanldleTypeProjectCategory = (type: string) => {
    if (!isDisable) {
      setTypeProjectCategory(type);
    }
  };

  const onChangeProjectCategory = (value) => {
    setDataDetail((prev) => ({
      ...prev,
      projectCategory: value.label,
    }));
  };

  const onChangeAmount = (e: any, key: string) => {
    setDataDetail((prev) => ({
      ...prev,
      [key]: e,
    }));
  };

  const addFieldTokenomics = () => {
    setFieldTokenomics(
      fieldTokenomics.concat({
        name: "",
        percent: "",
      })
    );
  };

  const removeField = () => {
    const newArr = fieldTokenomics.slice(0, fieldTokenomics.length - 1);
    setFieldTokenomics(newArr);
  };

  const onChangeTokenomics = (key, type, e) => {
    const newArr: any = fieldTokenomics;
    newArr[key] = {
      ...fieldTokenomics[key],
      [type]: e.target.value,
    };
    setFieldTokenomics(newArr);
  };

  const onChangeStockName = (stock) => {
    setDataICO((prev) => ({
      ...prev,
      stock_to_buy: stock.value,
    }));
  };

  const handleChangeHold = async (checked) => {
    setDataDetail((prev) => ({
      ...prev,
      is_holding_balance: checked,
    }));
  };

  useEffect(() => {
    if (dataDetail) {
      if (
        listProjectCategory.find(
          (el) => el.value === dataDetail?.projectCategory
        )
      ) {
        setTypeProjectCategory("dropdown");
      } else {
        setTypeProjectCategory("input");
      }
    }
  }, []);

  return (
    <>
      {typeProjectCategory === "dropdown" ? (
        <Dropdown
          transparent={true}
          color={"white100"}
          width={"100%"}
          hasError={errorField.projectCategory}
          disabled={isDisable}
          label={
            <TitleProjectCategory>
              Project category: &ensp;
              <span onClick={() => hanldleTypeProjectCategory("input")}>
                Others
              </span>
            </TitleProjectCategory>
          }
          options={listProjectCategory?.map((el: any) => {
            return {
              label: el?.label,
              value: el?.value,
            };
          })}
          defaultValue={
            dataDetail?.projectCategory || "Choose the project category"
          }
          onChange={onChangeProjectCategory}
        />
      ) : (
        <Controller
          name="projectCategory"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Input
              transparent={true}
              hasError={Boolean(errors.projectCategory)}
              // eslint-disable-next-line max-len
              label={
                <TitleProjectCategory>
                  Project category: &ensp;
                  <span onClick={() => hanldleTypeProjectCategory("dropdown")}>
                    Select
                  </span>
                </TitleProjectCategory>
              }
              placeholder="project category"
              disabled={isDisable}
              defaultValue={dataDetail?.projectCategory}
              {...field}
            />
          )}
        />
      )}
      <HoldingSwitch>
        <label>Holding balance:</label>
        <Switch
          onChange={handleChangeHold}
          checked={dataDetail?.is_holding_balance || true}
        />
      </HoldingSwitch>
      <Controller
        name="contract"
        control={control}
        render={({ field }) => (
          <Input
            transparent={true}
            placeholder="your contract address"
            label="Your contract address"
            defaultValue={dataDetail?.contract}
            disabled={isDisable}
            {...field}
          />
        )}
      />
      <Controller
        name="description"
        control={control}
        rules={{ required: dataDetail?.description ? false : true }}
        render={({ field }) => (
          <Input
            transparent={true}
            placeholder="description"
            label="Description"
            defaultValue={dataDetail?.description}
            disabled={isDisable}
            hasError={errors.description}
            {...field}
          />
        )}
      />
      <Controller
        name="url"
        control={control}
        rules={{ required: dataDetail?.url ? false : true }}
        render={({ field }) => (
          <Input
            transparent={true}
            placeholder="url"
            label="Url"
            defaultValue={dataDetail?.url}
            disabled={isDisable}
            hasError={errors.url}
            {...field}
          />
        )}
      />
      <Flex>
        <Controller
          name="tabs"
          control={control}
          rules={{ required: dataDetail?.tabs ? false : true }}
          render={({ field }) => (
            <Input
              transparent={true}
              placeholder="tabs"
              label="Tabs"
              defaultValue={dataDetail?.tabs}
              disabled={isDisable}
              hasError={errors.tabs}
              {...field}
            />
          )}
        />
        <Controller
          name="amountToSale"
          control={control}
          rules={{ required: dataDetail?.amountToSale ? false : true }}
          render={({ field }) => (
            <Input
              type="text"
              transparent={true}
              placeholder="0"
              label="Amount To Sale"
              isCurrency={true}
              allowNegativeValue={false}
              defaultValue={dataDetail?.amountToSale}
              disabled={isDisable}
              hasError={errors.amountToSale}
              onValueChange={(e) => onChangeAmount(e, "amountToSale")}
            />
          )}
        />
      </Flex>
      <Flex>
        <Controller
          name="stock_price"
          control={control}
          rules={{ required: dataDetail?.stock_price ? false : true }}
          render={({ field }) => (
            <Input
              type="text"
              transparent={true}
              placeholder="0"
              label="Stock price"
              isCurrency={true}
              allowNegativeValue={false}
              defaultValue={dataDetail?.stock_price}
              disabled={isDisable}
              hasError={errors.stock_price}
              onValueChange={(e) => onChangeAmount(e, "stock_price")}
            />
          )}
        />
        <Dropdown
          width={"100%"}
          transparent={true}
          hasError={errorField["stock_to_buy"]}
          label="Stock to buy"
          disabled={isDisable}
          options={cryptos?.map((el: any) => {
            return {
              label: el?.name,
              value: el?.symbol,
            };
          })}
          defaultValue={dataICO?.stock_to_buy || "Choose the stock"}
          onChange={onChangeStockName}
        />
      </Flex>
      <Flex>
        <Controller
          name="max_per_user"
          control={control}
          rules={{ required: dataDetail?.max_per_user ? false : true }}
          render={({ field }) => (
            <Input
              type="text"
              transparent={true}
              placeholder="0"
              label="Max per user"
              isCurrency={true}
              allowNegativeValue={false}
              defaultValue={dataDetail?.max_per_user}
              disabled={isDisable}
              hasError={errors.max_per_user}
              onValueChange={(e) => onChangeAmount(e, "max_per_user")}
            />
          )}
        />
        <Controller
          name="max_per_product"
          control={control}
          rules={{ required: dataDetail?.max_per_product ? false : true }}
          render={({ field }) => (
            <Input
              type="text"
              transparent={true}
              placeholder="0"
              label="Max per product"
              isCurrency={true}
              allowNegativeValue={false}
              defaultValue={dataDetail?.max_per_product}
              disabled={isDisable}
              hasError={errors.max_per_product}
              onValueChange={(e) => onChangeAmount(e, "max_per_product")}
            />
          )}
        />
      </Flex>
      <Flex>
        <Dropdown
          width={"100%"}
          transparent={true}
          hasError={errorField["type_sell"]}
          label="Type Sell"
          disabled={isDisable}
          options={[
            { label: "round", value: "round" },
            { label: "pixel", value: "pixel" },
          ]}
          defaultValue={dataDetail?.type_sell || "Type sell"}
          onChange={(e) => onChangeAmount(e.value, "type_sell")}
        />
        <Dropdown
          width={"100%"}
          transparent={true}
          hasError={errorField["type_product"]}
          label="Type Product"
          disabled={isDisable}
          options={[
            { label: "tree", value: "tree" },
            { label: "apartment", value: "apartment" },
            { label: "land", value: "land" },
            { label: "image", value: "image" },
            { label: "product", value: "product" },
          ]}
          defaultValue={dataDetail?.type_product || "Type Product"}
          onChange={(e) => onChangeAmount(e.value, "type_product")}
        />
      </Flex>
      <Controller
        name="logo_pixel"
        control={control}
        render={({ field }) => (
          <Input
            transparent={true}
            placeholder="logo_pixel"
            label="Logo pixel"
            defaultValue={dataDetail?.logo_pixel}
            disabled={isDisable}
            {...field}
          />
        )}
      />

      <Flex>
        <Controller
          name="min"
          control={control}
          rules={{ required: dataDetail?.min ? false : true }}
          render={({ field }) => (
            <Input
              type="text"
              transparent={true}
              placeholder="0"
              label="Min Sale"
              isCurrency={true}
              allowNegativeValue={false}
              defaultValue={dataDetail?.min}
              disabled={isDisable}
              hasError={errors.min}
              onValueChange={(e) => onChangeAmount(e, "min")}
            />
          )}
        />
        <Controller
          name="max"
          control={control}
          rules={{ required: dataDetail?.max ? false : true }}
          render={({ field }) => (
            <Input
              type="text"
              transparent={true}
              placeholder="0"
              label="Max Sale"
              isCurrency={true}
              allowNegativeValue={false}
              defaultValue={dataDetail?.max}
              disabled={isDisable}
              hasError={errors.max}
              onValueChange={(e) => onChangeAmount(e, "max")}
            />
          )}
        />
      </Flex>

      {isShowDetail ? (
        <FieldTokenomics>Tokenomics</FieldTokenomics>
      ) : (
        <FieldTokenomics err={errorField["totalTokenomics"]}>
          Tokenomics:&ensp;<AddField onClick={removeField}>-</AddField>
          <AddField onClick={addFieldTokenomics}>+</AddField>
          {errorField["totalTokenomics"] && (
            <ErrorTk>
              Total amount your tokenomics is invalid(~100) and values must be
              greater than 0{" "}
            </ErrorTk>
          )}
        </FieldTokenomics>
      )}
      {fieldTokenomics.length > 0 &&
        fieldTokenomics.map((el, index) => (
          <Tokenomics>
            <Input
              transparent={true}
              defaultValue={el?.name}
              disabled={isDisable}
              placeholder="A"
              hasError={errors[`tokenomics${index}${active}`]}
              onChange={(e) => onChangeTokenomics(index, "name", e)}
            />
            <Input
              transparent={true}
              defaultValue={Number(el?.percent)}
              disabled={isDisable}
              type="number"
              min="0"
              placeholder="%"
              hasError={errors[`tokenomics${index}${index}${active}`]}
              onChange={(e) => onChangeTokenomics(index, "percent", e)}
            />
          </Tokenomics>
        ))}
    </>
  );
};

export default FormProjectICO;
