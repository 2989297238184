import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "api";

export const getAdvertisementsAsync = createAsyncThunk(
  "advertisement/list",
  async () => {
    try {
      const response: any = await API.ADVERTISEMENT.getAdvertisement();
      return response;
    } catch (error) {
      return error;
    }
  }
);
