import styled from "styled-components";

export const Loading = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(var(--vh, 1vh) * 100);
  background-color: black;
  img {
    width: 500px;
  }
`;
