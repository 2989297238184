import { isDesktop } from "react-device-detect";
import { getAdvertisement } from "redux/reducers/advertisments/advertisments.selectors";
import { useAppSelector } from "redux/reducers/hook";
import Center from "./Center";
import Left from "./Left";
import Right from "./Right";

const View = ({ isHome = false }) => {
  const ads = useAppSelector(getAdvertisement);
  return (
    <>
      {isDesktop && ads["left"] && <Left data={ads["left"]} isHome={isHome} />}
      {isDesktop && ads["right"] && <Right data={ads["right"]} />}
      {ads["center"] && <Center data={ads["center"]} />}
    </>
  );
};

export default View;
