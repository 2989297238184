import styled from "styled-components";
import { isMobile } from "react-device-detect";

const Wrapper = styled.div`
  margin-top: 40px;
  background: ${(props) => props.theme.colors["black100"]};
  border-radius: 8px;
  width: 100%;
  overflow: hidden;
  padding: 30px;

  h4 {
    text-align: center;
  }
`;

export const Campaigns = styled.div``;

export const ContainerUpload = styled.div`
  position: relative;
  width: 70px;
  height: 70px;
  margin: 4px;
  z-index: 999;
`;

export const AddOrRemoveChilCampaign = styled.div<{mt?: string}>`
  position: absolute;
  top: ${props => props.mt || '0px'};
  right: 0;
  z-index: 10;

  div {
    display: inline;
    padding: 0px 6px;
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.orange100};
    /* margin-right: 10px; */
    cursor: pointer;
  }
`;

export const TimeLineWrapper = styled.div`
  margin: 50px 0;

  .level-stepper {
    span,
    a {
      display: flex;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 30px;
        height: 30px;
      }
    }
    .level-detail {
      span,
      a {
        display: unset !important;
        border-radius: 0 !important;
        overflow: hidden;
      }
      img {
        width: 70px !important;
        height: 70px !important;
      }
      p {
        color: white;
        font-weight: 600;
      }
      @media screen and (max-width: 500px) {
        img {
          width: 40px !important;
          height: 40px !important;
        }
        p {
          color: white;
          font-weight: 600;
          font-size: 13px;
        }
      }
    }
    .active {
      opacity: 1 !important;
    }
  }
`;

export const TooltipWrapper = styled.div`
  hr {
    margin: 0;
    width: 100%;
  }
  text-align: left;
`;

export const Info = styled.div``;

export const ButtonWrapper = styled.div`
  margin: 50px 0;
  button {
    margin: 10px 0;
    margin-right: 6px;
  }
`;

export const AvatarActive = styled.div<{ isActive: boolean }>`
  display: ${(props) => (props.isActive ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -30px;
  left: calc(50% - 25px);
  overflow: hidden;
  width: 50px;
  height: 50px;
  img {
    width: 100% !important;
    height: auto !important;
  }
  @media screen and (max-width: 500px) {
    width: 30px;
    height: 30px;
    left: calc(50% - 15px);
    top: -10px;
  }
`;

export const Container = styled.div`
  margin-top: 40px;
  background: ${(props) => props.theme.colors["black100"]};
  padding: 20px;
  border-radius: 8px;
  img {
    cursor: pointer;
  }

  @media screen and (max-width: 512px) {
    padding: 5px;
  }
`;

export const WrapperProduct = styled.div`
  padding: 0 10px;
`;

export const Header = styled.div`
  margin-bottom: 20px;
`;

export const Item = styled.div`
  border-radius: 10px;
  border: 1px solid white;
  width: ${isMobile ? "300px" : "250px"};
  margin: 15px;
  padding: 5px;
  overflow: hidden;
  position: relative;
  text-align: center;
`;

export const Promotion = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;

  width: 50px;
  height: 40px;
  overflow: hidden;
  border-radius: 4px;

  img {
    width: 100%;
    height: auto;
  }
`;

export const WraperImg = styled.div`
  /* height: 160px; */
  height: 200px;
  overflow: hidden;
  background: ${(props) => props.theme.colors.white100};
  width: 100%;
  @media screen and (max-width: 1440px) {
    height: 150px;
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    &:hover {
      transform: scale(1.1);
      transition-duration: 0.5s;
    }
  }
`;

export const Detail = styled.div`
  padding: 5px;
  background: ${(props) => props.theme.colors["grey200"]};
  border-radius: 0 0 8px 8px;
`;

export const Element = styled.div`
  display: flex;
  align-items: center;
  p {
    width: 80%;
    height: 16px;
    overflow: hidden;
    font-size: 15px;
    line-height: 19px;
    font-weight: 700;
    margin: 0;
  }
  div {
    width: 20%;
    text-align: right;
    img {
      width: 25px;
    }
  }
`;

export const Title = styled.div``;

export const SubTitle = styled.div`
  font-weight: 300;
  font-size: 11px;
  height: 12px;
  width: 100%;
  margin-top: 3px;
  overflow: hidden;
`;

export const Cost = styled.div`
  margin-top: 10px;
  padding-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  span:first-child {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #00ac11;
  }
  p {
    font-size: 14px;
    line-height: 16px;
    margin: 0;
  }
  span:last-child {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: ${(props) => props.theme.colors["orange100"]};
  }
`;

export const Price = styled.p`
  font-weight: 600;
`;

export const Download = styled.div`
  width: 50%;
  margin: auto;
`;

export const Check = styled.div`
  text-align: center;
`;

export const SeletedCon = styled.div`
  margin-bottom: 10px;
  div {
    color: black;
  }
`;

export const HistoryShop = styled.div`
  padding: 0 10px;
  overflow-x: auto;
`;

export const Left = styled.div`
  /* width: 40%; */
  margin-right: 10px;
  text-align: center;
  img {
    width: 150px;
    margin-bottom: 10px;
  }
`;

export const Right = styled.div`
  /* width: 58%; */
  margin-left: 30px;
  h4 {
    font-size: 15px;
    display: inline-block;
  }
`;

export const PriceCart = styled.div`
  span {
    font-weight: bold;
  }
`;

export const TitleLabel = styled.div`
  font-size: 18px;
`;

export const ContainImage = styled.div`
  img {
    width: 100px;
  }
`;

export const Amount = styled.div``;

export const LabelAmount = styled.div``;

export const TotalArea = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
  h4 {
    display: inline;
    font-size: 18px;
  }
`;

export const Buy = styled.div`
  /* position: absolute; */
  /* right: 50px; */
  text-align: end;
`;

export const ContainModal = styled.div`
  display: flex;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;
  min-height: 250px;
  overflow: auto;
  button {
    padding: 5px 20px;
  }

  img {
    width: 100%;
    height: auto;
    max-width: 500px;
    margin: auto;
  }
`;

export const Action = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  div {
    width: 102px;
    margin: 0 2px;
  }
`;

export const CodeWrapper = styled.div`
  text-align: center;
  padding: 10px;
  background-image: url("/assets/images/gif/crypto.gif");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  .code {
    p {
      color: ${(props) => props.theme.colors.yellow100};
      margin: 3px 0;
      font-weight: 700;
      font-size: 20px;
    }
  }
`;

export const ImageList = styled.div`
  max-width: 500px;
  padding: 20px;
`;

export const ImageListItem = styled.div`
  display: flex;
  margin: 5px;
  img {
    width: 70px;
  }
`;

export const ProductWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ItemWrapper = styled.div`
  width: 170px;
  margin: 5px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors["grey200"]};
  @media screen and (max-width: 425px) {
    width: 150px;
  }
  @media screen and (max-width: 382px) {
    width: 140px;
  }
  @media screen and (max-width: 362px) {
    width: 121px;
  }
`;

export const RewardWrapper = styled.div`
  cursor: pointer;
  svg {
    color: deeppink;
  }
  display: flex;
  justify-content: end;
  background: ${(props) => props.theme.colors.white100};
  padding-right: 4px;
`;

export const Background = styled.div<{ bg }>`
  background-image: url(${(props) => props.bg});
  padding: 10px 5px;
  border-radius: 10px;
`;

export const ContainModalCampaign = styled.div`
  padding: 20px;
  h3 {
    font-weight: 600;
    text-align: center;
  }
  input {
    width: 100%;
  }
  .packages {
    button {
      margin: 10px;
    }
  }

  img {
    width: 100%;
    max-width: 700px;
    height: auto;
  }
`;

export const CounterdownWrapper = styled.div`
  margin: 10px 0;
  text-align: center;
  .title {
    font-size: 17px;
    text-align: center;
    font-weight: 700;
  }
  .Upcomming {
    color: ${(props) => props.theme.colors["yellow100"]};
  }
  .Happenning {
    color: ${(props) => props.theme.colors["green100"]};
  }
  .countdown-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0;
    .countdown-item {
      margin-right: 3px;
      .countdown-item-label {
        margin-bottom: 2px;
        padding-top: 0px;
        font-size: 10px;
      }
      &:last-child {
        margin-right: 0;
      }
      .countdown-item-value {
        background: #284f8a;
        padding: 0;
        font-family: "Poppins", Sans-serif;
        font-size: 14px;
        border-radius: 12px 12px 12px 12px;
        width: 30px;
        height: 30px;
        margin-bottom: 0px;
      }
    }
  }
`;

export const Footer = styled.div`
  text-align: center;

  .btn-glow {
    background: #1877f2;
    border-color: #1877f2;
    font-weight: 600;
    border: none;
    outline: none;
    color: #fff;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
  }

  .btn-glow:before {
    content: "";
    background: linear-gradient(
      45deg,
      #ff0000,
      #ff7300,
      #fffb00,
      #48ff00,
      #00ffd5,
      #002bff,
      #7a00ff,
      #ff00c8,
      #ff0000
    );
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }

  .btn-glow:active {
    color: #000;
  }

  .btn-glow:active:after {
    background: transparent;
  }

  .btn-glow:hover:before {
    opacity: 1;
  }

  .btn-glow:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  @keyframes glowing {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
`;

export const IconWrapper = styled.div`
  text-align: center;
  margin: 10px 0;
  img {
    width: 100px;
    height: 100px;
  }
`;

export const Introduce = styled.div`
  p {
    font-size: 18px;
    font-weight: 700;
    margin: 0px;
    width: 100%;
    height: 27px;
    overflow: hidden;
  }
  span {
    font-size: 12px;
  }
`;

export const PackagesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Profit = styled.div`
  font-size: 13px;
  /* min-height: 300px; */
  .title {
    font-weight: 600;
    margin: 0;
    font-size: 18px;
  }
  .gift {
    margin-right: 15px;
  }
  .active {
    background-image: linear-gradient(90deg, #292a2a 0, #b9be54);
    font-weight: 700;
  }
  .col-4 {
    cursor: pointer;
  }
  .tooltip_attachment {
    a {
      margin: 0 3px;
    }
  }
`;

export const UploadWrapper = styled.div`
  padding: 10px;
  .detail {
    max-width: 500px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;

    .add_image {
      cursor: pointer;
      width: 70px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0.5px dashed grey;
      margin: 4px;
    }
  }
  h3 {
    text-align: center;
  }
  button {
    height: auto;
    margin-top: 5px;
  }
  img,
  video {
    margin: 3px;
  }
`;

export const ContainerTable = styled.div`

`;

export default Wrapper;
