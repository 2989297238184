import styled from "styled-components";
import { isMobile } from "react-device-detect";

export const Container = styled.div`
  margin-top: 40px;
  background: ${(props) => props.theme.colors["black100"]};
  padding: 20px;
  border-radius: 8px;
  input {
    background: ${(props) => props.theme.colors["black100"]};
    color: white;
    /* width: 50%; */
    height: 40px;
    border: 0.1px solid white;
    border-radius: 8px;
    padding: 0 10px;
    height: 48px;
  }
`;
export const SwapType = styled.div`
  display: flex;
  margin: 20px 0;
  img {
    margin-left: 10px;
    padding: 0 8px;
    border-radius: 50%;
    border: 0.1px solid white;
    cursor: pointer;
  }
  span {
    font-size: 16px;
    line-height: 19px;
  }
`;
export const Choose = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 400px;
  text-align: center;
`;

export const TabSwap = styled.div<{active: boolean}>`
    padding: ${isMobile ? "5px" : "10px"};
    border: 1px solid ${(props) => props.theme.colors["orange100"]};
    border-radius: 8px;
    cursor: pointer;
    color: ${(props) => props.theme.colors["orange100"]};
    margin: ${isMobile ? "3px" : ""};
    opacity: ${props => props.active ? 1 : 0.5};
`;


export const InputText = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;

  label {
    font-size: 14px;
  }
  .select{
    margin-top: -80px!important;
  }
  .select2{
    margin-top: 0px!important;
  }
`;
export const Item = styled.div`
  width: 450px;
  label {
    width: 100%;
  }
  input {
    width: 100%;
  }
`;

export const Balance = styled.span`
  float: right;
  font-weight: 600;
`;

export const Select = styled.div`
  margin-left: 20px;
  margin-top: 15px;
  position: relative;
  padding: 0 10px;
  background: ${(props) => props.theme.colors["white100"]};
  color: ${(props) => props.theme.colors["black100"]};
  height: 48px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img:first-child {
    width: 25px;
  }
  p {
    margin: 0;
    margin-left: 6px;
    margin-right: 8px;
    font-weight: bold;
  }
  img:last-child {
  }
`;
export const Transfer = styled.div`
  max-width: 450px;
  text-align: center;
  margin-top: 18px;
  img {
    cursor: pointer;
  }
`;
export const Bottom = styled.div`
  p {
    span {
      color: #00ac11;
      font-weight: bold;
    }
  }
  button {
    margin-top: 30px;
    width: 360px;
    font-weight: bold;
    font-size: 16px;
  }

  @media screen and (max-width: 470px) {
    button {
      max-width: 100%;
    }
  }
`;
export const Explain = styled.div`
  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
`;
export const Note = styled.div`
  display: inline;
  margin-right: 7px;
  span {
    padding: 1px 7px;
    background: ${(props) => props.theme.colors["orange100"]};
    border-radius: 50%;
    color: black;
  }
`;

export const ModalContainer = styled.div`
  padding: 10px;
  position: relative;
  h5 {
    margin-bottom: 10px;
  }
`;

export const ListItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 500px;

  .active {
    border: 2px solid;
    border-color: ${(props) => props.theme.colors.yellow100};
  }
`;

export const Element = styled.div`
  margin: 0 16px 16px 0;
  padding: 8px 10px;
  border: 1px solid white;
  border-radius: 8px;
  img {
    margin-right: 8px;
    width: 25px;
  }
`;

export const Close = styled.div`
  background: gray;
  color: white;
  width: 10px;
`;

export const Status = styled.div`
  .success {
    color: ${(props) => props.theme.colors["green100"]};
  }
`;

export const HistoryWrapper = styled.div`
  overflow: auto;
`;
