import styled from "styled-components";

export const CountdownWrapper = styled.div`
  .countdown-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 18px;
    .countdown-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
      .countdown-item-value {
        background: #290571;
        font-family: "Poppins", Sans-serif;
        font-size: 25px;
        line-height: 30px;
        border-radius: 12px 12px 12px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        color: ${(props) => props.theme.colors.white100};
        width: 65px;
        height: 75px;
        margin-bottom: 5px;
      }
      .countdown-item-label {
        color: ${(props) => props.theme.colors.white100};
        font-family: "Poppins", Sans-serif;
        font-size: 12px;
        line-height: 21px;
        letter-spacing: 0.01px;
        text-align: center;
        margin-bottom: 10px;
        text-transform: lowercase;
      }
    }
  }
`;