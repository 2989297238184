import React from "react";

import Wrapper from "./styles";
import { formatNumber } from "utils/common";

export default function View({ reward }) {
  return (
    <Wrapper>
      {reward["stocks"] && reward["stocks"].length > 0 && (
        <>
          <label>Stocks :</label>
          {reward["stocks"].map((s) => (
            <div>
              <span>
                +{formatNumber(s.amount)} ({String(s.symbol).toUpperCase()})
              </span>
            </div>
          ))}
          <hr />
        </>
      )}
      {reward["vouchers"] && reward["vouchers"].length > 0 && (
        <>
          <label>Vouchers :</label>
          {reward["vouchers"].map((s) => (
            <div>
              <span>
                +{formatNumber(s.amount)} ({s.id})
              </span>
            </div>
          ))}
          <hr />
        </>
      )}

      {reward["tickets"] && reward["tickets"].length > 0 && (
        <>
          <label>Tickets :</label>
          {reward["tickets"].map((s) => (
            <div>
              <span>
                +{formatNumber(s.amount)} ({s.id})
              </span>
            </div>
          ))}
          <hr />
        </>
      )}

      {reward["codes"] && reward["codes"].length > 0 && (
        <>
          <label>Codes :</label>
          {reward["codes"].map((s) => (
            <div>
              <span>
                +{s.codes} ({s.symbol})
              </span>
            </div>
          ))}
          <hr />
        </>
      )}
    </Wrapper>
  );
}
