/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/alt-text */
import VButton from "components/button";
import VModal from "components/modal";
import { useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import Wrapper, {
  ButtonWrapper,
  ContainModal,
  CounterdownWrapper,
  Info,
  TimeLineWrapper,
  UploadWrapper,
  ContainerUpload,
} from "./styles";
import Timeline from "./TimeLine";
import Countdown from "components/countdown";
import { compareDateWithCur } from "utils/times";
import { useAppSelector } from "redux/reducers/hook";
import { getUserProfile } from "redux/reducers/users/users.selector";
import APIs from "api";
import CongratulationInvests from "components/congratulations";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Upload } from "pages/home/landing-page/styles";
import { AddOrRemoveChilCampaign } from "./styles";
import UploadFile from "components/uploadFile";

import Tabs from "components/tabs";
import { CgProfile } from "react-icons/cg";

import HistoryLevel from "./LevelHistories";
import Revenues from "./Revenues";
import { formatNumber } from "utils/common";
interface IProps {
  [key: string]: any;
}

export default function DetailCampaign(props: IProps) {
  const { campaignId } = useParams();

  const user = useAppSelector(getUserProfile);
  const [campaignSeleted, setCampaignSeleted] = useState(null);
  const [level, setLevel] = useState({
    data: {
      total_f1: 0,
      total_user: 0,
      total_my_invest: 0,
      total_user_invest: 0,
      amount_user_invest: 0,
    },
    level_id: "",
    images: [],
    videos: [],
    attachments: [],
    status_upload: "pending",
    images_uploaded: [],
    videos_uploaded: [],
    attachments_uploaded: [],
    enable_amount_image: false,
    enable_amount_video: false,
    enable_amount_attachment: false,
    createdAt: "",
  });

  const [congratulationInverst, setCongratulationInverst] = useState<any>(null);
  const [openModalCongratulation, setOpenModalCongratulation] = useState(false);
  const [openModalUpload, setOpenModalUpload] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [filesPreview, setFilesPreview] = useState([]);
  const [videosPreview, setVideosPreview] = useState([]);
  const [attachmentsPreview, setAttachmentsPreview] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState<string>("detail");
  const [levelNext, setLevelNext] = useState(null);

  const levelCurrent =
    (campaignSeleted ? campaignSeleted?.levels : []).find(
      (l) => l.id === level.level_id
    ) || campaignSeleted?.levels[0];

  const setFiles = (level) => {
    setFilesPreview(level.images);
    setVideosPreview(level.videos);
    setAttachmentsPreview(level.attachments);
  };

  const changeTab = (tab: string) => {
    setTab(tab);
  };

  const getLevel = async () => {
    const res: any = await APIs.TASK.getLevelUserCampaign({
      params: {
        id: campaignId,
      },
    });
    if (res.campaign) {
      setCampaignSeleted(res.campaign);
    }
    if (res.level) {
      setFiles(res.level);
      setLevel(res.level);
    }
  };

  useEffect(() => {
    if (campaignId) {
      getLevel();
    }
  }, [campaignId]);

  const handleLevel = () => {
    if (user && level) {
      const levelCampaign = (campaignSeleted.levels || []).find(
        (l) => l.id === level.level_id
      );
      if (levelCampaign) {
        setOpenModalCongratulation(true);
        setCongratulationInverst({
          background: campaignSeleted?.backgroundSuccess,
          userName: user.fullName || user.username,
          avataUser: user?.avatar,
          logoProject: campaignSeleted?.logo,
          logoLevel: levelCampaign?.logoLevel,
        });
      } else {
        toast.warning("You haven't reached any level yet?");
      }
    }
  };

  const renderImage = (image) => {
    return (
      <ContainModal>
        <img src={image} alt="" />
      </ContainModal>
    );
  };

  const renderCountDown = (p) => {
    let title = "";
    let time = 0;
    if (p.startDate && compareDateWithCur(p.startDate) > 0) {
      title = "Upcoming";
      time = new Date().getTime() + compareDateWithCur(p.startDate);
    } else {
      title = "Happenning";
      time = new Date().getTime() + compareDateWithCur(p.endDate);
    }

    return (
      <CounterdownWrapper>
        <span className={`title ${title}`}>{title}</span>
        <Countdown date={time} />
      </CounterdownWrapper>
    );
  };

  const setFileUpLoad = (f: any, id: string, key?: string) => {
    const updateUpload = (setStateUpload) => {
      setStateUpload((prev) =>
        prev.map((el: any) =>
          el.id === id
            ? {
                ...el,
                file: f,
                isNew: true,
              }
            : el
        )
      );
    };
    if (key === "image") {
      updateUpload(setFilesPreview);
    } else if (key === "video") {
      updateUpload(setVideosPreview);
    } else if (key === "attachments") {
      updateUpload(setAttachmentsPreview);
    } else {
      // console.log();
    }
  };

  const removeChil = (uuid: string, key: string) => {
    const updateState = (state, setState) => {
      const newArr = state.filter((el: any) => el.id !== uuid);
      setState(newArr);
    };
    if (key === "image") {
      updateState(filesPreview, setFilesPreview);
    } else if (key === "video") {
      updateState(videosPreview, setVideosPreview);
    } else if (key === "attachments") {
      updateState(attachmentsPreview, setAttachmentsPreview);
    } else {
    }
  };

  const onSubmit = async () => {
    try {
      // const { amount_image, amount_video, amount_attachment } = levelNext;

      // if (+amount_image !== +filesPreview.length)
      //   return toast.warning(
      //     "Please upload the correct number of files required!"
      //   );
      // if (+amount_video !== +videosPreview.length)
      //   return toast.warning(
      //     "Please upload the correct number of files required!"
      //   );
      // if (+amount_attachment !== +attachmentsPreview.length)
      //   return toast.warning(
      //     "Please upload the correct number of files required!"
      //   );

      setLoading(true);

      let imageUploaded = filesPreview;
      let videoUploaded = videosPreview;
      let attachmentUploaded = attachmentsPreview;

      const uploadSectionImg = async (state: any, uploadedState: any) => {
        if (state?.length > 0 && state?.filter((it) => it.file)?.length > 0) {
          const formData = new FormData();
          formData.append("folderPath", `campaigns`);

          const statesNew = state
            .filter((st) => st.isNew)
            .map((stNew) => {
              formData.append("files", stNew.file);
              return stNew;
            });

          const listImg: any = await APIs.UPLOAD.uploadMultiFile({
            body: formData,
          });

          listImg.forEach((el) => {
            statesNew[el.id] = {
              ...statesNew[el.id],
              src: el?.filePath,
            };
          });
          if (uploadedState === "imageUploaded") {
            imageUploaded = state.map((st) => {
              const newStFound = statesNew.find((c) => c.id === st.id);
              if (newStFound) {
                return {
                  ...st,
                  ...newStFound,
                };
              }
              return st;
            });
          } else if (uploadedState === "videoUploaded") {
            videoUploaded = state.map((st) => {
              const newStFound = statesNew.find((c) => c.id === st.id);
              if (newStFound) {
                return {
                  ...st,
                  ...newStFound,
                };
              }
              return st;
            });
          } else if (uploadedState === "attachmentUploaded") {
            attachmentUploaded = state.map((st) => {
              const newStFound = statesNew.find((c) => c.id === st.id);
              if (newStFound) {
                return {
                  ...st,
                  ...newStFound,
                };
              }
              return st;
            });
          } else {
            // console.log();
          }
        }
      };

      await uploadSectionImg(filesPreview, "imageUploaded");
      await uploadSectionImg(videosPreview, "videoUploaded");
      await uploadSectionImg(attachmentsPreview, "attachmentUploaded");

      await APIs.TASK.updateLevel({
        body: {
          ...level,
          campaignId: campaignId,
          images: imageUploaded.length > 0 ? imageUploaded : level.images,
          videos: videoUploaded.length > 0 ? videoUploaded : level.videos,
          attachments:
            attachmentUploaded.length > 0
              ? attachmentUploaded
              : level.attachments,
        },
      });

      toast.success("Upload success!");
      getLevel();
      setLoading(false);
      setOpenModalUpload(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.errors || "Image upload error!");
    }
  };

  const handleCloseModal = () => {
    setFiles(level);
    setOpenModalUpload(false);
  };

  const renderModalUpload = (levelNext: any) => {
    if (levelNext) {
      const {
        amount_image,
        amount_video,
        amount_attachment,
        enable_amount_image,
        enable_amount_video,
        enable_amount_attachment,
      } = levelNext;
      return (
        <UploadWrapper>
          <h3>Upload</h3>
          {enable_amount_image && (
            <>
              <h6>Upload ({amount_image}) images:</h6>
              <div className="detail">
                {filesPreview.map((sl: any, index: number) => (
                  <ContainerUpload>
                    <AddOrRemoveChilCampaign>
                      <div onClick={() => removeChil(sl.id, "image")}>-</div>
                    </AddOrRemoveChilCampaign>
                    <Upload>
                      <UploadFile
                        id={sl.id}
                        typeUpload="image"
                        sectionName="image"
                        file={sl.file}
                        src={sl.src}
                        setFile={setFileUpLoad}
                      />
                    </Upload>
                  </ContainerUpload>
                ))}
                <div
                  className="add_image"
                  onClick={() =>
                    setFilesPreview(
                      filesPreview.concat({
                        id: uuidv4(),
                        src: "",
                      })
                    )
                  }
                >
                  +
                </div>

                <br />
                <hr />
              </div>
            </>
          )}
          {enable_amount_video && (
            <>
              <h6>Upload ({amount_video}) videos:</h6>
              <div className="detail">
                {videosPreview.map((sl: any, index: number) => (
                  <ContainerUpload>
                    <AddOrRemoveChilCampaign>
                      <div onClick={() => removeChil(sl.id, "video")}>-</div>
                    </AddOrRemoveChilCampaign>
                    <Upload>
                      <UploadFile
                        id={sl.id}
                        typeUpload="video"
                        sectionName="video"
                        file={sl.file}
                        src={sl.src}
                        setFile={setFileUpLoad}
                      />
                    </Upload>
                  </ContainerUpload>
                ))}
                <div
                  className="add_image"
                  onClick={() =>
                    setVideosPreview(
                      videosPreview.concat({
                        id: uuidv4(),
                        src: "",
                      })
                    )
                  }
                >
                  +
                </div>
                <br />
                <hr />
              </div>
            </>
          )}
          {enable_amount_attachment && (
            <>
              <h6>Upload ({amount_attachment}) attachments:</h6>

              <div className="detail">
                {attachmentsPreview.map((sl: any, index: number) => (
                  <ContainerUpload>
                    <AddOrRemoveChilCampaign>
                      <div onClick={() => removeChil(sl.id, "attachments")}>
                        -
                      </div>
                    </AddOrRemoveChilCampaign>
                    <Upload>
                      <UploadFile
                        id={sl.id}
                        typeUpload="application/pdf"
                        sectionName="attachments"
                        file={sl.file}
                        src={sl.src}
                        setFile={setFileUpLoad}
                      />
                    </Upload>
                  </ContainerUpload>
                ))}
                <div
                  className="add_image"
                  onClick={() =>
                    setAttachmentsPreview(
                      attachmentsPreview.concat({
                        id: uuidv4(),
                        src: "",
                      })
                    )
                  }
                >
                  +
                </div>
                <br />
                <hr />
              </div>
            </>
          )}

          <VButton onClick={() => onSubmit()} loading={loading}>
            Submit
          </VButton>
        </UploadWrapper>
      );
    }
  };

  const myLevel = useMemo(() => {
    return (
      level.data || {
        total_f1: 0,
        total_user: 0,
        total_my_invest: 0,
        total_user_invest: 0,
      }
    );
  }, [level]);

  useEffect(() => {
    if (campaignSeleted?.levels && level) {
      const index = campaignSeleted?.levels.findIndex(
        (l) => l.id === level.level_id
      );
      setLevelNext(campaignSeleted?.levels[index + 1]);
    }
  }, [campaignSeleted, level]);

  return (
    campaignSeleted && (
      <Wrapper>
        <Tabs
          active={tab}
          tabs={[
            {
              name: "detail",
              render: (
                <>
                  <CgProfile size={17} /> <span>My Level</span>
                </>
              ),
            },
            {
              name: "my_level",
              render: (
                <>
                  <CgProfile size={17} /> <span>Histories</span>
                </>
              ),
            },
            {
              name: "revenues",
              render: (
                <>
                  <CgProfile size={17} /> <span>Revenues</span>
                </>
              ),
            },
          ]}
          handleTab={changeTab}
        />

        {tab === "my_level" && <HistoryLevel />}
        {tab === "revenues" && <Revenues />}
        {tab === "detail" && (
          <>
            <h4>{String(campaignSeleted.info.name || "").toUpperCase()}</h4>

            <TimeLineWrapper>
              <Timeline
                levels={campaignSeleted?.levels || []}
                myLevel={level}
                campaignSeleted={campaignSeleted}
              ></Timeline>
            </TimeLineWrapper>
            <Col xs={12} style={{ textAlign: "center" }}>
              {" "}
              <span>
                {" "}
                Start Date :{" "}
                {new Date(
                  campaignSeleted?.start_date
                ).toLocaleDateString()}{" "}
              </span>
              <br />
              <span>
                {" "}
                End Date :{" "}
                {new Date(campaignSeleted?.end_date).toLocaleDateString()}{" "}
              </span>
            </Col>
            {renderCountDown(campaignSeleted)}
            <Info>
              <Container>
                <Row>
                  <Col md={3} s={12}>
                    {" "}
                    {levelNext?.enable_total_f1 && (
                      <span>
                        {" "}
                        Amount Groups : {myLevel.total_f1 || 0}/
                        {levelNext?.total_f1 || 0}{" "}
                      </span>
                    )}
                    <br />
                    {levelNext?.enable_total_user && (
                      <span>
                        {" "}
                        Amount Users Under: {myLevel.total_user || 0}/
                        {levelNext?.total_user || 0}{" "}
                      </span>
                    )}
                    <br />
                  </Col>

                  <Col md={3} s={12}>
                    {levelNext?.enable_total_user_invest && (
                      <span>
                        {" "}
                        Total Users Invest :{" "}
                        {formatNumber(myLevel.total_user_invest || 0)}/
                        {formatNumber(levelNext?.total_user_invest || 0)} (
                        {String(campaignSeleted?.stock).toUpperCase()})
                      </span>
                    )}
                    <br />
                    {levelNext?.enable_total_my_invest && (
                      <span>
                        {" "}
                        Total My Invest :{" "}
                        {formatNumber(myLevel.total_my_invest || 0)}/
                        {formatNumber(levelNext?.total_my_invest || 0)} (
                        {String(campaignSeleted?.stock).toUpperCase()}){" "}
                      </span>
                    )}
                  </Col>

                  <Col md={3} s={12}>
                    {levelNext?.enable_amount_image && (
                      <span>
                        {" "}
                        Total Images : {level.images.length || 0}/
                        {levelNext?.amount_image || 0}
                      </span>
                    )}
                    <br />
                    {levelNext?.enable_amount_video && (
                      <span>
                        {" "}
                        Total Videos : {level.videos.length || 0}/
                        {levelNext?.amount_video || 0}
                      </span>
                    )}
                    <br />
                    {levelNext?.enable_amount_attachment && (
                      <span>
                        {" "}
                        Total Attachments : {level.attachments.length || 0}/
                        {levelNext?.amount_attachment || 0}
                      </span>
                    )}
                  </Col>

                  {levelNext?.enable_condition && (
                    <Col md={3} s={12}>
                      {levelNext?.conditions.length > 0 &&
                        levelNext?.conditions.map((item) => {
                          const level = campaignSeleted?.levels.find(
                            (el) => el.id === item.levelId
                          );
                          return (
                            <>
                              <span>
                                {" "}
                                {level.name} : {item.amount || 0}
                              </span>
                              <br />
                            </>
                          );
                        })}
                    </Col>
                  )}
                </Row>
              </Container>
            </Info>

            <Container>
              {" "}
              <ButtonWrapper>
                <VButton onClick={() => setOpenModal(true)}>
                  Campaign Information
                </VButton>
                <VButton width={"161px"} onClick={handleLevel}>
                  Level Certification
                </VButton>

                <VButton
                  onClick={() => {
                    setOpenModalUpload(true);
                  }}
                  disabled={
                    level.status_upload === "approved" ||
                    (!levelNext?.enable_amount_image &&
                      !levelNext?.enable_amount_video &&
                      !levelNext?.enable_amount_attachment)
                  }
                >
                  +Upload File
                </VButton>
              </ButtonWrapper>
            </Container>

            <CongratulationInvests
              type="level-up"
              openModalCongratulation={openModalCongratulation}
              setOpenModalCongratulation={setOpenModalCongratulation}
              createdAt={level && level.createdAt}
              levelName={levelCurrent && levelCurrent.name}
              {...congratulationInverst}
            />

            <VModal
              isOpen={openModal}
              children={renderImage(campaignSeleted.info.guide)}
              onCancel={() => setOpenModal(false)}
              centered={true}
            />

            <VModal
              isOpen={openModalUpload}
              children={renderModalUpload(levelNext ? levelNext : levelCurrent)}
              onCancel={() => handleCloseModal()}
              centered={true}
            />
          </>
        )}
      </Wrapper>
    )
  );
}
