/* eslint-disable react-hooks/exhaustive-deps */
import { ROUTES } from "config/routes";
import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { useLocation, useParams } from "react-router-dom";
import APIs from "api";
import Loading from "components/loading";
import { getEventTypeFromRoute } from "pages/events/components/constants";

const withEvent = (
  Component: any,
  option?: { create?: boolean; roles?: string[] }
) => {
  const TokenEdit: React.FC = (props) => {
    const prams: any = useParams();
    const location: any = useLocation();
    const [isLoading, setIsLoading] = useState(false);

    const { create: isCreateEvent } = option || {};

    const eventType = getEventTypeFromRoute(location.pathname);

    useEffect(() => {
      async function checkRoleCreateEvent() {
        try {
          const token = queryString.parse(location.search)?.token;
          const res: any = await APIs.USER.getUserByToken(token);
          if (
            res.user.role === "ADMIN" ||
            (res.user.role === "USER" && res.user.permissions[`events.create`])
          ) {
            setIsLoading(false);
            return;
          } else {
            throw new Error("Role invalid");
          }
        } catch (err) {
          window.location.href = ROUTES.LOGIN;
          setIsLoading(false);
        }
      }

      async function checkToken() {
        try {
          setIsLoading(true);
          const event: any = await APIs.EVENT.getEventById({
            params: {
              id: prams.id,
            },
          });

          if (event.status === "approved") {
            setIsLoading(false);
            return;
          } else {
            const token = queryString.parse(location.search)?.token;
            const res: any = await APIs.USER.getUserByToken(token);

            if (
              res.user.role === "ADMIN" ||
              res.user._id === event.user_id ||
              event.eventManagers?.includes(res.user._id)
            ) {
              setIsLoading(false);
              return;
            } else {
              window.location.href = ROUTES.LOGIN;
              setIsLoading(false);
            }

            setIsLoading(false);
          }
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          window.location.href = ROUTES.LOGIN;
        }
      }

      if (isCreateEvent) {
        checkRoleCreateEvent();
      } else {
        checkToken();
      }
    }, []);

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <Component {...props} eventType={eventType} />
      </div>
    );
  };

  return TokenEdit;
};

export default withEvent;
