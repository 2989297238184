import styled from "styled-components";
import { isMobile } from "react-device-detect";

export const HomeLayout = styled.div`
  height: calc(var(--vh, 1vh) * 100);
  overflow: ${isMobile ? "hidden" : ''};
`;

export const PaddingHeader = styled.div`
  height: 108px;
  @media screen and (max-width: 1100px) {
    height: 86px;
  }
`;

export const LayoutHome = styled.div`
  height: calc(calc(var(--vh, 1vh) * 100) - 108px);
  overflow: ${isMobile ? "auto" : ''};

  @media screen and (max-width: 1100px) {
    height: calc(calc(var(--vh, 1vh) * 100) - 142px);
  }
`;

export const BottomHome = styled.div`
  display: none;
  @media screen and (max-width: 1100px) {
    display: block
  }
`;

export const Wrapper = styled.div`
  overflow: auto;
`;

export const Section = styled.div<{ bgColor: any }>`
  background-color: ${(props) => props.theme.colors[props.bgColor]};
  padding-bottom: 30px;
  color: white;
`;

export const Container = styled.div<{ max?: any }>`
  max-width: ${(props) => props.max || "700px"};
  margin: auto;

  .row {
    align-items: center;
    --bs-gutter-x: 0.5rem;
  }
`;

export const ContentScreen = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  background-color: #00000081;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > div {
    padding: 100px 40px;
    background-color: #071120;
    text-align: center;
    box-shadow: 0 0 10px #ff9900;
    border-radius: 15px;
  }

  h1 {
    color: white;
    margin-bottom: 40px;
  }
`;

export const TopMain = styled.div`
  padding: 10px 0;
  padding-top: 5px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 640px) {
    flex-direction: column;
  }
`;

export const CoinsItem = styled.div`
  width: 47%;

  @media screen and (max-width: 640px) {
    width: 100%;
  }
`;

export const Coins = styled.div`
  display: flex;
  flex-direction: row;
  color: white;
`;

export const ItemContent = styled.div`
  img {
    width: 100%;
  }
`;

export const VideoYT = styled.div`
  iframe {
    width: 100%;
  }
`;

export const Title = styled.div`
  text-align: center;
  padding-top: 40px;

  h3 {
    margin: 0;
  }

  hr {
    margin: 0 auto;
    width: 40px;
    height: 2px;
  }
`;

export const Description = styled.p`
  margin-top: 15px;
  padding: 0 30px;
`;

export const Fundamentals = styled.div`
  img {
    width: 22px;
  }
  h6 {
    display: inline;
  }
  p {
    font-size: 14px;
  }
`;

export const Project = styled.div`
  text-align: center;
  background-color: ${(props) => props.theme.colors.black100};
  padding: 20px 8px;
  margin-bottom: 8px;
  h6 {
    font-size: 14px;
  }
  span {
    font-size: 12px;
  }
  p {
    margin: 0;
    font-size: 12px;
  }
`;

export const QrCodeWrapper = styled.div`
  padding: 10px;
  background-color: #fff;
  display: inline-block;
`;

export const TextWallet = styled.div`
  font-size: 8px;
  margin-bottom: 10px;
  margin-top: 4px;
`;

export const ItemDoc = styled.div`
  img {
    width: 30px;
    margin-bottom: 30px;
  }
  margin: auto;
  padding: 30px 0;
`;

export const Partners = styled.div`
  padding: 20px;
  .item-partners {
    height: 140px !important;
    max-width: 140px !important;
  }
`;

export const ItemPartner = styled.div`
  border: 1px solid white;
  border-radius: 50%;
  padding: 10px;
`;

export const OutTeam = styled.div`
  text-align: center;
  padding: 0 20px;
  h3 {
    margin-top: 5px;
  }
`;

export const Avta = styled.div`
  width: 150px;
  margin: auto;
  img {
    border-radius: 50%;
  }
`;

export const Flex = styled.div`
  display: flex;
`;
