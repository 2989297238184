import styled, { css } from "styled-components";

export const BankList = styled.div`
  padding: 5px 15px;

  p {
    color: ${(props) => props.theme.colors.grey100};
  }

  .row {
  }
`;

export const BankItem = styled.div<{selected?: boolean}>`
  background-color: ${(props) => props.theme.colors.bgColorLayer2};
  padding: 15px;
  border: 1px solid ${(props) => props.theme.colors.grey200};
  cursor: pointer;

  ${props => props.selected && css`
    border: 1px solid ${(props) => props.theme.colors.orange100};
  `}

  :hover {
    border: 1px solid ${(props) => props.theme.colors.orange100};
  }
`;
