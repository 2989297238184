import APIs from "api";
import Button from "components/button";
import Input from "components/input";
import Modal from "components/modal";
import { WrapperModalDonate, Thanks, Histories } from "./style";
import VTable from "components/table";
import { formatCurrency } from "utils/common";
import { useEffect, useState } from "react";
// import { isMobile } from "react-device-detect";
// import { BiDonateHeart } from "react-icons/bi";
// import { FaDonate } from "react-icons/fa";
// import { toast } from "react-toastify";
import { getCryptos } from "redux/reducers/cryptos/cryptos.selector";
import { useAppSelector } from "redux/reducers/hook";
import { toast } from "react-toastify";

export default function View(props: any) {
  const stocks = useAppSelector(getCryptos);
  const { dataEvent, openModal, setOpenModal } = props;

  const [listDonated, setListDonated] = useState([]);

  console.log("dataEvent", dataEvent);

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    stock: "vgc",
    amount: 0,
  });
  const [isSuccess, setIsSuccess] = useState(false);
  //   const [histories, setHistories] = useState([]);

  const handleSubmit = async () => {
    try {
      setLoading(true);

      await APIs.EVENT.donateEvent({
        body: {
          amount: form.amount,
          eventId: dataEvent._id,
        },
      });
      setIsSuccess(true);
    } catch (err) {
      toast.error(err.message);
    }

    setLoading(false);

    const resDonated: any = await APIs.EVENT.getDonateHistoriesEvent({
      params: {
        id: dataEvent._id,
      },
    });

    setListDonated(resDonated || []);
  };

  useEffect(() => {
    if (dataEvent._id) {
      APIs.EVENT.getDonateHistoriesEvent({
        params: {
          id: dataEvent._id,
        },
      }).then((resDonated: any) => {
        setListDonated(resDonated || []);
      });
    }
  }, [dataEvent._id]);

  return (
    <>
      <Modal
        isOpen={openModal}
        centered={true}
        onCancel={() => {
          setOpenModal(false);
          setIsSuccess(false);
        }}
      >
        {!isSuccess ? (
          <WrapperModalDonate>
            <h3>Vzone Charity</h3>

            <Input
              transparent={true}
              placeholder="VNEX"
              label="Stock"
              name="stock"
              type="select"
              value={form.stock}
              // onChange={handleChange}
            >
              {stocks
                ?.filter((el: any) => String(el.symbol).toUpperCase() === "VGC")
                .map((stock) => (
                  <option value={stock.symbol}>
                    {stock.name} ({String(stock.symbol).toUpperCase()})
                  </option>
                ))}
            </Input>

            <Input
              isCurrency={true}
              transparent={true}
              placeholder="10"
              label="Amount"
              name="amount"
              value={form.amount}
              allowNegativeValue={false}
              // hasError={errors.stock_price}
              onValueChange={(e) => setForm((form) => ({ ...form, amount: e }))}
            />

            <Button loading={loading} onClick={handleSubmit}>
              Submit
            </Button>
          </WrapperModalDonate>
        ) : (
          <Thanks>
            <img src={"/assets/images/thanks.gif"} alt="" />
          </Thanks>
        )}

        {
          listDonated.length > 0 && (
            <Histories>
              {" "}
              <VTable columns={[
                {
                  key: "ID",
                  title: "ID",
                  render: (item: any) => `${item.user_id && item.user_id["refer_code"]}`,
                },
                {
                  key: "Email",
                  title: "Email",
                  render: (item: any) => `${item.user_id && item.user_id["email"]}`,
                },
                {
                  key: "Amount",
                  title: "Amount",
                  render: (item) => formatCurrency(item?.event?.amount) + ` (${String(item?.event?.stock).toUpperCase()})`,
                },
              ]} dataSource={listDonated}></VTable>
            </Histories>
          )
        }
      </Modal>
    </>
  );
}
