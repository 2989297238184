/* eslint-disable no-empty-pattern */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getNotificationsClientAsync,
  getNotificationsOwnerAsync,
  getNotificationsSystemAsync,
} from "./notifications.actions";

export interface INotificationState {
  client: any[];
  owner: any[];
  system: any[];
  access: boolean;
}

const initialState: INotificationState = {
  client: [],
  owner: [],
  system: [],
  access: true,
};

export const NotificationSlice = createSlice({
  name: "advertisments",
  initialState,
  reducers: {
    setNotificationClient: (
      state: INotificationState,
      action: PayloadAction<any>
    ) => {
      state.client = [action.payload, ...state.client];
    },
    setNotificationSystem: (
      state: INotificationState,
      action: PayloadAction<any>
    ) => {
      state.system = [
        action.payload,
        ...state.system.filter((n) => n._id !== action.payload._id),
      ];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotificationsClientAsync.pending, (state) => {})
      .addCase(getNotificationsClientAsync.fulfilled, (state, action) => {
        const { notifications } = action.payload;
        state.client = notifications;
      })
      .addCase(getNotificationsSystemAsync.pending, (state) => {})
      .addCase(getNotificationsSystemAsync.fulfilled, (state, action) => {
        const { notifications } = action.payload;
        state.system = notifications;
      })
      .addCase(getNotificationsOwnerAsync.pending, (state) => {})
      .addCase(getNotificationsOwnerAsync.fulfilled, (state, action) => {
        const { notifications, errors } = action.payload;
        if (errors === "Not Authenticated") state.access = false;
        else {
          state.owner = notifications;
          state.access = true;
        }
      });
  },
});

export const { setNotificationClient, setNotificationSystem } =
  NotificationSlice.actions;

export default NotificationSlice.reducer;
