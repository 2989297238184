/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { getCampaignsAsync } from "redux/reducers/tasks/tasks.actions";
import { getCampaigns } from "redux/reducers/tasks/tasks.selector";
import { compareDateWithCur } from "utils/times";
import ToolTip from "components/tooltip/View";
import { Button } from "reactstrap";

import Wrapper, {
  Background,
  ContainModalCampaign,
  CounterdownWrapper,
  Footer,
  IconWrapper,
  Introduce,
  Item,
  PackagesWrapper,
  Profit,
  Promotion,
} from "./styles";
import Modal from "components/modal";
import Countdown from "components/countdown";
import { BsCameraVideoFill, BsFillImageFill } from "react-icons/bs";
import { TbWorld } from "react-icons/tb";
import { useHistory } from "react-router-dom";
import { ROUTES } from "config/routes";
interface IProps {
  [key: string]: any;
}

export default function View(props: IProps) {
  const campaigns = useAppSelector(getCampaigns);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [showImage, setShowImage] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [imageSelected, setImageSelected] = useState(null);
  const [videoSelected, setVideoSelected] = useState(null);

  useEffect(() => {
    dispatch(getCampaignsAsync());
  }, []);

  const renderCountDown = (p) => {
    let title = "";
    let time = 0;
    if (p.startDate && compareDateWithCur(p.startDate) > 0) {
      title = "Upcoming";
      time = new Date().getTime() + compareDateWithCur(p.startDate);
    } else {
      title = "Happenning";
      time = new Date().getTime() + compareDateWithCur(p.endDate);
    }
    return (
      <CounterdownWrapper>
        <span className={`title ${title}`}>{title}</span>
        <Countdown date={time} />
      </CounterdownWrapper>
    );
  };

  const renderImage = (image) => {
    return (
      <ContainModalCampaign>
        <img src={image} alt="" />
      </ContainModalCampaign>
    );
  };

  const renderVideo = (video) => {
    return (
      <ContainModalCampaign>
        <div className="video-responsive">
          <iframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${video}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen={true}
          ></iframe>
        </div>
      </ContainModalCampaign>
    );
  };

  const handleMoveDetail = (item) => {
    return history.push(ROUTES.CAMPAIGNDETAIL.replace(":campaignId", item._id));
  };

  const handleClosed = () => {
    setShowImage(false);
    setShowVideo(false);
  };
  return (
    <Wrapper>
      <PackagesWrapper>
        {campaigns &&
          campaigns.map((p: any) => (
            <Item>
              <Promotion>
                <img src={p.info.img_promotion} alt="" />
              </Promotion>
              <Background bg={p.banner}>
                <IconWrapper>
                  <img src={p.info.logo} alt="" />
                </IconWrapper>
                {renderCountDown(p)}
                <Introduce>
                  <p>{p.name}</p>
                  <div>
                    <span>
                      {" "}
                      Start Date: {new Date(
                        p.start_date
                      ).toLocaleDateString()}{" "}
                    </span>
                    <br />
                    <span>
                      {" "}
                      End Date: {new Date(p.end_date).toLocaleDateString()}{" "}
                    </span>
                  </div>
                  <hr />
                </Introduce>
                <Profit>
                  <div>
                    <ToolTip
                      id={`${p._id}_image`}
                      content={renderImage(p.info.guide)}
                    >
                      <span
                        id={`tooltip-` + `${p._id}_image`}
                        onClick={() => {
                          setImageSelected(p.info.guide);
                          setShowImage(true);
                        }}
                      >
                        <BsFillImageFill size={18} />
                      </span>
                    </ToolTip>
                    <ToolTip id={`${p._id}_video`} content={"Click view video"}>
                      <span
                        id={`tooltip-` + `${p._id}_video`}
                        onClick={() => {
                          setVideoSelected(p.info.video);
                          setShowVideo(true);
                        }}
                      >
                        {" "}
                        <BsCameraVideoFill size={22} />
                      </span>
                    </ToolTip>
                    <ToolTip id={`${p._id}_website`} content={p.info.website}>
                      <span
                        id={`tooltip-` + `${p._id}_website`}
                        onClick={() => {
                          window.open(p.website);
                        }}
                      >
                        {" "}
                        <TbWorld size={22} />
                      </span>
                    </ToolTip>
                  </div>
                </Profit>
                <hr />
                <Profit>
                  <div className="tooltip_attachment">
                    {(p.info.attachments || []).map((attach, index) => (
                      <ToolTip
                        id={`${index}_attachments`}
                        content={() => attach.name}
                      >
                        <a
                          id={`tooltip-` + `${index}_attachments`}
                          href={attach.link}
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          <img
                            src={attach.icon}
                            alt=""
                            width={25}
                            height={25}
                          />
                        </a>
                      </ToolTip>
                    ))}
                  </div>
                </Profit>

                <hr />
                <Footer>
                  <Button
                    className="btn-glow"
                    onClick={() => handleMoveDetail(p)}
                  >
                    DETAIL - CAMPAIGN
                  </Button>
                </Footer>
              </Background>
            </Item>
          ))}
        <Modal
          centered={true}
          isOpen={showImage}
          children={renderImage(imageSelected)}
          onCancel={handleClosed}
        />
        <Modal
          centered={true}
          isOpen={showVideo}
          children={renderVideo(videoSelected)}
          onCancel={handleClosed}
        />
      </PackagesWrapper>
    </Wrapper>
  );
}
