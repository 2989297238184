/* eslint-disable react-hooks/exhaustive-deps */
// import { isMobile } from "react-device-detect";
import APIs from "api";
import VButton from "components/button";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Col, Container, Input, Row } from "reactstrap";
import { useAppSelector } from "redux/reducers/hook";
import { getProjectSelected } from "redux/reducers/projects/projects.selector";
import {
  ModalWrapper,
  PixelItem,
  PixelItems,
  PixelWrapper,
  UploadData,
} from "../styles";
import readXlsxFile from "read-excel-file";
import { BsImage, BsTree } from "react-icons/bs";
import { MdOutlineApartment } from "react-icons/md";
import { BiLandscape } from "react-icons/bi";
import { TbBrandProducthunt } from "react-icons/tb";
import VModal from "components/modal";
import EditPixel from "./Edit";

const Pixels = () => {
  const project = useAppSelector(getProjectSelected);
  const [pixels, setPixels] = useState([]);
  const [pixelsAdd, setPixcelsAdd] = useState([]);
  const [amount, setAmount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [pixel, setPixel] = useState();

  const refDetail = useRef(null);

  const iconDefault = {
    tree: <BsTree />,
    apartment: <MdOutlineApartment />,
    land: <BiLandscape />,
    image: <BsImage />,
    product: <TbBrandProducthunt />,
  };

  useEffect(() => {
    if (project && project._id) getPixels();
  }, [project]);

  const getPixels = async () => {
    try {
      const res: any = await APIs.PROJECT.getPixelsByProjectId({
        params: {
          projectId: project._id,
        },
      });
      if (res) {
        setPixels(res.pixels);
      }
    } catch (error) {}
  };

  const handleChangeAmount = (e) => {
    const value = +e.target.value || 0;
    setAmount(value);
    if (value > 0) {
      setPixcelsAdd(Array(value).fill({}));
    }
  };

  const handleCreate = async () => {
    try {
      setLoading(true);
      if (pixelsAdd.length > 0) {
        await APIs.PROJECT.createPixels({
          body: {
            pixels: pixelsAdd,
            projectId: project._id,
          },
        });
        getPixels();
        setPixcelsAdd([]);
        setAmount("");
        setLoading(false);
        toast.success("Create success!");
      } else {
        setLoading(false);
        return toast.error("Please input data");
      }
    } catch (error) {
      toast.error(error.errors);
      setLoading(false);
    }
  };

  const handleChangeFile = (event) => {
    try {
      let titles = [];
      readXlsxFile(event.target.files[0]).then((rows) => {
        if (rows && rows.length > 0) {
          titles = rows[0];
          try {
            const products = rows.slice(1, rows.length).map((row: any) => {
              return {
                [titles[0].toLowerCase()]: row[0],
                [titles[1].toLowerCase()]: row[1],
                [titles[2].toLowerCase()]: row[2],
                [titles[3].toLowerCase()]: row[3],
                [titles[4].toLowerCase()]: row[4].split(","),
              };
            });
            setPixcelsAdd(products);
          } catch (error) {
            toast.error("The file is not in the correct format");
          }
        }
      });
    } catch (error) {
      toast.error("The file is not in the correct format");
    }
  };

  const handleClosed = () => {
    setIsOpenModal(false);
    setPixel(null);
  };

  const handleClickItem = (pixel) => {
    setPixel(pixel);
    setIsOpenModal(true);
  };

  const renderModal = (pixel: any) => {
    if (pixel) {
      return (
        <ModalWrapper>
          <EditPixel
            ref={refDetail}
            setPixels={setPixels}
            pixel={pixel}
            setIsOpenModal={setIsOpenModal}
            setLoading={setLoading}
          />
        </ModalWrapper>
      );
    }
  };

  return (
    <PixelWrapper>
      <Container>
        <UploadData>
          <Row>
            <Col xs={6} md={3}>
              <Input
                type="file"
                disabled={amount > 0}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={handleChangeFile}
              ></Input>
            </Col>
            <Col xs={6} md={3}>
              {" "}
              <Input
                value={amount}
                type="number"
                placeholder="Amount"
                onChange={handleChangeAmount}
              />
            </Col>
            <Col xs={6} md={3}>
              <VButton onClick={handleCreate} loading={loading}>
                Submit
              </VButton>
            </Col>
            <Col xs={6} md={3}>
              Total : {pixels.length || 0}
            </Col>
          </Row>
        </UploadData>
        <PixelItems>
          {pixels.map((product, index) => (
            <PixelItem key={index} onClick={(_) => handleClickItem(product)}>
              {product.image ? (
                <img src={product.image} alt="" />
              ) : project.logo_pixel ? (
                <img src={project.logo_pixel} alt="" />
              ) : (
                iconDefault[project.type_product]
              )}
            </PixelItem>
          ))}
        </PixelItems>
      </Container>

      <VModal
        isOpen={isOpenModal}
        children={renderModal(pixel)}
        onCancel={handleClosed}
        onConfirm={(_) => refDetail.current.submit()}
        onDelete={(_) => refDetail.current.delete()}
        centered={true}
        loading={loading}
      />
    </PixelWrapper>
  );
};

export default Pixels;
