/* eslint-disable indent */
import { StepWrapper, TitleWrapper, VerificationWrapper } from "../styles";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import Button from "components/button";
import { useEffect, useState } from "react";
import { useAppSelector } from "redux/reducers/hook";
import { getVerify } from "redux/reducers/users/users.selector";
import KYC from "./Kyc";
import Verify from "./Verify";
import { BsFillInfoCircleFill } from "react-icons/bs";

const Verification = () => {
  const defaultState = {
    status: "",
    note: "",
  };
  const verify = useAppSelector(getVerify);

  const [status, setStatus] = useState("init");
  const [percent, setPercent] = useState(50);
  const [stepCurrent, setStepCurrent] = useState(null);
  const [kyc, setKYC] = useState(defaultState);

  const handleClick = () => {
    setStepCurrent(status);
  };

  useEffect(() => {
    if (verify && verify.status !== "declined") {
      setStatus(verify.status);
      if (verify.status === "verified") {
        setPercent(100);
      }
      if (
        verify.status === "kyc" ||
        verify.status === "pending_verified" ||
        verify.status === "declined_verified"
      ) {
        setPercent(75);
      }
    }
    setKYC(verify);
  }, [verify]);

  const getColorVerify = (type, status) => {
    if (type === "verify")
      switch (status) {
        case "pending_verified":
          return "yellow";
        case "verified":
          return "green";
        default:
          return "gray";
      }
    else
      switch (status) {
        case "pending_kyc":
          return "yellow";
        case "kyc":
          return "green";
        case "pending_verified":
          return "green";
        case "verified":
          return "green";
        case "declined_verified":
          return "green";
        default:
          return "gray";
      }
  };

  return (
    <VerificationWrapper>
      {stepCurrent === "init" && <KYC setStepCurrent={setStepCurrent} />}
      {(stepCurrent === "kyc" || stepCurrent === "declined_verified") && (
        <Verify setStepCurrent={setStepCurrent} />
      )}

      {!stepCurrent && (
        <>
          {" "}
          <TitleWrapper>
            <CircularProgressbar
              value={percent}
              text={`${percent}%`}
              styles={buildStyles({
                textColor: "#FFE600",
                pathColor: "#00AC11",
              })}
            />{" "}
            <h4>
              Complete the account verification steps to enhance the security of
              your wallet
            </h4>
          </TitleWrapper>
          <StepWrapper>
            <ul>
              <li>
                {" "}
                <img
                  src={`/assets/images/kyc/verify-${getColorVerify(
                    "verify",
                    status
                  )}.png`}
                  alt=""
                />{" "}
                Account verification{" "}
                {status === "pending_verified" && <span>verifying</span>}
              </li>
              <li>
                {" "}
                <img
                  src={`/assets/images/kyc/verify-${getColorVerify(
                    "kyc",
                    status
                  )}.png`}
                  alt=""
                />{" "}
                Account KYC {status === "pending_kyc" && <span>verifying</span>}
              </li>
              <li>
                {" "}
                <img src="/assets/images/kyc/verify-green.png" alt="" /> Confirm
                email address, phonenumber
              </li>
              <li>
                <img src="/assets/images/kyc/verify-green.png" alt="" /> Update
                personal information
              </li>
            </ul>
            {status !== "verified" && status !== "pending_verified" && (
              <Button
                onClick={() => handleClick()}
                disabled={status === "pending_kyc"}
              >
                Establish
              </Button>
            )}
            {(kyc?.status === "declined" ||
              kyc?.status === "declined_verified") &&
              kyc?.note && (
                <div className="note">
                  <BsFillInfoCircleFill color="yellow" />
                  Note: {kyc?.note}
                </div>
              )}
          </StepWrapper>
        </>
      )}
    </VerificationWrapper>
  );
};

export default Verification;
