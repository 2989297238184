import styled from "styled-components";

export const SectionManagers = styled.div`
	display: flex;
	flex-wrap: wrap;
	max-width: 500px;
	margin-bottom: 15px;

	.form-check {
		margin: 5px 10px;
	}
`;