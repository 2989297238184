import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { getProjectsAsync } from "redux/reducers/projects/projects.action";
import { getProjects } from "redux/reducers/projects/projects.selector";

export default function useProjects() {
  const dispatch = useAppDispatch();
  const projects: any = useAppSelector(getProjects);
  const [projectsByType, setProjectsByType] = useState({
    upcoming: [],
    opening: [],
    closed: [],
  });

  useEffect(() => {
    dispatch(getProjectsAsync());
  }, [dispatch]);

  useEffect(() => {
    if (projects) {
      setProjectsByType({
        opening: projects.filter((p: any) => p.status === "opening"),
        closed: projects.filter((p: any) => p.status === "closed"),
        upcoming: projects.filter((p: any) => p.status === "upcoming"),
      });
    }
  }, [projects]);

  return { projectsByType, projects };
}
