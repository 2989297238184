/* eslint-disable no-empty-pattern */
import { createSlice } from "@reduxjs/toolkit";
import {
  getTasksAsync,
  getTaskByIdAsync,
  getCampaignsAsync,
} from "./tasks.actions";

export interface ITasksState {
  tasks: any;
  task: any;
  campaigns: any;
}

const initialState: ITasksState = {
  tasks: [],
  task: {},
  campaigns: [],
};

export const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTasksAsync.pending, (state) => {})
      .addCase(getTasksAsync.fulfilled, (state, action) => {
        const { tasks, errors } = action.payload;

        if (errors) {
          return;
        }
        state.tasks = tasks;
      })
      .addCase(getCampaignsAsync.pending, (state) => {})
      .addCase(getCampaignsAsync.fulfilled, (state, action) => {
        const { campaigns, errors } = action.payload;

        if (errors) {
          return;
        }
        state.campaigns = campaigns;
      })
      .addCase(getTaskByIdAsync.pending, (state) => {})
      .addCase(getTaskByIdAsync.fulfilled, (state, action) => {
        const { task, errors } = action.payload;

        if (errors) {
          return;
        }
        state.task = task;
      });
  },
});

export const {} = tasksSlice.actions;

export default tasksSlice.reducer;
