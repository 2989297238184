import { isMobile } from "react-device-detect";
import styled from "styled-components";

export const Wrapper = styled.div`
  background: linear-gradient(180deg, #00143d, #000);
  width: 100vw;
  min-height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
  color: ${(props) => props.theme.colors.white100};
`;
export const Header = styled.div`
  margin-top: 50px;

  h3 {
    font-size: 24px;
  }
  p {
    font-size: 14px;
    color: ${(props) => props.theme.colors.grey200};
  }
`;

export const Category = styled.div`
  margin: 20px 15px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .dropdown {
    margin: 0 10px;
  }

  ._filter_addresss {
    display: flex;
    flex-wrap: wrap;

    .dropdown {
    width: 200px;
  }
  }

  .active {
    opacity: 1;
  }
`;

export const Item = styled.div`
  padding: 6px 14px;
  margin: 2px 15px;
  background: ${(props) => props.theme.colors.white100};
  font-size: 12px;
  border-radius: 8px;
  color: ${(props) => props.theme.colors.grey200};
  opacity: 0.6;
  cursor: pointer;
`;

export const Border = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.white100};
  margin: 13px 15px;
`;

export const Project = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
`;

export const Element = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export const ContainerEndProject = styled.div`
  margin-top: 30px;
`;

export const ContainHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
`;

export const CreatePage = styled.div`
  position: fixed;
  cursor: pointer;
  bottom: ${isMobile ? "130px" : "100px"};
  right: 24px;
  width: 60px;
  height: 60px;
  background-color: white;
  padding: 18px;
  border-radius: 50%;

  img {
    width: 100%;
    border-radius: 4px;
  }

  &:hover {
    transform: scale(1.2);
  }
`;
