import styled from "styled-components";

export const Container = styled.div`
  margin-top: 40px;
  background: ${(props) => props.theme.colors["black100"]};
  padding: 20px;
  border-radius: 8px;
  @media screen and (max-width: 512px) {
    padding: 5px;
  }
  h4 {
    text-align: center;
    font-weight: 600;
    font-size: 25px;
  }
  img {
    max-width: 1000px;
    width: 100%;
  }
  .item {
    width: 80px;
    height: 80px;
    position: absolute;
    @media screen and (max-width: 420px) {
      width: 60px;
      height: 60px;
    }
  }
  h3 {
    margin-bottom: 40px;
  }
`;
export const CountdownContainer = styled.div`
  .countdown-item {
    .countdown-item-value {
      background-image: linear-gradient(90deg, #21d397 0, #7450fe) !important;
    }
  }
`;

export const ContainLevel = styled.div`
  position: relative;
  min-height: 300px;
  margin: auto;
  width: 420px;
  @media screen and (max-width: 420px) {
    width: 300px;
    min-height: 230px;
    margin: auto;
  }
`;

export const Five = styled.div`
  background: red;
  right: 0;
  z-index: 999;
`;
export const Four = styled.div`
  background: orange;
  right: 80px;
  top: 50px;
  z-index: 998;
  @media screen and (max-width: 420px) {
    right: 60px;
    top: 40px !important;
  }
`;
export const Three = styled.div`
  background: yellow;
  right: 160px;
  top: 100px;
  z-index: 997;
  @media screen and (max-width: 420px) {
    right: 120px;
    top: 80px;
  }
`;
export const Two = styled.div`
  background: green;
  right: 240px;
  top: 150px;
  z-index: 996;
  @media screen and (max-width: 420px) {
    right: 180px;
    top: 120px;
  }
`;
export const One = styled.div`
  background: blue;
  right: 320px;
  top: 200px;
  @media screen and (max-width: 420px) {
    right: 240px;
    top: 160px;
  }
`;

export const TableWrapper = styled.div`
  overflow: auto;
`;

export const TableContent = styled.div`
  position: relative;
`;

export const FilterDate = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  label {
    margin-bottom: 0;
  }
  .dropdown-menu {
    width: auto;
    max-width: 350px;
  }
`;

export const ViewLevel = styled.div`
  margin-bottom: 20px;
`;

export const StatisWrapper = styled.div`
  .claim-wrapper {
    display: flex;
  }
`;

export const DateContainer = styled.div`
  margin: 5px;
`;

export const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  max-width: 100%;
  overflow: auto;
  .tab-item {
    display: flex;
    align-items: center;
    gap: 0px 4px;
    padding: 0 12px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    :hover {
      text-decoration: underline;
    }
  }
  .active-tab {
    text-decoration: underline;
  }
  .tab-item:not(:last-child) {
    border-right: solid 1px white;
  }
`;

export const PaginateWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0 8px;
  padding: 8px 12px;
`;
