/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";

import { Wrapper } from "../styles";

import APIs from "api";

import PdfPreview from "components/pdf-preview";

const View = () => {
  const prams = useParams();

  const location = useLocation();

  const [data, setData] = useState<any>({});
  const [type, setType] = useState<string>("");

  const getYourStock = async () => {
    try {
      const yourEstock: any = await APIs.YOURESTOCK.getYourEstock(prams.symbol);
      if (yourEstock) setData(yourEstock);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    if (location.pathname.includes("whitepaper")) {
      setType("whitepaper");
    }
    if (location.pathname.includes("terms")) {
      setType("terms");
    }
    getYourStock();
  }, []);

  return (
    <Wrapper>
      {data?.eStockInfo?.[type] && (
        <PdfPreview
          symbol={prams.symbol}
          title={
            type === "whitepaper"
              ? "whitepaper"
              : "User Agreement and Privacy Policy"
          }
          urlPdf={`${process.env.REACT_APP_API_URL}/pdf-preview?url=${data?.eStockInfo?.[type]}`}
        />
      )}
    </Wrapper>
  );
};

export default View;
