import { Dots, InputRange, InputRangeSlider } from "./styles";

const Progress = ({ value, handleChange }) => {
  return (
    <>
      <InputRange>
        <Dots>
          {Array(5)
            .fill(0)
            .map((_, i) => (
              <div onClick={() => handleChange(i * 25)}>
                <span>{25 * i}%</span>
              </div>
            ))}
        </Dots>
        <InputRangeSlider
          className="slider"
          type={"range"}
          value={value}
          onChange={(e) => handleChange(e.target.value)}
        />
      </InputRange>
    </>
  );
};

export default Progress;
