/* eslint-disable indent */
import Table from "components/table";
import { getPurchasePackages } from "redux/reducers/purchases/purchases.selector";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPurchasesAsync } from "redux/reducers/purchases/purchases.actions";
import Button from "components/button";
import APIs from "api";
import { toast } from "react-toastify";
import { formatNumber } from "utils/common";
import Modal from "components/modal";
import {
  Coin,
  ContainModal,
  Description,
  ModalHeader,
  ModalHistoryWrapper,
  Transfer,
  Available,
} from "../styles";
import VButton from "components/button";
import { convertTimestamptoDate } from "utils/times";
import { ModalContent } from "pages/my/ibo-project/styles";
import Input from "components/input";
import {
  getCryptosByObject,
  getStocksPrice,
} from "redux/reducers/cryptos/cryptos.selector";
import CongratulationInvests from "components/congratulations";

const View = () => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(null);
  const [loadingClaim, setLoadingClaim] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [investSelected, setInvestSelected] = useState(null);
  const stocks = useAppSelector(getCryptosByObject);
  const prices = useAppSelector(getStocksPrice);
  const [state, setState] = useState({
    isOpen: false,
    amountFrom: null,
    amountTo: null,
  });
  const [openModalCongratulation, setOpenModalCongratulation] =
    useState<boolean>(false);

  const columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "_id",
      render: (item: any) => {
        return item?._id.slice(0, 4) + "..." + item?._id.slice(-3);
      },
    },
    {
      key: "invoice",
      title: "Invoice",
      render: (item: any) => (
        <a
          href={`/invoice/investment/${item.package_id["name"]}/${item._id}`}
          target={"_blank"}
          rel="noreferrer"
        >
          <VButton>View</VButton>
        </a>
      ),
    },
    {
      key: "congratulation",
      title: "Congratulation",
      render: (item: any) => (
        <VButton
          onClick={() => {
            setInvestSelected(item);
            setOpenModalCongratulation(true);
          }}
        >
          View
        </VButton>
      ),
    },
    {
      key: "Package",
      title: "Package",
      render: (item) => {
        return `${item.package_id.name} (${item.package_id.type})`;
      },
    },
    {
      key: "amount",
      title: "Amount",
      render: (item) => {
        return `${formatNumber(item.investment.amount)} (${String(
          item.package_id.invest.stock
        ).toUpperCase()})`;
      },
    },
    {
      key: "from",
      title: "From",
      render: (item) => {
        return `${new Date(item.investment.from_date).toLocaleDateString()} `;
      },
    },
    {
      key: "to",
      title: "To",
      render: (item) => {
        return `${new Date(item.investment.to_date).toLocaleDateString()} `;
      },
    },
    {
      key: "Hash",
      title: "Hash",
      render: (item: any) => (
        <a
          href={`${
            process.env.REACT_APP_VZX_SCAN || "https://block.vzonex.com"
          }/tx/${item.investment.hash}`}
          target={"_blank"}
          rel="noreferrer"
        >
          {item.investment.hash?.slice(0, 5)}...
          {item.investment.hash?.slice(-5)}
        </a>
      ),
    },
    { key: "status", title: "Status", dataIndex: "status" },
    {
      key: "histories",
      title: "Histories",
      render: (item: any) => (
        <VButton
          onClick={() => {
            setInvestSelected(item);
            setOpenModal(true);
          }}
        >
          View
        </VButton>
      ),
    },
    {
      key: "principal",
      title: "Principal",
      render: (item) => {
        return (
          <div className="principal">
            <span>
              Total : {formatNumber(item.investment.principal?.total)} (
              {String(item.investment.principal?.stock).toUpperCase()})
            </span>
            <hr />
            <span>
              Claimed : {formatNumber(item.investment.principal?.claimed)} (
              {String(item.investment.principal?.stock).toUpperCase()})
            </span>
            <hr />
            <span>
              Remaining : {formatNumber(item.investment.principal?.remaining)} (
              {String(item.investment.principal?.stock).toUpperCase()})
            </span>
            <hr />
            {item.investment.principal.status !== "finished" &&
              +item.investment.principal?.remaining > 0 && (
                <Button
                  onClick={() => handleClaim(item._id, "principal")}
                  loading={loading === item._id}
                >
                  Claim
                </Button>
              )}
          </div>
        );
      },
    },
    {
      key: "profit",
      title: "Profit",
      render: (item) => {
        return (
          <div className="profit">
            <span>
              Total : {formatNumber(item.investment.profit?.total)} (
              {String(item.investment.profit?.stock).toUpperCase()})
            </span>
            <hr />
            <span>
              Claimed : {formatNumber(item.investment.profit?.claimed)} (
              {String(item.investment.profit?.stock).toUpperCase()})
            </span>
            <hr />
            <span>
              Remaining : {formatNumber(item.investment.profit?.remaining, 0)} (
              {String(item.investment.profit?.stock).toUpperCase()})
            </span>
            <hr />
            {item.investment.profit.status !== "finished" &&
              +item.investment.profit?.remaining > 0 && (
                <Button
                  onClick={() => {
                    setInvestSelected(item);
                    setState({
                      ...state,
                      isOpen: true,
                    });
                  }}
                >
                  Claim
                </Button>
              )}
          </div>
        );
      },
    },
  ];

  const columnsHistoriesProfit = [
    { key: "unlock", title: "Unlock", dataIndex: "unlock" },
    {
      key: "Date",
      title: "Date",
      render: (item) => {
        return convertTimestamptoDate(item.date);
      },
    },
    {
      key: "amount",
      title: "Amount",
      render: (item) => {
        return `${item.amount} (${String(item.stock).toUpperCase()})`;
      },
    },
    {
      key: "Hash",
      title: "Hash",
      render: (item: any) => (
        <a
          href={`${
            process.env.REACT_APP_VZX_SCAN || "https://block.vzonex.com"
          }/tx/${item.hash}`}
          target={"_blank"}
          rel="noreferrer"
        >
          {item.hash?.slice(0, 20)}...
        </a>
      ),
    },
  ];

  const handleClosed = () => {
    setOpenModal(false);
    setOpenModalCongratulation(false);
    setInvestSelected(null);
  };

  const handleChange = (type, value, price) => {
    let amountFrom = 0;
    let amountTo = 0;
    if (type === "from") {
      amountFrom = +value;
      amountTo = +value / +price;
    } else {
      amountTo = +value;
      amountFrom = +value * +price;
    }
    setState({
      ...state,
      amountFrom,
      amountTo,
    });
  };
  const handleClosedClaim = () => {
    setInvestSelected(null);
    setState({
      ...state,
      isOpen: false,
      amountFrom: 0,
      amountTo: 0,
    });
  };

  const handleClaim = async (purchase_id, type, amount = 0) => {
    try {
      if (type === "profit") setLoadingClaim(true);
      else setLoading(purchase_id);
      await APIs.PURCHASE.claim({
        body: {
          type,
          purchase_id,
          amount,
        },
      });
      dispatch(
        getPurchasesAsync({
          params: {
            type: "package",
          },
        })
      );
      setLoading(null);
      setLoadingClaim(false);
      handleClosedClaim();
      toast.success("Claim success!");
    } catch (error) {
      setLoading(null);
      setLoadingClaim(false);
      toast.error(error.errors);
    }
  };

  const renderModal = (investSelected: any) => {
    if (investSelected) {
      return (
        <ModalHistoryWrapper>
          {" "}
          <Table
            columns={columnsHistoriesProfit}
            dataSource={investSelected.investment.histories || []}
            bgTable={"black100"}
          ></Table>
        </ModalHistoryWrapper>
      );
    }
  };

  const renderModalClaim = (investSelected) => {
    if (investSelected) {
      const from = investSelected?.investment?.profit.stock;
      const to = investSelected?.investment?.profit.stock_claim;
      const price = prices[to]["vnd"] / prices[from]["vnd"];

      return (
        <ContainModal>
          <ModalHeader>
            <img src={investSelected.package_id.icon} alt="" />
            {/* <span>VZONEX</span> */}
            <span>{investSelected.package_id.name}</span>
          </ModalHeader>
          <ModalContent>
            <Coin>
              <img src={stocks[from]["logo"]} alt="" />
              <span>{from.toUpperCase()}</span>
              <Input
                name="amountFrom"
                // type="number"
                isCurrency={true}
                value={state.amountFrom}
                placeholder="0.00"
                onValueChange={(e) => handleChange("from", e, price)}
              />
              <Available>
                <span>Available: </span>
                <span>
                  {investSelected?.investment?.profit?.remaining?.toFixed(2) ||
                    0}
                </span>
              </Available>
            </Coin>
            <Transfer>
              <img src="/assets/images/icons/transfer.svg" alt="" />
            </Transfer>
            <Coin>
              <img src={stocks[to]["logo"]} alt="" />
              <span>{to.toUpperCase()}</span>
              <Input
                isCurrency={true}
                name="amountTo"
                value={state.amountTo}
                placeholder="0.00"
                onValueChange={(e) => handleChange("to", e, price)}
              />
            </Coin>
            <Description>
              <span>Rate</span>
              <span>
                1 {String(to).toUpperCase()} = {price.toFixed(2)}{" "}
                {String(from).toUpperCase()}
              </span>
            </Description>
            {/* <Description>
                <span>Fee</span>
                <span>0.5%</span>
              </Description> */}
            <Button
              onClick={() =>
                handleClaim(investSelected._id, "profit", state.amountFrom)
              }
              loading={loadingClaim}
            >
              Claim
            </Button>
          </ModalContent>
        </ContainModal>
      );
    }
  };

  useEffect(() => {
    dispatch(
      getPurchasesAsync({
        params: {
          type: "package",
        },
      })
    );
  }, [dispatch]);

  const orders = useSelector(getPurchasePackages);

  return (
    <div className="history">
      <Table columns={columns} dataSource={orders} bgTable={"black100"}></Table>

      {investSelected && (
        <Modal
          isOpen={state.isOpen}
          children={renderModalClaim(investSelected)}
          onCancel={handleClosedClaim}
          centered={true}
        />
      )}
      <Modal
        isOpen={openModal}
        children={renderModal(investSelected)}
        onCancel={handleClosed}
        centered={true}
      />
      <CongratulationInvests
        type="investsSuccess"
        background={investSelected?.package_id?.backgroundSuccess}
        userName={investSelected?.user_id?.fullName}
        avataUser={investSelected?.user_id?.avatar}
        logoProject={investSelected?.package_id?.icon}
        logoLevel={investSelected?.package_id?.logoLevel}
        amount={investSelected?.investment?.amount}
        symbol={investSelected?.package_id?.invest?.stock}
        createdAt={investSelected?.createdAt}
        openModalCongratulation={openModalCongratulation}
        setOpenModalCongratulation={setOpenModalCongratulation}
      />
    </div>
  );
};

export default View;
