import {
  AddLink,
  AddOrRemoveChil,
  MovePosition,
} from "pages/home/landing-page/styles";

import { Label, FormGroup, Input as InputB } from "reactstrap";
import { FcSurvey } from "react-icons/fc";
import { AiOutlineClose, AiFillEye } from "react-icons/ai";
import { v4 as uuidv4 } from "uuid";
import Button from "components/button";
import { toast } from "react-toastify";

import FormAddLink from "../formAddLink";
import FormInfomation from "../formInfomation";
import ListIconComponents from "./components/list-icon";


import {
  ListSurvey,
  ItemSurvey,
  BottomBar,
  FooterSurvey,
  ItemEdit,
  SurveyContainer,
  ContainerImage,
  OptionChooseType,
  ItemChecked,
  SubmitSurveyImage,
  OptionChangeLimit,
  ItemContentSurvey,
  ContainerRegisterItem,
  TitleItemSurvey,
  AdminAddBonusView
} from "../../styles";
import Input from "components/input";
import Table from "components/table";
import { useState } from "react";
import Modal from "components/modal";
import { useParams } from "react-router-dom";

import SurveyItem from "./components/survey-item";
import APIs from "api";
import { useForm } from "react-hook-form";
import { formatNumber } from "utils/common";

const View = (props) => {
  const {
    user,
    isEdit,
    type,
    index,
    idSurvey,
    content,
    detail,
    color,
    limit,
    handleChooseTypeSurvey,
    handleAddOrRemoveChil,
    removeChil,
    handleMoveChil,
    setFile,
    onChangeChecked,
    handleAddIconContent,
    onChangeDataSurveyBanner,
    onBlurText,
    handleInteractiveOrSurvey,
    handleSubSectionSurvey
  } = props

  const params = useParams();
  const { id } = params

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const [tableSurveyDetail, setTableSurveyDetail] = useState({
    isShow: false,
    data: []
  })

  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openModalLinkIcon, setopenModalLinkIcon] = useState<boolean>(false)

  const column = [
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Vị trí",
      dataIndex: "stt",
      key: "stt",
      render: (data: any) => <div>
        {
          content.map((item, index) => item.id === data.id ? (index + 1) : '')
        }
      </div>,
    },
    {
      title: "Bình chọn",
      dataIndex: "checked",
      key: "checked",
      render: (data: any) => <div className="_ratio_servey">
        {formatNumber((Number(data?.checked?.length) +  Number(data.s || 0)), 0)}/{formatNumber(totalSurvey(tableSurveyDetail.data), 0)}</div>,
    },
  ]
	
  const totalSurvey = (data: any) => {
    let total = 0;
    total = data?.reduce(function (
      acc,
      obj
    ) {
      return acc + Number(obj.checked?.length || 0) + Number(obj.s || 0);
    }, 0);
    return total
  }


  const totalLimit = (data: any) => {
    let total = 0;
    total = data?.reduce(function (
      acc,
      obj
    ) {
      return acc + obj.checked?.includes(String(user?._id)) ? 1 : 0;
    }, 0);
    return total
  }

  const openTagWindow = (url: string) => {
    if (!isEdit) {
      window.open(url)
    }
  }
	
  const openModalRegister = () => {
    setOpenModal(true)
  }

  const handleViewSurveyDetail = (data: any) => {
    const preDataSort = [...data];
    const dataSort = preDataSort.sort((a: any, b: any) => (b.checked?.length + Number(b.s || 0)) - (a.checked?.length + Number(a.s || 0)))
    setTableSurveyDetail(({
      isShow: !tableSurveyDetail.isShow,
      data: dataSort
    }))
  }

  const [dataRegister, setDataRegister] = useState<any>({
    id: uuidv4(),
    src: "",
    name: "",
    urlVideo: 'https://www.youtube.com/watch?v=Aq6W-nmRO94',
    slide: [],
    listIcon: {},
    checked: [],
  })

  const setFileItem = (f: any, id: string, key?: string, index?: number) => {
    setDataRegister(prev => ({
      ...prev,
      file: f,
    }))
  };

  const handleAddIconContentItem = (listLink: any, uuid: string, index: number) => {
    setopenModalLinkIcon(true)
    // setDataRegister(prev => ({
    //   ...prev,
    //   listIcon: listLink,
    // }))
  }

  const onChangeBanner = (data, index: number, uuid: string) => {
    setDataRegister(prev => ({
      ...prev,
      slide: data.slide,
      urlVideo: data.link
    }))
  }

  const onChangeInput = (e: any, key?: string, index?: number, type?: string, uuid?: string, name?: string) => {
    if(type === "name"){
      setDataRegister(prev => ({
        ...prev,
        name: e.target.value,
      }))
    } else if(key === "list_survey_others"){
      setDataRegister(prev => ({
        ...prev,
        listIcon: {
          ...prev.listIcon,
          others: prev.listIcon.others.map(el => el.id === uuid ? {
            ...el,
            link: e.target.value
          } : el)
        },
      }))
    } else {
      setDataRegister(prev => ({
        ...prev,
        listIcon: {
          ...prev.listIcon,
          [type]: e.target.value
        },
      }))
    }
  }
  
  const addOrRemoveOthersIcon = (key: string, type: string) => {
    if(type === 'add'){
      setDataRegister(prev => ({
        ...prev,
        listIcon: {
          ...prev.listIcon,
          others: (dataRegister?.listIcon?.others || []).concat({
            id: uuidv4(),
            src: '',
            link: ''
          })
        },
      }))
    } else {
      setDataRegister(prev => ({
        ...prev,
        listIcon: {
          ...prev.listIcon,
          others: (dataRegister?.listIcon?.others || []).slice(0, dataRegister?.listIcon?.others?.length - 1)
        },
      }))
    }
   
  }

  const setFileOthersIcon = (f: any, id: string) => {
    setDataRegister(prev => ({
      ...prev,
      listIcon: {
        ...prev.listIcon,
        others: prev?.listIcon.others?.map(el => el.id === id ? {
          ...el,
          file: f,
          isNew: true
        } : el)
      }
    }))
  }

  const handleAccept = () => {
    setopenModalLinkIcon(false)
  }

  const handleCancel = () => {
    setopenModalLinkIcon(false)
    setDataRegister(prev => ({
      ...prev,
      listIcon: {},
    }))
  }

  const handleSubmitRegister = async (value) => {
    try {
      let logo = "";
      let listIconUpload = dataRegister.listIcon.others
      if(dataRegister.file){
        const formData = new FormData();
        formData.append("files", dataRegister.file);
        const img: any = await APIs.UPLOAD.uploadFile({
          body: formData,
        });
        if(img){
          logo = img.filePath
        }
      }
      if(dataRegister.listIcon.others?.length > 0 && dataRegister.listIcon.others?.filter((it) => it.file)?.length > 0){
        const formData = new FormData();
        formData.append("folderPath", `event`);
  
        const statesNew = dataRegister.listIcon.others
          .filter((st) => st.isNew)
          .map((stNew) => {
            formData.append("files", stNew.file);
            return stNew;
          });
       
        const listImg: any = await APIs.UPLOAD.uploadMultiFile({
          body: formData,
        });
        listImg.forEach((el) => {
          statesNew[el.id] = {
            ...statesNew[el.id],
            src: el?.filePath,
          };
        });
  
        listIconUpload = dataRegister.listIcon.others.map((st) => {
          const newStFound = statesNew.find((c) => c.id === st.id);
          if (newStFound) {
            return {
              ...st,
              ...newStFound,
            };
          }
          return st;
        });
  
      }

      const data = {
        id: uuidv4(),
        name: value.fullname,
        email: value.email,
        phone: Number(value.phone),
        registerData: {
          ...dataRegister,
          src: logo,
          listIcon: {
            ...dataRegister.listIcon,
            others: listIconUpload
          }
        },
        serveyId: idSurvey,
      }

      const res = await APIs.EVENT.registerProductEvent({
        params: {
          id: id
        },
        body: data,
      })
      if(res){
        toast.success("Successfull!")
        setOpenModal(false)
      }
    } catch (error) {
      toast.error(error.errors || "Something went wrong!")
    }
  }

  const renderModalFrom = () => {
    return <ContainerRegisterItem>
      <FormInfomation 
        control={control}
        errors={errors}
        setValue={setValue}
      />
      <ContainerImage isEdit={true}>
        <SurveyItem
          isEdit={true}
          item={dataRegister}
          index={index}
          setFile={setFileItem}
          handleAddIconContent={handleAddIconContentItem}
          onChangeDataSurveyBanner={onChangeBanner}
          onBlurText={onChangeInput}
          openTagWindow={openTagWindow}
        />
      </ContainerImage>
    </ContainerRegisterItem>
  }

  return <>
    <SurveyContainer isEdit={isEdit}>
      {isEdit && (
        <>
          <OptionChooseType mt={"-26px"}>
            <FormGroup check>
              <Label check>
                <InputB
                  type="checkbox"
                  checked={type === "image_video"}
                  onChange={(e) => handleChooseTypeSurvey(e.target.checked, index, "image_video")}
                />
								Image/Video
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <InputB
                  type="checkbox"
                  checked={type === "text"}
                  onChange={(e) => handleChooseTypeSurvey(e.target.checked, index, "text")}
                />
								Text
              </Label>
            </FormGroup>
            <Button onClick={() => handleSubSectionSurvey(idSurvey)}>
              <AiOutlineClose size={14} color={'#dc3545'} />
            </Button>
          </OptionChooseType>
          {
            type === "image_video" && <AddOrRemoveChil mt="0px">
              <div
                onClick={() =>
                  handleAddOrRemoveChil("list_survey", "add", index, 'content')
                }
              >
								+
              </div>
              <div
                onClick={() =>
                  handleAddOrRemoveChil("list_survey", "remove", index, 'content')
                }
              >
								-
              </div>
            </AddOrRemoveChil>
          }
        </>
      )}
      {

        type === "image_video" && <>
          {
            tableSurveyDetail.isShow ? <>
              <Table
                bgTable="blue300"
                columns={column}
                dataSource={tableSurveyDetail.data}
              />
            </> :
              content?.length > 0 &&
							content?.map((item: any, i: number) => (
							  <ItemContentSurvey>
							    <ContainerImage isEdit={isEdit} color={color.text} colorTitle={color.title}>
							      {
							        isEdit &&
											<>
											  <AddOrRemoveChil>
											    <div onClick={() => removeChil("list_survey", item.id, index)}>-</div>
											  </AddOrRemoveChil>
											  <MovePosition>
											    {
											      i !== 0 && <Button onClick={() => handleMoveChil(i, 'list_survey', 'upward', index)}>{'<'}</Button>
											    }
											    {
											      i !== content?.length - 1 &&
														<Button onClick={() => handleMoveChil(i, 'list_survey', 'downward', index)}>{'>'}</Button>
											    }
											  </MovePosition>
											</>
							      }
							      <SurveyItem
							        isEdit={isEdit}
							        item={item}
							        index={index}
							        setFile={setFile}
							        handleAddIconContent={handleAddIconContent}
							        onChangeDataSurveyBanner={onChangeDataSurveyBanner}
							        onBlurText={onBlurText}
							        openTagWindow={openTagWindow}
							      />
							      {
							        !isEdit && <ItemChecked className="_container_checked">
							          <FormGroup check>
							            <Label check>
							              <InputB
							                type="checkbox"
							                disabled={!item.checked?.includes(user?._id) && totalLimit(content) === Number(limit)}
							                checked={item.checked?.includes(user?._id)}
							                onChange={(e) => onChangeChecked(e.target.checked, index, item.id, "content")}
							              />
							              {`Vote (${
							               formatNumber((Number(item?.checked?.length) +  Number(item.s || 0)), 0) 
                             || 0}/${formatNumber(totalSurvey(content), 0)})`}
							            </Label>
							            <Label check>
							              <AiFillEye /> {formatNumber((item?.w || 0), 0) }
							            </Label>
							          </FormGroup>
							          <span className="items_checked">
							            <ListIconComponents item={item.listIcon} />
							          </span>
							          <BottomBar
							            width={
							              (Number(item?.checked?.length) + Number(item.s || 0))
                            / totalSurvey(content)
                            * 100
							            }
							            color={color.title}
							          ></BottomBar>
							        </ItemChecked>
							      }
							    </ContainerImage>
							    {
							      (isEdit && user?.role === "ADMIN") && 
                    <AdminAddBonusView>
							        <Input
							          transparent={true}
                        type={"number"}
							          color={"orange"}
                        label={`Bình chọn`}
							          defaultValue={item.s}
							          onBlur={(e) =>
							            onBlurText(e, "list_survey", index, "s", item.id, "content")
							          }
							          placeholder={0}
							        />
                      <Input
							          transparent={true}
                        type={"number"}
							          color={"orange"}
                        label={`Lượt xem`}
							          defaultValue={item.w}
							          onBlur={(e) =>
							            onBlurText(e, "list_survey", index, "w", item.id, "content")
							          }
							          placeholder={0}
							        />
							      </AdminAddBonusView>
							    }
							    {
							      !isEdit && <TitleItemSurvey color={color.text}>{item?.name?.toUpperCase()}</TitleItemSurvey>
							    }
							  </ItemContentSurvey>
							))
          }
        </>
      }
    </SurveyContainer>
    {
      (type === "image_video" && !isEdit) && <SubmitSurveyImage colorText={color.text} color={color.background2} bgColor={color.background2}>
        {
          !tableSurveyDetail.isShow && <Button onClick={() => handleInteractiveOrSurvey(type, "survey")}>Bình chọn</Button>
        }
        <Button onClick={() => handleViewSurveyDetail(content)}>{tableSurveyDetail.isShow ? "Trở về" : "Xem BXH"}</Button>
        <div className="register_product">
          <span onClick={openModalRegister}> Đăng ký tham gia.</span>
        </div>
      </SubmitSurveyImage>
    }
    {
      type === "text" && <ListSurvey>
        {
          isEdit ?
            <h5>Tạo khảo sát:
              <AddOrRemoveChil mt="0px">
                <div
                  onClick={() => handleAddOrRemoveChil("list_survey", "add", index, 'detail')}
                >
									+
                </div>
                <div
                  onClick={() =>
                    handleAddOrRemoveChil("list_survey", "remove", index, 'detail')
                  }
                >
									-
                </div>
              </AddOrRemoveChil>
            </h5> : <h5>Lựa chọn của bạn:</h5>
        }
        {
          detail?.length > 0 && <>
            {
              detail.map((item: any, idx_: number) =>
                isEdit ? <ItemEdit key={item.id}>
                  <Input
                    transparent={true}
                    color={"orange"}
                    label={`Title ${idx_ + 1}`}
                    defaultValue={item.name}
                    onBlur={(e) =>
                      onBlurText(e, "list_survey", index, "name", item.id, "detail")
                    }
                    placeholder="Nhập lựa chọn..."
                  />
                  <FormAddLink
                    index={index}
                    id={item.id}
                    type={"detail"}
                    listLinkIcon={{
                      link: item.link,
                      link_vdiarybook: item.link_vdiarybook,
                      link_zalo: item.link_zalo,
                      link_facebook: item.link_facebook,
                      link_document: item.link_document,
                      others: item.others || []
                    }}
                    onBlurText={onBlurText}
                    handleAddOrRemoveChil={handleAddOrRemoveChil}
                    setFile={setFile}
                  />
                  <hr />
                </ItemEdit> : <ItemSurvey
                  colorTitle={color.title}
                  colorLabel={color.text}
                  bgColor={color.background2}
                >
                  <FormGroup check>
                    <Label check>
                      <InputB
                        type="checkbox"
                        disabled={!item.checked.includes(user?._id) && totalLimit(detail) === Number(limit)}
                        checked={item.checked.includes(user?._id)}
                        onChange={(e) => onChangeChecked(e.target.checked, index, item.id, "detail")}
                      />
                      {item.name}
                    </Label>
                  </FormGroup>
                  <span className="items_checked">
                    <ListIconComponents item={item} />
                    <p>{item.checked.length}</p>
                    <h6>{(item.checked.length / totalSurvey(detail) * 100 || 0).toFixed(2)}%</h6>
                  </span>
                  <BottomBar
                    width={item.checked.length / totalSurvey(detail) * 100}
                    color={color.title}
                  ></BottomBar>
                </ItemSurvey>
              )
            }
            {
              !isEdit && <FooterSurvey bgColor={color.title} color={color.text}>
                <div className="total_survey"><FcSurvey size={24} />{' '}{totalSurvey(detail)}</div>
                <div className={'submit_survey'} >
                  <Button height={"36px"} onClick={() => handleInteractiveOrSurvey(type, "survey")}>Bình chọn</Button>
                </div>
              </FooterSurvey>
            }
          </>
        }
      </ListSurvey>
    }
    {
      isEdit && <OptionChangeLimit>
        <Input
          key={limit}
          transparent={true}
          color={"orange"}
          label={`Limit vote option`}
          defaultValue={Number(limit)}
          onBlur={(e) =>
            onBlurText(e, "list_survey", index, "limit")
          }
          placeholder="Nhập lựa chọn..."
        />
      </OptionChangeLimit>
    }
		 <Modal
      centered={true}
      isOpen={openModal}
      title="Đăng ký sản phẩm"
      children={renderModalFrom()}
      onConfirm={handleSubmit(handleSubmitRegister)}
      onCancel={() => setOpenModal(false)}
    />
    <Modal
      isOpen={openModalLinkIcon}
      title={"Link icon"}
      centered={true}
      onCancel={() => handleCancel()}
      onConfirm={() => handleAccept()}
    >
      <AddLink>
        <FormAddLink
          index={null}
          id={null}
          type={"content"}
          listLinkIcon={dataRegister.listIcon}
          onBlurText={onChangeInput}
          handleAddOrRemoveChil={addOrRemoveOthersIcon}
          setFile={setFileOthersIcon}
        />
      </AddLink>
    </Modal>
  </>
}

export default View