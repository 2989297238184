/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, Row } from "reactstrap";
import { formatNumber, getBase64Image } from "utils/common";
import {
  BotttomLeft,
  Container,
  Content,
  Footer,
  Header,
  HeaderLeft,
  HeaderRight,
  Label,
  Logo,
  PaymentInfo,
  TopRight,
  Total,
  Value,
  LogoVzonex,
  TotalBalance,
} from "../styles";
import Table from "components/table";
import { useEffect, useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import DownloadImg from "components/download/View";
import { useAppSelector } from "redux/reducers/hook";
import { getCryptos } from "redux/reducers/cryptos/cryptos.selector";
import APIs from "api";

const Invoice = () => {
  const cryptos = useAppSelector(getCryptos);
  const { id, type } = useParams();
  const [transaction, setTransaction] = useState(null);
  const [stock, setStock] = useState(null);
  const history = useHistory();
  const imgRef = useRef(null);
  const [logo, setLogo] = useState(null);

  const types = {
    deposit: "DEPOSITE",
    withdraw: "WITHDRAWAL",
    changes: "CHANGES",
  };

  const columns = [
    {
      key: "Logo",
      title: "Logo",
      render: (item: any) => <img className="logo" src={logo} alt="" />,
    },
    {
      key: "From",
      title: "From",
      render: (item: any) => `${item ? item["from"] : "N/A"}`,
    },
    {
      key: "To",
      title: "To",
      render: (item: any) =>
        `${
          item
            ? item["to"].length > 15
              ? item["to"].slice(0, 15) + "..."
              : item["to"]
            : "N/A"
        }`,
    },
    {
      key: "amount",
      title: "Amount",
      render: (item: any) =>
        `${
          item
            ? formatNumber(item.amount) +
              "(" +
              String(item["token"]).toUpperCase() +
              ")"
            : "N/A"
        } `,
    },
    {
      key: "status",
      title: "Status",
      render: (item: any) => `${item ? item["status"] : "N/A"}`,
    },
  ];

  useEffect(() => {
    if (id && type) {
      APIs.TRANSACTION.getTransactionById({
        params: {
          id,
        },
      })
        .then((res: any) => {
          if (res && res.transaction) {
            setTransaction(res.transaction);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [history, id, type]);

  useEffect(() => {
    const getImages = async () => {
      if (stock) {
        const res = await getBase64Image(stock.logo);
        setLogo(res);
      }
    };
    getImages();
  }, [stock]);

  useEffect(() => {
    if (cryptos && transaction) {
      setStock(cryptos.find((c) => c.symbol === transaction.token));
    }
  }, [cryptos, transaction]);

  return (
    <>
      <Container ref={imgRef}>
        <TopRight src="/assets/images/vzx/invoice1.png" />
        <BotttomLeft src="/assets/images/vzx/invoice2.png" />
        <LogoVzonex>
          <img src="/assets/images/vzx/logo.png" alt="" />
        </LogoVzonex>
        {transaction && (
          <>
            <Header>
              <Logo>
                <img src={logo} alt=""></img>
              </Logo>

              <Row>
                <Col xs={6}>
                  <HeaderLeft>
                    <p>Invoice To</p>
                    <h5>{transaction.user_id && transaction.user_id.email}</h5>
                    <p className="desc">
                      Invoice to buy shares of{" "}
                      <span> {stock ? stock.name : "N/A"}</span> project
                    </p>
                  </HeaderLeft>
                </Col>
                <Col xs={6}>
                  <HeaderRight>
                    <h3>{types[type]} - INVOICE </h3>
                    <p>
                      Invoice #{" "}
                      <span>{new Date(transaction.createdAt).getTime()}</span>
                    </p>
                    <p>
                      Date:{" "}
                      <span>
                        {new Date(transaction.createdAt).toLocaleString()}
                      </span>
                    </p>
                  </HeaderRight>
                </Col>
              </Row>
            </Header>

            <Content>
              <Table columns={columns} dataSource={[transaction]}></Table>
            </Content>

            <TotalBalance>
              <Row>
                <Col>
                  <h6>Total Balance Before :</h6>
                </Col>
                <Col>
                  <h6>
                    {(transaction.invoice &&
                      transaction.invoice.balanceBefore[transaction.token]) ||
                      0}{" "}
                    ({String(transaction.token).toUpperCase()})
                  </h6>
                </Col>
              </Row>
            </TotalBalance>

            <TotalBalance>
              <Row>
                <Col>
                  <h6>Total Balance After :</h6>
                </Col>
                <Col>
                  <h6>
                    {transaction.invoice &&
                      transaction.invoice.balanceAfter[transaction.token]}{" "}
                    ({String(transaction.token).toUpperCase()})
                  </h6>
                </Col>
              </Row>
            </TotalBalance>

            <Row>
              <Col>
                <PaymentInfo>
                  <h5>Payment Info</h5>
                  <Row>
                    <Col xs={4}>
                      <Label>Account#</Label>
                      <Label>Stocks</Label>
                    </Col>
                    <Col xs={8}>
                      <Value>{transaction.user_id._id.slice(10)}...</Value>
                      <Value>
                        ({String(transaction.token).toUpperCase()})
                      </Value>{" "}
                    </Col>
                  </Row>
                </PaymentInfo>
              </Col>

              <Col>
                <Total>
                  <Row>
                    <Col>
                      <p>Sub Total</p>
                      <p>Tax</p>
                      <p className="total">Total</p>
                    </Col>
                    <Col>
                      <p>
                        {`${formatNumber(transaction.amount)} (${
                          transaction ? String(transaction.token).toUpperCase() : "N/A"
                        })`}
                      </p>
                      <p>
                        {transaction.fee.amount} (
                        {String(transaction.fee.symbol).toUpperCase()})
                      </p>
                      <p className="total-value">
                        {" "}
                        {`${formatNumber(transaction.amountReceived)} (${
                          transaction
                            ? String(transaction.token).toUpperCase()
                            : "N/A"
                        })`}
                      </p>
                    </Col>
                  </Row>
                </Total>
              </Col>
            </Row>

            <Footer>
              <h3>Thank you for trusting us</h3>
              <p>For any queries related to this document please contact us</p>
              <a>{"support@vzonex.com"}</a>
              <p>
                Address : No. 6 Lot A12 Le Trong Tan KDT - Geleximco, An Khanh Ward, Hoai Duc District, Hanoi City, Vietnam.
              </p>
            </Footer>
          </>
        )}
      </Container>

      {transaction && (
        <DownloadImg
          imgRef={imgRef}
          fileName={`invoice_${type}_${transaction.token}`}
        />
      )}
    </>
  );
};

export default Invoice;
