import styled from "styled-components";

export const WhitePaper = styled.div`
    width: 100%;
    .pg-viewer-wrapper {
      display: block;
      overflow-y: auto !important;

      .pdf-viewer {
        max-width: 100vw;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
      }
    }
    
`;

export const Title = styled.div`
  position: relative;
  margin: 24px;
  text-align: center;

  button {
    position: absolute;
    right: -25px;
    top: -40px;
    height: 30px;
    padding: 0 10px;
  }
`;