import { Container, RedirectWrapper } from "./style";
import Button from "components/button";

const View = () => {
  const token = localStorage.getItem("auth");

  return (
    <Container>
      <img src="/assets/images/trade-screen.png" alt="trade-screen" />
      <RedirectWrapper>
        <Button
          onClick={() =>
            window.open(`https://trade.vzonex.com?token=${token}`, "_blank")
          }
        >
          Go to Trade Page
        </Button>
      </RedirectWrapper>
    </Container>
  );
};

export default View;
