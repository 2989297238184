import UserLayout from "../../layouts/UserLayout";
import Countdown from "components/countdown";
import { Container, CountdownContainer } from "./style";
import { useAppSelector } from "redux/reducers/hook";
import { getComingSoon } from "redux/reducers/settings/settings.selector";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";

const View = () => {
  const coming_soon = useAppSelector(getComingSoon);
  const location = useLocation();
  const { pathname } = location;
  const currentPage = useMemo(
    () => coming_soon?.find((item) => item.url === pathname),
    [pathname, coming_soon]
  );
  
  return (
    <UserLayout>
      <Container>
        <h4>{currentPage?.title} </h4>
        <CountdownContainer>
          <Countdown date={new Date(currentPage?.countdown).getTime()} />
        </CountdownContainer>
        <img src={`${currentPage?.background}`} alt="warning" />
      </Container>
    </UserLayout>
  );
};

export default View;
