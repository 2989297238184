import Button from "components/button";
import Input from "components/input";
import Modal from "components/modal";
import {
  Section,
  ItemContent,
  VideoYT,
  Title,
  Project,
  UpLinkVideoYoutube,
  AddOrRemoveChil,
  Video,
  MovePosition,
  AddLink,
} from "pages/home/landing-page/styles";
import { useState } from "react";

import {
  Row,
  Col,
  Container,
} from "reactstrap";
import { getLinkYoutubeIframe } from "utils/common";
import { convertIdVideo } from "utils/convertIdVideo";

const View = (props) => {
  const {
    videoState,
    data_key,
    title,
    isEdit,
    color,
    isDisable,
    setVideoState,
    onBlurText,
    hasWallpaper
  } = props

  const [isModal, setIsModal] = useState<boolean>(false)
  const [link, setLink] = useState<string>();
  const [indexStateChange, setIndexStateChange] = useState<number>(null)

  const handleAddOrRemoveChil = (type: string) => {
    if (type === "add") {
      setVideoState(
        videoState.concat({
          link: 'Aq6W-nmRO94'
        })
      );
    } else {
      const newArr = videoState.slice(0, videoState.length - 1);
      setVideoState(newArr);
    }
  };

  const removeChil = (index: number) => {
    const newArr = videoState.filter((el: any, idx: number) => idx !== index)
    setVideoState(newArr)
  }

  const handleMoveChil = (index: number, key: string) => {
    const newArr = videoState;
    const el = newArr[index];

    if (key === "upward") {
      newArr[index] = newArr[index - 1]
      newArr[index - 1] = el;
    } else {
      newArr[index] = newArr[index + 1]
      newArr[index + 1] = el;
    }
    setVideoState([...newArr])
  }

  const onChangLink = (e: any) => {
    setLink(e.target.value)
  }

  const handleAccept = () => {
    setVideoState(prev => prev.map((item, index: number) =>
      index === indexStateChange ? { link: convertIdVideo(link) } : item
    ))
    setIsModal(false)
  }

  const handleClickVideo = (link: string, index?: number) => {
    if (!isEdit) {
      window.open(link)
    } else {
      setIsModal(true)
      setIndexStateChange(index)
    }
  }


  return <>
    <Modal
      isOpen={isModal}
      title={"Link video"}
      centered={true}
      onCancel={() => setIsModal(false)}
      onConfirm={() => handleAccept()}
    >
      <AddLink>
        <Input
          transparent={true}
          label="Link"
          onChange={onChangLink}
          placeholder="link..."
        />
      </AddLink>
    </Modal>
    <Section
      id="video"
      bgColor={color.bgColor}
      bgColorDefault={color.bgDefault}
      textColor={color.textColor}
      isDisable={isDisable}
      hasWallpaper={hasWallpaper}
    >
      <Container>
        {isEdit && (
          <AddOrRemoveChil>
            <div onClick={() => handleAddOrRemoveChil("add")}>+</div>
            <div onClick={() => handleAddOrRemoveChil("remove")}>-</div>
          </AddOrRemoveChil>
        )}
        <Title titleColor={color.titleColor}>
          <h3
            data-key={data_key}
            contentEditable={isEdit}
            onBlur={(e) => onBlurText(e, 'video')}
          >
            {title || "Video"}
          </h3>
          <hr />
        </Title>
        <br />
        <Video>
          <Row>
            {videoState?.length > 0 ?
              videoState.map((item: any, index: number) => (
                <Col xs={6} sm={4} md={4} lg={3}>
                  <ItemContent data-aos="flip-left" data-aos-duration="800">
                    {isEdit && <>
                      <AddOrRemoveChil>
                        <div onClick={() => removeChil(index)}>-</div>
                      </AddOrRemoveChil>
                      <MovePosition>
                        {
                          index !== 0 && <Button onClick={() => handleMoveChil(index, 'upward')}>{'<'}</Button>
                        }
                        {
                          index !== videoState.length - 1 && <Button onClick={() => handleMoveChil(index, 'downward')}>{'>'}</Button>
                        }
                      </MovePosition>
                    </>}
                    <Project isVideo={true} pd={"0px"}>
                      <VideoYT
                        isEdit={isEdit}
                        type={"list"}
                        onClick={() =>
                          handleClickVideo(getLinkYoutubeIframe(item.link, true), index)
                        }
                      >
                        {isEdit && (
                          <UpLinkVideoYoutube>
                              Click here to up your link video
                          </UpLinkVideoYoutube>
                        )}
                        {item.link && (
                          <iframe
                            key={item.link}
                            frameBorder="0"
                            allow="accelerometer"
                            allowFullScreen={true}
                            title="YouTube video player"
                            // eslint-disable-next-line max-len
                            src={getLinkYoutubeIframe(item.link)}
                          />
                        )}
                      </VideoYT>
                    </Project>
                  </ItemContent>
                </Col>
              )) : (
                <p className="text-center">No video</p>
              )}
          </Row>
        </Video>
      </Container>
    </Section>
  </>
}

export default View;