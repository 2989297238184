import View from "./View";

import { connect } from "react-redux";

import { getMyBanks, createMyBank } from "redux/reducers/banks/banks.actions";

const mapDispatchToProps = {
  getMyBanks,
  createMyBank
};

export default connect(null, mapDispatchToProps)(View);
