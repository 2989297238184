import { IRootState } from "redux/reducers";

export const getUserProfile = (state: IRootState) => ({
  ...state.users.profile,
  vzxWallet: state.users.vzxWallet,
});
export const getToken = (state: IRootState) => state.users.token;
export const getIsLoggedIn = (state: IRootState) => state.users.isLoggedIn;
export const getBalances = (state: IRootState) => state.users.balances;
export const getLockedBalances = (state: IRootState) =>
  state.users.locked_balances;
export const getCommissions = (state: IRootState) => state.users.commissions;
export const getSharings = (state: IRootState) => state.users.sharings;
export const getVerify = (state: IRootState) => state.users.verify;
export const getSalesStatistics = (state: IRootState) => state.users.sales_statistics;
