import { isMobile } from "react-device-detect";
import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: #061a38;
  min-height: calc(calc(var(--vh, 1vh) * 100) - 108px);
  color: white !important;
  padding: 30px 0;
  p,
  h1,
  h2,
  h3,
  h4,
  span {
    color: white !important;
  }

  @media screen and (max-width: 1100px) {
    min-height: calc(calc(var(--vh, 1vh) * 100) - 86px);
  }
`;

export const ContainerContent = styled.div``;

export const WrapperSupport = styled.div`
  margin-top: 70px;
  background-color: ${(props) => props.theme.colors.black100};
  padding: 14px;
`;

export const FileUpLoad = styled.div`
  position: relative;
  overflow: hidden;
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  margin: 10px;

  img {
    width: 100%;
    height: auto;
  }
`;

export const Submit = styled.div`
  padding: 20px 0;
  button {
    margin: ${isMobile ? "auto" : "10px 0px"};
    background-color: ${props => props.theme.colors.orange100} !important;
    max-width: 200px;
    display: flex;
    justify-content: center;
  }
`;

export const Attachments = styled.div`
  margin-top: 50px;

  h4 {
    font-size: 16px;
  }
  
  span {
  font-size: 14px;
  }
`;

export const AttachmentsFile = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const AddAttachment = styled.div`
  margin-left: 10px;
  height: 100px;
  width: 100px;
  cursor: pointer;
  border: 0.5px dashed grey;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RemoveChil = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999999;
  button {
    height: 20px;
    background-color: ${props => props.theme.colors.orange100} !important;
    border-radius: 4px !important;
    padding: 12px;
  }
`;