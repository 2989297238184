/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import APIs from "api";
import VButton from "components/button";
import VTable from "components/table";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getLinkYoutubeIframe } from "utils/common";
import { BuyWrapper, EmptyText } from "./styles";

const Buy = (props) => {
  const { onCancel, setPixels, pixelId, project } = props;

  const round = project ? (project.rounds || []).find((r) => r.on_sale) : [];

  const [pixel, setPixel] = useState(null);
  const [loading, setLoading] = useState(false);
  const [histories, setHistories] = useState([]);

  useEffect(() => {
    if (pixelId) {
      getPixel();
    }
  }, [pixelId]);

  const getPixel = async (isReset = false) => {
    try {
      const res: any = await APIs.PROJECT.getPixelById({
        params: {
          id: pixelId,
        },
      });
      if (res && res.pixel) {
        setPixel(res.pixel);
        if (pixel && isReset) {
          setPixels((prev) =>
            prev.map((p) => (p._id === pixel._id ? res.pixel : p))
          );
        }
      }
      const res2: any = await APIs.PURCHASE.getPurchaseByPixelId({
        params: {
          id: pixelId,
        },
      });
      if (res2 && res2.purchases) {
        setHistories(res2.purchases);
      }
    } catch (error) {
      toast.error(error?.errors);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await APIs.PURCHASE.buyIBO({
        body: {
          project_id: project._id,
          type_buy: "pixel",
          pixelId: pixel._id,
        },
      });
      getPixel(true);
      setLoading(false);
      toast.success("Buy success!");
    } catch (error) {
      toast.error(error?.errors);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  if (!pixelId) {
    return <EmptyText>Please click the box on the left!</EmptyText>;
  }

  if (!round || !pixel || !pixel.name) {
    return <EmptyText>Coming soon!</EmptyText>;
  }

  const check = (round.pixels || []).includes(pixel._id);

  return (
    <BuyWrapper>
      <p>Name : {pixel.name}</p>
      <p>Description : {pixel.description}</p>
      {pixel.image && <img src={pixel.image} alt="" />}
      {pixel.video && (
        <div className="video">
          <iframe
            width={300}
            frameBorder="0"
            allow="accelerometer"
            allowFullScreen={true}
            title="YouTube video player"
            src={getLinkYoutubeIframe(pixel.video)}
          />
        </div>
      )}

      {pixel?.attachments.length > 0 && (
        <div>
          Attachments
          <br></br>
          {pixel?.attachments.map((a) => (
            <span>
              {" "}
              -{" "}
              <a href={a} target={"_blank"}>
                {a}
              </a>
              <br />
            </span>
          ))}
        </div>
      )}
      <hr />
      <div>
        Price : {round.price} {String(project.stock_to_buy).toUpperCase()}
      </div>
      <hr />
      <VButton
        onClick={handleSubmit}
        color={"green100"}
        disabled={pixel.is_sold || !check}
        loading={loading}
      >
        Buy
      </VButton>
      <VButton onClick={handleCancel} color={"red100"} className={"btn_close"}>
        Close
      </VButton>
      <hr />

      <div>
        <p>User already owns </p>
        <VTable
          columns={[
            {
              key: "User",
              title: "User",
              render: (item: any) =>
                `${item.user_id && item.user_id["refer_code"]}`,
            },
            {
              key: "Date",
              title: "Date",
              render: (item: any) => new Date(item.createdAt).toLocaleString(),
            },
          ]}
          dataSource={histories}
        ></VTable>
      </div>
    </BuyWrapper>
  );
};

export default Buy;
