import { ExpectValueWrapper } from "../styles";
import Select from "react-select";
import { useAppSelector } from "redux/reducers/hook";
import { getStocks } from "redux/reducers/cryptos/cryptos.selector";
import { Col, Row } from "reactstrap";
import {
  Amount,
  ContainButton,
  ContainCard,
  ContainOutput,
  Wrapper,
  SeletedCon,
} from "./styles";
import Input from "components/input";
import Button from "components/button";
import { useState } from "react";
import { formatNumber } from "utils/common";

const View = () => {
  const stocks = useAppSelector(getStocks);

  const defaultState = {
    crypto: "",
    amount: 0,
    price: 0,
    usd: 0,
    vnex: 0,
  };
  const [state, setState] = useState(defaultState);

  const handleCheck = () => {
    if (state.amount > 0 && state.price > 0) {
      const usd = state.amount * state.price;
      const vnex = (usd * 23500) / 1000;
      setState({
        ...state,
        usd: usd,
        vnex: vnex,
      });
    }
  };

  const handleChange = (event, field) => {
    let amount = state.amount;
    let price = state.price;

    if (field === "amount") amount = +event.target.value;
    else price = +event.target.value;

    if (price >= 0 && amount >= 0) {
      const usd = amount * price;
      const vnex = (usd * 23500) / 1000;
      setState({
        ...state,
        usd: usd,
        vnex: vnex,
        [field]: +event.target.value,
      });
    }
  };

  return (
    <Wrapper>
      <Row>
        <Col col xs={12} md={5}>
          <ExpectValueWrapper>
            <SeletedCon>
              <Select
                options={stocks.map((c) => ({
                  label: `${c.name} (${String(c.symbol).toUpperCase()})`,
                  value: c.symbol,
                }))}
                defaultValue={[]}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder={"e.NFT Stocks"}
              />
            </SeletedCon>
          </ExpectValueWrapper>
          <Amount>
            <Input
              label="Amount"
              type="number"
              name="amount"
              value={state.amount}
              onChange={(e) => handleChange(e, "amount")}
            />
            <Input
              label="Price ($)"
              type="number"
              name="price"
              value={state.price}
              onChange={(e) => handleChange(e, "price")}
            />
          </Amount>
        </Col>
        <Col col xs={12} md={2} className="middle">
          <ContainButton>
            <Button children="=>" onClick={handleCheck} />
          </ContainButton>
        </Col>
        <Col col xs={12} md={5} className="output">
          <ContainOutput>
            <ContainCard>
              <span>Total USD: {formatNumber(state?.usd)}</span>
            </ContainCard>
            <ContainCard>
              <span>Total VNEX: {formatNumber(state?.vnex)}</span>
            </ContainCard>
          </ContainOutput>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default View;
