import UserSidebar from "../UserSidebar";
import UserHeader from "../UserHeader";
import Container, { Content, SideBarLayout, ContentScoll } from "./styles";
import Advertisement from "layouts/Advertisements";
import NavBarBottom from "../components/navbar-bottom"
import { isMobile } from "react-device-detect";

export default function View(props) {
  return (
    <Container>
      <Advertisement />
      <SideBarLayout>
        <UserSidebar />
      </SideBarLayout>
      <Content>
        <UserHeader />
        <ContentScoll>{props.children}</ContentScoll>
      </Content>
      {isMobile && <NavBarBottom />}
    </Container>
  );
}
