/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import StoryBooks from "./pages/story-books";
import Home from "./pages/home";
import AuthRoute from "./pages/auth";
import MyRoute from "./pages/my";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ROUTES } from "./config/routes";
import "aos/dist/aos.css";

import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import AOS from "aos";
import CreateEstock from "./pages/create-landing-page";
import BusinessLandingPage from "components/business-landing-page";
import Invoice from "pages/invoice/invoice-ico";
import InvoicePayment from "pages/invoice/invoice-payment";
import CertificateHoldStocks from "pages/certificate/hold-stocks/View";
import WebviewProfit from "pages/web-view/profit";
import {
  getCryptosAsync,
  getStocksPriceAsync,
} from "redux/reducers/cryptos/cryptos.actions";
import {
  getCitiesAsync,
  getObjectsAsync,
  getRateUSDTToVNDAsync,
  getLogoAsync,
  getGroupsAsync,
  getComingSoonAsync,
  getLocationAsync,
} from "redux/reducers/settings/settings.action";
import { setConnectSocket } from "redux/reducers/settings/settings.reducer";
import { getReward } from "redux/reducers/settings/settings.selector";
import Reward from "layouts/Reward";
import Support from "layouts/Support";
import InvoiceInvest from "pages/invoice/invoice-invest";
import InvoiceProduct from "pages/invoice/invoice-product";
import Event from "pages/events";
// import { isDesktop } from "react-device-detect";
import WebviewRound from "pages/web-view/round";
import ComingSoon from "pages/cm";

// import Snowfall from "react-snowfall";

function App() {
  const reward: any = useAppSelector(getReward);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setConnectSocket(true));
  }, [dispatch]);

  useEffect(() => {
    AOS.init({
      duration: 1500,
      delay: 300,
    });
    dispatch(getCryptosAsync());
    dispatch(getStocksPriceAsync());
    dispatch(getCitiesAsync());
    dispatch(getRateUSDTToVNDAsync());
    dispatch(getObjectsAsync());
    dispatch(getLogoAsync());
    dispatch(getGroupsAsync());
    dispatch(getComingSoonAsync());
    dispatch(getLocationAsync());
  }, []);

  //   const snowFallSetting: any = {
  //     color: "#fff",
  //     rotate: 0,
  //     rotationSpeed: [-2, 1],
  //     speed: [0, 0.1],
  //     radius: [20, 30],
  //     snowflakeCount: 15,
  //     icon_url:
  //       "https://file.vdiarybook.com/api/files/users/6460e8b9cc94cad4e2467d65/posts/7be90784-fb3b-4e68-b141-577e2f5f3dea--700.png",
  //   };

  //   const snowFallImages = useMemo(() => {
  //     if (snowFallSetting?.icon_url) {
  //       const snowflake = document.createElement("img");
  //       snowflake.src = snowFallSetting?.icon_url;
  //       return [snowflake];
  //     }

  //     return [];
  //   }, [snowFallSetting]);

  return (
    <div className="App">
      {/* <Snowfall
        rotationSpeed={snowFallSetting.rotationSpeed ?? [-1.0, 1.0]}
        color={snowFallSetting?.color ?? "#fff"}
        speed={snowFallSetting?.speed ?? [1.0, 3.0]}
        radius={snowFallSetting?.radius ?? [0.5, 3.0]}
        snowflakeCount={snowFallSetting?.snowflakeCount ?? 200}
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          zIndex: 1112,
          transform: `rotate(${snowFallSetting?.rotate ?? 0}deg)`,
        }}
        images={snowFallImages}
      /> */}

      {reward && reward && <Reward />}
      <Switch>
        <Route path="/auth" component={AuthRoute} />
        <Route path="/my" component={MyRoute} />
        <Route path="/cm/:id" component={ComingSoon} />
        <Route
          path={ROUTES.LANGDINGPAGE_PROJECT}
          component={BusinessLandingPage}
        />
        <Route
          path={[
            "/create-your-estock/:role/profit/:projectId/:token",
            "/create-your-estock/:role/:symbol",
            "/create-your-estock/:role",
            "/create-your-estock",
          ]}
          component={CreateEstock}
        />
        <Route path={ROUTES.STORY_BOOK} component={StoryBooks} />
        <Route path={ROUTES.INVOICE} component={Invoice} />
        <Route path={ROUTES.INVOICE_PAYMENT} component={InvoicePayment} />
        <Route path={ROUTES.INVOICE_INVEST} component={InvoiceInvest} />
        <Route path={ROUTES.INVOICE_PRODUCT} component={InvoiceProduct} />
        <Route path={ROUTES.WEB_VIEW} component={WebviewProfit} />
        <Route path={ROUTES.WEB_VIEW_ROUND} component={WebviewRound} />
        <Route
          path={ROUTES.CERTIFICATE_HOLD_STOCK}
          component={CertificateHoldStocks}
        />
        <Route path={"/smart"} component={Event} />
        <Route path="/" component={Home} />
      </Switch>
      <Support />
      <ToastContainer position="bottom-right" />
    </div>
  );
}

export default App;
