import { ROUTES } from "config/routes";
import { Route, Switch } from "react-router-dom";

import Event from "./components/event";
import ListEvents from "./list_events";
import withEvent from "HOCs/withEvent";

function View() {
  return (
    <Switch>
      <Route
        path={[
          ROUTES.SMART_EVENTS_CREATE,
          ROUTES.SMART_CHARITY_CREATE,
          ROUTES.SMART_SCHOOLS_CREATE,
          ROUTES.SMART_HOSPITALS_CREATE,
          ROUTES.SMART_POOLS_CREATE,
        ]}
        component={withEvent(Event, { create: true, roles: ["USER", "ADMIN"] })}
      />
      <Route
        path={[
          ROUTES.SMART_EVENTS_DETAIL,
          ROUTES.SMART_CHARITY_DETAIL,
          ROUTES.SMART_SCHOOLS_DETAIL,
          ROUTES.SMART_HOSPITALS_DETAIL,
          ROUTES.SMART_POOLS_DETAIL,
        ]}
        component={withEvent(Event)}
      />
      <Route
        path={[
          ROUTES.SMART_EVENTS,
          ROUTES.SMART_CHARITY,
          ROUTES.SMART_SCHOOLS,
          ROUTES.SMART_HOSPITALS,
          ROUTES.SMART_POOLS,
        ]}
        component={ListEvents}
      />
    </Switch>
  );
}

export default View;
