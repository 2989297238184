import styled from "styled-components";

import { Table } from "reactstrap";

const CustomTable = styled(Table)<{ props: any }>`
  width: ${(props) => (props.props.width ? props.props.width : "100%")};
  background: ${(props) => props.theme.colors[props.props.bgTable]};
  outline: none !important;
  color: white;
  box-shadow: none !important;
  font-size: 14px;
  margin-bottom: 0px;

  thead {
    background-color: ${(props) => props.theme.colors[props.props.bgHeader] || props.theme.colors.grey200};
  }

  td,
  th {
    padding: ${(props) => props.props.tdPadding || "15px"};
    vertical-align: middle !important;
  }
  tr {
    border-color: black;

    &:hover {
      cursor: pointer;
      background-color: ${(props) => props.theme.colors.grey200};
    }
  }
`;

export const TdTable = styled.td`
  color: ${(props) => props.theme.colors[props.color]};

  img {
    width: auto;
    height: 100%;
    max-height: 40px;
  }
`;

export default CustomTable;
