export const ROUTES = {
  HOME: "/",
  STORY_BOOK: "/story-books",
  LOGIN: "/auth/login",
  LOGIN_CALLBACK: "/auth/login/:callback_type",
  REGISTER: "/auth/register",
  REGISTER_CALLBACK: "/auth/register/:callback_type",
  VERIFY_EMAIL: "/auth/verify-email",
  VERIFY_LOGIN: "/auth/verify-login",
  FORGOT_PASSWORD: "/auth/forgot-password",
  RESET_PASSWORD: "/auth/reset-password",
  MY_PROFILE: "/my/profile",
  MY_WALLETS: "/my/wallets",
  MY_SWAP: "/my/swap",
  MY_BY_SELL: "/my/buy-and-sell",
  MY_REFERRALS: "/my/referrals",
  MY_SHOP: "/my/shop",
  MY_IDO: "/my/ico",
  MY_IBO_ROUND: "/my/ico/:projectId",
  MY_IBO: "/my/ibo-project",
  MY_TASK_DETAIL: "/my/task/detail/:id",
  MY_TASK: "/my/task",
  VZONE_BANK: "/my/vzone-bank",
  BORROW_LEND: "/my/borrow-lend",
  BLOCKCHAIN: "/my/blockchain-genealogy",
  PETAVERSE: "/my/petaverse",
  ADVERTISEMENT: "/my/advertisement",
  INVEST: "/my/invests",
  REWARD: "/my/rewards",
  CHANNEL: "/my/channel",
  SALESTATISTICS: "/my/sales-statistics",
  QA: "/my/qa",
  CAMPAIGN: "/my/campaign",
  CAMPAIGNDETAIL: "/my/campaign/:campaignId",
  SUPPORTS: "/my/supports",
  DASHBOARD: "/my/dashboard",
  PREVIEW_CHANNEL: "/my/preview-channel/:id",

  //profit
  PROFIT: "/my/ibo-project/:projectId/edit",
  ROUND: "/my/ibo-project/:projectId/round",

  // wallets routes
  DEPOSIT_VND: "/my/wallets/vnex/deposit",
  WITHDRAW_VND: "/my/wallets/vnex/withdraw",
  DEPOSIT_VNP: "/my/wallets/vnp/deposit",
  DEPOSIT_STOCK: "/my/wallets/:stock/deposit",
  WITHDRAW_STOCK: "/my/wallets/:stock/withdraw/",
  DEPOSIT_P2P: "/my/wallets/:stock/deposit/p2p",
  WITHDRAW_P2P: "/my/wallets/:stock/withdraw/p2p",
  DEPOSIT_SPOT: "/my/wallets/:stock/deposit/spot",
  WITHDRAW_SPOT: "/my/wallets/:stock/withdraw/spot",
  WITHDRAW_CV: "/my/wallets/:stock/withdraw/cv",
  WITHDRAW_QRMART: "/my/wallets/:stock/withdraw/qr-mart",
  WITHDRAW_VDIARYBOOK: "/my/wallets/:stock/withdraw/tiepthiso",
  HISTORY_STOCK: "/my/wallets/:stock/history",
  SELL_STOCK: "/my/wallets/:stock/sell",
  TRANSFER: "/my/wallets/:stock/transfer",

  SETTING: "/my/setting",

  INVOICE_PAYMENT: "/invoice/transactions/:type/:id",
  INVOICE: "/invoice/ico/:project/:id",
  INVOICE_INVEST: "/invoice/investment/:package/:id",
  INVOICE_PRODUCT: "/invoice/order/:product/:id",
  CERTIFICATE_HOLD_STOCK: "/certificate/:projectId",

  //web view
  WEB_VIEW: "/web-view/project-share-profit/:projectId",
  WEB_VIEW_ROUND: "/web-view/project-round/:projectId",

  //landingpage project
  LANGDINGPAGE_PROJECT: [
    "/personal/:symbol",
    "/business/:symbol",
    "/organization/:symbol",
    "/student/:symbol",
  ],
  LANGDINGPAGE_PROJECT_WHITEPAPER: [
    "/personal/:symbol/whitepaper",
    "/business/:symbol/whitepaper",
    "/organization/:symbol/whitepaper",
  ],
  LANGDINGPAGE_PROJECT_TERMS: [
    "/personal/:symbol/terms",
    "/business/:symbol/terms",
    "/organization/:symbol/terms",
  ],

  // smart things
  SMART_EVENTS: "/smart/events",
  SMART_EVENTS_DETAIL: "/smart/events/:id",
  SMART_EVENTS_CREATE: "/smart/events/create",
  SMART_EVENTS_MY_LIST: "/my/smart/events",

  SMART_SCHOOLS: "/smart/schools",
  SMART_SCHOOLS_DETAIL: "/smart/schools/:id",
  SMART_SCHOOLS_CREATE: "/smart/schools/create",
  SMART_SCHOOLS_MY_LIST: "/my/smart/schools",

  SMART_POOLS: "/smart/polls",
  SMART_POOLS_DETAIL: "/smart/polls/:id",
  SMART_POOLS_CREATE: "/smart/polls/create",
  SMART_POOLS_MY_LIST: "/my/smart/polls",

  SMART_HOSPITALS: "/smart/hospitals",
  SMART_HOSPITALS_DETAIL: "/smart/hospitals/:id",
  SMART_HOSPITALS_CREATE: "/smart/hospitals/create",
  SMART_HOSPITALS_MY_LIST: "/my/smart/hospitals",

  SMART_CHARITY: "/smart/charity",
  SMART_CHARITY_DETAIL: "/smart/charity/:id",
  SMART_CHARITY_CREATE: "/smart/charity/create",
  SMART_CHARITY_MY_LIST: "/my/smart/charity",
};
