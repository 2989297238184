import React from 'react'
import { Route, Switch } from 'react-router-dom';
import Login from "./login"
import Register from "./register"
import VerifyEmail from "./verify-email"
import VerifyLogin from "./verify-login"
import ForgotPassword from "./forgot-password"
import ResetPassword from "./reset-password"

import AuthLayout from "layouts/AuthLayout";
import { ROUTES } from 'config/routes';

function MyRoute() {
  return (
    <AuthLayout>
      <Switch>
        <Route path={[ROUTES.LOGIN_CALLBACK, ROUTES.LOGIN]} component={Login} />
        <Route path={[ROUTES.REGISTER_CALLBACK, ROUTES.REGISTER]} component={Register} />
        <Route path={ROUTES.VERIFY_EMAIL} component={VerifyEmail} />
        <Route path={ROUTES.VERIFY_LOGIN} component={VerifyLogin} />
        <Route path={ROUTES.FORGOT_PASSWORD} component={ForgotPassword} />
        <Route path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
      </Switch>
    </AuthLayout>
  );
}

export default MyRoute;
