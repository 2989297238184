/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import Button from "components/button";
import { ButtonPreview, ChannelWrapper, Header, RowItem } from "./styles";
import { Container, Row, Input } from "reactstrap";
import { useEffect, useState } from "react";
import { TitleWrapper } from "./styles";
import { BiTrash } from "react-icons/bi";
import { editUser, getUser } from "redux/reducers/users/users.reducer";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { getUserProfile } from "redux/reducers/users/users.selector";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const View = () => {
  const profile = useAppSelector(getUserProfile);
  const dispatch = useAppDispatch();

  const [data, setData] = useState([]);

  const handleAdd = () => {
    setData((data) => [
      ...data,
      {
        id: "",
        prioritized: false,
      },
    ]);
  };

  const onInputCheckChange = (e, index) => {
    const { checked } = e.target || false;
    const _data = [...data].map((item, i) =>
      i === index
        ? {
            ...item,
            prioritized: checked,
          }
        : item
    );

    setData(_data);
  };

  const onChangeHandler = (value, index) => {
    const arr = data.map((item, i) =>
      i === index
        ? {
            ...item,
            id: value,
          }
        : item
    );
    setData(arr);
  };

  const handleDeleteIndex = (index) => {
    const newState = data.filter((item, i) => i !== index);
    setData(newState);
  };

  const handleSubmit = async () => {
    const user: any = await dispatch(
      editUser({
        params: {
          id: profile._id,
        },
        body: {
          channels: data,
        },
      })
    );

    if (user) {
      const data: any = await dispatch(getUser());
      setData(data?.payload?.user?.channels);
      toast.success("Update Success");
    }
  };

  useEffect(() => {
    if (profile) {
      const { channels } = profile;
      if (channels?.length) {
        setData(channels);
      } else {
        setData([
          {
            id: "",
            prioritized: false,
          },
        ]);
      }
    }
  }, [profile]);

  return (
    <ChannelWrapper>
      <Header>
        <p className="heading">Setting My Channel</p>
        <ButtonPreview>
          <Link to={`/my/preview-channel/${profile._id}`}>
            <Button>Preview</Button>
          </Link>
        </ButtonPreview>
      </Header>
      <>
        <Container>
          <Row>
            <TitleWrapper>
              <div className="id">ID</div>
              <div className="prioritized">Prioritized</div>
            </TitleWrapper>
          </Row>
          <Row>
            {data.map((item, index) => (
              <RowItem key={index}>
                <div className="input-id">
                  <Input
                    placeholder="ID"
                    value={item.id}
                    onChange={(e) => onChangeHandler(e.target.value, index)}
                  />
                </div>
                <div className="input-prior">
                  <Input
                    type="checkbox"
                    checked={item.prioritized}
                    onChange={(e) => onInputCheckChange(e, index)}
                  />
                </div>
                <div className="btn-delete">
                  <button onClick={() => handleDeleteIndex(index)}>
                    <BiTrash />
                  </button>
                </div>
              </RowItem>
            ))}
          </Row>
          <Row>
            <RowItem>
              <Button color="green100" onClick={handleAdd}>
                Add Channel
              </Button>
              <Button onClick={handleSubmit}>Submit</Button>
            </RowItem>
          </Row>
        </Container>
      </>
    </ChannelWrapper>
  );
};

export default View;
