/* eslint-disable no-useless-concat */
/* eslint-disable indent */
import { Col, Row } from "reactstrap";
import { renderAmount } from "utils/render";
import {
  Amount,
  Content,
  Detail,
  DetailRightView,
  Status,
  Title,
} from "../../styles";
import { BsFillInfoCircleFill } from "react-icons/bs";
import ToolTip from "components/tooltip/View";

const View = (props: any) => {
  const renderNote = (el) => {
    return <span>{el.note || ""}</span>;
  };

  return (
    <DetailRightView paddingTop={"20px"}>
      <Title bgColor={"grey200"} just="space-between">
        <p> {props.type === "deposit" ? "Deposit" : "Withdraw"} VNEX history</p>
      </Title>
      {props.lists.length &&
        props.lists.map((el: any, index: number) => (
          <Content key={index}>
            <Row lg={12}>
              <Col xs={8} sm={6}>
                <Detail>
                  <h6>Reference: {el.content}</h6>
                  <h6>{new Date(el.updatedAt).toLocaleString()}</h6>
                </Detail>
              </Col>
              <Col xs={4} sm={6}>
                <Amount>
                  {props.type === "withdraw" ? (
                    <h6>{renderAmount(-el.amount)} VNEX</h6>
                  ) : (
                    <h6> {renderAmount(el.amount_received)} VNEX</h6>
                  )}
                  <Status
                    color={
                      el.status === "pending"
                        ? "yellow100"
                        : el.status === "success"
                        ? "green100"
                        : "red100"
                    }
                  >
                    {el.status === "error" && (
                      <ToolTip id={`${el._id}_statis`} content={renderNote(el)}>
                        <span
                          id={`tooltip-` + `${el._id}_statis`}
                          className="gift"
                        >
                          <BsFillInfoCircleFill color="yellow" />
                        </span>
                      </ToolTip>
                    )}
                    <span>{el.status}</span>
                  </Status>
                </Amount>
              </Col>
            </Row>
          </Content>
        ))}
    </DetailRightView>
  );
};

export default View;
