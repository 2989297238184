import React from "react";

import "./_marquee.css";

export default function View({ size, children }) {
  // v = S/t
  const t = (size * 120) / 50;

  return (
    <div className="marqueex">
      <div
        className="marqueex-wrapper"
        style={{
          animation: `marqueex ${t}s linear infinite`,
        }}
      >
        <div className="marqueex-children">{children}</div>
        <div className="marqueex-children">{children}</div>
      </div>
    </div>
  );
}
