import {
  SettingColor,
  ItemColor,
  Detail,
  // BoxColor,
  OptionDelSection,
} from "./styles";

import { Upload } from "pages/home/landing-page/styles";

import UploadFile from "components/uploadFile";

import { Label, FormGroup, Input as InputB } from "reactstrap";

import { AiOutlineSetting } from "react-icons/ai";
import { useEffect } from "react";

const View = (props) => {
  const {
    managersSections,
    openSettingColor,
    optionSetting,
    color,
    setOpenSettingColor,
    onChangeColor,
    optionSection,
    setOptionSection,
    setWallpaper,
    setWallpaperVideo,
    setWallpaperLinkClick,
    dataEvent,
    setTimeSetting,
    timeSetting,
    dataProject,
  } = props;

  const setIsShowSection = (checked, section) => {
    setOptionSection((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        isShow: checked,
      },
    }));
  };

  const onChangeTimes = (type: string, value: any) => {
    setTimeSetting({
      ...timeSetting,
      [type]: value,
    });
  };

  useEffect(() => {
    if (dataProject) {
      setTimeSetting({
        ...timeSetting,
        start: dataProject?.keep_time?.ido_open,
        end: dataProject?.keep_time?.ido_close,
      });
    }
  }, [dataProject]);

  const wallpaperVideo = dataEvent?.wallpaperVideo;

  return (
    <>
      <SettingColor isActive={openSettingColor}>
        <div
          className="_setting_color"
          onClick={() => setOpenSettingColor(!openSettingColor)}
        >
          <AiOutlineSetting size={24} />
        </div>
        <Detail>
          {optionSetting &&
            optionSetting.map((item) => (
              <div className="mb-3">
                <ItemColor key={item.key}>
                  <div className="d-flex flex-column w-50 me-2 gap-2">
                    {/* <BoxColor color={color[item.key]}> */}
                    <input
                      type="color"
                      value={color[item.key]}
                      onChange={(e) => {
                        onChangeColor(e, item.key);
                      }}
                    />
                    {/* </BoxColor> */}
                    <input
                      type="text"
                      placeholder="Input color..."
                      value={color[item.key]}
                      onChange={(e) => onChangeColor(e, item.key)}
                    />
                  </div>
                  <p>{item.title}</p>
                </ItemColor>
              </div>
            ))}
          {optionSection &&
            Object.keys(optionSection).map((item, idx) => (
              <OptionDelSection
                key={idx}
                hidden={
                  !managersSections?.includes(item) &&
                  !managersSections.includes("all")
                }
              >
                <FormGroup check>
                  <Label check>
                    <InputB
                      type="checkbox"
                      checked={optionSection[item].isShow}
                      onChange={(e) => setIsShowSection(e.target.checked, item)}
                    />
                    {optionSection[item].title}
                  </Label>
                </FormGroup>
              </OptionDelSection>
            ))}

          {(managersSections.includes("wallpaper") ||
            managersSections.includes("all")) && (
            <div className="d-flex flex-column gap-2">
              <div
                style={{
                  position: "relative",
                  width: "150px",
                  marginTop: "10px",
                }}
              >
                <p>Ảnh nền VIP</p>
                <Upload>
                  <UploadFile
                    id={"event-wallpaper-id"}
                    ratio="100x100"
                    sectionName="companions"
                    // file={item.file}
                    // src={item.src}
                    setFile={(f: any) => {
                      setWallpaper(f);
                    }}
                  />
                </Upload>
              </div>
              <div>
                <input
                  name="backgroundOpacity"
                  type={"number"}
                  min={0}
                  max={1}
                  step={0.1}
                  placeholder="Độ mờ nền"
                  style={{
                    width: "174px",
                  }}
                  value={color?.backgroundOpacity}
                  onChange={(e) => onChangeColor(e, "backgroundOpacity")}
                />
              </div>
              <div>
                {wallpaperVideo ? (
                  <input
                    placeholder="Video nền VIP"
                    onBlur={(e) => setWallpaperVideo(e.target.value)}
                    defaultValue={wallpaperVideo}
                  />
                ) : (
                  <input
                    placeholder="Video nền VIP"
                    onBlur={(e) => setWallpaperVideo(e.target.value)}
                  />
                )}
              </div>
              <div>
                <input
                  placeholder="Link nền click vào"
                  onBlur={(e) => setWallpaperLinkClick(e.target.value)}
                />
              </div>
              <div className="d-flex gap-2">
                <input
                  type="date"
                  id="times_start"
                  value={timeSetting?.start}
                  onChange={(e) => onChangeTimes("start", e.target.value)}
                />
                <label htmlFor="times_start">Times start</label>
              </div>
              <div className="d-flex gap-2">
                <input
                  type="date"
                  id="times_end"
                  value={timeSetting?.end}
                  onChange={(e) => onChangeTimes("end", e.target.value)}
                />
                <label htmlFor="times_end">Times end</label>
              </div>
            </div>
          )}
        </Detail>
      </SettingColor>
    </>
  );
};

export default View;
