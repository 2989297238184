import styled from "styled-components";

const Wrapper = styled.div``;

export const Content = styled.div`
  padding: 15px;
  align-items: center;
  border-bottom: 1px solid black;
  input {
    width: 100%;
    border-color: white;
    margin-bottom: 0;
    margin-top: 0!important;
  }
  .action {
    right: 10px;
    color: ${(props) => props.theme.colors["yellow100"]};
    background-color: transparent;
    border: unset;
    font-weight: 700;
  }
  .details {
    font-size: 12px;
  }
  button {
    top: 25px;
  }
  select {
    width: 100%;
    height: 45px;
    background: transparent;
    color: white;
    border-radius: 5px;
    border-color: white;
  }
`;

export default Wrapper;
