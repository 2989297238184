import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "api";

export const getNotificationsClientAsync = createAsyncThunk(
  "notifications/list/client",
  async () => {
    try {
      const response: any = await API.NOTIFICATION.getNotificationsClient({});
      return response;
    } catch (error) {
      return error;
    }
  }
);


export const getNotificationsOwnerAsync = createAsyncThunk(
  "notifications/list/owner",
  async () => {
    try {
      const response: any = await API.NOTIFICATION.getNotificationsOwner({});
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getNotificationsSystemAsync = createAsyncThunk(
  "notifications/list/system",
  async () => {
    try {
      const response: any = await API.NOTIFICATION.getNotificationsSystem({});
      return response;
    } catch (error) {
      return error;
    }
  }
);
