import UserHeaderWapper, { BalancesWrapper } from "./styles";
import { TopMain } from "pages/home/styles";
import { useAppSelector } from "redux/reducers/hook";
import { getBalances } from "redux/reducers/users/users.selector";
import { formatCurrencyByType } from "utils/common";
import TopHeader from "layouts/components/top-header";
import ProjectAdvertisement from "layouts/ProjectAdvertisement";
import { isDesktop, isMobile } from "react-device-detect";
import Notification from "layouts/Notification";
import { FaBalanceScaleLeft, FaExchangeAlt } from "react-icons/fa";
import { getStocksPrice } from "redux/reducers/cryptos/cryptos.selector";

export default function View() {
  const balances = useAppSelector(getBalances);
  const prices = useAppSelector(getStocksPrice);

  return (
    <>
      <UserHeaderWapper>
        {isDesktop && <TopHeader />}
        <BalancesWrapper>
          <div className="balances">
            <FaBalanceScaleLeft />{" "}
            <span>
              {" "}
              {formatCurrencyByType(
                +balances["total_vnd"] || 0,
                "vi-VN",
                "VND"
              )}{" "}
            </span>{" "}
          </div>
          <div className="exchanges">
            <span>1</span> VNEX <FaExchangeAlt />
            <span> {formatCurrencyByType(prices && prices['vnex'] ? prices['vnex']['vnd'] : 0, "vi-VN", "VND", 0)}</span>{" "}
          </div>
          <Notification />
        </BalancesWrapper>
      </UserHeaderWapper>
      <br />
      <TopMain>
        {isMobile && <TopHeader />}
        <ProjectAdvertisement />
      </TopMain>
    </>
  );
}
