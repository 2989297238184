/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
// import { isMobile } from "react-device-detect";
import APIs from "api";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import {
  PixelItem,
  PixelItems,
  PixelItemsWapper,
  PixelWrapper,
  ProjectInfo,
  RoundWrapper,
  Wrapper,
} from "./styles";
import { BsImage, BsTree, BsZoomIn, BsZoomOut } from "react-icons/bs";
import { MdOutlineApartment } from "react-icons/md";
import { BiLandscape, BiReset } from "react-icons/bi";
import { TbBrandProducthunt } from "react-icons/tb";
import { useParams, useHistory } from "react-router-dom";
import Buy from "./Buy";
import {
  TransformComponent,
  TransformWrapper,
} from "@kokarn/react-zoom-pan-pinch";
import VButton from "components/button";

const Rounds = () => {
  //   const project = useAppSelector(getProjectSelected);

  const { projectId } = useParams();
  const history = useHistory();

  const [project, setProject] = useState(null);
  const [pixels, setPixels] = useState([]);
  const [pixel, setPixel] = useState(null);
  const [loading, setLoading] = useState(false);

  const round = project ? (project.rounds || []).find((r) => r.on_sale) : [];

  const iconDefault = {
    tree: <BsTree />,
    apartment: <MdOutlineApartment />,
    land: <BiLandscape />,
    image: <BsImage />,
    product: <TbBrandProducthunt />,
  };

  useEffect(() => {
    if (projectId) getProject();
  }, [projectId]);

  useEffect(() => {
    if (project && project._id) getPixels();
  }, [project]);

  const getProject = async () => {
    try {
      const res: any = await APIs.PROJECT.getProjectById({
        params: {
          id: projectId,
        },
      });
      if (res && res.project) setProject(res.project);
    } catch (error) {
      history.push("/my/ico");
    }
  };

  const getPixels = async () => {
    try {
      setLoading(true);
      const res: any = await APIs.PROJECT.getPixelsByProjectId({
        params: {
          projectId: project._id,
        },
      });
      if (res) {
        setPixels(res.pixels);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleClickItem = (pixel) => (event) => {
    if (event.detail === 2) setPixel(pixel);
  };

  const getClassPixelItem = (_pixel) => {
    const check = (round.pixels || []).includes(_pixel._id);
    // if(_pixel._id === pixel._id)  return "active";
    if (round && check) {
      if (_pixel.is_sold) return "pixel-sold";
      else return "";
    } else return "disable-pixel";
  };

  const renderRound = (round) => {
    const _pixels = pixels.filter((p) => (round.pixels || []).includes(p._id));
    return (
      <div className="round-details">
        <span>{round.name}</span>
        {_pixels
          .filter((p) => p.image)
          .map((p) => (
            <img src={p.image} />
          ))}
        <hr />
      </div>
    );
  };

  return (
    <Wrapper>
      <PixelWrapper>
        <Container>
          <ProjectInfo>
            <h3>{project && project?.name}</h3>
          </ProjectInfo>
          <Row>
            <Col xs={12} lg={8}>
              {loading ? (
                <div>Loading...</div>
              ) : (
                <TransformWrapper
                  initialScale={1}
                  initialPositionX={0}
                  initialPositionY={0}
                >
                  {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <>
                      <div className="action-zoom">
                        <VButton height={"36px"} onClick={() => zoomIn()}>
                          <BsZoomIn />
                        </VButton>
                        <VButton height={"36px"} onClick={() => zoomOut()}>
                          <BsZoomOut />
                        </VButton>
                        <VButton
                          height={"36px"}
                          onClick={(e: any) => resetTransform()}
                        >
                          <BiReset />
                        </VButton>
                      </div>
                      <TransformComponent>
                        <PixelItemsWapper>
                          <PixelItems>
                            {pixels.map((product, index) => (
                              <PixelItem
                                key={index}
                                className={getClassPixelItem(product)}
                                onClick={handleClickItem(product)}
                              >
                                {product.image ? (
                                  <img src={product.image} alt="" />
                                ) : project.logo_pixel ? (
                                  <img src={project.logo_pixel} alt="" />
                                ) : (
                                  iconDefault[project.type_product]
                                )}
                              </PixelItem>
                            ))}
                          </PixelItems>
                        </PixelItemsWapper>
                      </TransformComponent>
                    </>
                  )}
                </TransformWrapper>
              )}

              <RoundWrapper>
                {(project?.rounds || []).map((round) => renderRound(round))}
              </RoundWrapper>
            </Col>
            <Col xs={12} lg={4}>
              <Buy
                setPixels={setPixels}
                pixelId={pixel?._id}
                onCancel={(_) => setPixel(null)}
                project={project}
              />
            </Col>
          </Row>
        </Container>
      </PixelWrapper>
    </Wrapper>
  );
};

export default Rounds;
