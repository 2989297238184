import styled from "styled-components";

const Wrapper = styled.div`
  overflow: auto;
`;

export const Tabs = styled.div`
  display: flex;
`;

export const Tab = styled.p<{ active: boolean; color?: string }>`
  font-size: 15px;
  margin-right: 20px;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.active ? `2px solid ${props.color || "white"}` : ""};
  font-weight: ${(props) => (props.active ? "bold" : "500")};
  opacity: ${(props) => (props.active ? 1 : 0.7)};
`;

export default Wrapper;
