import styled from "styled-components";

export const TextProfile = styled.span`
	font-weight: bold;
	margin-left: 8px;
`;

export const ItemProfile = styled.div`
	display: flex;
`;

export const ProfileDeposit = styled.div`
	margin-bottom: 10px;
	padding: 0 15px;
`;