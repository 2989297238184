import styled from "styled-components";

export const Wrapper = styled.div`
	position: fixed;
	right: 0;
	z-index: 999;
	bottom: 100px;
	width: 100px;
`;

export const Container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	justify-content: flex-end;
	align-items: center;

	input {
		height: 24px;
	}
`;

export const ItemIcon = styled.div`
	position: relative;
  width: 60px;
  height: 60px;
  margin: 4px;
  z-index: 999;

	img {
		width: 100%;
		height: auto;
	}
`;

export const AddIcon = styled.div`
	padding-top: 15px;
	div {
    display: inline;
    padding: 3px 15px;
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.orange100};
    margin-right: 10px;
    cursor: pointer;
  }
`;