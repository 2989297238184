import {
  Container,
  ContainerVisit,
  Fee,
  SelectWrapper,
  Upload,
  WrapperForm,
} from "./styles";
import Button from "components/button";
import Modal from "components/modal";
import Table from "components/table";
import Input from "components/input";
import { useEffect, useState } from "react";
import UploadFile from "components/uploadFile";
import APIs from "api";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { getNotificationsOwnerAsync } from "redux/reducers/notifications/notifications.actions";
import { getProjectsOnwer } from "redux/reducers/projects/projects.selector";
import { getProjectsOwnerAsync } from "redux/reducers/projects/projects.action";
import {
  getAccessNotifications,
  getNotificationsOwner,
} from "redux/reducers/notifications/notifications.selectors";
import { FaBell } from "react-icons/fa";
import NotAuth from "components/not_auth";
import Select from "react-select";

const columns = [
  {
    key: "Icon",
    title: "Icon",
    render: (item) => <img src={item.icon} alt="" width={10} />,
  },
  {
    key: "Banner",
    title: "Banner",
    render: (item) => <img src={item.banner} alt="" width={10} />,
  },
  {
    key: "To",
    title: "To",
    render: (item) => (item.to ? item.to.email : "N/A"),
  },
  {
    key: "Title",
    title: "title",
    dataIndex: "title",
  },
  {
    key: "content",
    title: "Content",
    dataIndex: "content",
  },
  {
    key: "Date",
    title: "Date",
    dataIndex: "createdAt",
  },
];

const fee = {
  amount: 0,
  stock: "vnex",
};

const View = () => {
  const projects = useAppSelector(getProjectsOnwer);
  const access = useAppSelector(getAccessNotifications);
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState(false);
  const notifications = useAppSelector(getNotificationsOwner);
  const defaultState = {
    projectId: "",
    title: "",
    link: "",
    content: "",
  };
  const [form, setForm] = useState(defaultState);
  const [file, setFile] = useState(null);
  const [banner, setBanner] = useState(null);
  const [loading, setLoading] = useState(false);
  const [buyers, setBuyers] = useState([]);
  const [users, setUsers] = useState([]);

  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let bannerImg;
      let icon;
      if (file) {
        const formData = new FormData();
        formData.append("files", file);
        icon = await APIs.UPLOAD.uploadFile({
          body: formData,
        });
      }

      if (banner) {
        const formData = new FormData();
        formData.append("files", banner);
        bannerImg = await APIs.UPLOAD.uploadFile({
          body: formData,
        });
      }

      await APIs.NOTIFICATION.createNotification({
        body: {
          ...form,
          icon: icon.filePath,
          banner: bannerImg.filePath,
          users: users.map((u) => u.value),
        },
      });
      setLoading(false);
      setOpenModal(false);
      setForm(defaultState);
      setFile(null);
      dispatch(getNotificationsOwnerAsync());
      toast.success("Success!");
    } catch (error) {
      toast.error(error.errors);
      setLoading(false);
    }
  };

  const renderModal = () => {
    return (
      <Modal
        isOpen={openModal}
        centered={true}
        onCancel={() => setOpenModal(false)}
      >
        <WrapperForm>
          <h3>NOTIFICATION</h3>
          <Fee>
            Fee :
            <span>
              {" "}
              {fee.amount} ({fee.stock})
            </span>
          </Fee>

          <ContainerVisit>
            <Upload>
              <UploadFile
                id="icon"
                title="Icon"
                ratio="100x100"
                typeUpload="image"
                file={file}
                setFile={(f) => setFile(f)}
              />
            </Upload>
          </ContainerVisit>

          <ContainerVisit>
            <Upload>
              <UploadFile
                id="banner"
                title="Banner"
                ratio="220x350"
                typeUpload="image"
                file={banner}
                setFile={(f) => setBanner(f)}
              />
            </Upload>
          </ContainerVisit>

          <SelectWrapper>
            <label>Project</label>
            <Select
              options={projects.map((p) => ({
                label: `${p.name} `,
                value: p._id,
              }))}
              onChange={(e: any) => {
                setBuyers(projects.find((p) => p._id === e.value).buyers);
                handleChange({
                  target: {
                    name: "projectId",
                    value: e.value,
                  },
                });
              }}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </SelectWrapper>

          <SelectWrapper>
            <label>Users</label>
            <Select
              options={buyers.map((c) => ({
                label: `${c.email} `,
                value: c._id,
              }))}
              defaultValue={[]}
              isMulti
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e: any) => setUsers(e)}
            />
          </SelectWrapper>

          <Input
            transparent={true}
            placeholder="..."
            label="Title"
            name="title"
            value={form.title}
            onChange={handleChange}
          />

          <Input
            transparent={true}
            placeholder="..."
            label="Content"
            name="content"
            value={form.content}
            type="textarea"
            height={150}
            onChange={handleChange}
          />

          <Input
            transparent={true}
            placeholder="https://vzonex.com/"
            label="Link"
            name="link"
            value={form.link}
            onChange={handleChange}
          />

          <Button loading={loading} onClick={handleSubmit}>
            Submit
          </Button>
        </WrapperForm>
      </Modal>
    );
  };

  useEffect(() => {
    dispatch(getNotificationsOwnerAsync());
    dispatch(getProjectsOwnerAsync());
  }, [dispatch]);

  return !access ? (
    <NotAuth />
  ) : (
    <Container>
      {renderModal()}
      <Button onClick={() => setOpenModal(true)}>
        + <FaBell color="white" size={20} />
      </Button>

      <Table columns={columns} dataSource={notifications}></Table>
    </Container>
  );
};

export default View;
