import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Collapse } from "reactstrap";
import { MenuItem } from "./styles";

const SubMenu = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const toggle = () => setCollapsed(!collapsed);
  const { title, items, selectedMenu, openMenu, clickMenu, isOpen } = props;

  useEffect(() => {
    if (isOpen) {
      setCollapsed(true);
    } else if (
      items &&
      selectedMenu &&
      items.some((i) => i.path === selectedMenu)
    ) {
      setCollapsed(true);
    }
  }, [items, selectedMenu, isOpen]);

  return (
    <div className="submenu">
      <div
        onClick={toggle}
        className={`submenu-title ${(collapsed || !openMenu) && "menu-open"}`}
      >
        {openMenu ? <div className="dropdown-toggle">{title}</div> : "-----"}
      </div>
      <Collapse
        isOpen={openMenu ? collapsed : true}
        navbar
        className={classNames("items-menu", { "mb-1": !collapsed })}
      >
        {items.map((menu, index) => (
          <MenuItem
            key={menu.path}
            {...{ selected: menu.path === selectedMenu }}
            onClick={menu.disabled ? () => {} : clickMenu(menu)}
            disabled={menu.disabled || false}
          >
            {menu.icon}
            {openMenu && <span className="name">{menu.name}</span>}
          </MenuItem>
        ))}
      </Collapse>
    </div>
  );
};

export default SubMenu;
