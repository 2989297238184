import { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";

import Wrapper, {
  CloseIcon,
  DropdownCarret,
  DropdownWrapper,
  Scroll,
} from "./styles";
import { Label } from "../input/styles";
import { RiCloseLine } from "react-icons/ri";

interface IProps {
  [key: string]: any;
}

export default function View(props: IProps) {
  const [open, setOpen] = useState(false);
  const [valueDropdown, setValueDropdown] = useState<string>("");

  const onChangeDropdown = (value, e: any) => {
    if (!props.notChange) {
      const option = props.options.find((item) => item.value === value.value);
      setValueDropdown(option?.label);
    }
    props.onChange(value);
  };

  useEffect(() => {
    if (!props?.defaultValue) {
      setValueDropdown(props.label);
    }
  }, [props?.defaultValue]);

  useEffect(() => {
    if (props?.options) {
      const value = props?.options?.find(
        (item) => item.value === props.defaultValue
      )?.label;
      setValueDropdown(value);
    }
  }, [props?.options]);

  return (
    <Wrapper
      width={props.width}
      height={props.height}
      color={props.color}
      transparent={props.transparent}
      hasError={props.hasError}
    >
      {props.label && <Label hasError={props.hasError}>{props.label}</Label>}

      <Dropdown
        toggle={function noRefCheck() {
          setOpen(!open);
        }}
        isOpen={open}
        {...props}
      >
        <DropdownToggle onClick={(e) => e.preventDefault()}>
          <DropdownWrapper>
            <span>{valueDropdown}</span>
            <DropdownCarret>
              <svg
                width="15"
                height="10"
                viewBox="0 0 18 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L9 9L17 1"
                  stroke={props.transparent ? "white" : "black"}
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </DropdownCarret>
            {props?.isCloseIcon && props?.defaultValue && (
              <CloseIcon
                onClick={(e) => {
                  e.stopPropagation();
                  props?.onRemoveValue();
                }}
              >
                <RiCloseLine />
              </CloseIcon>
            )}
          </DropdownWrapper>
        </DropdownToggle>

        {props?.options?.length > 0 && (
          <DropdownMenu>
            <Scroll>
              {props.options?.map((item: any) => (
                <DropdownItem
                  eventKey={item.value}
                  onClick={onChangeDropdown.bind(null, item)}
                >
                  {item.label}
                </DropdownItem>
              ))}
            </Scroll>
          </DropdownMenu>
        )}
      </Dropdown>
    </Wrapper>
  );
}
