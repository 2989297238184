import { IRootState } from "..";

export const getReward = (state: IRootState) => state.settings.reward;

export const getadvertisementPosition = (state: IRootState) =>
  state.settings.adPostition;

export const getChannel = (state: IRootState) => state.settings.channel;
export const getDocuments = (state: IRootState) => state.settings.documents;
export const getNfts = (state: IRootState) => state.settings.nfts;
export const getQA = (state: IRootState) => state.settings.qa;
export const getProjects = (state: IRootState) => state.settings.projects;
export const getSupports = (state: IRootState) => state.settings.supports;
export const getBanner = (state: IRootState) => state.settings.banner;
export const getCities = (state: IRootState) => state.settings.cities;
export const getRateUSDToVND = (state: IRootState) => state.settings.usd_vnd;
export const getObjects = (state: IRootState) => state.settings.objects;
export const getLogo = (state: IRootState) => state.settings.logos;
export const getWebConfig = (state: IRootState) => state.settings.webConfig;
export const getTopMenu = (state: IRootState) =>
  [...state.settings.top_menu].sort(
    (a, b) => (a.position || 0) - (b.position || 0)
  );
export const getGroups = (state: IRootState) => state.settings.groups;
export const getComingSoon = (state: IRootState) => state.settings.coming_soon;
export const getLocations = (state: IRootState) => state.settings.locations;

