/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";

import {
  DetailRightView,
  Title,
  SpanColor,
  MarginTop,
  SubmitWithdraw,
  RightViewContent,
  Warning,
} from "../../styles";
import Input from "components/input";
import Button from "components/button";
import { useSelector } from "react-redux";
import { getBalances } from "redux/reducers/users/users.selector";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/reducers/hook";
import { getCryptos } from "redux/reducers/cryptos/cryptos.selector";
import { formatNumber } from "utils/common";
import Verify from "../verify";
import APIs from "api";

const EmailWithdraw = ({
  userBalance,
  control,
  errors,
  setMaxAmount,
  tokenName,
  errorAmount,
  amount,
  handleChange,
}) => {
  const stockName = String(tokenName).toUpperCase();

  return (
    <RightViewContent>
      <MarginTop mt="20px" />
      <Input
        transparent={true}
        label={`Stock ${stockName} Amount`}
        placeholder="0.00"
        isCurrency={true}
        type="text"
        value={amount}
        hasError={amount > +userBalance[tokenName] || errorAmount}
        suffixIcon={
          <Button
            className={"btn-max-withdraw"}
            onClick={setMaxAmount}
            height="35px"
          >
            MAX
          </Button>
        }
        onValueChange={(e) => handleChange(e)}
      />

      <MarginTop mt="20px" />
      <p>
        Maximum {stockName} Available:{" "}
        <SpanColor color="red100">{userBalance[tokenName]}</SpanColor>{" "}
        {stockName}
      </p>
      <p>
        You can <SpanColor color="green100">buy</SpanColor> or{" "}
        <SpanColor color="orange100">deposit</SpanColor> {stockName}
      </p>
      <MarginTop mt="20px" />

      <Controller
        name="note"
        control={control}
        render={({ field }) => {
          return (
            <Input
              type={"textarea"}
              height="100px"
              transparent={true}
              label="Message"
              placeholder={`Welcome to ${stockName} Stock`}
              hasError={errors.note}
              {...field}
            />
          );
        }}
      />

      <MarginTop mt="20px" />
      <Warning>
        <img src="/assets/images/icons/warning.svg" alt="warning" />
        <p>
          Please update the correct VZONEXID to the correct profile at
          <a href="https://cv.tiepthiso.org/" target={"_blank"}>
            {" "}
            https://cv.tiepthiso.org/
          </a>
        </p>
      </Warning>
      <Warning>
        <img src="/assets/images/icons/warning.svg" alt="warning" />
        <p>Estimated time to complete: less than a minute</p>
      </Warning>
    </RightViewContent>
  );
};

const View = (props) => {
  const location: any = useLocation();

  const history = useHistory();

  const address = queryString.parse(location.search)?.address;
  const amountStock = queryString.parse(location.search)?.amountStock;

  const [loading, setLoading] = useState(false);
  const [stock, setStock] = useState(null);
  const cryptos = useAppSelector(getCryptos);
  const [showVerify, setShowVerify] = useState(false);
  const [verify, setVerify] = useState({
    email_code: "",
    google_code: "",
  });

  const userBalance = useSelector(getBalances);
  const tokenName = props.match.params.stock;

  const [amount, setAmount] = useState<number>(null);
  const [errorAmount, setErrorAmount] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    setValue,
    // watch,
    formState: { errors },
  } = useForm();

  const handleChange = (e) => {
    setAmount(e);
  };

  const handleSubmitWithdraw = async (data) => {
    const body = {
      note: data.note,
      to: data.to,
      stock: tokenName,
      amount: Number(amount),
      network: "vzonex",
      verify,
    };
    try {
      if (amount <= 0 || !amount) {
        setErrorAmount(true);
      } else {
        if (!showVerify) {
          setShowVerify(true);
          return;
        }
        setLoading(true);
        await APIs.TRANSACTION.withdrawCV({ body });
        setLoading(false);
        setShowVerify(false);

        history.push(`/my/wallets/${tokenName}/withdraw/`);
        toast.success("Withdraw successful!");
        setVerify({
          email_code: "",
          google_code: "",
        });
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.errors || "Withdraw failed!");
    }
  };

  const setMaxAmount = () => {
    setAmount(userBalance[tokenName]);
  };

  const feeTransfer =
    stock && stock.fee_transfer
      ? stock.fee_transfer
      : { stock: tokenName, percent: 0 };
  // const amount = watch("amount");
  const amountFee = formatNumber(((+amount || 0) * feeTransfer.percent) / 100);

  const formProps = {
    userBalance,
    control,
    errors,
    tokenName,
    fee: {
      amount: amountFee,
      stock: feeTransfer.stock,
    },
    setMaxAmount,
  };

  useEffect(() => {
    if (cryptos) setStock(cryptos.find((c) => c.symbol === tokenName));
  }, [cryptos, tokenName]);

  useEffect(() => {
    if (Number(amountStock) > 0) {
      setAmount(Number(amountStock));
    }
    if (address) {
      setValue("to", address);
    }
  }, []);

  return (
    <DetailRightView>
      <Title bgColor={"grey200"} just="space-between">
        <p>Transfer Stock ({String(tokenName).toUpperCase()})</p>
      </Title>

      <form onSubmit={handleSubmit(handleSubmitWithdraw)}>
        {showVerify ? (
          <Verify verify={verify} setVerify={setVerify} />
        ) : (
          <EmailWithdraw
            {...formProps}
            amount={amount}
            errorAmount={errorAmount}
            handleChange={handleChange}
          />
        )}
        <MarginTop mt="40px" />
        <SubmitWithdraw>
          <Button type="submit" height="40px" loading={loading}>
            Submit
          </Button>
        </SubmitWithdraw>
      </form>
    </DetailRightView>
  );
};

export default View;
