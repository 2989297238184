/* eslint-disable jsx-a11y/alt-text */
import Button from "components/button";
import { Continue } from "../../styles";
import { Step0Wrapper } from "./styles";

const Step0 = ({ dataTrading, nextStep, setDataByKey, setDataTrading }) => {
  const currencies = Object.keys(dataTrading.sell_pairs);

  return (
    <Step0Wrapper>
      {currencies.map((c) => (
        <Button
          className={`btn-currency ${dataTrading.currency === c ? "active" : ""}`}
          onClick={() => {
            setDataTrading((dataTrading) => {
              return {
                ...dataTrading,
                currency: c,
              };
            });
          }}
        >
          {c.toUpperCase()}
        </Button>
      ))}
      <Continue>
        <Button onClick={() => nextStep()} disabled={!dataTrading.currency}>
          Continue
        </Button>
      </Continue>
    </Step0Wrapper>
  );
};

export default Step0;
