/* eslint-disable react-hooks/exhaustive-deps */
import { Controller } from "react-hook-form";
import { Form, FormItem } from "pages/auth/styles";
import { ContainerForm } from "../styles";
import Input from "components/input";
import { useEffect } from "react";
import decode from "utils/decodeToken";

const View = (props) => {
  const { control, errors, setValue } = props;

  const token =
    localStorage.getItem("auth") || localStorage.getItem("interactive-survey");
  const user: any = decode(token);

  useEffect(() => {
    if (user?.email) {
      setValue("email", user.email);
    }
    if (user?.fullName) {
      setValue("fullname", user.fullName);
    }
    if (user?.phone) {
      setValue("phone", user.phone);
    }
  }, [user]);

  return (
    <ContainerForm>
      <Form>
        <FormItem>
          <Controller
            name="email"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                transparent={true}
                label="Email"
                placeholder="Email"
                disabled={user?.email}
                hasError={Boolean(errors.email)}
                {...field}
              />
            )}
          />
        </FormItem>
        <FormItem>
          <Controller
            name="fullname"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                transparent={true}
                label="Full name"
                placeholder="Full name"
                disabled={user?.fullName}
                hasError={Boolean(errors.fullname)}
                {...field}
              />
            )}
          />
        </FormItem>
        <FormItem>
          <Controller
            name="phone"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <Input
                transparent={true}
                label="Phone"
                placeholder="Phone"
                disabled={user?.phone}
                hasError={Boolean(errors.phone)}
                {...field}
              />
            )}
          />
        </FormItem>
      </Form>
    </ContainerForm>
  );
};

export default View;
