import { useState } from "react"
import UploadFile from "components/uploadFile"
import { UserProfile, Avatar, ChangeAvt } from "../styles"
import { Upload } from "pages/home/landing-page/styles"
import Button from "components/button"
import { useDispatch } from "react-redux";
import { getUser, editUser } from "redux/reducers/users/users.reducer"
import APIs from "api"
import { toast } from "react-toastify"

const AvatarProfile = ({profile}: any) => {
  const dispatch = useDispatch();
  const [fileAvt, setFileAvt] = useState(null)
  const [loading, setLoading] = useState<boolean>(false)

  const handleEditAvt = async () => {
    setLoading(true)
    const formData = new FormData();
    formData.append("files", fileAvt);
    const res: any = await APIs.UPLOAD.uploadFile({
      body: formData
    })
    if(res) {
      const user: any = await dispatch(editUser({
        params: {
          id: profile._id
        },
        body: {
          avatar: res.filePath
        }
      }));
      if(user){
        await dispatch(getUser())
        setLoading(false)
        toast.success("Update success!");
      } else {
        toast.error('Error')
        setLoading(false)
      }
      setFileAvt(null)
    }
  } 
  return <UserProfile>
    <Avatar>
      <Upload isHidden={Boolean(!fileAvt) && profile?.avatar}>
        <UploadFile id="avatar" ratio='340x340' file={fileAvt} setFile={setFileAvt} />
      </Upload>
      <img hidden={fileAvt} src={profile?.avatar} alt="" />
    </Avatar>
    <p>{profile?.email}</p>
    { fileAvt && <ChangeAvt>
      <Button height={"30px"} loading={loading} onClick={handleEditAvt} >Save</Button> 
    </ChangeAvt>
    }
        
  </UserProfile>
}

export default AvatarProfile