/* eslint-disable indent */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import QRCode from "react-qr-code";
import {
  Container,
  TextColor,
  Profile,
  Info,
  SubmitEdit,
  ChangePassword,
  Activity,
  RowInfo,
  ItemRow,
  ItemEdit,
  SubmitKYC,
  ButtonEdit,
  VisitCard,
  ContainerVisitCard,
  VisitCardDetailFront,
  VisitCardDetailBack,
  TitleVisitCard,
  ImageVisit,
  Content,
  LogoVisit,
  NameVisit,
  DescVisit,
  LinkVisit,
  LeftDetail,
  RightDetail,
  FooterRight,
  ContentRigth,
  HeaderRight,
  QrCodeWrapper,
  MessageKyc,
} from "./styles";
import Table from "components/table";
import Button from "components/button";
import Input from "components/input";
import Dropdown from "components/dropdown";

import { getBase64Image } from "utils/common";

import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/reducers/hook";
import { getUserProfile } from "redux/reducers/users/users.selector";
import { getUser, editUser } from "redux/reducers/users/users.reducer";
import UserAPIs from "api/users";
import AvatarProfile from "./components/AvatarProfile";
import { Upload } from "pages/home/landing-page/styles";
import UploadFile from "components/uploadFile";
import APIs from "api";
import { exportComponentAsPNG } from "react-component-export-image";
import Verification from "./components/Verification";
import useQuery from "hooks/useQuery";
import Tabs from "components/tabs";
import { TbActivity, TbAffiliate } from "react-icons/tb";
import { AiOutlineIdcard, AiOutlineSetting } from "react-icons/ai";
import { MdOutlineVerified } from "react-icons/md";
import { Si1Password } from "react-icons/si";
import { CgProfile } from "react-icons/cg";
import Affiliates from "./referral";
import Setting from "./setting";
import {
  getGroups,
  getLocations,
  getObjects,
} from "redux/reducers/settings/settings.selector";
import ObjectInfo from "./object";
import SettingChannel from "./channel";

const columns = ["Action", "Source", "Ip_address", "Location", "Updated_at"];

const View = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const visitcardFrontRef = useRef();
  const visitcardBackRef = useRef();
  const query = useQuery();

  const profile: any = useAppSelector(getUserProfile);
  const objects = useAppSelector(getObjects);
  const groups = useAppSelector(getGroups);
  const locations = useAppSelector(getLocations);
  const { wallet } = profile?.vzxWallet || {};
  const vzxAddress = wallet?.address;

  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState<string>("profile");
  const [errMessage, setErrMessage] = useState<any>({
    old: "",
    new: "",
    confirm: "",
  });

  const [showMessDownload, setShowMessDownload] = useState<boolean>(false);

  const [listFiles, setListFiles] = useState<any>({});

  const [show, setShow] = useState<any>({
    current: false,
    new: false,
    confirm: false,
  });
  const [password, setPassword] = useState<any>({
    oldPassword: "",
    newPassword: "",
    confirm: "",
  });
  const [infoUser, setInfoUser] = useState<any>({
    fullName: profile.fullName,
    phone: profile.phone,
    email: profile.email,
    visit_card: profile.visit_card,
    object: profile.object_type,
    group: profile.group,
  });

  const [isEditVisit, setIsEditVisit] = useState<boolean>(false);

  const [imageBase64, setImageBase64] = useState<any>([]);

  const [provinces, setProvinces] = useState<any>([]);
  const [districts, setDistricts] = useState<any>([]);
  const [wards, setWards] = useState<any>([]);

  const [address, setAddress] = useState<any>({
    country: null,
    province: null,
    district: null,
    ward: null,
  });

  useEffect(() => {
    if (query && query.tab) {
      setTab(query.tab);
    }
  }, [query]);

  const filterLocations = (parentId, data) => {
    return data?.filter((item) => item.parent === parentId);
  };

  const findLocationName = (locationId, data) => {
    return data?.find((item) => item._id === locationId)?.name;
  };

  useEffect(() => {
    if (profile && profile.address) {
      setAddress(profile.address);
    }
  }, [profile]);

  const renderAddressDetail = () => {
    const countryName = findLocationName(
      address?.country,
      locations?.countries
    );
    const provinceName = findLocationName(
      address?.province,
      locations?.provinces
    );
    const districtName = findLocationName(
      address?.district,
      locations?.districts
    );
    const wardName = findLocationName(address?.ward, locations?.wards);
    return `${!!wardName ? `${wardName}, ` : ""}${
      !!districtName ? `${districtName}, ` : ""
    }${!!provinceName ? `${provinceName}, ` : ""}${
      !!countryName ? `${countryName}` : ""
    }`;
  };

  useEffect(() => {
    if (locations && profile.address) {
      const { address } = profile;
      const _provinces = filterLocations(address.country, locations?.provinces);
      const _districts = filterLocations(
        address.province,
        locations?.districts
      );
      const _wards = filterLocations(address.district, locations?.wards);
      setProvinces(_provinces);
      setDistricts(_districts);
      setWards(_wards);
    }
  }, [locations, profile.address]);

  const changeTab = (tab: string) => {
    setTab(tab);
    history.push(`?tab=${tab}`);
    setShowMessDownload(false);
    setListFiles({});
  };

  const setFile = (f: any, id: string) => {
    setListFiles((prev) => ({
      ...prev,
      [id]: f,
    }));
  };

  const editProfile = (e: any, key: string) => {
    setInfoUser({
      ...infoUser,
      [key]: e.target.value,
    });
  };

  const convertBase64 = async () => {
    if (infoUser && infoUser.visit_card) {
      const { logoFrontVisit, backVisitcard } = infoUser.visit_card;
      const res = await Promise.all(
        [logoFrontVisit, backVisitcard].map(
          async (i, index) => await getBase64Image(i)
        )
      );
      setImageBase64(res);
    }
  };

  const handleDownloadVisit = async () => {
    setLoading(true);
    // if (KYC?.status === "approved") {
    await convertBase64();
    exportComponentAsPNG(visitcardFrontRef, {
      fileName:
        `${infoUser?.fullName}_visitFront_${new Date().getTime()}` || "vzonex",
    });
    exportComponentAsPNG(visitcardBackRef, {
      fileName:
        `${infoUser?.fullName}_visitBack_${new Date().getTime()}` || "vzonex",
    });
    // }
    // else {
    //   setShowMessDownload(true)
    // }
    setLoading(false);
  };

  const handleEdit = async () => {
    setLoading(true);
    let data = {
      ...infoUser,
      address,
    };
    if (Object.keys(listFiles).length > 0) {
      const formData = new FormData();
      Object.keys(listFiles).map((el: any) => {
        formData.append("files", listFiles[el]);
      });
      const listImg: any = await APIs.UPLOAD.uploadMultiFile({
        body: formData,
      });
      if (listImg?.length > 0) {
        await Promise.all(
          listImg?.map((el: any) => {
            Object.keys(listFiles).map((item: any, indexKey: number) => {
              if (el.id === indexKey) {
                data = {
                  ...data,
                  visit_card: {
                    ...data.visit_card,
                    [item]: el?.filePath,
                  },
                };
              }
            });
          })
        );
        setListFiles({});
      }
    }
    const user: any = await dispatch(
      editUser({
        params: {
          id: profile._id,
        },
        body: data,
      })
    );

    if (user) {
      const data: any = await dispatch(getUser());
      setInfoUser({
        fullName: data?.payload?.user?.fullName,
        phone: data?.payload?.user?.phone,
        email: data?.payload?.user?.email,
        visit_card: data?.payload?.user?.visit_card,
        group: data?.payload?.user?.group,
        object: data?.payload?.user?.object_type,
        address: data?.payload?.user?.address,
      });
      setTab("profile");
      setLoading(false);
      toast.success("Update success!");
    } else {
      toast.error("Error");
      setLoading(false);
    }
  };

  const handleChangePassword = async () => {
    setLoading(true);
    if (password.newPassword !== password.confirm) {
      setErrMessage({
        confirm: `Confirm password doesn't match`,
      });
      setLoading(false);
    } else {
      try {
        await UserAPIs.changePassword({ body: password });
        setLoading(false);
        toast.success(`Change password success!`);
        localStorage.removeItem("auth");
        history.push("/auth/login");
      } catch (error) {
        setErrMessage({
          old: error.errors,
        });
        setLoading(false);
      }
    }
  };

  const handleClickCard = async () => {
    if (isEditVisit) {
      await handleEdit();
    }
    setIsEditVisit(!isEditVisit);
  };

  const onBlurText = (e: any, type?: string) => {
    if (type === "profile") {
      setInfoUser((prev) => ({
        ...prev,
        [e.target.getAttribute("data-key")]: e.target.innerText,
      }));
    } else {
      setInfoUser((prev) => ({
        ...prev,
        visit_card: {
          ...prev.visit_card,
          [e.target.getAttribute("data-key")]: e.target.innerText,
        },
      }));
    }
  };

  const objectName = useMemo(() => {
    if (profile && objects) {
      const _object = objects.find((o) => o.id === profile.object_type);
      return _object ? _object.name : null;
    }
  }, [profile, objects]);

  const setFilterCities = async (key: string, e: any) => {
    const id = e.value;
    if (key === "country") {
      setDistricts([]);
      setWards([]);
      if (id) {
        const _provinces = filterLocations(id, locations?.provinces);
        if (_provinces) {
          setProvinces(_provinces);
          setAddress({
            country: id,
            province: null,
            district: null,
            ward: null,
          });
        } else {
          setAddress({
            country: null,
            province: null,
            district: null,
            ward: null,
            detail: null,
          });
        }
      } else {
        setAddress({
          country: null,
          province: null,
          district: null,
          ward: null,
        });
      }
    }
    if (key === "province") {
      setWards([]);
      if (id) {
        const _districts = filterLocations(id, locations?.districts);
        if (_districts) {
          setDistricts(_districts);
          setAddress({
            ...address,
            province: id,
            district: null,
            ward: null,
          });
        } else {
          setDistricts([]);
          setAddress({
            ...address,
            province: null,
            district: null,
            ward: null,
          });
        }
      } else {
        setDistricts([]);
        setAddress({
          ...address,
          province: null,
          district: null,
          ward: null,
        });
      }
    }
    if (key === "district") {
      if (id) {
        const _wards = filterLocations(id, locations?.wards);
        setWards(_wards);
        setAddress({
          ...address,
          district: id,
          ward: null,
        });
      } else {
        setWards([]);
        setAddress({
          ...address,
          district: null,
          ward: null,
        });
      }
    }
    if (key === "ward") {
      if (id) {
        setAddress({
          ...address,
          ward: id,
        });
      } else {
        setAddress({
          ...address,
          ward: null,
        });
      }
    }
  };

  return (
    <>
      <Container>
        <Tabs
          active={tab}
          tabs={[
            {
              name: "profile",
              render: (
                <>
                  <CgProfile size={17} /> <span>Profile</span>
                </>
              ),
            },
            {
              name: "visitcard",
              render: (
                <>
                  <AiOutlineIdcard size={17} /> <span> Vzonex Card</span>
                </>
              ),
            },
            {
              name: "affiliate",
              render: (
                <>
                  <TbAffiliate size={17} /> <span> Affiliates</span>
                </>
              ),
            },
            {
              name: "verification",
              render: (
                <>
                  <MdOutlineVerified size={17} /> <span> ID Verification</span>
                </>
              ),
            },
            {
              name: "changePassword",
              render: (
                <>
                  <Si1Password size={17} /> <span> Change Password</span>
                </>
              ),
            },
            {
              name: "setting",
              render: (
                <>
                  <AiOutlineSetting size={17} /> <span>Setting</span>
                </>
              ),
            },
            {
              name: "activity",
              render: (
                <>
                  <TbActivity size={17} /> <span> Activity Log</span>
                </>
              ),
            },
            {
              name: "channel",
              render: (
                <>
                  <AiOutlineSetting size={17} /> <span> My Channel</span>
                </>
              ),
            },
          ]}
          handleTab={changeTab}
        />

        {(tab === "profile" || tab === "edit") && (
          <Profile>
            <ButtonEdit>
              <Button
                height={"30px"}
                onClick={() =>
                  changeTab(tab === "profile" ? "edit" : "profile")
                }
              >
                {tab === "profile" ? "Edit" : "Cancel"}
              </Button>
            </ButtonEdit>
            <Row lg={12} md={12} xl={10}>
              <Col lg={4}>
                <AvatarProfile profile={profile} />
              </Col>
              {tab === "profile" ? (
                <Col lg={8}>
                  <Info>
                    <RowInfo>
                      <ItemRow>Name</ItemRow>
                      <ItemRow>{profile?.fullName}</ItemRow>
                    </RowInfo>
                    <RowInfo>
                      <ItemRow>Object</ItemRow>
                      <ItemRow>{objectName}</ItemRow>
                    </RowInfo>
                    <RowInfo>
                      <ItemRow>Group</ItemRow>
                      <ItemRow>{profile?.group}</ItemRow>
                    </RowInfo>
                    <RowInfo>
                      <ItemRow>Role</ItemRow>
                      <ItemRow>{profile?.role}</ItemRow>
                    </RowInfo>
                    <RowInfo>
                      <ItemRow>Phone</ItemRow>
                      <ItemRow>{profile?.phone}</ItemRow>
                    </RowInfo>
                    <RowInfo>
                      <ItemRow>Email</ItemRow>
                      <ItemRow>{profile?.email}</ItemRow>
                    </RowInfo>
                    <RowInfo>
                      <ItemRow>Address</ItemRow>
                      <ItemRow>{renderAddressDetail()}</ItemRow>
                    </RowInfo>
                    <RowInfo>
                      <ItemRow>Email verification</ItemRow>
                      <ItemRow>
                        <TextColor color="orange100">Confirmed</TextColor>
                      </ItemRow>
                    </RowInfo>
                    <RowInfo>
                      <ItemRow>Status</ItemRow>
                      <ItemRow>
                        <TextColor
                          color={
                            profile?.status === "Active"
                              ? "green100"
                              : "orange100"
                          }
                        >
                          {profile?.status}
                        </TextColor>
                      </ItemRow>
                    </RowInfo>
                  </Info>
                </Col>
              ) : (
                <Col lg={8}>
                  <Info>
                    <Input
                      transparent={true}
                      label="Full name"
                      value={infoUser.fullName}
                      errMessage={"Please enter"}
                      onChange={(e) => editProfile(e, "fullName")}
                    />
                    <Dropdown
                      transparent={true}
                      color={"white100"}
                      width={"100%"}
                      label="Choose the object"
                      defaultValue={infoUser?.object}
                      options={objects?.map((el: any) => {
                        return {
                          label: el?.name,
                          value: el?.id,
                        };
                      })}
                      onChange={(e) =>
                        setInfoUser((prev) => ({
                          ...prev,
                          object: e.value,
                        }))
                      }
                    />

                    <Dropdown
                      transparent={true}
                      color={"white100"}
                      width={"100%"}
                      label="Group"
                      options={groups?.map((el: any) => {
                        return {
                          label: el?.name,
                          value: el?.key,
                        };
                      })}
                      defaultValue={infoUser.group}
                      onChange={(e) =>
                        setInfoUser((prev) => ({
                          ...prev,
                          group: e.value,
                        }))
                      }
                      isCloseIcon={true}
                      onRemoveValue={() =>
                        setInfoUser((prev) => ({
                          ...prev,
                          group: null,
                        }))
                      }
                    />

                    <ItemEdit>
                      <Input
                        transparent={true}
                        type="number"
                        label="Phone number"
                        placeholder="+84.."
                        value={infoUser.phone}
                        onChange={(e) => editProfile(e, "phone")}
                      />
                    </ItemEdit>
                    <Input
                      transparent={true}
                      label="Email"
                      value={infoUser.email}
                      disabled={true}
                    />
                    <Dropdown
                      transparent={true}
                      color={"white100"}
                      width={"100%"}
                      label="Choose the Country"
                      options={locations?.countries?.map((el: any) => {
                        return {
                          label: el?.name,
                          value: el?._id,
                        };
                      })}
                      value={address.country}
                      defaultValue={address?.country}
                      onChange={(e) => setFilterCities("country", e)}
                    />
                    <Dropdown
                      transparent={true}
                      color={"white100"}
                      width={"100%"}
                      label="Choose the Province / City"
                      options={provinces?.map((el: any) => {
                        return {
                          label: el?.name,
                          value: el?._id,
                        };
                      })}
                      value={address.province}
                      defaultValue={address.province}
                      onChange={(e) => setFilterCities("province", e)}
                    />
                    <Dropdown
                      transparent={true}
                      color={"white100"}
                      width={"100%"}
                      label="Choose the District"
                      options={districts?.map((el: any) => {
                        return {
                          label: el?.name,
                          value: el?._id,
                        };
                      })}
                      value={address.district}
                      defaultValue={address.district}
                      onChange={(e) => setFilterCities("district", e)}
                    />
                    <Dropdown
                      transparent={true}
                      color={"white100"}
                      width={"100%"}
                      label="Choose the Ward"
                      options={wards?.map((el: any) => {
                        return {
                          label: el?.name,
                          value: el?._id,
                        };
                      })}
                      value={address.ward}
                      defaultValue={address.ward}
                      onChange={(e) => setFilterCities("ward", e)}
                    />
                    <SubmitEdit>
                      <Button
                        loading={loading}
                        height="36px"
                        onClick={handleEdit}
                      >
                        Save
                      </Button>
                    </SubmitEdit>
                  </Info>
                </Col>
              )}
            </Row>
          </Profile>
        )}

        {tab === "visitcard" && (
          <>
            <VisitCard>
              <ContainerVisitCard>
                <TitleVisitCard>Front Visit Card</TitleVisitCard>
                <ImageVisit ref={visitcardFrontRef}>
                  <img
                    src="/assets/images/frontvisitcard.png"
                    alt="frontvisit"
                  />
                  <VisitCardDetailFront>
                    <LogoVisit>
                      {isEditVisit && (
                        <Upload
                          isHidden={
                            Boolean(!listFiles["logoFrontVisit"]) &&
                            infoUser?.visit_card?.logoFrontVisit
                          }
                        >
                          <UploadFile
                            id="logoFrontVisit"
                            ratio="50x50"
                            typeUpload="image"
                            file={listFiles["logoFrontVisit"]}
                            padding="0px"
                            setFile={setFile}
                          />
                        </Upload>
                      )}
                      <img
                        hidden={listFiles["logoFrontVisit"]}
                        src={
                          imageBase64[0] ||
                          infoUser?.visit_card?.logoFrontVisit ||
                          ""
                        }
                        alt=""
                      />
                    </LogoVisit>
                    <Content>
                      <NameVisit
                        data-key={"nameFrontVisit"}
                        contentEditable={isEditVisit}
                        onBlur={onBlurText}
                      >
                        {infoUser?.visit_card?.nameFrontVisit?.toUpperCase() ||
                          "VZONEX"}
                      </NameVisit>
                      <DescVisit
                        data-key={"descFrontVisit"}
                        contentEditable={isEditVisit}
                        onBlur={onBlurText}
                      >
                        {infoUser?.visit_card?.descFrontVisit?.toUpperCase() ||
                          "DESCRIPTION"}
                      </DescVisit>
                    </Content>
                    <LinkVisit
                      data-key={"linkFrontVisit"}
                      contentEditable={isEditVisit}
                      onBlur={onBlurText}
                    >
                      {infoUser?.visit_card?.linkFrontVisit || "www.vzonex.com"}
                    </LinkVisit>
                  </VisitCardDetailFront>
                </ImageVisit>
              </ContainerVisitCard>
              <ContainerVisitCard>
                <TitleVisitCard>Back Visit Card</TitleVisitCard>
                <ImageVisit ref={visitcardBackRef}>
                  <img src="/assets/images/backvisitcard.png" alt="backvisit" />
                  <VisitCardDetailBack>
                    <LeftDetail>
                      <LogoVisit>
                        {isEditVisit && (
                          <Upload
                            isHidden={
                              Boolean(!listFiles["backVisitcard"]) &&
                              infoUser?.visit_card?.backVisitcard
                            }
                          >
                            <UploadFile
                              id="backVisitcard"
                              ratio="50x50"
                              typeUpload="image"
                              file={listFiles["backVisitcard"]}
                              padding="0px"
                              setFile={setFile}
                            />
                          </Upload>
                        )}
                        <img
                          hidden={listFiles["backVisitcard"]}
                          src={
                            imageBase64[1] ||
                            infoUser?.visit_card?.backVisitcard ||
                            ""
                          }
                          alt=""
                        />
                      </LogoVisit>
                      <Content marginTop={"10px"}>
                        <NameVisit
                          data-key={"nameBackVisit"}
                          contentEditable={isEditVisit}
                          onBlur={onBlurText}
                        >
                          {infoUser?.visit_card?.nameBackVisit?.toUpperCase() ||
                            "VZONEX"}
                        </NameVisit>
                        <DescVisit
                          data-key={"descBackVisit"}
                          contentEditable={isEditVisit}
                          onBlur={onBlurText}
                        >
                          {infoUser?.visit_card?.descBackVisit?.toUpperCase() ||
                            "DESCRIPTION"}
                        </DescVisit>
                      </Content>
                      <QrCodeWrapper>
                        <QRCode value={vzxAddress} size={60} />
                      </QrCodeWrapper>
                    </LeftDetail>
                    <RightDetail>
                      <HeaderRight>
                        <h5
                          data-key={"fullName"}
                          contentEditable={isEditVisit}
                          onBlur={(e) => onBlurText(e, "profile")}
                        >
                          {profile?.fullName?.toUpperCase() || "FULLNAME"}
                        </h5>
                        <p
                          data-key={"positionVisit"}
                          contentEditable={isEditVisit}
                          onBlur={onBlurText}
                        >
                          {infoUser?.visit_card?.positionVisit?.toUpperCase() ||
                            "POSITION"}
                        </p>
                      </HeaderRight>
                      <ContentRigth>
                        <p
                          data-key={"phone"}
                          contentEditable={isEditVisit}
                          onBlur={(e) => onBlurText(e, "profile")}
                        >
                          {profile?.phone || "+84..."}
                        </p>
                        <p
                          data-key={"phone2"}
                          contentEditable={isEditVisit}
                          onBlur={onBlurText}
                        >
                          {infoUser?.visit_card?.phone2 || "+84..."}
                        </p>
                        <p>{profile?.email}</p>
                        <p
                          data-key={"linkBackVisit"}
                          contentEditable={isEditVisit}
                          onBlur={onBlurText}
                        >
                          {infoUser?.visit_card?.linkBackVisit ||
                            "www.vzonex.com"}
                        </p>
                      </ContentRigth>
                      <FooterRight
                        data-key={"address"}
                        contentEditable={isEditVisit}
                        onBlur={(e) => onBlurText(e, "profile")}
                      >
                        <p>
                          {renderAddressDetail() ||
                            "119 My Dinh, Nam Tu Liem, Ha Noi"}
                        </p>
                      </FooterRight>
                    </RightDetail>
                  </VisitCardDetailBack>
                </ImageVisit>
              </ContainerVisitCard>
            </VisitCard>
            <SubmitKYC>
              <Button onClick={handleClickCard} loading={loading}>
                {isEditVisit ? "Save" : "Edit"}
              </Button>
              <Button onClick={handleDownloadVisit} loading={loading}>
                Download
              </Button>
            </SubmitKYC>
            {showMessDownload && (
              <MessageKyc>
                You have not KYC or are waiting for approval.{" "}
                <p onClick={() => setTab("verification")}>Click here !</p>
              </MessageKyc>
            )}
          </>
        )}
        {tab === "verification" && <Verification />}
        {tab === "changePassword" && (
          <ChangePassword>
            <Input
              transparent={true}
              label="Current Password"
              type={show.current ? "text" : "password"}
              placeholder="***************"
              hasError={Boolean(errMessage.old)}
              errMessage={errMessage.old || "Please enter"}
              suffixIcon={
                show.current ? (
                  <img
                    src="/assets/images/icons/eyes.svg"
                    alt=""
                    onClick={() =>
                      setShow({
                        ...show,
                        current: false,
                      })
                    }
                  />
                ) : (
                  <img
                    src="/assets/images/icons/eyes-blink.svg"
                    alt=""
                    onClick={() =>
                      setShow({
                        ...show,
                        current: true,
                      })
                    }
                  />
                )
              }
              onChange={(e) =>
                setPassword({
                  ...password,
                  oldPassword: e.target.value,
                })
              }
            />
            <ItemEdit>
              <Input
                transparent={true}
                label="New Password"
                type={show.new ? "text" : "password"}
                placeholder="New Password"
                hasError={Boolean(errMessage.new)}
                errMessage={errMessage.new || "Please enter"}
                suffixIcon={
                  show.new ? (
                    <img
                      src="/assets/images/icons/eyes.svg"
                      alt=""
                      onClick={() =>
                        setShow({
                          ...show,
                          new: false,
                        })
                      }
                    />
                  ) : (
                    <img
                      src="/assets/images/icons/eyes-blink.svg"
                      alt=""
                      onClick={() =>
                        setShow({
                          ...show,
                          new: true,
                        })
                      }
                    />
                  )
                }
                onChange={(e) =>
                  setPassword({
                    ...password,
                    newPassword: e.target.value,
                  })
                }
              />
            </ItemEdit>
            <Input
              transparent={true}
              label="Confirm Password"
              type={show.confirm ? "text" : "password"}
              placeholder="Confirm Password"
              hasError={Boolean(errMessage.confirm)}
              errMessage={errMessage.confirm || "Please enter"}
              suffixIcon={
                show.confirm ? (
                  <img
                    src="/assets/images/icons/eyes.svg"
                    alt=""
                    onClick={() =>
                      setShow({
                        ...show,
                        confirm: false,
                      })
                    }
                  />
                ) : (
                  <img
                    src="/assets/images/icons/eyes-blink.svg"
                    alt=""
                    onClick={() =>
                      setShow({
                        ...show,
                        confirm: true,
                      })
                    }
                  />
                )
              }
              onChange={(e) =>
                setPassword({
                  ...password,
                  confirm: e.target.value,
                })
              }
            />
            <SubmitEdit>
              <Button
                loading={loading}
                height="36px"
                onClick={() => handleChangePassword()}
              >
                Save
              </Button>
            </SubmitEdit>
          </ChangePassword>
        )}
        {tab === "activity" && (
          <Activity>
            <h6>All activity list</h6>
            <Table columns={columns} bgTable="grey200"></Table>
          </Activity>
        )}
        {tab === "setting" && <Setting />}
        {tab === "affiliate" && <Affiliates />}
        {tab === "object" && <ObjectInfo />}
        {tab === "channel" && <SettingChannel />}
      </Container>
    </>
  );
};

export default View;
