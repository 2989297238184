import React, { createContext, useState } from "react";
import { ThemeProvider as ThemeProviderStyled } from "styled-components";

export const ThemeContext = createContext({
  them: "dark",
});

const DarkThemesColors = {
  white100: "#FFFFFF",
  orange100: "#FF9900",
  green100: "#00AC11",
  bgColorLayer1: "#061a38",
  bgColorLayer2: "#071120",
  black100: " #071120",
  blue200: "#0d203e",
  blue300: "#172D4D",
  grey100: "#a0a0a0",
  grey200: "#3A3F51",
  red100: "#dc3545",
  yellow100: "#FFE600",
};

const DarkThemesColorsHover = {
  white100: "#cecbcb",
  orange100: "#ff8800d8",
  red100: "#b81727",
  green100: "#00ac11a9",
  blue100: "#2465e8a9",
};

const ThemeProvider = (props: any) => {
  const [them] = useState("dark");

  return (
    <ThemeContext.Provider
      value={{
        them: them,
      }}
    >
      <ThemeProviderStyled
        theme={{
          them: them,
          colors: DarkThemesColors,
          hoverColors: DarkThemesColorsHover,
        }}
      >
        {props.children}
      </ThemeProviderStyled>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
