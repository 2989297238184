import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "api";
import { IRequestData } from "api/request";

export const getUserAsync = createAsyncThunk("users/get", async (data) => {
  try {
    const response = await API.USER.getUserAuth();
    return response;
  } catch (error) {
    return error;
  }
});

export const loginAsync = createAsyncThunk("auth/login", async (data) => {
  try {
    const response = await API.AUTH.login(data);
    return response;
  } catch (error) {
    return error;
  }
});

export const registerAsync = createAsyncThunk("register", async (data) => {
  try {
    const response = await API.AUTH.register(data);
    return response;
  } catch (error) {
    return error;
  }
});

export const verifyEmailAsync = createAsyncThunk(
  "verify-email",
  async (data) => {
    try {
      const response = await API.AUTH.postVerifyEmail(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const resendVerifyEmailAsync = createAsyncThunk(
  "resend-verify-email",
  async (data) => {
    try {
      const response = await API.AUTH.resendVerifyEmail(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const forgotPasswordAsync = createAsyncThunk(
  "forgot-password",
  async (data) => {
    try {
      const response = await API.AUTH.postForgotPassword(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const resetPasswordAsync = createAsyncThunk(
  "reset-password",
  async (data) => {
    try {
      const response = await API.AUTH.resetPassword(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const changePasswordAsync = createAsyncThunk(
  "users/changePassword",
  async (data) => {
    try {
      const response = await API.USER.changePassword(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const checkPasswordAsync = createAsyncThunk(
  "users/checkpassword",
  async (data) => {
    try {
      const response = await API.USER.checkPassword(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const verify2FaAsync = createAsyncThunk("verify", async (data) => {
  try {
    const response = await API.AUTH.postVerify(data);
    return response;
  } catch (error) {
    return error;
  }
});

export const editUserAsync = createAsyncThunk(
  "user/edit",
  async (req: IRequestData) => {
    try {
      const response = await API.USER.editUser(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getKycAsync = createAsyncThunk("kyc/get", async (req) => {
  try {
    const response = await API.KYC.getKyc(req);
    return response;
  } catch (error) {
    return error;
  }
});

export const getNonceAsync = createAsyncThunk(
  "users/nonce",
  async (req: IRequestData) => {
    try {
      const response = await API.USER.getNonce(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const createKycAsync = createAsyncThunk("kyc/create", async (req) => {
  try {
    const response = await API.KYC.createKyc(req);
    return response;
  } catch (error) {
    return error;
  }
});

export const getCommissionsAsync = createAsyncThunk(
  "commissons/get",
  async (req: any) => {
    try {
      const response = await API.USER.getCommissons(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getSharingsAsync = createAsyncThunk(
  "sharings/get",
  async (req: any) => {
    try {
      const response = await API.USER.getSharings(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getBalancesAsync = createAsyncThunk(
  "users/balances",
  async (req) => {
    try {
      const response = await API.USER.getBalances(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getBanksAsync = createAsyncThunk("users/banks", async (req) => {
  try {
    const response = await API.BANK.getBanks();
    return response;
  } catch (error) {
    return error;
  }
});

export const getSalesStatisticsAsync = createAsyncThunk(
  "users/sales-statistics",
  async (req: any) => {
    try {
      const response = await API.USER.getSalesStatistics(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);
