import { request, parseErrorResponse } from "./request";

const createBank = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/banks`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getBanks = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/banks`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const APIs = {
  createBank,
  getBanks,
};

export default APIs;
