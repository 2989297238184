import {
  Header,
  // Item,
  Category,
  Border,
  Project,
  Element,
  ContainHeader,
  Wrapper,
  CreatePage
} from "./styles";
import Table from "./components/tables";
import { Col, Container, Row } from "reactstrap";
import { useHistory } from "react-router-dom"

// import Swiper from "./components/swiper";
import { TopMain } from "../styles";
import useProjects from "hooks/userProjects";
import ProjectAdvertisement from "layouts/ProjectAdvertisement";
import { useEffect, useState } from "react";
import Dropdown from "components/dropdown";

import { listProjectCategory } from "config/constant";
import { filterAddress } from "utils/getAddress";
import { useAppSelector } from "redux/reducers/hook";
import { getCities } from "redux/reducers/settings/settings.selector";


const View = () => {
  const { projectsByType } = useProjects();
  const cities = useAppSelector(getCities);

  const listFilter = [
    {
      label: "Tất cả",
      value: "Tất cả"
    },
    {
      label: "Lĩnh vực khác",
      value: "Lĩnh vực khác"
    },
    ...listProjectCategory
  ]
  const history = useHistory();

  const [filters, setFilters] = useState<any>({
    category: "Tất cả",
    provices: '',
    districts: '',
    wards: ''
  })

  const [provices, setProvices] = useState<any>([])
  const [districts, setDistricts] = useState<any>([])
  const [wards, setWards] = useState<any>([])
  
  const applyFiltersAddress = (data: any[]) => {
    return data.filter((item) => {
      let matches = true;
      if (
        (filters.provices && item.address?.provices !== filters.provices) ||
        (filters.districts && item.address?.districts !== filters.districts) ||
        (filters.wards && item.address?.wards !== filters.wards)
      ) {
        matches = false;
      }
      return matches;
    });
  };
  
  const filterProject = (data: any[]) => {
    let dataFilter = data;
    if(filters.category === "Tất cả") {
      dataFilter = data
    } else if (filters.category === "Lĩnh vực khác"){
      dataFilter = data.filter(el => !listProjectCategory.find(a => el.projectCategory === a.value))
    } else {
      dataFilter = data.filter(el => el.projectCategory === filters.category)
    }

    return applyFiltersAddress(dataFilter);
  }

  const handleFilter = (value) => {
    setFilters(prev => ({
      ...prev,
      category: value.label
    }))
  }

  const onChangeAddress = (value, key) => {
    if (key === 'provices'){
      filterAddress(key, cities, value.value, setDistricts)
    } else if (key === 'districts') {
      filterAddress(key, districts, value.value, setWards)
    }
    setFilters(prev => ({
      ...prev,
      [key]: value.label
    }))
  }

  useEffect(() => {
    setProvices(cities)
  }, [cities])

  return (
    <Wrapper>
      <Container>
        <TopMain>
          <ProjectAdvertisement />
        </TopMain>
        <ContainHeader>
          <Container>
            <Row>
              <Col lg={5} md={12}>
                <Header>
                  <h3>In Full - The Complete IBO Calendar</h3>
                  <p>
                    The full initial coin offering calendar listed in date
                    order.
                  </p>
                </Header>
              </Col>
              {/* <Col lg={7} md={12}>
                <Swiper />
              </Col> */}
            </Row>
          </Container>
        </ContainHeader>
        {/* <Category>
          <Item className="active">Overview</Item>
          <Item>Ongoing</Item>
          <Item>Upcoming</Item>
          <Item>Ended</Item>
        </Category>
        <Border />
        <Category>
          <Item className="active">All projects</Item>
          <Item>Vzonex Eco</Item>
          <Item>BSC Eco</Item>
          <Item>Etherum Eco</Item>
        </Category>
        <Border /> */}
        <Category>
          <Dropdown
            transparent={true}
            color={'white100'}
            width={"280px"}
            label={"Category"}
            options={listFilter?.map((el: any) => {
              return {
                label: el?.label,
                value: el?.value
              }
            })}
            defaultValue={"Choose the project category"}
            onChange={handleFilter}
          />
          <div className="_filter_addresss">
            <Dropdown
              transparent={true}
              color={'white100'}
              width={"100%"}
              label="Choose the Province / City"
              options={provices?.map((el: any) => {
                return {
                  label: el?.name,
                  value: el?.code
                }
              })}
              defaultValue={"Province / City"}
              onChange={(e) => onChangeAddress(e, "provices")}
            />
            <Dropdown
              transparent={true}
              color={'white100'}
              width={"100%"}
              label="Districts"
              options={districts?.map((el: any) => {
                return {
                  label: el?.name,
                  value: el?.code
                }
              })}
              defaultValue={"Choose the Districts"}
              onChange={(e) => onChangeAddress(e, "districts")}
            />
            <Dropdown
              transparent={true}
              color={'white100'}
              width={"100%"}
              label="Wards"
              options={wards?.map((el: any) => {
                return {
                  label: el?.name,
                  value: el?.code
                }
              })}
              defaultValue={"Choose the Wards"}
              onChange={(e) => onChangeAddress(e, "wards")}
            />
          </div>
        </Category>
        <Border />
        <Project>
          <Container>
            <Row>
              <Col lg={6} md={12}>
                <Element>
                  <Table
                    titleTable="Ongoing Projects"
                    data={filterProject(projectsByType.opening)}
                    type="opening"
                  ></Table>
                </Element>
              </Col>
              <Col lg={6} md={12}>
                <Element>
                  <Table
                    titleTable="Upcoming Projects"
                    data={filterProject(projectsByType.upcoming)}
                    type="upcomming"
                  ></Table>
                </Element>
              </Col>
              <Element>
                <Table
                  titleTable="Ended Projects"
                  data={filterProject(projectsByType.closed)}
                  type="all"
                ></Table>{" "}
              </Element>
            </Row>
          </Container>
        </Project>
      </Container>
      <CreatePage onClick={() => history.push('create-your-estock')}>
        <img src="/assets/images/icons/createPages.svg" alt="" />
      </CreatePage>
    </Wrapper>
  );
};

export default View;
