import { SpanColor, MarginTop, RightViewContent } from "../../../../styles";
import Input from "components/input";
import Button from "components/button";
import { Controller } from "react-hook-form";

const IDWithdraw = ({
  userBalance,
  control,
  errors,
  setMaxAmount,
  tokenName,
  errorAmount,
  amount,
  handleChange
}) => {
  const stockName = String(tokenName).toUpperCase();

  return (
    <RightViewContent>
      <Controller
        name="to"
        control={control}
        rules={{ required: true }}
        render={({ field }) => {
          return (
            <Input
              transparent={true}
              label="ID Vzonex"
              placeholder="Input Vzonex ID"
              hasError={errors.to}
              {...field}
            />
          );
        }}
      />
      <MarginTop mt="20px" />
     
      <Input
        transparent={true}
        label={`Stock ${stockName} Amount`}
        placeholder="0.00"
        isCurrency={true}
        type="text"
        value={amount}
        hasError={amount > +userBalance[tokenName] || errorAmount}
        suffixIcon={
          <Button
            className={"btn-max-withdraw"}
            onClick={setMaxAmount}
            height="35px"
          >
                  MAX
          </Button>
        }
        onValueChange={(e) => handleChange(e)}
      />

      <MarginTop mt="20px" />
      <p>
        Maximum {stockName} Available:{" "}
        <SpanColor color="red100">{userBalance[tokenName]}</SpanColor>{" "}
        {stockName}
      </p>
      <p>
        You can <SpanColor color="green100">buy</SpanColor> or{" "}
        <SpanColor color="orange100">deposit</SpanColor> {stockName}
      </p>
      <MarginTop mt="20px" />

      <Controller
        name="note"
        control={control}
        render={({ field }) => {
          return (
            <Input
              type={"textarea"}
              height="100px"
              transparent={true}
              label="Message"
              placeholder={`Welcome to ${stockName} Stock`}
              hasError={errors.note}
              {...field}
            />
          );
        }}
      />
    </RightViewContent>
  );
};

export default IDWithdraw;
