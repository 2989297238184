/* eslint-disable react-hooks/exhaustive-deps */
import APIs from "api";
import VButton from "components/button";
import VModal from "components/modal";
import Table from "components/table";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/reducers/hook";
import { getUserProfile } from "redux/reducers/users/users.selector";
import { ModalHistoryWrapper } from "../invests/styles";
import UserService from "api/users";
import { formatNumber } from "utils/common";

const Revenues = () => {
  const columns = [
    {
      key: "type",
      title: "Type",
      render: (item: any) => <span>{item.type}</span>,
    },
    {
      key: "amount",
      title: "Amount",
      render: (item: any) => <span>{item.amount} (VNEX)</span>,
    },
    {
      key: "claimed",
      title: "Claimed",
      render: (item: any) => <span>{item.claimed} (VNEX)</span>,
    },
    {
      key: "Remain",
      title: "Remain",
      render: (item: any) => <span>{+item.amount - item.claimed}</span>,
    },
    {
      key: "infoRevenue",
      title: "Histories Information Revenue",
      render: (item: any) => (
        <VButton onClick={() => handleLoadHistory(item)}>View</VButton>
      ),
    },
    {
      key: "Histories Claim",
      title: "Histories Claim",
      render: (item: any) => (
        <VButton
          onClick={() => {
            setRevenueSelected(item);
            setOpenModalClaimed(true);
          }}
        >
          View
        </VButton>
      ),
    },
    {
      key: "claim",
      title: "Claim",
      render: (item: any) => (
        <VButton
          loading={loading}
          onClick={() => handleClaim(item)}
          disabled={+item.claimed >= +item.amount || loading}
        >
          Claim
        </VButton>
      ),
    },
  ];

  const columnsHistoriesClaimed = [
    {
      key: "date",
      title: "Date",
      render: (item) => new Date(item.date).toLocaleString(),
    },
    {
      key: "amount",
      title: "Amount",
      render: (item) => {
        return `${item.amount} (VNEX)`;
      },
    },
  ];

  const columnsHistoriesRevenue = [
    {
      key: "date",
      title: "Date",
      render: (item) => new Date(item.createdAt || item.date).toLocaleString(),
    },
    {
      key: "id",
      title: "UserId",
      render: (item) => item?.user?.refer_code,
    },
    {
      key: "amount",
      title: "Amount",
      render: (item) => {
        return `${formatNumber(item.amount)} (${String(
          item.stock || "VDC"
        ).toUpperCase()})`;
      },
    },
  ];

  const revenueHistoryTabs = [
    {
      icon: <img src="/assets/images/icons/history.svg" alt="icon" />,
      name: "Old History",
      type: "old",
    },
    {
      icon: <img src="/assets/images/icons/history.svg" alt="icon" />,
      name: "New History",
      type: "new",
    },
  ];

  const defaultHistory = {
    old: [],
    new: [],
    totalPages: 1,
  };

  const defaultParams = {
    page: 1,
    limit: 20,
  };

  const [revenues, setRevenues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModalClaimed, setOpenModalClaimed] = useState(false);
  const [openModalInfoRevenue, setOpenModalInfoRevenue] = useState(false);
  const [revenueSelected, setRevenueSelected] = useState(null);
  const user = useAppSelector(getUserProfile);
  const [histories, setHistories] = useState(defaultHistory);
  const [activeTab, setActiveTab] = useState("old");
  const [lazyParams, setLazyParams] = useState(defaultParams);
  useEffect(() => {
    getRevenues();
  }, []);

  useEffect(() => {
    if (revenueSelected) {
      getRevenuesHistory();
    }
  }, [lazyParams, revenueSelected]);

  const handleLoadHistory = (item) => {
    setRevenueSelected(item);
    setOpenModalInfoRevenue(true);
  };

  const getRevenuesHistory = async () => {
    try {
      const res: any = await UserService.getRevenuesHistory({
        params: {
          revenueId: revenueSelected._id,
        },
        query: {
          ...lazyParams,
        },
      });
      if (res && res.docs) {
        setHistories({
          old: revenueSelected.histories_received,
          new: res.docs,
          totalPages: res?.totalPages || 1,
        });
      }
    } catch (error) {
      setHistories(defaultHistory);
    }
  };
  const getRevenues = async () => {
    try {
      const res: any = await APIs.USER.getRevenuesInSystem({});
      if (res.revenues) {
        setRevenues(res.revenues);
      }
    } catch (error) {
      // console.log();
    }
  };

  const handleClaim = async (revenue) => {
    try {
      setLoading(true);
      const { type } = revenue;
      await APIs.USER.claimRevenueSystem({
        body: {
          type,
        },
      });
      setLoading(false);
      getRevenues();
      toast.success("Claim success!");
    } catch (error) {
      toast.error(error?.errors);
      setLoading(false);
    }
  };

  const renderModalClaimed = (investSelected: any) => {
    if (investSelected) {
      return (
        <ModalHistoryWrapper>
          {" "}
          <Table
            columns={columnsHistoriesClaimed}
            dataSource={investSelected.histories || []}
            bgTable={"black100"}
          ></Table>
        </ModalHistoryWrapper>
      );
    }
  };

  const renderModalRevenue = (investSelected: any) => {
    if (investSelected) {
      return (
        <ModalHistoryWrapper>
          {" "}
          <Table
            columns={columnsHistoriesRevenue}
            dataSource={activeTab === "old" ? histories.old : histories.new}
            bgTable={"black100"}
          ></Table>
        </ModalHistoryWrapper>
      );
    }
  };

  const handleClosed = () => {
    setOpenModalClaimed(false);
    setOpenModalInfoRevenue(false);
    setRevenueSelected(null);
  };

  return (
    <>
      <div>
        {user.is_leader && <p>Percent Sale : {user.percent_sales || 0}%</p>}
        {user.id_region && <p>Percent Region : {user.percent_region || 0}%</p>}
        {user.id_province && (
          <p>Percent Provice : {user.percent_provice || 0}%</p>
        )}

        <Table bgTable={"black100"} columns={columns} dataSource={revenues} />
      </div>
      <VModal
        isOpen={openModalClaimed}
        children={renderModalClaimed(revenueSelected)}
        onCancel={handleClosed}
        centered={true}
      />
      <VModal
        isOpen={openModalInfoRevenue}
        children={renderModalRevenue(revenueSelected)}
        onCancel={handleClosed}
        centered={true}
        tabs={revenueHistoryTabs}
        activeTab={activeTab}
        onChangeTab={setActiveTab}
        isPaginate={activeTab === "new" && histories?.totalPages > 1}
        lazyParams={lazyParams}
        onChangeParams={setLazyParams}
        totalPages={histories.totalPages}
      />
    </>
  );
};

export default Revenues;
