/* eslint-disable react-hooks/exhaustive-deps */
import { OptionRole, WithRole, FormDescription } from "../styles";

import { useHistory } from "react-router-dom";
import { ROUTES } from "config/routes";

interface IProps {
  [key: string]: any;
}

export default function View(props: IProps) {
  const history = useHistory();

  const hanldeClick = (value: string) => {
    if (value === "event") {
      history.push(ROUTES.SMART_EVENTS);  
      return;
    }
    history.push(`/create-your-estock/${value}`);
  };
  return (
    <>
      <FormDescription>
        Blockchain technology platform building project to help students,
        individuals, organizations and businesses Create and share ideas to find
        investors using Blockchain technology
      </FormDescription>
      <OptionRole>
        <WithRole onClick={() => hanldeClick("personal")}>
          <img src="/assets/images/canhan.png" alt="" />
          <span>Personal</span>
        </WithRole>
        <WithRole onClick={() => hanldeClick("business")}>
          <img src="/assets/images/doanhnghiep.png" alt="" />
          <span>Business</span>
        </WithRole>
        <WithRole onClick={() => hanldeClick("organization")}>
          <img src="/assets/images/hiephoi.png" alt="" />
          <span>Organization</span>
        </WithRole>
        <WithRole onClick={() => hanldeClick("event")}>
          <img src="/assets/images/sukien.png" alt="" />
          <span>Event</span>
        </WithRole>
        <WithRole onClick={() => hanldeClick("student")}>
          <img src="/assets/images/vzx-student-icon.png" alt="" />
          <span>Student</span>
        </WithRole>
      </OptionRole>
    </>
  );
}