/* eslint-disable import/no-anonymous-default-export */
import { request, parseErrorResponse } from "./request";

export const getPackages = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/packages/?status=${req.query.status}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

// export const postPerform = (req) =>
//   new Promise((resolve, reject) => {
//     request()
//       .post("/invests/" + req.params.id, req.body)
//       .then((res) => {
//         const { data } = res.data;
//         if (data) resolve(data);
//         else reject(new Error("Something went error!"));
//       })
//       .catch(parseErrorResponse)
//       .then(reject);
//   });

export default {
  getPackages,
};
