/* eslint-disable react-hooks/exhaustive-deps */
import {
  Container,
  TopMain,
  EndBorder,
  MiddleMain,
  BottomMain,
  ContainIcon,
  Facebook,
  Twitter,
  ContainButton,
  Content,
  URLContract,
  URL,
  Vdiarybook,
} from "./styles";
import { FormGroup } from "reactstrap";
import Button from "components/button";
import Input from "components/input";
import { getUserProfile } from "redux/reducers/users/users.selector";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import {
  getCommissionsAsync,
  getSharingsAsync,
} from "redux/reducers/users/users.actions";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import QRCode from "react-qr-code";

const View = () => {
  const dispatch = useAppDispatch();

  const [state, setState] = useState({
    contact_link: "",
  });

  const user = useAppSelector(getUserProfile);

  useEffect(() => {
    if (user && user._id) {
      dispatch(getCommissionsAsync({}));
      dispatch(getSharingsAsync({}));
      setState({
        ...state,
        contact_link: `https://${window.location.host}/auth/register?refer_code=${user.refer_code}`,
      });
    }
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(state.contact_link);
    toast.success("Copied to clipboard!");
  };

  return (
    <Container color={"black100"}>
      <Content>
        <TopMain>
          {user.sponsor && (
            <div className="contain-user">
              <h4>Sponsor</h4>
              <span>Username: {user.sponsor.username}</span>
              <span>Email: {user.sponsor.email}</span>
              <span>Refer code: {user.sponsor.refer_code}</span>
            </div>
          )}
          <h4>Invite Your Contact</h4>
          <div>
            <img alt="" src="/assets/images/icons/share.svg" />
            <span>Share this link to your contact</span>
          </div>
          <p>Clicking this button will copy the URL to the user's clipboard</p>
          <URLContract>
            <Button onClick={handleCopy}>Copy URL</Button>
            <URL>
              <Input transparent={true} value={state.contact_link} />
            </URL>
          </URLContract>
          <div className="qr-code-affilate">
            <QRCode
              value={`https://${window.location.host}/auth/register?refer_code=${user.refer_code}`}
              size={120}
            />
            <div className="icon_qr">
              <img
                style={{
                  width: `25px`,
                  height: `25px`,
                  margin: "auto",
                }}
                src="https://vzonex.s3.ap-southeast-1.amazonaws.com/uploads/3f0bdd4b-9a37-469e-bd08-6c84f4ff8508.png"
                alt=""
              />
            </div>
          </div>
        </TopMain>
        <EndBorder></EndBorder>
        <MiddleMain>
          <div>
            <img alt="" src="/assets/images/icons/share.svg" />
            <span>Share your code on</span>
          </div>
          <ContainIcon>
            <Facebook>
              <img alt="" src="/assets/images/icons/facebook.svg" />
              <span>Facebook</span>
            </Facebook>
            <Twitter>
              <img alt="" src="/assets/images/icons/twitter.svg" />
              <span>Twitter</span>
            </Twitter>
            <Vdiarybook>
              <img alt="" src="/assets/images/social/vdiarybook.png" />
              <a
                href="https://vdiarybook.vn/"
                target={"_blank"}
                rel="noreferrer"
              >
                Vdiary Book
              </a>
            </Vdiarybook>
          </ContainIcon>
        </MiddleMain>
        <EndBorder></EndBorder>
        <BottomMain>
          <div>
            <img alt="" src="/assets/images/icons/mail.svg" />
            <span>Invite your friends by your mail</span>
          </div>
          <p>Enter one email by line and click send.</p>
          <FormGroup>
            <Input id="exampleText" name="text" type="textarea" />
          </FormGroup>
          <ContainButton>
            <Button>Send</Button>
          </ContainButton>
        </BottomMain>
      </Content>

      {/* <History>
        <Title bgColor={"grey200"}>My Earning</Title>
        <Table
          columns={columns}
          dataSource={commissions}
          bgTable={"black100"}
          bgHeader={"black100"}
        ></Table>

        <Title bgColor={"grey200"}>My Sharing</Title>
        <Table
          columns={columns}
          dataSource={sharings}
          bgTable={"black100"}
          bgHeader={"black100"}
        ></Table>
      </History> */}
    </Container>
  );
};

export default View;
