/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import APIs from "api";
import Button from "components/button";
import Table from "components/table";
import { useEffect, useState } from "react";
import { Wrapper } from "./styles";

import Container, { TextColor } from "pages/my/my-project/styles";
import Modal from "components/modal";
import Switch from "react-switch";
import SurveyItem from "../components/survey/components/survey-item";
import { ContainerImage, ContainerRegisterItem, ItemChecked } from "../styles";
import { AiOutlineEye, AiOutlineClose } from "react-icons/ai";
import { TiTick } from "react-icons/ti";
import { toast } from "react-toastify";
import ListIconComponents from "../components/survey/components/list-icon";
import { useForm } from "react-hook-form";

import FromAddIDManagers from "components/add-page-managers";
import { useAppSelector } from "redux/reducers/hook";
import { getUserProfile } from "redux/reducers/users/users.selector";
import {
  getEventRoute,
  getEventTypeFromObj,
  getEventTypeFromRoute,
} from "../components/constants";

const optionManagersSection = {
  title: {
    title: "Thanh tiêu đề",
  },
  organization: {
    title: "Đơn vị tổ chức",
  },
  event_calendars: {
    title: "Lịch làm việc",
  },
  guest: {
    title: "Đại biểu khách mời - Diễn giả",
  },
  sponsor_companion: {
    title: "Đơn vị tài trợ / đồng hành - truyền thông",
  },
  doc: {
    title: "Tài liệu",
  },
  video: {
    title: "Video",
  },
  survey: {
    title: "Khảo sát",
  },
  review: {
    title: "Bảng đánh giá",
  },
  wallpaper: {
    title: "Nền VIP",
  },
};

const View = () => {
  const profile: any = useAppSelector(getUserProfile);

  const eventType = getEventTypeFromRoute(window.location.pathname);

  const columns = [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      render: (item: any) => <span>{item?.event?.name}</span>,
    },
    {
      key: "banner",
      title: "Banner",
      dataIndex: "banner",
      render: (item: any) => (
        <span>
          {item?.event?.banner?.link
            ? "link"
            : item?.event?.banner?.slide?.length > 0
            ? "slide"
            : ""}
        </span>
      ),
    },
    {
      key: "is_vip_event",
      title: "Vip",
      dataIndex: "is_vip_event",
      render: (item: any) => (
        <span>{item?.is_vip_event ? "Vip" : "Normal"}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (data: any) => (
        <TextColor
          color={
            data?.status === "approved"
              ? "green100"
              : data?.status === "pending"
              ? "yellow100"
              : data?.status === "declined"
              ? "red100"
              : "white100"
          }
        >
          {data?.status}
        </TextColor>
      ),
    },
    {
      key: "on_off",
      title: "On/Off Status",
      dataIndex: "on_off",
      render: (item: any) => (
        <Switch
          onChange={(e) => handleChangeStatusByTrustUser(e, item._id)}
          checked={item.status === "approved"}
        />
      ),
    },
    {
      key: "createdAt",
      title: "Created At",
      dataIndex: "createdAt",
      render: (item: any) => (
        <span>{new Date(item?.createdAt).toLocaleString("en-GB")}</span>
      ),
    },
    {
      key: "id_user",
      title: "Add Managers",
      dataIndex: "id_user",
      render: (item: any) => (
        <Button
          height="30px"
          disabled={item?.user_id !== profile?._id}
          onClick={() => handleUserManagers(item)}
        >
          Add Managers
        </Button>
      ),
    },
    {
      key: "action",
      title: "Action",
      dataIndex: "action",
      render: (item: any) => (
        <>
          <Button height="30px" onClick={() => handleAction(item, "view")}>
            View
          </Button>
          <Button
            height="30px"
            disabled={item?.status === "closed" || item?.status === "opening"}
            onClick={() => handleAction(item, "edit")}
          >
            Edit
          </Button>
          <Button
            height="30px"
            disabled={!(item?.pendingRegisterItems?.length > 0)}
            onClick={() =>
              handleAction(item, "product", item?.pendingRegisterItems)
            }
          >
            Register Items
          </Button>
        </>
      ),
    },
  ];

  const { setValue } = useForm();

  const [events, setEvents] = useState<any>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalItem, setOpenModalItem] = useState<boolean>(false);
  const [openModalEventManagers, setOpenModalEventManagers] =
    useState<boolean>(false);
  const [dataTableRegisterItems, setDataTableRegisterItems] = useState<any>([]);
  const [dataSurveyItem, setDataSurveyItem] = useState<any>({});
  const [eventSelectedModal, setEventSelectedModal] = useState<any>({});

  const [loading, setLoading] = useState<boolean>(false);

  const columnsTableRegister = [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email",
    },
    {
      key: "phone",
      title: "Phone",
      dataIndex: "phone",
    },
    {
      key: "surveyId",
      title: "Name survey",
      dataIndex: "surveyId",
      render: (data: any) => (
        <span>
          {eventSelectedModal?.event?.surveys?.map((el) =>
            el.id === data.serveyId ? el.title : ""
          )}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (data: any) => (
        <TextColor
          color={
            data?.status === "approved"
              ? "green100"
              : data?.status === "pending"
              ? "yellow100"
              : data?.status === "declined"
              ? "red100"
              : "white100"
          }
        >
          {data?.status}
        </TextColor>
      ),
    },
    {
      key: "view_product",
      title: "Action",
      dataIndex: "view_product",
      render: (item: any) => (
        <>
          <Button
            height="30px"
            onClick={() =>
              handleAction(item.id, "view_survey_item", item.registerData)
            }
          >
            <AiOutlineEye size={20} />
          </Button>
          <Button
            height="30px"
            onClick={() =>
              handleChangeStatusItem(
                item,
                item.status !== "approved" ? "approved" : "declined"
              )
            }
          >
            {item.status !== "approved" ? (
              <TiTick size={20} color={"green"} />
            ) : (
              <AiOutlineClose size={20} color={"red"} />
            )}
          </Button>
        </>
      ),
    },
  ];

  const openTagWindow = () => {
    window.open(dataSurveyItem?.listIcon?.link);
  };

  const handleUserManagers = (event: any) => {
    setOpenModalEventManagers(true);
    setEventSelectedModal(event);
    setValue("refer_code", "");
  };

  const handleAction = (event: any, type: string, data?: any) => {
    if (type === "product") {
      setEventSelectedModal(event);
      setOpenModal(true);
      setDataTableRegisterItems(data);
    } else if (type === "view_survey_item") {
      setOpenModalItem(true);
      setDataSurveyItem(data);
    } else {
      const token = localStorage.getItem("auth");
      if (token) {
        const route = getEventRoute(event, "root");

        window.open(
          `${route}/${event?._id}?token=${token}${
            type === "edit" ? "&isEdit=true" : ""
          }`
        );
      }
    }
  };

  const handleChangeStatusByTrustUser = async (
    checked: boolean,
    id: string
  ) => {
    try {
      const res: any = await APIs.EVENT.changeStatusEventById({
        params: {
          id: id,
        },
        body: {
          status: checked ? "approved" : "declined",
        },
      });
      if (res) {
        toast.success("Successfull!");
        getMyEvent();
      }
    } catch (error) {
      toast.error(error.errors || "Error!");
    }
  };

  const handleEventManagers = async (
    value: any,
    dataManagersSection: any,
    type: string
  ) => {
    try {
      setLoading(true);
      if (!["add", "delete", "edit"].includes(type))
        return toast.error("Error!");

      const res = await APIs.EVENT.addEventManagers({
        params: {
          id: eventSelectedModal._id,
        },
        body: {
          refer_code: value.refer_code,
          sectionManagers: dataManagersSection,
          type: type,
        },
      });
      if (res) {
        setLoading(false);
        toast.success("Successfull!");
        setValue("refer_code", "");
        getMyEvent();
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.errors || "Error!");
    }
  };

  const getMyEvent = async () => {
    try {
      let myEvent: any = await APIs.EVENT.getEventByUserId();

      if (myEvent) {
        console.log("eventType", eventType);

        myEvent = myEvent.filter(
          (event) => getEventTypeFromObj(event) === eventType
        );

        setEvents(myEvent);
        const event = myEvent.find((el) => el._id === eventSelectedModal._id);
        setDataTableRegisterItems(event.pendingRegisterItems);
        setEventSelectedModal(event);
      }
    } catch (error) {
      // console.log();
    }
  };

  const handleChangeStatusItem = async (data: any, status: string) => {
    try {
      const changeStatus: any = await APIs.EVENT.changeStatusRegisterItem({
        params: {
          id: eventSelectedModal._id,
        },
        body: {
          data: data,
          status: status,
        },
      });
      if (changeStatus) {
        getMyEvent();
        toast.success(`${status} successful!`);
      }
    } catch (error) {
      toast.error(`${status} error!`);
    }
  };

  useEffect(() => {
    getMyEvent();
  }, [eventType]);

  const renderModalFrom = () => {
    return (
      <ContainerRegisterItem>
        <Table
          bgTable="blue300"
          columns={columnsTableRegister}
          dataSource={dataTableRegisterItems}
        />
      </ContainerRegisterItem>
    );
  };

  const renderModalSurveyItem = () => {
    return (
      <ContainerRegisterItem>
        <ContainerImage isEdit={false}>
          <SurveyItem
            isEdit={false}
            type="single"
            item={dataSurveyItem}
            openTagWindow={openTagWindow}
          />
          <ItemChecked className="_container_checked">
            <span className="items_checked">
              <ListIconComponents item={dataSurveyItem.listIcon} />
            </span>
          </ItemChecked>
        </ContainerImage>
      </ContainerRegisterItem>
    );
  };

  const renderEventManagers = () => {
    return (
      <ContainerRegisterItem>
        <FromAddIDManagers
          page={"event"}
          data={eventSelectedModal.eventManagers}
          dataManagersWithSection={
            eventSelectedModal?.eventManagersWithSections || {}
          }
          optionSection={optionManagersSection}
          loading={loading}
          onSubmit={handleEventManagers}
        />
      </ContainerRegisterItem>
    );
  };

  return (
    <>
      <Container>
        {events.length > 0 ? (
          <Wrapper>
            <Table
              bgTable="blue300"
              columns={
                profile.permissions?.["events.trust"]
                  ? columns
                  : columns.filter((el) => el.key !== "on_off")
              }
              dataSource={events}
            />
          </Wrapper>
        ) : (
          <div style={{ textAlign: "center" }}>No events</div>
        )}
      </Container>
      <Modal
        centered={true}
        isOpen={openModal}
        title="Register Survey"
        children={renderModalFrom()}
        // onConfirm={handleSubmit(handleSubmitRegister)}
        onCancel={() => setOpenModal(false)}
      />
      <Modal
        centered={true}
        isOpen={openModalItem}
        title="Product"
        children={renderModalSurveyItem()}
        // onConfirm={handleSubmit(handleSubmitRegister)}
        onCancel={() => setOpenModalItem(false)}
      />
      <Modal
        centered={true}
        isOpen={openModalEventManagers}
        title="Event Managers"
        children={renderEventManagers()}
        // onConfirm={handleSubmit(handleSubmitRegister)}
        onCancel={() => setOpenModalEventManagers(false)}
      />
    </>
  );
};

export default View;
