import { Loading } from "./styles";
import { Spinner } from "reactstrap";

const View = (props: any) => {
  const { type } = props;

  if (type === "sm") {
    return <Spinner size="sm">...</Spinner>;
  }

  return (
    <Loading>
      <img src="/assets/images/loading.gif" alt="" />
    </Loading>
  );
};

export default View;
