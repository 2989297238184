/* eslint-disable react/jsx-no-target-blank */
import Table from "components/table";
import { HistoryShop } from "../../styles";
import { getPurchasesAsync } from "redux/reducers/purchases/purchases.actions";
import { getPurchaseProducts } from "redux/reducers/purchases/purchases.selector";
import { useAppDispatch } from "redux/reducers/hook";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const View = () => {
  const columns = [
    { key: "id", title: "ID", dataIndex: "_id" },
    {
      key: "invoice",
      title: "Invoice",
      render: (item: any) => (
        <a
          href={`/invoice/order/${item.product_id?.name}/${item._id}`}
          target={"_blank"}
          rel="noreferrer"
        >
          View
        </a>
      ),
    },
    {
      key: "product",
      title: "Product",
      render: (item) => {
        return `${item.product_id?.name}`;
      },
    },
    {
      key: "price",
      title: "Price",
      render: (item) => {
        return `${item.product?.price}`;
      },
    },
    {
      key: "amount",
      title: "Amount",
      render: (item) => {
        return `${item.product?.quantity}`;
      },
    },
    {
      key: "total",
      title: "Total",
      render: (item) => {
        return `${item.product.total}`;
      },
    },
    {
      key: "code",
      title: "Code",
      render: (item) => {
        return `${item.product.code}`;
      },
    },
    {
      key: "link",
      title: "Link to use",
      render: (item) => {
        const code =
          (item?.product_id?.codes || []).find(
            (c) => c.code === item.product.code[0]
          ) || {};
        const link =
          item.product_id?.method === "sync"
            ? "https://vdiarybook.vn/"
            : code.link;
        return (
          <a href={link} target={"_blank"}>
            CLICK TO USE
          </a>
        );
      },
    },
    {
      key: "time",
      title: "Time",
      render: (item) => {
        return `${new Date(item.createdAt).toLocaleDateString()}`;
      },
    },
    {
      key: "Hash",
      title: "Hash",
      render: (item: any) => (
        <a
          href={`${
            process.env.REACT_APP_VZX_SCAN || "https://block.vzonex.com"
          }/tx/${item.product.hash}`}
          target={"_blank"}
          rel="noreferrer"
        >
          {item.product.hash?.slice(0, 20)}...
        </a>
      ),
    },
  ];

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      getPurchasesAsync({
        params: {
          type: "product",
        },
      })
    );
  }, [dispatch]);

  const orders = useSelector(getPurchaseProducts);

  return (
    <HistoryShop>
      <div>
        <Table
          columns={columns}
          dataSource={orders}
          bgTable={"black100"}
        ></Table>
      </div>
    </HistoryShop>
  );
};

export default View;
