/* eslint-disable no-empty-pattern */
import { createSlice } from "@reduxjs/toolkit";
import { getDepositBanks, getMyBanks, createMyBank } from "./banks.actions";

export interface IBanksState {
  depositBanks: any[];
  myBanks: any[];
}

const initialState: IBanksState = {
  depositBanks: [],
  myBanks: [],
};

export const banksSlice = createSlice({
  name: "banks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDepositBanks.pending, (state) => {})
      .addCase(getDepositBanks.fulfilled, (state, action) => {
        state.depositBanks = action.payload;
      })
      .addCase(getMyBanks.fulfilled, (state, action) => {
        state.myBanks = action.payload;
      })
      .addCase(createMyBank.fulfilled, (state, action) => {
        state.myBanks.push(action.payload);
      });
  },
});

export const {} = banksSlice.actions;

export default banksSlice.reducer;
