/* eslint-disable no-empty-pattern */
import { createSlice } from "@reduxjs/toolkit";
import { getPackagesAsync } from "./invests.actions";

export interface IInvestState {
  packages: any;
}

const initialState: IInvestState = {
  packages: [],
};

export const InvestsSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPackagesAsync.pending, (state) => {})
      .addCase(getPackagesAsync.fulfilled, (state, action) => {
        const { invests, errors } = action.payload;

        if (errors) {
          return;
        }
        state.packages = invests;
      });
  },
});

export const {} = InvestsSlice.actions;

export default InvestsSlice.reducer;
