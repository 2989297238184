/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Container } from "./styles";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { getTasksAsync } from "redux/reducers/tasks/tasks.actions";
import { getTasks } from "redux/reducers/tasks/tasks.selector";
// import { useNavigate } from "react-router-dom";
import Tasks from "./Tasks";
import { getUserProfile } from "redux/reducers/users/users.selector";
import Tabs from "components/tabs";
import { IoMdDoneAll, IoMdCodeWorking } from "react-icons/io";
import { BsListTask } from "react-icons/bs";
import { compareDateWithCur } from "utils/times";

const View = () => {
  const listTasks = useAppSelector(getTasks);
  const user = useAppSelector(getUserProfile);
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  const [tab, setTab] = useState<string>("new");
  const [tasks, setTasks] = useState({
    news: [],
    doing: [],
    done: [],
  });

  useEffect(() => {
    dispatch(getTasksAsync({}));
  }, [dispatch]);

  useEffect(() => {
    if (listTasks && listTasks.length >= 0) {
      const news = listTasks.filter(
        (task) =>
          !task.participants ||
          (!task.participants[user._id] && compareDateWithCur(task.time.end_date) > 0)
      );
      const doing = listTasks.filter(
        (task) =>
          task.participants &&
          task.participants[user._id] &&
          task.participants[user._id]["status"] === "doing" &&
          compareDateWithCur(task.time.end_date) > 0
      );
      const done = listTasks.filter(
        (task) =>
          task.participants &&
          task.participants[user._id] &&
          task.participants[user._id]["status"] === "done"
      );

      setTasks({
        news,
        doing,
        done,
      });
    }
  }, [listTasks]);

  return (
    <Container>
      <Tabs
        active={tab}
        tabs={[
          {
            name: "new",
            render: (
              <>
                <BsListTask size={17} /> <span>Task List</span>
              </>
            ),
          },
          {
            name: "doing",
            render: (
              <>
                <IoMdCodeWorking size={17} /> <span>Doing</span>
              </>
            ),
          },
          {
            name: "done",
            render: (
              <>
                <IoMdDoneAll size={17} /> <span>Done</span>
              </>
            ),
          },
        ]}
        handleTab={setTab}
      />

      {tab === "new" && <Tasks listTasks={tasks.news || []} />}
      {tab === "doing" && <Tasks listTasks={tasks.doing || []} />}
      {tab === "done" && <Tasks type="done" listTasks={tasks.done || []} />}
    </Container>
  );
};

export default View;
