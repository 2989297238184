import { isMobile } from "react-device-detect";
import styled, { css } from "styled-components";

const Wrapper = styled.div<{ bgColor: string; hasWallpaper?: boolean }>`
  width: 100%;
  height: auto;
  margin: auto;
  background-color: ${(props) =>
    props.hasWallpaper ? "unset" : props.bgColor || props.theme.colors.blue200};
  position: relative;
  white-space: pre-line;

  .event-wallpaper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
  }

  .event-wallpaper-video {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    transform: scale(1.2);
    width: 100vw;

    @media screen and (max-width: 500px) {
      transform: scale(4);
    }
  }

  .event-wallpaper-has-link {
  }
`;

export const Header = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;

  img {
    width: 100%;
    height: auto;
  }

  ._advertisement {
    width: 22%;
    position: relative;
    max-height: 35vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    /* img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    } */

    ._item_ads {
      width: 100%;
      height: 50%;
      position: relative;

      :nth-child(1) {
        padding-bottom: 5px;
      }

      :nth-child(2) {
        border-top: 3px solid orange;
        padding-top: 5px;
      }
      ._banner_container {
        min-height: 100% !important;
        max-width: 100% !important;
        cursor: pointer;
      }

      ._item_ {
        height: calc(100% - 30px);
      }

      input {
        height: 30px;
        border-radius: 4px;
      }
    }

    ._add_remove_banner_ads {
      margin-top: 20px;
      text-align: center;
      button {
        height: 30px;
        padding: 3px 20px;
        border-radius: 4px;
      }
    }

    @media screen and (max-width: 875px) {
      display: none;
    }
  }

  ._banner_container {
    overflow: hidden;
    cursor: pointer;
    @media screen and (min-width: 876px) {
      max-width: 66%;
      min-height: 35vw !important;
    }
  }
`;

export const Section = styled.div<{
  type?;
  bgColor: string;
  mb?;
  textColor?: string;
  isDisable?: boolean;
  hasWallpaper?: boolean;
}>`
  background-color: ${(props) =>
    `${props.bgColor || props.theme.colors.bgColorLayer1}`};
  margin: 30px 0;
  padding: 30px 15px;
  width: 100%;
  color: ${(props) => props.textColor || "white"};

  ${({ isDisable }) =>
    isDisable
      ? css`
          pointer-events: none;
          opacity: 0.5;
        `
      : ""}

  ${({ mb }) =>
    mb
      ? css`
          margin-bottom: ${mb};
        `
      : ""}

  @media screen and (min-width: 877px) {
    ${({ type }) =>
    type === "item-flex"
      ? css`
            width: 49%;
          `
      : ""}
  }
`;

export const ContainerItemSurvey = styled.div`
  height: 100%;
  input {
    margin-bottom: 0;
    padding-right: 80px;
  }

  ._suffixIcon {
    margin-right: -10px;
    border-radius: 4px;
  }
`;

export const ContainerItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  input {
    height: 30px;
    width: 100%;
  }
`;

export const Title = styled.div<{ mb?; type?; color: string }>`
  text-align: center;
  overflow-wrap: break-word;
  color: white;
  width: 100%;

  ${({ mb }) =>
    mb
      ? css`
          margin-bottom: ${mb};
        `
      : ""}

  h2 {
    color: ${(props) => props.color || props.theme.colors.orange100};
    margin-bottom: 10px;
    font-size: ${isMobile ? "22px" : "30px"};
  }

  h3 {
    color: ${(props) => props.color || props.theme.colors.orange100};
    margin-bottom: 40px;
    font-size: ${isMobile ? "15px" : "22px"};
  }

  h4 {
    color: ${(props) => props.color || props.theme.colors.orange100};
    font-size: 18px;
    width: fit-content;
    margin: auto;
  }

  @media screen and (min-width: 1000px) {
    ${({ type }) =>
    type === "organizational"
      ? css`
            display: none;
          `
      : ""}
  }
`;

export const TitleOrg = styled.div<{ color: string; isShow?: boolean }>`
  text-align: center;
  overflow-wrap: break-word;
  color: white;
  width: 25%;
  ${({ isShow }) =>
    !isShow
      ? css`
          display: none;
        `
      : ""}

  h4 {
    color: ${(props) => props.color || props.theme.colors.orange100};
    font-size: 18px;
    width: fit-content;
    margin: auto;
  }

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

export const Content = styled.div`
  max-width: 1200px;
  margin: auto;
  position: relative;
`;

export const ItemFlex = styled.div<{
  width?;
  just_content?;
  alignItem?: string;
  isColumn?: boolean;
  isNoWrap?: boolean;
}>`
  width: ${(props) => props.width || "100%"};
  display: flex;
  justify-content: ${(props) => props.just_content};
  align-items: ${(props) => props.alignItem};
  flex-wrap: ${(props) => (props.isNoWrap ? "nowrap" : "wrap")};

  @media screen and (max-width: 876px) {
    ${({ isColumn }) => {
    return isColumn
      ? css`
            flex-direction: column;
            justify-content: center;
          `
      : "";
  }}
  }

  @media screen and (max-width: 768px) {
    justify-content: space-around;
  }
`;

export const Statistical = styled.div`
  text-align: center;
  @media screen and (max-width: 876px) {
    margin-bottom: 40px;
  }
`;

export const Action = styled.button<{ bgColor: string }>`
  border: none;
  outline: none;
  padding: 8px 15px;
  color: white;
  background-color: ${(props) => props.theme.colors[props.bgColor]};
  border-radius: 4px;
  font-size: 14px;
  margin-right: 10px;

  svg {
    margin-left: -8px;
    margin-right: 3px;
  }

  &:hover {
    filter: brightness(120%);
  }

  @media screen and (max-width: 450px) {
    padding: 8px 5px;
  }
`;

export const DateEvent = styled.div`
  /* width: 45%; */
  .countdown-section {
    padding-bottom: 10px;

    .countdown-item-value {
      height: 50px !important;
      border-radius: 10px !important;
      width: 60px !important;
      background: #243959 !important;
    }
  }
`;

export const Status = styled.div<{ color: string }>`
  text-align: center;
  font-size: 24px;
  margin-bottom: 10px;
  color: ${(props) => props.theme.colors[props.color] || "white"};
`;

export const DateItem = styled.div<{ textColor: string }>`
  color: ${(props) => props.textColor || "white"};
  margin-bottom: 5px;
  text-align: center;

  > label {
    width: 70px;
  }

  > div {
    display: inline-block;
    margin-left: 20px;

    @media screen and (max-width: 430px) {
      margin-left: 0;
    }
  }
`;

export const DateBox = styled.button<{
  ml?;
  textColor?: string;
  noBg?: boolean;
}>`
  width: 47px;
  border: none;
  outline: none;
  padding: 4px 10px;
  color: ${(props) => props.textColor || "white"} !important;
  background-color: #243959;
  border-radius: 4px;
  font-size: 12px;
  margin-right: 6px;
  font-weight: bold;

  ${({ ml }) => {
    return ml
      ? css`
          margin-left: ${ml};
        `
      : "";
  }}

  ${({ noBg }) => {
    return noBg
      ? css`
          font-weight: normal;
          color: grey;
          background-color: transparent;
        `
      : "";
  }}

  &:hover {
    filter: brightness(120%);
  }

  @media screen and (max-width: 401px) {
    margin-right: 3px;

    ${({ ml }) => {
    return ml
      ? css`
            margin-left: 6px;
          `
      : "";
  }}
  }
`;

export const HeaderRunIcon = styled.div<{bgColor?: string}>`
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 55px;
  width: 100%;
  background-color: ${(props) => props.bgColor ? props.bgColor : 'rgb(217 239 204 / 35%)'};

  button {
    position: absolute;
    top: 3px;
    left: 10px;
    height: 30px;
    z-index: 99999;
    padding: 3px 10px;
    border-radius: 4px;
  }

  .marquee {
    width: 100%;
  }

  ._listIcon {
    display: flex;
    flex-direction: row;
    height: 100%;
    max-height: 55px;
    padding: 5px;
    width: max-content;

    img {
      margin: 0 20px;
      height: 100%;
      width: auto;
    }

    @media screen and (max-width: 640px) {
      max-height: 40px;
    }
  }

  @media screen and (max-width: 640px) {
    height: 40px;
  }
`;

export const Organization = styled.div<{ width?; type?: string }>`
  margin: 0 -10px;
  position: relative;
  width: ${(props) => props.width || "100%"};

  @media screen and (max-width: 1000px) {
    width: ${(props) => (props.type === "organizational" ? "100%" : "100%")};
  }
`;

export const OrganizationItem = styled.div<{ size?: string }>`
  position: relative;
  width: 90px;
  height: 90px;
  overflow: hidden;
  margin: 15px 15px;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #707070;

  ${({ size }) =>
    size
      ? css`
          width: ${size};
          height: ${size};
        `
      : ""}

  img {
    width: 100%;
    height: auto;
  }
`;

export const FooterEvent = styled.div`
  padding: 20px;

  h5 {
    font-size: 14px;
  }
`;

export const ImageFooter = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    width: auto;
    height: 100%;
  }
`;

export const ListSurvey = styled.div`
  padding: 30px 20px;
  color: white;
  background-color: #bdbdbd3b;
  width: 100%;
  max-width: 670px;
  margin: 20px auto;
  border-radius: 10px;

  h5 {
    position: relative;
    width: 240px;
    margin-bottom: 30px;
  }

  input {
    margin-left: 10px;
    margin-bottom: 0px;
  }
`;

export const ItemEdit = styled.div`
  input {
    margin-bottom: 10px;
  }

  ._add_icon {
    margin: 20px;
  }

  ._show_hide {
    button {
      height: 30px;
      padding: 3px 10px;
    }
  }
`;

export const ContainerRegisterItem = styled.div`
  padding: 20px 10px;
  margin-bottom: 30px;
  max-height: calc(calc(var(--vh, 1vh) * 100) - 240px);
  overflow: auto;
  button {
    padding: 3px 10px;
    margin: 0px 3px;
    border-radius: 4px;
  }

  ._container_checked {
    justify-content: end;
  }
`;

export const ItemSurvey = styled.div<{
  colorTitle;
  colorLabel;
  bgColor: string;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: ${(props) => props.bgColor || props.theme.colors.blue300};
  margin-bottom: 10px;
  position: relative;
  border-radius: 4px;
  padding: 10px 0px;

  .form-check {
    padding: 0;
    width: 65%;

    label {
      color: ${(props) => props.colorLabel || "white"};
      word-break: break-word;
      white-space: pre-line;
    }
  }

  .form-check-label {
    text-overflow: ellipsis;
    display: flex;
    align-items: center;

    input {
      min-width: 16px !important;
      margin: 0 10px;
    }
  }

  .items_checked {
    font-size: 14px;
    margin-left: 10px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: 10px;

    p,
    h6 {
      margin: 0;
      line-height: 100%;
    }

    p {
      font-size: 20px;
    }

    h6 {
      font-size: 14px;
      margin-left: 5px;
      color: ${(props) => props.colorTitle || props.theme.colors.orange100};
    }

    ._list_icon {
      margin-right: 20px;
      display: flex;

      ._icon {
        margin-left: 8px;
        cursor: pointer;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
`;

export const BottomBar = styled.div<{ width?; color: any }>`
  width: ${(props) => `${props.width}%` || "0px"};
  height: 7px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: ${(props) => props.color || props.theme.colors.orange100};
  border-radius: 5px;
`;

export const FooterSurvey = styled.div<{ bgColor?; color?: string }>`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  align-items: center;

  .total_survey {
    border: 1px solid grey;
    border-radius: 5px;
    padding: 7px 10px;
  }

  .submit_survey {
    text-align: center;

    button {
      background-color: ${(props) => props.bgColor};
      color: ${(props) => props.color};
    }
  }
`;

export const Submit = styled.div`
  text-align: center;
  position: fixed;
  left: 5px;
  bottom: 5px;
  z-index: 9999;

  button {
    width: 150px;
    padding: 5px 20px;
  }
`;

export const EditDateTime = styled.div`
  display: inline;
  width: 40px;
  overflow: hidden;
  position: relative;
  direction: rtl;
  margin-left: 10px;

  input {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
  }
`;

export const SurveyContainer = styled.div<{ isEdit?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: ${(props) => (props.isEdit ? "30px" : "0px")};

  table {
    max-width: 700px;
    tr {
      th {
        :last-child {
          width: 110px;
          text-align: center;
        }
      }
    }
    ._ratio_servey {
      text-align: center;
    }
  }
`;

export const OptionChooseType = styled.div<{ mt?: string }>`
  position: absolute;
  top: ${(props) => props.mt || 0};
  left: 0;
  display: flex;
  color: white;

  button {
    height: 24px;
    padding: 0 10px;
    border-radius: 4px;
  }

  .form-check {
    margin-right: 20px;
  }
`;

export const ItemChecked = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
  background-color: #6c757d69;
  color: white;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  .items_checked {
    display: flex;
    align-items: center;
    ._list_icon {
      display: flex;
      ._icon {
        cursor: pointer;
        margin: 0 5px;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .form-check {
    display: flex;
    align-items: center;
    padding: 0;
    height: 100%;

    input {
      margin: 0 10px;
    }

    .form-check-label {
      display: flex;
      align-items: center;
      font-size: 14px;
      :first-child {
        margin-right: 10px;
      }

      svg {
        margin-right: 3px;
      }
    }
  }
`;

export const ItemSurveyDetail = styled.div`
  margin-bottom: 30px;
  border-bottom: 1px dashed grey;
  padding-bottom: 30px;

  :last-child {
    border-bottom: none;
  }
`;

export const ContainerImage = styled.div<{
  isEdit?: boolean;
  color?;
  colorTitle?: string;
}>`
  position: relative;
  width: 100%;
  height: ${(props) => (props.isEdit ? "350px" : "280px")};
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  max-width: 480px;

  input {
    margin-top: 10px;
  }

  ._item_ {
    box-shadow: 0px 0px 4px 1px
      ${(props) => props.colorTitle || props.theme.colors.orange100};
  }

  ._banner_container {
    min-height: 280px !important;
    cursor: pointer;
  }
`;

export const TitleItemSurvey = styled.div<{ color?: string }>`
  margin-top: 10px;
  margin-bottom: 20px;
  color: ${(props) => props.color || "white"};
  text-align: center;
  max-width: 480px;
  word-break: break-all;
`;

export const ContainerForm = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 15px;

  form {
    width: 100%;
  }
`;

export const SubmitSurveyImage = styled.div<{
  colorText?;
  color?: string;
  bgColor?: boolean;
}>`
  text-align: center;
  margin-top: 50px;

  button {
    height: 36px;
    margin: 0 5px;
    background-color: ${(props) => props.color || props.theme.colors.orange100};
    color: ${(props) => props.colorText || "white"};
    border: 1px solid ${(props) => props.colorText || props.bgColor};
  }

  .register_product {
    margin-top: 10px;
    span {
      cursor: pointer;
      font-size: 14px;
      color: ${(props) => props.colorText || "white"};

      &:hover {
        color: ${(props) => props.color || "white"};
      }
    }
  }
`;

export const OptionChangeLimit = styled.div`
  width: 100%;
  max-width: 500px;
  margin: auto;
`;

export const ItemContentSurvey = styled.div`
  position: relative;
  max-width: 400px;
  min-width: 30%;
  width: 100%;
  margin: auto;
  height: 100%;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AddOthersLinkIcon = styled.div`
  position: relative;
  font-size: 20px;
  width: 180px;
`;

export const IconLinkSurvey = styled.div`
  position: relative;
  width: 60px;
  height: 60px;
`;

export const AdminAddBonusView = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 15px;

  input {
    width: 100px;
  }
`;

export default Wrapper;


export const FirstStepForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  padding: 30px;

  .event-options {
    display: flex;
    margin-top: 40px;
  }

  .event-option {
    width: 150px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5px;
    cursor: pointer;

    img {
      width: auto;
      height: 55px;
      margin-bottom: 15px;
    }

    span {
      font-size: 14px;
      color: #FF9900;
    }
  }
`;
