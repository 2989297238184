/* eslint-disable react-hooks/exhaustive-deps */
import {
  Content,
  Count,
  Date,
  IconWrapper,
  ImgWrapper,
  NoticeContent,
  NotificationWrapper,
  View,
  ItemNoti,
} from "./styles";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { getNotificationsSystem } from "redux/reducers/notifications/notifications.selectors";
import { convertDateToAgo } from "utils/times";
import APIs from "api";
import { AiOutlineNotification } from "react-icons/ai";
import { getNotificationsSystemAsync } from "redux/reducers/notifications/notifications.actions";

import ModalPreview from "./components/ModalPreview";

const System = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const notifications = useAppSelector(getNotificationsSystem);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [dataModal, setDataModal] = useState<any>(null);

  const handlePreview = (notice: any) => {
    setDataModal(notice);
    setOpenModal(true);
    setOpen(false);
  };
  const handleClick = async (notify) => {
    try {
      window.location.replace(notify.link);
      await APIs.NOTIFICATION.readNotification({
        params: {
          id: notify._id,
        },
      });
      dispatch(getNotificationsSystemAsync());
    } catch (error) {}
  };

  useEffect(() => {
    dispatch(getNotificationsSystemAsync());
  }, []);

  return (
    <NotificationWrapper>
      <ModalPreview
        dataModal={dataModal}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
      <Dropdown
        toggle={function noRefCheck() {
          setOpen(!open);
        }}
        isOpen={open}
      >
        <DropdownToggle tag="span">
          <IconWrapper>
            <AiOutlineNotification color="white" size={17} />
            <Count>
              <span>
                {notifications &&
                  notifications.filter((n) => n.status === "unread").length}
              </span>
            </Count>
          </IconWrapper>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>
            <div className="title">
              <p>Systems</p>
            </div>
            <div className="icon-dots">
              <BsThreeDotsVertical color="white" />
            </div>
          </DropdownItem>
          {notifications &&
            notifications.map((notice, key) => (
              <ItemNoti key={key}>
                <DropdownItem
                  onClick={() => handleClick(notice)}
                  className={notice.status === "read" ? "read" : "unread"}
                >
                  <NoticeContent>
                    <ImgWrapper>
                      <img src={notice.icon} alt="" />
                    </ImgWrapper>
                    <Content>
                      <h6>{notice.title}</h6>
                      <p>{notice.content}</p>
                    </Content>
                    <Date>{convertDateToAgo(notice.createdAt)}</Date>
                  </NoticeContent>
                </DropdownItem>
                <View onClick={() => handlePreview(notice)}>View</View>
              </ItemNoti>
            ))}
        </DropdownMenu>
      </Dropdown>
    </NotificationWrapper>
  );
};

export default System;
