/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Container,
  Image,
  Tutorial,
  TopMain,
  BottomMain,
  Step,
  Item,
  ContainButton,
  ContainInput,
  Flex,
  Description,
  Title,
} from "./styles";
import { Col, Row } from "reactstrap";
import Button from "components/button";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { useEffect, useState } from "react";
import {
  disable2faAsync,
  enable2faAsync,
  securityAsync,
} from "redux/reducers/securities/securities.action";
import { getSecurity } from "redux/reducers/securities/securities.selector";
import Input from "components/input";
import Switch from "react-switch";
import { getUserProfile } from "redux/reducers/users/users.selector";
import { toast } from "react-toastify";
import { editUserAsync } from "redux/reducers/users/users.actions";
import { signMessage } from "utils/ethers";
import APIs from "api";
import Verify from "pages/verify";
import { getUser } from "redux/reducers/users/users.reducer";

const View = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUserProfile);
  const { is_enable, secret_key, uri } = useAppSelector(getSecurity);
  const [twoFa, setTwoFa] = useState("");
  const [errMessage, setErrMessage] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingPin, setLoadingPin] = useState(false);
  const [emailSecure, setEmailSecure] = useState(false);
  const [loginByAddress, setLoginByAddress] = useState(false);
  const [publicAddress, setPublicAddress] = useState(null);
  const [pinExist, setPinExist] = useState(false);
  const [pinCode, setPinCode] = useState(false);
  const [isReset, setIsReset] = useState(false);

  const [verify, setVerify] = useState({
    email_code: "",
    google_code: "",
  });
  const [openSecure, setOpenSecure] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    if (!twoFa) setErrMessage({ otp: "2Fa incorrect!" });
    if (!is_enable) {
      const res: any = await dispatch(
        enable2faAsync({
          body: {
            otp: twoFa,
          },
        })
      );
      setErrMessage({});
      if (res.payload.errors) {
        setErrMessage({ twoFa: res.payload.errors });
      }
    } else {
      const res: any = await dispatch(
        disable2faAsync({
          body: {
            otp: twoFa,
          },
        })
      );
      setErrMessage({});
      if (res.payload.errors) {
        setErrMessage({ twoFa: res.payload.errors });
      }
    }
    setTwoFa("");
    setLoading(false);
  };

  const createSignature = async () => {
    try {
      const res: any = await signMessage("Create Signature Login");
      setPublicAddress(res.address);
      await dispatch(
        editUserAsync({
          params: {
            id: user._id,
          },
          body: {
            public_address: res.address.toLowerCase(),
          },
        })
      );
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleChangeEmailSecure = async (checked) => {
    setEmailSecure(checked);
    await dispatch(
      editUserAsync({
        params: {
          id: user._id,
        },
        body: {
          email_secure: checked,
        },
      })
    );
  };

  const handleChangePinSecure = async (checked) => {
    setOpenSecure(true);
  };

  const handleLoginByAddress = async (checked) => {
    setLoginByAddress(checked);
    await dispatch(
      editUserAsync({
        params: {
          id: user._id,
        },
        body: {
          login_by_address: checked,
        },
      })
    );
  };

  const handleSubmit = async () => {
    try {
      if (isReset || !pinExist) {
        setLoadingPin(true);
        if (Number.isInteger(+pinCode) && String(pinCode).length === 6) {
          if (!isReset) {
            await APIs.USER.createPinCode({
              body: {
                verify,
                code: pinCode,
              },
            });
          } else
            await APIs.USER.resetPinCode({
              body: {
                verify,
                code: pinCode,
              },
            });
        } else {
          setLoadingPin(false);
          return toast.error("Code is not in the correct format!");
        }
      }
      !isReset &&
        (await APIs.USER.editUserSecure({
          body: {
            verify,
            pin_secure: !user.pin_secure,
          },
        }));
      await dispatch(getUser());
      await getData();
      setIsReset(false);
      setOpenSecure(false);
      setLoadingPin(false);
    } catch (error) {
      setLoadingPin(false);
      toast.error(error.errors);
    }
  };

  useEffect(() => {
    dispatch(securityAsync());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      setEmailSecure(user.email_secure);
      setLoginByAddress(user.login_by_address);
      setPublicAddress(user.public_address);
    }
  }, [user]);

  const getData = async () => {
    try {
      const res = await APIs.USER.checkPinExist({});
      if (res) setPinExist(true);
      else setPinExist(false);
    } catch (error) {
      setPinExist(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container color={"black100"}>
      <TopMain>
        <Title>Signature</Title>

        {!publicAddress ? (
          <Button onClick={createSignature} loading={false}>
            Create
          </Button>
        ) : (
          <>
            {" "}
            <Switch onChange={handleLoginByAddress} checked={loginByAddress} />
            <p>Address : {publicAddress}</p>
          </>
        )}
      </TopMain>

      <TopMain>
        <Title>Email Secure</Title>
        <Switch onChange={handleChangeEmailSecure} checked={emailSecure} />
      </TopMain>

      <TopMain>
        <Title>Set up pin code</Title>
        <Switch onChange={handleChangePinSecure} checked={user.pin_secure} />
        {openSecure && (
          <div className="pin-secure">
            <Verify verify={verify} setVerify={setVerify} />
            {(isReset || !pinExist) && (
              <div className="input-code">
                <label className="details">Pin code</label>
                <Input
                  transparent={true}
                  value={pinCode}
                  onChange={(e) => setPinCode(e.target.value)}
                  type="number"
                  placeHolder={"Please input pin code"}
                />
                <span className="details">Enter the 6 digit code</span>
              </div>
            )}
            <Button onClick={() => handleSubmit()} loading={loadingPin}>
              Submit
            </Button>
            <Button
              onClick={() => {
                setOpenSecure(false);
                setIsReset(false);
              }}
            >
              Cancel
            </Button>
          </div>
        )}
        <br />
        {!openSecure && user.pin_secure && (
          <a
            className="reset"
            onClick={() => {
              setOpenSecure(true);
              setIsReset(true);
            }}
          >
            Reset pin code
          </a>
        )}
      </TopMain>

      <TopMain>
        <h4>Two-Factor Authentication</h4>
        <Row>
          {!is_enable && (
            <Col col lg={5} md={12}>
              <Flex>
                <Image>
                  <img
                    alt=""
                    src={`https://api.qrserver.com/v1/create-qr-code/?size=250*250&data=${uri}`}
                  />
                </Image>
                <Description>
                  <h5>Google Authenticator</h5>
                  <p>
                    Download and install Google Authenticator. Scan the QR code
                    or copy the key.
                  </p>
                </Description>
              </Flex>
              <Input transparent value={secret_key} />
            </Col>
          )}
          <Col col lg={!is_enable ? 7 : 5} md={12}>
            <span>Two-Factor Authentication</span>
            <ContainInput>
              <Input
                transparent={true}
                value={twoFa}
                onChange={(e) => setTwoFa(e.target.value)}
                errMessage={errMessage["twoFa"]}
                hasError={errMessage["twoFa"]}
                type="number"
              />
            </ContainInput>
            <ContainButton>
              <Button onClick={handleClick} loading={loading}>
                {!is_enable ? "Enable" : "Disable"}
              </Button>
            </ContainButton>
          </Col>
        </Row>
      </TopMain>
      <BottomMain>
        <Row>
          <Col col md={6}>
            <Tutorial>
              <h4>How to Anable Authenticator App</h4>
              <p>
                2-step verification uses your mobile phone device to provide an
                extra layer of security for your Hootsuite account. Powered by
                Google Authenticator.
              </p>
              <a
                href="https://support.google.com/accounts/answer/1066447?hl=en&co=GENIE.Platform%3DiOS&oco=1"
                target={"_blank"}
                rel="noreferrer"
              >
                <img alt="" src="/assets/images/icons/authenticator.svg" />
              </a>
            </Tutorial>
          </Col>
          <Col col md={6}>
            <Step>
              <Item>
                <span>1</span>
                <p>
                  Install the Google Authenticator App. For your mobile device.
                  <span> Learn more</span>
                </p>
              </Item>
              <Item>
                <span>2</span>
                <p>
                  Scan the QR code or manually input your secret code into the
                  Google Authenticator App.
                </p>
              </Item>
              <Item>
                <span>3</span>
                <p>
                  Input the 6-degit code that the Google Authenticator App
                  provider.
                </p>
              </Item>
            </Step>
          </Col>
        </Row>
      </BottomMain>
    </Container>
  );
};

export default View;
