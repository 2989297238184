/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import Container, {
  Wrapper,
  Menu,
  UserInfo,
  Avatar,
  Info,
  MenuItem,
  SignOut,
  MenuIcon,
  Logo,
  LogoAndMenuIcon,
  OpenMenuIcon,
  Footer,
  VerifyIcon,
  CerAvt,
  Copy,
  CloseSideBar,
  UserMenu,
  PigWrapper,
} from "./styles";
import { useHistory } from "react-router-dom";
import { isDesktop, isMobile } from "react-device-detect";

import { useAppSelector } from "redux/reducers/hook";
import { getUserProfile, getVerify } from "redux/reducers/users/users.selector";
import {
  getComingSoon,
  getLogo,
} from "redux/reducers/settings/settings.selector";
import { copyText } from "utils/common";

import { ROUTES } from "config/routes";
import { renderTextInDay } from "utils/render";
import { FaSignOutAlt } from "react-icons/fa";

import {
  AiOutlineCloseSquare,
  AiOutlineProject,
  AiOutlineShoppingCart,
  AiOutlineWallet,
} from "react-icons/ai";
import { IoMdCopy, IoMdShare } from "react-icons/io";

import Donate from "layouts/Donate";
import Social from "./Social";
import { toast } from "react-toastify";

import { RiExchangeFundsLine, RiExchangeLine } from "react-icons/ri";
import {
  MdDashboard,
  MdOutlineQuestionAnswer,
  MdOutlineSwapVerticalCircle,
  MdOutlineCampaign,
  MdEvent,
  MdOutlineCreateNewFolder,
  MdOutlineEventNote,
} from "react-icons/md";
import SubMenu from "./SubMenu";
import { GiProfit } from "react-icons/gi";
import { FcStatistics } from "react-icons/fc";
import { BsListTask } from "react-icons/bs";
import { BiSupport } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";

import {
  eventTypeMeta,
  eventType,
  eventPermissions,
} from "pages/events/components/constants";

export default function View() {
  const history = useHistory();
  const coming_soons = useAppSelector(getComingSoon);
  // AiFillBank,

  const verify = useAppSelector(getVerify);
  const profile: any = useAppSelector(getUserProfile);
  const logos = useAppSelector(getLogo);

  const [selectedMenu, setSelectedMenu] = useState(history.location.pathname);

  const [openMenu, setOpenMenu] = useState<boolean>(true);

  const clickMenu = (menu) => () => {
    if (menu.openWindow) {
      if (menu.path.split("smart")[1].indexOf("/create") > -1) {
        const token = localStorage.getItem("auth");
        window.open(`${menu.path}?token=${token}`);
      } else {
        window.open(menu.path);
      }
    } else {
      history.push(menu.path);
    }
    if (isMobile) {
      setOpenMenu(false);
    }
    setSelectedMenu(menu.path);
  };

  const logoutUser = () => {
    localStorage.clear();
    history.push(ROUTES.LOGIN);
  };

  const handleCopy = () => {
    copyText(profile?.refer_code);
    toast.success("Copied to clipboard");
  };

  const comingSoonMenu = useMemo(
    () =>
      coming_soons?.map((item) => ({
        path: item.url,
        name: openMenu && item.name,
        icon: (
          <div className="flex items-center justify-center w-[20px] h-[20px] max-w-[20px] max-h-[20px]">
            <img
              style={{
                width: 20,
                height: 20,
                margin: 0,
              }}
              src={item.icon}
              alt={`${item.name}`}
              className="max-w-[20px] max-h-[20px] !object-cover"
            />
          </div>
        ),
      })),
    [coming_soons]
  );

  useEffect(() => {
    if (isMobile) {
      setOpenMenu(false);
    }
  }, []);

  const renderVerify = () => {
    if (
      verify &&
      (verify.status === "kyc" ||
        verify.status === "pending_verified" ||
        verify.status === "declined_verified")
    ) {
      return <img src="/assets/images/kyc/kyc.png" alt="" />;
    }
    if (verify && verify.status === "verified") {
      return <img src="/assets/images/kyc/veriried.png" alt="" />;
    }
    return <img src="/assets/images/kyc/no.png" alt="" />;
  };

  const menus = [
    {
      path: "/my/dashboard",
      name: "DASHBOARD",
      icon: <MdDashboard size={24} />,
    },
    {
      name: "EXCHANGES",
      isOpen: true,
      icon: <RiExchangeLine size={24} />,
      items: [
        // {
        //   path: ROUTES.MY_BY_SELL,
        //   name: openMenu && "Buy & Sell",
        //   icon: <RiExchangeLine size={24} />,
        // },
        {
          path: ROUTES.MY_SWAP,
          name: openMenu && "Swap",
          icon: <MdOutlineSwapVerticalCircle size={23} />,
        },
        {
          path: ROUTES.DEPOSIT_VND,
          name: openMenu && "Wallets",
          icon: <AiOutlineWallet size={20} />,
        },
      ],
    },
    {
      name: "PRODUCTS",
      isOpen: true,
      items: [
        {
          path: ROUTES.INVEST,
          name: openMenu && "Trust Project",
          icon: <GiProfit size={20} />,
        },
        {
          path: ROUTES.MY_SHOP,
          name: openMenu && "B-Comercer",
          icon: <AiOutlineShoppingCart size={20} />,
        },
        {
          path: ROUTES.MY_IDO,
          name: openMenu && "IBO Crowdfunding Project",
          icon: <RiExchangeFundsLine size={24} />,
        },
        {
          path: ROUTES.MY_TASK,
          name: openMenu && "Tasks",
          icon: <BsListTask size={20} />,
        },
      ],
    },
    {
      name: "PERSONAL",
      isOpen: true,
      items: [
        {
          path: ROUTES.MY_PROFILE,
          name: "Profile",
          icon: <CgProfile size={20} />,
        },
        {
          path: ROUTES.MY_IBO,
          name: openMenu && "My IBO Project",
          icon: <AiOutlineProject size={20} />,
        },
        {
          path: ROUTES.CAMPAIGN,
          name: "Campaign",
          icon: <MdOutlineCampaign size={20} />,
        },
        {
          path: ROUTES.SALESTATISTICS,
          name: openMenu && "Sales statistics",
          icon: <FcStatistics size={20} />,
        },
        {
          path: ROUTES.CHANNEL,
          name: openMenu && "Channel",
          icon: <IoMdShare size={20} />,
        },
        {
          path: ROUTES.QA,
          name: openMenu && "Q&A",
          icon: <MdOutlineQuestionAnswer size={20} />,
        },
        {
          path: ROUTES.SUPPORTS,
          name: openMenu && "Supports",
          icon: <BiSupport size={20} />,
        },
      ],
    },
    ...Object.keys(eventType).map((eventType) => ({
      name: eventTypeMeta[eventType].name.toUpperCase(),
      items: [
        {
          path: eventTypeMeta[eventType].routes.create,
          name: openMenu && "Create",
          icon: <MdOutlineCreateNewFolder size={20} />,
          openWindow: true,
          disabled:
            !profile.permissions?.[`${eventPermissions[eventType]}.create`],
        },
        {
          path: eventTypeMeta[eventType].routes.root,
          name: openMenu && "Public " + eventTypeMeta[eventType].name,
          icon: <MdEvent size={20} />,
          openWindow: true,
        },
        {
          path: eventTypeMeta[eventType].routes.my,
          name: openMenu && "My " + eventTypeMeta[eventType].name,
          icon: <MdOutlineEventNote size={20} />,
          // disabled: !profile.permissions?.[`events.create`]
        },
      ],
    })),
    comingSoonMenu?.length && {
      name: "COMINGSOON",
      items: comingSoonMenu,
    },
  ];

  return (
    <>
      {isMobile && (
        <LogoAndMenuIcon>
          <MenuIcon onClick={() => setOpenMenu(!openMenu)}>
            <img src={`/assets/images/menu/1.svg`} alt="avatar" />
          </MenuIcon>
          <Logo>
            <img src={`/assets/images/logo.png`} alt="Vzonex.com" />
          </Logo>
        </LogoAndMenuIcon>
      )}

      <Wrapper width={openMenu ? "100vw" : isMobile ? "0px" : "90px"}>
        {!isMobile && (
          <OpenMenuIcon
            isOpen={openMenu}
            onClick={() => setOpenMenu(!openMenu)}
          >
            <img src={`/assets/images/openMenu.png`} alt="open" />
          </OpenMenuIcon>
        )}

        <Container width={openMenu ? "100%" : isMobile ? "0px" : "90px"}>
          {isMobile && (
            <CloseSideBar onClick={() => setOpenMenu(!openMenu)}>
              <AiOutlineCloseSquare size={30} />
            </CloseSideBar>
          )}
          {!isMobile && (
            <Logo isOpen={openMenu}>
              <img
                // src={`/assets/images/${openMenu ? "logo.png" : "logoMenu.png"}`}
                src={openMenu ? logos.logo : logos.logoResponsive}
                alt="Vzonex.com"
              />
            </Logo>
          )}
          {
            <>
              <Menu>
                <UserInfo isOpen={openMenu} selected={false}>
                  <CerAvt>
                    <VerifyIcon>{renderVerify()}</VerifyIcon>
                    <Avatar
                    // onClick={clickMenu({
                    //   path: ROUTES.MY_PROFILE,
                    // })}
                    >
                      <img
                        src={profile?.avatar || `/assets/images/avatar.png`}
                        alt="avatar"
                      />
                    </Avatar>
                  </CerAvt>
                  {openMenu && (
                    <>
                      <Info>
                        <h3
                        // onClick={clickMenu({
                        //   path: ROUTES.MY_PROFILE,
                        // })}
                        >
                          {String(profile?.fullName || profile?.username).slice(
                            0,
                            isDesktop ? 10 : 30
                          )}
                        </h3>
                        <Copy>
                          <p>{profile?.refer_code}</p>
                          <IoMdCopy onClick={handleCopy} size={14} />
                        </Copy>
                        <span className="animate-charcter">
                          {renderTextInDay()}
                        </span>
                      </Info>
                    </>
                  )}
                  {openMenu && (
                    <PigWrapper>
                      <img
                        src={`/assets/images/pig.png`}
                        alt="pig"
                        className="pig"
                      />
                    </PigWrapper>
                  )}
                </UserInfo>
                <UserMenu>
                  <div className={"sidebar"}>
                    {" "}
                    {menus.map((menu: any, idx: number) => {
                      return menu.items && menu.items.length > 0 ? (
                        <SubMenu
                          key={idx}
                          isOpen={menu.isOpen}
                          title={menu.name}
                          items={menu.items}
                          openMenu={openMenu}
                          selectedMenu={selectedMenu}
                          clickMenu={clickMenu}
                        />
                      ) : (
                        <MenuItem
                          key={menu.path}
                          {...{ selected: menu.path === selectedMenu }}
                          onClick={clickMenu(menu)}
                        >
                          {menu.icon}
                          {openMenu && (
                            <span className="name">{menu.name}</span>
                          )}
                        </MenuItem>
                      );
                    })}
                  </div>
                </UserMenu>
              </Menu>
              <Footer>
                <Donate setOpenMenu={setOpenMenu} />
                <SignOut isOpen={openMenu} onClick={logoutUser}>
                  <FaSignOutAlt size={20} />
                  {openMenu && <span>Sign out</span>}
                </SignOut>
                {openMenu && <Social openMenu={openMenu} />}
              </Footer>
            </>
          }
        </Container>
      </Wrapper>
    </>
  );
}
