import qs from "query-string";
import { request, parseErrorResponse, IRequestData } from "./request";

const getUserAuth = () =>
  new Promise((resolve, reject) => {
    request()
      .get("users/userAuth")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getUserByQueryOption = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`users/userAuth?${req.query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getUserByToken = (token) =>
  new Promise((resolve, reject) => {
    request({
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .get("users/userAuth")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getNonce = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`users/nonce/${req.params.address}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const editUser = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .put(`users/edit/${req.params.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const changePassword = (req) =>
  new Promise((resolve, reject) => {
    request()
      .put("users/changePassword", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const checkPassword = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("users/checkpassword", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const check2FA = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("users/check2fa", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getSecurity = () =>
  new Promise((resolve, reject) => {
    request()
      .get("users/security")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const enable2FA = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("users/enable-2fa", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const disable2FA = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("users/disable-2fa", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getCommissons = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`commissions/commissions`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getSharings = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`commissions/sharings`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getBalances = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`users/balances`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getSalesStatistics = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`users/sales-statistics`, req)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const createPinCode = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("users/create-pin", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const resetPinCode = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("users/reset-pin", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const checkPinExist = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get("users/check-exist-pin")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const editUserSecure = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .put(`users/edit-secure`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const getRevenuesInSystem = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get("/revenues/system")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const claimRevenueSystem = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("/revenues/claim-system", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const swapToTradeBalances = (params) => {
  return new Promise((resolve, reject) => {
    request()
      .post("/finances/balances/swap", params)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });
};

const getRevenuesHistory = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(
        `/revenues/getRevenueHistoryByRevenueId/${
          req.params.revenueId
        }?${qs.stringify(req?.query)}`
      )
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const UserService = {
  getUserAuth,
  getSecurity,
  enable2FA,
  disable2FA,
  editUser,
  changePassword,
  getCommissons,
  getBalances,
  checkPassword,
  check2FA,
  getNonce,
  getSharings,
  getSalesStatistics,
  createPinCode,
  resetPinCode,
  checkPinExist,
  editUserSecure,
  getUserByToken,
  getRevenuesInSystem,
  claimRevenueSystem,
  getUserByQueryOption,
  swapToTradeBalances,
  getRevenuesHistory,
};

export default UserService;
