import { AiFillCloseCircle } from "react-icons/ai";
import { setAdvertisement } from "redux/reducers/advertisments/advertisments.reducers";
import { useAppDispatch } from "redux/reducers/hook";
import { Wrapper, WrapperRight } from "./styles";

const Right = ({ data }) => {
  const dispatch = useAppDispatch();
  return (
    <WrapperRight>
      <Wrapper>
        <div
          className="close"
          onClick={() => dispatch(setAdvertisement({ right: null }))}
        >
          <AiFillCloseCircle size={20} />
        </div>
        <img src={data.banner} alt="" />
        <p>{data.name && data.name.toUpperCase()}</p>
        <a href={data.url} target={"_blank"} rel="noreferrer">
          {" "}
          DISCOVER NOW
        </a>
      </Wrapper>
    </WrapperRight>
  );
};

export default Right;
