/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import {
  DetailRightView,
  Title,
  Tabs,
  Tab,
  SpanColor,
  Warning,
  MarginTop,
  WithdrawPhone,
  SubmitWithdraw,
  PhoneNumber,
  InputPhone,
  RightViewContent,
} from "../../../styles";
import Input from "components/input";
import Button from "components/button";
import Dropdown from "components/dropdown";
import { useSelector } from "react-redux";
import { getBalances } from "redux/reducers/users/users.selector";
import { Controller, useForm } from "react-hook-form";
import API from "api";
import { toast } from "react-toastify";
import IDWithdraw from "./components/IDWithdraw";
import { useAppSelector } from "redux/reducers/hook";
import { getCryptos } from "redux/reducers/cryptos/cryptos.selector";
import { formatNumber } from "utils/common";
import Verify from "../../verify";
import Network from "../../networks";
import { ProfileDeposit, ItemProfile, TextProfile } from "./styles";

const optionsPhone = [
  {
    label: "VN +84",
    value: "VN",
  },
];

const WalletWithdraw = ({
  userBalance,
  control,
  errors,
  setMaxAmount,
  tokenName,
  fee,
  errorAmount,
  amount,
  handleChange,
  onBlurTo,
}) => {
  const stockName = String(tokenName).toUpperCase();

  return (
    <RightViewContent>
      <Controller
        name="to"
        control={control}
        rules={{ required: true }}
        render={({ field }) => {
          return (
            <Input
              transparent={true}
              label={`Stock ${stockName} Address`}
              placeholder={`Stock ${stockName} Address`}
              hasError={errors.to}
              {...field}
              onBlur={onBlurTo}
            />
          );
        }}
      />

      <MarginTop mt="20px" />
      {/* <span>Network</span> */}
      <Network network={"vzonex"} setNetwork={() => {}} />
      <MarginTop mt="20px" />
      <Input
        isCurrency={true}
        type="text"
        transparent={true}
        label={`Stock  ${stockName}  Amount`}
        placeholder="0.00"
        value={amount}
        hasError={amount > +userBalance[tokenName] || errorAmount}
        suffixIcon={
          <Button
            className={"btn-max-withdraw"}
            type="button"
            height="35px"
            onClick={setMaxAmount}
          >
            MAX
          </Button>
        }
        onValueChange={(e) => handleChange(e)}
      />

      <MarginTop mt="20px" />
      <p>
        Maximum {stockName} Available:{" "}
        <SpanColor color="red100">{userBalance[tokenName]}</SpanColor>{" "}
        {stockName}
      </p>

      <p>
        Withdraw fee stock {stockName}:{" "}
        <SpanColor color="red100">{fee.amount}</SpanColor>{" "}
        {String(fee.stock || "").toUpperCase()}
      </p>
      {/* <p>You can <SpanColor color="green100">buy</SpanColor> or <SpanColor color="orange100">deposit</SpanColor> VZX</p> */}
      <MarginTop mt="20px" />
      <Warning>
        <img src="/assets/images/icons/warning.svg" alt="warning" />
        <p>
          The above fees are not fixed, can vary depending on the state of the
          blockchain networks
        </p>
      </Warning>
      <Warning>
        <img src="/assets/images/icons/warning.svg" alt="warning" />
        <p>Estimated time to complete: less than a minute</p>
      </Warning>
    </RightViewContent>
  );
};

const EmailWithdraw = ({
  userBalance,
  control,
  errors,
  setMaxAmount,
  tokenName,
  errorAmount,
  amount,
  handleChange,
}) => {
  const stockName = String(tokenName).toUpperCase();

  return (
    <RightViewContent>
      <Controller
        name="to"
        control={control}
        rules={{ required: true }}
        render={({ field }) => {
          return (
            <Input
              transparent={true}
              label="Email"
              placeholder="Input Vzonex Email"
              hasError={errors.to}
              {...field}
            />
          );
        }}
      />
      <MarginTop mt="20px" />

      <Input
        transparent={true}
        label={`Stock ${stockName} Amount`}
        placeholder="0.00"
        isCurrency={true}
        type="text"
        value={amount}
        hasError={amount > +userBalance[tokenName] || errorAmount}
        suffixIcon={
          <Button
            className={"btn-max-withdraw"}
            onClick={setMaxAmount}
            height="35px"
          >
            MAX
          </Button>
        }
        onValueChange={(e) => handleChange(e)}
      />

      <MarginTop mt="20px" />
      <p>
        Maximum {stockName} Available:{" "}
        <SpanColor color="red100">{userBalance[tokenName]}</SpanColor>{" "}
        {stockName}
      </p>
      <p>
        You can <SpanColor color="green100">buy</SpanColor> or{" "}
        <SpanColor color="orange100">deposit</SpanColor> {stockName}
      </p>
      <MarginTop mt="20px" />

      <Controller
        name="note"
        control={control}
        render={({ field }) => {
          return (
            <Input
              type={"textarea"}
              height="100px"
              transparent={true}
              label="Message"
              placeholder={`Welcome to ${stockName} Stock`}
              hasError={errors.note}
              {...field}
            />
          );
        }}
      />
    </RightViewContent>
  );
};

const PhoneWithdraw = ({
  userBalance,
  control,
  errors,
  setMaxAmount,
  tokenName,
  errorAmount,
  amount,
  handleChange,
}) => {
  const stockName = String(tokenName).toUpperCase();

  return (
    <RightViewContent>
      <WithdrawPhone>
        <p>
          You can donate coin to your friends via phone numbers, even when your
          friends have not added their phone number to Stock Vzonex.
        </p>
        <MarginTop mt="20px" />
        <PhoneNumber>
          <Dropdown
            options={optionsPhone}
            defaultValue={"VN +84"}
            onChange={() => {}}
          />
          <InputPhone>
            <Controller
              name="to"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <Input
                    type={"number"}
                    transparent={true}
                    placeholder="Phone number"
                    hasError={errors.to}
                    {...field}
                  />
                );
              }}
            />
          </InputPhone>
        </PhoneNumber>
      </WithdrawPhone>
      <MarginTop mt="20px" />

      <Input
        transparent={true}
        label={`Stock ${stockName} Amount`}
        placeholder="0.00"
        isCurrency={true}
        type="text"
        value={amount}
        hasError={amount > +userBalance[tokenName] || errorAmount}
        suffixIcon={
          <Button
            className={"btn-max-withdraw"}
            onClick={setMaxAmount}
            height="35px"
          >
            MAX
          </Button>
        }
        onValueChange={(e) => handleChange(e)}
      />

      <MarginTop mt="20px" />
      <p>
        Maximum {stockName} Available:{" "}
        <SpanColor color="red100">{userBalance[tokenName]}</SpanColor>{" "}
        {stockName}
      </p>
      <p>
        You can <SpanColor color="green100">buy</SpanColor> or{" "}
        <SpanColor color="orange100">deposit</SpanColor> {stockName}
      </p>
      <MarginTop mt="20px" />

      <Controller
        name="note"
        control={control}
        render={({ field }) => {
          return (
            <Input
              type={"textarea"}
              height="100px"
              transparent={true}
              label="Message"
              placeholder="Welcome to VZX Stock"
              hasError={errors.note}
              {...field}
            />
          );
        }}
      />
    </RightViewContent>
  );
};

const View = (props) => {
  const location: any = useLocation();

  const address = queryString.parse(location.search)?.address;
  const amountStock = queryString.parse(location.search)?.amountStock;

  const [tab, setTab] = useState<string>("wallet");
  const [loading, setLoading] = useState(false);
  const [stock, setStock] = useState(null);
  const cryptos = useAppSelector(getCryptos);
  const [showVerify, setShowVerify] = useState(false);
  const [verify, setVerify] = useState({
    email_code: "",
    google_code: "",
  });

  const userBalance = useSelector(getBalances);
  const tokenName = props.match.params.stock;

  const [amount, setAmount] = useState<number>(null);
  const [errorAmount, setErrorAmount] = useState<boolean>(false);
  const [profileDeposit, setProfileDeposit] = useState<any>(null);

  const {
    control,
    handleSubmit,
    setValue,
    // watch,
    formState: { errors },
  } = useForm();

  const handleChange = (e) => {
    setAmount(e);
  };

  const handleSubmitWithdraw = async (data) => {
    const body = {
      method: tab,
      note: data.note,
      to: data.to,
      token: tokenName,
      amount: Number(amount),
      network: "vzonex",
      verify,
    };
    try {
      if (amount <= 0 || !amount) {
        setErrorAmount(true);
      } else {
        if (!showVerify) {
          setShowVerify(true);
          return;
        }
        setLoading(true);
        if (tab === "wallet") {
          await API.TRANSACTION.withdraw({ body });
        } else {
          await API.TRANSACTION.transfer({ body });
        }
        setLoading(false);
        setShowVerify(false);
        toast.success("Withdraw successful!");
        setVerify({
          email_code: "",
          google_code: "",
        });
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.errors || "Withdraw failed!");
    }
  };

  const setMaxAmount = () => {
    setAmount(userBalance[tokenName]);
  };

  const handleChangeTab = (tab: string) => {
    setTab(tab);
    setErrorAmount(false);
    setAmount(null);
    setValue("to", null);
    setProfileDeposit(null);
  };

  const onBlurTo = (e: any) => {
    getProfileDeposit(e.target.value, tab);
  };

  const feeTransfer =
    stock && stock.fee_transfer
      ? stock.fee_transfer
      : { stock: tokenName, percent: 0 };
  // const amount = watch("amount");
  const amountFee = formatNumber(((+amount || 0) * feeTransfer.percent) / 100);

  const formProps = {
    userBalance,
    control,
    errors,
    tokenName,
    fee: {
      amount: amountFee,
      stock: feeTransfer.stock,
    },
    setMaxAmount,
  };

  const getProfileDeposit = async (value: string, type: string) => {
    try {
      const profile: any = await API.USER.getUserByQueryOption({
        query: `type=${type}&value=${value}`,
      });

      console.log("profile", profile);

      if (profile) {
        const data = {
          ...profile.user,
          phone: profile.user?.phone || profile.user?.phone || profile.phone,
          role: profile.role,
          businessData: profile.businessData,
          organizationsData: profile.organizationsData,
        };
        setProfileDeposit(data);
      } else {
        setProfileDeposit(null);
      }
    } catch (error) {
      setProfileDeposit(null);
    }
  };

  useEffect(() => {
    setShowVerify(false);
  }, [tab]);

  useEffect(() => {
    if (cryptos) setStock(cryptos.find((c) => c.symbol === tokenName));
  }, [cryptos, tokenName]);

  useEffect(() => {
    if (address) {
      getProfileDeposit(String(address), "wallet");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  useEffect(() => {
    if (Number(amountStock) > 0) {
      setAmount(Number(amountStock));
    }
    if (address) {
      setValue("to", address);
    }
  }, []);

  return (
    <DetailRightView>
      <Title bgColor={"grey200"} just="space-between">
        <p>Transfer Stock ({String(tokenName).toUpperCase()})</p>
      </Title>
      <Tabs>
        <Tab
          isActive={tab === "wallet"}
          onClick={() => handleChangeTab("wallet")}
        >
          Wallet
        </Tab>
        <Tab
          isActive={tab === "email"}
          onClick={() => handleChangeTab("email")}
        >
          Email
        </Tab>
        <Tab
          isActive={tab === "phone"}
          onClick={() => handleChangeTab("phone")}
        >
          Phone Number
        </Tab>
        <Tab isActive={tab === "id"} onClick={() => handleChangeTab("id")}>
          ID
        </Tab>
      </Tabs>
      {profileDeposit && (
        <ProfileDeposit>
          <ItemProfile>
            <span>Name:</span>
            <TextProfile>
              {profileDeposit.fullName || profileDeposit.username}
            </TextProfile>
          </ItemProfile>
          <ItemProfile>
            <span>ID:</span>
            <TextProfile>{profileDeposit.refer_code}</TextProfile>
          </ItemProfile>

          <ItemProfile>
            <span>Adress:</span>
            <TextProfile>{profileDeposit.address}</TextProfile>
          </ItemProfile>
          {profileDeposit.phone && (
            <ItemProfile>
              <span>Phone:</span>
              <TextProfile>{profileDeposit.phone}</TextProfile>
            </ItemProfile>
          )}
          {(profileDeposit?.organizationsData ||
            profileDeposit?.businessData) && (
            <div>
              {profileDeposit?.organizationsData ? "Organization" : "Business"}{" "}
              Name: &nbsp;
              <b>
                {profileDeposit?.organizationsData?.organization ||
                  profileDeposit?.businessData?.business}
              </b>
            </div>
          )}
        </ProfileDeposit>
      )}
      <form onSubmit={handleSubmit(handleSubmitWithdraw)}>
        {tab === "wallet" &&
          (showVerify ? (
            <Verify verify={verify} setVerify={setVerify} />
          ) : (
            <WalletWithdraw
              {...formProps}
              amount={amount}
              errorAmount={errorAmount}
              onBlurTo={onBlurTo}
              handleChange={handleChange}
            />
          ))}

        {tab === "email" &&
          (showVerify ? (
            <Verify verify={verify} setVerify={setVerify} />
          ) : (
            <EmailWithdraw
              {...formProps}
              amount={amount}
              errorAmount={errorAmount}
              handleChange={handleChange}
            />
          ))}

        {tab === "phone" &&
          (showVerify ? (
            <Verify verify={verify} setVerify={setVerify} />
          ) : (
            <PhoneWithdraw
              {...formProps}
              amount={amount}
              errorAmount={errorAmount}
              handleChange={handleChange}
            />
          ))}

        {tab === "id" &&
          (showVerify ? (
            <Verify verify={verify} setVerify={setVerify} />
          ) : (
            <IDWithdraw
              {...formProps}
              amount={amount}
              errorAmount={errorAmount}
              handleChange={handleChange}
            />
          ))}

        <MarginTop mt="40px" />

        <SubmitWithdraw>
          <Button type="submit" height="40px" loading={loading}>
            Submit
          </Button>
        </SubmitWithdraw>
      </form>
    </DetailRightView>
  );
};

export default View;
