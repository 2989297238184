/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import { Title, Slide, Dot } from "../../styles";

import { getDepositBanks } from "redux/reducers/banks/banks.actions";
import { getDepositBanks as getDepositBanksSelector } from "redux/reducers/banks/banks.selector";

import { getUserProfile } from "redux/reducers/users/users.selector";

import { toast } from "react-toastify";

import History from "../history-vnd";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import APIs from "api";
import Step0 from "./Step0";

const titles = {
  0: "Select currency",
  1: "Select amount",
  2: "Select deposit methods",
  3: "Confirm deposit information",
  4: "Send and upload proof",
};

const View = () => {
  const defaultData = {
    bank: null,
    amount: null,
    content: "",
    proof: "",
    currency: "",
    hash: null,
    network: "bsc",
    stock: "vnex",
  };

  const user: any = useSelector(getUserProfile);

  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const [file, setFile] = useState();
  const [errUpload] = useState<boolean>(false);

  const [dataPayment, setDataPayment] = useState(defaultData);
  const depositBanks = useSelector(getDepositBanksSelector);
  const [hisDeposit, setHisDeposit] = useState<any>([]);

  const dispatch = useDispatch();

  const nextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const backStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const canclePayment = () => {
    setActiveStep(0);
    setDataPayment(defaultData);
  };

  const setDataByKey = (key, value) => {
    setDataPayment({
      ...dataPayment,
      [key]: value,
    });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let resImage;
      if (file) {
        const formData = new FormData();
        formData.append("files", file);
        resImage = await APIs.UPLOAD.uploadFile({
          body: formData,
        });
      }
      await APIs.PAYMENT.createPayment({
        body: {
          user_id: user._id,
          type: "deposit",
          bank: dataPayment.bank,
          amount: dataPayment.amount,
          content: dataPayment.content,
          proof: resImage.filePath,
          currency: dataPayment.currency,
          hash: dataPayment.hash,
          network: dataPayment.network,
          stock: "vnex",
        },
      });
      setLoading(false);
      setActiveStep(0);
      getPayment();
      setDataPayment(defaultData);
      toast.success("Deposit successful!");
    } catch (err) {
      // handle error
      setLoading(false);
      return toast.error(err.errors);
    }
    setLoading(false);
  };

  const getPayment = async () => {
    try {
      const res: any = await APIs.PAYMENT.getPaymentByUserId({
        userId: user._id,
        type: "deposit",
        stock: "vnex",
      });
      if (res.payments?.length > 0) {
        setHisDeposit(res.payments);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(getDepositBanks());
    getPayment();
  }, [dispatch]);

  return (
    <>
      <Title bgColor={"grey200"} just="center" direction="column">
        <p>{titles[activeStep]}</p>
        <Slide>
          <Dot isActive={activeStep === 0 ? true : false} />
          <Dot isActive={activeStep === 1 ? true : false} />
          <Dot isActive={activeStep === 2 ? true : false} />
          <Dot isActive={activeStep === 3 ? true : false} />
          <Dot isActive={activeStep === 4 ? true : false} />
        </Slide>
      </Title>

      {activeStep === 0 && (
        <Step0
          dataPayment={dataPayment}
          nextStep={nextStep}
          setDataByKey={setDataByKey}
          setDataPayment={setDataPayment}
        />
      )}

      {activeStep === 1 && (
        <Step1
          dataPayment={dataPayment}
          nextStep={nextStep}
          setDataByKey={setDataByKey}
          backStep={backStep}
        />
      )}
      {activeStep === 2 && (
        <Step2
          dataPayment={dataPayment}
          nextStep={nextStep}
          setDataByKey={setDataByKey}
          depositBanks={depositBanks}
          backStep={backStep}
        />
      )}
      {activeStep === 3 && (
        <Step3
          nextStep={nextStep}
          dataPayment={dataPayment}
          setDataByKey={setDataByKey}
          backStep={backStep}
        />
      )}
      {activeStep === 4 && (
        <Step4
          canclePayment={canclePayment}
          setDataByKey={setDataByKey}
          dataPayment={dataPayment}
          handleSubmit={handleSubmit}
          loading={loading}
          backStep={backStep}
          file={file}
          setFile={setFile}
          errUpload={errUpload}
        />
      )}

      {hisDeposit.length ? (
        <History lists={hisDeposit} type="deposit" />
      ) : (
        <></>
      )}
    </>
  );
};

export default View;
