import styled from "styled-components";
import { Modal } from "reactstrap";

const CustomModal = styled(Modal)<{showImage?: boolean}>`
  max-width: fit-content;
  /* width: auto; */
  margin: auto;
  min-width: 500px;

  @media screen and (max-width: 500px) {
    min-width: 350px;
  }

  .modal-header {
    justify-content: center;
    > * {
      font-size: 16px;
    }
  }

  .modal-content {
    /* max-height: calc(calc(var(--vh, 1vh) * 100) - 300px); */
    /* overflow: auto; */
    width: ${props => props.showImage ? "100vw" : ''};
    background-color: ${(props) => props.showImage ? "transparent" : props.theme.colors.bgColorLayer1};
    color: white;
    .modal-body {
      padding: 0;
    }
  }

  .modal-header {
    background-color: ${(props) => props.theme.colors.grey200};
    border: none;
  }

  .modal-body {
    /* background-color: ${(props) => props.theme.colors.bgColorLayer1}; */
    /* img {
      width: 100%;
      max-height: 580px;
    } */
  }

  .modal-footer {
    padding: 15px;
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;

    button {
      margin: 0;
      width: 48%;
    }
  }
`;

export default CustomModal;
