import styled, { css } from "styled-components";
import { isMobile } from "react-device-detect";

export const Container = styled.div`
  position: fixed;
  cursor: pointer;

  ${isMobile
    ? css`
        bottom: 65px;
        right: 10px;
        width: 40px;
        height: 40px;
      `
    : css`
        bottom: 24px;
        right: 100px;
        width: 60px;
        height: 60px;
      `}

  background-color: white;
  border-radius: 50%;
  padding: 7px;
  overflow: hidden;
  z-index: 999;

  img {
    width: 100%;
    border-radius: 4px;
  }

  &:hover {
    transform: scale(1.2);
  }
`;

export const ChatSupportIframe = styled.div<{ isHideChat: boolean }>`
  ${isMobile
    ? css`
        width: 100vw;
        height: calc(100vh - 120px);
        bottom: 120px;
        right: 0px;
      `
    : css`
        width: 400px;
        height: 590px;
        bottom: 20px;
        right: 170px;
      `}

  position: fixed;

  background-color: white;
  z-index: ${(props) => (props.isHideChat ? "-1" : "99999999999")};
  border-radius: 8px;
  overflow: hidden;

  iframe {
    width: 100%;
    height: 100%;
  }
`;
