import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getAdvertisementPositionsync,
  getChannelAsync,
  getDocumentsAsync,
  getNftsAsync,
  getProjectsAsync,
  getQAAsync,
  getSupportsAsync,
  getBannerAsync,
  getCitiesAsync,
  getRateUSDTToVNDAsync,
  getObjectsAsync,
  getLogoAsync,
  getTopMenuAsync,
  getGroupsAsync,
  getWebConfigAsync,
  getComingSoonAsync,
  getLocationAsync,
} from "./settings.action";

export interface ISettingState {
  connectSocket: any;
  reward: any;
  adPostition: any;
  channel: any;
  documents: any;
  nfts: any;
  qa: any;
  projects: any;
  supports: any;
  banner: any;
  cities: any;
  usd_vnd: any;
  objects: any;
  logos: any;
  top_menu: any;
  groups: any;
  webConfig: any[];
  coming_soon: any;
  locations: any;
}

const initialState: ISettingState = {
  connectSocket: null,
  reward: null,
  adPostition: [],
  channel: [],
  documents: [],
  nfts: [],
  qa: [],
  projects: [],
  supports: [],
  banner: [],
  cities: [],
  usd_vnd: 24000,
  objects: [],
  logos: {},
  top_menu: [],
  groups: [],
  webConfig: [],
  coming_soon: [],
  locations: [],
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setConnectSocket: (
      state: ISettingState,
      action: PayloadAction<boolean>
    ) => {},
    setReward: (state: ISettingState, action: PayloadAction<boolean>) => {
      state.reward = action.payload;
    },
  },
  extraReducers: {
    [`${getAdvertisementPositionsync.pending}`]: (
      state: ISettingState,
      { payload }
    ) => {},
    [`${getAdvertisementPositionsync.fulfilled}`]: (
      state: ISettingState,
      { payload }
    ) => {
      const { errors, setting } = payload;
      if (errors) {
        //handle error
        return;
      } else {
        state.adPostition = setting["value"];
      }
    },
    [`${getChannelAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getChannelAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors, setting } = payload;
      if (errors) {
        //handle error
        return;
      } else {
        state.channel = setting["value"];
      }
    },
    [`${getLogoAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getLogoAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors, setting } = payload;
      if (errors) {
        //handle error
        return;
      } else {
        state.logos = setting["value"];
      }
    },
    [`${getNftsAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getNftsAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors, setting } = payload;
      if (errors) {
        return;
      } else {
        state.nfts = setting["value"];
      }
    },
    [`${getDocumentsAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getDocumentsAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors, setting } = payload;
      if (errors) {
        return;
      } else {
        state.documents = setting["value"];
      }
    },
    [`${getQAAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getQAAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors, setting } = payload;
      if (errors) {
        return;
      } else {
        state.qa = setting["value"];
      }
    },
    [`${getSupportsAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getSupportsAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors, setting } = payload;
      if (errors) {
        return;
      } else {
        state.supports = setting["value"];
      }
    },
    [`${getProjectsAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getProjectsAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors, setting } = payload;
      if (errors) {
        return;
      } else {
        state.projects = setting["value"];
      }
    },
    [`${getBannerAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getBannerAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors, setting } = payload;
      if (errors) {
        return;
      } else {
        state.banner = setting["value"];
      }
    },
    [`${getCitiesAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getCitiesAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors } = payload;
      if (errors) {
        return;
      } else {
        state.cities = payload;
      }
    },
    [`${getRateUSDTToVNDAsync.pending}`]: (
      state: ISettingState,
      { payload }
    ) => {},
    [`${getRateUSDTToVNDAsync.fulfilled}`]: (
      state: ISettingState,
      { payload }
    ) => {
      const { errors } = payload;
      if (errors) {
        return;
      } else {
        state.usd_vnd = payload;
      }
    },
    [`${getObjectsAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getObjectsAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors, setting } = payload;
      if (errors) {
        return;
      } else {
        state.objects = setting["value"];
      }
    },
    [`${getGroupsAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getGroupsAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors, setting } = payload;
      if (errors) {
        return;
      } else {
        state.groups = setting["value"];
      }
    },
    [`${getTopMenuAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getTopMenuAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors, setting } = payload;
      if (errors) {
        return;
      } else {
        state.top_menu = setting["value"];
      }
    },
    [`${getComingSoonAsync.pending}`]: (
      state: ISettingState,
      { payload }
    ) => {},
    [`${getComingSoonAsync.fulfilled}`]: (
      state: ISettingState,
      { payload }
    ) => {
      const { errors, setting } = payload;
      if (errors) {
        return;
      } else {
        state.coming_soon = setting["value"];
      }
    },

    [`${getWebConfigAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors, setting } = payload;
      if (errors) {
        return;
      } else if (setting) {
        state.webConfig = setting["value"];
      }
    },
    // location
    [`${getLocationAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getLocationAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors } = payload;
      if (errors) {
        return;
      } else {
        const _countries = payload.filter((item) => item.type === "country");
        const _provinces = payload.filter((item) => item.type === "province");
        const _districts = payload.filter((item) => item.type === "district");
        const _wards = payload.filter((item) => item.type === "ward");
        state.locations = {
          countries: _countries,
          provinces: _provinces,
          districts: _districts,
          wards: _wards,
        };
      }
    },
  },
});

export const { setConnectSocket, setReward } = settingsSlice.actions;

export default settingsSlice.reducer;
