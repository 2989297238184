import styled from "styled-components";

export const Wrapper = styled.div`

`;

export const Container = styled.div`
	padding: 20px;
	max-width: 900px;
	margin: auto;

	h4 {
		position: relative;
		max-width: 350px;
		font-size: 18px;
	}
`;

export const Content = styled.div`

`;

export const ItemField = styled.div`

`;

export const Detail = styled.div`
	margin: 30px 0;

	h5 {
		position: relative;
		max-width: 150px;
		font-size: 16px;
	}
`;

export const FieldContainer = styled.div`
	margin-top: 20px;
	._item_field {
		padding: 10px;
	}
`;

export const Attachment = styled.div`
	display: flex;
  align-items: center;
  margin-bottom: 10px;
	padding: 10px;
    
  label {
    margin-right: 20px;
    font-size: 14px;
  } 
`;