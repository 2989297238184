/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import Loading from "components/loading";
import { useEffect, useRef, useState } from "react";
import { BankDepositUploadDragger, Ratio, CenterAbsolute } from "./styles";

const View = (props: any) => {
  const {
    width,
    index,
    height,
    ratio,
    typeUpload,
    id,
    idGlobal,
    sectionName,
    file,
    setFile,
    padding,
    title,
    requeried,
    src,
    loading,
  } = props;
  const fileRef = useRef<any>();
  const imgRef = useRef<any>();
  const [errUpload, setErrorUpload] = useState<boolean>(false);
  const [srcVideo, setSrcVideo] = useState("");

  const isVideo = (filename: any) => {
    const type = filename?.type?.split("/")[1];
    switch (type.toLowerCase()) {
      case "m4v":
      case "avi":
      case "mpg":
      case "mp4":
      case "mov":
      case "quicktime":
        // etc
        return true;
    }
    return false;
  };

  const srcIsVideo = (src: any) => {
    const type = src.split(".")[src.split(".").length - 1];
    switch (type.toLowerCase()) {
      case "m4v":
      case "avi":
      case "mpg":
      case "mp4":
      case "mov":
        // etc
        return true;
    }
    return false;
  };

  const onUpload = async () => {
    const [f] = fileRef.current.files;
    setFile(f, id, sectionName, index, idGlobal);
    imgRef.current.src = URL.createObjectURL(f);
    if (isVideo(f)) {
      setSrcVideo(URL.createObjectURL(f));
    } else {
    }
    try {
      const formData = new FormData();
      formData.append("files", f);
      // const res: any = await APIs.UPLOAD.uploadFile({
      //   body: formData
      // })
      // if(res){
      //   setErrorUpload(false)
      //   setDataByKey("proof", res.filePath)
      // } else {
      //   setErrorUpload(true)
      // }
    } catch (error) {
      // console.log(error);
      setErrorUpload(true);
    }
  };

  useEffect(() => {
    if (src) {
      if (srcIsVideo(src)) {
        setSrcVideo(src);
      } else {
        imgRef.current.src = src;
      }
    }
  }, [src]);

  useEffect(() => {
    if (
      sectionName &&
      file &&
      typeUpload !== "video" &&
      typeUpload !== "application/pdf"
    ) {
      imgRef.current.src = URL.createObjectURL(file);
    }
  }, [sectionName, file]);

  return (
    <>
      <BankDepositUploadDragger
        htmlFor={id}
        width={width}
        height={height}
        errUpload={errUpload || requeried}
        padding={padding}
      >
        {!Boolean(file) ? (
          <div hidden={src}>
            + Upload {title}
            <Ratio>{ratio}</Ratio>
          </div>
        ) : (
          ""
        )}
        {srcVideo ? (
          <video src={srcVideo} width="auto" height="100%" autoPlay loop>
            Your browser does not support HTML video.
          </video>
        ) : typeUpload === "application/pdf" && file ? (
          file.name
        ) : src ? (
          <img ref={imgRef} alt={id} />
        ) : (
          <img hidden={!Boolean(file)} ref={imgRef} alt={id} />
        )}
      </BankDepositUploadDragger>
      <input
        hidden={true}
        id={id}
        ref={fileRef}
        type="file"
        className="custom-file-input"
        accept={`${typeUpload || ""}/*`}
        required
        onChange={onUpload}
      />

      {loading && (
        <CenterAbsolute>
          <Loading type="sm" />
        </CenterAbsolute>
      )}
    </>
  );
};

export default View;
