/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable indent */
import { useAppDispatch } from "redux/reducers/hook";
import { getQAAsync } from "redux/reducers/settings/settings.action";
import { useSelector } from "react-redux";
import { getQA } from "redux/reducers/settings/settings.selector";
// import Button from "components/button";
import { useEffect, useState } from "react";
import { Card, CardBody, UncontrolledCollapse } from "reactstrap";
import { Wrapper, Element, Contain, DreamFapArea } from "./styles";
// import { GrAdd, GrFormSubtract } from "react-icons/gr";
import draftToHtml from "draftjs-to-html";

const View = () => {
  const dispatch = useAppDispatch();
  const qa: any = useSelector(getQA);
  const [state, setState] = useState([]);

  useEffect(() => {
    state.map((item, index) => {
      const element = document.getElementById(`answer_${index}`);
      if (element) element.innerHTML = draftToHtml(item.answer);
    });
  }, [state]);

  useEffect(() => {
    dispatch(getQAAsync());
  }, []);

  useEffect(() => {
    if (qa)
      setState(
        (qa || []).map((q) => ({
          ...q,
          isOpen: false,
        }))
      );
  }, [qa]);

  return (
    <Wrapper>
      <h3>Q&A</h3>
      <DreamFapArea>
        {state.map((item, index) => (
          <Element className={item.isOpen}>
            <Contain>
              {/* <Button onClick={() => handleToggle(index)}>
                  <span>{item.question}</span>
                  {item.isOpen === true ? <GrFormSubtract /> : <GrAdd />}
                </Button> */}
              <button
                id={`toggler_${item.index}`}
                data-aos="fade-up"
                data-aos-delay="200"
              >
                {item.question}
              </button>
              <UncontrolledCollapse toggler={`toggler_${item.index}`}>
                <Card>
                  <CardBody>
                    <span id={`answer_${item.index}`}></span>
                  </CardBody>
                </Card>
              </UncontrolledCollapse>
            </Contain>
          </Element>
        ))}
      </DreamFapArea>
    </Wrapper>
  );
};

export default View;
