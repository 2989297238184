import { createAsyncThunk } from "@reduxjs/toolkit";
import settingAPI from "api/settings";
import { getRateUSDTToVND } from "utils/currency";

export const getAdvertisementPositionsync = createAsyncThunk(
  "setting/advertisementPosition",
  async () => {
    try {
      const response = await settingAPI.getSettingByName(
        "advertisement_position"
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getLogoAsync = createAsyncThunk("setting/logos", async () => {
  try {
    const response = await settingAPI.getSettingByName("logos");
    return response;
  } catch (error) {
    return error;
  }
});

export const getChannelAsync = createAsyncThunk("setting/channel", async () => {
  try {
    const response = await settingAPI.getSettingByName("channel");
    return response;
  } catch (error) {
    return error;
  }
});

export const getDocumentsAsync = createAsyncThunk(
  "setting/documents",
  async () => {
    try {
      const response = await settingAPI.getSettingByName("documents");
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getNftsAsync = createAsyncThunk("setting/nfts", async () => {
  try {
    const response = await settingAPI.getSettingByName("nfts");
    return response;
  } catch (error) {
    return error;
  }
});

export const getQAAsync = createAsyncThunk("setting/q&a", async () => {
  try {
    const response = await settingAPI.getSettingByName("q&a");
    return response;
  } catch (error) {
    return error;
  }
});

export const getSupportsAsync = createAsyncThunk(
  "setting/supports",
  async () => {
    try {
      const response = await settingAPI.getSettingByName("supports");
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getBannerAsync = createAsyncThunk("setting/banner", async () => {
  try {
    const response = await settingAPI.getSettingByName("banner");
    return response;
  } catch (error) {
    return error;
  }
});

export const getProjectsAsync = createAsyncThunk(
  "setting/projects",
  async () => {
    try {
      const response = await settingAPI.getSettingByName("projects");
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getObjectsAsync = createAsyncThunk("setting/objects", async () => {
  try {
    const response = await settingAPI.getSettingByName("objects");
    return response;
  } catch (error) {
    return error;
  }
});

export const getGroupsAsync = createAsyncThunk("setting/groups", async () => {
  try {
    const response = await settingAPI.getSettingByName("groups");
    return response;
  } catch (error) {
    return error;
  }
});

export const getComingSoonAsync = createAsyncThunk("setting/coming_soon", async () => {
  try {
    const response = await settingAPI.getSettingByName("coming_soon");
    return response;
  } catch (error) {
    return error;
  }
});

export const getCitiesAsync = createAsyncThunk("setting/cities", async () => {
  try {
    const response = await settingAPI.getCities();
    return response;
  } catch (error) {
    return error;
  }
});

export const getRateUSDTToVNDAsync = createAsyncThunk(
  "setting/rate",
  async () => {
    try {
      const response = await getRateUSDTToVND();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getTopMenuAsync = createAsyncThunk("setting/topMenu", async () => {
  try {
    const response = await settingAPI.getSettingByName("top_menu");
    return response;
  } catch (error) {
    return error;
  }
});


export const getWebConfigAsync = createAsyncThunk("setting/web_config", async () => {
  try {
    const response = await settingAPI.getSettingByName("web_config");
    
    return response;
  } catch (error) {
    return error;
  }
});

export const getLocationAsync = createAsyncThunk(
  "setting/location",
  async () => {
    try {
      const response = await settingAPI.getLocations();
      return response;
    } catch (error) {
      return error;
    }
  }
);
