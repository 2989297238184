import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getProjectById,
  getProjectsAsync,
  getProjectsOwnerAsync,
} from "./projects.action";

export interface IProjectState {
  projects: any;
  projectsOwner: any;
  projectSeleted: any;
}

const initialState: IProjectState = {
  projects: [],
  projectSeleted: {},
  projectsOwner: [],
};

export const ProjectSlice = createSlice({
  name: "wallets",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProjectsAsync.pending, (state, { payload }) => {});
    builder.addCase(getProjectsAsync.fulfilled, (state, { payload }) => {
      const { projects } = payload;
      if (projects) state.projects = projects;
    });
    builder.addCase(getProjectsOwnerAsync.pending, (state, { payload }) => {});
    builder.addCase(getProjectsOwnerAsync.fulfilled, (state, { payload }) => {
      const { projects } = payload;
      if (projects) state.projectsOwner = projects;
    });
    builder.addCase(getProjectsAsync.rejected, (state, action: any) => {
      if (action.payload) {
        toast.error(action.payload.errorMessage);
      } else {
        toast.error(action.error);
      }
    });
    builder.addCase(getProjectById.pending, (state, { payload }) => {});
    builder.addCase(getProjectById.fulfilled, (state, { payload }) => {
      if (payload.project) state.projectSeleted = payload.project;
    });
    builder.addCase(getProjectById.rejected, (state, action: any) => {
      if (action.payload) {
        toast.error(action.payload.errorMessage);
      } else {
        toast.error(action.error);
      }
    });
  },
});

// export const {} = projectSlice.actions;

export default ProjectSlice.reducer;
