/* eslint-disable react-hooks/exhaustive-deps */
import Input from "components/input";
import { Form, Label, FormGroup, Input as InputB } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import Button from "components/button";
import Table from "components/table";
import { MdDeleteOutline, MdModeEditOutline } from "react-icons/md";
import { useEffect, useState } from "react";
import { SectionManagers } from "./styles";

const View = (props) => {
  const {
    page,
    data,
    dataManagersWithSection,
    optionSection,
    loading,
    onSubmit,
  } = props;

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const columnsTableManagers = [
    {
      key: "refer_code",
      title: "ID",
      dataIndex: "refer_code",
    },
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      render: (item: any) => <span>{item?.fullName || item?.username}</span>,
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email",
    },
    {
      key: "action",
      title: "Action",
      dataIndex: "action",
      render: (item: any) => (
        <>
          <Button height="30px" onClick={() => handleAction("edit", item)}>
            <MdModeEditOutline size={20} />
          </Button>
          <Button
            height="30px"
            loading={loading}
            onClick={() => handleAction("delete", item)}
          >
            <MdDeleteOutline size={20} />
          </Button>
        </>
      ),
    },
  ];

  const [sectionManagers, setSectionManagers] = useState(null);
  const [dataManagers, setDataManagers] = useState<any>([]);

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const onChangeChecked = (value: boolean, key: string) => {
    if (value) {
      const newData = dataManagers.concat(key);
      setDataManagers(newData);
    } else {
      setDataManagers((prev) => prev.filter((el) => el !== key));
    }
  };

  const handleAction = (type: string, data: any) => {
    if (type === "edit") {
      setIsEdit(true);
      setValue("refer_code", data.refer_code);
      setDataManagers(dataManagersWithSection[data._id]);
    } else if (type === "delete") {
      onSubmit({ refer_code: data.refer_code }, dataManagers, type);
      handelCancelEdit();
    } else {
    }
  };

  const handelCancelEdit = () => {
    setIsEdit(false);
    setValue("refer_code", "");
    setDataManagers([]);
  };

  useEffect(() => {
    setSectionManagers(optionSection);
  }, []);

  return (
    <>
      <Form>
        <Controller
          name="refer_code"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Input
              transparent={true}
              color={"orange"}
              label="ID User"
              placeholder="Nhập ID..."
              hasError={Boolean(errors.refer_code)}
              disabled={isEdit}
              {...field}
            />
          )}
        />
        <SectionManagers>
          <FormGroup check>
            <Label check>
              <InputB
                type="checkbox"
                checked={dataManagers?.includes("all")}
                onChange={(e) => onChangeChecked(e.target.checked, "all")}
              />
              {page === "event" ? "Tất cả" : "All"}
            </Label>
          </FormGroup>
          {sectionManagers &&
            Object.keys(sectionManagers).map((st) => (
              <FormGroup check>
                <Label check>
                  <InputB
                    type="checkbox"
                    checked={dataManagers?.includes(st)}
                    onChange={(e) => onChangeChecked(e.target.checked, st)}
                  />
                  {sectionManagers[st].title}
                </Label>
              </FormGroup>
            ))}
        </SectionManagers>
        {isEdit ? (
          <>
            <Button
              height={"30px"}
              loading={loading}
              onClick={handleSubmit((e) => onSubmit(e, dataManagers, "edit"))}
            >
              Edit
            </Button>
            <Button
              height={"30px"}
              loading={loading}
              onClick={handelCancelEdit}
            >
              Cancel
            </Button>
          </>
        ) : (
          <Button
            height={"30px"}
            loading={loading}
            onClick={handleSubmit((e) => onSubmit(e, dataManagers, "add"))}
          >
            Add
          </Button>
        )}
      </Form>
      <br />
      <br />
      <Table
        bgTable="blue300"
        columns={columnsTableManagers}
        dataSource={data}
      />
    </>
  );
};

export default View;
