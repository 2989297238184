import { IRootState } from "..";

export const getNotificationsClient = (state: IRootState) =>
  state.notifications.client;
export const getNotificationsOwner = (state: IRootState) =>
  state.notifications.owner;
export const getNotificationsSystem = (state: IRootState) =>
  state.notifications.system;
export const getAccessNotifications = (state: IRootState) =>
  state.notifications.access;
