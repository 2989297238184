import { useEffect } from "react"

import { HomeLayout, PaddingHeader, LayoutHome, BottomHome } from "./styles";
import { Route, Switch } from "react-router-dom";
import Header from "layouts/components/header";
import NavBottom from "layouts/components/navbar-bottom";
import LandingPage from "./landing-page";
import Project from "./projects";
import ProjectDetail from "./projects/project-detail";
import Terms from "./terms";
import Whitepaper from "./whitepaper";
import Advertisement from "layouts/Advertisements";
import ScanQR from "components/scanQR"

import aos from "aos"

import Mess from "components/messenger";

const View = () => {
  
  useEffect(() => {
    aos.init({
      disable: 'mobile'
    });
  }, [])

  return (
    <HomeLayout>
      <Advertisement isHome={true} />
      <Header main={true}></Header>
      <PaddingHeader></PaddingHeader>
      <LayoutHome id="layout">
        <Switch>
          <Route path="/projects/:id" component={ProjectDetail} />
          <Route path="/projects" component={Project} />
          <Route path="/whitepaper" component={Whitepaper} />
          <Route path="/terms" component={Terms} />
          <Route path="/scan" component={ScanQR} />
          <Route path={"/"} component={LandingPage} />
        </Switch>
      </LayoutHome>
      <BottomHome>
        <NavBottom />
      </BottomHome>
      <Mess />
    </HomeLayout>
  );
};

export default View;
