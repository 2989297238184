/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import {
  Card,
  CardBody,
  Col,
  Container,
  Progress,
  Row,
  UncontrolledCollapse,
} from "reactstrap";
import {
  Wrapper,
  Top,
  TopLeft,
  TopLeftData,
  TopRight,
  Main,
  TableWrapper,
  TableContainer,
  Table,
  TableRow,
  Details,
  Banner,
  SectionHeading,
  DreamFapArea,
  IcoCounter,
  CounterHeader,
  CounterdownContent,
  ProgressWrapper,
  BuyMore,
  ICODoc,
  DocElement,
} from "./styles";
import Countdown from "react-countdown";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { useParams } from "react-router-dom";
import { getProjectSelected } from "redux/reducers/projects/projects.selector";
import { getProjectById } from "redux/reducers/projects/projects.action";
import { SOCIAL_IMG } from "utils/constants";

const CountDownElement = (props) => {
  return (
    <div className="countdown-section">
      <div className="countdown-item">
        <span className="countdown-item-value">{props.days}</span>
        <span className="countdown-item-label">Days</span>
      </div>
      <div className="countdown-item">
        <span className="countdown-item-value">{props.hours}</span>
        <span className="countdown-item-label">Hours</span>
      </div>
      <div className="countdown-item">
        <span className="countdown-item-value">{props.minutes}</span>
        <span className="countdown-item-label">Minutes</span>
      </div>
      <div className="countdown-item">
        <span className="countdown-item-value">{props.seconds}</span>
        <span className="countdown-item-label">Seconds</span>
      </div>
    </div>
  );
};

const countdow_render = ({ days, hours, minutes, seconds }) => {
  return (
    <CountDownElement
      days={days}
      hours={hours}
      minutes={minutes}
      seconds={seconds}
    />
  );
};

const View = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const _project: any = useAppSelector(getProjectSelected);

  // const providers = new ethers.providers.Web3Provider(ethereum);

  useEffect(() => {
    dispatch(
      getProjectById({
        params: {
          id,
        },
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    if (_project && _project.content) {
      const element = document.getElementById("project-content");
      element.innerHTML = _project.content;
    }
  }, [_project]);

  const project = {
    ..._project,
    ido: _project.ido || {},
    keep_time: _project.keep_time || {},
    doc: _project.doc || [],
  };

  return (
    <Wrapper>
      {project && project.name ? (
        <Container>
          <Top>
            <TopLeft>
              <img
                src="https://server.daopad.finance/uploads/order_5799a124-6f61-4d42-aa39-24c289b3e85b.jpeg"
                alt=""
              />
              <TopLeftData>
                <p>
                  {project.name}(${project.symbol})
                </p>
                <div>
                  {project.tabs.split(",").map((i, key) => (
                    <p key={key}>{i}</p>
                  ))}
                </div>
              </TopLeftData>
            </TopLeft>
            <TopRight>
              {Object.keys(project.social || {}).map((key) => {
                return (
                  <a
                    href={project.social[key]}
                    target="_blank"
                    rel="noreferrer"
                    key={key}
                  >
                    <img src={SOCIAL_IMG[key]} alt="" />
                  </a>
                );
              })}
            </TopRight>
          </Top>

          <Main>
            <Row>
              <Col lg={7} md={12}>
                <SectionHeading>
                  <h2 data-aos="fade-up">Frequently Asked Questions</h2>
                  <p data-aos="fade-up">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    quis accumsan nisi Ut ut felis congue nisl hendrerit
                    commodo.
                  </p>{" "}
                </SectionHeading>
                <DreamFapArea>
                  <div>
                    <button
                      id="toggler1"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      What are the objectives of this stocks?
                    </button>
                    <UncontrolledCollapse toggler="#toggler1">
                      <Card>
                        <CardBody>
                          Anim pariatur cliche reprehenderit, enim eiusmod high
                          life accusamus terry richardson ad squid. Nihil anim
                          keffiyeh helvetica, craft beer labore wes anderson
                          cred nesciunt sapiente ea proident.
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                  <div>
                    <button
                      id="toggler2"
                      data-aos="fade-up"
                      data-aos-delay="300"
                    >
                      What is stocks Sale and pre-sale?
                    </button>
                    <UncontrolledCollapse toggler="#toggler2">
                      <Card>
                        <CardBody>
                          Anim pariatur cliche reprehenderit, enim eiusmod high
                          life accusamus terry richardson ad squid. Nihil anim
                          keffiyeh helvetica, craft beer labore wes anderson
                          cred nesciunt sapiente ea proident.
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                  <div>
                    <button
                      id="toggler3"
                      data-aos="fade-up"
                      data-aos-delay="400"
                    >
                      What is the pre-sale start date?
                    </button>
                    <UncontrolledCollapse
                      toggler="#toggler3"
                      data-aos-delay="400"
                    >
                      <Card>
                        <CardBody>
                          Anim pariatur cliche reprehenderit, enim eiusmod high
                          life accusamus terry richardson ad squid. Nihil anim
                          keffiyeh helvetica, craft beer labore wes anderson
                          cred nesciunt sapiente ea proident.
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                  <div>
                    <button
                      id="toggler5"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                      How may I take part in pre-sale?
                    </button>
                    <UncontrolledCollapse toggler="#toggler5">
                      <Card>
                        <CardBody>
                          Anim pariatur cliche reprehenderit, enim eiusmod high
                          life accusamus terry richardson ad squid. Nihil anim
                          keffiyeh helvetica, craft beer labore wes anderson
                          cred nesciunt sapiente ea proident.
                        </CardBody>
                      </Card>
                    </UncontrolledCollapse>
                  </div>
                </DreamFapArea>
              </Col>
              <Col lg={5} md={12}>
                <IcoCounter>
                  <CounterHeader>
                    {project.status === "opening" && (
                      <>
                        <h3>STOCKS SALE ENDS IN</h3>
                        <CounterdownContent>
                          <Countdown
                            date={new Date(
                              project.keep_time.ido_close
                            ).getTime()}
                            renderer={countdow_render}
                          />
                        </CounterdownContent>
                      </>
                    )}
                    {project.status === "upcoming" && (
                      <>
                        <h3>STOCKS WILL BE SOLD LATE</h3>
                        <CounterdownContent>
                          <Countdown
                            date={new Date(
                              project.keep_time.ido_open
                            ).getTime()}
                            renderer={countdow_render}
                          />
                        </CounterdownContent>
                      </>
                    )}
                    {project.status === "closed" && (
                      <>
                        <h3>FINNISHED</h3>
                      </>
                    )}
                  </CounterHeader>
                  <ProgressWrapper>
                    <Progress
                      value={
                        (+project.amountSold / +project.amountToSale) * 100
                      }
                    >
                      {(+project.amountSold / +project.amountToSale) * 100}%
                    </Progress>
                    <span>
                      Number of shares sold :{" "}
                      {(+project.amountSold / +project.amountToSale) * 100}%
                    </span>
                  </ProgressWrapper>
                  <BuyMore>
                    <a href="/my/ico">Buy More stocks</a>
                  </BuyMore>
                </IcoCounter>

                <ICODoc>
                  <Row>
                    <Col md={6} xs={12}>
                      <DocElement data-aos="fade-up">
                        {project.docs.map((p, key) => (
                          <a
                            href={p.value}
                            target="_blank"
                            rel="noreferrer"
                            key={key}
                          >
                            <span>{p.name}</span>
                          </a>
                        ))}
                      </DocElement>
                    </Col>
                  </Row>
                </ICODoc>
              </Col>
            </Row>
          </Main>

          <TableWrapper>
            <Container>
              <Row>
                <Col lg={6} md={12}>
                  <TableContainer>
                    <h1>IDO Information</h1>
                    <Table>
                      <TableRow data-aos="fade-up" data-aos-delay="100">
                        <p>Stocks</p>
                        <p>${project.ido["token"] || "N/A"}</p>
                      </TableRow>
                      <TableRow data-aos="fade-up" data-aos-delay="200">
                        <p>Network</p>
                        <p>{project.ido["network"] || "N/A"}</p>
                      </TableRow>
                      <TableRow data-aos="fade-up" data-aos-delay="300">
                        <p>IDO Token Price</p>
                        <p>{project.ido["price"] || "N/A"}</p>
                      </TableRow>
                      <TableRow data-aos="fade-up" data-aos-delay="400">
                        <p>Total IDO Pool size</p>
                        <p>{project.ido["pool"] || "N/A"}</p>
                      </TableRow>
                      <TableRow data-aos="fade-up" data-aos-delay="500">
                        <p>IDO Type</p>
                        <p>{project.ido["type"] || "N/A"}</p>
                      </TableRow>
                      <TableRow data-aos="fade-up" data-aos-delay="600">
                        <p>Vesting</p>
                        <p>{project.ido["vesting"] || "N/A"}</p>
                      </TableRow>
                    </Table>
                  </TableContainer>
                </Col>
                <Col lg={6} md={12}>
                  <TableContainer>
                    <h1>Keep time in mind </h1>
                    <Table>
                      <TableRow data-aos="fade-up" data-aos-delay="100">
                        <p>Opening Whitelist</p>
                        <p>{project.keep_time["opening_whitelist"] || "N/A"}</p>
                      </TableRow>
                      <TableRow data-aos="fade-up" data-aos-delay="200">
                        <p>Closing Whitelist</p>
                        <p>
                          {project.keep_time["closeing_whitelist"] || "N/A"}
                        </p>
                      </TableRow>
                      <TableRow data-aos="fade-up" data-aos-delay="300">
                        <p>Whitelist results</p>
                        <p>{project.keep_time["whitelist_results"] || "N/A"}</p>
                      </TableRow>
                      <TableRow data-aos="fade-up" data-aos-delay="400">
                        <p>IDO Open</p>
                        <p>{project.keep_time["ido_open"] || "N/A"}</p>
                      </TableRow>
                      <TableRow data-aos="fade-up" data-aos-delay="500">
                        <p>IDO Close</p>
                        <p>{project.keep_time["ido_close"] || "N/A"}</p>
                      </TableRow>
                      <TableRow data-aos="fade-up" data-aos-delay="600">
                        <p>Distribution</p>
                        <p>{project.keep_time["distribution"] || "N/A"}</p>
                      </TableRow>
                    </Table>
                  </TableContainer>
                </Col>
              </Row>
            </Container>
          </TableWrapper>

          <Details data-aos="fade-up" id="project-content"></Details>

          <Banner src={project.banner} />
        </Container>
      ) : (
        <div>Not found</div>
      )}
    </Wrapper>
  );
};

export default View;
