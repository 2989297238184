/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { useAppSelector } from "redux/reducers/hook";

import Button from "components/button";
import {
  Content,
  Continue,
  DetailRightView,
  Title,
  Warning,
} from "../../../styles";
import Input from "components/input";
import { formatNumber, randString } from "utils/common";

import APIs from "api";

import {
  getBalances,
  getUserProfile,
} from "redux/reducers/users/users.selector";
import Verify from "../../verify";
import Network from "../../networks";
import HistoryP2P from "../history-p2p";
import { useParams, useHistory } from "react-router-dom";

const View = (props) => {
  const { stock } = useParams();
  const history = useHistory();

  const balances = useAppSelector(getBalances);
  const user = useAppSelector(getUserProfile);

  const [step, setStep] = useState(1);
  const [amount, setAmount] = useState<number>(null);
  const [hisDeposit, setHisDeposit] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errMessage, setErrMessage] = useState<string>("");
  const [address, setAddress] = useState(null);
  const [network, setNetwork] = useState("bsc");
  const [verify, setVerify] = useState({
    email_code: "",
    google_code: "",
  });

  const handleChange = (e) => {
    if (+e < 0) {
      setAmount(0);
    } else if (+e === 0) {
      setAmount(null);
    } else {
      setAmount(+e);
    }
  };

  const handleClickButtonTop = async () => {
    setErrMessage("");
    if (step !== 2) {
      setStep(step + 1);
    } else {
      try {
        if (amount > 0) {
          setLoading(true);
          await APIs.PAYMENT.withdraw({
            body: {
              verify,
              amount: amount,
              content: randString(false, 10),
              address,
              network,
              stock: stock,
            },
          });
          getPayment();
          toast.success("Withdraw successful!");
          setStep(1);
          setLoading(false);
        } else {
          setErrMessage("Please enter amount");
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        toast.error(error?.errors);
      }
    }
  };

  const backStep = () => {
    if (step === 1) {
      history.push(`/my/wallets/${stock}/withdraw/`);
    }
    setStep(step - 1);
  };

  const getPayment = async () => {
    try {
      const res: any = await APIs.PAYMENT.getPaymentByUserId({
        userId: user._id,
        type: "withdraw",
        stock,
      });
      if (res.payments?.length > 0) {
        setHisDeposit(res.payments);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPayment();
  }, []);

  return (
    <>
      <DetailRightView>
        <Title bgColor={"grey200"} just="space-between">
          <p>Withdraw {String(stock || "").toUpperCase()}</p>
        </Title>

        {step === 1 && (
          <Content>
            <Network network={network} setNetwork={setNetwork} />
            <Input
              transparent={true}
              label="Address"
              placeholder="0x9f0F7601b9143ACc36aE838800153805698e8f08"
              onChange={(e) => setAddress(e.target.value)}
            />
            <Input
              isCurrency={true}
              transparent={true}
              label={`Amount (${stock.toUpperCase()})*`}
              placeholder="10,000"
              value={amount}
              hasError={errMessage ? true : false}
              errMessage={errMessage}
              suffixIcon={
                <Button
                  className={"action"}
                  onClick={() => setAmount(+balances[stock] || 0)}
                >
                  MAX
                </Button>
              }
              onValueChange={(e) => handleChange(e)}
            />
            <Warning>
              <img src="/assets/images/icons/warning.svg" alt="warning" />
              <p>
                Maximum {String(stock || "").toUpperCase()} Withdrawal:{" "}
                <span>
                  {formatNumber(+balances[stock] || 0)}
                  {String(stock).toUpperCase()}
                </span>
              </p>
            </Warning>
            <Warning>
              <img src="/assets/images/icons/warning.svg" alt="warning" />
              <p>
                Please enter the correct network address for the transaction.
              </p>
            </Warning>
          </Content>
        )}
        {step === 2 && <Verify verify={verify} setVerify={setVerify} />}
      </DetailRightView>
      <Continue>
        <Button loading={loading} height="40px" onClick={handleClickButtonTop}>
          {step === 2 ? "Confirm" : "Continue"}
        </Button>
        {step !== 0 && (
          <Button transparent={true} height="40px" onClick={() => backStep()}>
            Back
          </Button>
        )}
      </Continue>
      {hisDeposit.length ? (
        <HistoryP2P lists={hisDeposit} type="withdraw" stock={stock} />
      ) : (
        <></>
      )}
    </>
  );
};

export default View;
