/* eslint-disable no-empty-pattern */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAdvertisementsAsync } from "./advertisments.actions";

export interface IAdvertisementState {
  advertisements: any[];
  advertisement: any;
  access: boolean;
}

const initialState: IAdvertisementState = {
  advertisements: [],
  advertisement: {
    left: null,
    right: null,
    center: null,
  },
  access: true,
};

export const banksSlice = createSlice({
  name: "advertisments",
  initialState,
  reducers: {
    setAdvertisement: (
      state: IAdvertisementState,
      action: PayloadAction<any>
    ) => {
      state.advertisement = {
        ...state.advertisement,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdvertisementsAsync.pending, (state) => {})
      .addCase(getAdvertisementsAsync.fulfilled, (state, action) => {
        const { advertisements, errors } = action.payload;
        if (errors === "Not Authenticated") state.access = false;
        else {
          state.advertisements = advertisements;
          state.access = true;
        }
      });
  },
});

export const { setAdvertisement } = banksSlice.actions;

export default banksSlice.reducer;
