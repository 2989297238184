import ContainerNav from "./styles";
import { Nav, NavItem } from "reactstrap";
import { NavLink, useLocation } from "react-router-dom";

import {
  AiOutlineProject,
  AiOutlineWallet,
  AiOutlineScan,
} from "react-icons/ai";

import { ROUTES } from "config/routes";
import {   MdEvent,
} from "react-icons/md";
import { GiProfit } from "react-icons/gi";

const tabs = [
  {
    route: ROUTES.SMART_EVENTS,
    icon: <MdEvent size={24} />,
    label: "Event",
  },
  {
    route: ROUTES.MY_IBO,
    icon: <AiOutlineProject size={24} />,
    label: "IBO",
  },
  {
    route: "/scan",
    icon: <AiOutlineScan size={40} />,
  },
  {
    route: ROUTES.DEPOSIT_VND,
    icon: <AiOutlineWallet size={24} />,
    label: "Wallet",
  },
  {
    route: ROUTES.INVEST,
    icon: <GiProfit size={24} />,
    label: "Invest",
  },
];

const View = () => {
  const { pathname } = useLocation();

  return (
    <ContainerNav>
      {/* Bottom Tab Navigator*/}
      <nav className="navbar fixed-bottom navbar-light" role="navigation">
        <Nav className="w-100">
          <div className=" d-flex flex-row justify-content-around w-100">
            {tabs.map((tab, index) => (
              <NavItem key={`tab-${index}`}>
                <NavLink
                  to={tab.route}
                  className="nav-link"
                  activeClassName={pathname.includes(tab.route) ? "active" : ""}
                >
                  <div className="row d-flex flex-column justify-content-center align-items-center">
                    <div>{tab.icon}</div>
                    <div>{tab.label}</div>
                  </div>
                </NavLink>
              </NavItem>
            ))}
          </div>
        </Nav>
      </nav>
    </ContainerNav>
  );
};

export default View;
