import Countdown from "react-countdown";
import { CountdownWrapper } from "./styles";

const View = ({ date }) => {
  const CountDownElement = (props) => {
    return (
      <div className="countdown-section">
        <div className="countdown-item">
          <span className="countdown-item-value">{props.days}</span>
          <span className="countdown-item-label">Days</span>
        </div>
        <div className="countdown-item">
          <span className="countdown-item-value">{props.hours}</span>
          <span className="countdown-item-label">Hours</span>
        </div>
        <div className="countdown-item">
          <span className="countdown-item-value">{props.minutes}</span>
          <span className="countdown-item-label">Minutes</span>
        </div>
        <div className="countdown-item">
          <span className="countdown-item-value">{props.seconds}</span>
          <span className="countdown-item-label">Seconds</span>
        </div>
      </div>
    );
  };

  const countdow_render = ({ days, hours, minutes, seconds }) => {
    return (
      <CountDownElement
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  };

  return (
    <CountdownWrapper>
      <Countdown date={date} renderer={countdow_render} />
    </CountdownWrapper>
  );
};

export default View;
