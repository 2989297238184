import { createSlice } from "@reduxjs/toolkit";
import {
  disable2faAsync,
  enable2faAsync,
  securityAsync,
} from "./securities.action";

export interface ISecurityState {
  uri: string;
  is_enable: boolean;
  secret_key: string;
}

const initialState: ISecurityState = {
  uri: null,
  is_enable: false,
  secret_key: null,
};

export const usersSlice = createSlice({
  name: "security",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(securityAsync.pending, (state) => {})
      .addCase(securityAsync.fulfilled, (state, action) => {
        const { errors, is_enable, uri, secret_key } = action.payload;
        if (errors) {
          return;
        } else {
          state.uri = uri;
          state.is_enable = is_enable;
          state.secret_key = secret_key;
        }
      })
      .addCase(enable2faAsync.pending, (state) => {})
      .addCase(enable2faAsync.fulfilled, (state, action) => {
        const { errors, is_enable } = action.payload;
        if (errors) {
          return;
        } else {
          state.is_enable = is_enable;
        }
      })
      .addCase(disable2faAsync.pending, (state) => {})
      .addCase(disable2faAsync.fulfilled, (state, action) => {
        const { errors, is_enable, uri, secret_key } = action.payload;
        if (errors) {
          return;
        } else {
          state.uri = uri;
          state.is_enable = is_enable;
          state.secret_key = secret_key;
        }
      });
  },
});

export default usersSlice.reducer;
