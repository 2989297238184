/* eslint-disable react-hooks/exhaustive-deps */
import { ROUTES } from "config/routes";
import React, { useEffect, useState } from "react";
import queryString from "query-string";
// import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import APIs from "api";
import Loading from "components/loading";

import { Helmet } from "react-helmet";

const withEstock = (Component: any) => {
  const TokenEdit: React.FC = (props) => {
    // const dispatch = useDispatch();
    const prams: any = useParams();
    const history = useHistory();
    const location: any = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const token: any = queryString.parse(location.search)?.token;
    const [fbPageID, setFBPageID] = useState(null);

    useEffect(() => {
      async function checkToken() {
        try {
          setIsLoading(true);
          const estockApproved: any = await APIs.YOURESTOCK.getYourEstock(
            prams.symbol
          );

          setFBPageID(
            estockApproved.projectId.facebookPageId // '105475828881866'
          );

          if (estockApproved.status === "approved") {
            setIsLoading(false);
            return;
          } else {
            const isToken: any = await APIs.YOURESTOCK.verifyTokenEdit({
              token: token,
            });

            setIsLoading(false);
            if (isToken && isToken?.symbol === prams.symbol) {
              return;
            } else {
              history.push(ROUTES.HOME);
              setIsLoading(false);
            }
          }
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          history.push(ROUTES.HOME);
        }
      }
      checkToken();
    }, []);

    if (isLoading) {
      return <Loading />;
    }

    const isEnable = false;

    return (
      <div>
        <Component {...props} token={token} />

        {fbPageID && isEnable && (
          <Helmet>
            <script>
              {`
            var chatbox = document.getElementById('fb-customer-chat');
            chatbox.setAttribute("page_id", "${fbPageID}");
            chatbox.setAttribute("attribution", "biz_inbox");
          `}
            </script>
            <script>
              {`
            window.fbAsyncInit = function() {
              FB.init({
                xfbml: true,
                version: 'v14.0'
              });
            };
    
            (function(d, s, id) {
              var js, fjs = d.getElementsByTagName(s)[0];
              if (d.getElementById(id)) return;
              js = d.createElement(s); js.id = id;
              js.src = 'https://connect.facebook.net/vi_VN/sdk/xfbml.customerchat.js';
              fjs.parentNode.insertBefore(js, fjs);
              }(document, 'script', 'facebook-jssdk'));
          `}
            </script>
          </Helmet>
        )}
      </div>
    );
  };

  return TokenEdit;
};

export default withEstock;
