/* eslint-disable import/no-anonymous-default-export */
import { request, parseErrorResponse } from "./request";

export const getTasks = () =>
  new Promise((resolve, reject) => {
    request()
      .get("/tasks")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const getTaskById = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get("/tasks/" + req.params.id)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const postPerform = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("/tasks/" + req.params.id, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const getCampaigns = () =>
  new Promise((resolve, reject) => {
    request()
      .get("/campaigns/getByUser")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const getLevelUserCampaign = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get("/campaigns/getLevelByUser/" + req.params.id)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const getLevels = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get("/levels/" + req.params.campaignId)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const updateLevel = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("/levels/update/", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const getRevenues = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get("/revenues/campaign/" + req.params.campaignId)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const claimRevenue = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("/revenues/claim" , req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export default {
  getTasks,
  getTaskById,
  postPerform,
  getCampaigns,
  getLevelUserCampaign,
  getLevels,
  updateLevel,
  claimRevenue,
  getRevenues
};
